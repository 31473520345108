import moment from "moment";
import { useState } from "react";

export const generateNiceWeekFromWeek = (week) => {
  const startWeek = week.split("-")[0];
  const endWeek = week.split("-")[1];

  // 29 Jul 2024 to 04 Aug 2024
  return `${moment(startWeek, "YYYYMMMDD").format("DD MMM YYYY")} to ${moment(
    endWeek,
    "YYYYMMMDD"
  ).format("DD MMM YYYY")}`;
};

export const generateTimesheetWeek = (startDate, endDate) =>
  startDate.format("YYYYMMMDD").toUpperCase() +
  "-" +
  endDate.format("YYYYMMMDD").toUpperCase();

export const periodToDate = (selectedPeriod) => {
  // 07 Oct 2024 to 13 Oct 2024 -> moment()
  const firstDay = moment(selectedPeriod.split(" to ")[0], "DD MMM YYYY");

  return firstDay;
};

const useWeekData = (selectedPeriodToDate = false) => {
  const currentDate = moment();
  const [date, setDate] = useState(
    selectedPeriodToDate ? selectedPeriodToDate : currentDate
  );
  const [edit, setEdit] = useState(false);
  const currentWeek = date.clone().startOf("isoWeek"); //? the current selected week
  const currentWeekEnd = date.clone().endOf("isoWeek");

  const [personIncharge, setPersonInCharge] = useState([]);

  const presentWeek = generateTimesheetWeek(
    moment().startOf("isoWeek"),
    moment().endOf("isoWeek")
  );

  const currentNiceWeekRange =
    currentWeek.format("DD MMM YY") +
    " - " +
    currentWeekEnd.format("DD MMM YY");

  const [week, setWeek] = useState(
    generateTimesheetWeek(currentWeek, currentWeekEnd)
  );

  const onChange = (date) => {
    setDate(date);
    // 30Oct23-5Nov23
    var weekStart = date.clone().startOf("isoWeek");
    var weekEnd = date.clone().endOf("isoWeek");
    setWeek(generateTimesheetWeek(weekStart, weekEnd));
    setEdit(false);
    setPersonInCharge([]);
  };

  const handleNextWeek = () => {
    // if (moment().isBefore(date.clone().endOf("isoWeek"))) {
    //   return;
    // }
    const nextWeek = date.clone().add(1, "week");
    setDate(nextWeek);
    var weekStart = nextWeek.clone().startOf("isoWeek");
    var weekEnd = nextWeek.clone().endOf("isoWeek");
    setWeek(generateTimesheetWeek(weekStart, weekEnd));
    setPersonInCharge([]);
  };

  const handlePreviousWeek = () => {
    const previousWeek = date.clone().subtract(1, "week");
    setDate(previousWeek);
    var weekStart = previousWeek.clone().startOf("isoWeek");
    var weekEnd = previousWeek.clone().endOf("isoWeek");
    setWeek(generateTimesheetWeek(weekStart, weekEnd));
    setPersonInCharge([]);
  };

  const disabledDate = (current) => {
    // return current && current.isAfter(moment().endOf("isoWeek"));
    return false;
  };

  const isPreviouseWeek = (week) => week.isAfter(date.clone().endOf("isoWeek"));

  return {
    date,
    week,
    currentWeek,
    presentWeek,
    currentNiceWeekRange,
    edit,
    isPreviouseWeek,
    setEdit,
    disabledDate,
    handleNextWeek,
    handlePreviousWeek,
    onChange,
    personIncharge,
    setPersonInCharge,
  };
};

export default useWeekData;
