import { Col, Form, Input, Modal, Radio, Row, Select } from 'antd';
import Button from 'atoms/Button';
import { currencyOptions } from 'common/Constants';
import queryKeys from 'common/queryKey';
import { useScrollFetch } from 'hooks/useScrollFetch';
import React, { useMemo } from 'react'
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;


function AddVendor({ onClose, showAddModal, isUpdating, onFinish, form }) {

    return (
        <div>
            <StyledModal title="Add Vendor"
                visible={showAddModal}
                onCancel={() => {
                    onClose()
                }}
                width={800}

                footer={[]}>

                <Form
                    layout="vertical"
                    // onFinish={onFinish}
                    onFinish={onFinish}
                    colon={false}
                    form={form}

                >
                    <div className="resource-card">
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Name is required.',
                                        },]}
                                >
                                    <Input placeholder="Enter" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item
                                    label="Email Id"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Email is required.',
                                        },]}
                                >
                                    <Input placeholder="Enter" />

                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item
                                    label="Role"
                                    name="role"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Role is required.',
                                        },]}
                                >
                                    <Input placeholder="Enter" />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="To/CC"
                                    name="sendtype"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Role is required.',
                                        },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value="to">To</Radio>
                                        <Radio value="cc">CC</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <div className="text-end">
                        <Button type="primary" onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isUpdating}>
                            Save
                        </Button>
                    </div>
                </Form>

            </StyledModal>
        </div>
    )
}

export default AddVendor