import React, { useState, useEffect, useMemo } from "react";
import { useParams, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Col, Row, Button, Tooltip, Form, Select, Input } from "antd";
import UITabs from "../../atoms/UITabs";
import Engagements from "./Engagements";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ReactComponent as DeleteIcon } from "assets/trash_btn.svg";
import Activity from "./Activity";
import { ReactComponent as ClientIcon } from "../../assets/clientIcon.svg";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import COLORS from "../../common/Colors";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import ClientContactJSON from "../../JsonFormat/ClientContactJson/index";
import ClientBasicJSON from "../../JsonFormat/ClientBasicJson/index";
import ClientStyle from "./ClientStyle";
import UIModal from "reusableComponent/UIModal";
import { CALL_API_UPDATED } from "common/API";
import { message } from "antd";
import { STATUS_CODE } from "common/Constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import styled from "styled-components";
import MenuBar from "atoms/UITextEditor/MenuBar";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Text from "@tiptap/extension-text";
import TabsList from "molecules/TabsList";
import ClientInformation from "./ClientInformation";
import GeneralInformation from "./GeneralInformation";
import EMSRecord from "./EMSRecord";
import COICheckProcess from "./COICheckProcess";
import ClientOnboarding from "./ClientOnboarding";
import Industry from "./Industry";
import ClientFiles from "./ClientFiles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const EmailModalStyle = styled.div`
  .error-msg {
    color: #b70707;
    font-weight: 400;
    font-size: 14px;
  }
  h6 {
    padding: 16px;
    background: #ededed;
    border-radius: 5px 5px 0px 0px;
  }
  .p-16 {
    padding: 16px;
  }
  .ant-form-item-control-input-content {
    font-size: 16px;
    font-weight: 500;
    color: #1b1b1b;
  }
  .ant-input {
    border-radius: 5px;
    border: 1px solid #dedede;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
`;
const ClientDetails = () => {

  
  const { TextArea } = Input;
  const { id } = useParams();
  const location = useLocation();
  const extraData = location.state ? location.state.extraData : null;
  const [activeTab, setActiveTab] = useState(1);
  const [copied, setCopied] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(0);
  const fullWidth = { width: "100%" };
  const [basicData, setBasicData] = useState({});
  const [deletebtnLoader, setDeletebtnLoader] = useState(false);
  const [notebtnLoader, setNotebtnLoader] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const editorRef = React.useRef(null);


  const filteredData = extraData.filter((item) => item?.bdo_id == id); 
  const panelStyle = {
    marginBottom: "8px",
    background: COLORS.SIDEBAR,
    borderRadius: "5px",
    border: "none",
  };

  const loaderStyle = { fontSize: "16px", color: "#000" };

  const errMsg = { color: "#b70707" };
  const disableResize = { resize: "none" };
  const [noteModal, setNoteModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [noteValidation, setNoteValiation] = useState(false);
  const [emailForm, setEmailForm] = useState({
    to: "",
    cc: "",
    header: "",
    body: "",
    footer: "",
  });
  const [errors, setErrors] = useState({
    to: { required: false, emailInvalid: false },
    cc: { required: false, emailInvalid: false },
    header: { required: false },
    body: { required: false },
    footer: { required: false },
  });
  const [disableEmailBtn, setDisableEmailBtn] = useState(false);
  const [viewEmail, setViewEmail] = useState(false);
  const [emailViewInputData, setEmailViewInputData] = useState({});
  const handleViewEmailOpen = (e) => {
    setEmailViewInputData(e);
    setViewEmail(true);
  };
  const handleTxtUpdate = ({ editor }) => {
    const newContent = editor.getHTML();
    setEditorContent(newContent);
    onEmailForm(newContent); // Call your email form update function here if needed
  };
  const editorTipTap = useEditor({
    extensions: [StarterKit],
    content: "<p></p>",
    editable: true,
    // injectCSS: false,
    onUpdate: handleTxtUpdate,
  });

  const onEmailForm = (e) => {
    setEmailForm({
      ...emailForm,
      body: e,
    });
    if (
      e.replace(
        /<p>|<\/p>|<b>|<\/b>|<i>|<\/i>|<ul>|<\/ul>|<li>|<\/li>|<ol>|<\/ol>|<code>|<\/code>|<s>|<\/s>|<blockquote>|<\/blockquote>|<hr>/g,
        ""
      ).length != 0
    ) {
      setErrors({
        ...errors,
        body: { required: false },
      });
    }
  };
  const onChangeEmailForm = (e) => {
    const { name, value } = e.target;
    setEmailForm({
      ...emailForm,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: { required: false, emailInvalid: false },
    });
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateForm = () => {
    let valid = false;
    const newErrors = {};
    const editorContent = editorTipTap
      ?.getHTML()
      .replace(
        /<p>|<\/p>|<b>|<\/b>|<i>|<\/i>|<ul>|<\/ul>|<li>|<\/li>|<ol>|<\/ol>|<code>|<\/code>|<s>|<\/s>|<blockquote>|<\/blockquote>|<hr>/g,
        ""
      );

    if (emailForm.to == "") {
      valid = true;
      newErrors.to = { ...newErrors.to, required: true };
    }
    if (emailForm.to !== "" && !validateEmail(emailForm.to)) {
      valid = true;
      newErrors.to = { ...newErrors.to, emailInvalid: true };
    }
    if (emailForm.cc == "") {
      valid = true;
      newErrors.cc = { ...newErrors.cc, required: true };
    }
    if (emailForm.cc !== "" && !validateEmail(emailForm.cc)) {
      valid = true;
      newErrors.cc = { ...newErrors.cc, emailInvalid: true };
    }
    if (emailForm.header == "") {
      valid = true;
      newErrors.header = { ...newErrors.header, required: true };
    }
    if (emailForm.body == "") {
      valid = true;
      newErrors.body = { ...newErrors.body, required: true };
    }
    if (emailForm.footer == "") {
      valid = true;
      newErrors.footer = { ...newErrors.footer, required: true };
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    const data = {
      clientId: deleteID,
      email: {
        To: emailForm.to,
        CC: emailForm.cc,
        Body: editorTipTap?.getHTML().replace(/<p>/g, "").replace(/<\/p>/g, ""),
        Header: emailForm.header,
        Footer: emailForm.footer,
        time: moment().format("llll"),
      },
    };

    console.log("data", data);
    if (validateForm()) {
      console.log("Form contains errors. Please add valid input.");
    } else {
      setDisableEmailBtn(true);
      const response = await CALL_API_UPDATED(
        `/insyts/clients/email`,
        "post",
        data
      );
      if (response) {
        setEmailModal(false);
        setDeleteID(0);
        setDisableEmailBtn(false);
      }
      if (response.status === true) {
        message.success("Email Send Successfully");
      } else if (response.status === STATUS_CODE.INVALID_PAYLOAD) {
        message.error(`Invalid payload. Please enter correct data.`);
      } else if (response.status === STATUS_CODE.SOMETHING_WENT_WRONG) {
        message.error(`Oops!! something went wrong.`);
      } else {
        message.error(`Oops!! something went wrong.`);
      }
    }
  };

  const deleteModalBtnStyle = {
    margin: "0 5px",
    fontSize: "14px",
    fontWeight: 600,
    background: "#C2FAE5",
    border: "none",
    borderRadius: "5px",
  };

  const marginSpace = {
    marginTop: "20px",
  };
  const documentlist = filteredData[0]?.documents;
  const industrylist = filteredData[0]?.industries;
  const modaltxtStyle = {
    color: "#1B1B1B",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
  };

  const noteheader = {
    color: "#1B1B1B",
    fontSize: "14px",
    fontWeight: "bold",
  };
  const textright = { textAlign: "right", marginTop: "20px" };
  useEffect(() => {
    setBasicData(filteredData[0]);
  }, []);

  const history = useHistory();
  // const { ContactDetail } = ClientContactJSON(filteredData[0]);
  // const { BasicDetail } = ClientBasicJSON(filteredData[0]);

  const handleDelete = async () => {
    setDeletebtnLoader(true);
    const data = {
      clientId: deleteID,
    };
    const response = await CALL_API_UPDATED(
      `/insyts/clients/changestatus?status=delete`,
      "post",
      data
    );
    if (response) {
      setDeleteModal(false);
      setDeleteID(0);
      setDeletebtnLoader(false);
      history.push(`/client`);
    }
    if (response.status === true) {
      message.success("Client Deleted Successfully");
    } else if (response.status === STATUS_CODE.INVALID_PAYLOAD) {
      message.error(`Invalid payload. Please enter correct data.`);
    } else if (response.status === STATUS_CODE.SOMETHING_WENT_WRONG) {
      message.error(`Oops!! something went wrong.`);
    } else {
      message.error(`Oops!! something went wrong.`);
    }
  };

  const handleNote = async () => {
    if (!noteText) {
      setNoteValiation(true);
    } else {
      setNotebtnLoader(true);
      const data = {
        clientId: deleteID,
        note: {
          note: noteText,
          time: moment().format("llll"),
        },
      };
      const response = await CALL_API_UPDATED(
        `/insyts/clients/note`,
        "post",
        data
      );
      if (response) {
        setNoteModal(false);
        setDeleteID(0);
        setNotebtnLoader(false);
      }
      if (response.status === true) {
        message.success("Note Added Successfully");
      } else if (response.status === STATUS_CODE.INVALID_PAYLOAD) {
        message.error(`Invalid payload. Please enter correct data.`);
      } else if (response.status === STATUS_CODE.SOMETHING_WENT_WRONG) {
        message.error(`Oops!! something went wrong.`);
      } else {
        message.error(`Oops!! something went wrong.`);
      }
    }
  };
  const handleFormChange = (e) => {
    setBasicData({
      ...basicData,
      [e.target.name]: e.target.value,
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
  };
  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };
  // const getItems = (panelStyle) => [
  //   {
  //     key: "1",
  //     label: "Basic Information",
  //     children: (
  //       <div className="collapse_form">
  //         <GridBuilder
  //           formStructureJson={BasicDetail}
  //           handleChange={handleFormChange}
  //           initialValues={basicData}
  //         />
  //       </div>
  //     ),
  //     style: panelStyle,
  //   },
  //   {
  //     key: "2",
  //     label: "Contact  Details",
  //     children: (
  //       <div className="collapse_form">
  //         <GridBuilder
  //           formStructureJson={ContactDetail}
  //           handleChange={handleFormChange}
  //           initialValues={basicData}
  //         />
  //       </div>
  //     ),
  //     style: panelStyle,
  //   },
  //   {
  //     key: "3",
  //     label: "Industries",
  //     children: (
  //       <div className="collapse_form">
  //         {industrylist.map((e, idx) => {
  //           return (
  //             <>
  //               <Form.Item label="Industry"> </Form.Item>
  //               <Select
  //                 options={[{ value: e.Industry, label: e.Industry }]}
  //                 disabled
  //                 defaultValue={[e.Industry]}
  //                 style={fullWidth}
  //               />

  //               <Form.Item label="Sector"> </Form.Item>
  //               <Select
  //                 options={[{ value: e.Sector, label: e.Sector }]}
  //                 defaultValue={[e.Sector]}
  //                 style={fullWidth}
  //                 disabled
  //               />
  //             </>
  //           );
  //         })}
  //       </div>
  //     ),
  //     style: panelStyle,
  //   },
  //   {
  //     key: "4",
  //     label: "Documents",
  //     children: (
  //       <>
  //         {documentlist.map((data, idx) => {
  //           return (
  //             <div className="document_container" key={`document-${idx}`}>
  //               {data.File}
  //             </div>
  //           );
  //         })}
  //       </>
  //     ),
  //     style: panelStyle,
  //   },
  // ];
// console.log('basicdat-->',basicData);
const [tabId, setTabId] = useState("Client Information");

const handleTabSelect = (id) => {
  setTabId(id);
};

const clientTabs = useMemo(
  () => [
    {
      id: "Client Information",
      label: "Client Information",
      component: <ClientInformation filteredData={ filteredData[0] }/>,
    },
    {
      id: "General Details",
      label: "General Details",
      component: <GeneralInformation filteredData={ filteredData[0] }/>,
    },
    // {
    //   id: "Industry",
    //   label: "Industry",
    //   component: <Industry data={ filteredData[0] }/>,
    // },
    {
      id: "Client OnBoarding",
      label: "Client OnBoarding",
      component: <ClientOnboarding data={ filteredData[0] }/>,
    },
    {
      id: "COI Check Process",
      label: "COI Check Process",
      component: <COICheckProcess data={ filteredData[0] }/>,
    },
    // {
    //   id: "Files",
    //   label: "Files",
    //   component: <ClientFiles data={ filteredData[0] }/>,
    // },
    {
      id: "EMS Records",
      label: "EMS Records",
      component: <EMSRecord filteredData={ filteredData[0] }/>,
    },
  ],
  []
);
  return (
    <>
      <ClientStyle>
        
          <label className="bg-transparent border-0 mb-3 text-left styled-cursor" onClick={() => history.push(`/client`)} >
          Client<ArrowForwardIosIcon className="px-1" /> 
          </label>
          
          <b className=" pb-0 pt-1">{filteredData[0]?.name}</b>
        
        <Row className="pb-2">
          <div className="d-flex align-items-center gap-2">
            <div className="client-icon">
              <ClientIcon />
            </div>
            <div>
              <p className="mb-0"><b>{filteredData[0]?.name}</b></p>
              <p className="mb-0">client</p>
            </div>
          </div>
        </Row>
        <div className="d-flex gap-3 pb-2">
        <div>
              <p className="mb-0"><b>{filteredData[0]?.bdo_id || "-"}</b></p>
              <p className="mb-0">Client ID</p>
              </div>
              <div className="left_border"></div>
              <div>
              <p className="mb-0"><b>{filteredData[0]?.["statuscode@OData.Community.Display.V1.FormattedValue"] || "-"}</b></p>
              <p className="mb-0">Status Reason</p>
            </div>
            <div className="left_border"></div>
            <div>
              <p className="mb-0"><b>{filteredData[0]?.["bdo_status@OData.Community.Display.V1.FormattedValue"] || "-"}</b></p>
              <p className="mb-0">COB FORM Status</p>
            </div>
            <div className="left_border"></div>
            <div>
              <p className="mb-0 text-info"><b>{filteredData[0]?.["_ownerid_value@OData.Community.Display.V1.FormattedValue"] || "-"}</b></p>
              <p className="mb-0">Owner</p>
            </div>
        </div>
        <TabsList
        items={clientTabs}
        handleTabSelect={handleTabSelect}
        tabId={tabId}
      />
      </ClientStyle>
    </>
  );
};

export default ClientDetails;
