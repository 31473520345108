import { message, Spin, Table } from "antd";
import Icon from "atoms/Icon";
import { CALL_API } from "common/API";
import COLORS from "common/Colors";
import { STATUS_CODE } from "common/Constants";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { useQueryState } from "hooks/useQueryState";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUserInfo } from "redux/selectors";
import styled from "styled-components";
import BillingInvoice from "./BillingInvoice";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const FindEngagementName = ({ engagementid }) => {
  const { key: engagementkey, url: engagementurl } =
    queryKeys.getEngagementById(engagementid);
  const { isFetched } = useQueryState(engagementkey);
  const { data: engagementDataRes } = useFetch(engagementkey, engagementurl, {
    enabled: !isFetched && !!engagementid,
  });
  const engagementData = engagementDataRes?.data;

  const name = engagementData?.information?.jobTitle;

  return <>{name}</>;
};

const FindClinentName = ({ clientcode }) => {
  const { isFetched } = useQueryState(queryKeys.getClient(clientcode).key);
  const { data: clientDataRes, isLoading: isClientFetching } = useFetch(
    queryKeys.getClient(clientcode).key,
    queryKeys.getClient(clientcode).url,
    {
      enabled: !isFetched && !!clientcode,
    }
  );

  const client = clientDataRes?.data?.[0]?.registeredName || "-";
  return <>{client}</>;
};

const BillingApproval = ({ userInfo }) => {
  const iconStyle = { fontSize: "12px", cursor: "pointer" };
  const disabledIcon = {
    fontSize: "12px",
    cursor: "not-allowed",
    opacity: "0.3",
  };
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showBillingInvoiceModal, setShowBillingInvoiceModal] = useState(false);
  const [billingInvoiceData, setBillingInvoiceData] = useState(null);

  const showBillingInvoice = (record) => {
    setShowBillingInvoiceModal(true);
    setBillingInvoiceData(record);
  };

  const handleCloseInvoiceModal = () => {
    setShowBillingInvoiceModal(false);
  };

  const { getBillingApprovals } = queryKeys;

  const { key, url } = getBillingApprovals(userInfo?.userid);

  const { data, isLoading, refetch } = useFetch(key, url, {
    enabled: !!userInfo?.userid,
  });

  const onFinish = async (record, type) => {
    try {
      setLoading(true);

      const payload = {
        clientcode: record?.clientcode,
        engagementid: record?.engagementid,
        statusid: "1",
        statusname:
          type === "approved"
            ? userInfo?.userid === "finance@bdo-ea.com"
              ? "Approved by Finance"
              : "Approved by Partner"
            : userInfo?.userid === "finance@bdo-ea.com"
            ? "Declined by Finance"
            : "Declined by Partner",
        engagementPartner: record?.engagementPartner,
        billingoffice: record?.billingoffice,
        userid: record?.userid,
        billingdate: moment(record?.billingdate).format("YYYY-MM-DD"),
        internalbilling: record?.internalbilling,
        description: record?.description,
        currency: record?.currency,
        // subengagement: form?.subEngagement,
        amount: record?.amount,
        vat: record?.vat,
        financialyear: moment(record?.financialyear).format("YYYY-MM-DD"),
        loerefno: record?.loerefno,
        contactpersonid: record?.contactpersonid,
        contactperson: record?.contactperson,
        contactpersonacid: record?.contactpersonacid,
        contactpersonac: record?.contactpersonac,
        billingfilename: record?.billingfilename,
        // billingfiletype: record?.type,
        content: "",
        billingfile: record?.billingfile,
        claims: record?.claims || [],
      };

      const { code } = await CALL_API(
        `insyts/billing?engagementid=${record?.engagementid}&billingid=${record?.billingid}`,
        "patch",
        payload
      );
      setLoading(false);
      if (code === STATUS_CODE.SUCCESS) {
        message.success(
          type === "approved"
            ? "Approved Successfully"
            : "Declined Successfully"
        );
        refetch();
      }
    } catch (error) {
      message.error(
        "Something went wrong. Please reach out to our technical team."
      );
      setLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Engagement",
      dataIndex: "engagementid",
      key: "engagementid",
      render: (engagementid) => (
        <FindEngagementName engagementid={engagementid} />
      ),
    },
    {
      title: "Client",
      dataIndex: "clientcode",
      key: "clientcode",
      render: (clientcode) => <FindClinentName clientcode={clientcode} />,
    },
    {
      title: "Billing Office",
      dataIndex: "billingoffice",
      key: "billingoffice",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Internal Billing",
      dataIndex: "internalbilling",
      key: "internalbilling",
      render: (internalbilling) => (internalbilling ? "Yes" : "No"),
    },
    {
      title: "Status",
      dataIndex: "statusname",
      key: "statusname",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$ ${amount}`,
    },
    {
      title: "Date Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD-MMM-YYYY"),
    },
    {
      title: "Submitted By",
      dataIndex: "userid",
      key: "userid",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        const isApprovedOrDeclined =
          record?.statusname === "Approved by Finance" ||
          record?.statusname === "Approved by Partner" ||
          record?.statusname === "Declined by Finance" ||
          record?.statusname === "Declined by Partner";

        return (
          <div className="d-flex gap-2">
            <Icon
              name="tick"
              style={isApprovedOrDeclined ? disabledIcon : iconStyle}
              onClick={(e) => {
                if (isApprovedOrDeclined) {
                  return;
                }
                e.stopPropagation();
                onFinish(record, "approved");
              }}
            ></Icon>
            <Icon
              name="wrong"
              style={isApprovedOrDeclined ? disabledIcon : iconStyle}
              onClick={(e) => {
                if (isApprovedOrDeclined) {
                  return;
                }
                e.stopPropagation();
                onFinish(record, "declined");
              }}
            ></Icon>
            <Icon
              name="eye"
              style={iconStyle}
              onClick={() => {
                // history.push(`/approvals/bills/${record.engagementid}`, {
                //   record,
                //   viewbillings: true,
                //   tabid: "billing_approval",
                // });
                showBillingInvoice(record);
              }}
            ></Icon>
          </div>
        );
      },
    },
  ];

  const dataList = data?.response?.data;

  const ApprovedTotalBillingAmount = dataList?.reduce(
    (acc, item) =>
      item?.internalbilling &&
      (item?.statusname === "Approved by Partner" ||
        item?.statusname === "Approved by Finance")
        ? acc +
          (parseInt(item?.amount?.toString()?.replace(/[$,]/g, ""), 10) || 0)
        : acc,
    0
  );
  const ApprovedTotalExternalBillingAmount = dataList?.reduce(
    (acc, item) =>
      !item?.internalbilling &&
      (item?.statusname === "Approved by Partner" ||
        item?.statusname === "Approved by Finance")
        ? acc +
          (parseInt(item?.amount?.toString()?.replace(/[$,]/g, ""), 10) || 0)
        : acc,
    0
  );

  const PendingTotalBillingAmount = dataList?.reduce(
    (acc, item) =>
      item?.internalbilling && item?.statusname === "Pending"
        ? acc +
          (parseInt(item?.amount?.toString()?.replace(/[$,]/g, ""), 10) || 0)
        : acc,
    0
  );

  const PendingTotalExternalBillingAmount = dataList?.reduce(
    (acc, item) =>
      !item?.internalbilling && item?.statusname === "Pending"
        ? acc +
          (parseInt(item?.amount?.toString()?.replace(/[$,]/g, ""), 10) || 0)
        : acc,
    0
  );
  return (
    <>
      <Spin spinning={isLoading || loading}>
        <StyledTable
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="id"
          dataSource={data?.response?.data}
          pagination={false}
          onRow={(record, index) => ({
            onClick: (event) => {
              showBillingInvoice(record);
            },
          })}
        />

        {dataList?.length > 0 && (
          <div className="w-100 d-flex justify-content-end mt-5">
            <div>
              <h6>
                Approved (Internal) Total Billing amount:{" "}
                {ApprovedTotalBillingAmount}
              </h6>
              <h6>
                Approved (External) Total Billing amount:{" "}
                {ApprovedTotalExternalBillingAmount}
              </h6>
              <h6>
                Pending (Internal) Total Billing amount:{" "}
                {PendingTotalBillingAmount}
              </h6>
              <h6>
                Pending (External) Total Billing amount:{" "}
                {PendingTotalExternalBillingAmount}
              </h6>
            </div>
          </div>
        )}

        <BillingInvoice
          billingInvoiceData={billingInvoiceData}
          open={showBillingInvoiceModal}
          handleClose={handleCloseInvoiceModal}
        />
      </Spin>
    </>
  );
};
const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(BillingApproval);

const BillingWrap = styled.div`
  .styled-text {
    background-color: #c2fae5;
  }
`;
