import React from "react";
import { StyledDateCell } from "./StyledComponents";
import { dateInDetail, findWeekId, isDateBetweenTwoDates } from "utils/Date";
import { filter, uniqBy } from "lodash";
import ResourceIndicator from "./ResourceIndicator";
import ReusableCalendar from "./ReusableCalendar";
import moment from "moment";
import styled from "styled-components";
import { Collapse } from "antd";

const AllEngagements = ({
  isResourceLoading,
  resources,
  handleShowAddResource,
}) => {
  const dateFullCellRender = (cellDate) => {
    const { date: day } = dateInDetail(cellDate);
    // const currentDate = moment(cellDate).format("YYYY-MM-DD");

    const currentStartWeekDate = moment(cellDate).startOf("week");
    const currentEndWeekDate = moment(cellDate).endOf("week");

    const currentWeekId = findWeekId(currentStartWeekDate, currentEndWeekDate);

    const resourcesList = filter(
      resources,
      ({ actualBookedStartDate, actualBookedEndDate, weekId }) =>
        isDateBetweenTwoDates(
          cellDate,
          actualBookedStartDate,
          actualBookedEndDate
        ),
        // && currentWeekId === weekId,
      {}
    );

    return (
      <StyledDateCell onClick={() => handleShowAddResource(moment(cellDate))}>
        <span className={`day`}>{day}</span>
        <div className="ant-picker-calendar-date-content">
          {resourcesList.map((resource) => (
            <ResourceIndicator resourceDetails={resource}>
              {resource.employeeName}
            </ResourceIndicator>
          ))}
        </div>
      </StyledDateCell>
    );
  };

  const todayResources = resources?.filter(
    ({ actualBookedStartDate, actualBookedEndDate, weekId }) =>
      isDateBetweenTwoDates(
        moment(),
        actualBookedStartDate,
        actualBookedEndDate
      ) && findWeekId(moment(), moment()) === weekId
  );

  const todayEngagements = uniqBy(todayResources, "engagementId");

  const engagmentPanelContent = (
    <Collapse defaultActiveKey={["1"]} className="mt-2">
      <Collapse.Panel header="Today's Engagements" key="1">
        <StyledPanel>
          {todayEngagements?.length > 0 ? (
            <ul>
              {todayEngagements.map((resource) => (
                <li key={resource.engagementId}>{resource.engagementName}</li>
              ))}
            </ul>
          ) : (
            <p>No engagements today</p>
          )}
        </StyledPanel>
      </Collapse.Panel>

      <Collapse.Panel header="Today's Resources" key="2">
        <StyledPanel>
          {todayResources?.length > 0 ? (
            <ul>
              {todayResources.map((resource) => (
                <li key={resource.id}>{resource.employeeName}</li>
              ))}
            </ul>
          ) : (
            <p>No resources today</p>
          )}
        </StyledPanel>
      </Collapse.Panel>
    </Collapse>
  );

  return (
    <ReusableCalendar
      dateFullCellRender={dateFullCellRender}
      isCalendarLoading={isResourceLoading}
      calendarTitle="Calendar"
      showHoliday={false}
      leftPanelContent={engagmentPanelContent}
    />
  );
};

export default AllEngagements;

const StyledPanel = styled.div`
  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    padding: 8px;
    margin-bottom: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f1f1f1;
    }
  }
`;
