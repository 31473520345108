import { Row, Col, Table, Spin, Select, Form, message } from "antd";
import Icon from "atoms/Icon";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { getUserInfo } from "redux/selectors";
import { useSelector } from "react-redux";
import { sumBy } from "lodash";
import { currencyAmount } from "utils/functions";
import { useUpdate } from "hooks/useUpdate";
import { useState } from "react";

const PFAApproval = ({ pfaapprovalpermission }) => {
    const userInfo = useSelector(getUserInfo);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const { getPFAApproval, updatePFAApproval: updatePFAApprovalQuery } = queryKeys;
    const queryParams = new URLSearchParams();
    queryParams.append("userId", userInfo?.userid);
    const queryString = queryParams.toString();
    const { key, url } = getPFAApproval(queryString);

    const { data: PFAApprovalRes, refetch, isLoading } = useFetch(key, url, {
        enabled: !!userInfo?.userid,
    });

    const { mutate: updatePFAApproval, isLoading: isUpdating } = useUpdate(
        updatePFAApprovalQuery.key,
        updatePFAApprovalQuery.url
    );

    const PFAApprovalData = PFAApprovalRes?.data?.length
        ? PFAApprovalRes.data.map(item => {
            const totalMandays = item.employeeList.reduce((sum, emp) => {
                const personDay = parseInt(emp.personDay, 10) || 0;
                return sum + personDay;
            }, 0) || 0;

            const sumSubContractamt = sumBy(item?.subcontractor, (item) =>
                parseFloat(item?.amount)
            ) || 0;

            const budjetChargeout = (
                item.employeeList
            )
                ?.filter((item) => {
                    const resourceExtRate = parseFloat(item?.resourceExtRate);
                    return !isNaN(resourceExtRate) && item?.personDay !== undefined;
                })
                .map((item) => parseFloat(item?.resourceExtRate) * parseInt(item?.personDay) * 8)
                .reduce((acc, value) => acc + value, 0) || 0;

            const profitLossCal =
            Number(item?.financial_resourcePlan?.billingAmount) / item?.financial_resourcePlan?.conversion_rate -
                (parseInt(budjetChargeout) + parseInt(sumSubContractamt)) || 0;

            return {
                engagementId: item.engagementId,
                clientId: item.clientId,
                jobTitle: item.information.jobTitle,
                clientName: item.client.clientName,
                totalmandays: totalMandays || 0,
                billingamount: currencyAmount("$", Number(item?.financial_resourcePlan?.billingAmount) / item?.financial_resourcePlan?.conversion_rate || 0),
                plannedchargeout: currencyAmount("$", budjetChargeout),
                plannedsubcontractor: currencyAmount("$", sumSubContractamt),
                estimatedprofit: currencyAmount("$", profitLossCal),
                pfa: profitLossCal < 0
                    ? currencyAmount("$", Math.abs(profitLossCal))
                    : currencyAmount("$", 0),
                profitpfa: (budjetChargeout > 0 || sumSubContractamt > 0) &&
                    profitLossCal > 0
                    ? currencyAmount("$", Math.abs(profitLossCal))
                    : currencyAmount("$", 0),
                approvalstatus: item?.approvalstatus,
                pfastatus: item?.financial_resourcePlan?.pfaStatus
            };
        })
        : [];

    const handleApprove = async (record, status) => {
        setLoading(true);
        const payload = {
            clientId: record?.clientId,
            engagementId: record?.engagementId,
            pfastatus: status === "Approved" ? "Approved" : "Declined",
            jobclosurestatus: status === "Approved" ? "Not Started" : "Not Approved",
            approvalstatus: status === "Approved" ? "Approved" : "Declined"
        };
        updatePFAApproval(payload, {
            onSuccess: (res) => {
                message.success(status === "Approved" ? 
                    "PFA Approved Successfully" : "PFA Declined Successfully"
                );
                refetch();
            },
        });
        setLoading(false);
    }

    const iconStyle = { fontSize: "12px", cursor: "pointer" }
    const disabledIcon = { fontSize: "12px", cursor: "not-allowed", opacity: "0.3" }
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (_, record, index) => index + 1,
        },
        {
            title: "Engagement",
            dataIndex: "jobTitle",
            key: "jobTitle",
        },
        {
            title: "Client",
            dataIndex: "clientName",
            key: "clientName",
        },
        {
            title: "Total Mandays",
            dataIndex: "totalmandays",
            key: "totalmandays",
        },
        {
            title: "Billing Amount(USD)",
            dataIndex: "billingamount",
            key: "billingamount",
        },
        {
            title: "Planned Chargout",
            dataIndex: "plannedchargeout",
            key: "plannedchargeout",
        },
        {
            title: "Planned Subcontractor",
            dataIndex: "plannedsubcontractor",
            key: "plannedsubcontractor",
        },
        {
            title: "Estimated Profit",
            dataIndex: "estimatedprofit",
            key: "estimatedprofit",
            render: (_, record) => {
                return (
                    <span style={{ color: parseFloat(record?.estimatedprofit?.replace('$', '')) < 0 ? 'red' : 'black', fontWeight: parseFloat(record?.estimatedprofit?.replace('$', '')) < 0 ? "bold" : "" }}>
                        {record?.estimatedprofit}
                    </span>
                );
            },
        },
        {
            title: "PFA(Planned fee Adjustment)",
            dataIndex: "pfa",
            key: "pfa",
        },
        {
            title: "Profit - after PFA",
            dataIndex: "profitpfa",
            key: "profitpfa",
        },
        {
            title: "Status",
            dataIndex: "pfastatus",
            key: "pfastatus",
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (_, record) => (
                <div className="d-flex gap-2">
                    <Icon name="tick" style={(record?.approvalstatus === "Approved" || record?.approvalstatus === "Declined") ? disabledIcon : iconStyle} onClick={() => record?.approvalstatus === "Approved" || record?.approvalstatus === "Declined" ? null : handleApprove(record, "Approved")}></Icon>
                    <Icon name="wrong" style={(record?.approvalstatus === "Approved" || record?.approvalstatus === "Declined") ? disabledIcon : iconStyle} onClick={() => record?.approvalstatus === "Approved" || record?.approvalstatus === "Declined" ? null : handleApprove(record, "Declined")}></Icon>
                </div>
            )
        },
    ];

    const filteredData = selectedStatus
        ? PFAApprovalData?.filter(item => item?.pfastatus === selectedStatus)
        : PFAApprovalData;

    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={4}>
                    <Form.Item name="approvalstatus">
                        <Select
                            placeholder="Status"
                            value={selectedStatus}
                            onChange={setSelectedStatus}
                            allowClear
                        >
                            <Select.Option value="Not Approved">Not Approved</Select.Option>
                            <Select.Option value="Approved">Approved</Select.Option>
                            <Select.Option value="Declined">Declined</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Spin spinning={isLoading || isUpdating}>
                <Table
                    columns={columns}
                    rowClassName={(record, index) =>
                        index % 2 === 0 ? "" : "non-white"
                    }
                    rowKey="id"
                    dataSource={filteredData}
                    pagination={false}
                />
            </Spin>
        </>

    );
}

export default PFAApproval;
