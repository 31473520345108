import React, { useEffect, useMemo, useRef, useState } from "react";
import { Row, Col, Spin, Modal, message, Form, Select } from "antd";
import Button from "atoms/Button";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getClientid,
  getAllDepartmentList,
  getLocations,
  getMenuList,
  getallRoleList,
} from "redux/selectors";
import { setAllRoleList, setRoleList } from "redux/actions";
import COLORS from "common/Colors";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import { CSVLink } from "react-csv";
import AddRole from "organisms/AddRole";
import EditRole from "organisms/EditRole";
import { reduce } from "lodash";
import Table from "atoms/Table";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import queryKeys from "common/queryKey";
import { useScrollFetch } from "hooks/useScrollFetch";
import { useFetch } from "hooks/useFetch";
const { Option } = Select;

const StyledTable = styled(Table)`
  /* padding-top: 20px; */
  table {
    table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
  }
  .ant-table-cell {
    padding: 10px;
    height: 0px !important;
    border: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: none;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

function Roles({
  clientId,
  AllDepartmentList,
  locationList,
  menuList,
  userRoleId,
  saveAllRoleList,
  allRoleList,
}) {
  const [addRole, toggleAddRole] = useState(false);
  const [editRole, toggleEditRole] = useState(false);
  const [RoleDetails, setRoleDetails] = useState({});
  const [roleList, setRoleList] = useState(allRoleList);
  const [deleteRole, setDeleteRole] = useState(false);
  const [roleid, setRoleid] = useState("");
  const [displayLocationLoader, setDisplayLocationLoader] = useState(false);
  const [roleCSVlist, setroleCSVlist] = useState([]);
  const [btnLoader, togglebtnLoader] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const csvLinkEl = useRef();

  const CSVHeaders = [
    { label: "Role name", key: "roleName" },
    { label: "Department", key: "department" },
    { label: "Country", key: "country" },
    { label: "Location", key: "locationId" },
    { label: "Created On", key: "insertedAt" },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "roleName",
      key: "role_name",
      width: "10%",
      sorter: (a, b) => a.roleName.localeCompare(b.roleName),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: "20%",
      className: "normal-column",
      render: (department) => {
        if (department) {
          const departmentIds = Array.isArray(department)
            ? department
            : [department];
          const departmentNames = departmentIds.map((id) => {
            const matchingDepartment = AllDepartmentList.find(
              (item) => item.department_id === id
            );
            return matchingDepartment ? matchingDepartment.department_name : "";
          });
          return departmentNames.join(", ");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Country",
      dataIndex: "locationId",
      key: "locationId",
      width: "20%",
      className: "normal-column",
      render: (locationId) => {
        if (locationId) {
          const locationIdIds = Array.isArray(locationId)
            ? locationId
            : [locationId];
          const locationNames = locationIdIds.map((id) => {
            const matchinglocationId = locationList.find(
              (item) => item.location_id === id
            );
            return matchinglocationId ? matchinglocationId.country : "";
          });
          return locationNames.join(", ");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      width: "20%",
      className: "normal-column",
      render: (locationId) => {
        if (locationId) {
          const locationIdIds = Array.isArray(locationId)
            ? locationId
            : [locationId];
          const locationNames = locationIdIds.map((id) => {
            const matchinglocationId = locationList.find(
              (item) => item.location_id === id
            );
            return matchinglocationId ? matchinglocationId.location_name : "";
          });
          return locationNames.join(", ");
        } else {
          return "-";
        }
      },
    },
    // {
    //   title: "Created On",
    //   dataIndex: "insertedAt",
    //   key: "insertedAt",
    //   width: "15%",
    //   align: "center",
    //   render: (_, record) => {
    //     return moment(record?.insertedAt).format("DD-MM-yyyy");
    //   },
    // },
  ];

  const [form] = Form.useForm();

  useEffect(() => {
    refetch();
  }, [setRoleList]);

  useEffect(() => {
    setRoleList(allRoleList);
  }, [allRoleList]);

  const getFilteredRoleList = () => {
    const department = form.getFieldValue("department");
    const country = form.getFieldValue("country");

    if (!department && !country) {
      return dataList;
    } else {
      return dataList.filter((role) => {
        const departmentMatch =
          department && department.length > 0
            ? department.some((d) => role.department.includes(d))
            : true;

        const countryMatch =
          country && country.length > 0
            ? country.some((c) => role.locationId.includes(c))
            : true;

        return departmentMatch && countryMatch;
      });
    }
  };

  const { getRolesList, getPermissonNameList, getPermissionByName } = queryKeys;

  const { key, url } = getRolesList(clientId);

  const { data, lastElementRef, isLoading, refetch, isReloading  } = useScrollFetch(
    key,
    {
      endPoint: url,
      queryParam: "lastEvaluatedKey",
      onFetchStart: () => setPageLoading(true),
      onFetchEnd: () => setPageLoading(false), 
    },
   
  );

  const dataList = useMemo(() => {
    if (data) {
      return data?.pages?.reduce((acc, page) => {
        if (page?.data === undefined) return acc;
        return [...acc, ...page?.data];
      }, []);
    } else {
      return []
    }

  }, [data]);


  const { key: permissonKey, url: permissionUrlByname } = getPermissionByName(clientId, form.getFieldValue("roleName"));


  const { data: permissionData, lastElementRef: permissionRef, isLoading: permissionLoaded  } = useScrollFetch(
    permissonKey,
    {
      endPoint: permissionUrlByname,
      queryParam: "lastEvaluatedKey",
    },
   
  );


  const permissonByName = useMemo(() => {
    if (permissionData) {
      return permissionData.pages.reduce((acc, page) => {
        console.log("page -->", page);
        if (page?.response?.data === undefined) return acc;
  
        const responseData = page.response.data;
        if (Array.isArray(responseData)) {
          return [...acc, ...responseData];
        } else if (typeof responseData === 'object') {
         
          return [...acc, responseData]; 
        }
  
        return acc;
      }, []);
    } else {
      return [];
    }
  }, [permissionData]);

  const { key :permissionNameKey, url: permissionUrl } = getPermissonNameList(clientId);

  const { data: permissionNameList } = useFetch(
    permissionNameKey,
    permissionUrl,
    {
      enabled: true,
    },
    {
      clientId: clientId
     }
  );

  
  const permissionList = permissionNameList?.response?.data;
  useEffect(()=>{
    saveAllRoleList(dataList);
  },[dataList]);



  const prepareCSV = async () => {
    togglebtnLoader(true);
    if (getFilteredRoleList().length > 0) {
      setroleCSVlist(
        reduce(
          getFilteredRoleList(),
          function (result, list) {
            const departmentIds = Array.isArray(list?.department)
              ? list?.department
              : [list?.department];
            const departmentNames = departmentIds.map((id) => {
              const matchingDepartment = AllDepartmentList.find(
                (item) => item.department_id === id
              );
              return matchingDepartment
                ? matchingDepartment.department_name
                : "";
            });

            const locationIdIds = Array.isArray(list?.locationId)
              ? list?.locationId
              : [list?.locationId];
            const locationNames = locationIdIds.map((id) => {
              const matchinglocationId = locationList.find(
                (item) => item.location_id === id
              );
              return matchinglocationId ? matchinglocationId.location_name : "";
            });
            const countryNames = locationIdIds.map((id) => {
              const matchinglocationId = locationList.find(
                (item) => item.location_id === id
              );
              return matchinglocationId ? matchinglocationId.country : "";
            });

            result.push({
              Name: list.roleName,
              Department: departmentNames?.join(", "),
              Country: countryNames?.join(", "),
              Location: locationNames?.join(", "),
              // "Created On": moment(list?.insertedAt).format("yyyy-MM-DD"),
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
    togglebtnLoader(false);
  };

  const deletedRole = () => {
    setDeleteRole(true);
  };

  const ViewRole = (RoleObject, isViewOnly = false) => {
    setRoleid(RoleObject.roleId);
    toggleEditRole(true);
    setRoleDetails({
      ...RoleObject,
      isViewOnly,
    });
  };

  const handleRoleFilters = () => {
  };

  const confirmdelete = async () => {
    setDisplayLocationLoader(true);
    const { code, Message } = await CALL_API(
      `owner-roles/${clientId}/${roleid}`,
      "delete",
      {}
    );
    if (code === STATUS_CODE.SUCCESS) {
      setDeleteRole(false);
      message.success(Message);
      toggleEditRole(false);
      refetch();
      setDisplayLocationLoader(false);
    }
  };

  const headerstyle = { fontSize: "20px", fontWeight: "bold" };
  return (
    <>
      {!addRole && !editRole && (
        <Row className="page-title">
          <Col sm={24} md={12} style={headerstyle}>
            List of Employee Permissions
          </Col>
          <Col
            sm={24}
            md={12}
            className="d-flex justify-content-end algin-items-center"
          >
            <ButtonOutlined onClick={prepareCSV} className="m-0">
              Export CSV file
            </ButtonOutlined>
            <CSVLink
              header={CSVHeaders}
              data={roleCSVlist}
              filename={"role-list.csv"}
              asyncOnClick={true}
              ref={csvLinkEl}
            />
            {/* {Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "roles" && subItem?.permission.create
                )
            ) && (
              <StyledButton
                type="primary"
                htmlType="submit"
                onClick={() => addRoleModal(true)}
              >
                +Add Role
              </StyledButton>
            )} */}
          </Col>
        </Row>
      )}

      {!addRole && !editRole && (
        <Form
          form={form}
          layout="vertical"
          className="mt-2"
          onValuesChange={handleRoleFilters}
        >
          <Row gutter={16}>
            <Col xs={24} sm={8}>
              <DropdownCheckBox
                name="roleName"
                label="Name"
                options={permissionList?.map((role) => ({
                  label: role.roleName,
                  value: role.roleId,
                }))}
                form={form}
              />
            </Col>

            <Col xs={24} sm={8}>
              <DropdownCheckBox
                name="department"
                label="Department"
                options={AllDepartmentList?.map((department) => ({
                  label: department.department_name,
                  value: department.department_id,
                }))}
                form={form}
              />
            </Col>

            <Col xs={24} sm={8}>
              <DropdownCheckBox
                name="country"
                label="Country"
                options={locationList?.map((location) => ({
                  label: location.country,
                  value: location.location_id,
                }))}
                form={form}
              />
            </Col>
          </Row>
        </Form>
      )}

      {!addRole && !editRole && (
        <Spin spinning={ pageLoading || isLoading || permissionLoaded }>
        <StyledTable
          className="mb-5"
          dataSource={permissonByName?.length > 0 ? permissonByName : getFilteredRoleList()}
          columns={columns}
          rowKey="role_id"
          pagination={false}
          showLoader={isLoading}
          loader= {isReloading}
          onRow={(record, index) => ({
            ref: index === dataList?.length - 1 ? lastElementRef : null,
            onClick: () => {
              ViewRole(record);
            },
          })}
          footer={!isLoading ? () => (<>
            {isReloading && !isLoading &&(
              <div style={{ textAlign: "center" }}>
            
              <Spin spinning={isReloading}/>
            </div>
            )}
            </>
          )
        : false}
          />
        </Spin>
      )}
      {addRole && (
        <AddRole
          isModalVisible={addRole}
          closeModal={() => toggleAddRole(false)}
          getRoleList={refetch}
          RoleDetails={RoleDetails}
        />
      )}

      {editRole && (
        <EditRole
          isModalVisible={editRole}
          closeModal={() => {
            toggleEditRole(false);
            form.resetFields();
          }}
          getRoleList={refetch}
          RoleDetails={RoleDetails}
          deleteRole={deletedRole}
          setPageLoading={setPageLoading}
          viewOnly={
            Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "roles" && subItem?.permission.edit
                )
            )
              ? false
              : true
          }
        />
      )}

      <StyleddeactivateModal
        title="Are you sure you want to deactivate this Role?"
        width={500}
        open={deleteRole}
        onCancel={() => setDeleteRole(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head">
          <Col>
            <StyledButton
              className="mx-2"
              type="primary"
              htmlType="submit"
              onClick={() => setDeleteRole(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col>
            <StyledButton
              className="mx-2"
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={confirmdelete}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
  clientId: getClientid(state),
  AllDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  menuList: getMenuList(state),
  allRoleList: getallRoleList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveRoleList: setRoleList,
      saveAllRoleList: setAllRoleList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(Roles);
