import React from "react";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getClientid,
  getRoleList,
  getUserRole,
  getMenuList,
} from "redux/selectors";
import styled from "@emotion/styled";
import CheckInCheckout from "molecules/CheckInCheckout";
import UserMonthlyReport from "molecules/UserMonthlyReport";
import UserCheckinReport from "molecules/UserCheckinReport";
import NonStaffDashboard from "molecules/NonStaffDashboard";
import UserLeaveReport from "molecules/UserLeaveReport";

const GridContainer = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  padding-top: 5px;
  grid-template-areas:
    "check-in-monthly user-checkin-report"
    "user-leave-report user-leave-report"; // Assign areas

  @media (max-width: 1024px) {
    // Adjust for medium screens
    grid-template-columns: repeat(auto-fill, 1fr);
    grid-template-areas:
      "check-in-monthly"
      "user-checkin-report"
      "user-leave-report";
  }
`;

const GridItem = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  &.check-in-monthly {
    grid-area: check-in-monthly;
  }
  &.user-checkin-report {
    grid-area: user-checkin-report;
  }
  &.user-leave-report {
    grid-area: user-leave-report;
  }
`;

const MyDashboard = ({ userRoleId }) => {
  return (
    <MyDashboardWrap>
      <GridContainer>
        <GridItem className="check-in-monthly">
          {/* <CheckInCheckout /> */}
          {/* <div className="mt-auto"> */}
            <UserMonthlyReport />
          {/* </div> */}
        </GridItem>
        <GridItem className="user-checkin-report">
          <UserCheckinReport />
        </GridItem>
        <GridItem className="user-leave-report">
          <UserLeaveReport />
        </GridItem>
      </GridContainer>
      {userRoleId == 1 && <NonStaffDashboard />}
    </MyDashboardWrap>
  );
};

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
  userRoleType: getUserRole(state),
  menuList: getMenuList(state),
});

export default connect(mapStateToProps, null)(MyDashboard);

const MyDashboardWrap = styled.div``;
