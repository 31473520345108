import React, { useEffect, useState } from "react";
import { Bar } from "@ant-design/plots";
import styled from "@emotion/styled";
import { Spin } from "antd";

// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getClientid,
  getLeaveCategoryList,
  getAllDepartmentList,
  getUserList,
  getIsCheckedIn,
  getUserInfo
} from "redux/selectors";
import DepartmentAttendanceReport from "./DepartmentAttendanceReport";
import AttendanceStaffList from "./AttendanceStaffList";
import { CALL_API } from "common/API";
import { concat, isNaN, map, max, size, includes, some } from "lodash";

const StyledChartContainer = styled.div`
  padding: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 8px !important;
  height: 100%;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
    font-weight: 600;
  }
`;

function AttendanceBarChart({
  userRoleId,
  selectedDepartmentId,
  ClientId,
  leaveCategoryList,
  allDepartmentList,
  userList,
  isCheckedIn,
  isHrDashboard = false,
  myTeam = false,
  getSubordinates,
  userInfo
})
{
  const [
    displayDepartmentAttendanceReportModal,
    toggleDepartmentAttendanceReportModal,
  ] = useState(false);
  const [displayAttendanceStaffModal, toggleDisplayAttendanceStaffModal] =
    useState(false);
  const [reportType, setReportType] = useState("");
  const [reportName, setReportName] = useState("");
  const [reportList, setReportList] = useState([]);
  const [reportColor, setReportColor] = useState("");
  const [attendanceReportTitle, setAttendanceReportTitle] = useState("");
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [absentList, setAbsentList] = useState([]);
  const [lateListData, setLateListData] = useState([]);
  const [onTimeList, setOnTimeList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [reportData, setReportData] = useState([
    {
      name: "onTime",
      type: "Present",
      numbers: 0,
      list: [],
    },
    {
      name: "Absent",
      type: "Absent",
      numbers: 0,
      list: [],
    },
    // {
    //   name:'Post cut Off',
    //   type: 'Post cut Off',
    //   numbers: 0,
    //   list: [],
    // },
    {
      name: "Late",
      type: "Present",
      numbers: 0,
      list: [],
    },
    {
      name: "Leave",
      type: "leave",
      numbers: 0,
      list: [],
    },
    // {
    //   name:'Others',
    //   type: 'Present',
    //   numbers: 0,
    //   list: [],
    // }
  ]);
  const config = {
    isStack: true,
    data: reportData,
    dodgePadding: 0.2,
    intervalPadding: 10,
    height: 400,
    xField: "numbers",
    yField: "type",
    seriesField: "name",
    legend: false,
    color: ({ name }) => {
      if (name === "Absent") {
        return "orange";
      } else if (name === "onTime") {
        return "#0565f5";
      } else if (name === "Late") {
        return "#5d9af5";
      } else if (name === "Leave") {
        return "#bd34eb";
      } else {
        return "#f5e0ff";
      }
    },
    theme: {
      maxColumnWidth: 50,
      minColumnWidth: 50,
    },
    // legend:{
    //   layout: 'vertical',
    //   position: 'right'
    // },
    label: {
      position: "middle",
      content: "",
    },
    columnStyle: {
      // stroke: COLORS.BLACK,
      // lineWidth: 1,
      cursor: "pointer",
    },
    xAxis: {
      max: isNaN(max(map(reportData, "numbers")))
        ? 100
        : max(map(reportData, "numbers")) + 15,
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };

  const annualLeaveCategoryId = leaveCategoryList.find(
    (item) => item.leave_category_short_name === "annualleave"
  );
  const sickLeaveCategoryId = leaveCategoryList.find(
    (item) => item.leave_category_short_name === "sickleave"
  );

  useEffect(() => {
    if (
      (userRoleId !== 1 && selectedDepartmentId !== 0) ||
      userRoleId === 1 ||
      userRoleId === 6
    ) {
      getReportData();
    }
  }, []);

  const filteruser = userList?.filter(
    (item) => item?.userid === userInfo.userid
  );

  const getReportData = async () => {
    toggleReportLoader(true);
    if (ClientId) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(100);

      // Generate the team tree
      let subOrdinatesList = [""];

      if (myTeam) {
        const subOrdinates = [...new Set(getSubordinates(myTeam))]
        subOrdinatesList = subOrdinates?.length > 0 ? subOrdinates : [""];
      } else if (isHrDashboard) {
        subOrdinatesList = [""];
      }

      const { todayAttendenceDetails } = await CALL_API(
        `attendance-service/${ClientId}`,
        "post",
        {
          // departmentId:
          //   userRoleId != 1 && userRoleId != 6 ? selectedDepartmentId : [""],
          officesupervisorid: subOrdinatesList,
        }
      );
      let absentList = [];
      let ontimeList = [];
      let lateList = [];
      let leaveList = [];
      todayAttendenceDetails?.forEach((value) => {
        const userLeaveList = value?.leaveCount
          ?.filter((item) => {
            const findUser = userList?.find((user) => {
              return user.userid === item?.userid;
            });
            if(filteruser[0].OfficeSupervisorId === "none"){
              const userDetails = userList?.find((user) => {
                return user.userid === item?.userid;
              });
              return userDetails?.statusid === "1";
            } else {
              const userDetails = some(findUser?.managingoffice, value => includes(filteruser[0]?.managingoffice, value));
              return userDetails?.statusid === "1";
            }
          })
          .map((item) => {
            const department = allDepartmentList?.find((dept) => {
              return dept?.department_id == item?.department_id;
            });
            const leaveCategory = leaveCategoryList?.find((leave) => {
              return leave.leave_category_id === item?.leave_category_id;
            });
            const username = userList?.find((user) => {
              return user.userid == item?.userid;
            });
            return {
              ...item,
              departmentName: department?.department_name,
              leave_category_name: leaveCategory?.leave_category_name,
              name: username?.fullname,
            };
          });
        absentList = concat(absentList, value.absent?.filter((item) => {
          const findUser = userList?.find((user) => {
            return user.userid === item?.userid;
          });

          

          if(filteruser[0].OfficeSupervisorId === "none"){
            const userDetails = userList?.find((user) => {
              return user.userid === item?.userid;
            });
            return userDetails;
          } else {
            const userDetails = some(findUser?.managingoffice, value => includes(filteruser[0]?.managingoffice, value));
            return userDetails;
          }
          
        }));

        const absentLists = absentList?.map((item)=>{
          const findUserid = userList?.find((user) => {
            return user.userid === item?.userid;
          });
          return {
            ...item,
            leveldepartmentname: findUserid.leveldepartmentname
          };
        })


        absentList = absentLists
        
        ontimeList = concat(ontimeList, value.onTime?.filter((item) => {
          const findUser = userList?.find((user) => {
            return user.userid === item?.userId;
          });
          if(filteruser[0].OfficeSupervisorId === "none"){
            const userDetails = userList?.find((user) => {
              return user.userid === item?.userId;
            });
            return userDetails;
          } else {
            const userDetails = some(findUser?.managingoffice, value => includes(filteruser[0]?.managingoffice, value));
            return userDetails;
          }
        }));
        lateList = concat(lateList, value.late?.filter((item) => {
          const findUser = userList?.find((user) => {
            return user.userid === item?.userId;
          });
          if(filteruser[0].OfficeSupervisorId === "none"){
            const userDetails = userList?.find((user) => {
              return user.userid === item?.userId;
            });
            return userDetails;
          } else {
            const userDetails = some(findUser?.managingoffice, value => includes(filteruser[0]?.managingoffice, value));
            return userDetails;
          }
        }));
        leaveList = concat(leaveList, userLeaveList);
      });

      let dupeReportData = [...reportData];
      // let otherList = []
      // let newList = []
      // let lateListFilter = []
      // // let otherList1 =filter(leaves, (e) => e.locationName === 'Other' )
      // // console.log("list other", otherList1)
      // ontimeList.map((list) => {
      //   // console.log("list", list.locationName)
      //   if(list.locationName === 'Other'){
      //     otherList.push(list)
      //   }
      //   else{
      //     newList.push(list)
      //   }
      // })
      // lateList.map((list) => {
      //   // console.log("list", list.locationName)
      //   if(list.locationName === 'Other'){
      //     otherList.push(list)
      //   }
      //   else{
      //     lateListFilter.push(list)
      //   }
      // })

      // console.log("other",otherList)
      // onTime.map()
      dupeReportData[0].numbers = size(ontimeList);
      dupeReportData[1].numbers = size(absentList);
      dupeReportData[2].numbers = size(lateList);
      dupeReportData[3].numbers = size(leaveList);

      // dupeReportData[3].numbers = size(leave)
      dupeReportData[0].list = ontimeList;
      dupeReportData[1].list = absentList;
      dupeReportData[2].list = lateList;
      dupeReportData[3].list = leaveList;
      // const leaveList = leaves
      // let sickCount = 0;
      // let sickLeaveList = [];
      // let annualCount = 0;
      // let annualLeaveList = [];
      // let otherCount = 0;
      // let otherLeaveList = [];
      // leaveList.map((list) => {
      //   if (list.leave_category_id === sickLeaveCategoryId?.leave_category_id) {
      //     sickCount++;
      //     sickLeaveList.push(list);
      //   } else if (
      //     list.leave_category_id === annualLeaveCategoryId?.leave_category_id
      //   ) {
      //     annualCount++;
      //     annualLeaveList.push(list);
      //   } else {
      //     otherCount++;
      //     otherLeaveList.push(list);
      //   }
      // });
      // dupeReportData[5].numbers = otherCount;
      // dupeReportData[5].list = otherLeaveList;
      // dupeReportData[4].numbers = sickCount;
      // dupeReportData[4].list = sickLeaveList;
      // dupeReportData[3].numbers = annualCount;
      // dupeReportData[3].list = annualLeaveList;
      setReportData(dupeReportData);
      toggleReportLoader(false);
      // dupeReportData[6].numbers = size(otherList)
      // dupeReportData[6].list = otherList
      // const groupedData = data.reduce((result, item) => {
      //   (result[item.name] = result[item.name] || []).push(item);
      //   return result;
      // }, {});

      // const groupByAbsentDeptId = groupedData?.undefined[0][selectedDepartmentId]?.absent?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByPresentDeptId = groupedData?.undefined[0][selectedDepartmentId]?.late?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByOnTimeDeptId = groupedData?.undefined[0][selectedDepartmentId]?.onTime?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByLateDeptId = groupedData?.undefined[0][selectedDepartmentId]?.leaves?.reduce((acc, user) => {
      //   const key = user?.departmentId;
      //   if (!acc[key]) {
      //     acc[key] = [];
      //   }
      //   acc[key].push(user);
      //   return acc;
      // }, {});

      // const groupByAbsentDeptId = absentList.map((e) => e.departmentId == hrSelectedDept)
      // console.log("---->", groupByAbsentDeptId)

      setAbsentList(absentList);
      setLateListData(lateList);
      setOnTimeList(ontimeList);
      setLeaveList(leaveList);
    }
  };

  return (
    <>
      <StyledChartContainer>
        <div className="name-title">Today's Overall Attendance</div>
        <Spin spinning={displayReportLoader}>
          <Bar
            {...config}
            onReady={(plot) => {
              plot.on("plot:click", (evt) => {
                if (evt.data && evt.data.data !== null) {
                  const {
                    data: {
                      data: { type, list, name },
                      color,
                    },
                  } = evt;
                  setReportList(list);
                  setReportType(type);
                  setReportName(name);
                  if (userRoleId !== "1") {
                  //   setAttendanceReportTitle(
                  //     name === "Leave" ? `${type}` : `${type}- ${name}`
                  //   );
                  //   toggleDepartmentAttendanceReportModal(true);
                    // setReportColor(color);
                    if (!isHrDashboard) {
                      setAttendanceReportTitle(`${type} staff`);
                      toggleDisplayAttendanceStaffModal(true);
                    } 
                    else {
                      setAttendanceReportTitle(
                        name === "Leave" ? `${type}` : `${type}- ${name}`
                      );
                      toggleDepartmentAttendanceReportModal(true);
                    }
                  }
                  // else if (includes(["2", "3"], userRoleId)) {
                  //   if (type === "Absent") {
                  //     setAttendanceReportTitle(`${type} staff`);
                  //   } else {
                  //     setAttendanceReportTitle(`${type}- ${name}`);
                  //   }
                  //   toggleDisplayAttendanceStaffModal(true);
                  // }
                }
              });
            }}
          />
        </Spin>
      </StyledChartContainer>
      <DepartmentAttendanceReport
        displayModal={displayDepartmentAttendanceReportModal}
        toggleModal={() => toggleDepartmentAttendanceReportModal(false)}
        reportType={reportType}
        reportColor={reportColor}
        list={reportList}
        name={reportName}
        lateListData={lateListData}
        onTimeList={onTimeList}
        leaveList={leaveList}
        absentList={absentList}
      />
      <AttendanceStaffList
        displayModal={displayAttendanceStaffModal}
        toggleModal={() => toggleDisplayAttendanceStaffModal(false)}
        title={attendanceReportTitle}
        reportType={reportType}
        list={reportList}
        name={reportName}
        isHrDashboard={isHrDashboard}
        userInfo={userInfo}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  allDepartmentList: getAllDepartmentList(state),
  userList: getUserList(state),
  isCheckedIn: getIsCheckedIn(state),
  userInfo: getUserInfo(state)
});

export default connect(mapStateToProps, null)(AttendanceBarChart);
