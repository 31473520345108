import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Form,
  Select,
  Tooltip,
  Modal,
  message,
  Button,
} from "antd";
import styled from "@emotion/styled";
import AttendanceBarChart from "molecules/AttendanceBarChart";
import LocationBasedPresentStaff from "molecules/LocationBasedPresentStaff";
import { CALL_API } from "common/API";
import { getColor, LEAVE_ICON, STATUS_CODE } from "common/Constants";
import { includes, concat, orderBy, some } from "lodash";
import COLORS from "common/Colors";
import moment from "moment";
import LeaveFormModel from "organisms/LeaveFormModel";
import ViewMore from "organisms/ViewMore";
import { EyeFilled, BellFilled } from "@ant-design/icons";
import { getMySqlDate } from "utils/Date";
import LeaveDonutChart from "molecules/LeaveDonutChart";
import DepartmentStaffLeaveBalance from "molecules/DepartmentStaffLeaveBalance";
import { getLoadingRoute } from "redux/selectors";
import { connect } from "react-redux";
const StyledRow = styled(Row)`
  padding-top: 20px;
`;

const StyledLeaveContainer = styled.div`
  padding: 12px 20px 20px 20px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 5px !important;
  padding-bottom: 25px;
  margin: 0px !important;
`;

const StyledChartContainer = styled.div`
  padding: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 5px !important;
  padding-bottom: 25px;
  margin: 0px !important;
`;

const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #141414;
  margin-right: auto;
`;
const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  tr.ant-table-row:hover > td {
    background: hsla(0, 0%, 90%, 0.5);
    cursor: pointer;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledLoader = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

function HrDashboard({
  ClientId,
  employeeid,
  selectedDepartmentId,
  userRoleId,
  departmentList,
  roleList,
  AllDepartmentList,
  Menulist,
  leaveCategoryList,
  userList,
  userInfo,
  loadingRoute,
  tabId,
}) {
  const [leaveList, setLeaveList] = useState([]);
  const [leaveCatgeory, setLeaveCatgeory] = useState([]);
  const [displayTaskLoader, setDisplayTaskLoader] = useState(false);
  const [displayLeaveModal, toggleDisplayLeaveModal] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState({});
  const [leaveID, setLeaveID] = useState();
  const [havingCancel, sethavingCancel] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leaveSummary, setLeaveSummary] = useState({});
  const [displayLeaveReportLoader, toggleLeaveReportLoader] = useState(false);
  const [staffDepartmentLeaveModal, toggleStaffDepartmentLeaveModal] =
    useState(false);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState("");
  const [selectedLeaveType, setSelectedLeaveType] = useState("");

  const containerRef = useRef(null);

  useEffect(() => {
    let leaveStatistics = {};

    leaveCategoryList?.forEach((category) => {
      leaveStatistics[category.leaveCategoryId] = {
        available: 0,
        consumed: 0,
      };
    });

    const approvedLeaves = leaveCatgeory.filter((item) => {
      const userid = item.userid;
      const user = userList.find((user) => user.userid === userid);
      const isActiveUser = user?.statusid === "1";

      return isActiveUser && item.status_id === "5";
    });

    approvedLeaves.forEach((leave) => {
      const leaveCategoryName = leave?.leave_category_id;
      leaveStatistics[leaveCategoryName].consumed += parseFloat(
        leave.number_of_days
      );
    });

    const filteruser = userList?.filter(
      (item) => item?.userid === userInfo.userid
    );

    const updatedLeaveData = leaveCategoryList?.map((category) => {
      const categoryName = category.leaveCategoryName;
      const activeUserList = userList.filter((user) => user.statusid === "1");
      let countryBasedUserList = activeUserList?.filter((item) => {
        const findUser = userList?.find((user) => {
          return user.userid === item?.userid;
        });
        const userDetails = some(findUser?.managingoffice, (value) =>
          includes(filteruser[0]?.managingoffice, value)
        );
        return userDetails;
      });
      let allLeaveData =
        filteruser[0]?.OfficeSupervisorId === "none"
          ? activeUserList
          : countryBasedUserList;

      const userCount = allLeaveData?.filter((user) => {
        const eligibleGender = category.eligible.toLowerCase();
        if (eligibleGender === "both") {
          return true;
        } else {
          const userGender = user.gender.toLowerCase();
          return eligibleGender === userGender;
        }
      }).length;

      // if (category.leaveCategoryName === "Annual Leave") {
      //   console.log("userCount  userCount--->", userCount);
      //   console.log("category--->", category);
      //   console.log("leaveStatistics--->", leaveStatistics);
      //   console.log(
      //     userCount * parseInt(category.numberOfLeaves) -
      //       leaveStatistics[categoryName]?.consumed
      //   );
      // }

      return {
        categoryName: categoryName,
        id: category.leaveCategoryId,
        data: [
          {
            type: "available",
            value:
              // userCount * parseInt(category.numberOfLeaves) || 0,
              userCount * parseInt(category.numberOfLeaves) -
                leaveStatistics[category.leaveCategoryId]?.consumed || 0,
          },
          {
            type: "consumed",
            value: leaveStatistics[category.leaveCategoryId]?.consumed || 0,
          },
        ],
      };
    });

    setLeaveSummary(updatedLeaveData);
  }, [leaveCatgeory, userList, leaveCategoryList]);

  const openDetailWindow = (name, id) => {
    setSelectedLeaveTypeId(id);
    setSelectedLeaveType(`${name} Report`);
    toggleStaffDepartmentLeaveModal(true);
  };

  useEffect(() => {
    if (loadingRoute || tabId) {
      getLeaveList();
      getLeaveListCategories();
    }
  }, [loadingRoute, tabId]);

  function getDates() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const startDate = new Date(currentYear - 2, 0, 1);
    const endDate = new Date(currentYear + 1, 11, 31);

    return {
      startDate,
      endDate,
    };
  }

  const dates = getDates();

  const ApproveOk = async (reason) => {
    return new Promise(async (resolve) => {
      await approveRejectCancelLeave2(5, reason, havingCancel);
      resolve();
    }).catch((err) => console.log("[ApproveOk] Error-->", err));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setLoading(true);
    ApproveOk();
  };

  const getLeaveList = async () => {
    return new Promise(async (resolve, reject) => {
      setLeaveList([]);

      setDisplayTaskLoader(true);
      const { code, leaves = [] } = await CALL_API(
        `leave-list/${ClientId}`,
        "post",
        {
          status_id: "4",
          // department_id: selectedDepartmentId,
          officesupervisorid: [""],
          start_date: dates.startDate,
          end_date: dates.endDate,
        }
      );

      setDisplayTaskLoader(false);
      if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
        setLeaveList(leaves.reverse());

        resolve(leaves);
      } else {
        reject([]);
      }
    });
  };

  const getLeaveListCategories = async () => {
    return new Promise(async (resolve, reject) => {
      setLeaveCatgeory([]);

      if (selectedDepartmentId) {
        toggleLeaveReportLoader(true);
        const { code, response = [] } = await CALL_API(
          `employee/allleave`,
          "post",
          {
            clientId: ClientId,
            year: new Date().getFullYear().toString(),
          }
        );

        toggleLeaveReportLoader(false);
        if (
          includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)
        ) {
          setLeaveCatgeory(response);

          resolve(response);
        } else {
          reject([]);
        }
      } else {
        reject([]);
      }
    });
  };

  const approveRejectCancelLeave2 = async (
    statusId,
    declined_reason = "",
    is_leave_cancel_request
  ) => {
    return new Promise(async (resolve) => {
      const { code } = await CALL_API(
        `approve-reject-cancel-leave/${ClientId}`,
        "post",
        {
          departmentId: selectedDepartmentId,
          user_leave_id: leaveID,
          status_id: statusId.toString(),
          declined_reason,
          is_cancel_request: is_leave_cancel_request,
          start_date: leaveDetails?.new_start_date
            ? getMySqlDate(leaveDetails?.new_start_date)
            : getMySqlDate(leaveDetails?.start_date),
          end_date: leaveDetails?.new_end_date
            ? getMySqlDate(leaveDetails?.new_end_date)
            : getMySqlDate(leaveDetails?.end_date),
        }
      );

      if (code === STATUS_CODE.SUCCESS) {
        setLoading(false);
        setIsModalOpen(false);
        message.success(`Leave has been approved`);
        getLeaveList();
      } else {
        message.error(`Something went wrong. Please try again.`);
      }
      resolve();
    });
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 10,
    showSizeChanger: false,
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      width: "10%",
    },
    {
      title: "Employee ID",
      dataIndex: "employeeid",
      key: "employeeid",
      width: "7%",
    },
    // {
    //   title: "Department",
    //   dataIndex: "department_name",
    //   key: "department_name",
    //   width: "10%",
    // },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_start_date === "null" ||
          record.new_start_date === "" ||
          record.new_start_date === null
        ) {
          const dateFormat = moment(record.start_date).format("DD MMM YYYY");
          const getdayAlone = moment(record.start_date).format("dddd");
          return `${dateFormat} - ${getdayAlone}`;
        } else {
          const dateFormat = moment(record.new_start_date).format(
            "DD MMM YYYY"
          );
          const getdayAlone = moment(record.new_start_date).format("dddd");

          return `${dateFormat} - ${getdayAlone}`;
        }
      },
    },

    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_end_date === "null" ||
          record.new_end_date === "" ||
          record.new_end_date === null
        ) {
          const dateFormat = moment(record.end_date).format("DD MMM YYYY");
          const getdayAlone = moment(record.end_date).format("dddd");

          return `${dateFormat} - ${getdayAlone}`;
        } else {
          const dateFormat = moment(record.new_end_date).format("DD MMM YYYY");
          const getdayAlone = moment(record.new_end_date).format("dddd");

          return `${dateFormat} - ${getdayAlone}`;
        }
      },
    },
    {
      title: "Days",
      dataIndex: "number_of_days",
      key: "number_of_days",
      width: "7%",
      render: (_, record) => {
        if (
          record.new_number_of_days === null ||
          record.new_number_of_days === "" ||
          record.new_number_of_days === "null"
        ) {
          return record.number_of_days;
        } else {
          return record.new_number_of_days;
        }
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      width: "10%",
    },
    {
      title: "Amended Leave",
      dataIndex: "having_cancel_request",
      key: "having_cancel_request",
      width: "10%",
      render: (having_cancel_request) => {
        if (having_cancel_request === "1") {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    {
      title: "Applied On",
      dataIndex: "applied_date",
      key: "applied_date",
      width: "10%",
      render: (_, record) => {
        const dateFormat = moment(record.created_at).format("DD MMM YYYY");
        return dateFormat;
      },
    },
  ];

  // if (
  //   Menulist?.find((item) => item?.key === "approvals" && item?.permission.edit)
  // ) {
  //   columns.push({
  //     title: "Action",
  //     dataIndex: "address",
  //     key: "address",
  //     width: "10%",
  //     render: (_, record) => (
  //       <>
  //         <Tooltip title="View Leave">
  //           <EyeFilled
  //             className="action-button"
  //             onClick={() => viewLeave(record, true)}
  //           />
  //         </Tooltip>
  //         {(userRoleId === 1 || userRoleId === 6) && (
  //           <Tooltip title="Remind HOD & Assistant HOD" placement="left">
  //             <BellFilled
  //               className="action-button"
  //               onClick={() => remindSuperior(record)}
  //             />
  //           </Tooltip>
  //         )}
  //       </>
  //     ),
  //   });
  // }

  const filteruser = userList?.filter(
    (item) => item?.userid === userInfo.userid
  );

  const filterleavereport = leaveList.filter(
    // (item) => item.status_id === "4" && item.department_id == selectedDepartment
    (item) => {
      const findUser = userList?.find((user) => {
        return user.userid === item?.userid;
      });
      if (filteruser[0]?.OfficeSupervisorId === "none") {
        return item;
      } else {
        const isSameCountry = some(findUser?.managingoffice, (value) =>
          includes(filteruser[0]?.managingoffice, value)
        );
        return isSameCountry;
      }
    }
  );

  const viewLeave = (leaveObject, isViewOnly = false) => {
    if (
      Menulist?.find(
        (item) => item?.key === "approvals" && item?.permission.edit
      )
    ) {
      toggleDisplayLeaveModal(true);
      setLeaveDetails({
        ...leaveObject,
        isViewOnly,
      });
    }
  };

  const remindSuperior = (leaveRecord) => {
    const { confirm } = Modal;
    confirm({
      icon: "",
      closable: true,
      content:
        "Do you want to send reminder notification to HOD & Assistant HOD?",
      async onOk() {
        const { user_leave_id, user_id } = leaveRecord;
        const { code } = await CALL_API(
          "leave-notification-to-hod-assistant-hod",
          "post",
          {
            user_leave_id,
            user_id,
          }
        );
        if (code === STATUS_CODE.SUCCESS) {
          message.success(`Notification has been sent.`);
        } else {
          message.error(`Something went wrong. Please try again.`);
        }
      },
      onCancel() {},
    });
  };

  if (loadingRoute) {
    return (
      <StyledLoader>
        <div>
          <Spin size="large" />
        </div>
      </StyledLoader>
    );
  }

  return (
    <>
      <>
        <StyledRow gutter={16}>
          <Col md={24} sm={24} xs={24} xl={12}>
            <AttendanceBarChart isHrDashboard={true} employeeid={employeeid} />
          </Col>

          <Col md={24} sm={24} xs={24} xl={12}>
            <LocationBasedPresentStaff
              employeeid={employeeid}
              isHrDashboard={true}
            />
          </Col>

          {Menulist?.find(
            (item) => item?.key === "approvals" && item?.permission.view
          ) && (
            <Col
              md={24}
              sm={24}
              xs={24}
              xl={24}
              ref={containerRef}
              className="mt-2"
            >
              <StyledChartContainer>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <StyledTitle>
                    Waiting for Approval{" "}
                    {filterleavereport?.length > 0
                      ? `(${filterleavereport?.length})`
                      : null}
                  </StyledTitle>
                </div>
                <Spin spinning={displayTaskLoader}>
                  <StyledTable
                    dataSource={filterleavereport}
                    columns={columns}
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "" : "non-white"
                    }
                    onRow={(record) => {
                      return {
                        onClick: () => viewLeave(record, true),
                      };
                    }}
                    rowKey="user_leave_id"
                    pagination={
                      filterleavereport.length > 10 ? paginationOptions : false
                    }
                  />
                </Spin>
                <LeaveFormModel
                  isModalVisible={displayLeaveModal}
                  closeModal={() => toggleDisplayLeaveModal(false)}
                  userRoleId={userRoleId}
                  leaveDetails={leaveDetails}
                  toggleDisplayLeaveModal={toggleDisplayLeaveModal}
                  getLeaveList={getLeaveList}
                  setIsModalOpen={setIsModalOpen}
                  setLeaveID={setLeaveID}
                  sethavingCancel={sethavingCancel}
                  ClientId={ClientId}
                />
              </StyledChartContainer>
            </Col>
          )}

          <Col md={24} sm={24} xs={24} xl={24} className="mt-2">
            <Spin spinning={displayLeaveReportLoader}>
              <StyledLeaveContainer>
                <StyledTitle className="mb-2">Overall Leave Report</StyledTitle>
                <Row
                  gutter={{
                    xs: 8,
                    sm: 12,
                    md: 16,
                  }}
                >
                  {Array.isArray(leaveSummary) &&
                    leaveSummary.map((value, index) => (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={8}
                        key={value.categoryName}
                        onClick={() =>
                          openDetailWindow(value.categoryName, value.id)
                        }
                      >
                        <LeaveDonutChart
                          iconName={LEAVE_ICON[7]}
                          title={value.categoryName}
                          data={value.data}
                          color={getColor(index)}
                        />
                      </Col>
                    ))}
                </Row>
              </StyledLeaveContainer>
            </Spin>
          </Col>
        </StyledRow>
      </>
      <Modal
        title="Approve leave"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Yes
          </Button>,
        ]}
      >
        <p>Are you sure you want to approve leave?</p>
      </Modal>

      <DepartmentStaffLeaveBalance
        displayModal={staffDepartmentLeaveModal}
        toggleModal={() => toggleStaffDepartmentLeaveModal(false)}
        leaveTypeId={selectedLeaveTypeId}
        selectedDepartmentId={selectedDepartmentId}
        leaveName={selectedLeaveType}
        leaveList={leaveList}
        leaveCategoryList={leaveCategoryList}
        leaveCatgeory={leaveCatgeory}
        all={true}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  loadingRoute: getLoadingRoute(state),
});

export default connect(mapStateToProps, null)(HrDashboard);
