import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Row, Col, DatePicker, Select } from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import { momentDate } from "utils/Date";
import { NON_EMPTY_REGEX, NON_CHARACTER, VALIDATE_CHARACTER, CountryCompany, VALIDATE_SYMBOL_CHARACTER } from "common/Constants";

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  margin-top: 20px;
  font-weight: bold;
`;
const Styleddiv = styled.div`
  .ant-form-item-label > label {
    font-weight: bold;
  }
`;

const BasicInformation = ({ step, setStep, title, setValues, values, alldatas }) => {
  const [form] = Form.useForm();
  const containerRef = useRef(null);

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const onFinish = (values) => {
    setValues(values);
    setStep(step + 1);
  };

  useEffect(() => {
    form.setFieldsValue(values);
  }, [values])
  const filteredCountryCompany = CountryCompany.filter(item => item.country !== "Other")
  const filterlocation = CountryCompany?.filter((item)=>item.country === form.getFieldValue('country'))

  useEffect(() => {
    form.setFieldsValue({
      name: filterlocation[0]?.location,
    });
  }, [form.getFieldValue('country')]);

  return (
    <Styleddiv>
      <Form
        form={form}
        name="AddDepartment"
        layout="vertical"
        initialValues={{
          name: "",
          country: "",
          latitude: "",
          longtitude: "",
          start_date: momentDate(new Date()),
          end_date: "",
        }}
        onFinish={onFinish}
      >
        {title !== "Add Location" && (
        <Form.Item
          label={
            title === "holiday"
                ? "Holiday Name"
                : title === "break"
                  ? "Break Name"
                  : "Team Name"
          }
          name="name"
          rules={[
            {
              required: true,
              message:
                 title === "holiday"
                    ? "Holiday name is required"
                    : title === "break"
                      ? "Break name is required"
                      : "Team Name is required",
            },
            {
              validator: (rule, value) => {
                if (value && !VALIDATE_SYMBOL_CHARACTER.test(value)) {
                  return Promise.reject(
                    title === "Add Location"
                      ? "Location Name should not contain Numbers"
                      : title === "holiday"
                        ? "Holiday Name should not contain Numbers"
                        : title === "break"
                          ? "Break Name should not contain Numbers"
                          : "Team Name should not contain Numbers"
                  );
                }
                return Promise.resolve();
              },              
            },
            {
              validator: (rule, value) => {
                if (title === "holiday") {
                  if (alldatas.some((data) => data.holiday_name === value)) {
                    return Promise.reject("Holiday already exists");
                  }
                } else if (title === "break") {
                  let trimmedValue = value.trim();
                  if (
                    alldatas.some(
                      (data) => data.breakName.trim() === trimmedValue
                    )
                  ) {
                    return Promise.reject("Break already exists");
                  }
                } else if (title === "Add Location") {
                  if (alldatas.some((data) => data.location_name === value)) {
                    return Promise.reject("Location already exists");
                  }
                } else if (
                  alldatas.some((data) => data.department_name === value)
                ) {
                  return Promise.reject("Department already exists");
                }
                return Promise.resolve();
              },
            }
          ]}
        >
          <Input
            placeholder={
              title === "holiday"
                  ? "Holiday Name"
                  : title === "break"
                    ? "Break Name"
                    : "Team Name"
            }
            maxLength={75}
          />
        </Form.Item>
        )}
        {title === "Add Location" && (
          <>
          <Col xs={24} sm={24} md={24} ref={containerRef}>
                <Form.Item
                    name="country"
                    label="Country/ Company"
                    rules={[
                      { required: true, message: "Please select Country/ Company" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                    >
                      {filteredCountryCompany?.map((value, index) => (
                        <Select.Option
                          key={index}
                          value={value.country}
                          label={value.country}
                        >
                          {value.country}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                

                <Col xs={24} sm={24} md={24} ref={containerRef}>
                <Form.Item
                    name="name"
                    label="Location"
                    rules={[
                      { required: true, message: "Please select Location" },
                    ]}
                    disabled={!form.getFieldValue('country')}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                    >
                      {filterlocation?.map((value, index) => (
                        <Select.Option
                          key={index}
                          value={value.location}
                          label={value.location}
                        >
                          {value.location}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
            <Form.Item
              label="Latitude"
              name="location_latitude"
              rules={[
                {
                  required: true,
                  pattern: NON_CHARACTER,
                  message: "Latitude is required",
                },
              ]}
            >
              <Input placeholder="Enter latitude" maxLength={20} />
            </Form.Item>
            <Form.Item
              label="Longitude"
              name="location_longitude"
              rules={[
                {
                  required: true,
                  pattern: NON_CHARACTER,
                  message: "Longitude is required",
                },
              ]}
            >
              <Input placeholder="Enter longitude" maxLength={20} />
            </Form.Item>
          </>
        )}

        {title === "holiday" && (
          <>
            <Row>
              <Col xs={24} xl={24} ref={containerRef}>
                <Form.Item
                  label="Start date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Start Date is required",
                    },
                    () => ({
                      validator(rule, value) {
                        const date = moment(value);
                        return date.isValid()
                          ? Promise.resolve()
                          : Promise.reject();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={validateDateRange}
                    placeholder="Select start date"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} ref={containerRef}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.start_date !== currentValues.start_date
                  }
                >
                  {() => (
                    <Form.Item
                      label="End date"
                      name="end_date"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "End Date is required",
                        },
                        () => ({
                          validator(rule, value) {
                            const date = moment(value);
                            return date.isValid()
                              ? Promise.resolve()
                              : Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        format="DD-MM-YYYY"
                        placeholder="Select end date"
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <div className="text-end w-100">
          <StyledButton type="primary" htmlType="submit">
            Next
          </StyledButton>
        </div>
      </Form>
    </Styleddiv>
  );
};

export default BasicInformation;
