import TabsList from "molecules/TabsList";
import React, { useMemo, useState } from "react";
import AddNewEngagement from "../AddNewEngagement";
import ResourcePlan from "./ResourcePlan";
import TodoList from "./TodoList";
import { Skeleton } from "antd";
import Claims from "./Claims";
import SubContractor from "../SubContractor";
import Documents from "../Documents";
import ThirdParty from "../ThirdParty";
import { useDispatch, useSelector } from "react-redux";
import { getEngagementMainTab } from "redux/selectors";
import { setmainengagementtab } from "redux/actions";

const TabsView = ({ engagementData, Details }) => {
  const selectedMainTab = useSelector((state) =>
    getEngagementMainTab(state)
  );
  const [tabId, setTabId] = useState(selectedMainTab);
  const dispatch = useDispatch();

  const handleTabSelect = (id) => {
    setTabId(id);
    dispatch(setmainengagementtab(id));
  };

 
  const items = useMemo(
    () => [
      {
        id: "details",
        label: "Details",
        component: Details,
      },
      {
        id: "engagement-details",
        label: "Engagement Details",
        component: (
          <AddNewEngagement update={true} engagementData={engagementData} />
        ),
      },
      // {
      //   id: "to-do-list",
      //   label: "To do List",
      //   component: engagementData ? (
      //     <TodoList engagementData={engagementData} />
      //   ) : (
      //     <Skeleton />
      //   ),
      // },
      // {
      //   id: "resource-plan",
      //   label: "Resources Plan",
      //   component: <ResourcePlan engagementData={engagementData}/>,
      // },
      // {
      //   id: "claims",
      //   label: "Claims",
      //   component: <Claims engagementData={engagementData}/>,
      // },
      // {
      //   id: "sub-contract",
      //   label: "Subcontractors",
      //   component: <SubContractor engagementData={engagementData}/>,
      // },
      {
        id: "documents",
        label: "Documents",
        component: <Documents engagementData={engagementData} />,
      },
      // {
      //   id: "thirdparty",
      //   label: "Third Party",
      //   component: <ThirdParty engagementData={engagementData}/>,
      // }
    ],
    [engagementData]
  );

  return (
    <div>
      <TabsList items={items} handleTabSelect={handleTabSelect} tabId={tabId} />
    </div>
  );
};

export default TabsView;
