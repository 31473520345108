
import { SearchOutlined } from '@mui/icons-material';
import { Input, Menu, Dropdown } from 'antd';
import Table from 'atoms/Table';
import COLORS from 'common/Colors';
import React from 'react';
import styled from "styled-components";
import { find, get } from 'lodash';
import moment from 'moment';

function DisqualifiedCandidates({ data, jobList }) {
  const [loading, setLoading] = React.useState(false)

  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  let disqualifiedUsers = data?.filter((item) => item?.currentStatusName === "Disqualified")
  const filteredDataSource = disqualifiedUsers?.filter((item) =>
    item.firstName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: "Candidate Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, record) => {
        return record?.firstName + " " + record?.lastName
      }
    },
    {
      title: "Applied For",
      dataIndex: "jobId",
      key: "jobId",
      render: (_, record) => {
        const job = find(jobList, { 'jobId': record?.jobId })
        return job?.title
      }
    },
    {
        title: "Date Applied",
        dataIndex: "jobId",
        key: "jobId",
        render: (_, record) => {
          const job = find(jobList, { 'jobId': record?.jobId })
          return moment(job.createdAt).format("DD MMM YYYY")
        }
      },
      {
        title: "Disqualified Date",
        dataIndex: "disqualifiedDate",
        key: "disqualifiedDate",
        render: (_, record) => {
          return record.disqualifiedDate ? moment(record.disqualifiedDate).format("DD MMM YYYY") : "-"
        }
      },
  ];

  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: '18px' }
  const styledInput = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: '16px' }
  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div>
          <Input style={styledInput} prefix={<SearchOutlined style={styledIcon} />} placeholder='Search People' onChange={handleSearchChange} />
        </div>
      </div>

      <Table
        dataSource={filteredDataSource}
        columns={columns}
        pagination={false}
        loading={loading}
      />
    </div>
  )
}

export default DisqualifiedCandidates;
