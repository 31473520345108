import COLORS from "common/Colors";
import Styled from "styled-components";

const Wrapper = Styled.div`
    h6{
        cursor: pointer;
    }

    .label {
      font-weight: bold;
      font-size: 14px;
    }

    .card-container{
      padding: 10px;
      display: flex;
      gap:10px;
      flex-wrap: wrap;
      .cards{
        border: 1px solid #f1f2f4;
        border-radius: 10px;
        width: 180px;
        img{
            width: 100%;
        }
      }
   }
   .template{
    /* background: #f7fffd; */
    padding: 20px;
    h6{
      font-weight: bold;
      font-size: 18px;
    }
     span{
        cursor: pointer;
        font-weight: 500;
        margin-right: 20px;
        color: ${COLORS.PRIMARY}
     }
  
   }
  


`;

export const CardImage = Styled.div`
  /* background: ${(props) => props.backgroundColor || "#F0EBF8"}; */
  border-radius: 10px 10px 0px 0px;
  max-width: 150px;
  min-width:150px;
  small{
    font-size: 10px;
  }

  img {
    width: 100%;
  }
`;

export default Wrapper;
