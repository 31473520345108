import styled from "@emotion/styled";
import {
    Button,
    Col,
    DatePicker,
    Form,
    message,
    Modal,
    Row,
    Select
} from "antd";
import { CALL_API_UPDATED } from "common/API";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getUserList } from "redux/selectors";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    background: #f2f2f2 0% 0% no-repeat padding-box;
  }
  .ant-modal-title {
    font-weight: 500;
    font-size: 15px;
  }

  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-input {
    border-radius: 5px;
  }
  .ant-upload-select-picture-card {
    width: 100% !important;
    height: 40px !important;
  }
  .ant-upload-list-item-actions > a {
    display: none;
  }
`;

const StyledFooterDiv = styled.div`
  display: flex;
  justify-content: end;
`;

const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
`;

const { Option } = Select;
const AssignAsset = ({
  setShowRevoke,
  showRevoke,
  editData,
  getAssetsData,
}) => {
  const [form] = Form.useForm();
  const [showLoader, toggleLoader] = useState(false);

  const userList = useSelector(getUserList);

  const onFinish = () => {
    updateAssetData();
  };

  const updateAssetData = async () => {
    const assignedTo = form.getFieldValue("assignedTo");
    const assignedDate = form.getFieldValue("assignedDate");
    toggleLoader(true);
    const response = await CALL_API_UPDATED(
      `assets/${editData?.assetId}`,
      "patch",
      {
        assetName: editData.assetName,
        category: editData.category,
        description: editData.description,
        image: editData.image,
        assetStatus: "Assigned",
        assignedTo: assignedTo,
        assignedDate: assignedDate,
      }
    );

    if (response.status === "SUCCESS") {
      getAssetsData();
      toggleLoader(false);
      setShowRevoke(false);
      form.resetFields();
      message.success(`Asset Successfully Assigned to ${assignedTo}`);
    } else {
      message.error("Failed to assign Asset");
    }
  };

  return (
    <StyledModal
      title={`${editData?.assetName} - Assign to`}
      visible={showRevoke}
      onCancel={() => {
        setShowRevoke(false);
        form.resetFields();
        toggleLoader(false)
      }}
      width={500}
      footer={[]}
    >
      <Form
        form={form}
        name="AddLocation"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} xl={24}>
            <Form.Item
              label="Name/Employee ID:"
              name="assignedTo"
              rules={[
                { required: true, message: "Please select an employee name." },
              ]}
            >
              <Select placeholder="Enter">
                {userList?.map((data) => (
                  <Option key={data.userid} value={data.employeeid}>
                    {`${data.fullname} - ${data.employeeid}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24}>
            <Form.Item
              label="Assigned Date:"
              name="assignedDate"
              rules={[{ required: true, message: "Please select a date" }]}
            >
              <DatePicker name="assignedDate" format={"DD-MM-YYYY"} />
            </Form.Item>
          </Col>
        </Row>

        <StyledFooterDiv>
          <StyledButton loading={showLoader} type="primary" htmlType="submit">
            Assign
          </StyledButton>
        </StyledFooterDiv>
      </Form>
    </StyledModal>
  );
};

export default AssignAsset;
