import { Col, Collapse, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import ClientBasicJSON from "../../JsonFormat/ClientBasicJson/index";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ClientInformationJSON from "JsonFormat/ClientBasicJson/ClientInformation";
import { EMAIL_REGEX, NON_EMPTY_REGEX, RelationshipType} from "common/Constants";
import { CALL_API_UPDATED } from "common/API";

const ClientInformation = ({ filteredData }) => {

  
  const [form] = Form.useForm();
  const location = useLocation();
  const { id } = useParams();
  const extraData = location.state ? location.state.extraData : null;
  const [data, setData] = useState([]);
  const [showLoader, toggleLoader] = useState(false);
  const [basicData, setBasicData] = useState({
    bdo_id: filteredData?.bdo_id || "-",
    bdo_managingoffice: filteredData?.["bdo_managingoffice@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_influentialstarcustomer:
      filteredData?.["bdo_influentialstarcustomer@OData.Community.Display.V1.FormattedValue"] || "-",
    name: filteredData?.name || "-",
    relationshiptype: filteredData?.["customertypecode@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_incorporationtype: filteredData?.["bdo_incorporationtype@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_currentyearofengagement:
      filteredData?.["bdo_currentyearofengagement@OData.Community.Display.V1.FormattedValue"] || "-",
      emailaddress1: filteredData?.emailaddress1 || "-" ,
      websiteurl: filteredData?.websiteurl || "-",
    description: filteredData?.description || "-",
    bdo_addresstype: filteredData?.["bdo_addresstype@OData.Community.Display.V1.FormattedValue"] || "-",
    usegroupaddress:
      filteredData?.['bdo_usegroupaddress@OData.Community.Display.V1.FormattedValue'] || "-",
      bdo_registeredaddress:
      filteredData?.bdo_registeredaddress || "-",
      sic: filteredData?.sic || "-",
      bdo_operatingcountries:
      filteredData?.bdo_operatingcountries || "-",
      bdo_registrationno:
      filteredData?.bdo_registrationno || "-",
      bdo_pin: filteredData?.bdo_pin || "-",
      address1_line1:
      filteredData?.address1_line1 || "-",
      bdo_postalcode: filteredData?.bdo_postalcode || "-",
      address1_city: filteredData?.address1_city || "-"
  });
  // const filteredData = extraData.filter((item) => item.id == id);

  const { BasicDetail } = ClientInformationJSON(basicData);

  const getFulldata = async (value) => {
    toggleLoader(true);
    const response = await CALL_API_UPDATED(
      `/insyts/clients/activity?LastEvaluatedKey=0&clientId=${filteredData?.clientId}`,
      "get"
    );

    if (response.data) {
      toggleLoader(false);
      setData(response?.data);
    }
  };

  const handleFormChange = (e) => {
    setBasicData({
      ...basicData,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    getFulldata();
  }, []);

  return (
    <div className="scroll_height" >
    <Row gutter={20}>
      <Col sm={24} md={24} lg={12} xl={12}>
        <div className="mb-3">
          <GridBuilder
            formStructureJson={BasicDetail}
            handleChange={handleFormChange}
            initialValues={basicData}
          />
        </div>
      </Col>
      <Col sm={24} md={24} lg={12} xl={112}>
            <div className="box mb-2 p-2">
              <Form
                className=""
                form={form}
                name="onboard"
                layout="vertical"
                initialValues={{
                  primarycontact: filteredData?._primarycontactid_value ? filteredData?.["_primarycontactid_value@OData.Community.Display.V1.FormattedValue"] : "",
                  email: filteredData?.emailaddress1,
                }}
              >
                <Form.Item
                  name="primarycontact"
                  label="Primary Contact"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Primary Contact is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Primary Contact"
                    maxLength={50}
                    readOnly={true}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      pattern: EMAIL_REGEX,
                      message: "Email ID is required",
                    },
                  ]}
                >
                  <Input placeholder="Email ID" maxLength={60} readOnly={true}/>
                </Form.Item>
              </Form>
            </div>
            <div className="box p-2">
              <Form
                className=""
                form={form}
                name="industry"
                layout="vertical"
                initialValues={{
                  primaryindustry: filteredData?.["_bdo_primaryindustry_value@OData.Community.Display.V1.FormattedValue"] ? filteredData?.["_bdo_primaryindustry_value@OData.Community.Display.V1.FormattedValue"] : "",
                  primarysector: filteredData?.["_bdo_sector_value@OData.Community.Display.V1.FormattedValue"] ? filteredData?.["_bdo_sector_value@OData.Community.Display.V1.FormattedValue"] : "",
                }}
              >
                <Form.Item
                  name="primaryindustry"
                  label="Primary Industry"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Primary Industry is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Primary Industry"
                    maxLength={50}
                    readOnly={true}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="primarysector"
                  label="Primary Sector"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Primary Sector is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Primary Sector"
                    maxLength={50}
                    readOnly={true}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Form>
            </div>
          </Col>
      {/* <Col sm={24} md={14} lg={15} xl={16}>
        <div className="heading">Activity:</div>
        <Row gutter={20}>
          <Col sm={24} md={24} lg={24} xl={14}>
            <>
              {data?.length > 0 ? (
                <>
                  {data.map((item) => (
                    <div className="box mb-2 p-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="note px-2">{item?.logName}</div>
                        <div className="text-small">{item?.log?.time}</div>
                      </div>
                      <p className="mb-0">
                        {item?.log?.email || item?.log?.note}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <p className="text-center w-100 p-5">No Data</p>
              )}
            </>
          </Col>
          
        </Row>
      </Col> */}
    </Row>
    </div>
  );
};


export default ClientInformation;
