import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Breadcrumbs, Typography } from "@mui/material";
import {
  Row,
  Col,
  Tooltip,
  Divider,
  Input,
  Form,
  Progress,
  Button,
  DatePicker,
  notification,
  Spin,
  Modal
} from "antd";
import TabsView from "./TabsView";
import COLORS from "common/Colors";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useFetch } from "hooks/useFetch";
import queryKeys from "common/queryKey";
import moment from "moment";
import { Link } from "react-router-dom";
import { useUpdate } from "hooks/useUpdate";
import { useQueryClient } from "react-query";
import { ReactComponent as CalendarPickerIcon } from "assets/icons/UI/calendar-picker.svg";
import { ReactComponent as ContentCopyIcon } from "assets/icons/UI/copy-link.svg";
import { IconButton } from "@mui/material";
import { useForm } from "antd/lib/form/Form";
import { find, sumBy } from "lodash";
import { getUserInfo, getUserList } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { useScrollFetch } from "hooks/useScrollFetch";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { setFilterClientData } from "redux/actions";

const dateFormat = (date) => {
  if (!date) return "-";
  return moment(date, "DD-MM-YYYY").format("DD MMM YYYY");
};

const EngagementView = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [copied, setCopied] = useState(false);
  const [form] = Form.useForm();
  const userList = useSelector((state) => getUserList(state));
  const userInfo = useSelector((state) => getUserInfo(state))

  const {
    getEngagementById,
    // getClientDetailed,
    updateEngagement: updateEngagementQuery,
  } = queryKeys;

  const { id } = useParams();

  const { key, url } = getEngagementById(id);

  const { data: engagementDataRes } = useFetch(key, url, {
    enabled: !!id,
  });

  const engagementData = engagementDataRes?.data;

  useEffect(() => {
    if (!engagementData) return;

    const parseDate = (dateString) => {
      if (!dateString || dateString === "Invalid date") {
        return null;
      }
      const date = moment(dateString, "DD-MM-YYYY");
      return date.isValid() ? date : null;
    };

    form.setFieldsValue({
      actualEndDate: parseDate(engagementData?.stakeHolder?.actualEndDate),
      actualStartDate: parseDate(engagementData?.stakeHolder?.actualStartDate),
    });
  }, [engagementData]);

  const addResourceandSubDisabled = useMemo(() => {
    const engagementStatus = engagementData?.jobCloser?.status;

    const isPartnerOrManager =
      userInfo?.userid === engagementData?.stakeHolder?.personIncharge ||
      userInfo?.userid === engagementData?.stakeHolder?.partner;
    return !isPartnerOrManager
  }, [engagementData]);

  const { mutate: update } = useUpdate(
    updateEngagementQuery.key,
    updateEngagementQuery.url
  );

  const updateEngagement = (name, value) => {
    const data = {
      ...engagementData,
      stakeHolder: {
        ...engagementData.stakeHolder,
        [name]: moment(value).format("DD-MM-YYYY"),
      },
    };

    update(data, {
      onSuccess: () => {
        notification.success({
          message: "Engagement updated successfully",
        });
        queryClient.setQueryData(key, (oldData) => ({
          ...oldData,
          data,
        }));
      },
      onError: () => {
        console.log("Error in updating engagement");
      },
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard:", text);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
  };

  const breadStyle = { color: "#636980", cursor: "pointer" };
  const breadcrumbs = [
    <label key="2" style={breadStyle} onClick={() => history.goBack()}>
      Engagement
    </label>,
    <Typography key="3" color="text.primary">
      {engagementData?.information?.jobTitle}
    </Typography>,
  ];

  return (
    <EngagementWrap>
      <div className="main-back">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <Row gutter={12}>
        <Col lg={24} xl={24}>
          <TabsView
            engagementData={engagementData}
            Details={
              <Details
                engagementData={engagementData}
                form={form}
                updateEngagement={updateEngagement}
                addResourceandSubDisabled={addResourceandSubDisabled}
              />
            }
          />
        </Col>
      </Row>
    </EngagementWrap>
  );
};

export default EngagementView;

const EngagementWrap = styled.div`
  .main-back {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    .backicon {
      font-size: 16px;
    }
  }
  .audit {
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
    text-decoration: underline;
  }
  .client-heading {
    font-weight: 600;
  }
  .client {
    box-shadow: 0px 3px 6px #00000015;
    border-radius: 5px;
    padding: 1rem;
    /* line-height: 0.85; */
    margin-bottom: 0.5rem;
    font-weight: 600;
    a {
      color: #1890ff;
    }
    .client-name {
      font-weight: 600;
    }
  }
  .side-view {
    padding: 2rem;
    box-shadow: 0px 3px 6px #00000015;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  .manager,
  .sponsor {
    font-weight: 600;
    color: #636980;
  }
  .manager.name,
  .sponsor.name {
    font-size: 1rem;
    /* font-weight: 600; */
    margin-bottom: 0.5rem;
  }
  .actual-cpl {
    font-weight: 600;
  }
  .progress-percent {
    text-align: center;
    font-weight: 600;
  }
  /* Ant Customization */
  .ant-btn.custom-engagement-btn {
    background-color: ${COLORS.PRIMARY};
    width: 100%;
    border-radius: 5px;
    border: none;
    transition: 0.2s ease-in-out;
    white-space: pre-wrap;
    height: 100%;
  }
  .ant-btn.custom-engagement-btn:hover {
    box-shadow: 0 0 0 2px ${COLORS.PRIMARY};
  }
  .ant-form-item {
    margin-bottom: 0.5rem;
  }
  .copyicon_container {
    position: absolute;
    font-size: 12px;
    translate: 0px -12px;
  }
`;

const Details = ({ engagementData, form, updateEngagement, addResourceandSubDisabled }) => {
  
  const clientSurveyModal = async () => {
    try {
      if (!engagementData?.checkList?.billing || !engagementData?.checkList?.timesheet) {
        Modal.info({
          icon: "",
          content: (
            <div style={{ textAlign: "center" }}>
              <p>
                You cannot create a client survey at this time as Billing and Timesheet approval is still pending.
              </p>
            </div>
          ),
          okButtonProps: { style: { display: 'none' } },
          cancelButtonProps: { style: { display: 'none' } },
          maskClosable: true,
        });
      } else {
        history.push(`/engagement/${id}/client-survey`);
      }
    } catch (error) {
      console.error("[clientSurveyModal] error -->", error);
    }
  };
  
  const dispatch = useDispatch();
  const userList = useSelector((state) => getUserList(state));
  const { getClient } = queryKeys;
  const { id } = useParams();

  const history = useHistory();

  const { data: clientDataRes, isLoading: isClientFetching } = useFetch(
    getClient(engagementData?.clientId).key,
    getClient(engagementData?.clientId).url,
    {
      enabled: !!engagementData?.clientId,
    }
  );

  const clientDataFetched = !isClientFetching && !!clientDataRes?.data;

  useEffect(() => {
    if (clientDataRes) {
      dispatch(setFilterClientData(clientDataRes));
    }
  }, [clientDataRes]);

  const client = clientDataRes?.data?.[0]?.registeredName || "-";

  const percentage =
    (parseInt(engagementData?.actualMandays) /
      (sumBy(engagementData?.employeeList, "personDay") * 8)) *
    100;
  const formattedPercentage = percentage ? percentage.toFixed(2) : "0.00";
  return (
    <Row gutter={16}>
      <Col lg={24} xl={12}>
        <div className="side-view">
          <div className="audit">{engagementData?.information?.jobTitle}</div>
          <div className="client-heading">Client</div>
          <div className="p-1">
            <div className="client">
              <span className="client-name">
                <Spin spinning={isClientFetching}>{client}</Spin>
              </span>
              <div>
                {/* <span className="position-relative">
                  <a href="www.gamedaycatering.com" target="_blank">
                    www.gamedaycatering.com
                  </a>
                  <Tooltip
                    className="copyicon_container"
                    placement="bottom"
                    title={copied ? "Copied!" : "Copy"}
                  >
                    <IconButton
                      className=""
                      onClick={() =>
                        copyToClipboard("www.gamedaycatering.com")
                      }
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </span> */}
              </div>
            </div>
          </div>

          <div className="manager">Manager </div>
          <div className="manager name">
            {
              find(userList, {
                userid: engagementData?.stakeHolder?.personIncharge,
              })?.fullname
            }
          </div>

          <div className="sponsor">Sponsor </div>
          <div className="manager name">
            {
              find(userList, { userid: engagementData?.stakeHolder?.partner })
                ?.fullname
            }
          </div>

          <div className="actual-cpl">Actual %CPL:</div>
          <Divider
            style={{
              margin: "0.3rem 0",
            }}
          />
          <Progress
            percent={formattedPercentage}
            size={[300, 20]}
            strokeColor={COLORS.PRIMARY}
            showInfo={false}
          />

          <div className="progress-percent">
            <span>{formattedPercentage}%</span>
          </div>
        </div>
      </Col>
      <Col lg={24} xl={12}>
        <div className="side-view">
          <Divider
            style={{
              margin: "0.6rem 0",
            }}
          />

          <Form layout="vertical" form={form}>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Plan Start Date">
                  <Input
                    value={dateFormat(
                      engagementData?.stakeHolder?.planStartDate
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Plan End Date">
                  <Input
                    value={dateFormat(engagementData?.stakeHolder?.planEndDate)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Actual Start Date" name="actualStartDate">
                  <DatePicker
                    style={{
                      width: "100%",
                    }}
                    format="DD MMM YYYY"
                    placeholder="Enter"
                    suffixIcon={<CalendarPickerIcon />}
                    // value={actualStartDate}
                    onChange={(date) => {
                      updateEngagement("actualStartDate", date);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Actual End Date" name="actualEndDate">
                  <DatePicker
                    style={{
                      width: "100%",
                    }}
                    placeholder="Enter"
                    format="DD MMM YYYY"
                    suffixIcon={<CalendarPickerIcon />}
                    // value={actualEndDate}
                    onChange={(date) => {
                      updateEngagement("actualEndDate", date);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Divider
            style={{
              margin: "0.3rem 0",
            }}
          />

          <Row
            gutter={[12, 8]}
            style={{
              marginTop: "2rem",
            }}
          >
            <Col span={12}>
              <Button
                className="custom-engagement-btn"
                disabled={addResourceandSubDisabled}
              >
                <Link
                  to={{
                    pathname: `/engagement/${id}/add-resources`,
                    state: { engagementData },
                  }}
                  disabled={addResourceandSubDisabled}
                >
                  Add Resources
                </Link>
              </Button>
            </Col>
            {/* <Col span={12}>
              <Button className="custom-engagement-btn">
                Add Thirdparty
              </Button>
            </Col>
            <Col span={12}>
              <Button className="custom-engagement-btn">
                Add Subengagement
              </Button>
            </Col> */}
            <Col span={12}>
              <Button
                className="custom-engagement-btn"
                onClick={() => {
                  history.push(`/engagement/${id}/financialsummary`);
                }}
              >
                Financial Summary
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="custom-engagement-btn"
                onClick={() => {
                  history.push(`/engagement/${id}/billing`);
                }}
                disabled={!clientDataFetched || engagementData?.jobCloser?.status === "Not Started" || engagementData?.jobCloser?.status === "Not Approved"}
              >
                Billing
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="custom-engagement-btn"
                onClick={() => clientSurveyModal()}
                disabled={!clientDataFetched || engagementData?.jobCloser?.status === "Not Started" || engagementData?.jobCloser?.status === "Not Approved"}
              >
                Client Survey
              </Button>
            </Col>
            {/* <Col span={12}>
              <Button
                className="custom-engagement-btn"
                onClick={() => {
                  history.push(`/engagement/${id}/claims`);
                }}
              >
                Claims
              </Button>
            </Col> */}
            {/* <Col span={12}>
              <Button className="custom-engagement-btn" onClick={() => { }}>
                Add New Document
              </Button>
            </Col> */}
          </Row>
        </div>
      </Col>
    </Row>
  );
};
