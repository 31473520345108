import UITabs from 'atoms/UITabs';
import Organization from 'molecules/Organization';
import Team from 'pages/Team';
import React, { useState } from 'react'

function MyTeam() {
    const [activeTab, setActiveTab] = useState(1);
    
    const items = [
        {
            id: 1,
            label: "List",
            component: <Team />
        },
        {
            id: 2,
            label: "Office Org Chart",
            component: <Organization type="office" team={true}/>
        },
        {
            id: 3,
            label: "Regional Org Chart",
            component: <Organization type="regional" team={true}/>
        },
       
    ]
    const handleTabChange = (newValue) => {
        setActiveTab(newValue);
    };

  return (
    <div>
        {/* <h5>My Team Member</h5> */}
        <UITabs items={items} activeTab={activeTab} onTabChange={handleTabChange} />
    </div>
  )
}

export default MyTeam