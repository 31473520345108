import { getEMSRecoedJson } from 'JsonFormat/ClientBasicJson/EMSRecord';
import React from 'react'
import GridBuilder from 'UIBuilderEngine/GridBuilder'

const EMSRecord = ({filteredData}) => {

  let initialData = ({
    bdo_emsclientid: filteredData?.bdo_emsclientid || "-",
    bdo_emsmanagerid: filteredData?.bdo_emsmanagerid || "-",
    _bdo_bdofirm_value: filteredData?.["_bdo_bdofirm_value@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_entityclassification: filteredData?.bdo_entityclassification || "-",
    _bdo_referredbyfirm_value: filteredData?._bdo_referredbyfirm_value || "-",
    bdo_internationalreferral: filteredData?.["bdo_internationalreferral@OData.Community.Display.V1.FormattedValue"] || "-",
    siccode: filteredData?.sic || "-",
  });

 const { EMSRecord } = getEMSRecoedJson();
  return (
    <div className="scroll_height">
    <div className='box p-1 px-2'>
        <GridBuilder
          formStructureJson={EMSRecord}
          // handleChange={handleFormChange}
          initialValues={initialData}
        />
    </div>
    </div>
  )
}

export default EMSRecord