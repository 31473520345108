import Button from 'atoms/Button';
import Table from 'atoms/Table';
import React, { useEffect, useMemo, useState } from 'react'
import AddDocument from './AddDocument';
import queryKeys from 'common/queryKey';
import { useMutate } from 'hooks/useMutate';
import { Form, message, notification } from 'antd';
import { getUserInfo } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { useScrollFetch2 } from 'hooks/useScrollFetch2';
import download from 'downloadjs';
import { STATUS_CODE } from 'common/Constants';
import { CALL_API } from 'common/API';
import { useFetch } from 'hooks/useFetch';
import { width } from '@mui/system';

function Documents({engagementData}) {
    const [showAddModal, setShowAddModal] = useState(false)
    const [base64, setBase64] = useState("");
    const [downloadId, setDownloadId] = useState(null);
    const [loadingDocuments ,toggleLoadingDocuments] = useState(false)
    const [form] = Form.useForm();
    const columns = [
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "File Name",
            dataIndex: "filename",
            key: "filename",
        },
        {
            title: "Attachment",
            dataIndex: "fileurl",
            key: "fileurl",
            width: "30%",
            render: (text, record) => (
                <Button type="primary" loading={loadingDocuments && record.categorydocid === downloadId}  onClick={() => handleDownload(record.categorydocid)}>
                        Download
                </Button>
            ),
        },
    ];

    
    const { postEngagementDocumentList, getEngagementDocumentList, getEngagementDocumentListId } = queryKeys;

    const { mutate: add, isLoading: isPosting } = useMutate(
        postEngagementDocumentList.key,
        postEngagementDocumentList.url
    );

    const queryParams = new URLSearchParams();
    queryParams.append('engagementid', engagementData?.engagementId);
  
    const queryString = queryParams.toString();
  
    const { key, url } = getEngagementDocumentList(engagementData?.engagementId, `?${queryString}`);
  
    const { data, lastElementRef, isLoading, refetch } = useScrollFetch2(
      key,
      {
        endPoint: url,
        queryParam: "lastEvaluatedKey",
      },
      {
        enabled: !!engagementData?.engagementId,
      }
    );
  
    const dataList = useMemo(() => {
      if(data){
        return data?.pages?.reduce((acc, page) => {
          if (page?.response?.items === undefined) return acc;
          return [...acc, ...page?.response?.items];
        }, []);
      }else{
        return []
      }
     
    }, [data]);
    const userInfo = useSelector((state) => getUserInfo(state));

    const onFinish = (value) => {
        const payload = {
            clientid : engagementData?.clientId,
            engagementid: engagementData?.engagementId,
            category:value?.category ,
            filename: value?.file[0]?.name, 
            attachmenttype: value?.file[0]?.type, 
            description: value?.description,
            content: base64,
            createdby: userInfo?.userid,
            updatedby:userInfo?.userid 
        }
        add(payload, {
            onSuccess: (res) => {
             
              notification.success({
                message: "Document Added Successfully",
              });
              refetch()
              form.resetFields();
              setShowAddModal(false) 
            },
          });
    }

    const handleDownload = async (id) => {
        toggleLoadingDocuments(true);
        setDownloadId(id)
        
   
        console.log("calling")
         
        try {
            const {code, url} = await CALL_API(`insyts/engagement/category-doc/download/?categorydocid=${id}`, "get");

            if (code === STATUS_CODE.SUCCESS) {
              download(url?.presignedUrl);
            } else {
              message.error("Something went wrong!! Please try again.");
            }
          } catch (error) {
            // message.error("Failed to download the document.");
            console.error("There was an error!", error);
          }
        toggleLoadingDocuments(false);
      };
    


    return (
        <div>
            <div className='text-end'>
                <Button type="primary" onClick={() => setShowAddModal(true)}>Add Document</Button>
            </div>
            <Table
                columns={columns}
                dataSource={dataList}
                pagination={false}
                rowKey="categorydocid"
                loading={isLoading}
                onRow={(record, index) => ({
                    ref: index === dataList.length - 30 ? lastElementRef : null,
                })}
            />
            <AddDocument
                showAddModal={showAddModal}
                onClose={() => {setShowAddModal(false); form.resetFields();}}
                onFinish={onFinish}
                setBase64={setBase64}
                isUpdating={isPosting}
                form={form}
            />

        </div>
    )
}

export default Documents