import { Button, Col, Form, Row, Table, Tabs } from "antd";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import COLORS from "common/Colors";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import CreateperformanceContract from "./CreateperformanceContract";
import SelfAssessment from "./SelfAssesment";
import AcceptPerformance from "./acceptPerformance";
import SupervisorAssessment from "./SupervisorAssessment";
import FinalAppraisal from "./FinalAppraisal";
import KeyResponsibilities from "./KeyResponsibilities";

const StyledTable = styled(Table)`
  /* padding-top: 20px; */
  table {
    table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
  }
  .ant-table-cell {
    padding: 10px;
    height: 0px !important;
    border: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: none;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledWrap = styled.div`
  .ant-table-tbody > tr > td {
    font-weight: 400 !important;
  }

  .ant-table-cell::before {
    display: none !important;
  }

  .card {
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    border: none !important;
  }
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    border-radius: 5px;
    font-weight: 500;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .ant-tabs-tab {
    font-size: 16px;
    &:hover {
      color: ${COLORS.TEXT.PRIMARY};
    }
  }
  /* .ant-tabs-ink-bar {
    background: ${COLORS.TAB};
    font-weight: bold;
  } */
  /* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #181818;
    background-color: #E3FFF5;
    font-weight: 500;
    font-size: 16px;
  } */
  .ant-tabs-tab {
    width: 150px !important;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    text-wrap: wrap;
    margin-right: 10px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #181818;
    text-shadow: 0 0 0.25px currentcolor;
    text-wrap: wrap;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    background: #e3fff5 !important;
    color: #181818 !important;
  }
`;

function Performance() {
  const [form] = Form.useForm();
  const [keyresp, setKeyResp] = useState(false);
  const [open, setOpen] = useState(false);
  const [tabId, setTabId] = useState("active");

  const handleTabSelect = (id) => {
    setTabId(id);
  };

  const clientTabs = useMemo(
    () => [
      {
        id: "CreateperformanceContract",
        label: "Create performance Contract",
        component: (
          <CreateperformanceContract
            open={open} 
            setOpen={setOpen}
            keyresp={keyresp}
            setKeyResp={setKeyResp}
          />
        ),
      },
      {
        id: "Accept Performance Contract",
        label: "Accept Performance Contract",
        component: <AcceptPerformance />,
      },
      {
        id: "SelfAssessment",
        label: "Self Assessment",
        component: <SelfAssessment />,
      },
      {
        id: "supervisorassessment",
        label: "Supervisor Assessment",
        component: <SupervisorAssessment />,
      },
      {
        id: "finalappraisal",
        label: "Final Appraisal",
        component: <FinalAppraisal />,
      },
    ],
    []
  );

  const tempdata = [
    {
      id: "1",
      employeename: "Abijuru JEAN DE LA CROIX",
      Period: "2023/2024- Second half",
      status: "Performance contract created",
      appraisalscore: "-",
    },
    {
      id: "2",
      employeename: "Agegnehu TESFA",
      Period: "Agegnehu TESFA",
      status: "Performance contract Accepted",
      appraisalscore: "-",
    },
  ];

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employeename",
      key: "employeename",
      //   width: "10%",
    },
    {
      title: "Period",
      dataIndex: "Period",
      key: "Period",
      //   width: "20%",
      className: "normal-column",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      //   width: "20%",
      className: "normal-column",
    },
    {
      title: "Appraisal score",
      dataIndex: "appraisalscore",
      key: "appraisalscore",
      //   width: "20%",
      className: "normal-column",
    },
  ];

  const performanceData = [
    { label: "Daniel ISHIMWE", value: "Daniel ISHIMWE" },
    { label: "Ajay", value: "Ajay" },
  ];

  const uniqueNames = Array.from(
    new Set(performanceData.map((item) => item.name))
  ).map((name) => ({
    label: name,
    value: name,
  }));

  return (
    <div>
      {keyresp && (
        <KeyResponsibilities
         open={open} 
         setOpen={setOpen} 
         keyresp={keyresp} 
         setKeyResp={setKeyResp} 
        />
      )}

      {!open && !keyresp && (
        <>
          <h6>Appraisal and performance</h6>
          <Row>
            <Col md={18} className="">
              <StyledWrap>
                <Form
                  form={form}
                  layout="vertical"
                  className="mt-2"
                  // onValuesChange={() => form.submit()}
                >
                  <Row className="g-3">
                    <Col xs={12} sm={6} md={5} lg={5} xxl={4}>
                      <DropdownCheckBox
                        name="name"
                        label="Search by name"
                        options={uniqueNames}
                        form={form}
                      />
                    </Col>
                  </Row>
                </Form>
              </StyledWrap>
            </Col>
            {/* {
              Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.create)) && ( */}
            <Col className="text-end" md={6}>
              <Button type="primary" className="mx-2">
                Performance Report
              </Button>
              <Button type="primary" onClick={() => setOpen(true)}>
                Add new
              </Button>
            </Col>
            {/* )} */}
          </Row>
          <StyledTable
            className="mb-5"
            dataSource={tempdata}
            columns={columns}
            rowKey="role_id"
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  setOpen(true);
                },
              };
            }}
          />
        </>
      )}

      {open && !keyresp && (
        <StyledTabs
          type="card"
          defaultActiveKey={tabId}
          onChange={handleTabSelect}
          items={clientTabs?.map((val) => {
            return {
              label: val?.label,
              key: val?.id,
              children: val?.component,
            };
          })}
        />
      )}
    </div>
  );
}

export default Performance;
