export const RadioBtnOption = [
    {
        label: "Yes",
        value: "Yes",
    },
    {
        label: "No",
        value: "No",
    }
];

const ClientOnBoardJSON = (data) => {
    const ClientOnBoard = {
        totalpages: 1,
        pages: [
            {
                modalView: false,
                colSpan: [12, 12, 12],
                fields: [
                    // Row 1
                    [
                        {
                            id: 1,
                            label: "Full name of entity:",
                            type: "text",
                            name: "name",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,

                            onKeyDown: /^[a-zA-Z\s]+$/,
                        },
                    ],
                    // Row 2
                    [
                        {
                            id: 2,
                            label: "Activities/sector details:",
                            type: "text",
                            name: "sector",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 30,
                            onKeyDown: /^[a-zA-Z\s]+$/,
                        },
                    ],
                    [
                        {
                            id: 3,
                            label: "Location & contact details:",
                            type: "text",
                            name: "location",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 50,
                            onKeyDown: /^[a-zA-Z\s.\-0-9]+$/
                        },
                    ],
                    // Row 4
                    [
                        {
                            id: 4,
                            label: "Name of directors & officers:",
                            type: "text",
                            name: "nameofdirectors",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            validationRules: [
                                {
                                    required: true,
                                    message: `Name of directors & officers is required`,
                                },
                            ],
                            onKeyDown: /^[a-zA-Z\s]+$/,
                        },
                    ],
                    [
                        {
                            id: 5,
                            label: "Client is a listed/Regulated/PIE company:",
                            type: "radiobutton",
                            name: "clientlist",
                            options: [
                                {
                                    label: "Yes",
                                    value: "Yes",
                                },
                                {
                                    label: "No",
                                    value: "No",
                                }
                            ],
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],
                    // Row 6
                    [
                        {
                            id: 6,
                            label: "Scope of service required from BDO:",
                            type: "text",
                            name: "scope",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 50,
                            onKeyDown: /^[a-zA-Z\s]+$/,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 7
                    [
                        {
                            id: 7,
                            label: "Web link/Social media presence:",
                            type: "text",
                            name: "socialMedia",
                            Placeholder: "Enter",
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 8
                    [
                        {
                            id: 8,
                            label: "Countries that the prospective clients are related group operate in:",
                            type: "formbuilder",
                            buildtype: "text",
                            name: "clientCountries",
                            options: [
                                {
                                    tableheader: [{ label: "Country" }, { label: "Affiliate name" }],
                                },
                                {
                                    defaultOption: [{ id: 1, name: "scopecountry", value: "" },
                                    { id: 2, name: "scopeAffiliate", value: "" }],
                                }
                            ],

                            Placeholder: "Enter",
                            value: "",
                        },
                    ],
                    [
                        {
                            id: 9,
                            label: "Parent company Listed/ Regulated/ PIE company:",
                            type: "radiobutton",
                            name: "parentcompany",
                            options: RadioBtnOption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],
                    // Row 10
                    [
                        {
                            id: 10,
                            label: "Have you or any affiliate or any officer/ director ever used BDO for professional services?",
                            type: "text",
                            name: "bdoservices",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 100,
                            onKeyDown: /^[a-zA-Z\s.0-9]+$/,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 11
                    [
                        {
                            id: 11,
                            label: "Who were your other professional service provider? (Previous and Current)",
                            type: "text",
                            name: "serviceprovider",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 100,
                            onKeyDown: /^[a-zA-Z\s.0-9]+$/,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],
                    // Row 12
                    [
                        {
                            id: 12,
                            label: "Please provide us information on any bank dispute, anti-money, political exposer to repetitional issues faced by the entity or its directors & officers, or any other information that BDO should be aware of, if any?",
                            type: "text",
                            name: "bankdispute",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 100,
                            onKeyDown: /^[a-zA-Z\s.0-9]+$/,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 13
                    [
                        {
                            id: 13,
                            label: "",
                            type: "filenew",
                            name: "attachedFile",
                            options: { btnLabel: "Attach Signature" },
                            Placeholder: "Attach Signature",
                            value: "",
                            // validationRules: [
                            //     {
                            //         required: true,
                            //         message: `This field is required`,
                            //     },
                            // ]
                        },
                        {

                        }
                    ],

                    //Row 14
                    [
                        {
                            id: 14,
                            label: "Designation:",
                            type: "text",
                            name: "designation",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 100,
                            onKeyDown: /^[a-zA-Z\s.0-9]+$/,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        }, {

                        }
                    ]

                ],
            },
        ],
    };
    return {
        ClientOnBoard
    };
};

export default ClientOnBoardJSON;

