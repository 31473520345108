import React, { useState } from "react";
import styled from "styled-components";
import ClientBasicJSON from "../../JsonFormat/ClientBasicJson/basicInfo";
import ClientContactJSON from "../../JsonFormat/ClientContactJson/contactInfo";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import { Button, Form, Input, Upload, Row, Col, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import StepsProgress from "organisms/StepsProgress";
import { CALL_API_UPDATED } from "common/API";
import { STATUS_CODE } from "common/Constants";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import axios from "axios";

const AddNewClientWrap = styled.div`
  .main-heading {
    font-size: 18px;
    line-height: 22px;
    color: #1b1b1b;
  }

  .form-container {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .form-container .ant-form-item {
    margin-bottom: 3px;
  }

  .text-end {
    text-align: end;
  }

  .ant-btn-default {
    background-color: #c2fae5;
    border-radius: 5px;
    font-size: 15px;
    color: #000000;
    border: none;
    font-weight: 500;
  }

  .form-container .ant-form-item-label {
    padding: 0;
  }

  .px_3 {
    padding: 0 10px;
  }

  .mx-3 {
    margin: 0 10px;
  }

  .ml-5 {
    margin: 0 0 0 5px;
  }

  .sub-title-bg {
    background: #ededed;
    color: #1b1b1b;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    padding: 10px;
    font-weight: 500;
  }

  .sub-title-bg-docs {
    background: #ededed;
    color: #1b1b1b;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    padding: 5px 10px;
    font-weight: 500;
  }

  .upload_docs .ant-btn-default {
    background: #f1f2f2;
    border: none;
    width: 100%;
    margin: 0;
  }
  .upload_docs .ant-upload.ant-upload-select {
    display: block;
  }

  .ant-steps {
    width: 70%;
    margin: auto;
  }
  .ant-steps-item-title {
    font-family: "Circular Std", Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .ant-btn-text {
    color: #1b1b1b;
    font-size: 16px;
    font-weight: 500;
  }
  .ant-btn-text span {
    text-decoration: underline;
  }
  .d_block {
    display: block;
  }
  .px_5 {
    padding-inline: 5px;
  }
  .py_4 {
    padding-block: 4px;
  }
  .remove_txt {
    text-align: right;
  }
  .remove_txt .ant-btn-text,
  .industry_add_new .ant-btn-text {
    font-size: 16px;
    font-weight: 500;
  }
  .remove_txt .ant-btn-text span {
    text-decoration: underline;
    color: #be0000;
  }
  .industry_add_new .ant-btn-text span {
    text-decoration: underline;
  }
  .industry_add_new {
    text-align: right;
  }

  .ant-select {
    width: 100%;
  }
  .space_industry_15 {
    padding-left: 10px;
    padding-right: 40px;
  }

  .space_industry_15 {
    padding-left: 10px;
    padding-right: 40px;
    position: relative;
  }

  .remove_button_icon .ant-btn {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 1px;
    right: -7px;
  }

  /* Remove up and down arrows */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;
const loaderStyle = { fontSize: 20, color: "#000" };
const AddNewClient = () => {
  const history = useHistory();
  const { Option } = Select;
  const IndustryOptions = [
    "Tea, Coffee,Wheat,Barley,Tobacco and other cash crops",
    "Horticulture and Floriculture",
    "Animal Husbandry,Dairy & Meat",
    "Foodstuff - freenhouse - agriculture",
    "Others - Agribusiness",
  ];

  const SectorOptions = [
    "Primary Sector",
    "Secondary Sector",
    "Tertiary Sector",
    "Quaternary Sector",
    "Quinary Sector",
    "Public Sector",
    "Nonprofit Sector",
  ];

  const { BasicDetail } = ClientBasicJSON();
  const { ContactDetail } = ClientContactJSON();
  //  Basic Information
  const [showForm, setShowForm] = useState("Basic Information");
  const [step, setStep] = useState(0);
  const [showLoader, toggleLoader] = useState(false);
  const [documentformData, setDocumentFormData] = useState([
    {
      Category: "",
      AttachmentType: "",
      Creator: "",
      Updater: "",
      File: null,
      description: "",
    },
  ]);

  const [industriesformData, setIndustiesFormData] = useState([
    {
      Industry: "",
      Sector: "",
    },
  ]);

  const resetData = () => {
    setShowForm("Basic Information");
    setStep(0);
    setClientBasicData({
      clientId: "",
      crmId: "",
      managingOffice: "",
      status: "",
      relationship: "",
      ultimateParent: "",
      registeredName: "",
      registeredAddress: "",
      description: "",
      city: "",
      country: "",
      incorporationType: "",
      industry: "",
      size: "",
      riskClassification: "",
      reference: "",
      createdNo: "",
      registeredNo: "",
      stockExchange: "",
      pinNo: "",
      name: "",
      designation: "",
      role: "",
      mobile: "",
      officePhone: "",
      officeEmail: "",
      personalEmail: "",
    });
    setDocumentFormData([
      {
        Category: "",
        AttachmentType: "",
        Creator: "",
        Updater: "",
        File: "",
        description: "",
      },
    ]);
    setIndustiesFormData([
      {
        Industry: "",
        Sector: "",
      },
    ]);
  };

  const [clientBasicData, setClientBasicData] = useState({
    clientId: "",
    crmId: "",
    managingOffice: "",
    status: "",
    relationship: "",
    ultimateParent: "",
    registeredName: "",
    registeredAddress: "",
    description: "",
    city: "",
    country: "",
    incorporationType: "",
    industry: "",
    size: "",
    riskClassification: "",
    reference: "",
    createdNo: "",
    registeredNo: "",
    stockExchange: "",
    pinNo: "",
    name: "",
    designation: "",
    role: "",
    mobile: "",
    officePhone: "",
    officeEmail: "",
    personalEmail: "",
  });

  const onFinish = async (value) => {
    toggleLoader(true);
    const fullformData = {
      clientId: clientBasicData.clientId,
      crmId: clientBasicData.crmId,
      managingOffice: clientBasicData.managingOffice,
      status: clientBasicData.status,
      relationship: clientBasicData.relationship,
      ultimateParent: clientBasicData.ultimateParent,
      registeredName: clientBasicData.registeredName,
      registeredAddress: clientBasicData.registeredAddress,
      description: clientBasicData.description,
      city: clientBasicData.city,
      country: clientBasicData.country,
      incorporationType: clientBasicData.incorporationType,
      industry: clientBasicData.industry,
      size: clientBasicData.size,
      riskClassification: clientBasicData.riskClassification,
      reference: clientBasicData.reference,
      createdNo: clientBasicData.createdNo,
      registeredNo: clientBasicData.registeredNo,
      stockExchange: clientBasicData.stockExchange,
      pinNo: clientBasicData.pinNo,
      name: clientBasicData.name,
      designation: clientBasicData.designation,
      role: clientBasicData.role,
      mobile: clientBasicData.mobile,
      officePhone: clientBasicData.officePhone,
      officeEmail: clientBasicData.officeEmail,
      personalEmail: clientBasicData.personalEmail,
      industries: industriesformData,
      documents: documentformData,
    };
    const response = await CALL_API_UPDATED(
      `insyts/clients`,
      "post",
      fullformData
    );
    if (response) {
      toggleLoader(false);
    }
    if (response.status === true) {
      if (value !== "clientpage") {
        history.push("/client");
      }

      message.success("Client Added Successfully Added");
    } else if (response.status === STATUS_CODE.INVALID_PAYLOAD) {
      toggleLoader(false);

      message.error(`Invalid payload. Please enter correct data.`);
    } else if (response.status === STATUS_CODE.SOMETHING_WENT_WRONG) {
      toggleLoader(false);

      message.error(`Oops!! something went wrong.`);
    } else {
      toggleLoader(false);

      message.error(`Oops!! something went wrong.`);
    }
  };

  const stepdata = [
    { id: 1, title: "Basic Information" },
    { id: 2, title: "Contact Details" },
    { id: 3, title: "Industries" },
    { id: 4, title: "Documents" },
  ];

  const handleFormChange = (e) => {
    setClientBasicData({
      ...clientBasicData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectChange = (e, name) => {
    setClientBasicData({
      ...clientBasicData,
      [name]: e,
    });
  };
  const handleIndustryFormChange = (index, fieldName, value) => {
    const updatedFormData = [...industriesformData];
    updatedFormData[index][fieldName] = value;
    setIndustiesFormData(updatedFormData);
  };

  const handleIndustryAddField = () => {
    setIndustiesFormData((prevFormData) => [
      ...prevFormData,
      {
        Industry: "",
        Sector: "",
      },
    ]);
  };

  const handleRemoveIndustryAddedField = (index) => {
    setIndustiesFormData((prevFormData) =>
      prevFormData.filter((_, idx) => idx !== index)
    );
  };

  const handleDocsFormChange = (index, fieldName, e) => {
    const value = e;
    const updatedFormData = [...documentformData];
    updatedFormData[index][fieldName] = value;
    setDocumentFormData(updatedFormData);
  };

  const handleDocsFileChange = async (e, index, file) => {
    try {
      const response = await CALL_API_UPDATED(
        `/insyts/clients/attachfile`,
        "get"
      );
      console.log("response", response.url);
      if (response.url) {
        const updatedFormData = [...documentformData];
        updatedFormData[index]["File"] = file.name;
        setDocumentFormData(updatedFormData);
        fileApi(response.url, e);
      }
    } catch (error) {
      console.error("Error fetching file URL:", error);
    }
  };

  const fileApi = async (puturl, e) => {
    try {
      const response = await axios.put(`${puturl}`, e);

      // Handle response
      console.log("response of file upload", response.status);
      if (response.status === 200) {
        message.success("File Successfully Attached");
      } else {
        message.error(`Oops!! something went wrong.`);
      }
    } catch (error) {
      // Handle error
      console.error("Error:", error);
    }
  };

  const handleRemoveField = (index) => {
    setDocumentFormData((prevFormData) =>
      prevFormData.filter((_, idx) => idx !== index)
    );
  };

  const handleAddField = () => {
    setDocumentFormData((prevFormData) => [
      ...prevFormData,
      {
        Category: "",
        AttachmentType: "",
        Creator: "",
        Updater: "",
        File: "",
        description: "",
      },
    ]);
  };

  const handleNextinBasicForm = () => {
    const requiredFields = BasicDetail.pages[0].fields
      .flatMap((row) =>
        row.filter(
          (field) =>
            field.validationRules &&
            field.validationRules.some((rule) => rule.required)
        )
      )
      .map((field) => field.name);

    const missingFields = requiredFields.filter(
      (field) => !clientBasicData[field]
    );

    if (missingFields.length > 0) {
      message.error("Please fill in all required fields");
    } else {
      setShowForm("Contact Details");
      setStep(1);
    }
  };

  const handleNextinContactDetail = () => {
    const requiredFields = ContactDetail.pages[0].fields
      .flatMap((row) =>
        row.filter(
          (field) =>
            field.validationRules &&
            field.validationRules.some((rule) => rule.required)
        )
      )
      .map((field) => field.name);

    const missingFields = requiredFields.filter(
      (field) => !clientBasicData[field]
    );

    if (missingFields.length > 0) {
      message.error("Please fill in all required fields");
    } else {
      setShowForm("Industries");
      setStep(2);
    }
  };
  return (
    <React.StrictMode>
      <AddNewClientWrap>
        <h4 className="main-heading">Add New Client</h4>

        <StepsProgress stepdata={stepdata} title="" step={step} />
        {showForm === "Basic Information" && step === 0 && (
          <>
            <div className="form-container">
              <div className="sub-title-bg">Basic Info</div>
              <div className="px_3">
                <GridBuilder
                  formStructureJson={BasicDetail}
                  handleChange={handleFormChange}
                  handleSelectChange={handleSelectChange}
                  initialValues={clientBasicData}
                  handleNxt={() => handleNextinBasicForm()}
                />
              </div>
            </div>

            {/* <div className="text-end">
            <Button onClick={handleNextinBasicForm}>Next</Button>
          </div> */}
          </>
        )}

        {showForm === "Contact Details" && step === 1 && (
          <>
            <div className="form-container">
              <div className="sub-title-bg">Contact Details</div>
              <div className="px_3">
                <GridBuilder
                  formStructureJson={ContactDetail}
                  handleChange={handleFormChange}
                  handleSelectChange={handleSelectChange}
                  initialValues={clientBasicData}
                  handleNxt={() => handleNextinContactDetail()}
                  handleBack={() => {
                    setShowForm("Basic Information");
                    setStep(step - 1);
                  }}
                />
              </div>
            </div>

            {/* <div className="text-end">
            <Button className="mx-3" onClick={() => {}}>
              Back
            </Button>
            <Button
              onClick={() => {
                handleNextinContactDetail();
              }}
            >
              Next
            </Button>
          </div> */}
          </>
        )}

        {showForm === "Industries" && step === 2 && (
          <>
            <div className="form-container">
              <div className="sub-title-bg-docs">
                <Row>
                  <Col md={12}>
                    <div className="py_4">Industries</div>
                  </Col>
                  <Col md={12}>
                    <div className="industry_add_new">
                      <Button type="text" onClick={handleIndustryAddField}>
                        + Add new
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>

              {industriesformData.map((field, index) => (
                <div className="space_industry_15">
                  <Row key={index} gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item label="Industry"> </Form.Item>
                      <Select
                        placeholder="Select industry"
                        value={field.Industry}
                        onChange={(value) =>
                          handleIndustryFormChange(index, "Industry", value)
                        }
                      >
                        {IndustryOptions.map((option, idx) => (
                          <Option key={idx} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Sector"> </Form.Item>
                      <Select
                        placeholder="Select sector"
                        value={field.Sector}
                        onChange={(value) =>
                          handleIndustryFormChange(index, "Sector", value)
                        }
                      >
                        {SectorOptions.map((option, idx) => (
                          <Option key={idx} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    {index !== 0 && (
                      <span className="remove_button_icon">
                        <Button
                          className="mx-3"
                          onClick={() => handleRemoveIndustryAddedField(index)}
                        >
                          <CloseIcon />
                        </Button>
                      </span>
                    )}
                  </Row>
                </div>
              ))}
            </div>

            <div className="text-end">
              <Button
                className="mx-3"
                onClick={() => {
                  setShowForm("Contact Details");
                  setStep(step - 1);
                }}
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  setShowForm("Documents");
                  setStep(3);
                }}
              >
                Next
              </Button>
            </div>
          </>
        )}

        {showForm === "Documents" && step === 3 && (
          <>
            {documentformData?.map((field, index) => (
              <div className="form-container">
                <div className="sub-title-bg-docs">
                  <Row>
                    <Col md={12}>
                      <div className="py_4">Documents</div>
                    </Col>
                    <Col md={12}>
                      {index !== 0 && (
                        <div className="remove_txt">
                          <Button
                            type="text"
                            onClick={() => handleRemoveField(index)}
                          >
                            Remove
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="px_3 upload_docs">
                  <div key={index}>
                    <Row>
                      <Col md={6}>
                        <div className="px_5">
                          <Form.Item label="Category"> </Form.Item>
                          <Input
                            name="Category"
                            value={field.Category}
                            onChange={(e) =>
                              handleDocsFormChange(
                                index,
                                "Category",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="px_5">
                          <Form.Item label="Attachment Type"></Form.Item>
                          <Input
                            name="AttachmentType"
                            value={field.AttachmentType}
                            onChange={(e) =>
                              handleDocsFormChange(
                                index,
                                "AttachmentType",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {/*  */}
                      </Col>
                      <Col md={6}>
                        <div className="px_5">
                          <Form.Item label="Creator"> </Form.Item>
                          <Input
                            name="Creator"
                            value={field.Creator}
                            onChange={(e) =>
                              handleDocsFormChange(
                                index,
                                "Creator",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="px_5">
                          <Form.Item label="Updater"> </Form.Item>
                          <Input
                            name="Updater"
                            value={field.Updater}
                            onChange={(e) =>
                              handleDocsFormChange(
                                index,
                                "Updater",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="px_5">
                          <Form.Item label="File"> </Form.Item>
                          <Upload
                            name="File"
                            // listType="picture"
                            value={field.File}
                            maxCount={1}
                            customRequest={({ file, onSuccess }) => {
                              // Read the file and convert it to base64
                              const reader = new FileReader();
                              reader.readAsDataURL(file);
                              reader.onload = () => {
                                // Get the base64 data
                                const base64Data = reader.result.split(",")[1];
                                handleDocsFileChange(base64Data, index, file);

                                onSuccess();
                              };
                              reader.onerror = (error) => {
                                console.error("File read error:", error);
                              };
                            }}
                          >
                            <Button icon={<PlusOutlined />}>Upload</Button>
                          </Upload>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="px_5">
                          <Form.Item label="Description"> </Form.Item>
                          <Input
                            name="description"
                            value={field.description}
                            onChange={(e) =>
                              handleDocsFormChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            ))}
            <Button onClick={handleAddField} type="text">
              +Add New
            </Button>

            <div className="text-end">
              <Button
                onClick={() => {
                  setShowForm("Industries");
                  setStep(step - 1);
                }}
              >
                Back
              </Button>
              <Button className="ml-5" onClick={onFinish} disabled={showLoader}>
                {showLoader ? (
                  <Spin
                    indicator={<LoadingOutlined style={loaderStyle} spin />}
                  />
                ) : (
                  "Save"
                )}
              </Button>
              <Button className="ml-5" onClick={resetData}>
                Cancel
              </Button>
              <Button
                className="ml-5"
                onClick={() => {
                  onFinish("clientpage");
                  history.push(`/clientonboard/${clientBasicData.clientId}`);
                }}
              >
                Client On boarding
              </Button>
            </div>
          </>
        )}
      </AddNewClientWrap>
    </React.StrictMode>
  );
};

export default AddNewClient;
