import { useMutation } from "react-query";
import { apiInstance } from "config/baseUrl";

export const useDelete = (mutationKey, url, options) => {
  const accessToken = window.localStorage.getItem("token");

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: async (id) => {
      return await apiInstance({
        method: "DELETE",
        url: `${url}${id}`,
        headers: {
          Tymeplusjwt: `${accessToken}`,
        },
      }).then((res) => {
        return res.data;
      });
    },
    ...options,
  });
};
