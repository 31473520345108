import React from "react";
import { Card, Avatar, Tooltip, Col, Row } from "antd";
import { connect } from "react-redux";
import Mail from "../../src/assets/Mail.svg";
import chat from "assets/chat.svg";
import ViewMore from "organisms/ViewMore";
import {
  getUserName,
  getUserRoleId,
  getUnreadChatMessageList,
  getUserInfo,
  getRoleList,
} from "redux/selectors";
import styled from "@emotion/styled";
import { startCase } from "lodash";
import COLORS from "common/Colors";

const StyledCard = styled(Card)`
  .contract_style {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .contract_text {
    width: 92px;
    height: 24px;
    font-family: "DM Sans", sans-serif;
    font-weight: 550;
    font-size: 14px;
    color: #000;
    background-color: #f3fefa;
    border-radius: 0px 0px 8px 0px;
  }
  .ant-card-body {
    padding: 0px;
  }
  .ant-card-bordered {
    border-radius: 10px !important;
  }
  .vert_Icon {
    color: #000;
  }
  .first_row_styl {
    display: flex;
  }
  .thin-hr {
    border: none;
    height: 0.1px;
    background-color: black;
  }

  .threedot {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  .StyledImage {
    background-color: transparent;
    vertical-align: middle;
    height: 100px;
    width: 100px;
    min-width: 100px;
    min-height: 100px;
  }
  .employee-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background-color: white;
    border-radius: 10px;
    height: 324px;
    gap: 3px;
    box-shadow: 0px 4px 27px 0px #00000017;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .chat-container {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: ${COLORS.PRIMARY};
      translate: 50% -50%;
      .chat-icon {
        translate: 20px 55px;
      }
    }
    .icon {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name {
      font-size: 16px;
      font-weight: 600;
      margin-top: 4px;
    }
    .department {
      font-size: 14px;
      margin-top: 8px;
      place-self: flex-start;
      .bold {
        color: #636980 !important;
      }
    }
    .position {
      font-size: 14px;
      place-self: flex-start;
      .bold {
        font-weight: 400;
        color: #636980;
      }
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  font-size: 3rem;
  text-align: center;
  height: 100px;
  width: 100px;
  min-width: 100px;
  min-height: 100px;
  color: #000;
  .ant-avatar-string {
    margin-top: 2rem;
  }
`;

function TeamCard({
  id,
  name,
  profile,
  employeeid,
  jobtitle,
  usertype,
  employementtype,
  url,
  leveldepartmentname,
  designation
}) {
  const handleTeamsChat = (email) => {
    // const teamsUrl = `msteams://chat?userId=${email}`;
    // console.log("id -->", id)
    // window.open(`https://teams.microsoft.com/l/chat/0/0?users=${email}`);
    // window.open(teamsUrl);
    window.open(`https://teams.microsoft.com/_#/chat?ctx=chat&userId=${email}`);
  };

  const handleEmailClick = (id) => {
    if (url === "myteam") {
      window.open(`mailto:${id}`);
    }
  };
  return (
    <>
      <StyledCard>
        <div className="employee-card">
          {/* {url === "myteam" && (
            <div className="w-100 text-end">
              <Icon name="chat" onClick={() => handleTeamsChat(id)}/>
            </div>
          )} */}
          <Row>
            <Col className="contract_style">
              <div className="contract_text text-center">{employementtype}</div>
            </Col>
          </Row>

          {profile === null ||
            profile === "" ||
            profile === undefined ||
            profile === "null" ? (
            <StyledAvatar size="default">
              {startCase(name?.charAt(0))}
            </StyledAvatar>
          ) : (
            <StyledAvatar
              src={profile}
              className="StyledImage"
              size="default"
            ></StyledAvatar>
          )}
          <div className="name">
            {" "}
            <Tooltip title={name}>{name}</Tooltip>
          </div>
          <hr className="thin-hr w-100"></hr>

          {url === "myteam" && (
            <>
              <div className="position">
                <span className="bold">Designation</span>: {designation}
              </div>
              <div className="position">
                <span className="bold">Department</span>: {leveldepartmentname?.length > 0
                  ? leveldepartmentname?.join(", ")
                  : "-"}
              </div>
              <div
                className="position d-flex jusity-content-start align-items-start gap-1"
                onClick={() => handleEmailClick(id)}
              >
                <span className="bold">
                  <img src={Mail} alt="mail"></img>
                </span>{""}
                <ViewMore value={id} count={22} />
              </div>
            </>
          )}
          {url !== "myteam" && (
            <>
              <div className="position">
                <span className="bold">Employee ID</span>: {employeeid || "-"}
              </div>
              <div className="position">
                <span className="bold">Job Title</span>: {jobtitle}
              </div>
              <div className="position">
                <span className="bold">Type</span>: {usertype}
              </div>
              <div
                className="position d-flex jusity-content-start align-items-start gap-1"
                onClick={() => handleEmailClick(id)}
              >
                <span className="bold">
                  <img src={Mail} alt="mail"></img>
                </span>{" "}
                <ViewMore value={id} count={22} />
              </div>
            </>
          )}


        </div>
      </StyledCard>
    </>
  );
}

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  userRoleId: getUserRoleId(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
  userInfo: getUserInfo(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps, null)(TeamCard);
