import TabsList from "molecules/TabsList";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { getMenuList, getUserRoleId } from "redux/selectors";
import LeaveApproval from "./LeaveApproval";
import TimeSheetApproval from "./TimeSheet/TimeSheetApproval";
import BillingApproval from "./Engagement/Billing/BillingApprovals";
import PFAApproval from "./Engagement/AddNewEngagement/PFAApproval";

const Approval = ({ menuList, userRoleId }) => {
  let Menulist = menuList[userRoleId] || Object.values(menuList)[0] || menuList;

  Menulist = Array.isArray(Menulist) ? Menulist : [];

  const timeSheetMenu = Menulist.find(
    (item) => item.key === "timesheetapprovals"
  );

  const leaveApprovalAccess = Menulist.find(
    (item) => item.key === "approvals" //? "approvals" == "leaveapprovals"
  )?.access;

  const timeSheetApprovalAccess = timeSheetMenu?.access;

  const billingApprovalAccess = Menulist.find(
    (item) => item.key === "billingapprovals"
  )?.access;

  const timeSheetPermission = timeSheetMenu?.permission;
  const pfaapprovalaccess = Menulist?.find(
    (item) => item.key === "PFAapprovals" && item.access
  );

  const pfaapprovalpermission = pfaapprovalaccess?.permission;

  const { state } = useLocation();
  const [tabId, setTabId] = useState(state?.tabId || "leave_approvals");

  const handleTabSelect = (id) => {
    setTabId(id);
  };

  useEffect(() => {
    if (leaveApprovalAccess) {
      setTabId("leave_approvals");
    } else if (timeSheetApprovalAccess) {
      setTabId("timesheet_approvals");
    } else if (billingApprovalAccess) {
      setTabId("billing_approval");
    }
  }, [leaveApprovalAccess, timeSheetApprovalAccess, billingApprovalAccess]);

  useEffect(() => {
    if (state?.tabId) {
      setTabId(state.tabId);
    }
  }, [state]);

  const approvalTabs = useMemo(
    () => [
      leaveApprovalAccess
        ? {
            id: "leave_approvals",
            label: "Leave Approvals",
            component: <LeaveApproval />,
          }
        : null,
      timeSheetApprovalAccess
        ? {
            id: "timesheet_approvals",
            label: "Timesheet Approvals",
            component: (
              <TimeSheetApproval timeSheetPermission={timeSheetPermission} />
            ),
          }
        : null,
      billingApprovalAccess
        ? {
            id: "billing_approval",
            label: "Billing Approvals",
            component: (
              <BillingApproval timeSheetPermission={timeSheetPermission} />
            ),
          }
        : null,
      pfaapprovalaccess ? {
        id: "pfa_approval",
        label: "PFA Approval",
        component: (
          <PFAApproval pfaapprovalpermission={pfaapprovalpermission} />
        ),
      } : null,
    ].filter(Boolean),
    [
      leaveApprovalAccess,
      timeSheetApprovalAccess,
      timeSheetPermission,
      billingApprovalAccess,
      pfaapprovalaccess,
      pfaapprovalpermission,
    ]
  );

  return (
    <div>
      <TabsList
        items={approvalTabs}
        handleTabSelect={handleTabSelect}
        tabId={tabId}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  menuList: getMenuList(state),
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(Approval);
