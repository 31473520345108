import Table from 'atoms/Table';
import moment from 'moment';
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const columns = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    // className: "normal-column",
  },
  {
    title: "VAT/NON-VAT",
    dataIndex: "vat",
    key: "vat",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Currency",
    dataIndex: "Currency",
    key: "Currency",
    render: () => {
      return "USD";
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Submitted By",
    dataIndex: "submittedBy",
    key: "submittedBy",
  },
  {
    title: "Approved By",
    dataIndex: "approvedBy",
    key: "approvedBy",
  },
  {
    title: "Timesheet Week",
    dataIndex: "timesheetWeek",
    key: "timesheetWeek",
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
    render: (date) => {
      const dateFormat = moment(date).format("DD MMM YYYY");
      return `${dateFormat}`;
    },
  },
  {
    title: "Billing Description",
    dataIndex: "billingDescription",
    key: "billingDescription",
    className: "normal-column",
  
  }

];

function Claims() {

  const history = useHistory();
  const cursorStyle = { cursor: "pointer" }
  return (
    <div>
      <h6><span
        style={cursorStyle}
        className="back"
        onClick={() => {
          history.goBack();
        }}>Back </span> {">"} Claims</h6>
      <Table
        bordered={false}
        className="mb-5"
        // dataSource={activitylog}
        columns={columns}
        rowKey=""
        pagination={false}
      // loading={isLoading}
      // onRow={(record, index) => ({
      //   ref: index === activitylog.length - 30 ? lastElementRef : null,
      // })}
      />

    </div>
  )
}

export default Claims