import React, { useState, useEffect } from "react";
import styled from "styled-components";
import UISearch from "atoms/UISearch";
import { Col, Row, Button, Spin } from "antd";
import User from "../../assets/user_imgae.jpg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CALL_API_UPDATED } from "common/API";

const EngagementWrapper = styled.div`
  .sub-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 12px;
  }
  .status {
    font-style: normal;
    font-weight: medium;
    font-size: 16px;
    line-height: 19px;
  }
  .success {
    color: #0eb200;
  }
  .danger {
    color: #b70707;
  }
  .ant-btn-link {
    text-align: left;
    color: #397ff5 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .ant-btn-link > span {
    text-decoration: underline !important;
  }
  .margin_top_15 {
    margin-top: 15px;
  }
  .bg_light {
    background-color: #f7f7f7;
    border-radius: 5px;
    padding: 5px;
  }
  .align_end {
    display: flex;
    align-item: end;
  }
  .text-align-end {
    text-align: right;
  }
  .font_bold {
    font-weight: bold;
  }
  .user_image {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-left: 5px;
  }
  .user_image img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  .d_flex {
    display: flex !important;
  }
  .justify_end {
    justify-content: flex-end !important;
  }
  .float_end {
    float: right;
  }
`;

const Engagements = ({ clientID }) => {
  const history = useHistory();
  const [showLoader, toggleLoader] = useState(false);
  const [data, setData] = useState([]);
  const [clientid, setClientid] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setClientid(clientID);
    if (clientid) {
      getFulldata();
    }
  }, [clientid]);

  useEffect(() => {
    filterData();
  }, [searchQuery, data]);

  const filterData = () => {
    if (searchQuery === "") {
      setFilteredData(data);
    } else {
      const filtered = data?.filter((item) =>
        item?.information?.jobTitle
          .toLowerCase()
          .includes(searchQuery?.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const getFulldata = async () => {
    toggleLoader(true);

    const response = await CALL_API_UPDATED(
      `/insyts/clients/engagement?clientId=${clientid}&LastEvaluatedKey=0`,
      "get"
    );

    if (response.data) {
      toggleLoader(false);
      // Merge image URLs from employeeList into profileUrlList
      const updatedProfileUrlList = response.profileUrlList.map((profile) => {
        const email = Object.keys(profile)[0];
        const imageUrl = profile[email];
        return { [email]: imageUrl };
      });

      const updatedData = response.data.map((entry) => {
        const updatedEmployeeList = entry.employeeList.map((employee) => {
          const email = employee.userId;
          const imageUrl = updatedProfileUrlList.find(
            (profile) => Object.keys(profile)[0] === email
          )?.[email];
          return { ...employee, imageUrl };
        });
        return { ...entry, employeeList: updatedEmployeeList };
      });

      setData(updatedData);
      console.log("Engagement response data", response?.data);
    }
  };

  return (
    <EngagementWrapper>
      <Spin spinning={showLoader}>
        {data.length === 0 ? (
          <div className="text-center">No engagement added yet</div>
        ) : (
          <>
            <UISearch
              placeholder="Search"
              id={`serch-${data.length}`}
              onSearch={(e) => handleSearch(e)}
              name="searchQuery"
              value={searchQuery}
            />

            {filteredData?.map((e, idx) => {
              return (
                <>
                  <Row key={`e-filteredData-${idx + 23}`}>
                    <Col md={20} key={Math.random() * 1000}>
                      <h4 className="sub-title">{e?.information?.jobTitle}</h4>
                    </Col>
                    <Col md={4} key={`e-col-${idx}`}>
                      <span className="float_end">
                        <Button
                          type="link"
                          onClick={() => {
                            history.push(`/engagement/${e?.engagementId}`);
                          }}
                        >
                          View details
                        </Button>
                      </span>
                    </Col>
                  </Row>

                  <div className="status">
                    Status:{" "}
                    <span
                      className={e?.status === "Ongoing" ? "success" : "danger"}
                    >
                      {e?.status}
                    </span>
                  </div>

                  <Row>
                    <Col
                      key={`e-${e?.information?.country}-${idx}`}
                      sm={12}
                      md={8}
                      lg={5}
                      xl={4}
                      className="margin_top_15"
                    >
                      <div>
                        <strong>Country: </strong>
                      </div>
                      <div>{e?.information?.country}</div>
                    </Col>

                    <Col
                      key={`e-${e?.information?.approvedDate}-${idx}`}
                      sm={12}
                      md={8}
                      lg={5}
                      xl={4}
                      className="margin_top_15"
                    >
                      <div>
                        <strong>Date Approved: </strong>
                      </div>
                      <div>{e?.information?.approvedDate}</div>
                    </Col>
                    <Col
                      key={`e-${e?.stakeHolder?.planStartDate}-${idx}`}
                      sm={12}
                      md={8}
                      lg={5}
                      xl={4}
                      className="margin_top_15"
                    >
                      <div>
                        <strong>Plan Start Date: </strong>
                      </div>
                      <div>{e?.stakeHolder?.planStartDate}</div>
                    </Col>
                    <Col
                      key={`${idx}}`}
                      sm={12}
                      md={8}
                      lg={5}
                      xl={4}
                      className="margin_top_15"
                    >
                      <div>
                        <strong>Plan End Date: </strong>
                      </div>
                      <div>{e?.stakeHolder?.planEndDate}</div>
                    </Col>
                  </Row>
                  <div className="bg_light margin_top_15">
                    <Row>
                      <Col lg={18} md={14}>
                        {e?.employeeList?.map((profile, idx) => {
                          return (
                            <div key={idx} className="user_image">
                              <img
                                src={profile?.imageUrl || User}
                                alt={profile?.employeeName}
                              />
                            </div>
                          );
                        })}
                      </Col>

                      <Col lg={6} md={10}>
                        <span className="float_end">
                          <Button
                            type="text"
                            className=" font_bold "
                            onClick={() => history.push("/add-engagement")}
                          >
                            +Add Resources
                          </Button>
                        </span>
                      </Col>
                    </Row>
                  </div>
                </>
              );
            })}
          </>
        )}
      </Spin>
    </EngagementWrapper>
  );
};

export default Engagements;
