import { Modal, Table } from "antd";
import React from "react";
import styled from "styled-components";

const ViewResourceModal = ({
  show,
  onClose,
  selectedResource,
  generateSchedule,
}) => {
  const schedule = generateSchedule(
    selectedResource?.actualBookedStartDate,
    selectedResource?.actualBookedEndDate
  );

  return (
    <StyledModal
      title={"Work Hours Details"}
      visible={show}
      onCancel={() => {
        onClose();
      }}
      width={400}
      footer={[]}
    >
      <StyledTable
        showHeader={true}
        columns={[
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
            width: "30%",
            className: "action-status",
          },
          {
            title: "Work hours",
            dataIndex: "value",
            key: "value",
            width: "70%",
            render: (val) => {
              let value = parseFloat(
                (
                  (parseInt(selectedResource?.personDay) * 8) /
                  (schedule?.length || 1)
                ).toFixed(2)
              );
              return <span>{value} hours</span>;
            },
          },
        ]}
        dataSource={schedule}
        pagination={false}
      />
      <TotalHours schedule={schedule} selectedResource={selectedResource} />
    </StyledModal>
  );
};

export default ViewResourceModal;

const TotalHours = ({ schedule, selectedResource }) => {
  const totalHours = schedule?.reduce((acc, _curr) => {
    const value = parseFloat(
      (
        (parseInt(selectedResource?.personDay) * 8) /
        (schedule?.length || 1)
      ).toFixed(2)
    );

    return acc + value;
  }, 0);

  return (
    <div className="mt-2 fw-bold">
      <div>Total Hours: {totalHours.toFixed(2)} hours</div>
    </div>
  );
};

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 10px 14px;
  }
  .action-status {
    background-color: #f7f7f8;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border: 1px solid #dbdbdb;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;
