import React, { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import LeaveDonutChart from "./LeaveDonutChart";
import styled from "@emotion/styled";
import { getColor, LEAVE_ICON } from "common/Constants";
import { CALL_API } from "common/API";
import { dateInDetail, getKenyanDateTime } from "utils/Date";
import {
  getUserRoleId,
  getClientid,
  getLeaveCategoryList,
  getUserList,
  getUserInfo,
  getRoleList,
} from "redux/selectors";
import { connect } from "react-redux";
import { intersection } from "lodash";
import moment from "moment";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

function UserLeaveReport({
  ClientId,
  leaveCategoryList,
  userRoleId,
  userList,
  userInfo,
  roleList,
}) {
  const [loading, setLoading] = useState(false);
  const [leaveData, setLeaveData] = useState([]);

  const getCheckinData = async () => {
    setLoading(true);
    const response = await CALL_API(
      `employee-leave-details/${ClientId}`,
      "post",
      {
        year: dateInDetail(getKenyanDateTime()).year,
      }
    );

    setLeaveData(response);
    setLoading(false);
  };

  useEffect(() => {
    if (ClientId) {
      getCheckinData();
    }
  }, [ClientId]);

  const filterrolelist = roleList?.filter((val) => val?.roleId === userRoleId);
  const filterdepartmentid = filterrolelist[0]?.department;
  const filterlocationid = filterrolelist[0]?.locationId;

  const filterleaves = [];
  leaveCategoryList?.forEach((item) => {
    if (item?.departmentId?.length > 0 && item?.locationId?.length > 0) {
      if (
        intersection(item?.departmentId, filterdepartmentid).length > 0 &&
        intersection(item?.locationId, filterlocationid).length > 0
      ) {
        filterleaves.push(item);
      }
    } else {
      if (intersection(item?.locationId, filterlocationid).length > 0) {
        filterleaves.push(item);
      }
    }
  });

  const filteruser = userList?.filter(
    (item) => item.userid === userInfo?.userid
  );
  const filtergender = filteruser[0]?.gender;
  const filteredCategoriesbygender =
    filtergender?.toLowerCase() === "male"
      ? filterleaves?.filter(
          (item) => item.eligible?.toLowerCase() !== "female"
        )
      : filterleaves?.filter((item) => item.eligible?.toLowerCase() !== "male");

  const filterbycountry = filteredCategoriesbygender?.filter((item) =>
    item?.locationId?.includes(filteruser[0]?.baselocationid)
  );

  return (
    <StyledContainer>
      {filterbycountry?.length > 0 && <StyledTitle>LEAVE REPORT</StyledTitle>}

      <Row
        gutter={{
          xs: 8,
          sm: 12,
          md: 16,
        }}
      >
        {filterbycountry?.map((data, index) => (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={8}
            key={data.leave_category_short_name}
          >
            <Spin spinning={loading}>
              <LeaveDonutChart
                iconName={LEAVE_ICON[7]}
                title={`${data.leave_category_name.toUpperCase()} -
                  ${moment().format("YYYY")}`}
                data={
                  leaveData?.find(
                    (item) => item[data?.leave_category_short_name]
                  )?.[data?.leave_category_short_name] ?? []
                }
                color={getColor(index)}
              />
            </Spin>
          </Col>
        ))}
      </Row>
    </StyledContainer>
  );
}
const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  userList: getUserList(state),
  userInfo: getUserInfo(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps, null)(UserLeaveReport);

const StyledContainer = styled.div`
  padding: 15px;
  box-shadow: 0px 4px 30px 0px #0000001f;
  border-radius: 8px;
`;
