import { Col, Row, Spin } from "antd";
import React, { useContext } from "react";
import { dateInDetail, momentDate } from "utils/Date";
import {
  SmallCalendar,
  StyledAntCalendarFull,
  StyledAntCalendarSmall,
  StyledLeftPanel,
  StyledNextIcon,
  StyledPreviousIcon,
  StyledTitle,
  StyledUpcomingHolidaysContainer,
} from "./StyledComponents";
import UpcomingHolidays from "organisms/UpcomingHolidays";
import { CalendarContext } from ".";

const ReusableCalendar = ({
  dateFullCellRender,
  isCalendarLoading,
  calendarTitle,
  leftPanelContent,
  showHoliday = true,
}) => {
  const { miniCalendarCurrentDate, holidayList, previousMonth, nextMonth } =
    useContext(CalendarContext);

  const { monthName, year } = dateInDetail(miniCalendarCurrentDate);

  return (
    <Row gutter={20}>
      <StyledLeftPanel xs={24} sm={15} md={10} lg={10} xl={6}>
        <StyledTitle>{calendarTitle}</StyledTitle>
        <SmallCalendar>
          <Col span={14} className="font-bold">
            {monthName} {year}
          </Col>
          <Col span={10} className="align-right">
            <StyledPreviousIcon onClick={previousMonth} />
            <StyledNextIcon onClick={nextMonth} />
          </Col>
        </SmallCalendar>
        <StyledAntCalendarSmall
          fullscreen={false}
          value={momentDate(miniCalendarCurrentDate)}
        />

        {showHoliday && (
          <StyledUpcomingHolidaysContainer>
            <UpcomingHolidays holidayList={holidayList}></UpcomingHolidays>
          </StyledUpcomingHolidaysContainer>
        )}

        {leftPanelContent ? leftPanelContent : null}
      </StyledLeftPanel>

      <Col xs={24} sm={24} md={19} xl={18}>
        <Row>
          <Col xs={24}>
            <StyledTitle>
              <StyledPreviousIcon
                onClick={previousMonth}
                style={{
                  fontSize: "1.2rem",
                }}
              />
              {monthName} {year}
              <StyledNextIcon
                onClick={nextMonth}
                style={{
                  fontSize: "1.2rem",
                }}
              />
            </StyledTitle>
          </Col>
        </Row>
        <Spin spinning={isCalendarLoading}>
          <StyledAntCalendarFull
            value={momentDate(miniCalendarCurrentDate)}
            fullscreen={true}
            dateFullCellRender={dateFullCellRender}
          />
        </Spin>
      </Col>
    </Row>
  );
};

export default ReusableCalendar;
