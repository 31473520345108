import { DatePicker, Form, Input, message, Spin, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import GridBuilder from 'UIBuilderEngine/GridBuilder';
import { getDeclarationJson } from './DeclarationJSON';
import styled from 'styled-components';
import { ButtonFilled } from 'reusableComponent/UIButtons';
import { CALL_API } from 'common/API';
import { STATUS_CODE } from 'common/Constants';
import moment from 'moment';
import queryKeys from 'common/queryKey';
import { useFetch } from 'hooks/useFetch';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { getClientid } from 'redux/selectors';

const StyledDeclaration = styled.div`
.ant-form-item-label > label{
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 100% !important; 
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}
    .styled-text{
    font: normal normal normal 20px/35px Pacifico;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: contents;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}
`

const Declaration = ({ filteruser, type="" }) => {

    const [form] = Form.useForm();
    const containerRef = useRef()
    const [clientName, setClientName] = useState("");
    const [natureOfRelationship, setNatureOfRelationship] = useState("");
    const [loading, setLoading] = useState(false)
    const [typeBtn, setTypeBtn] = useState("")
    const clientId = useSelector(getClientid)

    const printComponentRef = React.useRef();
  
    const handlePrint = useReactToPrint({
      content: () => printComponentRef.current,
    });
  
    const handlePrintWithDelay = () => {
      setLoading(true);
      setTimeout(() => {
        handlePrint();
        setLoading(false);
      }, 2000); // 2-second delay
    };

    const { getDeclaration } =
    queryKeys;

    const { key, url } = getDeclaration(filteruser?.userid);

    const { data: declarationDatas, isLoading, refetch } = useFetch(key, url, {
      enabled: !!filteruser?.userid,
    });

  
    const declarationData = declarationDatas?.response?.response


    const [data, setData] = useState({});
    const [year, setYear] = useState("");
    const [loader,setLoader] =useState(false);

    useEffect(()=>{
        setData(
            {
                "reviewed_clients_list": declarationData?.reviewed_clients_list,
                "inform_directors_about_changes": declarationData?.inform_directors_about_changes,
                "inform_firm_about_client_employment": declarationData?.inform_firm_about_client_employment,
                "treat_client_matters_confidentially": declarationData?.treat_client_matters_confidentially,
                "professional_duties_with_integrity": declarationData?.professional_duties_with_integrity,
                "no_financial_interests_in_clients": declarationData?.no_financial_interests_in_clients,
                "no_loans_or_guarantees_from_clients": declarationData?.no_loans_or_guarantees_from_clients,
                "no_commercial_relationship_with_clients": declarationData?.no_commercial_relationship_with_clients,
                "no_personal_or_family_relationship_with_clients": declarationData?.no_personal_or_family_relationship_with_clients,
                "no_director_or_officer_position_in_clients": declarationData?.no_director_or_officer_position_in_clients,
                "not_audited_client_for_more_than_7_years": declarationData?.not_audited_client_for_more_than_7_years,
                "not_accepted_gifts_or_hospitality_from_clients": declarationData?.not_accepted_gifts_or_hospitality_from_clients,
                "no_direct_or_indirect_charging_of_contingent_fees": declarationData?.no_direct_or_indirect_charging_of_contingent_fees,
                "no_litigation_against_clients": declarationData?.no_litigation_against_clients,
                "Select_as_appropriate": declarationData?.Select_as_appropriate,
                "court_judgment_against_debt_last_10_years": declarationData?.court_judgment_against_debt_last_10_years,
                "compromise_arrangement_with_creditors_last_10_years": declarationData?.compromise_arrangement_with_creditors_last_10_years,
                "bankruptcy_declaration_or_order": declarationData?.bankruptcy_declaration_or_order,
                "signed_trust_deed_or_arrangement_with_creditors": declarationData?.signed_trust_deed_or_arrangement_with_creditors,
                "pleaded_or_found_guilty_of_offence": declarationData?.pleaded_or_found_guilty_of_offence,
                "civil_action_against_professional_activities_last_5_years": declarationData?.civil_action_against_professional_activities_last_5_years,
                "disqualified_from_director_or_management": declarationData?.disqualified_from_director_or_management,
                "refused_or_restricted_right_to_carry_on_trade": declarationData?.refused_or_restricted_right_to_carry_on_trade,
                "investigated_for_misconduct_resulting_in_formal_complaint": declarationData?.investigated_for_misconduct_resulting_in_formal_complaint,
                "subject_to_professional_body_disiplinary_procedures": declarationData?.subject_to_professional_body_disiplinary_procedures,
                "reprimanded_or_excluded_from_profession": declarationData?.reprimanded_or_excluded_from_profession,
                "dismissed_or_requested_to_resign_from_employment": declarationData?.dismissed_or_requested_to_resign_from_employment,
                "reprimanded_or_disciplined_by_regulatory_body": declarationData?.reprimanded_or_disciplined_by_regulatory_body,
                "subject_to_court_order_by_regulatory_body": declarationData?.subject_to_court_order_by_regulatory_body,
                "undergoing_investigation_or_disciplinary_procedures": declarationData?.undergoing_investigation_or_disciplinary_procedures,
                "details": declarationData?.details,
                "nature_of_relationship": declarationData?.nature_of_relationship,
                "client_name": declarationData?.client_name,
                "confirm_information": declarationData?.confirm_information,
                "employment_status": declarationData?.employment_status,
                "status": declarationData?.status
            }
        );

        const formattedYear = declarationData?.year === "Invalid date" 
        ? null 
        : moment(declarationData?.year);
        setYear(formattedYear);
        setClientName(declarationData?.client_name);
        setNatureOfRelationship(declarationData?.nature_of_relationship);
    },[declarationData]);


    const handleSubmitForm = async (value,e) => {
        setLoader(true);
        const now = new Date();

        const currentDateTime = now.toISOString(); 
       
        const { code } = await CALL_API(
            `declaration/${filteruser?.userid}`,
            "post",
            {
                "userId": filteruser?.userid,
                "name": filteruser?.fullname,
                "year": year ? moment(year).format("YYYY") : "",
                "reviewed_clients_list": data?.reviewed_clients_list,
                "inform_directors_about_changes": data?.inform_directors_about_changes,
                "inform_firm_about_client_employment": data?.inform_firm_about_client_employment,
                "treat_client_matters_confidentially": data?.treat_client_matters_confidentially,
                "professional_duties_with_integrity": data?.professional_duties_with_integrity,
                "no_financial_interests_in_clients": data?.no_financial_interests_in_clients,
                "no_loans_or_guarantees_from_clients": data?.no_loans_or_guarantees_from_clients,
                "no_commercial_relationship_with_clients": data?.no_commercial_relationship_with_clients,
                "no_personal_or_family_relationship_with_clients": data?.no_personal_or_family_relationship_with_clients,
                "no_director_or_officer_position_in_clients": data?.no_director_or_officer_position_in_clients,
                "not_audited_client_for_more_than_7_years": data?.not_audited_client_for_more_than_7_years,
                "not_accepted_gifts_or_hospitality_from_clients": data?.not_accepted_gifts_or_hospitality_from_clients,
                "no_direct_or_indirect_charging_of_contingent_fees": data?.no_direct_or_indirect_charging_of_contingent_fees,
                "no_litigation_against_clients": data?.no_litigation_against_clients,
                "client_name": clientName || "",
                "nature_of_relationship": natureOfRelationship || "",
                "Select_as_appropriate": data?.Select_as_appropriate,
                "court_judgment_against_debt_last_10_years": data?.court_judgment_against_debt_last_10_years,
                "compromise_arrangement_with_creditors_last_10_years": data?.compromise_arrangement_with_creditors_last_10_years,
                "bankruptcy_declaration_or_order": data?.bankruptcy_declaration_or_order,
                "signed_trust_deed_or_arrangement_with_creditors": data?.signed_trust_deed_or_arrangement_with_creditors,
                "pleaded_or_found_guilty_of_offence": data?.pleaded_or_found_guilty_of_offence,
                "civil_action_against_professional_activities_last_5_years": data?.civil_action_against_professional_activities_last_5_years,
                "disqualified_from_director_or_management": data?.disqualified_from_director_or_management,
                "refused_or_restricted_right_to_carry_on_trade": data?.refused_or_restricted_right_to_carry_on_trade,
                "investigated_for_misconduct_resulting_in_formal_complaint": data?.investigated_for_misconduct_resulting_in_formal_complaint,
                "subject_to_professional_body_disiplinary_procedures": data?.subject_to_professional_body_disiplinary_procedures,
                "reprimanded_or_excluded_from_profession": data?.reprimanded_or_excluded_from_profession,
                "dismissed_or_requested_to_resign_from_employment": data?.dismissed_or_requested_to_resign_from_employment,
                "reprimanded_or_disciplined_by_regulatory_body": data?.reprimanded_or_disciplined_by_regulatory_body,
                "subject_to_court_order_by_regulatory_body": data?.subject_to_court_order_by_regulatory_body,
                "undergoing_investigation_or_disciplinary_procedures": data?.undergoing_investigation_or_disciplinary_procedures,
                "details": data?.details,
                "status": typeBtn,
                "confirm_information": data?.confirm_information,
                "employment_status": data?.employment_status,
                "clientId": clientId,
                "time": currentDateTime

            }
        );
        if (code === STATUS_CODE.SUCCESS) {
            message.success('updated Successfully')
            refetch()
          }
          setLoader(false);
    };

    const tabledata = [
        {
            client_name: "",
            nature_of_relationship: ""
        }
    ]

    const columns = [
        {
            title: "Client Name",
            dataIndex: "client_name",
            key: "client_name",
            align: "center",
            width: "50%",
            render: (text, record, index) => {
                return (
                    <Form.Item
                        label=""
                        name="client_name" // Use a unique name for each item
                        rules={[
                            { required: true, message: "Please input your client name!" },
                        ]}
                    >
                        <Input.TextArea
                            bordered={false}
                            placeholder="Enter"
                            rows={2}
                            // value={clientName}
                            style={{
                                resize: "none",
                            }}
                            onChange={(e) => setClientName(e.target.value)} // Update the change handler
                        />
                    </Form.Item>
                );
            }
        },
        
        {
            title: "Nature of relationship",
            dataIndex: "nature_of_relationship",
            key: "nature_of_relationship",
            align: "center",
            width: "50%",
            render: (text) => {
                return <Form.Item
                    label=""
                    name="nature_of_relationship"
                    rules={[
                        { required: true, message: "Please input your nature of relationship!" },
                    ]}
                >
                    <Input.TextArea
                        bordered={false}
                        placeholder="Enter"
                        rows={2}
                        // value={natureOfRelationship}
                        style={{
                            resize: "none",
                            border: "none"
                        }}
                        onChange={(e) => setNatureOfRelationship(e.target.value)}
                    />
                </Form.Item>
            }
        },
    ]

    const { DeclarationForm } = getDeclarationJson(tabledata, columns, handleSubmitForm, handlePrintWithDelay, loading, data,loader, type, setTypeBtn, filteruser);


    const handleFormChange = (e) => {
        const { name, type, value, checked, id } = e.target;

        if(type === 'checkbox'){
            setData((prevData) => ({
                ...prevData,
                [id]: checked,
            }));
        }else{
            setData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
        
    };
    return (
        <StyledDeclaration>
            <Spin spinning={loading}>
            <div className='d-flex align-items-center  gap-3'>
                <h6>Name: {filteruser?.fullname}</h6>
                    <div ref={containerRef}>
                       
                            <DatePicker
                                className="w-100"
                                picker='year'
                                value={year}
                                format="YYYY"
                                placeholder="Select date"
                                getPopupContainer={() => containerRef.current}
                                onChange={(date)=> setYear(date ? moment(date).startOf('year') : null)}
                                disabledDate={(current) => current && current.year() > 2024}
                            />
                    </div>
                    
                <span className='bg-info p-1 px-2'>{(declarationData === undefined || declarationData?.status === "draft" ||  declarationData?.status === "") ? "pending" : "completed"}</span>
                       
                    
                    
            </div>
            <div ref={printComponentRef} className='p-2'>
            <h6>Section A: Annual Independence Declaration:</h6>
            <p>The audit firm and the individual auditor should be free in both fact and appearance from personal, external, and organizational impairments to independence. Auditors are independent whey they can carry out their work freely and objectively. Independence permits auditors to render the impartial and unbiased judgment essential to the proper conduct of audits.</p>
            <p>We expect our auditors to maintain independence of mental attitude in the conduct of all assigned work; to be objective, fair, and impartial, and to conduct themselves so that auditees and third parties will see our office in this way. Each staff member must promptly notify the Audit Manager, or higher, in writing concerning an audit, or that might lead others to question it. If a staff member has any doubt about whether a situation may be impaired, he or she should resolve the question in favour of disclosure.</p>
            <p>In line with the above, I confirm the following:</p>
            <GridBuilder
                formStructureJson={DeclarationForm}
                handleChange={handleFormChange}
                formSubmit={handleSubmitForm}
                initialValues={data}
                disabled={(data?.status === undefined || data?.status === "draft" || data?.status === "" ) && type !== "Declaration" ? false : true}
            />
</div>
</Spin>
        </StyledDeclaration>
    )
}

export default Declaration