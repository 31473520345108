import { Col, Form, Modal, Row, Table } from "antd";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { dateInDetail } from "utils/Date";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import { getUserList } from "redux/selectors";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const StyledModal = styled(Modal)`
  width: 100% !important;
  max-width: 700px;
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
  .ant-modal-body {
    padding-top: 10px;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 10px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function AttendanceStaffList({
  displayModal,
  toggleModal,
  title,
  list,
  name,
  reportType,
  isHrDashboard,
  userInfo
}) {
  const columns = [
    {
      title: "Name",
      dataIndex:
        reportType === "leave"
          ? "name"
          : reportType === "Absent"
          ? "fullname"
          : "fullName",
      key: "fullName",
      // width: '50%',
    },
  ];

  if (reportType === "leave") {
    columns.push({
      title: "Leave Category",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      align: "center",
    });
    columns.push({
      title: "Start date",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      render: (start_date) => {
        if (start_date === "") return "-";
        const { date: day_th, shortMonthName, year } = dateInDetail(start_date);
        return `${day_th} ${shortMonthName} ${year}`;
      },
    });
    columns.push({
      title: "End date",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      render: (end_date) => {
        if (end_date === "") return "-";
        const { date: day_th, shortMonthName, year } = dateInDetail(end_date);
        return `${day_th} ${shortMonthName} ${year}`;
      },
    });
    columns.push({
      title: "No.of leave days",
      dataIndex: "number_of_days",
      key: "number_of_days",
      align: "center",
    });
  } else if (reportType === "Present") {
    if (name === "onTime") {
      // columns.push({
      //   title: 'Email ID',
      //   dataIndex: 'email_id',
      //   key: 'email_id',
      //   width: '50%',
      // });
      columns.push({
        title: "Station Name",
        dataIndex: "loginLocationName",
        key: "loginLocationName",
        // width: '50%',
        align: "center",
      });

      columns.push({
        title: "Check-In Time",
        dataIndex: "checkinTime",
        key: "checkinTime",
        // width: '50%',
        align: "center",
      });
    } else if (name === "Late") {
      // columns.push({
      //   title: 'Email ID',
      //   dataIndex: 'email_id',
      //   key: 'email_id',
      //   width: '30%',
      // });
      columns.push({
        title: "Station Name",
        dataIndex: "loginLocationName",
        key: "loginLocationName",
        // width: '50%',
        align: "center",
      });
      columns.push({
        title: "Check-In Time",
        dataIndex: "checkinTime",
        key: "checkinTime",
        // width: '50%',
        align: "center",
        // render: (checkinTime) => {
        //   console.log("checkinTime-->", checkinTime)
        //   const { time24 } = dateInDetail(checkinTime);
        //   return `${time24}`;
        // }
      });
    } else {
      // columns.push({
      //   title: 'Email ID',
      //   dataIndex: 'email_id',
      //   key: 'email_id',
      //   width: '30%',
      // });
      columns.push({
        title: "Check-In Time",
        dataIndex: "checkinTime",
        key: "checkinTime",
        width: "50%",
        align: "center",
        render: (checkinTime) => {
          const { time24 } = dateInDetail(checkinTime);
          return `${time24}`;
        },
      });
      columns.push({
        title: <div>Google Location</div>,
        dataIndex: "location",
        key: "loginLatitude",
        width: "50%",
        align: "center",
        render: (_, record) => {
          const url1 =
            "http://maps.google.com/maps/?q=" +
            record.loginLatitude +
            "," +
            record.logoutLongitude +
            "";
          return (
            <div>
              {record.loginLatitude !== "" && record.logoutLongitude !== "" ? (
                <>
                  <div id="loc">
                    <a href={url1} style={{ fontSize: "12px" }} target="_blank">
                      {record.loginLatitude}, {record.logoutLongitude}
                    </a>
                  </div>
                </>
              ) : (
                "-"
              )}
            </div>
          );
        },
      });
    }
  } else {
    columns.push({
      title: "Email ID",
      dataIndex: "userid",
      key: "userid",
      // width: '50%',
      align: "center",
    });
  }

  const [mappedList, setMappedList] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  const userList = useSelector(getUserList);

  const [form] = Form.useForm();

  const uniqueLocations = [
    ...new Set(mappedList?.map((item) => item.managingoffice).flat()),
  ];

  const locationList = uniqueLocations;

  const handleFilter = () => {
    const values = form.getFieldsValue();

    const country = values?.country || [];

    if (country.length === 0) {
      setFilteredList(mappedList);
      return;
    }

    const filteredList = mappedList?.filter((item) => {
      if (country.length > 0) {
        return country.some((location) =>
          item.managingoffice.includes(location)
        );
      }
      return true;
    });

    setFilteredList(filteredList);
  };

  useEffect(() => {
    const allList = list?.map((item) => {
      const user = userList?.find((user) => user.userid === item.userid);
      return {
        ...item,
        managingoffice: user?.managingoffice || [],
      };
    });

    setMappedList(allList);
    setFilteredList(allList);
  }, [list, userList]);

  const filteruser = userList?.filter(
    (item) => item?.userid === userInfo.userid
  );

  return (
    <>
      <StyledModal
        title={title}
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
        destroyOnClose
      >
        {isHrDashboard && filteruser[0].OfficeSupervisorId === "none" && (
          <Form
            form={form}
            layout="vertical"
            className="mt-2"
            onValuesChange={handleFilter}
          >
            <Row gutter={16}>
              <Col span={8}></Col>
              <Col span={8}></Col>
              <Col span={8}>
                <DropdownCheckBox
                  name="country"
                  label="Country"
                  options={locationList?.map((location) => ({
                    label: location,
                    value: location,
                  }))}
                  form={form}
                  handleFormChange={handleFilter}
                />
              </Col>
            </Row>
          </Form>
        )}

        <StyledTable
          dataSource={filteredList}
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="user_id"
          pagination={false}
        />
      </StyledModal>
    </>
  );
}

export default AttendanceStaffList;
