import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ManagerDashboard from "./ManagerDashboard";
import HrDashboard from "./HrDashboard";
import MyDashboard from "./MyDashboard";
import TabsList from "molecules/TabsList";

// Redux selectors and API call
import {
  getUserRoleId,
  getClientid,
  getRoleList,
  getUserRole,
  getMenuList,
  getSelectedDepartmentId,
  getDepartmentList,
  getAllDepartmentList,
  getLeaveCategoryList,
  getUserList,
  getUserInfo,
  getLoadingRoute,
} from "redux/selectors";
import { CALL_API } from "common/API";
import { Spin } from "antd";

function Dashboard({
  userRoleId,
  menuList,
  ClientId,
  selectedDepartmentId,
  departmentList,
  AllDepartmentList,
  roleList,
  leaveCategoryList,
  userList,
  userInfo,
  isLoadingRoute,
}) {
  const Menulist =
    menuList[userRoleId] || Object.values(menuList)[0] || menuList;
  const [LeaveList, setLeaveList] = useState([]);
  const userId = userInfo?.userid || "";
  const [tabId, setTabId] = useState(0);

  const userFullInfo = userList.find((user) => user.userid === userId);
  const employeeid = userFullInfo?.employeeid || "";

  const getLeaveList = async () => {
    try {
      const { response } = await CALL_API(`owner-leave/${ClientId}`, "get", {});
      setLeaveList(response);
    } catch (error) {
      console.log("[getLeaveList], Error-->", error);
    }
  };

  useEffect(() => {
    getLeaveList();
  }, []);

  const handleTabSelect = (key) => {
    setTabId(key);
  };

  const filteredUserList = userList.filter((user) => user.statusid === "1");

  let tabMenus =
    Menulist &&
    Menulist.filter(
      (item) =>
        item.key === "dashboard" ||
        item.key === "managerdashboard" ||
        item.key === "hrdashboard"
    );

  let newArray = [];
  newArray.push(...tabMenus);

  const myDashboard = <MyDashboard />;
  const managerDashboard = isLoadingRoute ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spin size="large" />{" "}
    </div>
  ) : (
    <ManagerDashboard
      ClientId={ClientId}
      selectedDepartmentId={selectedDepartmentId}
      userRoleId={userRoleId}
      departmentList={departmentList}
      AllDepartmentList={AllDepartmentList}
      roleList={roleList}
      Menulist={Menulist}
      leaveCategoryList={LeaveList}
      userList={filteredUserList}
      employeeid={employeeid}
    />
  );

  const hrDashboard = (
    <HrDashboard
      ClientId={ClientId}
      selectedDepartmentId={selectedDepartmentId}
      userRoleId={userRoleId}
      departmentList={departmentList}
      AllDepartmentList={AllDepartmentList}
      roleList={roleList}
      Menulist={Menulist}
      leaveCategoryList={LeaveList}
      userList={filteredUserList}
      userInfo={userInfo}
      employeeid={employeeid}
      tabId={tabId}
    />
  );

  let items =
    newArray &&
    newArray
      .filter((data) => data.access === true)
      .map((data, index) => ({
        id: index,
        label: data.subtitle,
        component:
          data.path === "dashboard"
            ? myDashboard
            : data.path === "managerdashboard"
            ? managerDashboard
            : data.path === "hrdashboard"
            ? hrDashboard
            : myDashboard,
      }));

  return (
    <>
      {items.length > 1 && (
        <TabsList items={items} handleTabSelect={handleTabSelect} />
      )}
      {items.length === 1 && (
        <>
          {newArray?.map((data) => (
            <React.Fragment key={data.key}>
              {data?.access && data?.path === "dashboard" && myDashboard}
              {data?.access &&
                data?.path === "managerdashboard" &&
                managerDashboard}
              {data?.access && data?.path === "hrdashboard" && hrDashboard}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
  userRoleType: getUserRole(state),
  menuList: getMenuList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  departmentList: getDepartmentList(state),
  AllDepartmentList: getAllDepartmentList(state),
  leaveCategoryList: getLeaveCategoryList(state),
  userList: getUserList(state),
  userInfo: getUserInfo(state),
  isLoadingRoute: getLoadingRoute(state),
});

export default connect(mapStateToProps, null)(Dashboard);
