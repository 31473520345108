import { VALIDATE_CHARACTER } from "common/Constants";

export const EligibleList = ["Both", "Male", "Female"];

const DoYouWant = ["Yes", "No"];
export const getEditLeaveJson = (data) => {
  const EditLeaveForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          // Row 1
          [
            {
              id: 1,
              label: "Leave Name",
              type: "text",
              name: "leaveCategoryName",
              Placeholder: "Leave Name",
              value: data["leaveCategoryName"],
              validationRules: [
                {
                  required: true,
                  message: `Leave Name is required`,
                },
                {
                  validator: (rule, value, callback) => {
                    if (value && !VALIDATE_CHARACTER.test(value)) {
                      callback(
                        "Leave Name should not contain special characters"
                      );
                    } else {
                      callback();
                    }
                  },
                },
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Number of Days",
              type: "text",
              name: "numberOfLeaves",
              Placeholder: "Number of Days",
              value: data["numberOfLeaves"],
              validationRules: [
                {
                  required: true,
                  message: `Number of Days is required`,
                },
              ],
            },
          ],
          [
            {
              id: 3,
              label: "Eligible",
              type: "radio",
              name: "eligible",
              Placeholder: "Select",
              value: "",
              options: EligibleList,
              validationRules: [
                {
                  required: true,
                  message: `eligible is required`,
                },
              ],
            },
          ],
          [
            {
              id: 11,
              label: "Do you want to include Saturday in this leave?",
              type: "radio",
              name: "isSaturday",
              Placeholder: "Select",
              value: "",
              options: DoYouWant,
              validationRules: [
                {
                  required: true,
                  message: `This field is required`,
                },
              ],
            },
          ],
          [
            {
              id: 4,
              label: "Do you want to include Sundays in this leave?",
              type: "radio",
              name: "weekend",
              Placeholder: "Select",
              value: "",
              options: DoYouWant,
              validationRules: [
                {
                  required: true,
                  message: `This field is required`,
                },
              ],
            },
          ],
          [
            {
              id: 5,
              label: `Do you want to include the "Holidays" you provide in this leave?`,
              type: "radio",
              name: "holiday",
              Placeholder: "Select",
              value: "",
              options: DoYouWant,
              validationRules: [
                {
                  required: true,
                  message: `This field is required`,
                },
              ],
            },
          ],
        ],
      },
    ],
  };

  return {
    EditLeaveForm,
  };
};
