import { Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React from "react";
import countries from "common/countries.json";
import { Required } from "./index.js";

const labelStyle = {
  width: "120px",
  display: "inline-block",
  textAlign: "start",
};

const classificationList = [
  {
    label: "High",
    value: "High",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Low",
    value: "Low",
  }
]

const InformationFields = ({disabled}) => {
  const countryOptions = countries.map((country) => {
    return {
      label: country.country,
      value: country.country,
    };
  });

  return (
    <Card className="fields-card">
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label={
              <span style={labelStyle}>
                Job Title <Required />
              </span>
            }
            name="job_title"
            rules={[
              {
                required: true,
                message: "Please enter Job Title!",
              },
            ]}
          >
            <Input placeholder="Enter"  disabled={disabled}/>
          </Form.Item>

          <Form.Item
            label={<span style={labelStyle}>Risk Classification</span>}
            name="classification"
          >
            <Input placeholder="Enter" disabled />
          </Form.Item>

          <Form.Item
            label={<span style={labelStyle}>Country</span>}
            name="country"
          >
             <Input placeholder="Enter" disabled />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={<span style={labelStyle}>Approved Date</span>}
            name="approved_date"
            style={{
              width: "100%",
            }}
          >
            <DatePicker placeholder="Enter" format={"DD-MM-YYYY"}  disabled={disabled}/>
          </Form.Item>

          {/* <Form.Item
            label={<span style={labelStyle}>Stage</span>}
            name="stage"
            style={{
              width: "100%",
            }}
          >
            <Input placeholder="Enter" />
          </Form.Item> */}
        </Col>
      </Row>

      <Form.Item
        label={
          <span style={labelStyle}>
            Objective <Required />
          </span>
        }
        name="objective"
        style={{
          width: "100%",
        }}
        rules={[
          {
            max: 1000,
            message: "Max 1000 Character",
          },
          {
            required: true,
            message: "Please enter objective!",
          },
        ]}
      >
        <Input.TextArea
          placeholder="Enter"
          style={{
            resize: "none",
          }}
          disabled={disabled}
        />
      </Form.Item>
      <div className="max-char-info">Max 1000 Character</div>
    </Card>
  );
};

export default InformationFields;
