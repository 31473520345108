import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Table, Spin, Select, message, TimePicker } from "antd";
import styled from "@emotion/styled";
import { connect, useSelector } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getAllDepartmentList,
  getClientid,
  getMenuList,
  getallShiftList,
  getlevelList,
  getUserList,
} from "redux/selectors";
import COLORS from "common/Colors";
import { CALL_API } from "common/API";
import { CSVLink } from "react-csv";
import { reduce, uniqBy, isEmpty } from "lodash";
import moment from "moment";
import { STATUS_CODE } from "common/Constants";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import { setAllShiftList, setUserList } from "redux/actions";
import { bindActionCreators } from "redux";
import useEmployeeFilter from "hooks/useEmployeeFilter";

const StyledTable = styled(Table)`
  /* padding-top: 10px; */
  table {
    width: 100% !important;
    border: 1px solid #ccc;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    color: ${COLORS.TEXT.PRIMARY};
    font-size: 14px;
  }
  .ant-table-row:nth-child(even) {
    background-color: #fafafa;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.TEXT.PRIMARY};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
  margin: 0px;
`;

function Shift({
  userList,
  userRoleId,
  selectedDepartmentId,
  ClientId,
  menuList,
  updateUserList,
  saveAllShift,
  allShiftList,
}) {
  const [shiftList, setShiftList] = useState([]);
  const [displayshiftLoader, setDisplayshiftLoader] = useState(false);
  const [shiftCSVlist, setShiftCSVlist] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [shiftFilterList, setShiftFilterList] = useState(shiftList);
  const csvLinkEl = useRef();
  const containerRef = useRef(null);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  useEffect(() => {
    if (userRoleId !== 5) setSelectedDepartment(selectedDepartmentId);
  }, [selectedDepartmentId]);

  // const STAFF_LIST = uniqBy(
  //   shiftList.map((val) => {
  //     return {
  //       user_id: val?.userid,
  //       name: val?.fullname,
  //       type: val?.usertype,
  //       managingoffice: val?.managingoffice,
  //     };
  //   }),
  //   "user_id"
  // );

  const levelList = useSelector(getlevelList);

  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  const [servicelineData, setServicelinefetchData] = useState([]);
  const [subservicelineData, setsubservicelineData] = useState([]);
  const [departmentLevelData, setDepartmentLevelData] = useState([]);

  useEffect(() => {
    let level1_values = new Set();
    let level2_values = new Set();
    let level3_values = new Set();
    let level4_values = new Set();
    let level5_values = new Set();

    levelList.forEach((level) => {
      switch (level.levelName) {
        case "Level1":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level1_values.add(item);
            });
          });
          break;
        case "Level2":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level2_values.add(item);
            });
          });
          break;
        case "Level3":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level3_values.add(item);
            });
          });
          break;
        case "Level4":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level4_values.add(item);
            });
          });
          break;
        case "Level5":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level5_values.add(item);
            });
          });
          break;
        default:
          break;
      }
    });

    setManagingOfficeData(Array.from(level1_values));
    setCompetencyQCfetchData(Array.from(level2_values));
    setServicelinefetchData(Array.from(level3_values));
    setsubservicelineData(Array.from(level4_values));
    setDepartmentLevelData(Array.from(level5_values));
  }, [levelList]);

  const { FilterJSX, getFilterList } = useEmployeeFilter({
    employeeFilterList: shiftList,
    containerRef,
    managingOfficeData,
    competencyQCData,
    departmentLevelData,
    subservicelineData,
    servicelineData,
    // STAFF_LIST,
    menuName: "shift",
  });

  const CSVHeaders = [
    { label: "Employee ID", key: "employeeid" },
    { label: "Name", key: "username" },
    { label: "Shift Start Time", key: "shiftstarttime" },
    { label: "Shift End Time", key: "shiftendtime" },
  ];
  const columns = [
    {
      title: "Employee ID",
      dataIndex: "employeeid",
      key: "employeeid",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      width: "10%",
    },
    {
      title: "Shift Start Time",
      dataIndex: "shiftstarttime",
      key: "shiftstarttime",
      width: "10%",
      render: (_, render) => {
        let date = render?.shiftstarttime;
        return (
          <div>
            <TimePicker
              disabled={
                Menulist?.find(
                  (item) =>
                    item?.key === "manage" &&
                    item?.subMenu.find(
                      (subItem) =>
                        subItem?.key === "shift" && subItem?.permission.edit
                    )
                )
                  ? false
                  : true
              }
              defaultValue={moment(date, "HH:mm")}
              format={"HH:mm"}
              onChange={(val) => {
                onUpdate(render?.userid, val, render?.shiftendtime);
              }}
              clearIcon={null}
              getPopupContainer={() => containerRef.current}
            />
          </div>
        );
      },
    },
    {
      title: "Shift End Time",
      dataIndex: "shiftendtime",
      key: "shiftendtime",
      width: "10%",
      render: (_, render) => {
        let date = render?.shiftendtime;
        return (
          <div>
            <TimePicker
              disabled={
                Menulist?.find(
                  (item) =>
                    item?.key === "manage" &&
                    item?.subMenu.find(
                      (subItem) =>
                        subItem?.key === "shift" && subItem?.permission.edit
                    )
                )
                  ? false
                  : true
              }
              defaultValue={moment(date, "HH:mm")}
              format={"HH:mm"}
              onChange={(val) => {
                onUpdate(render?.userid, render?.shiftstarttime, val);
              }}
              clearIcon={null}
              getPopupContainer={() => containerRef.current}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getShiftList();
  }, [setShiftList]);

  useEffect(() => {
    // setShiftList(allShiftList);
    // merged userList and allShiftList based on userid
    if (allShiftList?.length > 0 && userList?.length > 0) {
      let shiftList = allShiftList.map((shift) => {
        let user = userList.find((user) => user.userid === shift.userid);
        return {
          ...shift,
          ...user,
        };
      });
      setShiftList(shiftList);
    }
  }, [allShiftList, userList]);

  const getShiftList = async () => {
    try {
      setDisplayshiftLoader(true);
      const response = await CALL_API(`user-shift/${ClientId}`, "get", {});
      if (!isEmpty(response)) {
        saveAllShift(response);
      }
      setDisplayshiftLoader(false);
    } catch (error) {
      console.log("[getShiftList] Error-->", error);
    }
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateUserList(userList);
    }
  };

  const onUpdate = async (Id, start_time, end_time) => {
    let shiftStartTime = moment(start_time, "HH:mm").format("HH:mm");
    let shiftEndTime = moment(end_time, "HH:mm").format("HH:mm");
    if (shiftStartTime || shiftEndTime) {
      setDisplayshiftLoader(true);
      const { code } = await CALL_API(`user-shift/${ClientId}/${Id}`, "patch", {
        shift_start_time: moment(shiftStartTime, "HH:mm").format("HH:mm"),
        shift_end_time: moment(shiftEndTime, "HH:mm").format("HH:mm"),
      });
      if (code === STATUS_CODE.SUCCESS) {
        setDisplayshiftLoader(false);
        message.success(`Successfully Updated`);
        getShiftList();
        fetchMasterData();
      } else {
        message.error(`Something went wrong. Please try again.`);
      }
    }
  };

  const prepareCSV = async () => {
    if (getFilterList()?.length > 0) {
      setShiftCSVlist(
        reduce(
          getFilterList(),

          function (result, list) {
            result.push({
              "Employee ID": list?.employeeid,
              Name: list?.fullname,
              "Shift Start Time": moment(
                list?.shiftstarttime,
                "HH:mm:ss"
              ).format("HH:mm"),
              "Shift End Time": moment(list?.shiftendtime, "HH:mm:ss").format(
                "HH:mm"
              ),
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };
  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };
  const headerstyle = { fontSize: "20px", fontWeight: "bold" };

  return (
    <>
      <Row className="page-title mb-2">
        <Col span={12} className="mt-0" style={headerstyle}>
          List of Shift
        </Col>
        <Col span={12} className="align-right">
          <ButtonOutlined className="m-0" type="link" onClick={prepareCSV}>
            Export CSV file
          </ButtonOutlined>
          <CSVLink
            header={CSVHeaders}
            data={shiftCSVlist}
            filename={"shift-management.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>

      {FilterJSX}
      {/* <>
        <StyledSelect
          placeholder="Select Employee"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.name ?? "").toLowerCase().includes(input?.toLowerCase())
          }
          options={concat(
            {
              user_id: "",
              name: "All Employee",
            },
            STAFF_LIST
          )}
          fieldNames={{
            label: "name",
            value: "user_id",
          }}
          onChange={(id) => setSelectedStaff(id)}
        />
      </> */}

      <Spin spinning={displayshiftLoader && shiftFilterList?.length === 0}>
        <StyledTable
          ref={containerRef}
          dataSource={getFilterList()}
          columns={columns}
          // note : first parameter is needed
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="userid"
          pagination={getFilterList()?.length > 20 ? paginationOptions : false}
        />
      </Spin>
    </>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
      saveAllShift: setAllShiftList,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  menuList: getMenuList(state),
  allShiftList: getallShiftList(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(Shift);
