import { Input, Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CaretDownOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom/cjs/react-router-dom.min";
import Table from "atoms/Table";
import { CALL_API_UPDATED } from "common/API";
import {
  countryList,
  Industry,
  RiskOption,
  RelationShipOption,
  IncorporationData,
  ManagingOffice,
  ClientStatus,
} from "common/Constants";
import ClientWrapStyle from "./ClientWrapStyle";
const fontStyle = { color: "#636980" };

const columns = [
  {
    title: "COB Date",
    dataIndex: "createdTime",
    key: "createdTime",
    className: "normal-column",
    width: 150,
    render: (createdTime) => {
      if (!createdTime) return "No Data";

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const date = new Date(createdTime);
      const day = date.getDate();
      const monthIndex = date.getMonth(); // Month starts from 0
      const year = date.getFullYear();

      const formattedDate = `${day}-${months[monthIndex]}-${year}`;
      return formattedDate;
    },
  },
  {
    title: "COB Status",
    dataIndex: "clientStatus",
    key: "clientStatus",
    className: "normal-column approved",
    width: 130,
    render: (clientStatus) => clientStatus || "-",
  },
  {
    title: "Client Name",
    dataIndex: "name",
    key: "name",
    className: "normal-column",
    width: 150,
    render: (name) => name || "-",
  },
  {
    title: "Client Status",
    dataIndex: "status",
    key: "status",
    className: "normal-column",
    width: 150,
    render: (status) => status || "-",
  },
  {
    title: "Management Office",
    dataIndex: "managingOffice",
    key: "managingOffice",
    className: "normal-column",
    width: 180,
    render: (managingOffice) => managingOffice || "-",
  },
  {
    title: "Relationship",
    dataIndex: "relationship",
    key: "relationship",
    className: "normal-column",
    width: 150,
    render: (relationship) => relationship || "-",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    className: "normal-column",
    width: 300,
    ellipsis: {
      showTitle: false,
    },
    render: (description) => description || "-",
  },
  {
    title: "SP",
    dataIndex: "sp",
    key: "sp",
    className: "normal-column",
    width: 150,
    render: (sp) => sp || "-",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    className: "normal-column",
    width: 150,
    render: (country) => country || "-",
  },
  {
    title: "Industry",
    dataIndex: "industry",
    key: "industry",
    className: "normal-column",
    width: 150,
    render: (industry) => industry || "-",
  },
  {
    title: "Type",
    dataIndex: "incorporationType",
    key: "incorporationType",
    className: "normal-column",
    width: 150,
    render: (incorporationType) => incorporationType || "-",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    key: "createdBy",
    className: "normal-column",
    width: 150,
    render: (createdBy) => createdBy || "-",
  },
  {
    title: "Created Date",
    dataIndex: "createdDate",
    key: "createdDate",
    className: "normal-column",
    width: 150,
    render: (createdDate) => createdDate || "-",
  },
  {
    title: "Updated By",
    dataIndex: "updatedDate",
    key: "updatedDate",
    className: "normal-column",
    width: 150,
    render: (updatedDate) => updatedDate || "-",
  },
];

const ActiveWrapper = styled.div`
  .title {
    display: flex;
    justify-content: space-between;

    h5 {
      font-weight: 600;
    }
  }

  .approved {
    color: #397ff4;
    text-transform: capitalize;
    cursor: pointer;
  }
  .filter-title {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .search-box {
    max-width: 350px;
    margin-top: 12px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .filters {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-bottom: 20px;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const paginationOptions = {
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  pageSize: 20,
  showSizeChanger: false,
};

const Pending = () => {
  const history = useHistory();
  const [clientList, setClientList] = useState([]);
  const [lastEvalKey, setLastEvalKey] = useState(0);
  const [statusType, setStatusType] = useState("Pending");
  const [showLoader, toggleLoader] = useState(false);
  const [filters, setFilters] = useState({
    clientName: "",
    relationship: "",
    risk: "",
    country: "",
    managingOffice: "",
    industry: "",
    type: "",
    clientStatus: "",
  });
  const { Option } = Select;
  const defaultSelect = { color: "lightgrey" };
  useEffect(() => {
    getClistData();
  }, [filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };
  const dropdownStyle = {
    fontWeight: "bold",
    color: "black",
  };
  const getClistData = async () => {
    toggleLoader(true);

    const response = await CALL_API_UPDATED(
      `/insyts/clients?LastEvaluatedKey=${lastEvalKey}&status=${statusType}`,
      "get"
    );
    if (response.data) {
      toggleLoader(false);

      // setClientList(response.data);
      const filteredData = response.data?.filter((client) => {
        // Check each filter criteria against the client object
        const clientName = client.name?.toLowerCase();
        const filterClientName = filters.clientName?.toLowerCase();
        return (
          clientName?.includes(filterClientName) &&
          (filters.relationship === "" ||
            client.relationship === filters.relationship) &&
          (filters.risk === "" || client.risk === filters.risk) &&
          (filters.country === "" || client.country === filters.country) &&
          (filters.managingOffice === "" ||
            client.managingOffice === filters.managingOffice) &&
          (filters.industry === "" || client.industry === filters.industry) &&
          (filters.type === "" || client.type === filters.type) &&
          (filters.clientStatus === "" ||
            client.clientStatus === filters.clientStatus)
        );
      });

      setClientList(filteredData);
    }
    if ("LastEvaluatedKey" in response.data) {
      setLastEvalKey(response.LastEvaluatedKey);
    }
  };
  useEffect(() => {
    getClistData();
  }, []);
  return (
    <ClientWrapStyle>
      <div>
        <div className="filter-title">Filter:</div>
      </div>

      <div className="filters">
        <Input
          placeholder="Client Name"
          value={filters.clientName}
          onChange={(e) => handleFilterChange("clientName", e.target.value)}
        />

        <Select
          placeholder="Relationship"
          style={{
            width: "auto",
          }}
          suffixIcon={<CaretDownOutlined style={dropdownStyle} />}
          onChange={(value) =>
            handleFilterChange("relationship", value === "clear" ? "" : value)
          }
          value={filters.relationship || "Relationship"}
        >
          <Option value="clear" style={defaultSelect}>
            Relationship
          </Option>
          {RelationShipOption.map((option, idx) => (
            <Option key={idx} value={option}>
              {option}
            </Option>
          ))}
        </Select>

        <Select
          placeholder="Risk"
          style={{
            width: "auto",
          }}
          suffixIcon={<CaretDownOutlined style={dropdownStyle} />}
          onChange={(value) =>
            handleFilterChange("risk", value === "clear" ? "" : value)
          }
          value={filters.risk || "Risk"}
        >
          <Option value="clear" style={defaultSelect}>
            Risk
          </Option>

          {RiskOption.map((option, idx) => (
            <Option key={idx} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Country"
          style={{
            width: "auto",
          }}
          suffixIcon={<CaretDownOutlined style={dropdownStyle} />}
          onChange={(value) =>
            handleFilterChange("country", value === "clear" ? "" : value)
          }
          value={filters.country || "Country"}
        >
          <Option value="clear" style={defaultSelect}>
            Country
          </Option>

          {countryList.map((option, idx) => (
            <Option key={idx} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        {/* <Select
          placeholder="Client Contact"
          style={{
            width: "auto",
          }}
          suffixIcon={
            <CaretDownOutlined
              style={dropdownStyle}
            />
          }
        /> */}
        <Select
          placeholder="Managing office"
          style={{
            width: "auto",
          }}
          suffixIcon={<CaretDownOutlined style={dropdownStyle} />}
          onChange={(value) =>
            handleFilterChange("managingOffice", value === "clear" ? "" : value)
          }
          value={filters.managingOffice || "Managing Office"}
        >
          <Option value="clear" style={defaultSelect}>
            Managing office
          </Option>
          {ManagingOffice.map((option, idx) => (
            <Option key={idx} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Industry"
          style={{
            width: "auto",
          }}
          suffixIcon={<CaretDownOutlined style={dropdownStyle} />}
          onChange={(value) =>
            handleFilterChange("industry", value === "clear" ? "" : value)
          }
          value={filters.industry || "Industry"}
        >
          <Option value="clear" style={defaultSelect}>
            Industry
          </Option>

          {Industry.map((option, idx) => (
            <Option key={idx} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Type"
          style={{
            width: "auto",
          }}
          suffixIcon={<CaretDownOutlined style={dropdownStyle} />}
          onChange={(value) =>
            handleFilterChange("type", value === "clear" ? "" : value)
          }
          value={filters.type || "Type"}
        >
          <Option value="clear" style={defaultSelect}>
            Type
          </Option>

          {IncorporationData.map((option, idx) => (
            <Option key={idx} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Status"
          style={{
            width: "auto",
          }}
          suffixIcon={<CaretDownOutlined style={dropdownStyle} />}
          onChange={(value) =>
            handleFilterChange("clientStatus", value === "clear" ? "" : value)
          }
          value={filters.clientStatus || "Status"}
        >
          <Option value="clear" style={defaultSelect}>
            Status
          </Option>
          {ClientStatus.map((option, idx) => (
            <Option key={idx} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </div>

      <Spin spinning={showLoader}>
        <div className="customise_table">
          <Table
            // dataSource={clientList}
            columns={columns}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="active-client-id"
            dataSource={clientList.map((client) => ({
              ...client,
              "Client Name": (
                <Link to={`/client-details/${client.id}`} style={fontStyle}>
                  {client["name"]}
                </Link>
              ),
            }))}
            onRow={(record) => {
              return {
                onClick: () => {
                  history.push(`/client-details/${record.id}`, {
                    extraData: clientList,
                  });
                },
              };
            }}
            pagination={clientList.length > 20 ? paginationOptions : false}
          />
        </div>
      </Spin>
    </ClientWrapStyle>
  );
};

export default Pending;
