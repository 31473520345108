import styled from "@emotion/styled";
import { Col, Modal, Row, Select, Spin } from "antd";
import Button from "atoms/Button";
import COLORS from "common/Colors";
import { getColor, LEAVE_ICON } from "common/Constants";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import LeaveDonutChart from "./LeaveDonutChart";
// import COLORS from 'common/Colors';

// Redux
import { reduce } from "lodash";
import { connect, useSelector } from "react-redux";
import {
  getAllDepartmentList,
  getClientid,
  getUserInfo,
  getUserList,
  getUserRoleId,
} from "redux/selectors";

const StyledModal = styled(Modal)`
  width: 700px !important;
  .ant-modal-body {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 10px;
`;

const ButtonOutlined = styled(Button)`
  padding: 5px 20px;
  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 5px;
  background: transparent;
  font-weight: normal;
  color: ${COLORS.PRIMARY};
  white-space: nowrap;

  &:hover {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  &:focus {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  @media (max-width: 900px) {
    margin: 10px 0px;
  }
`;

const StyledSelect = styled(Select)`
  padding-bottom: 20px;
`;

function StaffLeaveBalance({
  displayModal,
  toggleModal,
  leaveTypeId,
  leaveName,
  selectedDepartmentId,
  leaveCategoryList,
  allDepartmentList,
  userList,
  clientId,
  leaveList,
  leaveCatgeory,
  filteredDepartments,
  employeeid,
  myTeam,
  isSubOrdinate,
  all,
}) {
  const [userLeaves, setUserLeaves] = useState([]);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [CSVDepartmentLeave, setCSVDepartmentLeave] = useState([]);
  const csvLinkDepartment = useRef();

  const [leaveSummary, setLeaveSummary] = useState({});
  const [teamUsers, setTeamFilteredUserList] = useState([]);

  //   const userInfo = useSelector(getUserInfo);
  //
  //   const userWholeData = userList.find(
  //     (user) => user.userid === userInfo.userid
  //   );

  const getActiveUserList = () =>
    userList.filter((user) => user.statusid === "1");

  // const userManagingOffices = userWholeData?.managingoffice;
  // const getCommonContryList = (user) =>
  //   user.filter((user) => {
  //     const isOnSameCountry = userManagingOffices.some((office) => {
  //       return user.managingoffice.includes(office);
  //     });
  //     return isOnSameCountry;
  //   });

  const getEligibleUsers = (users, currentCategory) =>
    users.filter((user) => {
      const isEligible =
        currentCategory?.eligible?.toLowerCase() === "both" ||
        currentCategory?.eligible?.toLowerCase() === user.gender.toLowerCase();

      return isEligible;
    });

  const getHrUserList = () => getEligibleUsers(getActiveUserList());

  const getApprovedLeaves = (
    leaveCatgeory,
    leaveTypeId,
    filteredDepartments,
    all
  ) => {
    return leaveCatgeory.filter((item) => {
      const isDepartmentFiltered = filteredDepartments?.some(
        (dept) => dept.department_id === item.department_id
      );
      return (
        (all || isDepartmentFiltered) &&
        item.status_id === "5" &&
        item.leave_category_id === leaveTypeId
      );
    });
  };

  const updateLeaveStatistics = (approvedLeaves, leaveStatistics) => {
    approvedLeaves.forEach((leave) => {
      const leaveTakenUserId = leave.userid;
      if (leaveStatistics?.[leaveTakenUserId]) {
        leaveStatistics[leaveTakenUserId].consumed += parseFloat(
          leave.number_of_days
        );
      }
    });
  };

  const createLeaveStatistics = (users) => {
    const leaveStatistics = {};
    users.forEach((user) => {
      leaveStatistics[user.userid] = {
        available: 0,
        consumed: 0,
      };
    });
    return leaveStatistics;
  };

  const createUpdatedLeaveData = (
    users,
    leaveCategoryList,
    leaveTypeId,
    leaveStatistics
  ) => {
    return users.map((category) => {
      const categoryName = category.fullname;
      const categoryid = category.userid;
      const availableCount = leaveCategoryList.find(
        (data) => data.leaveCategoryId == leaveTypeId
      );
      return {
        categoryName,
        categoryid,
        data: [
          {
            type: "available",
            value: parseInt(availableCount?.numberOfLeaves || 0, 10),
          },
          {
            type: "consumed",
            value: leaveStatistics[categoryid]?.consumed || 0,
          },
        ],
      };
    });
  };

  useEffect(() => {
    const activeUserList = getActiveUserList();

    const leaveStatistics = createLeaveStatistics(activeUserList);

    const currentCategory = leaveCategoryList?.find(
      (data) => data.leaveCategoryId == leaveTypeId
    );

    let teamFilteredUserList = [];
    if (!all) {
      teamFilteredUserList = activeUserList.filter((user) => {
        // const userDepartmentIds = user.userdepartmentid;
        const isEligible =
          currentCategory?.eligible?.toLowerCase() === "both" ||
          currentCategory?.eligible?.toLowerCase() ===
            user.gender.toLowerCase();
        const isEmployeeSubordinate = isSubOrdinate(myTeam, user?.employeeid);
        return (
          isEmployeeSubordinate && isEligible
          // &&
          // Object.values(userDepartmentIds).some((deptIds) =>
          //   filteredDepartments.some((dept) =>
          //     deptIds.includes(dept.department_id)
          //   )
          // )
        );
      });
    }

    const approvedLeaves = getApprovedLeaves(
      leaveCatgeory,
      leaveTypeId,
      filteredDepartments,
      all
    );

    updateLeaveStatistics(approvedLeaves, leaveStatistics);

    const updatedLeaveData = createUpdatedLeaveData(
      all
        ? getEligibleUsers(activeUserList, currentCategory)
        : teamFilteredUserList,
      leaveCategoryList,
      leaveTypeId,
      leaveStatistics
    );

    setTeamFilteredUserList(teamFilteredUserList);
    setLeaveSummary(updatedLeaveData);
    setUserLeaves(updatedLeaveData);
  }, [
    leaveTypeId,
    leaveCatgeory,
    employeeid,
    filteredDepartments,
    all,
    myTeam,
    isSubOrdinate,
  ]);

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Name" },
    { label: "Available Leaves" },
    { label: "Consumed Leaves" },
    { label: "Total Leaves" },
  ];

  const prepareCSV = async (event, done) => {
    if (userLeaves.length > 0) {
      setCSVDepartmentLeave(
        reduce(
          leaveSummary
              .filter(
                (value) =>
                  filteredUsers.length === 0 ||
                  filteredUsers.includes(value.categoryid)
              ),
          function (result, leave) {
            result.push({
              "Employee ID": leave.categoryid,
              Name: leave.categoryName,
              "Available Leaves": leave.data[0].value - leave.data[1].value,
              "Consumed Leaves": leave.data[1].value,
              "Total Leaves": leave.data[0].value,
              // "Last Year Leave Balance": leave.leaveData[0].lastYearLeaveBalance
            });
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkDepartment.current.link.click();
      });
    } else {
      console.log("No data available to download");
      // message.error(`No data available to download.`);
    }
  };

  return (
    <>
      <StyledModal
        title={leaveName}
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
      >
        <Row gutter={16}>
          <Col span={12}>
            <StyledSelect
              mode="multiple"
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search Staff"
              name="user"
              optionFilterProp="fullname"
              filterOption={(input, option) =>
                option.fullname.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) => {
                optionA.fullname
                  .toLowerCase()
                  .localeCompare(optionB.fullname.toLowerCase());
              }}
              options={all ? getHrUserList() : teamUsers}
              fieldNames={{
                label: "fullname",
                value: "userid",
              }}
              onChange={(newValue) => setFilteredUsers(newValue)}
            />
          </Col>

          <Col
            span={12}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <ButtonOutlined
              onClick={prepareCSV}
              disabled={userLeaves.length === 0}
            >
              Export CSV file
            </ButtonOutlined>
            <CSVLink
              header={CSVHeaders}
              data={CSVDepartmentLeave}
              filename={"Department Leave List.csv"}
              asyncOnClick={true}
              ref={csvLinkDepartment}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          {Array.isArray(leaveSummary) &&
            leaveSummary
              .filter(
                (value) =>
                  filteredUsers.length === 0 ||
                  filteredUsers.includes(value.categoryid)
              )
              .map((value, index) => (
                <StyledCol md={12} sm={24} xs={24} key={index}>
                  <LeaveDonutChart
                    iconName={LEAVE_ICON[7]}
                    title={value.categoryName}
                    data={value.data}
                    color={getColor(index)}
                  />
                </StyledCol>
              ))}
        </Row>
      </StyledModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  userList: getUserList(state),
  clientId: getClientid(state),
});

export default connect(mapStateToProps, null)(StaffLeaveBalance);
