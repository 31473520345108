import { Col, Form, Input, Modal, Row, Select } from 'antd';
import Button from 'atoms/Button';
import { currencyOptions } from 'common/Constants';
import queryKeys from 'common/queryKey';
import { useScrollFetch } from 'hooks/useScrollFetch';
import { concat } from 'lodash';
import React, { useMemo } from 'react'
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;


function AddSubContract({ onClose, showAddModal, isUpdating, onFinish, form, engagementData }) {

    const subcontractorList = concat(engagementData?.vendor ?? [], engagementData?.partner ?? [] )
    const subcontractorObjects = subcontractorList.map(item => ({
        label: item?.name,
        value: item?.name
    }));
    const { getAllClientsList } = queryKeys;

    const { key, url } = getAllClientsList;
  
    const {
      data: clientsDataRes,
      fetchNextPage,
      isLoading: loading,
      hasNextPage,
    } = useScrollFetch(key, {
      endPoint: url,
      queryParam: "LastEvaluatedKey",
    });
  
    const clients = useMemo(() => {
      
      return clientsDataRes?.pages?.reduce((acc, page) => {
        if (page?.data === undefined) return acc;
        return [...acc, ...page?.data];
      }, []);
    }, [clientsDataRes]);
  
    const clientOptions = clients
      ?.filter((client) => client?.bdo_strategicpartner) // Filter clients based on bdo_strategicpartner
      .map((client) => ({
        label: client.registeredName,
        value: client.registeredName,
      }));
  
      const onScroll = async (event) => {
        const target = event.target;
        if (
          !loading &&
          target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
          console.log("Loading...");
          target.scrollTo(0, target.scrollHeight);
          if (hasNextPage) {
            fetchNextPage();
          }
        }
      }; 


    return (
        <div>
            <StyledModal title="Add Subcontractor"
                visible={showAddModal}
                onCancel={() => {
                    onClose()
                }}
                width={800}
                
                footer={[]}>

                <Form
                    layout="vertical"
                    // onFinish={onFinish}
                    onFinish={onFinish}
                    colon={false}
                    form={form}

                >
                    <div className="resource-card">
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label="Subcontractor"
                                    name="subcontractor"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Partner is required.',
                                        },]}
                                >
                                    <Select
                                        placeholder="Select"
                                        filterOption={(input, option) =>
                                            (option?.label?.toLowerCase() ?? "").includes(
                                              input.toLowerCase()
                                            )
                                          }
                                          showSearch={true}
                                        options={clientOptions}
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Description is required.',
                                        },]}
                                >
                                    <Input placeholder="Enter"  />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Currency"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Currency is required.',
                                        }
                                    ]}
                                    name="currency"
                                >
                                    <Select placeholder="Select" options={currencyOptions} />
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Amount"
                                    name="amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Amount is required.',
                                        },]}
                                >
                                    <Input placeholder="Enter" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Reference No"
                                    name="refno"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Reference No is required.',
                                        },]}
                                >
                                    <Input placeholder="Enter" />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Remark"
                                    name="remark"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Remark is required.',
                                        },]}
                                >
                                    <Input placeholder="Enter"  />

                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <div className="text-end">
                        <Button type="primary" onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isUpdating}>
                            Save
                        </Button>
                    </div>
                </Form>

            </StyledModal>
        </div>
    )
}

export default AddSubContract