import styled from "styled-components";

const Title = styled.div`
  color: #141414;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
`;
export default Title;
