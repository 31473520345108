import React from "react";
import { Row, Col } from "antd";
import { Pie } from "@ant-design/plots";
import styled from "@emotion/styled";
import { map } from "lodash";

const StyledChartContainer = styled.div`
  margin-top:5%;
  padding: 5px;

  .styled-circle {
    height: 40px;
    width: 40px;
    svg {
      margin: 4px;
    }
  }
  .rowstyle {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
  }
  .report-title {
    font-weight: bold;
    font-size: 0.8rem;
    padding-top: 3px;
    padding-left: 10px;
    text-transform: uppercase;
  }
  .report-title1 {
    font-weight: bold;
    font-size: 0.9rem;
    padding-top: 3px;
    padding-left: 10px;
  }
  .legends {
    padding-top: 3px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 4px;
    padding: 16px;
    div {
      display: flex;
      justify-content: start;
      align-items: center;
    }
    .legend-box {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 12px;
      font-size: 12px;
      font-weight: 500;
      @media (max-width: 426px) {
        font-size: 8px;
      }
      strong {
        margin-left: auto;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`;

const StyledColorBox = styled.span`
  ${({ color }) => color && `background: ${color};`}
  /* padding: 0px 16px; */
  height: 14px;
  width: 28px;
  border-radius: 10px;
`;

function UserMonthlyReportDonut({ iconName, title, data, workingData }) {
  const config = {
    width: 200,
    height: 200,
    appendPadding: 9,
    data,
    angleField: "value",
    colorField: "type",
    color: map(data, "color", []),
    radius: 1,
    innerRadius: 0.8,
    legend: false,
    label: false,
    statistic: {
      title: {
        offsetY: -4,
        style: {
          fontWeight: "bold",
        },
        customHtml: (container) => {
          // const { width, height } = container.getBoundingClientRect();
          // const d = 12;
          const text = `${workingData}`;
          return text;
        },
      },
      content: {
        offsetY: 4,
        style: {
          height: "20px",
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "14px",
          fontWeight: "normal",
        },
        customHtml: (container) => {
          const text = "Working Days";
          return text;
        },
      },
    },
  };
  return (
    <StyledChartContainer id="conatiner">
      <Row className="rowstyle">
        <Col span={12}>
          <Pie {...config} />
        </Col>
        <Col span={12} className="legends">
          {data.map((val) => {
            return (
              <div>
                <div className="legend-box">
                  <StyledColorBox color={val.color}></StyledColorBox>
                  {val.type} <strong>{val.value}</strong>
                </div>
              </div>
            );
          })}
        </Col>
      </Row>
    </StyledChartContainer>
  );
}

export default UserMonthlyReportDonut;
