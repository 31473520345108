import React, { useEffect, useState } from "react";
import { Modal, Spin, Table, message } from "antd";
import styled from "styled-components";
import GridBuilder from "UIBuilderEngine/GridBuilder";

const StyledModal = styled(Modal)`
  .ant-form-item {
    margin-bottom: "12px";
  }
`;

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const EditChargeOut = ({
  isEdit,
  setIsEdit,
  setRecordValue,
  loading,
  recordValue,
  handleFormSubmit,
  isLoading,
}) => {
  const [isView, setView] = useState();
  const ChargeoutFormJson = {
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Job Grade Code",
              type: "text",
              name: "jobgradecode",
              disabled: true,
              validationRules: [],
            },
            {
              id: 4,
              label: "Target Chargeability",
              type: "text",
              name: "targetchargeability",
              maxLength: 3,
              validationRules: [
                {
                  pattern: /^(100|[1-9]?[0-9])$/,
                  message: "Please enter a value between 0 and 100.",
                },
              ],
              onKeyDown: /^[0-9]+$/,
            },
          ],
          [
            {
              id: 5,
              label: "Standard",
              type: "text",
              name: "standard",
              maxLength: 3,
              validationRules: [
                {
                  pattern: /^[0-9]{0,3}$/,
                  message: "",
                },
              ],
              onKeyDown: /^[0-9]+$/,
            },
            {
              id: 7,
              label: `${recordValue?.country}`,
              type: "text",
              name: "rate",
              maxLength: 3,
              validationRules: [
                {
                  pattern: /^[0-9]{0,3}$/,
                  message: "",
                },
              ],
              onKeyDown: /^[0-9]+$/,
            },
          ],
          [
            {
              id: 2,
              label: "Save",
              type: "button",
              position: "end",
              textColor: "white",
              event: "submit",
              loading: loading,
            },
          ],
        ],
      },
    ],
  };

  useEffect(() => {
    const num = getRandomNumber(1, 10000);
    setView(num);
    setRecordValue(recordValue);
  }, [isEdit, recordValue]);
  console.log("recordValue -->", recordValue);
  return (
    <Spin spinning={isLoading}>
      <StyledModal
        title="Edit Chargeout Rates"
        width={900}
        open={isEdit}
        footer={false}
        onCancel={() => {
          setIsEdit(false);
          setRecordValue({});
        }}
      >
        <GridBuilder
          formStructureJson={ChargeoutFormJson}
          initialValues={recordValue}
          formSubmit={handleFormSubmit}
          loading={loading}
          isModalOpen={isView}
        />
      </StyledModal>
    </Spin>
  );
};
