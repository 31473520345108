import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import AddForm from "pages/FormBuilder/AddForm";
import { CALL_API } from "common/API";
import { getClientid } from "redux/selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleProcessingModal, toggleSuccessModal } from "redux/actions";
import { Row, Col, Modal, Form, Input, Button, Select, Switch, DatePicker } from "antd";
import Styled from "styled-components";
import Icon from "atoms/Icon";
import EditorConvertToHTML from "atoms/EditorConvertToHTML";
import { NON_EMPTY_REGEX, VALIDATE_SYMBOL } from "common/Constants";
import COLORS from "common/Colors";
import EditForm from "pages/FormBuilder/EditForm";
import FormListModal from "./FormListModal";
import { formTemplates } from "pages/FormBuilder/JsonFormFormat";
import { ButtonLink, ButtonOutlined } from "reusableComponent/UIButtons";
import { find, isEmpty } from "lodash";
import AddHiringProcess from "pages/Recruitment/AddHiringProcess";
import moment from "moment";

const StyledButton = Styled(Button)`
  background: #ffffff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;
const StyledOutlinedButton = Styled(Button)`
  background: #ffffff;
  border-radius: 5px;
  color: black;
  width: 100px;
`;

const StyledModal = Styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1b1b1b;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close {
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26A4FF;
    color: #ffffff;
  }
  .styled-col {
    margin:10px;
    background-color: #ffffff;
    border-radius: 15px;
    border: 1px solid transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .styled-box{
    display: flex !important;
    justify-content: space-between !important;
  }
`;

const Wrapper = Styled.div`
   .styled-col {
    width: 100%;
    padding: 20px;
    /* background-color: #EDF7FF; */
    border: 2px solid rgba(0, 0, 0, 0.1) ;
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  .styled-cursor{
    cursor:pointer;
  }
  .links {
    color: ${COLORS.PRIMARY};
  }
`;

const Addjob = ({
  ClientId,
  updateProcessingModal,
  updateSuccessModal,
  processList,
  setShowComponent,
  getData,
  selectedItem,
}) => {
  const [formData, setFormData] = useState({});
  const [createCandidateFormData, setCreateCandidateFormData] = useState({});
  const [createScreeningFormData, setCreateScreeningFormData] = useState({});
  const [createResumeFormData, setCreateResumeFormData] = useState({});
  const [createPreviousCompanyFormData, setCreatePreviousCompanyFormData] =
    useState({});
  const [isPreviousCompany, setIsPreviousCompany] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const containersRef = useRef(null);

  const [formName, setFormName] = useState("");
  const [form] = Form.useForm();
  const [addForm, setAddForm] = useState(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;


  useEffect(() => {
    if (!isEmpty(selectedItem)) {
      form.setFieldValue({
        title: selectedItem?.title,
        processId: selectedItem?.processId,
        description: selectedItem?.description,
        expirydate: selectedItem?.expirydate? moment(selectedItem?.expirydate).format('DD-MM-YYYY') : ""
      });
      setCreateCandidateFormData(
        selectedItem?.candidateInfo ? selectedItem?.candidateInfo : {}
      );
      setCreateResumeFormData(selectedItem?.resume ? selectedItem?.resume : {});
      setCreateScreeningFormData(
        selectedItem?.screeningQuestion ? selectedItem?.screeningQuestion : {}
      );
      setCreatePreviousCompanyFormData(
        selectedItem?.referenceFromPreviousCompany
          ? selectedItem?.referenceFromPreviousCompany
          : {}
      );
      setIsPreviousCompany(
        selectedItem?.getPreviousCompanyInfo
          ? selectedItem?.getPreviousCompanyInfo
          : false
      );
    }
  }, [selectedItem]);

  const onFinish = async (values) => {
    if (!isEmpty(selectedItem)) {
      setLoading(true);
      // API for update job
      await CALL_API(`recruitment/jobs/${selectedItem?.jobId}`, "patch", {
        title: values?.title,
        processId: values?.processId,
        description: values?.description,
        candidateInfo: createCandidateFormData,
        screeningQuestion: createScreeningFormData,
        resume: createResumeFormData,
        referenceFromPreviousCompany: createPreviousCompanyFormData,
        getPreviousCompanyInfo: isPreviousCompany,
        expirydate: moment(values?.expirydate).format('DD-MM-YYYY')
      });

      getData();
      setShowComponent("tab");
      setLoading(false);
      return;
    }

    // API For Creating a new Job
    setLoading(true);
    await CALL_API(`recruitment/jobs`, "post", {
      title: values?.title,
      processId: values?.processId,
      description: values?.description,
      candidateInfo: createCandidateFormData,
      screeningQuestion: createScreeningFormData,
      resume: createResumeFormData,
      referenceFromPreviousCompany: createPreviousCompanyFormData,
      getPreviousCompanyInfo: isPreviousCompany,
      expirydate: moment(values?.expirydate).format('DD-MM-YYYY')
    });

    getData();
    setShowComponent("tab");
    setLoading(false);
  };

  const iconstyle = { width: "100px", height: "100px" };

  const fetchData = async () => {
    try {
      const res = await CALL_API(`formbuilder/${ClientId}`, "get");
      setFormData(res?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getformDetails = async (payload, id) => {
    if (id === "1") {
      setCreateCandidateFormData(payload);
    } else if (id === "2") {
      setCreateScreeningFormData(payload);
    } else if (id === "3") {
      setCreateResumeFormData(payload);
    } else if (id === "4") {
      setCreatePreviousCompanyFormData(payload);
    }
  };

  const handleClick = (id) => {
    window.localStorage.setItem("formId", id);
    // setFormName(title); // Keep the case consistent
    // updateFormName(id)
    setShow(true);
  };

  const defaultForm = {
    title: "",
    description: "",
    questions: [
      {
        question: "",
        question_placeholder: "Question",
        options: [
          {
            optionText: "Option 1",
            placeholder: "Option 1",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 1,
        requiredSwitch: false,
      },
    ],
  };

  const formList = [
    {
      title: "Candidate Info",
      // form: createCandidateFormData,
      id: "1",
    },
    {
      title: "Screening Questions",
      form: createScreeningFormData,
      id: "2",
    },
    {
      title: "Resume/CV",
      // form: createResumeFormData,
      id: "3",
    },
    {
      title: "Reference from previous company",
      // form: createPreviousCompanyFormData,
      id: "4",
    },
  ];

  const handleColClick = (item) => {
    const id = window.localStorage.getItem("formId");
    if (id === "1") {
      setCreateCandidateFormData(item);
    } else if (id === "2") {
      setCreateScreeningFormData(item);
    } else if (id === "3") {
      setCreateResumeFormData(item);
    } else if (id === "4") {
      setCreatePreviousCompanyFormData(item);
    }
    setShow(false);
  };

  const textStyle = { color: `${COLORS.TEXT.PRIMARY}` };

  const handleSubmit = async (form) => {
    setLoading(true);
    // API For Adding Hiring Process
    await CALL_API(`recruitment/hiringprocess`, "post", form);
    getData();
    setLoading(false);
    setShowAdd(false);
  };

  const ListItem = ({ item }) => {
    return (
      <div className="styled-col mb-2">
        <div className="w-100 d-flex justify-content-between ">
          <h6 style={textStyle}>{item?.title}</h6>
          <div>
            {item?.id === "2" && (
              <ButtonLink
                className="styled-cursor links"
                onClick={() => {
                  setFormName(item?.title);
                  setCreateScreeningFormData(defaultForm);
                  // setAddForm(true);
                }}
              >
                Add Questions
              </ButtonLink>
            )}
            {item?.id === "4" && (
              <span>
                Required{" "}
                <Switch
                  checked={isPreviousCompany}
                  onChange={() => setIsPreviousCompany(!isPreviousCompany)}
                />{" "}
              </span>
            )}
          </div>
        </div>

        <div>
          {!isEmpty(item?.form) && (
            
            <EditForm
              pageView={"edit"}
              selectedItem={item?.form}
              setPageView={() => console.log("dada")}
              fetchData={fetchData}
              ClientId={ClientId}
              updateProcessingModal={updateProcessingModal}
              formId={item?.id}
              getFormData={getformDetails}
            />
            
          )}
          {addForm && formName === item?.title && (
            <AddForm
              // fetchData={fetchData}
              ClientId={ClientId}
              updateProcessingModal={updateProcessingModal}
              updateSuccessModal={updateSuccessModal}
              // getformDetails={getformDetails}
              name="recuritment"
              formId={item?.id}
              getFormData={getformDetails}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Wrapper>
      <label
        className="bg-transparent border-0 mb-3 text-left styled-cursor"
        onClick={() => setShowComponent("tab")}
      >
        <b className="">Jobs </b> <ArrowForwardIosIcon className="px-1" />
      </label>{" "}
      <b className="pb-0 pt-1">
        {isEmpty(selectedItem) ? "Adding New Job" : selectedItem?.title}
      </b>
      <Form
        form={form}
        name="AddJob"
        layout="vertical"
        initialValues={{
          title: selectedItem?.title,
          expirydate: selectedItem?.expirydate ? moment(selectedItem?.expirydate, "DD-MM-YYYY") : "",
          processId:
            selectedItem?.processId ||
            find(processList, { defaultStatus: true })?.processId,
          // description: selectedItem?.description
        }}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} sm={24} md={12} ref={containersRef}>
            <Form.Item
              label="Job Title"
              name="title"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Job Title is required",
                },
                {
                  required: false,
                  pattern: VALIDATE_SYMBOL,
                  message: "Job Title should not contain special characters",
                },
              ]}
            >
              <Input placeholder="Job Title" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containersRef}>
            <Form.Item
              label="Hiring Process"
              name="processId"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Hiring Process is required",
                },
                {
                  required: false,
                  pattern: VALIDATE_SYMBOL,
                  message:
                    "Hiring Process should not contain special characters",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Process"
                name="processId"
                options={processList}
                fieldNames={{
                  label: "title",
                  value: "processId",
                }}
                getPopupContainer={() => containersRef.current}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containersRef}>
            <Form.Item
              label="Expiry Date"
              name="expirydate"
              format="DD-MM-YYYY"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Date is required",
                },
              ]}
            >
              <DatePicker
                className="w-100"
                format="DD-MM-YYYY"
                getPopupContainer={() => containersRef.current}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={3} className="mt-4 text-end">
            <ButtonOutlined onClick={() => setShowAdd(true)}>Add Process</ButtonOutlined>
          </Col> */}
        </Row>
        <Form.Item label="Job Description" name="description">
          <EditorConvertToHTML
            // onChange={(val) => {
            //   form.setFieldsValue("jobdescription",val);
            // }}
            content={selectedItem?.description}
          />
        </Form.Item>

        <h6>Details to be given by the candidates</h6>

        {formList?.map((item, index) => (
          <React.Fragment key={index}>
            {/* <ListItem item={item} /> */}
            <div className="styled-col mb-2">
              <div className="w-100 d-flex justify-content-between ">
                <h6 style={textStyle}>{item?.title}</h6>
                <div>
                  {item?.id === "2" && isEmpty(createScreeningFormData) && (
                    <ButtonLink
                      className="styled-cursor links"
                      onClick={() => {
                        setFormName(item?.title);
                        setCreateScreeningFormData(defaultForm);
                        // setAddForm(true);
                      }}
                    >
                      Add Questions
                    </ButtonLink>
                  )}
                  {item?.id === "2" && !isEmpty(createScreeningFormData) && (
                    <ButtonLink
                      className="styled-cursor links"
                      onClick={() => {
                        setFormName(item?.title);
                        setCreateScreeningFormData({});
                        // setAddForm(true);
                      }}
                    >
                      Cancel
                    </ButtonLink>
                  )}
                  {item?.id === "4" && (
                    <span>
                      Required{" "}
                      <Switch
                        checked={isPreviousCompany}
                        onChange={() =>
                          setIsPreviousCompany(!isPreviousCompany)
                        }
                      />{" "}
                    </span>
                  )}
                </div>
              </div>

              <div>
                {!isEmpty(item?.form) && (
                  <EditForm
                    pageView={"edit"}
                    selectedItem={item?.form}
                    setPageView={() => console.log("dada")}
                    fetchData={fetchData}
                    ClientId={ClientId}
                    updateProcessingModal={updateProcessingModal}
                    formId={item?.id}
                    getFormData={getformDetails}
                  />
                )}
              </div>
            </div>
          </React.Fragment>
        ))}

        <div className="text-end">
          <StyledOutlinedButton
            type="secondry"
            className="m-2"
            onClick={() => setShowComponent("tab")}
          >
            Cancel
          </StyledOutlinedButton>
          <StyledButton
            type="primary"
            className="mt-2"
            loading={loading}
            htmlType="submit"
          >
            {isEmpty(selectedItem) ? "Create" : "Update"}
          </StyledButton>
        </div>
      </Form>
      <FormListModal
        open={show}
        onCancel={() => setShow(false)}
        createdForm={formData}
        templateForm={formTemplates}
        handleChoose={handleColClick}
        formName={formName}
      />
      <AddHiringProcess
        isModalOpen={showAdd}
        handleCancel={() => {
          setShowAdd(false);
        }}
        handleOk={() => setShowAdd(false)}
        handleSubmit={handleSubmit}
        loading={loading}
        initialValue={{}}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Addjob);
