
const ClientInformationJSON = (data) => {
    const BasicDetail = {
        totalpages: 1,
        pages: [
            {
                modalView: false,
                colSpan: [12, 12, 12],
                fields: [
                    // Row 1
                    [
                        {
                            id: 1,
                            label: "Client ID" + ":",
                            type: "text",
                            name: "bdo_id",
                            Placeholder: "Enter",
                            maxLength: 25,
                            // value: data["clientId"],
                            viewOnly: true,
                            validationRules: [
                                {
                                    required: false,
                                    message: "Client ID is required",
                                },
                            ],
                        },
                    ],
                    // Row 2
                    [
                        {
                            id: 2,
                            label: "Managing Office:",
                            type: "text",
                            name: "bdo_managingoffice",
                            Placeholder: "Select",
                            // value: data["managingOffice"],
                            disabled: false,
                            viewOnly: true,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Client ID is required",
                                },
                            ],
                        },
                    ],
                    // Row 3
                    [
                        {
                            id: 3,
                            label: "Influential /Star Customer:",
                            type: "text",
                            name: "bdo_influentialstarcustomer",
                            Placeholder: "Enter",
                            viewOnly: true,
                            // value: data["influentialstarcustomer"],
                            disabled: false
                        },
                    ],
                    // Row 4
                    [
                        {
                            id: 4,
                            label: "Registered Name:",
                            type: "text",
                            name: "name",
                            Placeholder: "Enter",
                            viewOnly: true,
                            // value: data["registeredname"],
                            disabled: false,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Client ID is required",
                                },
                            ],
                        },
                    ],

                    [
                        {
                            id: 5,
                            label: "Relationship type:",
                            type: "text",
                            name: "relationshiptype",
                            Placeholder: "Enter",
                            viewOnly: true,
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 6,
                            label: "Incorporation Type:*",
                            type: "textarea",
                            name: "bdo_incorporationtype",
                            Placeholder: "Enter",
                            disabled: false,
                            viewOnly: true,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Client ID is required",
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 7,
                            label: "Current Nature of Engagement",
                            type: "text",
                            name: "bdo_currentyearofengagement",
                            Placeholder: "Enter",
                            viewOnly: true,
                            disabled: false
                        },
                    ],

                    [
                        {
                            id: 8,
                            label: "Email:",
                            type: "text",
                            name: "emailaddress1",
                            Placeholder: "Enter",
                            disabled: false,
                            viewOnly: true,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Client ID is required",
                                },
                            ],
                        },
                    ],
                     [
                        {
                            id: 9,
                            label: "Company Website:",
                            type: "text",
                            viewOnly: true,
                            name: "websiteurl",
                            Placeholder: "Enter",
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 10,
                            label: "Description:",
                            type: "text",
                            name: "description",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 11,
                            label: "Addresses",
                            type: "label",
                        },
                    ],
                    [
                        {
                            id: 11,
                            label: "Address Type:",
                            type: "text",
                            name: "bdo_addresstype",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 12,
                            label: "Use Group Address:",
                            type: "text",
                            name: "bdo_usegroupaddress",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 13,
                            label: "Registered Address:",
                            type: "text",
                            name: "bdo_registeredaddress",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 18,
                            label: "Address 1: City",
                            type: "text",
                            name: "address1_city",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false
                        },
                    ],
                    
                    [
                        {
                            id: 13,
                            label: "Country:",
                            type: "text",
                            name: "sic",
                            Placeholder: "Enter",
                            viewOnly: true,
                            disabled: false,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Client ID is required",
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 14,
                            label: "Opertating Countries:",
                            type: "text",
                            name: "bdo_operatingcountries",
                            Placeholder: "Enter",
                            viewOnly: true,
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 15,
                            label: "Registered No:",
                            type: "text",
                            name: "bdo_registrationno",
                            Placeholder: "Enter",
                            viewOnly: true,
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 16,
                            label: "Pin No.:",
                            type: "text",
                            name: "bdo_pin",
                            Placeholder: "-",
                            viewOnly: true,
                            disabled: false,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Client ID is required",
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 17,
                            label: "Registered office Postal address:",
                            type: "text",
                            name: "address1_line1",
                            Placeholder: "-",
                            viewOnly: true,
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 216,
                            label: "Postal Code:",
                            type: "text",
                            name: "bdo_postalcode",
                            Placeholder: "-",
                            viewOnly: true,
                            disabled: false
                        },
                    ],
                ],
            },
        ],
    };
    return {
        BasicDetail
    };
};

export default ClientInformationJSON;

