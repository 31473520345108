import React from "react";
import styled from "styled-components";
import {
  getUserName,
  getSelectedDepartmentId,
  getDepartmentList,
  getRoleName,
  getDepartmentName,
  getLoadingDataFirstTime,
  getUserRoleId,
  getRoleList,
  getUserRoleList,
  getNotificationList,
  getUserInfo,
  getProfilePic,
  getAllDepartmentList,
  getClientid,
  getMenuList,
} from "redux/selectors";
import { connect, useDispatch, useSelector } from "react-redux";
// nee this
// import Task from "../assets/productlogo/Task.svg";
import Manage from "../assets/productlogo/Manage.svg";
import { IconButton } from "@mui/material";
import COLORS from "common/Colors";
import HomeIcon from "../assets/home.svg";
import Icon from "atoms/Icon";
import { setActiveMenuList, setMenuList } from "redux/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const StyledAppDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  /* box-shadow: 0px 2px 5px 0px #b1b1b1; */
  color: black;
  width: 70px;
  padding: 2px;
  padding-top: 100px;
  margin: auto;
  background-color: ${COLORS.PRIMARY};
  /* @media (max-width: 992px) {
    padding-top: 150px;
  }
  @media (max-width: 571px) {
    padding-top: 200px;
  }
  @media (max-width: 426px) {
    padding-top: 220px;
  } */

  .icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
`;

// const Centereddiv = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

const BoxIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  height: 60px;

  &.active {
    background-color: #f0f0f0;
  }
  span {
    line-height: 1.5;
  }
`;

const AppSideBar = ({ isLoadingDataFirstTime, open, setOpen }) => {
  const allMenuList = useSelector(getMenuList);
  const roleId = useSelector(getUserRoleId);
  const dispatch = useDispatch();

  const menuList = allMenuList[roleId];
  const history = useHistory();

  const hasSubMenu = (menu) =>
    menu.subMenu.length > 0 && menu.subMenu.some((subMenu) => subMenu.access);

  const isManagementViewWithAccess = (menu) =>
    menu.key === "managementview" && menu.access;
  const isNotProfile = (menu) => menu.key !== "profile";

  const menuListWithSubmenu = menuList?.filter((menu) => {
    return (
      (hasSubMenu(menu) || isManagementViewWithAccess(menu)) &&
      isNotProfile(menu)
    );
  });

  const isAnyMenuActive = menuListWithSubmenu?.some((menu) => menu.active);

  function handleManagementView() {
    const jwtToken = window.localStorage.getItem("token");
    const hyperlink = `https://mgntview.bdo.insyts.co/?jwtToken=${jwtToken}`;
    window.open(hyperlink, "_blank");
  }
  // need for future
  function handleTaskView() {
    const jwtToken = window.localStorage.getItem("token");
    const hyperlink = `https://task.tymeplushr.com/?jwtToken=${jwtToken}`;
    window.open(hyperlink, "_blank");
  }

  const handleOtherMenu = (item) => {
    item.active = !item.active;

    dispatch(
      setMenuList({
        [roleId]: menuList.map((menu) => {
          if (menu.key === item.key) {
            return item;
          }
          return { ...menu, active: false };
        }),
      })
    );

    if (item.key === "managementview") {
      handleManagementView();
      return;
    }

    dispatch(
      setActiveMenuList({
        [roleId]: item.subMenu,
      })
    );

    const firstActiveSubMenu = item.subMenu.find((subMenu) => subMenu.access);

    history.push(`/${firstActiveSubMenu.path}`);
  };

  const isHomeActive = !isAnyMenuActive;

  const handleHome = () => {
    dispatch(
      setMenuList({
        [roleId]: menuList.map((menu) => {
          return { ...menu, active: false };
        }),
      })
    );
    dispatch(
      setActiveMenuList({
        [roleId]: menuList.filter((menu) => {
          return menu.subMenu.length === 0 && menu.key !== "managementview";
        }),
      })
    );

    const firstMenu = menuList.find((menu) => {
      return (
        menu.subMenu.length === 0 &&
        menu.key !== "managementview" &&
        menu.access
      );
    });

    history.replace(`/${firstMenu?.path}`);
  };

  return (
    <StyledAppDiv>
      <div className="icons">
        <BoxIcon
          className={`${isHomeActive ? "active" : ""}`}
          onClick={() => handleHome()}
        >
          <Icon name="home" />
          <span>Home</span>
        </BoxIcon>

        {menuListWithSubmenu?.map((menu) => (
          <BoxIcon
            key={menu.id}
            className={`${menu?.active ? "active" : ""}`}
            onClick={() => handleOtherMenu(menu)}
          >
            {menu.icon && <Icon name={menu.icon} />}
            <span>{menu.title}</span>
          </BoxIcon>
        ))}
      </div>
    </StyledAppDiv>
  );

  //   return (
  //     <>
  //       {window.innerWidth > 567 ? (
  //         <StyledAppDiv>
  //           {!isLoadingDataFirstTime ? (
  //             <>
  //               <>
  //                 <Centereddiv>
  //                   <IconButton onClick={handleManagementView}>
  //                     <img src={Manage} alt="logo" />
  //                   </IconButton>
  //                 </Centereddiv>
  //                 <div className="text-center mb-3">Manage</div>
  //               </>
  //             </>
  //           ) : (
  //             <Centereddiv>
  //               <Skeleton.Avatar active className="d-block mb-3" />{" "}
  //             </Centereddiv>
  //           )}
  //
  //           {/* // Note : need this */}
  //           {/* {!isLoadingDataFirstTime ? (
  //         <>
  //
  //           <>
  //             <Centereddiv>
  //         <IconButton onClick={handleTaskView}>
  //                 <img src={Task} alt="logo" />
  //               </IconButton>
  //             </Centereddiv>
  //             <div className="text-center mb-3">Task</div>
  //           </>
  //         </>
  //       ) : (
  //         <Centereddiv>
  //           <Skeleton.Avatar className="mb-3" active />
  //         </Centereddiv>
  //       )} */}
  //         </StyledAppDiv>
  //       ) : (
  //         <Drawer
  //           width={100}
  //           visible={!isLoadingDataFirstTime && open}
  //           onClose={() => setOpen(false)}
  //         >
  //           {!isLoadingDataFirstTime ? (
  //             <>
  //               <div className="text-center mb-2">
  //                 <IconButton onClick={handleManagementView}>
  //                   <img src={Manage} alt="logo" />
  //                 </IconButton>
  //               </div>
  //               <div className="text-center mb-3">Manage</div>
  //             </>
  //           ) : (
  //             <div className="text-center">
  //               <Skeleton.Avatar active className="d-block mb-3" />
  //             </div>
  //           )}
  //         </Drawer>
  //       )}
  //     </>
  //   );
};

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  departmentList: getDepartmentList(state),
  roleName: getRoleName(state),
  departmentName: getDepartmentName(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
  userRoleList: getUserRoleList(state),
  notificationList: getNotificationList(state),
  userInfo: getUserInfo(state),
  profilePic: getProfilePic(state),
  allDepartmentList: getAllDepartmentList(state),
  clientId: getClientid(state),
  menuList: getMenuList(state),
});

export default connect(mapStateToProps)(AppSideBar);
