import Button from "atoms/Button";
import Table from "atoms/Table";
import React, { useEffect, useState } from "react";
import AddSubContract from "./AddSubContract";
import queryKeys from "common/queryKey";
import { useUpdate } from "hooks/useUpdate";
import { Form, notification } from "antd";
import { useDispatch } from "react-redux";
import { setTempEngagementFormData } from "redux/actions";
import { currencyAmount } from "utils/functions";

function SubContractor({
  engagementData,
  showAddModal,
  setShowAddModal,
  tempEngagementFormData,
  engagementRefetch
}) {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Subcontractor",
      dataIndex: "subcontractor",
      key: "subcontractor",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Reference No",
      dataIndex: "refno",
      key: "refno",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
    },
  ];

  const updateSubcontractList = (existingList, newSubcontract) => {
    if (!Array.isArray(existingList)) {
      existingList = [];
    }
    return [...existingList, ...newSubcontract];
  };

  useEffect(() => {
    setData(engagementData?.subcontractor);
    
    form.setFieldsValue({currency : engagementData?.financial_resourcePlan?.currency ?? tempEngagementFormData?.currency}) 
  }, [engagementData, showAddModal]);

  const { updateSubcontract } = queryKeys;

  const { mutate: updateEngagement, isLoading: isUpdating } = useUpdate(
    updateSubcontract.key,
    updateSubcontract.url
  );

  const onFinish = (value) => {
    const newData = Array.isArray(data) ? [...data, value] : [value];
    if (engagementData) {
      const payload = {
        engagementId: engagementData?.id,
        clientId: engagementData?.clientId,
        subcontractor: newData,
      };
      setData(newData);
      updateEngagement(payload, {
        onSuccess: (res) => {
          notification.success({
            message: "Subcontractor Updated Successfully",
          });
          engagementRefetch()
          setShowAddModal(false);
          form.resetFields();
        },
      });
    } else {
      const existingSubcontractList = tempEngagementFormData?.subcontractList;
      const updatedSubcontractList = updateSubcontractList(
        existingSubcontractList,
        [value]
      );
      const updatedEngagementData = {
        ...tempEngagementFormData,
        subcontractList: updatedSubcontractList,
      };
      dispatch(setTempEngagementFormData(updatedEngagementData));
      setShowAddModal(false);
      form.resetFields();
    }
  };

  const dataSource = engagementData
    ? data
    : tempEngagementFormData?.subcontractList;

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource?.map((item, index) => ({
          ...item,
          key: index,
          amount: currencyAmount("$", item?.amount),
        }))}
        pagination={false}
        rowKey="resource_id"
        footer={() => {
          if (dataSource?.length > 0) {
            const totalAmount = dataSource?.reduce((acc, curr) => {
              return acc + parseInt(curr?.amount);
            }, 0);

            return (
              <p className="m-0 fw-bold">
                TOTAL AMOUNT:{" "}
                {totalAmount ? currencyAmount("$", totalAmount) : 0}
              </p>
            );
          }
        }}
      />
      <AddSubContract
        showAddModal={showAddModal}
        onClose={() => {
          setShowAddModal(false);
          form.resetFields();
        }}
        onFinish={onFinish}
        isUpdating={isUpdating}
        form={form}
        engagementData={engagementData}
      />
    </div>
  );
}

export default SubContractor;
