const roleOption = [
    "General Management",
    "Finance and Admin",
    "IT",
    "Operations",
    "Marketing",
    "Project",
    "Other",
    "Operations Member"
]
const ClientContactJSON = (data) => {
    const ContactDetail = {
        totalpages: 1,
        pages: [
            {
                modalView: false,
                colSpan: [12, 12, 12],
                fields: [
                    // Row 1
                    [
                        {
                            id: 1,
                            label: "Name",
                            type: "text",
                            name: "name",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Name is required",
                                },

                            ],
                            onKeyDown: /^[a-zA-Z ]+$/
                        },
                        {
                            id: 2,
                            label: "Designation",
                            type: "text",
                            name: "designation",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Designation is required",
                                },
                            ],
                            onKeyDown: /^[a-zA-Z ]+$/
                        },
                    ],
                    // Row 2
                    [
                        {
                            id: 3,
                            label: "Role",
                            type: "select",
                            name: "role",
                            options: roleOption,
                            Placeholder: "Enter",
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: "Role is required",
                                },
                            ],
                            onKeyDown: /^[a-zA-Z ]+$/
                        },
                        {
                            id: 5,
                            label: "Mobile",
                            type: "number",
                            name: "mobile",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            validationRules: [{
                                pattern: /^[0-9]{0,20}$/,
                                message: "Mobile number must be 20 characters",
                            }],
                            onKeyDown: /^[0-9]+$/,

                        },
                    ],
                    // Row 3
                    [
                        {
                            id: 6,
                            label: "Office phone",
                            type: "number",
                            name: "officePhone",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 20,
                            onKeyDown: /^[0-9]+$/,
                            validationRules: [{
                                pattern: /^[0-9]{0,20}$/,
                                message: "Office phone number must be 20 characters",
                            }]
                        },
                        {
                            id: 7,
                            label: "Office email",
                            type: "email",
                            name: "officeEmail",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 50,
                            validationRules: [
                                {
                                    required: true,
                                    message: `Email ID is required`,
                                },
                                {
                                    pattern: /^\S+@\S+\.\S+$/,
                                    message: "valid email address is required",
                                },
                            ],
                        },
                    ],
                    //Row 4
                    [
                        {
                            id: 8,
                            label: "Personal email:",
                            type: "text",
                            name: "personalEmail",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 50,
                            validationRules: [

                                {
                                    pattern: /^\S+@\S+\.\S+$/,
                                    message: "valid email address is required",
                                },
                            ],
                        },
                        {

                        }
                    ],
                    [
                        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {
                            id: 9,
                            label: "Back",
                            type: "combinebtnback",
                            position: "end",
                            textColor: "white",
                            event: "submit",
                            combinebtn: true
                        }, {
                            id: 10,
                            label: "Next",
                            type: "combinebtnNxt",
                            position: "end",
                            textColor: "white",
                            event: "submit",
                            combinebtn: true

                        }
                    ]
                ],
            },
        ],
    };
    return {
        ContactDetail
    };
};

export default ClientContactJSON;

