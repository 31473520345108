import { DEVICE } from "common/Device";
import styled from "styled-components";
import {
  Calendar as AntCalendar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Select,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import COLORS from "common/Colors";

export const StyledTitle = styled.div`
  font-size: larger;
  font-weight: bold;
  padding: 10px 0px 20px 0px;
`;

export const StyledAntCalendarSmall = styled(AntCalendar)`
  background: transparent !important;
  .ant-picker-calendar-header {
    display: none;
  }
  .ant-picker-calendar {
    line-height: 1rem;
  }
  .ant-picker-body {
    width: 50px;
    padding-top: 0px !important;
  }
  .ant-picker-content {
    height: 200px;
    th {
      width: 30px;
      line-height: 40px;
      font-weight: bold;
    }
    td {
      padding: 0px;
    }
    tr {
      height: 15px;
      line-height: 15px;
    }
    .ant-picker-calendar-date-content {
      display: none;
    }
  }
  .ant-picker-panel {
    background: transparent !important;
  }
  td,
  th {
    font-size: 0.8rem;
    width: 20px;
  }
  .ant-picker-date-panel {
    width: 95%;
  }
  .ant-picker-cell::before {
    height: 20px;
  }
`;

export const StyledAntCalendarFull = styled(AntCalendar)`
  background: transparent !important;
  .ant-picker-calendar-header {
    display: none;
  }
  .ant-picker-panel {
    background: transparent !important;
  }
  .ant-picker-cell,
  th {
    border: solid 1px #9c9c9c;
    text-align: left;
  }
  th {
    width: 100px;
    padding: 10px !important;
    text-align: center;
    font-weight: bold;
    border-bottom: none;
  }
  th:nth-child(1):after {
    content: "UN";
  }
  th:nth-child(2):after {
    content: "ON";
  }
  th:nth-child(3):after {
    content: "UE";
  }
  th:nth-child(4):after {
    content: "ED";
  }
  th:nth-child(5):after {
    content: "HU";
  }
  th:nth-child(6):after {
    content: "RI";
  }
  th:nth-child(7):after {
    content: "AT";
  }
  .ant-picker-cell-in-view {
    color: ${COLORS.BLACK};
  }
  tbody tr:nth-child(1) td {
    border-top: none !important;
  }

  @media ${DEVICE.mobileL} {
    th {
      font-size: 10px;
    }
  }
`;

export const SmallCalendar = styled(Row)`
  padding-top: 10px;
`;

export const smallCalendarIconStyle = {
  padding: "0px 10px",
  fontSize: "1rem",
  userSelect: "none",
};

export const StyledPreviousIcon = styled(LeftOutlined)`
  ${smallCalendarIconStyle}
`;

export const StyledNextIcon = styled(RightOutlined)`
  ${smallCalendarIconStyle}
`;

export const StyledUpcomingHolidaysContainer = styled.div`
  padding: 20px 0px;
`;

export const StyledLeaveCount = styled.div`
  height: 15px;
  width: 15px;
  margin: 10px;
  ${({ color }) => color && `background: ${color}`};
`;

export const StyledLeaveText = styled.div`
  padding: 5px;
`;

export const StyledDateCell = styled.div`
  padding: 15px 5px 5px 5px;
  .day {
    padding: 6px 5px;
    border-radius: 50px;
    font-size: 0.8rem;
    font-weight: bold;
  }
  .has-pending-leaves {
    background: ${COLORS.PENDING_LEAVE};
    color: ${COLORS.WHITE};
  }
  .hr-leave-container {
    text-align: center;
    padding-top: 50px;

    .ant-badge-count {
      min-width: 30px !important;
      height: 30px !important;
      line-height: 30px !important;
      border-radius: 50px;
      font-size: 14px;
      box-shadow: 0px 1px 5px #6f6f6f;
      font-weight: bold;
    }
    @media (max-width: 935px) {
      .ant-badge-count {
        min-width: 15px !important;
        height: 15px !important;
        line-height: 15px !important;
      }
    }
    @media (max-width: 762px) {
      .ant-badge-count {
        min-width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
      }
    }
    @media (max-width: 600px) {
      .ant-badge-count {
        min-width: 15px !important;
        height: 15px !important;
        line-height: 15px !important;
      }
    }
    .hr-has-pending-leaves {
      margin-right: 2px;
      .ant-badge-count {
        background: ${COLORS.PENDING_LEAVE};
      }
    }
  }
  .hr-has-approved-leaves {
    margin-left: 3px;
    .ant-badge-count {
      background: ${COLORS.APPROVED_LEAVE};
    }
  }
  @media ${DEVICE.mobileL} {
    padding: 0px;
    height: 100px;
    .hr-leave-container {
      padding-top: 0px;
    }
  }
  .has-approved-leaves {
    background: ${COLORS.APPROVED_LEAVE};
    color: ${COLORS.WHITE};
  }
  .ant-picker-calendar-date-content {
    height: 46px;
    margin-top: 5px;
  }
`;

export const StyleDepartmentDropdown = styled(Select)`
  width: 200px;
  text-align: left;
  @media ${DEVICE.tablet} {
    width: 100% !important;
    margin-bottom: 10px;
  }
`;

export const StyledHolidayBadge = styled(Badge)`
  margin-top: 5px;
  background: red;
  padding: 5px;
  color: white;
  border-radius: 50%;
  font-size: 9px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  left: 14px;
`;

export const StyledLeftPanel = styled(Col)`
  @media ${DEVICE.mobileS} {
    padding-bottom: 20px;
  }
`;

export const StyledBookedIndicator = styled.div`
  background: #fc9d25;
  color: white;
  width: 100%;
  border-radius: 5px;
  text-align: center;
`;

export const StyledRowContainer = styled(Card)`
  ${({ color }) => color && `background: ${color}`};
  border-radius: 3px;
  .ant-card-body {
    padding: 2px 5px;
  }
  svg {
    height: 15px !important;
    vertical-align: middle;
    fill: ${COLORS.WHITE} !important;
  }
  .staff-name {
    padding-left: 3px;
    font-size: 0.7rem;
    color: ${COLORS.WHITE};
    font-weight: bold;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 1px;
  }
`;

export const StyledButton = styled(Button)`
  color: ${COLORS.WHITE};
  border: none;
  width: 120px;
  margin: 10px;
  border-radius: 5px;
  width: initial;
  &.ant-btn[disabled] {
    color: white; // Customize the color for disabled state here
  }
`;

export const StyledLeaveDetailsPopover = styled.div`
  width: 350px;
  padding: 5px 1rem 1rem;
  .ant-col {
    padding: 3px;
  }
  .ant-col:nth-child(1) {
    font-weight: bold;
  }
`;

export const StyledLeaveDetailsPopoverFooter = styled.div`
  text-align: right;
  margin-right: -15px;
  .cancel-request {
    margin-right: 10px;
    padding: 0px 10px;
    background: #dbdbdb;
    margin-bottom: 10px;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 5px;
`;

export const StyledAmmendmentTitle = styled.div`
  padding: 10px 0px 7px 0px;
`;
