import { Button, Modal, Table } from "antd";
import { billingOffice } from "common/Constants";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { useQueryState } from "hooks/useQueryState";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getUserList } from "redux/selectors";
import styled from "styled-components";

const columns = [
  {
    title: "S.No",
    dataIndex: "sno",
    key: "sno",
    render: (_, record) => <>{record.sno}</>,
    width: "5%",
  },
  {
    title: "Column 1",
    dataIndex: "column1",
    key: "column1",
    onCell: (record) => ({ colSpan: record.column1.colSpan }),
    render: (text, record) => record.column1.value,
    width: "25%",
  },
  {
    title: "Column 2",
    dataIndex: "column2",
    key: "column2",
    onCell: (record) => ({ colSpan: record.column2.colSpan }),
    render: (text, record) => record.column2.value,
    width: "35%",
  },
  {
    title: "Column 3",
    dataIndex: "column3",
    key: "column3",
    onCell: (record) => ({ colSpan: record.column3.colSpan }),
    render: (text, record) => record.column3.value,
    className: "fw-bold",
    width: "15%",
  },
  {
    title: "Column 4",
    dataIndex: "column4",
    key: "column4",
    onCell: (record) => ({ colSpan: record.column4.colSpan }),
    render: (text, record) => record.column4.value,
    align: "right",
    width: "20%",
  },
];

const accountsColumns = [
  {
    title: "Column 1",
    dataIndex: "column1",
    key: "column1",
    onCell: (record) => ({ colSpan: record.column1.colSpan }),
    render: (text, record) => record.column1.value,
    width: "25%",
  },
  {
    title: "Column 2",
    dataIndex: "column2",
    key: "column2",
    onCell: (record) => ({ colSpan: record.column2.colSpan }),
    render: (text, record) => record.column2.value,
    width: "25%",
  },
  {
    title: "Column 3",
    dataIndex: "column3",
    key: "column3",
    onCell: (record) => ({ colSpan: record.column3.colSpan }),
    render: (text, record) => record.column3.value,
    width: "25%",
  },
  {
    title: "Column 4",
    dataIndex: "column4",
    key: "column4",
    onCell: (record) => ({ colSpan: record.column4.colSpan }),
    render: (text, record) => record.column4.value,
    align: "right",
    width: "25%",
  },
];

const BillingInvoice = ({ billingInvoiceData, open, handleClose }) => {
  const userList = useSelector(getUserList);

  const clientId = billingInvoiceData?.clientcode;
  const engagementId = billingInvoiceData?.engagementid;

  const { key, url } = queryKeys.getClient(clientId);

  const { data: clientDataRes } = useFetch(key, url, {
    endpoint: !!clientId,
  });

  const { key: engagementKey, url: engagementUrl } =
    queryKeys.getEngagementById(engagementId);

  const { isFetched } = useQueryState(key);

  const { data: engagementDataRes } = useFetch(engagementKey, engagementUrl, {
    enabled: !isFetched && !!engagementId,
  });

  const engagementData = engagementDataRes?.data;

  const clientData = clientDataRes?.data?.[0];

  const billingOfficeName = billingOffice.find(
    (item) => item.id == engagementData?.financial_resourcePlan?.billingOffice
  )?.name;

  const competency = engagementData?.client?.competency;

  const serviceLine = engagementData?.client?.serviceLine;

  const clientName = engagementData?.client?.clientName;

  const address = clientData?.bdo_registeredaddress;

  const registeredNumber = billingInvoiceData?.loerefno;

  const pinNumber = clientData?.bdo_pin;

  const contactPerson = billingInvoiceData?.contactperson;
  const contactPersonAc = billingInvoiceData?.contactpersonac;

  const clientEmailAddress = clientData?.emailaddress1;

  const description = billingInvoiceData?.description;

  const fees = parseInt(billingInvoiceData?.amount) || parseInt(billingInvoiceData?.amount?.replace(/[$,]/g, ''), 10) || 0;

   const Disbursement = billingInvoiceData?.claims?.reduce(
    (acc, claim) =>claim.type !== "Subcontract" ? acc + (parseInt(claim.amount) || 0) : acc,
    0
  );
  const Subcontract = billingInvoiceData?.claims?.reduce((acc, claim) => {
    return claim.type === "Subcontract" ? acc + (parseInt(claim.amount) || 0) : acc;
  }, 0);

  const total = fees + Disbursement + Subcontract;

  const partnerName = userList?.find(
    (user) => user.userid === engagementData?.stakeHolder?.partner
  )?.fullname;



  const personInCharge = userList?.find(
    (user) => user.userid === engagementData?.stakeHolder?.personIncharge
  )?.fullname;

  const billingStatus = billingInvoiceData?.statusname;
  const approvedby = billingInvoiceData?.engagementPartner;
  const approvedbyName = userList?.find((user)=> user?.userid === approvedby)?.fullname;



  const dataSource = [
    {
      sno: 1,
      column1: { value: "Department", colSpan: 1 },
      column2: { value: competency, colSpan: 3 },
      column3: { value: "", colSpan: 0 },
      column4: { value: "", colSpan: 0 },
    },
    {
      sno: 2,
      column1: { value: "Company", colSpan: 1 },
      column2: { value: billingOfficeName, colSpan: 1 },
      column3: { value: "Date", colSpan: 1 },
      column4: { value: moment().format("DD MMM YYYY"), colSpan: 1 },
    },
    {
      sno: 3,
      column1: { value: "Service Line", colSpan: 1 },
      column2: { value: serviceLine, colSpan: 3 },
      column3: { value: "", colSpan: 0 },
      column4: { value: "", colSpan: 0 },
    },
    {
      sno: 4,
      column1: { value: "Client Name", colSpan: 1 },
      column2: { value: clientName, colSpan: 3 },
      column3: { value: "", colSpan: 0 },
      column4: { value: "", colSpan: 0 },
    },
    {
      sno: 5,
      column1: { value: "Address", colSpan: 1 },
      column2: { value: address, colSpan: 3 },
      column3: { value: "", colSpan: 0 },
      column4: { value: "", colSpan: 0 },
    },
    {
      sno: 6,
      column1: { value: "Contact Person", colSpan: 1 },
      column2: { value: contactPerson, colSpan: 3 },
      column3: { value: "", colSpan: 0 },
      column4: { value: "", colSpan: 0 },
    },
    {
      sno: "",
      column1: { value: "Contact Person A/C", colSpan: 1 },
      column2: { value: contactPersonAc, colSpan: 3 },
      column3: { value: "", colSpan: 0 },
      column4: { value: "", colSpan: 0 },
    },
    {
      sno: "",
      column1: { value: "Email addresses", colSpan: 1 },
      column2: { value: clientEmailAddress, colSpan: 3 },
      column3: { value: "", colSpan: 0 },
      column4: { value: "", colSpan: 0 },
    },
    {
      sno: 7,
      column1: { value: "LOE Ref No", colSpan: 1 },
      column2: { value: registeredNumber, colSpan: 1 },
      column3: { value: "Client PIN", colSpan: 1 },
      column4: { value: pinNumber, colSpan: 1 },
    },
    {
      sn: 8,
      column1: { value: "Code (Time sheet system)", colSpan: 1 },
      column2: { value: "", colSpan: 1 },
      column3: { value: "Currency", colSpan: 1 },
      column4: { value: "USD", colSpan: 1 },
    },
    {
      sno: 9,
      column1: { value: "Description of Services Rendered", colSpan: 1 },
      column2: { value: description, colSpan: 3 },
      column3: { value: "", colSpan: 0 },
      column4: { value: "", colSpan: 0 },
    },
    {
      sno: 10,
      column1: { value: "Fees", colSpan: 1 },
      column2: { value: "", colSpan: 1 },
      column3: { value: "", colSpan: 1 },
      column4: { value: fees, colSpan: 1 },
    },
    {
      sno: "",
      column1: { value: "Disbursement(Claims)", colSpan: 1 },
      column2: { value: "", colSpan: 1 },
      column3: { value: "", colSpan: 1 },
      column4: { value: Disbursement, colSpan: 1 },
    },
    {
      sno: "",
      column1: { value: "Subcontract(Claims)", colSpan: 1 },
      column2: { value: "", colSpan: 1 },
      column3: { value: "", colSpan: 1 },
      column4: { value: Subcontract, colSpan: 1 },
    },
    {
      sno: "",
      column1: { value: "VAT - 16%", colSpan: 1 },
      column2: { value: "", colSpan: 1 },
      column3: { value: "", colSpan: 1 },
      column4: { value: ".00", colSpan: 1 },
    },
    {
      sno: 11,
      column1: { value: "TOTAL", colSpan: 1 },
      column2: { value: "", colSpan: 1 },
      column3: { value: "", colSpan: 1 },
      column4: { value: total ? total : "-", colSpan: 1 },
    },
    {
      sno: 12,
      column1: { value: "Name of Incharge", colSpan: 1 },
      column2: { value: "", colSpan: 1 },
      column3: { value: "", colSpan: 1 },
      column4: { value: "", colSpan: 1 },
    },
    {
      sno: "",
      column1: { value: "Partner", colSpan: 1 },
      column2: { value: partnerName, colSpan: 1 },
      column3: { value: "Senior", colSpan: 1 },
      column4: { value: "", colSpan: 1 },
    },
    {
      sno: "",
      column1: { value: "Manager", colSpan: 1 },
      column2: { value: personInCharge, colSpan: 1 },
      column3: { value: "Semi Senior", colSpan: 1 },
      column4: { value: "", colSpan: 1 },
    },
    // {
    //   sno: "",
    //   column1: { value: "Assistant Manager", colSpan: 1 },
    //   column2: { value: "Semi Senior", colSpan: 1 },
    //   column3: { value: "", colSpan: 1 },
    //   column4: { value: "", colSpan: 1 },
    // },
    // {
    //   sno: "",
    //   column1: { value: "Supervisors", colSpan: 1 },
    //   column2: { value: "", colSpan: 1 },
    //   column3: { value: "", colSpan: 1 },
    //   column4: { value: "", colSpan: 1 },
    // },
    {
      sno: 13,
      column1: {
        value:
          "Staff responsible for cash collection for this job and debtor's ledger reconciliation",
        colSpan: 2,
      },
      column2: { value: "", colSpan: 0 },
      column3: { value: "", colSpan: 1 },
      column4: { value: "", colSpan: 1 },
    },
  ];

  const printComponentRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    bodyClass: "p-3",
  });

  return (
    <StyledModal
      open={open}
      onCancel={handleClose}
      title={"DCM Advisory Services Ltd (Kenya)"}
      width={1200}
      footer={null}
      closable={true}
      maskClosable={false}
      centered={true}
    >
      <BillingInvoiceWrap ref={printComponentRef}>
        <div className="invoice-heading">
          INVOICE/CREDIT NOTE Raising Request
        </div>
        <div>
          <div className="heading">REQUEST FOR FEE NOTE</div>

          <BillingInvoiceTable
            columns={columns}
            dataSource={dataSource}
            bordered={true}
            pagination={false}
            showHeader={false}
          />

          <div className="section-row">
            <div className="left">{billingStatus}</div>
            <div className="right">{(billingStatus === "Approved by Partner" || billingStatus === "Approved by Finance") ? approvedbyName : "-"}</div>
          </div>

          <div className="section-row">
            <div className="left">Signature</div>
            <div className="right">{moment().format("DD MMM YYYY")}</div>
          </div>

          <div className="accepatance">
            I, certify that the above amount billed is in accordance with the
            engagement letter and/or the assignment has been completed at client
            satisfaction.
          </div>

          {/* <div className="accounts-heading">For accounts department</div>

        <BillingInvoiceTable
          columns={accountsColumns}
          dataSource={[
            {
              column1: { value: "Batch number", colSpan: 1 },
              column2: { value: "", colSpan: 1 },
              column3: { value: "PROFORMA INVOICE Number", colSpan: 1 },
              column4: { value: "", colSpan: 1 },
            },
          ]}
          bordered={true}
          pagination={false}
          showHeader={false}
        />

        <div className="complete">
          This completed and signed off document will be filed at
          \\Bdoserver\41-FEE NOTES\200-BDO EA invoice raising reques
        </div> */}
        </div>
      </BillingInvoiceWrap>
      <div
        style={{ display: "flex", gap: "8px", justifyContent: "end" }}
        className="mb-3 mt-2"
      >
        <div>
          <Button type="primary" className="ms-0" onClick={handlePrint}>
            Print Invoice
          </Button>
        </div>
      </div>
    </StyledModal>
  );
};

export default BillingInvoice;

const BillingInvoiceWrap = styled.div`
  .invoice-heading {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: start;
  }
  .heading {
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
  }
  .section-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-weight: 600;
    .right {
      padding: 10px 40px 10px 40px;
      border-bottom: 2px solid #000;
      min-width: 160px;
    }
  }
  .accepatance {
    font-weight: 600;
    margin-top: 20px;
  }
  .accounts-heading {
    font-size: 17px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: start;
  }
  .complete {
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
  }
`;

const BillingInvoiceTable = styled(Table)`
  .ant-table-cell.nowrap {
    white-space: nowrap;
  }
  .ant-table-cell.border-right-none {
    border-right: none !important;
  }
  .ant-table-cell.border-bottom-none {
    border-bottom: none;
  }
  .ant-table-cell {
    padding: 6px 10px !important;
    font-size: 14px;
    font-weight: 500;
  }
  .ant-table-thead > tr > th {
    overflow-wrap: normal;
  }
  .ant-table-cell:has(.extra-section) {
    border-bottom: 5px solid #f0f0f0;
  }
  margin-bottom: 20px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    .anticon-close.ant-modal-close-icon,
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .ant-modal-header {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 10px !important;
  }
  .ant-modal-title {
    font-weight: 500;
    text-align: left;
    color: #1b1b1b;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
    height: 85vh;
    overflow-y: scroll;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
  .ant-upload-span {
    cursor: pointer;
  }
`;
