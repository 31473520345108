import { useCallback, useRef } from "react";
import { useInfiniteQuery } from "react-query";
import { apiInstance } from "config/baseUrl";

export const useScrollFetchPost2 = (queryKey, url, options) => {
  const observer = useRef();
  const accessToken = window.localStorage.getItem("token");

  const fetcher = async (pageParam) => {
    const { endPoint, queryParam, limit = "", search = "", extra } = url || {};
    const pages = pageParam || 0;

    let params = {};
    if (limit) params.limit = limit;
    if (search) params.search = search;
    if (queryParam) params[queryParam] = pages;
    if (extra) {
      for (let key in extra) {
        params[key] = extra[key];
      }
    }

    return await apiInstance({
      method: "POST",
      url: endPoint,
      headers: {
        Tymeplusjwt: accessToken,
      },
      data: params,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
  };

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading, refetch } =
    useInfiniteQuery({
      queryKey: [queryKey],
      queryFn: ({ pageParam }) => fetcher(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.response?.LastEvaluatedKey?.id === null) {
          return null;
        }
        if (lastPage?.response?.LastEvaluatedKey?.id) {
          return lastPage.LastEvaluatedKey;
        }
        return null;
      },
      ...options,
      refetchOnWindowFocus: false,
      initialPageParam: 0,
    });

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  );

  const isReloading = isFetching || isLoading;

  return { data, isReloading, isLoading, lastElementRef, refetch };
};
