import { get } from "lodash";
import {
  USERNAME,
  TOGGLE_DRAWER,
  USER_ROLE,
  USER_ROLE_TYPE,
  IS_LOGGED_IN,
  SET_DEPARTMENT_ID,
  SET_SYSTEM_DATE,
  SET_LOCATIONS,
  SET_BREAK_TYPES,
  SET_CHECK_IN_DATE_TIME,
  SET_CHECK_OUT_DATE_TIME,
  SET_IS_ON_BREAK,
  TOGGLE_SUCCESS_MODAL,
  SET_USER_LIST,
  TOGGLE_PROCESSING_MODAL,
  LOADING_DATE_FIRST_TIME,
  LOADING_ROUTE,
  SET_DEPARTMENT_LIST,
  SET_SUPERVISOR_LIST,
  SET_LEAVE_CATEGORY_LIST,
  SET_PUNCH_ACTIONS,
  SET_ROLE_LIST,
  SET_USER_ROLE_LIST,
  CLEAR_STORE,
  SET_IS_CHECKED_IN,
  SET_CHECK_IN_LOCATION_ID,
  SET_CHECK_IN_DEPARTMENT_ID,
  SET_STATUS_LIST,
  SET_ALL_DEPARTMENT_LIST,
  SET_BREAK_ID,
  SET_PUNCH_ID,
  SET_SHIFT_TIME_MASTER,
  SET_NOTIFICATION_LIST,
  SET_WEB_SOCKET_STATUS,
  USER_PROFILE,
  SET_CHAT_MESSAGE_LIST,
  SET_CHAT_SELECTED_STAFF_ID,
  SET_CHAT_MESSAGE_QUEUE,
  SET_UNREAD_MESSAGE_INDICATOR,
  PROFILE_PIC,
  MENU_LIST,
  USER_MENU_LIST,
  CLIENT_ID,
  CURRENT_PLAN,
  TRAIL_PERIOD,
  SUBSCRIPTION_ID,
  SUBSCRIPTION_STATUS,
  BAND_LIST,
  AI_CHAT_HISTORY,
  CURRENT_AI_MESSAGE_DETAILS,
  FORM_NAME,
  LEVEL_LIST,
  SET_FILTER,
  ALL_ROLE,
  ALL_LOCATION,
  ALL_COMPETENCY,
  ALL_LEAVE,
  ALL_HOLIDAY,
  ALL_SHIFT,
  ALL_BREAK,
  ACTIVE_MENU_LIST,
  SET_CACHE_TIMESHEET,
  SET_FILTER_CLIENT_DATA,
  SET_TEMP_ENGAGEMENT_FORM_DATA,
  SET_STATUS_CODES,
  SET_RESOURCE_DATA,
  SET_SELECTED_ENGAGEMENT_TAB,
  SET_MAIN_ENGAGEMENT_TAB,
  SET_CRM_MANAGINGOFFICE_LIST
} from "../actionsTypes";

const initialState = {
  userInfo: {
    name: "",
    roleId: 0,
    userRoleType: "",
    location_name: "",
    joining_date: "",
    username: "",
    email_id: "",
    job_title: "",
    department: "",
    payroll: "",
    employer_id: "",
    staff_department_id: "",
    threadid: "",
    role_id: [],
  },
  webSocketStatus: 0,
  roleName: "",
  toggleDrawer: false,
  isLoggedIn: false,
  selectedDepartmentId: [],
  date: "",
  checkInDateTime: "",
  checkOutDateTime: "",
  isCheckedIn: false,
  checkInLocationId: "",
  checkInDepartmentId: "",
  isOnBreak: false,
  breakId: "",
  punchId: "",
  displaySuccessModal: false,
  successModalMessage: "",
  displayProcessingModal: false,
  loadingDataFirstTime: true,
  loadingRoute: false,
  departmentList: [],
  allDepartmentList: [],
  locationList: [],
  breakTypeList: [],
  userList: [],
  leaveCategoryList: [],
  punchActions: [],
  roleList: [],
  userRoleList: [],
  shiftTimeMaster: [],
  holidayList: [],
  notificationList: [],
  statusList: [],
  chatMessageList: [],
  chatSelectedStaffId: "",
  chatMessageQueue: [],
  newMessageIndicatorList: [],
  profilePic: "",
  menuList: [],
  activeMenuList: [],
  bandList: [],
  userMenuList: [],
  clientid: "",
  trailperiod: "",
  currentplan: "",
  aiChatHistory: [],
  currentAIMessageDetails: {},
  formName: "",
  supervisorList: [],
  levelList: [],
  statusCodes: [],
  filters: {
    employee: {
      employee: [],
      supervisor: [],
      competencyrole: [],
      managingoffice: [],
      leveldepartmentname: [],
      competencyQC: [],
      serviceline: [],
      team: [],
      grade: [],
    },
    shift: {
      employee: [],
      supervisor: [],
      competencyrole: [],
      managingoffice: [],
      leveldepartmentname: [],
      competencyQC: [],
      serviceline: [],
      team: [],
      grade: [],
    },
    myTeam: {
      employee: [],
      supervisor: [],
      competencyrole: [],
      managingoffice: [],
      leveldepartmentname: [],
      competencyQC: [],
      serviceline: [],
      team: [],
      grade: [],
    },
    hremployee: {
      employee: [],
      supervisor: [],
      competencyrole: [],
      managingoffice: [],
      leveldepartmentname: [],
      competencyQC: [],
      serviceline: [],
      team: [],
      grade: [],
    },
    clientcontact: {
      name: [],
      managingoffice: [],
      status: [],
    },
    client: {
      name: [],
      managingoffice: [],
      status: [],
      owner: [],
      relationship_manager: []
    },
  },
  cachedTimeSheets: {},
  allRoleList: [],
  allCompetency: [],
  allBreak: [],
  allHoliday: [],
  allShift: [],
  allLeave: [],
  allLocation: [],
  ownerList: [],
  sectorList: [],
  filterClientData: [],
  crmManagingOfficeList: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USERNAME:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          name: action.payload,
        },
      };
    case USER_ROLE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          roleId: action.payload,
        },
      };
    case USER_ROLE_TYPE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userRoleType: action.payload,
        },
      };
    case USER_PROFILE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          location_name: get(action.payload, "location_name", ""),
          joining_date: get(action.payload, "joiningdate", ""),
          username: get(action.payload, "username", ""),
          email_id: get(action.payload, "email_id", ""),
          job_title: get(action.payload, "job_title", ""),
          department: get(action.payload, "department", ""),
          payroll: get(action.payload, "payroll", ""),
          employer_id: get(action.payload, "userid", ""),
          staff_department_id: get(action.payload, "staff_department_id", ""),
          userid: get(action.payload, "userid", ""),
          threadid: get(action.payload, "threadid", ""),
          role_id: get(action.payload, "role_id", ""),
          baselocationid: get(action.payload, "baselocationid", ""),
        },
      };
    case TOGGLE_DRAWER:
      return {
        ...state,
        toggleDrawer: action.payload,
      };
    case IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case SET_DEPARTMENT_ID:
      return {
        ...state,
        selectedDepartmentId: action.payload,
      };
    case SET_SYSTEM_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locationList: action.payload,
      };
    case SET_BREAK_TYPES:
      return {
        ...state,
        breakTypeList: action.payload,
      };
    case SET_CHECK_IN_DATE_TIME:
      return {
        ...state,
        checkInDateTime: action.payload,
      };
    case SET_CHECK_OUT_DATE_TIME:
      return {
        ...state,
        checkOutDateTime: action.payload,
      };
    case SET_IS_ON_BREAK:
      return {
        ...state,
        isOnBreak: action.payload,
      };
    case TOGGLE_SUCCESS_MODAL:
      return {
        ...state,
        displaySuccessModal: action.payload.visibility,
        successModalMessage: action.payload.message,
      };
    case SET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };
    case TOGGLE_PROCESSING_MODAL:
      return {
        ...state,
        displayProcessingModal: action.payload,
      };
    case LOADING_DATE_FIRST_TIME:
      return {
        ...state,
        loadingDataFirstTime: action.payload,
      };
    case LOADING_ROUTE:
      return {
        ...state,
        loadingRoute: action.payload,
    };
    case SET_DEPARTMENT_LIST:
      return {
        ...state,
        departmentList: action.payload,
      };
    case SET_SUPERVISOR_LIST:
      return {
        ...state,
        supervisorList: action.payload,
      };
    case SET_LEAVE_CATEGORY_LIST:
      return {
        ...state,
        leaveCategoryList: action.payload,
      };
    case SET_PUNCH_ACTIONS:
      return {
        ...state,
        punchActions: action.payload,
      };
    case SET_ROLE_LIST:
      return {
        ...state,
        roleList: action.payload,
      };
    case SET_USER_ROLE_LIST:
      return {
        ...state,
        userRoleList: action.payload,
      };
    case SET_IS_CHECKED_IN:
      return {
        ...state,
        isCheckedIn: action.payload,
      };
    case SET_CHECK_IN_LOCATION_ID:
      return {
        ...state,
        checkInLocationId: action.payload,
      };
    case SET_CHECK_IN_DEPARTMENT_ID:
      return {
        ...state,
        checkInDepartmentId: action.payload,
      };
    case SET_STATUS_LIST:
      return {
        ...state,
        statusList: action.payload,
      };
    case SET_ALL_DEPARTMENT_LIST:
      return {
        ...state,
        allDepartmentList: action.payload,
      };
    case SET_PUNCH_ID:
      return {
        ...state,
        punchId: action.payload,
      };
    case SET_BREAK_ID:
      return {
        ...state,
        breakId: action.payload,
      };
    case SET_SHIFT_TIME_MASTER:
      return {
        ...state,
        shiftTimeMaster: action.payload,
      };
    case SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case SET_WEB_SOCKET_STATUS:
      return {
        ...state,
        webSocketStatus: action.payload,
      };
    case SET_CHAT_MESSAGE_LIST:
      return {
        ...state,
        chatMessageList: action.payload,
      };
    case SET_CHAT_SELECTED_STAFF_ID:
      return {
        ...state,
        chatSelectedStaffId: action.payload,
      };
    case SET_CHAT_MESSAGE_QUEUE:
      return {
        ...state,
        chatMessageQueue: action.payload,
      };
    case SET_UNREAD_MESSAGE_INDICATOR:
      return {
        ...state,
        newMessageIndicatorList: action.payload,
      };
    case SUBSCRIPTION_ID:
      return {
        ...state,
        subscriptionId: action.payload,
      };
    case SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    case PROFILE_PIC:
      return {
        ...state,
        profilePic: action.payload,
      };
    case MENU_LIST:
      return {
        ...state,
        menuList: action.payload,
      };
    case ACTIVE_MENU_LIST:
      return {
        ...state,
        activeMenuList: action.payload,
      };
    case BAND_LIST:
      return {
        ...state,
        bandList: action.payload,
      };
    case USER_MENU_LIST:
      return {
        ...state,
        userMenuList: action.payload,
      };
    case CLIENT_ID:
      return {
        ...state,
        clientid: action.payload,
      };
    case CURRENT_PLAN:
      return {
        ...state,
        currentplan: action.payload,
      };
    case TRAIL_PERIOD:
      return {
        ...state,
        trailperiod: action.payload,
      };
    case CLEAR_STORE:
      return {
        ...initialState,
      };
    case AI_CHAT_HISTORY:
      return {
        ...state,
        aiChatHistory: action.payload,
      };

    case CURRENT_AI_MESSAGE_DETAILS:
      return {
        ...state,
        currentAIMessageDetails: action.payload,
      };
    case FORM_NAME:
      return {
        ...state,
        formName: action.payload,
      };
    case LEVEL_LIST:
      return {
        ...state,
        levelList: action.payload,
      };
    case SET_CACHE_TIMESHEET:
      return {
        ...state,
        cachedTimeSheets: {
          ...state.cachedTimeSheets,
          [action.payload.week]: action.payload.value,
        },
      };
    case SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.menuName]: {
            ...state.filters[action.payload.menuName],
            [action.payload.filterName]: action.payload.filterValue,
          },
        },
      };
    case SET_FILTER_CLIENT_DATA:
        return {
          ...state,
          filterClientData: action.payload,
        };
    case SET_TEMP_ENGAGEMENT_FORM_DATA:
      return {
        ...state,
        tempengagementformdata: action.payload,
      };
    case SET_SELECTED_ENGAGEMENT_TAB:
      return {
        ...state,
        engagementselectedtab: action.payload,
      };
      case SET_MAIN_ENGAGEMENT_TAB:
      return {
        ...state,
        engagementmaintab: action.payload,
      };
      case SET_RESOURCE_DATA:
        return {
          ...state,
          resourceData: action.payload,
        };  
    case ALL_ROLE:
      return {
        ...state,
        allRoleList: action.payload,
      };
    case ALL_COMPETENCY:
      return {
        ...state,
        allCompetency: action.payload,
      };
    case ALL_LOCATION:
      return {
        ...state,
        allLocation: action.payload,
      };
    case ALL_BREAK:
      return {
        ...state,
        allBreak: action.payload,
      };
    case ALL_LEAVE:
      return {
        ...state,
        allLeave: action.payload,
      };
    case ALL_SHIFT:
      return {
        ...state,
        allShift: action.payload,
      };
    case ALL_HOLIDAY:
      return {
        ...state,
        allHoliday: action.payload,
      };
    case SET_STATUS_CODES:
      return {
        ...state,
        statusCodes: action.payload,
      };
      case SET_CRM_MANAGINGOFFICE_LIST:
        return {
          ...state,
          crmManagingOfficeList: action.payload,
        };
    default:
      return state;
  }
}
