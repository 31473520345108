import React, { useState } from "react";
import styled from "styled-components";
// import { getAcceptPerformJson } from "./JsonFormat/acceptperfJson";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import ReusableTable from "./ReusableTable";
import { Col, Row } from "antd";

const StyledWrap = styled.div`
  .main_para {
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-family: Lato, sans-serif;
    font-weight: normal;
  }
  .form_div {
    padding: 20px;
    box-shadow: 0px 3px 6px #00000015;
    border-radius: 5px;
  }
  .custom-box {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000015;
    border: 1px solid #ededed;
  }
  .gaf-column-header{
    align-content: baseline;
  }
`;
const AcceptPerformance = () => {
  const [data, setData] = useState({});
  const AcceptPerfContractForm = {
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Name:",
              type: "select",
              name: "name",
              Placeholder: "select",
              value: data["name"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 2,
              label: "Staff Level:",
              type: "select",
              name: "staffLevel",
              Placeholder: "select",
              value: data["staffLevel"],
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 3,
              label: "Firm:",
              type: "select",
              name: "firm",
              Placeholder: "select",
              value: data["firm"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 4,
              label: "Organisational Level:",
              type: "select",
              name: "organisationallevel",
              Placeholder: "select",
              value: data["organisationallevel"],
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 5,
              label: "Year:",
              type: "select",
              name: "year",
              Placeholder: "select",
              value: data["year"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 6,
              label: "Competency/Quality Circle:",
              type: "select",
              name: "competency/Qualitycircle",
              Placeholder: "select",
              value: data["competency/Qualitycircle"],
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 7,
              label: "Period:",
              type: "select",
              name: "period",
              Placeholder: "select",
              value: data["period"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 8,
              label: "Additional:",
              type: "select",
              name: "additional",
              Placeholder: "select",
              value: data["additional"],
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 9,
              label: "Department:",
              type: "select",
              name: "department",
              Placeholder: "select",
              value: data["department"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 10,
              label: "Service Line Expertise:",
              type: "text",
              name: "serviceLineExpertise",
              Placeholder: "Enter",
              value: data["serviceLineExpertise"],
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 11,
              label: "Industry Expertise:",
              type: "text",
              name: "industryExpertise",
              Placeholder: "Enter",
              value: data["industryExpertise"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 12,
              label: "Grade:",
              type: "select",
              name: "grade",
              Placeholder: "select",
              value: data["grade"],
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 13,
              label: "Hired Date:",
              type: "date",
              name: "hiredDate",
              Placeholder: "Select",
              value: data["hiredDate"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 14,
              label: "Years with Firm:",
              type: "text",
              name: "yearswithFirm",
              Placeholder: "Enter",
              value: data["yearswithFirm"],
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 15,
              label: "Graduation Status:",
              type: "text",
              name: "graduationStatus",
              Placeholder: "Enter",
              value: data["graduationStatus"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 16,
              label: "Professional Certification:",
              type: "select",
              name: "professionalCertification",
              Placeholder: "Select",
              value: data["professionalCertification"],
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 17,
              label: "L&D Completed:",
              type: "text",
              name: "l&dcompleted",
              Placeholder: "Enter",
              value: data["l&dcompleted"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 18,
              label: "Technical Skills Expertise:",
              type: "text",
              name: "technicalSkillsExpertise",
              Placeholder: "Enter",
              value: data["technicalSkillsExpertise"],
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 19,
              label: "Last Promotion Date:",
              type: "date",
              name: "lastPromotionDate",
              Placeholder: "Select",
              value: data["lastPromotionDate"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 20,
              label: "Last Salary Increase Date:",
              type: "date",
              name: "lastSalaryIncreaseDate",
              Placeholder: "Select",
              value: data["lastSalaryIncreaseDate"],
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 21,
              label: "Target Chargeability %:",
              type: "text",
              name: "targetChargeability",
              Placeholder: "Enter",
              value: data["targetChargeability"],
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 22,
              label: "Chargeout Rate:",
              type: "text",
              name: "chargeoutRate",
              Placeholder: "Enter",
              value: data["chargeoutRate"],
              maxLength: 35,
              validationRules: [],
            },
          ],
        ],
      },
    ],
  };

  const AcceptPerformDatacolumns = [
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
      width: "70%",
    },
    {
      title: "%",
      dataIndex: "percent",
      key: "percent",
      width: "30%",
      className: "normal-column",
    },
  ];
  const AcceptPerformData = [
    {
      category: "Cluster",
      percent: "0",
    },
    {
      category: "Firm",
      percent: "0",
    },
    {
      category: "Technical",
      percent: "0",
    },
    {
      category: "Individual",
      percent: "0",
    },
    {
      category: "TOTAL",
      percent: "0",
    },
  ];
  const BasicForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Reviewer:",
              type: "text",
              name: "reviewer",
              Placeholder: "Enter",
            },
            {
              id: 2,
              label: "Chargeout Rate:",
              type: "text",
              name: "chargeoutrate",
              Placeholder: "Enter",
            },
          ],
        ],
      },
    ],
  };
  const BasicForm1 = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Individual- Wow Factor(10%):",
              type: "text",
              name: "wowfactor",
              Placeholder: "Enter",
            },
          ],
          [
            {
              id: 2,
              label: "Self Overall Comment:",
              type: "textarea",
              name: "SelfOverallComment",
              Placeholder: "Enter",
            },
          ],
          [
            {
              id: 3,
              label: "Close",
              type: "button",
              position: "start",
              textColor: "white",
              event: "submit",
            },
          ],
        ],
      },
    ],
  };
  const TechnicalAssessmentColumns = [
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      width: "90%",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "10%",
      className: "normal-column",
    },
  ];
  const TechnicalAssessmentColumns1 = [
    {
      title: "Pillars",
      dataIndex: "Pillars",
      key: "Pillars",
      width: "30%",
    },
    {
      title: "Activity",
      dataIndex: "Activity",
      key: "Activity",
      width: "50%",
      className: "normal-column",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "20%",
      className: "normal-column",
    },
  ];
  const TechnicalAssessmentColumns2 = [
    {
      Description: (
        <>
          <h6 className="mb-0 pb-0">Value & Efficiency:</h6>
          <p>
            {" "}
            Knowledgeable and well prepared to handle the allocated work. The
            methodology and software used was appropriate. Conclusions reached
            were useful, realistic and adequately supported by relevant facts
            and thorough analysis.
          </p>
        </>
      ),
      weight: "0",
    },
    {
      Description: (
        <>
          <h6>Timely Delivery:</h6>{" "}
          <p>
            The assignment / tasks / sections were completed on a timely basis.
            The timeliness of response to phone calls/e-mails showed care and
            good communication skills. No undue disruption to workplace during
            the assignment.
          </p>
        </>
      ),
      weight: "0",
    },
    {
      Description: (
        <>
          <h6>Quality, Compliance & Risk:</h6>{" "}
          <p>
            Quality and Risks were properly managed and with compliance to
            methodology, tools and processes was as per expectation. Also,
            safeguarding of documents and work environment and staff commitments
            were respected.
          </p>
        </>
      ),
      weight: "0",
    },
    {
      Description: (
        <>
          <h6>Client satisfaction:</h6>{" "}
          <p>
            Demonstrated professionalism and the handling of client, staff and
            supervision of the job was impeccable. Both internal and external
            stakeholders were satisfied with the delivery.
          </p>
        </>
      ),
      weight: "0",
    },
    { Description: <h6>Client Survey Score</h6>, weight: "0" },
    {
      Description: <h6>TOTAL</h6>,
      weight: "0",
    },
  ];
  const IndividualForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "No of years with the Firm:",
              type: "text",
              name: "noofyears",
              Placeholder: "Enter",
            },
            {
              id: 2,
              label: "Years of Experience:",
              type: "text",
              name: "yearsofexperience",
              Placeholder: "Enter",
            },
          ],
          [
            {
              id: 3,
              label: "Qualifications Category:",
              type: "text",
              name: "1ualificationscategory",
              Placeholder: "Enter",
            },
            {
              id: 2,
              label: "Age:",
              type: "select",
              name: "age",
              Placeholder: "Select",
            },
          ],
        ],
      },
    ],
  };
  const tempdata2 = [
    {
      category: "On The Job",
      percent: "0",
    },
    {
      category: "Off the job",
      percent: "0",
    },
    {
      category: "TOTAL",
      percent: "0",
    },
  ];
  const tempdata3 = [
    {
      Description: (
        <div>
          <h6>Value & Efficiency:</h6>
          Knowledgeable and well prepared to handle the allocated work. The methodology and software used was appropriate. Conclusions reached were useful, realistic and adequately supported by relevant facts and thorough analysis.
        </div>
      ),
      weight: "0",
    },
    {
      Description: (
        <div>
          <h6>Timely Delivery:</h6>
          The assignment / tasks / sections were completed on a timely basis. The timeliness of response to phone calls/e-mails showed care and good communication skills. No undue disruption to workplace during the assignment.
        </div>
      ),
        
      weight: "0",
    },
    {
      Description: (
        <div>
          <h6>Quality, Compliance & Risk:</h6>
          Quality and Risks were properly managed and with compliance to methodology, tools and processes was as per expectation. Also, safeguarding of documents and work environment and staff commitments were respected.
        </div>
      ),
        
      weight: "0",
    },
    {
      Description: (
        <div>
          <h6>Client satisfaction:</h6>
          Demonstrated professionalism and the handling of client, staff and supervision of the job was impeccable. Both internal and external stakeholders were satisfied with the delivery.
        </div>
      ),
      weight: "0",
    },
    {
      Description:
        "Client Survey Score",
      weight: "0",
    },
    {
      Description:
        "Total",
      weight: "0",
    },
  ];
  const tempdata4 = [
    {
      Pillars: "Additional Roles",
      Activity:
        "Assist in the investigation of significant suspected fraudulent activities and notify management of the results",
      weight: "0",
    },
    {
      Pillars: "QRC",
      Activity: "0% system deficiencies, without effective remedial action.",
      weight: "0",
    },
    {
      Pillars: "Leadership /Employee Excellence & BDO Relationships",
      Activity: ">95% Hierarchical development of staff in the service line",
      weight: "0",
    },
    {
      Pillars: "Leadership /Employee Excellence & BDO Relationships",
      Activity: ">95% Hierarchical development of staff in the service line",
      weight: "0",
    },
    {
      Pillars: "Operational Excellence",
      Activity: ">95% achievement of measurable targets on employee scorecard",
      weight: "0",
    },
    {
      Pillars: "Market Focus/ Client Centricity",
      Activity: ">90% client satisfaction survey / feedback",
      weight: "0",
    },
    {
      Pillars: "Growth & Profitability",
      Activity: "% of growth in clients / No. of new clients",
      weight: "0",
    },
    {
      Pillars: "Total",
      Activity: "",
      weight: "0",
    },
  ];
  const tempdata5 = [
    {
      GAFBDOEAValues: "Integrity",
      Description:
        "Demonstrates hight emotional quotient and leadership traits. Demonstrates knowledge of policies and practices well. Experiences very little difficulty in acquiring/learning knowledge/information. Keeps abreast of work related developments. Demonstrates ability to apply technical/professional knowledge and skills to immediate work situation. Low level of supervision and guidance necessary. Ensures that the limits to authority are respected at all times. Demonstrates and embodies the BDO values and culture through their interactions, efforts and behaviour. Should have a lot of Social Capital and must adhere to ESG and other ethical behaviours",
      weight: "0",
    },
    {
      GAFBDOEAValues: "Effective communication",
      Description:
        "Expresses facts and ideas in a clear and logical manner, without excessive guidance.",
      weight: "0",
    },
    {
      GAFBDOEAValues: "Responsibility",
      Description:
        "Constantly accepts responsibility in a competent manner for own areas of work and those of subordinates. Can be relied upon to accept responsibility in respect of other employees in their absence when requested to do so. Normal level of supervision and counselling required. Works out own programmes/approaches to overcome problems and competently performs to expectations where general principles are not adequate to determine procedure or decisions to be taken.",
      weight: "0",
    },
    {
      GAFBDOEAValues: "Reliability",
      Description:
        "Seldom needs to enquire about progress of a task. Shows commitment to work. Will readily accept changes in work, work patterns or procedures, work location etc. in order to help achieve objectives.",
      weight: "0",
    },
    {
      GAFBDOEAValues: "Teamwork",
      Description:
        "Extremely positive and inspiring personality. Co-operates well with supervisors, colleagues and those supervised. Is polite and respectful of others. Demonstrates a sound and healthy attitude when interacting with others. Acceptable and gets on well with colleagues. Able to influence and communicate well as part of a team. Works to achieve team objectives and coaches/ mentors reportees. Requires negligible level of supervision and guidance.",
      weight: "0",
    },
  ];

  const IndividualDynamicAttributesData = [
    {
      gaf: "Integrity",
      Description:
        "Demonstrates hight emotional quotient and leadership traits. Demonstrates knowledge of policies and practices well. Experiences very little difficulty in acquiring/learning knowledge/information. Keeps abreast of work related developments. Demonstrates ability to apply technical/professional knowledge and skills to immediate work situation. Low level of supervision and guidance necessary. Ensures that the limits to authority are respected at all times. Demonstrates and embodies the BDO values and culture through their interactions, efforts and behaviour. Should have a lot of Social Capital and must adhere to ESG and other ethical behaviours",
      weight: "20%",
    },
    {
      gaf: "Effective communication",
      Description:
        "Expresses facts and ideas in a clear and logical manner, without excessive guidance.",
      weight: "20%",
    },
    {
      gaf: "Responsibility",
      Description:
        "Constantly accepts responsibility in a competent manner for own areas of work and those of subordinates. Can be relied upon to accept responsibility in respect of other employees in their absence when requested to do so. Normal level of supervision and counselling required. Works out own programmes/approaches to overcome problems and competently performs to expectations where general principles are not adequate to determine procedure or decisions to be taken.",
      weight: "20%",
    },
    {
      gaf: "Reliability",
      Description:
        "Seldom needs to enquire about progress of a task. Shows commitment to work. Will readily accept changes in work, work patterns or procedures, work location etc. in order to help achieve objectives.",
      weight: "20%",
    },
    {
      gaf: "Teamwork",
      Description:
        "Extremely positive and inspiring personality. Co-operates well with supervisors, colleagues and those supervised. Is polite and respectful of others. Demonstrates a sound and healthy attitude when interacting with others. Acceptable and gets on well with colleagues. Able to influence and communicate well as part of a team. Works to achieve team objectives and coaches/ mentors reportees. Requires negligible level of supervision and guidance.",
      weight: "20%",
    },
  ];

  const IndividualDynamicAttributescolumns = [
    {
      title: "GAF / BDO EA Values",
      dataIndex: "gaf",
      key: "gaf",
      width: "10%",
      className: "gaf-column-header",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      width: "80%",
      className: "normal-column",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "18%",
      className: "normal-column",
    },
  ];

  const handleFormChange = (e) => {
    //    yet to code
  };

  const handleSubmitForm = (e) => {
    //    yet to code
  };
  return (
    <>
      <StyledWrap>
        <div className="main_para">
          This Performance Contract documents the job performance expectations
          for an employee in the below-mentioned Job Title and Department in BDO
          East Africa. The Key Performance Indicators (KPIs) for each employee
          and the respective Key Results Areas (KRAs) are categorised according
          to Five Pillars of Excellence. These aim to illustrate an employee’s
          individual contributions to the achievement of their
          Competency/Quality Centre’s targets and the firm’s overall strategic
          objectives. Performance will be monitored against the agreed KPIs
          throughout the year and assessed during the Performance Appraisal
          periods as determined in the Performance Management Policy.
        </div>
        <div className="form_div">
          <GridBuilder
            formStructureJson={AcceptPerfContractForm}
            //   handleChange={handleFormChange}
            //   formSubmit={handleSubmitForm}
            initialValues={data}
          />
        </div>
        <Row gutter={16} className="mt-3">
          <Col sm={24} md={12}>
            <h6>Assessment Model:</h6>
            <ReusableTable
              data={AcceptPerformData}
              columns={AcceptPerformDatacolumns}
            />
          </Col>

          <Col sm={24} md={12}>
            <h6>Technical Assessment</h6>
            <ReusableTable
              data={tempdata2}
              columns={AcceptPerformDatacolumns}
            />
          </Col>
        </Row>
        <GridBuilder formStructureJson={BasicForm} />
        <h6>Technical Assessment – Chargeable Engagements Aggregate</h6>
        <ReusableTable data={tempdata3} columns={TechnicalAssessmentColumns} />
        <h6>Technical Assessment – Key Responsibilities</h6>
        <ReusableTable data={tempdata4} columns={TechnicalAssessmentColumns1} />
        <u>
          <h6>Individual- Static Attributes (40%)</h6>
        </u>
        <GridBuilder formStructureJson={IndividualForm} />
        <h6>Individual- Dynamic Attributes (50%)</h6>
        <StyledWrap>
          <ReusableTable
            data={IndividualDynamicAttributesData}
            columns={IndividualDynamicAttributescolumns}
          />
        </StyledWrap>
        
        <GridBuilder formStructureJson={BasicForm1} />
      </StyledWrap>
    </>
  );
};

export default AcceptPerformance;
