import TabsList from 'molecules/TabsList';
import React, { useMemo, useState } from 'react'
import styled from 'styled-components';
import Partner from './Partner';
import Vendor from './Vendor';

function ThirdParty({engagementData}) {
    const [tabId, setTabId] = useState("partner");

    const handleTabSelect = (id) => {
      setTabId(id);
    };
  
    const items = useMemo(
      () => [
        {
          id: "partner",
          label: "Partner",
          component: <Partner engagementData={engagementData}/>,
        },
        {
          id: "vendor",
          label: "Vendor",
          component: <Vendor engagementData={engagementData}/>,
        }
      ],
      [engagementData]
    );
  return (
    <AddEngagementWrap>
      <TabsList items={items} handleTabSelect={handleTabSelect} tabId={tabId} />
    </AddEngagementWrap>
  )
}

export default ThirdParty


const AddEngagementWrap = styled.div`
  .fields-card {
    box-shadow: 0px 3px 6px #00000015;
    border-radius: 5px;
  }
  .ant-tabs-tab {
    padding: 5px 0;
  }
  .ant-tabs-nav {
    color: #000000;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav {
    background-color: #f3fefa;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
  .profit {
    color: #4caf50;
    font-weight: 600;
  }
  .loss {
    color: #f44336;
    font-weight: 600;
  }
  .ant-picker {
    width: 100%;
  }
  .form-footer {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }
  .max-char-info {
    font-size: 12px;
    color: #bfbfbf;
    margin-top: -8px;
    text-align: end;
  }
  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
  .ant-upload.ant-upload-select.ant-upload-select-text {
    width: 100%;
  }
  .job-closer-buttons-container {
    margin-left: 70px;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  .job-closer-buttons-container .ant-btn-primary {
    border-radius: 5px;
    font-weight: 500;
  }

  .form-footer .ant-btn-primary {
    border-radius: 5px;
    font-weight: 500;
  }
`;