import { VALIDATE_CHARACTER } from "common/Constants";

export const getGeneralInformationJson = (data) => {
  const GeneralInformationForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Relationship / Liaison Manager:",
              type: "text",
              name: "relationshipliaisonmanager",
              Placeholder: "-",
              viewOnly: true,
              // value: data["coiCheckProcess?.Relationship / Liaison Manager"],
              validationRules: [
                {
                  required: true,
                  message: `This field is required`,
                },
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Relationship / Liaison Manager’s Email:",
              type: "text",
              name: "relationshipliaisonmanageremail",
              viewOnly: true,
              Placeholder: "-",
            },
          ],
          [
            {
              id: 3,
              label: "Relationship / Liaison Manager’s Role:",
              type: "text",
              name: "bdo_relationshipliaisonmanagersrole",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 5,
              label: "Relationship / Liaison Manager’s -ID:",
              type: "text",
              name: "bdo_relationshipliaisonmanagerid",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 6,
              label: "Other Side entity name / Alias name:",
              type: "text",
              name: "bdo_othersideentitynamealiasname",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 7,
              label: "Entity type:",
              type: "text",
              name: "bdo_entitytype",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 7,
              label: "Classification Type",
              type: "text",
              name: "classificationtype",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 8,
              label: "Principle Lawyer:",
              type: "text",
              name: "bdo_principlelawyer",
              Placeholder: "-",
              viewOnly: true,
            }
          ],
          [
            {
              id: 9,
              label: "Secretory:",
              type: "text",
              name: "bdo_secretory:",
              Placeholder: "-",
              viewOnly: true,
            }
          ],
          [
            {
              id: 10,
              label: "Principle Banker:",
              type: "text",
              name: "bdo_principlebanker",
              Placeholder: "-",
              viewOnly: true,
            }
          ],
        ],
      },
    ],
  };

  const GeneralInformationFormTwo = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "BC Client ID:",
              type: "text",
              name: "bdo_bcclientid",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 12,
              label: "Size:",
              type: "text",
              name: "bdo_size",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 2,
              label: "Risk Classification:",
              type: "text",
              name: "bdo_riskclassification",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 3,
              label: "Country of incorporation:",
              type: "text",
              name: "_bdo_countryofincorporation_value",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 4,
              label: "Domicile:",
              type: "text",
              name: "_bdo_domicile_value",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 5,
              label: "Review Cycle Period:",
              type: "text",
              name: "bdo_reviewcycleperiod",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 5,
              label: "Review Due Date",
              type: "text",
              name: "bdo_reviewduedate",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 6,
              label: "Inactivation Reason:",
              type: "text",
              name: "bdo_inactivationreason",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 7,
              label: "Public Interest Entity:",
              type: "text",
              name: "bdo_publicinterestentity",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 8,
              label: "Type of PIE:",
              type: "text",
              name: "bdo_typeofpie",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 9,
              label: "Regulator:",
              type: "text",
              name: "bdo_regulator",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 10,
              label: "Status:",
              type: "text",
              name: "bdo_status",
              Placeholder: "-",
              viewOnly: true,
            },
          ],          
        ],
      },
    ],
  };

  const GeneralInformationFormThree = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Last Date Included in Campaign:",
              type: "text",
              name: "marketingonly",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 2,
              label: "Send Marketing Materials:",
              type: "text",
              name: "sendmarketingmaterials",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
        ],
      },
    ],
  };
  const GeneralInformationFormFour = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Business Relationship:",
              type: "text",
              name: "bdo_businessrelationship",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 2,
              label: "Strategic Partner:",
              type: "text",
              name: "bdo_strategicpartner",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 3,
              label: "BR_FLAG_VALUE:",
              type: "text",
              name: "bdo_br_flag_value",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 4,
              label: "Reference # optional:",
              type: "text",
              name: "bdo_referenceoptional",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 5,
              label: "Business Designation Type:",
              type: "text",
              name: "bdo_businessdesignationtype",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 6,
              label: "Designation Template:",
              type: "text",
              name: "bdo_designationtemplate",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 7,
              label: "Designation Level / LOCATION_HIERARCHY:",
              type: "text",
              name: "bdo_designationlevellocation_hierarchy",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 8,
              label: "Created date:",
              type: "text",
              name: "bdo_createddate",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 9,
              label: "Removal / Archive date:",
              type: "text",
              name: "bdo_removalarchivedate",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 10,
              label: "Removal / Archive Reason:",
              type: "text",
              name: "bdo_removalarchivereason",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
        ],
      },
    ],
  };

  const GeneralInformationFormFive = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Contact Method:",
              type: "text",
              name: "contactmethod",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 2,
              label: "Email:",
              type: "text",
              name: "email",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 3,
              label: "Bulk Email:",
              type: "text",
              name: "bulkemail",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 4,
              label: "Follow Email:",
              type: "text",
              name: "followemail",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 4,
              label: "Phone:",
              type: "text",
              name: "phone",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
          [
            {
              id: 5,
              label: "Mail:",
              type: "text",
              name: "mail:",
              Placeholder: "-",
              viewOnly: true,
            },
          ],
        ],
      },
    ],
  };

  return {
    GeneralInformationForm,
    GeneralInformationFormTwo,
    GeneralInformationFormThree,
    GeneralInformationFormFour,
    GeneralInformationFormFive,
  };
};
