import { Button, Card, Form, Input, Radio, notification } from "antd";
import Title from "atoms/Title";
import COLORS from "common/Colors";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { useMutate } from "hooks/useMutate";
import { useQueryState } from "hooks/useQueryState";
import React, { useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSelector } from "react-redux";
import { getUserList, getFilteredClientData } from "redux/selectors";

const labelStyle = {
  width: "80px",
  display: "inline-block",
  textAlign: "start",
};

const ClientSurvey = () => {
  const { id: engagementId } = useParams();
  const [formStep, setFormStep] = React.useState(1);
  const [surveryId, setSurveyId] = React.useState(null);
  const userList = useSelector((state) => getUserList(state));
  
  const { getEngagementById, addClientSurvey: addClientSurveyQuery } =
    queryKeys;

  const { key, url } = getEngagementById(engagementId);

  const { key: addClientSurveyKey, url: addClientSurveyUrl } =
    addClientSurveyQuery(engagementId);

  const { isFetched } = useQueryState(key);

  const { data: engagementDataRes } = useFetch(key, url, {
    enabled: !isFetched,
  });

  const engagementData = engagementDataRes?.data;

  const { mutate: addClientSurvey } = useMutate(
    addClientSurveyKey,
    addClientSurveyUrl
  );

  const handleClientSurveyFirstForm = (values) => {
    console.log(values);

    const data = {
      surveyData: [
        {
          question: "1. Were the service timely?",
          answer: values.service_timely,
        },
        {
          question: "2. Were the staff responsive and knowledgeable?",
          answer: values.staff_responsive,
        },
        {
          question: "3. Did you get value for money from the service?",
          answer: values.value_for_money,
        },
        {
          question:
            "4. Would you continue using BDO and recommend them to a friend or colleague?",
          answer: values.recommend,
        },
        {
          question:
            "5. Was the methodology, technology and analysis used of high quality?",
          answer: values.quality,
        },
        {
          question: "6. Comment",
          answer: values.comment ?? "",
        },
      ],
    };

    addClientSurvey(data, {
      onSuccess: (res) => {
        notification.success({
          message: "Client Survey added successfully",
        });
        setSurveyId(res.surveyId);
        setFormStep(2);
      },
    });
  };
  const cursorStyle = { cursor: "pointer" }
  const history = useHistory();

  const breadStyle = { color: "#636980", cursor: "pointer" };
  const breadcrumbs = [
    <label key="2" style={breadStyle} onClick={() => history.goBack()}>
      {engagementData?.information?.jobTitle}
    </label>,
    <Typography key="3" color="text.primary">
      Client Satisfaction Survey 
    </Typography>,
  ]

  return (
    <ClientSurveyWrap>
      <div className="title-row">
      <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      
        <div className="client">
          Client: {engagementData?.client?.clientId || "-"}
        </div>
      </div>

      {/* {formStep === 1 && (
        <Form layout="vertical" onFinish={handleClientSurveyFirstForm}>
          <Card bordered={false}>
            <Form.Item
              label="1. Were the service timely?"
              name="service_timely"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Radio.Group optionType="button" buttonStyle="solid">
                <Radio value={"yes"}>Yes</Radio>
                <Radio value={"no"}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="2. Were the staff responsive and knowledgeable?"
              name="staff_responsive"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Radio.Group optionType="button" buttonStyle="solid">
                <Radio value={"yes"}>Yes</Radio>
                <Radio value={"no"}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="3. Did you get value for money from the service?"
              name="value_for_money"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Radio.Group optionType="button" buttonStyle="solid">
                <Radio value={"yes"}>Yes</Radio>
                <Radio value={"no"}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="4. Would you continue using BDO and recommend them to a friend or colleague?"
              name="recommend"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Radio.Group optionType="button" buttonStyle="solid">
                <Radio value={"yes"}>Yes</Radio>
                <Radio value={"no"}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="5. Was the methodology, technology and analysis used of high quality?"
              name="quality"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Radio.Group optionType="button" buttonStyle="solid">
                <Radio value={"yes"}>Yes</Radio>
                <Radio value={"no"}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="6. Comment"
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Input.TextArea
                rows={3}
                style={{
                  resize: "none",
                }}
              />
            </Form.Item>
          </Card>

          <div className="form-buttons">
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </div>
        </Form>
      )} */}

      {formStep === 1 && (
        <ClientSurveyEmailForm
          engagementId={engagementId}
          engagementData={engagementData}
          userList={userList}
        />
      )}
    </ClientSurveyWrap>
  );
};

export default ClientSurvey;

const ClientSurveyEmailForm = ({ engagementId, engagementData, userList }) => {
  const history = useHistory();
  const filteredClientList = useSelector((state) => getFilteredClientData(state));
  const { addClientSurveyEmail: addClientSurveyEmailQuery } = queryKeys;
  const { key: addClientSurveyEmailKey, url: addClientSurveyEmailUrl } =
    addClientSurveyEmailQuery(engagementId);

  const { mutate: addClientSurveyEmail, isLoading } = useMutate(
    addClientSurveyEmailKey,
    addClientSurveyEmailUrl
  );
  
  const emailaddress = filteredClientList?.data[0]?.emailaddress1 !== null ? filteredClientList?.data[0]?.emailaddress1 :
  filteredClientList?.data[0]?.emailaddress2 !== null ? filteredClientList?.data[0]?.emailaddress2 :
  filteredClientList?.data[0]?.emailaddress3 !== null ? filteredClientList?.data[0]?.emailaddress3 : "";

  const findUser = (id) => {
    return userList?.find((user) => user?.userid === id);
  };

  const assignedByPartner = findUser(engagementData?.stakeHolder?.partner);
  const clientsurveylink = `https://www.test.clientsurvey.bdo.insyts.co`;
  const managingpartnerName = assignedByPartner
  ? assignedByPartner?.firstname + " " + assignedByPartner?.lastname
  : null

  const initialValues = {
    title: engagementData?.information?.jobTitle,
    subject: "Client Survey",
    to: emailaddress,
    cc: "wongmc@teamsynthesis.com;managingpartner@bdo-ea.com",
    body: `Dear Client.\n
BDO is committed to high quality services to its clients.
In that regard, I would request you to give me your feedback on how my team fared on the recently completed engagement - ${engagementData?.information?.jobTitle}.\n
We request you to allocate 5 minutes to fill out this short client survey form using this link: "${clientsurveylink}".
Thank you in advance for your support.`,
    footer: `Regards,
${managingpartnerName},
Managing Partner.`
  }

  const handleSubmit = (values) => {

    const data = {
      mailData: {
        title: values.title,
        subject: values.subject,
        toMail: values.to,
        ccMail: values.cc,
        body: values.body,
        footer: values.footer,
        clientsurveylink: clientsurveylink,
        managingpartnerName: managingpartnerName
      },
    };

    addClientSurveyEmail(data, {
      onSuccess: () => {
        notification.success({
          message: "Email sent successfully",
        });
        history.push(`/engagement/${engagementId}`);
      },
    });
  };

  return (
    <Form onFinish={handleSubmit} layout="horizontal" initialValues={initialValues} colon={false}>
      <Card bordered={false}>
        <Form.Item label={<span style={labelStyle}>Title:</span>} name="title">
          <Input placeholder="Enter" disabled/>
        </Form.Item>

        <Form.Item
          label={<span style={labelStyle}>Subject:</span>}
          name="subject"
        >
          <Input placeholder="Enter" disabled/>
        </Form.Item>

        <Form.Item label={<span style={labelStyle}>To:</span>} name="to">
          <Input placeholder="Enter" />
        </Form.Item>

        <Form.Item label={<span style={labelStyle}>CC:</span>} name="cc">
          <Input placeholder="Enter" />
        </Form.Item>

        <Form.Item label={<span style={labelStyle}>Body:</span>} name="body">
          <Input.TextArea
            rows={3}
            style={{
              resize: "none",
            }}
          />
        </Form.Item>

        <Form.Item
          label={<span style={labelStyle}>Footer:</span>}
          name="footer"
        >
          <Input.TextArea
            rows={3}
            style={{
              resize: "none",
            }}
          />
        </Form.Item>
      </Card>

      <div className="form-buttons">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Send Mail
        </Button>
        <Button
          type="primary"
          onClick={() => history.push(`/engagement/${engagementId}`)}
        >
          Close
        </Button>
      </div>
    </Form>
  );
};

const ClientSurveyWrap = styled.div`
  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .client {
      font-size: 16px;
      color: #1b1b1b;
      font-weight: 600;
    }
  }
  label {
    color: #1b1b1b;
  }
  .form-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .ant-card-body {
    box-shadow: 0px 3px 6px #00000015;
  }
  .ant-radio-group {
    display: flex;
    align-items: center;
    gap: 20px;

    label.ant-radio-button-wrapper {
      width: 100px;
      text-align: center;
      background-color: #f7f7f8;
      &:hover {
        box-shadow: #00000015 0px 3px 6px;
      }
      .ant-radio-button-checked {
        background-color: ${COLORS.PRIMARY};
        color: black !important;
      }
      color: black !important;
      border: none;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }
  }
`;
