import { Input, Modal, notification } from "antd";
import queryKeys from "common/queryKey";
import { useQueryState } from "hooks/useQueryState";
import { useUpdate } from "hooks/useUpdate";
import moment from "moment";
import React from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getUserInfo } from "redux/selectors";

const DeclineTimesheetModal = ({ open, setOpen, index }) => {
  const { updateTimesheet, getTimesheet } = queryKeys;
  const [reason, setReason] = React.useState("");
  const queryClient = useQueryClient();

  const { week } = useParams();

  const { data: timesheetDataRes } = useQueryState(
    `${getTimesheet.key}-week:${week}`
  );

  const timesheetData = timesheetDataRes?.response?.response || {};

  const userInfo = useSelector((state) => getUserInfo(state));

  const { mutate: update, isLoading: isUpdating } = useUpdate(
    updateTimesheet.key,
    updateTimesheet.url
  );

  const handleOk = () => {
    if (!reason) {
      notification.error({
        message: "Error",
        description: "Please Enter the Reason",
      });
      return;
    }
    update(
      {
        ...timesheetData,
        timeSheet: timesheetData.timeSheet.map((ts, idx) => {
          if (idx === index) {
            return {
              ...ts,
              status: "declined",
              declined: {
                by: userInfo.employer_id,
                date: moment().format("DD-MM-YYYY"),
                reason,
              },
            };
          }
          return ts;
        }),
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(`${getTimesheet.key}-week:${week}`);
          notification.success({
            message: "Timesheet Declined Successfully",
          });
          setOpen(false);
        },
      }
    );
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        title="Reason for Decline"
        open={open}
        onOk={handleOk}
        okText="Decline"
        confirmLoading={isUpdating}
        onCancel={handleCancel}
      >
        <Input
          name="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Please Enter the Reason"
        />
      </Modal>
    </>
  );
};

export default DeclineTimesheetModal;
