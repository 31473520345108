import { Button, Col, Row, Table } from "antd";
import COLORS from "common/Colors";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReusableTable from "./ReusableTable";
import GridBuilder from "UIBuilderEngine/GridBuilder";
// import { ButtonFilled } from "reusableComponent/UIButtons";
// import KeyResponsibilities from "./KeyResponsibilities";

const StyledBox = styled.div`
  box-shadow: 0px 3px 6px #00000015;
  border-radius: 5px;

  .gaf-column-header{
    align-content: baseline;
  }
`;

const ButtonKeySelect = styled.div`
  margin: 10px;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  max-height: 50px;
  background: ${COLORS.PRIMARY};
  font-weight: 600;
  color: #000000;
  white-space: nowrap;

  &:hover {
    background: ${COLORS.PRIMARY};
    color: #000000;
  }
  &:focus {
    background: ${COLORS.PRIMARY};
    color: #000000;
  }
`;

function CreateperformanceContract({open, setOpen, keyresp, setKeyResp}) {
  const handleShowComponent = () => {
    setKeyResp(true);
  };

  const handleClickSubmit = () => {
    setOpen(false);
    setKeyResp(false);
  }

  const handleClickClose = () => {
    setOpen(false);
    setKeyResp(false);
  }
  
  const tempdata = [
    {
      category: "Technical",
      percent: "0",
    },
    {
      category: "Individual",
      percent: "0",
    },
    {
      category: "TOTAL",
      percent: "0",
    },
  ];

  const TechnicalAssessment = [
    {
      category: "On the Job",
      percent: "0",
    },
    {
      category: "Of the Job",
      percent: "0",
    },
    {
      category: "TOTAL",
      percent: "0",
    },
  ];

  const TechnicalAssessmentData = [
    {
      Description:
        <><h6 className="mb-0 pb-0">Value & Efficiency:</h6><p> Knowledgeable and well prepared to handle the allocated work. The methodology and software used was appropriate. Conclusions reached were useful, realistic and adequately supported by relevant facts and thorough analysis.</p></>,
      weight: "0",
    },
    {
      Description:
        <><h6>Timely Delivery:</h6> <p>The assignment / tasks / sections were completed on a timely basis. The timeliness of response to phone calls/e-mails showed care and good communication skills. No undue disruption to workplace during the assignment.</p></>,
      weight: "0",
    },
    {
      Description:
        <><h6>Quality, Compliance & Risk:</h6> <p>Quality and Risks were properly managed and with compliance to methodology, tools and processes was as per expectation. Also, safeguarding of documents and work environment and staff commitments were respected.</p></>,
      weight: "0",
    },
    {
      Description:
        <><h6>Client satisfaction:</h6> <p>Demonstrated professionalism and the handling of client, staff and supervision of the job was impeccable. Both internal and external stakeholders were satisfied with the delivery.</p></>,
      weight: "0",
    },
    { Description: <h6>Client Survey Score</h6>, weight: "0" },
    {
      Description: <h6>TOTAL</h6>,
      weight: "0",
    },
  ];

  const TechnicalAssessmentColumns = [
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      width: "90%",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "10%",
      className: "normal-column",
    },
  ];

  const IndividualDynamicAttributesData = [
    {
      gaf: "Integrity",
      Description:
      <>Demonstrates hight emotional quotient and leadership traits.<br></br> Demonstrates knowledge of policies and practices well.<br></br> Experiences very little difficulty in acquiring/learning knowledge/information.<br></br> Keeps abreast of work related developments.<br></br> Demonstrates ability to apply technical/professional knowledge and skills to immediate work situation.<br></br> Low level of supervision and guidance necessary.<br></br> Ensures that the limits to authority are respected at all times.<br></br> Demonstrates and embodies the BDO values and culture through their interactions, efforts and behaviour.<br></br> Should have a lot of Social Capital and must adhere to ESG and other ethical behaviours</>,
      Weight: "0",
    },
    {
      gaf: "Effective communication",
      Description:
        "Expresses facts and ideas in a clear and logical manner, without excessive guidance.",
      Weight: "0",
    },
    {
      gaf: "Responsibility",
      Description:
      <>Constantly accepts responsibility in a competent manner for own areas of work and those of subordinates.<br></br> Can be relied upon to accept responsibility in respect of other employees in their absence when requested to do so.<br></br> Normal level of supervision and counselling required.<br></br> Works out own programmes/approaches to overcome problems and competently performs to expectations where general principles are not adequate to determine procedure or decisions to be taken</>,
      Weight: "0",
    },
    {
      gaf: "Reliability",
      Description:
      <>Seldom needs to enquire about progress of a task.<br></br> Shows commitment to work.<br></br> Will readily accept changes in work, work patterns or procedures, work location etc. in order to help achieve objectives.</>,
      Weight: "0",
    },
    {
      gaf: "Teamwork",
      Description:
      <>Extremely positive and inspiring personality.<br></br> Co-operates well with supervisors, colleagues and those supervised.<br></br> Is polite and respectful of others.<br></br> Demonstrates a sound and healthy attitude when interacting with others.<br></br> Acceptable and gets on well with colleagues.<br></br> Able to influence and communicate well as part of a team.<br></br> Works to achieve team objectives and coaches/ mentors reportees.<br></br> Requires negligible level of supervision and guidance.</>,
      Weight: "0",
    },
  ];

  const IndividualDynamicAttributescolumns = [
    {
      title: "GAF / BDO EA Values",
      dataIndex: "gaf",
      key: "gaf",
      width: "10%",
      className: "gaf-column-header",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      width: "80%",
      className: "normal-column",
    },
    {
      title: "Weight",
      dataIndex: "Weight",
      key: "Weight",
      width: "10%",
      className: "normal-column",
    },
  ];

  const columns = [
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
      width: "70%",
    },
    {
      title: "%",
      dataIndex: "percent",
      key: "percent",
      width: "30%",
      className: "normal-column",
    },
  ];

  const tempcolumns = [
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
      width: "70%",
    },
    {
      title: "%",
      dataIndex: "percent",
      key: "percent",
      width: "30%",
      className: "normal-column",
    },
  ];

  const IndividualForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "No of years with the Firm:",
              type: "text",
              name: "noofyears",
              Placeholder: "",
              //   value: data["rolename"],
            },
            {
              id: 2,
              label: "Years of Experience:",
              type: "text",
              name: "yearsofexperience",
              Placeholder: "",
            },
          ],
          [
            {
              id: 3,
              label: "Qualifications Category:",
              type: "text",
              name: "1ualificationscategory",
              Placeholder: "",
            },
            {
              id: 2,
              label: "Age:",
              type: "text",
              name: "age",
              Placeholder: "",
            },
          ],
        ],
      },
    ],
  };

  const BasicForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Reviewer:",
              type: "text",
              name: "reviewer",
              Placeholder: "Enter",
              //   value: data["rolename"],
            },
            {
              id: 2,
              label: "Chargeout Rate:",
              type: "text",
              name: "chargeoutrate",
              Placeholder: "Enter",
            },
          ],
        ],
      },
    ],
  };

  const DynamicAttributesForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Individual- Wow Factor(10%):",
              type: "text",
              name: "individualwowfactor",
              Placeholder: "",
              //   value: data["rolename"],
            },
          ],
          [
            {
              id: 2,
              label: "Self Overall Comment:",
              type: "text",
              name: "selfoverallcomment",
              Placeholder: "",
            },
          ],
          [
            {
              id: 3,
              label: "Cancel",
              type: "buttongroup",
              position: "start",
              textColor: "white",
              event: "back",
              buttons: [
                {
                  label: "Save & Submit",
                  disabled: false,
                  loading: false,
                  btnStyle: {
                    /* Button 1 style object */
                  },
                  onClick: () => {
                    handleClickSubmit()
                    // Handle Button 1 click
                  },
                },
                {
                  label: "Cancel",
                  disabled: false,
                  loading: false,
                  btnStyle: {
                    /* Button 2 style object */
                  },
                  onClick: () => {
                    // Handle Button 2 click
                    handleClickClose()
                  },
                },
                // Add more buttons as needed
              ],
            },
          ],
        ],
      },
    ],
  };
  const AcceptPerfContractForm = {
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Name:",
              type: "select",
              name: "name",
              Placeholder: "select",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 2,
              label: "Staff Level:",
              type: "select",
              name: "staffLevel",
              Placeholder: "select",
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 3,
              label: "Firm:",
              type: "select",
              name: "firm",
              Placeholder: "select",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 4,
              label: "Organisational Level:",
              type: "select",
              name: "organisationallevel",
              Placeholder: "select",
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 5,
              label: "Year:",
              type: "select",
              name: "year",
              Placeholder: "select",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 6,
              label: "Competency/Quality Circle:",
              type: "select",
              name: "competency/Qualitycircle",
              Placeholder: "select",
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 7,
              label: "Period:",
              type: "select",
              name: "period",
              Placeholder: "select",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 8,
              label: "Additional:",
              type: "select",
              name: "additional",
              Placeholder: "select",
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 9,
              label: "Department:",
              type: "select",
              name: "department",
              Placeholder: "select",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 10,
              label: "Service Line Expertise:",
              type: "text",
              name: "serviceLineExpertise",
              Placeholder: "Enter",
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 11,
              label: "Industry Expertise:",
              type: "text",
              name: "industryExpertise",
              Placeholder: "Enter",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 12,
              label: "Grade:",
              type: "select",
              name: "grade",
              Placeholder: "select",
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 13,
              label: "Hired Date:",
              type: "date",
              name: "hiredDate",
              Placeholder: "Select",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 14,
              label: "Years with Firm:",
              type: "text",
              name: "yearswithFirm",
              Placeholder: "Enter",
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 15,
              label: "Graduation Status:",
              type: "text",
              name: "graduationStatus",
              Placeholder: "Enter",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 16,
              label: "Professional Certification:",
              type: "select",
              name: "professionalCertification",
              Placeholder: "Select",
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 17,
              label: "L&D Completed:",
              type: "text",
              name: "l&dcompleted",
              Placeholder: "Enter",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 18,
              label: "Technical Skills Expertise:",
              type: "text",
              name: "technicalSkillsExpertise",
              Placeholder: "Enter",
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 19,
              label: "Last Promotion Date:",
              type: "date",
              name: "lastPromotionDate",
              Placeholder: "Select",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 20,
              label: "Last Salary Increase Date:",
              type: "date",
              name: "lastSalaryIncreaseDate",
              Placeholder: "Select",
              maxLength: 35,
              validationRules: [],
            },
          ],
          [
            {
              id: 21,
              label: "Target Chargeability %:",
              type: "text",
              name: "targetChargeability",
              Placeholder: "Enter",
              maxLength: 35,
              validationRules: [],
            },
            {
              id: 22,
              label: "Chargeout Rate:",
              type: "text",
              name: "chargeoutRate",
              Placeholder: "Enter",
              maxLength: 35,
              validationRules: [],
            },
          ],
        ],
      },
    ],
  };

  return (
    <>
        <div>
          <p>
            This Performance Contract documents the job performance expectations
            for an employee in the below-mentioned Job Title and Department in
            BDO East Africa. The Key Performance Indicators (KPIs) for each
            employee and the respective Key Results Areas (KRAs) are categorised
            according to Five Pillars of Excellence. These aim to illustrate an
            employee’s individual contributions to the achievement of their
            Competency/Quality Centre’s targets and the firm’s overall strategic
            objectives. Performance will be monitored against the agreed KPIs
            throughout the year and assessed during the Performance Appraisal
            periods as determined in the Performance Management Policy.
          </p>

          <StyledBox className="px-3 p-1 mb-3">
            <GridBuilder formStructureJson={AcceptPerfContractForm} />
            
          </StyledBox>

          <Row gutter={16}>
            <Col sm={24} md={12}>
              <h6>Assessment Model:</h6>
              <ReusableTable data={tempdata} columns={columns} />
            </Col>

            <Col sm={24} md={12}>
              <h6>Technical Assessment:</h6>
              <ReusableTable data={TechnicalAssessment} columns={tempcolumns} />
            </Col>
          </Row>

          <GridBuilder formStructureJson={BasicForm} />
          <h6>Technical Assessment – Chargeable Engagements Aggregate</h6>
          <ReusableTable
            data={TechnicalAssessmentData}
            columns={TechnicalAssessmentColumns}
          />
          <h6>Technical Assessment – Key Responsibilities</h6>
          <ButtonKeySelect
            className="w-100 px-0 mx-0"
            onClick={handleShowComponent}
          >
            Select Key Responsibilities
          </ButtonKeySelect>
          <u>
            <h6>Individual- Static Attributes (40%)</h6>
          </u>
          <GridBuilder formStructureJson={IndividualForm} />
          <h6>Individual- Dynamic Attributes (50%)</h6>
          <StyledBox>
            <ReusableTable
              data={IndividualDynamicAttributesData}
              columns={IndividualDynamicAttributescolumns}
            />
          </StyledBox>
          <GridBuilder formStructureJson={DynamicAttributesForm} />
        </div>
    </>
  );
}

export default CreateperformanceContract;
