import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Modal,
  message,
  Switch,
  Checkbox,
  Select,
  Form,
} from "antd";
import Button from "atoms/Button";
import styled from "@emotion/styled";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setAllLocationList,
  setLocationList,
  setRoleList,
} from "redux/actions";
import Table from "atoms/Table";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getClientid,
  getRoleList,
  getAllDepartmentList,
  getMenuList,
  getUserList,
  getallLocationList,
} from "redux/selectors";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import { CSVLink } from "react-csv";
import EditLocationModal from "organisms/EditLocationModal";
import { filter, includes, isEmpty, reduce } from "lodash";
import Confirm from "../../src/assets/Confirm.svg";
import StepsProgress from "organisms/StepsProgress";
import SelectTable from "molecules/SelectTable";
import BasicInformation from "molecules/BasicInformation";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormModal from "organisms/FormModal";
import InReview from "molecules/InReview";
import moment from "moment";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
const { Option } = Select;

const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 15px;
    padding-top: 3%;
  }
  .ant-modal-footer {
    display: none;
  }
`;

function Location({
  ClientId,
  saveLocationList,
  roleList,
  departmentList,
  menuList,
  userRoleId,
  saveRoleList,
  userList,
  saveAllLocationList,
  allLocationList,
}) {
  const [addingLocation, toggleAddingLocation] = useState(false);
  const [editingLocation, toggleEditingLocation] = useState(false);
  const [deactivatingLocation, toggleDeactivatingLocation] = useState(false);
  const [confirmdeactivatingLocation, toggleconfirmDeactivatingLocation] =
    useState(false);
  const [LocationDetails, setLocationDetails] = useState({});
  const [locationList, setLocationList] = useState(allLocationList);
  const [displayLocationLoader, setDisplayLocationLoader] = useState(false);
  const [CSVLocationList, setCSVLocationList] = useState([]);
  const csvLinkEl = useRef();
  const [showLoader, toggleLoader] = useState(false);
  const [locateID, setLocateID] = useState();

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const CSVHeaders = [
    { label: "Country", key: "country" },
    { label: "Location name", key: "location_name" },
    { label: "No of employee", key: "no_of_employee" },
    { label: "No of Department", key: "no_of_department" },
    { label: "No of Role", key: "no_of_roles" },
    { label: "Created On", key: "Created On" },
  ];

  const columns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: "10%",
    },
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "No of Employee",
      dataIndex: "user_id",
      key: "user_id",
      width: "10%",
      render: (user_id) => {
        if (user_id) {
          const filteredUsers = filter(
            userList,
            (user) => includes(user_id, user?.userid) && user?.statusid === "1"
          );
          return filteredUsers?.length;
        }
      },
    },
    {
      title: "No of Department",
      dataIndex: "department_id",
      key: "department_id",
      width: "15%",
      render: (department_id) => {
        if (department_id) {
          return department_id?.length;
        }
      },
    },

    {
      title: "No of Role",
      dataIndex: "role_id",
      key: "role_id",
      width: "15%",
      render: (role_id) => {
        if (role_id) {
          return role_id?.length;
        }
      },
    },
    // {
    //   title: "Created On",
    //   dataIndex: "insertedat",
    //   key: "insertedat",
    //   width: "15%",
    //   render: (insertedat) => {
    //     return moment(insertedat).format("DD-MM-yyyy");
    //   },
    // },
  ];

  const addLocation = () => {
    toggleAddingLocation(true);
  };

  const deletedLocation = () => {
    toggleDeactivatingLocation(true);
  };

  useEffect(() => {
    getLocationList();
  }, [setLocationList]);

  useEffect(() => {
    setLocationList(allLocationList);
  }, [allLocationList]);

  const getLocationList = async () => {
    try {
      setDisplayLocationLoader(true);
      const { response } = await CALL_API(
        `owner-location/${ClientId}`,
        "get",
        {}
      );
      if (!isEmpty(response)) {
        saveAllLocationList(response);
      }
      setDisplayLocationLoader(false);
    } catch (error) {
      console.log("[getLocationList], Error-->", error);
    }
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, locations, roleList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      saveLocationList(locations);
      saveRoleList(roleList);
      getLocationList();
    }
  };

  const deletelocation = async (location_id) => {
    toggleLoader(true);
    const { code } = await CALL_API(
      `owner-location/${ClientId}/${location_id}`,
      "delete",
      {
        location_id: location_id,
        status_id: 0,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      fetchMasterData(true);
      toggleLoader(false);
      toggleDeactivatingLocation(false);
      toggleconfirmDeactivatingLocation(true);
      toggleEditingLocation(false);
      getLocationList();
    }
  };

  const prepareCSV = async () => {
    if (getFilteredLocationList().length > 0) {
      setCSVLocationList(
        reduce(
          getFilteredLocationList(),
          function (result, list) {
            const filteredUsers = filter(
              userList,
              (user) =>
                includes(list?.user_id, user?.userid) && user?.statusid === "1"
            );
            result.push({
              Country: list?.country,
              "Location name": list?.location_name,
              "No of Employee": filteredUsers?.length,
              "No of Department": list?.department_id?.length,
              "No of Role": list?.role_id?.length,
              "Created On": moment(list?.insertedAt).format("DD-MM-yyyy"),
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };

  const ViewLocation = (LocationObject, isViewOnly = false) => {
    setLocateID(LocationObject.location_id);
    toggleEditingLocation(true);
    setLocationDetails({
      ...LocationObject,
      isViewOnly,
    });
  };

  const [step, setStep] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [selectDepartmentId, setSelectDepartmentId] = useState([]);
  const [create, setCreate] = useState(false);
  const [values, setValues] = useState("");
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [selectAllDepartmentChecked, setSelectAllDepartmentChecked] =
    useState(false);
  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const getFilteredLocationList = () => {
    const country = form.getFieldValue("country") || [];
    if (country.length === 0) {
      return allLocationList;
    } else {
      return allLocationList.filter((location) =>
        country.includes(location.location_id)
      );
    }
  };

  useEffect(() => {
    setSelectAllDepartmentChecked(
      selectDepartmentId?.length === departmentList?.length
    );
    setSelectAllRoleChecked(selectedRoleId?.length === roleList?.length);
  }, [selectDepartmentId, departmentList, selectedRoleId, roleList]);

  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Role" },
    { id: 3, title: "Department" },
    { id: 3, title: "Review" },
  ];

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        return (
          <Switch
            size="small"
            checked={selectAllRoleChecked || selectedRoleId.includes(roleId)}
            onChange={(checked) => getRoleId(roleId, checked)}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
  };

  const departmentcolumns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id) => {
        return (
          <Switch
            size="small"
            checked={
              selectAllDepartmentChecked ||
              selectDepartmentId.includes(department_id)
            }
            onChange={(checked) => getDepartmentId(department_id, checked)}
          />
        );
      },
    },
  ];

  const getDepartmentId = (department_id, checked) => {
    const newLocationId = checked
      ? [...selectDepartmentId, department_id]
      : selectDepartmentId.filter((item) => item !== department_id);
    setSelectDepartmentId(newLocationId);
  };

  const handleSelectAllDepartment = (e) => {
    const checked = e.target.checked;
    setSelectAllDepartmentChecked(checked);
    const allDepartmentIds = departmentList.map((item) => item.department_id);
    setSelectDepartmentId(checked ? allDepartmentIds : []);
  };

  const onFinish = async () => {
    setLoading(true);
    const { name, location_latitude, location_longitude, country } = values;
    const { code } = await CALL_API(`owner-location/${ClientId}`, "post", {
      department_id: selectDepartmentId,
      latitude: location_latitude,
      longitude: location_longitude,
      location_name: name,
      role_id: selectedRoleId,
      country: country,
      status_id: 1,
    });
    if (code === STATUS_CODE.SUCCESS) {
      fetchMasterData(true);
      message.success("Successfully Added");
      getLocationList();
      setValues({});
      setSelectedRoleId([]);
      setSelectDepartmentId([]);
      setLoading(false);
      setCreate(false);
      setStep(0);
      toggleAddingLocation(false);
    }
  };

  useEffect(() => {
    if (create) {
      onFinish();
    }
  }, [create]);

  const headerstyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  const imgstyle = { marginLeft: "50%", marginBottom: "5%", marginTop: "2%" };

  const handleLocationFilters = () => {};

  return (
    <>
      {!addingLocation && !editingLocation && (
        <Row className="page-title">
          <Col sm={24} md={12} style={headerstyle}>
            List of Locations
          </Col>
          <Col
            sm={24}
            md={12}
            className="d-flex justify-content-end algin-items-center"
          >
            <ButtonOutlined type="link" onClick={prepareCSV} className="m-0">
              Export CSV file
            </ButtonOutlined>
            <CSVLink
              header={CSVHeaders}
              data={CSVLocationList}
              filename={"location-list.csv"}
              asyncOnClick={true}
              ref={csvLinkEl}
            />
            {Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "location" && subItem?.permission.create
                )
            ) && (
              <StyledButton
                type="primary"
                htmlType="submit"
                onClick={() => addLocation(true)}
              >
                +Add Location
              </StyledButton>
            )}
          </Col>
        </Row>
      )}

      {!addingLocation && !editingLocation && (
        <Form
          form={form}
          layout="vertical"
          className="mt-2"
          onValuesChange={handleLocationFilters}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <DropdownCheckBox
                name="country"
                label="Country"
                options={locationList?.map((location) => ({
                  label: location.country,
                  value: location.location_id,
                }))}
                form={form}
              />
            </Col>
          </Row>
        </Form>
      )}

      {!addingLocation && !editingLocation && (
        <Spin spinning={displayLocationLoader && locationList?.length === 0}>
          <Table
            className="pt-0"
            dataSource={getFilteredLocationList()}
            columns={columns}
            rowKey="location_id"
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  ViewLocation(record);
                },
              };
            }}
          />
        </Spin>
      )}
      {addingLocation && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => toggleAddingLocation(false)}
          >
            Location <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className=" pb-0 pt-1">Add Location</b>
          <StepsProgress
            stepdata={stepdata}
            title="Add Location:"
            step={step}
          />
        </>
      )}
      {addingLocation && (
        <div className="styled-card">
          {addingLocation && step === 0 && (
            <BasicInformation
              step={step}
              setStep={setStep}
              setValues={setValues}
              title={"Add Location"}
              values={values}
              alldatas={locationList}
            />
          )}

          {addingLocation && step === 1 && (
            <>
              {roleList.length > 0 && (
                <Checkbox
                  className="mt-3"
                  checked={selectAllRoleChecked}
                  onChange={handleSelectAllRole}
                >
                  Select All
                </Checkbox>
              )}
              <SelectTable
                step={step}
                setStep={setStep}
                columns={rolecolumns}
                tabledata={roleList}
                setOpenModal={() => setOpenRoleModal(true)}
                Name="Add Role"
              />
              <FormModal
                isModalVisible={openRoleModal}
                closeModal={() => setOpenRoleModal(false)}
                Name="Role"
              />
            </>
          )}

          {addingLocation && step === 2 && (
            <>
              {departmentList?.length > 0 && (
                <Checkbox
                  className="mt-3"
                  checked={selectAllDepartmentChecked}
                  onChange={handleSelectAllDepartment}
                >
                  Select All
                </Checkbox>
              )}

              <SelectTable
                step={step}
                setStep={setStep}
                columns={departmentcolumns}
                tabledata={departmentList}
                setOpenModal={() => setOpenDepartmentModal(true)}
                Name="Add Department"
              />
              <FormModal
                isModalVisible={openDepartmentModal}
                closeModal={() => setOpenDepartmentModal(false)}
                Name="Department"
              />
            </>
          )}

          {step === 3 && (
            <InReview
              step={step}
              setStep={setStep}
              BasicInformation={values}
              department={selectDepartmentId}
              role={selectedRoleId}
              create={onFinish}
              loading={loading}
            />
          )}
        </div>
      )}

      {editingLocation && (
        <EditLocationModal
          isModalVisible={editingLocation}
          closeModal={() => toggleEditingLocation(false)}
          getLocationList={getLocationList}
          LocationDetails={LocationDetails}
          deleteLocation={deletedLocation}
          viewOnly={
            Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "location" && subItem?.permission.edit
                )
            )
              ? false
              : true
          }
        />
      )}

      {deactivatingLocation && (
        <StyledModal
          visible={deactivatingLocation}
          width={500}
          onCancel={() => toggleDeactivatingLocation(false)}
          footer={[]}
          centered
        >
          <p className="w-100 text-center">
            <b>Are you sure you want to deactivate this location?</b>
          </p>
          <Row>
            <Col className="page-title-head align-right" span={12}>
              <StyledButton
                type="primary"
                htmlType="submit"
                onClick={() => toggleDeactivatingLocation(false)}
              >
                No
              </StyledButton>
            </Col>
            <Col className="page-title-head" span={12}>
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
                onClick={() => deletelocation(locateID)}
              >
                {showLoader ? "Yes" : "Yes"}
              </StyledButton>
            </Col>
          </Row>
        </StyledModal>
      )}

      {confirmdeactivatingLocation && (
        <StyledModal
          visible={confirmdeactivatingLocation}
          width={500}
          onCancel={() => toggleconfirmDeactivatingLocation(false)}
          footer={[]}
          centered
        >
          <img
            src={Confirm}
            alt="confirm"
            height={35}
            width={35}
            style={imgstyle}
          />
          <div className="text-center">
            <p>
              <b>The location has been deactivated</b>
            </p>
          </div>
        </StyledModal>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
  departmentList: getAllDepartmentList(state),
  menuList: getMenuList(state),
  userList: getUserList(state),
  allLocationList: getallLocationList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveLocationList: setLocationList,
      saveRoleList: setRoleList,
      saveAllLocationList: setAllLocationList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(Location);
