import Declaration from "pages/Declaration/Declaration";
import React from "react";
import { useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getUserList } from "redux/selectors";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function DetailView() {
  const userList = useSelector(getUserList);
  const { id } = useParams();
  const history = useHistory();

  const filterUser = userList?.find((val) => val?.userid === id);


  const breadStyle = { color: "#636980", cursor: "pointer" };
  const breadcrumbs = [
    <label key="2" style={breadStyle} onClick={() => history.goBack()}>
      Back
    </label>,
    <Typography key="3" color="text.primary">
       {filterUser?.fullname} - Annual Independence Declaration
    </Typography>,
  ];

  return (
    <div>
      <div className="d-flex gap-2">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <br/>
      <Declaration filteruser={filterUser} />
    </div>
  );
}

export default DetailView;
