import styled from "@emotion/styled";
import { Col, Modal, Row, Select, Spin } from "antd";
import Button from "atoms/Button";
import COLORS from "common/Colors";
import { getColor, LEAVE_ICON } from "common/Constants";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import LeaveDonutChart from "./LeaveDonutChart";
// import COLORS from 'common/Colors';

// Redux
import { reduce, some, includes, uniqBy } from "lodash";
import { connect, useSelector } from "react-redux";
import {
  getAllDepartmentList,
  getClientid,
  getlevelList,
  getUserInfo,
  getUserList,
  getUserRoleId,
} from "redux/selectors";

const StyledModal = styled(Modal)`
  width: 700px !important;
  .ant-modal-body {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
  .ant-select-arrow {
    position: absolute;
    top: 30% !important;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 10px;
`;

const ButtonOutlined = styled(Button)`
  padding: 5px 20px;
  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 5px;
  background: transparent;
  font-weight: normal;
  color: ${COLORS.PRIMARY};
  white-space: nowrap;

  &:hover {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  &:focus {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  @media (max-width: 900px) {
    margin: 10px 0px;
  }
`;

const StyledSelect = styled(Select)`
  padding-bottom: 20px;
`;

function DepartmentStaffLeaveBalance({
  displayModal,
  toggleModal,
  leaveTypeId,
  leaveName,
  selectedDepartmentId,
  leaveCategoryList,
  allDepartmentList,
  userList,
  clientId,
  leaveList,
  leaveCatgeory,
  filteredDepartments,
  employeeid,
  myTeam,
  isSubOrdinate,
  all,
  userInfo,
  levelList
}) {
  const [userLeaves, setUserLeaves] = useState([]);

  const [filteredUsers, setFilteredUsers] = useState([]);
  console.log('fil--->'.filteredUsers)
  const [CSVDepartmentLeave, setCSVDepartmentLeave] = useState([]);
  const csvLinkDepartment = useRef();

  const [leaveSummary, setLeaveSummary] = useState({});
  const [teamUsers, setTeamFilteredUserList] = useState([]);
  const [departmentLeaveSummary, setDepartmentLeaveSummary] = useState({});
  const [departmentName, setdepartmentName] = useState("");
  const [filter, setFilter] = useState([]);
  const [showMemberList, setShowMemberList] = useState(false);
  const [leveldepartment, setLevelDepartment] = useState([])


  useEffect(()=>{
    let level5_values = [];
    levelList.forEach((level) => {
      switch (level.levelName) {
        case "Level5":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level5_values.push(item);
            });
          });
          break;
        default:
          break;
      }
  });
  setLevelDepartment(uniqBy(level5_values))
  },[])



  const handleFilterChange = (newValue) => {
    setFilter(newValue);
  };

  const showDepartmentMemberList = (id) => {
    setFilteredUsers(id);
    // selecetDepartment(id);
    setShowMemberList(true);
  };

  const filteredUserList = userList.filter((user) => user.statusid === "1");

  //   const userInfo = useSelector(getUserInfo);
  //
  //   const userWholeData = userList.find(
  //     (user) => user.userid === userInfo.userid
  //   );

  const filteruser = userList?.filter(
    (item) => item?.userid === userInfo.userid
  );

  const getActiveUserList = () => {
    const activeUserList = userList.filter((user) => user.statusid === "1" );
    if (filteruser[0].OfficeSupervisorId === "none") {
      return activeUserList;
    } else {
      let countryBasedUserList = activeUserList?.filter((item) => {
        const findUser = userList?.find((user) => {
          return user.userid === item?.userid;
        });
        const userDetails = some(findUser?.managingoffice, (value) =>
          includes(filteruser[0]?.managingoffice, value)
        );
        return userDetails;
      });
      return countryBasedUserList;
    }
  };

  // const userManagingOffices = userWholeData?.managingoffice;
  // const getCommonContryList = (user) =>
  //   user.filter((user) => {
  //     const isOnSameCountry = userManagingOffices.some((office) => {
  //       return user.managingoffice.includes(office);
  //     });
  //     return isOnSameCountry;
  //   });

  const getEligibleUsers = (users, currentCategory) =>
    users.filter((user) => {
      const isEligible =
        currentCategory?.eligible?.toLowerCase() === "both" ||
        currentCategory?.eligible?.toLowerCase() === user.gender.toLowerCase();
      return isEligible;
    });

  const getHrUserList = () => getEligibleUsers(getActiveUserList());

  const getApprovedLeaves = (
    leaveCatgeory,
    leaveTypeId,
    filteredDepartments,
    all
  ) => {
    return leaveCatgeory.filter((item) => {
      
      return (
        (departmentName.includes(item.department_name)) &&
        item.status_id === "5" &&
        item.leave_category_id === leaveTypeId
      );
    });
  };

  const updateLeaveStatistics = (approvedLeaves, leaveStatistics) => {
    approvedLeaves.forEach((leave) => {
      const leaveTakenUserId = leave.userid;
      if (leaveStatistics?.[leaveTakenUserId]) {
        leaveStatistics[leaveTakenUserId].consumed += parseFloat(
          leave.number_of_days
        );
      }
    });
  };

  const createLeaveStatistics = (users) => {
    const leaveStatistics = {};
    users.forEach((user) => {
      leaveStatistics[user.userid] = {
        available: 0,
        consumed: 0,
      };
    });
    return leaveStatistics;
  };

  const createUpdatedLeaveData = (
    users,
    leaveCategoryList,
    leaveTypeId,
    leaveStatistics
  ) => {
    return users.map((category) => {
      const categoryName = category.fullname;
      const categoryid = category.userid;
      const availableCount = leaveCategoryList?.find(
        (data) => data.leaveCategoryId == leaveTypeId
      );
      return {
        categoryName,
        categoryid,
        data: [
          {
            type: "available",
            value: parseInt(availableCount?.numberOfLeaves || 0, 10),
          },
          {
            type: "consumed",
            value: leaveStatistics[categoryid]?.consumed || 0,
          },
        ],
      };
    });
  };

  useEffect(() => {
    const activeUserList = getActiveUserList();

    const departmentFilterUserList = activeUserList.filter((item)=>departmentName.includes(item.leveldepartmentname))

    const leaveStatistics = createLeaveStatistics(departmentFilterUserList);

    const currentCategory = leaveCategoryList?.find(
      (data) => data.leaveCategoryId == leaveTypeId
    );

    let teamFilteredUserList = [];
    if (!all) {
      teamFilteredUserList = activeUserList.filter((user) => {
        // const userDepartmentIds = user.userdepartmentid;
        const isEligible =
          currentCategory?.eligible?.toLowerCase() === "both" ||
          currentCategory?.eligible?.toLowerCase() ===
            user.gender.toLowerCase();
        const isEmployeeSubordinate = isSubOrdinate(myTeam, user?.employeeid);
        return (
          isEmployeeSubordinate && isEligible
          // &&
          // Object.values(userDepartmentIds).some((deptIds) =>
          //   filteredDepartments.some((dept) =>
          //     deptIds.includes(dept.department_id)
          //   )
          // )
        );
      });
    }


    const approvedLeaves = getApprovedLeaves(
      leaveCatgeory,
      leaveTypeId,
      filteredDepartments,
      all
    );


    updateLeaveStatistics(approvedLeaves, leaveStatistics);


    const updatedLeaveData = createUpdatedLeaveData(
      all
        ? getEligibleUsers(departmentFilterUserList, currentCategory)
        : teamFilteredUserList,
      leaveCategoryList,
      leaveTypeId,
      leaveStatistics
    );

    setTeamFilteredUserList(teamFilteredUserList);
    setLeaveSummary(updatedLeaveData);
    setUserLeaves(updatedLeaveData);
  }, [
    departmentName,
    leaveTypeId,
    leaveCatgeory,
    employeeid,
    filteredDepartments,
    all,
    myTeam,
    isSubOrdinate,
  ]);

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Name" },
    { label: "Available Leaves" },
    { label: "Consumed Leaves" },
    { label: "Total Leaves" },
  ];

  const prepareCSV = async (event, done) => {
    
      if(leaveSummary.length > 0 && showMemberList){
        setCSVDepartmentLeave(
          reduce(
            leaveSummary
                  .filter((leave) => {
                    if (filter && filter.length) {
                      return filter.includes(leave.categoryid);
                    }
                    return true;
                  }),
            function (result, leave) {
              result.push({
                "Employee ID": leave.categoryid,
                Name: leave.categoryName,
                "Available Leaves": leave.data[0].value - leave.data[1].value,
                "Consumed Leaves": leave.data[1].value,
                "Total Leaves": leave.data[0].value,
                // "Last Year Leave Balance": leave.leaveData[0].lastYearLeaveBalance
              });
              return result;
            },
            []
          )
        );
      }else if(!showMemberList)
      {
        setCSVDepartmentLeave(
          reduce(
            departmentLeaveSummary
                .filter(
                  (value) =>
                    filteredUsers.length === 0 ||
                    filteredUsers.includes(value.categoryid)
                ),
            function (result, leave) {
              result.push({
                "Department Name": leave.categoryName,
                "Available Leaves": leave.data[0].value - leave.data[1].value,
                "Consumed Leaves": leave.data[1].value,
                "Total Leaves": leave.data[0].value,
                // "Last Year Leave Balance": leave.leaveData[0].lastYearLeaveBalance
              });
              return result;
            },
            []
          )
        );
      }
      

      setTimeout(() => {
        csvLinkDepartment.current.link.click();
      });
    
  };

  useEffect(() => {
    let leaveStatistics = {};
    leveldepartment.forEach((category) => {
      leaveStatistics[category] = {
        available: 0,
        consumed: 0,
      };
    });

    console.log('leveldepartment--->',leveldepartment)


    let approvedLeaves = [];
    approvedLeaves = leaveCatgeory.filter((item) => {
      return item.status_id === "5" && item.leave_category_id === leaveTypeId;
    });

    approvedLeaves.forEach((leave) => {
      const leaveCategoryName = leave?.department_name;
      if (leaveCategoryName) {
        leaveStatistics[leaveCategoryName].consumed += parseFloat(
          leave.number_of_days
        );
      }
    });

    const updatedLeaveData = leveldepartment?.map((category) => {
      const categoryName = category;

      const availableCount = leaveCategoryList?.find(
        (data) => data.leaveCategoryId === leaveTypeId
      );

      


      const departmentFilteredUserList = getActiveUserList().filter((user) => {
        // const userDepartmentIds = user.leveldepartmentname;
        return categoryName?.includes(user?.leveldepartmentname)
      });

      return {
        categoryName: categoryName,
        categoryid: categoryName,
        data: [
          {
            type: "available",
            value: parseInt(
              (departmentFilteredUserList?.length *
                availableCount?.numberOfLeaves) || 0,
              10
            ),
          },
          {
            type: "consumed",
            value: leaveStatistics[categoryName]?.consumed || 0,
          },
        ],
      };
    });

    setDepartmentLeaveSummary(updatedLeaveData);
    setUserLeaves(updatedLeaveData);
  }, [leaveTypeId, leaveCatgeory, filteredUsers]);

  function findLeaveCategoryName(leaveCategoryList, leaveTypeId) {
    const leaveCategory = leaveCategoryList?.find(
      (category) => category.leaveCategoryId === leaveTypeId
    );

    return leaveCategory
      ? leaveCategory.leaveCategoryName
      : "Category not found";
  }

  const leaveCategoryName = findLeaveCategoryName(
    leaveCategoryList,
    leaveTypeId
  );

  return (
    <>
      <StyledModal
        title={
          showMemberList
            ? `${departmentName} - ${leaveCategoryName} Report`
            : `Overall ${leaveCategoryName} Report`
        }
        visible={displayModal}
        onCancel={() => {
          toggleModal();
          setShowMemberList(false);
          setFilteredUsers([]);
          setFilter([]);
        }}
        footer={null}
      >
        {/* <Spin spinning={displayLeaveReportLoader}> */}
        <Row gutter={16}>
          <Col span={8}>
            <p>Department</p>
            <StyledSelect
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search Department"
              name="user"
              optionFilterProp="department_name"
              options={leveldepartment?.map((item)=>{
                return {department_name: item, department_id: item }
              })}
              fieldNames={{
                label: "department_name",
                value: "department_id",
              }}
              disabled={showMemberList}
              value={filteredUsers}
              onChange={(newValue) => {
                const selectedDepartment = leveldepartment.find(
                  (dept) => dept === newValue
                );
                setFilteredUsers([newValue]);
                setdepartmentName(selectedDepartment);
                // showDepartmentMemberList(newValue);
              }}
            />
          </Col>
          <Col span={8}>
            <p>Employee</p>
            <StyledSelect
              mode="multiple"
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="All"
              name="user"
              optionFilterProp="fullname"
              filterOption={(input, option) =>
                option.fullname.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) => {
                optionA.fullname
                  .toLowerCase()
                  .localeCompare(optionB.fullname.toLowerCase());
              }}
              options={filteredUserList}
              fieldNames={{
                label: "fullname",
                value: "userid",
              }}
              value={filter}
              onChange={handleFilterChange}
              disabled={!showMemberList}
            />
          </Col>

          <Col span={8} style={{ justifyContent: "flex-end", display: "flex" }}>
            <ButtonOutlined
              style={{ margin: "0px !important" }}
              onClick={prepareCSV}
              disabled={userLeaves.length === 0}
            >
              Export CSV file
            </ButtonOutlined>
            <CSVLink
              header={CSVHeaders}
              data={CSVDepartmentLeave}
              filename={"Department Leave List.csv"}
              asyncOnClick={true}
              ref={csvLinkDepartment}
            />
          </Col>
        </Row>
        {showMemberList ? (
          <Row gutter={16}>
            {Array.isArray(leaveSummary) &&
              leaveSummary
                .filter((leave) => {
                  if (filter && filter.length) {
                    return filter.includes(leave.categoryid);
                  }
                  return true;
                })
                .map((value, index) => (
                  <StyledCol md={12} sm={24} xs={24} key={index}>
                    <LeaveDonutChart
                      iconName={LEAVE_ICON[7]}
                      title={value.categoryName}
                      data={value.data}
                    />
                  </StyledCol>
                ))}
          </Row>
        ) : (
          <Row gutter={16}>
            {console.log('departmentLeaveSummary--->',departmentLeaveSummary)}
            {Array.isArray(departmentLeaveSummary) &&
              departmentLeaveSummary
                .filter(
                  (value) =>
                    filteredUsers.length === 0 ||
                    filteredUsers.includes(value.categoryid)
                )
                .map((value, index) => (
                  <StyledCol
                    md={12}
                    sm={24}
                    xs={24}
                    key={index}
                    onClick={() => {
                      showDepartmentMemberList(value.categoryid);
                      const selectedDepartment = leveldepartment.find(
                        (dept) => dept === value.categoryid
                      );
                      setdepartmentName(selectedDepartment);
                    }}
                  >
                    <LeaveDonutChart
                      iconName={LEAVE_ICON[7]}
                      title={value.categoryName}
                      data={value.data}
                    />
                  </StyledCol>
                ))}
          </Row>
        )}
        {/* </Spin> */}
      </StyledModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  userList: getUserList(state),
  clientId: getClientid(state),
  userInfo: getUserInfo(state),
  levelList: getlevelList(state)
});

export default connect(mapStateToProps, null)(DepartmentStaffLeaveBalance);
