import React, { useEffect, useState } from "react";
import TeamCard from "molecules/TeamCard";
import {
  Row,
  Col,
  Input,
  Button,
  Modal,
  Dropdown,
  message,
  Pagination,
} from "antd";
import { DEVICE } from "common/Device";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
  getUnreadChatMessageList,
  getUserInfo,
  getDepartmentList,
  getClientid,
  getlevelList,
  getSupervisorList,
  getRoleList,
} from "redux/selectors";
import { setUserList, toggleSuccessModal } from "redux/actions";
import styled from "@emotion/styled";
import {
  map,
  concat,
  size,
  uniqBy,
  includes,
  find,
  result,
  uniq,
  flatMap,
  get,
} from "lodash";
import COLORS from "common/Colors";
import EventBus from "utils/EventBus";
import { useRef } from "react";
import { STATUS_CODE, TOPGRADESUPERVISORLIST } from "common/Constants";
import { CALL_API } from "common/API";
import { ButtonFilled } from "reusableComponent/UIButtons";
import { ReactComponent as IconLink } from "../assets/Component 275 – 1.svg";
import AddEmployee from "organisms/AddEmployee";
import useEmployeeFilter from "hooks/useEmployeeFilter";

const StyledCol = styled(Col)`
  padding: 10px;
`;

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  border-radius: 10px;
  .people_font_style {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.83px;
  }
  .selectbox {
    width: 100%;
    border-radius: 10px !important;
  }
  .ant-select-selector {
    border-radius: 10px !important;
  }
  .broadcast {
    width: 60px;
    height: 60px;
  }
  @media ${DEVICE.tablet} {
    .selectbox {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const StyledFooterDiv = styled.div`
  text-align: right;
  padding-top: 10px;
  .ant-btn {
    font-weight: bold !important;
    color: ${COLORS.PRIMARY};
    padding-right: 0px !important;
  }
  .ant-btn:hover {
    border: none;
  }
`;

function Team({
  userList,
  allDepartmentList,
  updateSuccessModal,
  unreadChatMessageList,
  selectedDepartmentId,
  levelList,
  supervisorList,
  userRoleId,
  roleList,
  userInfo,
  clientId,
  updateUserList,
}) {
  const [isBroadcastModalVisible, toggleBroadcastModalVisible] =
    useState(false);
  const [displayAddEmployeeModal, toggleAddEmployeeModal] = useState(false);
  const [sendingMessageLoader, ToggleSendingMessageLoader] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [broadcastMessage, setBroadcastMessage] = useState("");
  const { TextArea } = Input;
  const containerRef = useRef(null);

  let currentPath = window.location.pathname;
  let pathSegments = currentPath.split("/");
  let url = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    setSelectedDepartment(selectedDepartmentId);
  }, [selectedDepartmentId]);

  useEffect(() => {
    getStaffList();
  }, [selectedDepartment, url, userList]);

  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  const [servicelineData, setServicelinefetchData] = useState([]);
  const [subservicelineData, setsubservicelineData] = useState([]);
  const [departmentLevelData, setDepartmentLevelData] = useState([]);

  const sendMessage = () => {
    const staffIds = map(getStaffList(), "userid");
    if (size(staffIds) && broadcastMessage) {
      ToggleSendingMessageLoader(true);
      EventBus.$dispatch("sendMessage", {
        action: "BROADCAST",
        payload: {
          message: broadcastMessage,
          receiverUserIds: map(getStaffList(), "userid"),
          receiverName: map(getStaffList(), "fullname"),
        },
      });
      setTimeout(() => {
        toggleBroadcastModalVisible(false);
        ToggleSendingMessageLoader(false);
      }, 1000);
      setTimeout(() => {
        updateSuccessModal({
          visibility: true,
          message: `Message has been broadcasted.`,
        });
        setBroadcastMessage("");
      }, 1000);
    }
  };

  const userWholeData = userList.find(
    (user) => user.userid === userInfo.userid
  );

  const userManagingOffices = userWholeData?.managingoffice;

  const filteruser = userList?.filter(
    (item) => item?.userid === get(userInfo, "userid", "")
  );

  function getSubordinates(data, supervisorId) {
    let result = [];
    let visited = new Set();
    let memo = new Map();

    function findSubordinates(supervisorId) {
      if (visited.has(supervisorId)) {
        return;
      }
      if (memo.has(supervisorId)) {
        result = result.concat(memo.get(supervisorId));
        return;
      }

      visited.add(supervisorId);
      let subordinates = [];

      data.forEach((employee) => {
        if (employee.OfficeSupervisorId === supervisorId) {
          subordinates.push(employee);
          findSubordinates(employee.employeeid);
        }
      });

      result = result.concat(subordinates);
      memo.set(supervisorId, subordinates);
    }

    findSubordinates(supervisorId);
    return result;
  }

  const subordinates = getSubordinates(
    userList?.filter((val) => val?.statusid?.toString() === "1"),
    filteruser[0]?.employeeid
  );

  const StaffList = () => {
    return concat(
      url === "myteam"
        ? subordinates
        : userList
            ?.filter((item) => item?.statusid === "1") //? Active People will be shown
            ?.filter((item) => {
              const isOnSameCountry = userManagingOffices.some((office) => {
                return item.managingoffice.includes(office);
              });

              return isOnSameCountry;
            }) //? Based on User Location people will be filtered
    );
  };

  const getStaffList = () => {
    const allStaffList = userList;

    // console.log("filteruser-->", filteruser[0]?.leveldepartmentname);
    // console.log("filteruser-->", filteruser[0]?.baselocationid);

    // const filterByRole = roleList?.filter(
    //   (item) => item?.roleId === userRoleId
    // );

    var staffList;
    staffList =
      url === "myteam"
        ? subordinates
        : allStaffList
            ?.filter((item) => item?.statusid === "1")
            ?.filter((item) => {
              const isOnSameCountry = userManagingOffices.some((office) => {
                return item.managingoffice.includes(office);
              });

              return isOnSameCountry;
            }); //? Based on User Location people will be filtered
    return uniqBy(staffList, "userid");
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList, levelList } = await CALL_API(
      `master-data/${clientId}`,
      "post",
      {
        fetch_master_data,
      }
    );

    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        let level1_values = [];
        let level2_values = [];
        let level3_values = [];
        let level4_values = [];
        let level5_values = [];

        updateUserList(userList);

        levelList.forEach((level) => {
          switch (level.levelName) {
            case "Level1":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level1_values.add(item);
                });
              });
              break;
            case "Level2":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level2_values.add(item);
                });
              });
              break;
            case "Level3":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level3_values.add(item);
                });
              });
              break;
            case "Level4":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level4_values.add(item);
                });
              });
              break;
            case "Level5":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level5_values.add(item);
                });
              });
              break;
            default:
              break;
          }
        });

        setManagingOfficeData(Array.from(level1_values));
        setCompetencyQCfetchData(Array.from(level2_values));
        setServicelinefetchData(Array.from(level3_values));
        setsubservicelineData(Array.from(level4_values));
        setDepartmentLevelData(Array.from(level5_values));
      }
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <Link
          onClick={() => toggleAddEmployeeModal(true)}
          className="fw-semibold"
        >
          New Employee Form
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="pending-employee" className="fw-semibold">
          Pending Employee
        </Link>
      ),
    },
  ];

  const linkToCopy = "https://onboarding.bdo.insyts.co/";
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch(() => {
        message.error("Failed to copy link");
      });
  };

  // const STAFF_LIST = uniqBy(StaffList(), "userid")?.map((val) => {
  //   return {
  //     user_id: val?.userid,
  //     name: val?.fullname,
  //     managingoffice: val?.managingoffice,
  //   };
  // });

  const { FilterJSX, getFilterList } = useEmployeeFilter({
    employeeFilterList: getStaffList(),
    containerRef,
    managingOfficeData,
    competencyQCData,
    departmentLevelData,
    subservicelineData,
    servicelineData,
    // STAFF_LIST,
    showFilters: {
      managingOffice: url !== "myteam",
      serviceline: true,
      competencyQC: true,
      team: true,
      leveldepartmentname: url !== "myteam",
      competencyrole: true,
      employee: true,
      supervisor: true,
      grade: true,
    },
    menuName: url === "myteam" ? "myTeam" : "hremployee",
  });

  useEffect(() => {
    if (levelList?.length > 0) {
      let level1_values = [];
      let level2_values = [];
      let level3_values = [];
      let level4_values = [];
      let level5_values = [];

      levelList.forEach((level) => {
        if (level.levelName === "Level1") {
          level.value.forEach((value) => {
            level1_values.push(...value.value);
          });
        } else if (level.levelName === "Level2") {
          level.value.forEach((value) => {
            level2_values.push(...value.value);
          });
        } else if (level.levelName === "Level3") {
          level.value.forEach((value) => {
            level3_values.push(...value.value);
          });
        } else if (level.levelName === "Level4") {
          level.value.forEach((value) => {
            level4_values.push(...value.value);
          });
        } else if (level.levelName === "Level5") {
          level.value.forEach((value) => {
            level5_values.push(...value.value);
          });
        }
      });
      setManagingOfficeData(level1_values);
      setCompetencyQCfetchData(level2_values);
      setServicelinefetchData(level3_values);
      setsubservicelineData(level4_values);
      setDepartmentLevelData(level5_values);
    }
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50;

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate start and end index based on current page and page size
  const startIndex =
    ((getFilterList().length > 50 ? currentPage : 1) - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // Function to slice the employee list based on current page
  const getDisplayList = () => {
    return getFilterList().slice(startIndex, endIndex);
  };

  return (
    <>
      {!displayAddEmployeeModal && (
        <>
          <Row className="d-flex justify-content-between mb-1">
            <Col>
              <StyledTitle className="page-title-head">
                {url === "myteam" ? "" : "List of Employee"}
              </StyledTitle>
            </Col>
            <Col>
              <div>
                {url !== "myteam" && (
                  <div>
                    <IconLink onClick={handleCopyClick} />
                    <Dropdown
                      menu={{ items }}
                      placement="bottom"
                      trigger={["hover"]}
                    >
                      <ButtonFilled className="apply-leave-sm">
                        {" "}
                        Add People{" "}
                      </ButtonFilled>
                    </Dropdown>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          {/* <Form
        form={form}
        layout="vertical"
        initialValues={{
          competencyQC: [],
          serviceline: [],
          managingoffice: [],
          leveldepartmentname: [],
          team: [],
          competencyrole: "",
          employee: "",
          supervisor: "All",
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8} lg={6} xl={4}>
            <Form.Item name="employee" label="People">
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="name"
                getPopupContainer={() => containerRef.current}
                options={concat(
                  {
                    user_id: "",
                    name: "All Employee",
                  },
                  STAFF_LIST
                )}
                fieldNames={{
                  label: "name",
                  value: "user_id",
                }}
              />
            </Form.Item>
          </Col>
          {url !== "myteam" && (
            <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
              <Form.Item name="managingoffice" label="Country">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="value"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                >
                  {managingOfficeData?.map((value, index) => (
                    <Option key={index} value={value} label={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
            <Form.Item name="competencyQC" label="Competency/ QC">
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="value"
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                disabled={competencyQCData?.length === 0}
              >
                {competencyQCData?.map((value, index) => (
                  <Option key={index} value={value} label={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
            <Form.Item name="serviceline" label="Serviceline">
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                disabled={servicelineData?.length === 0}
              >
                {servicelineData?.map((value, index) => (
                  <Option key={index} value={value} label={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
            <Form.Item name="team" label="Sub Serviceline">
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                disabled={subservicelineData?.length == 0}
              >
                {subservicelineData?.map((value, index) => (
                  <Option key={index} value={value} label={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {url !== "myteam" && (
            <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
              <Form.Item name="leveldepartmentname" label="Department">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                  disabled={departmentLevelData?.length == 0}
                >
                  {departmentLevelData?.map((value, index) => (
                    <Option key={index} value={value} label={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={24} md={8} lg={6} xl={4} ref={containerRef}>
            <Form.Item name="competencyrole" label="Competency role">
              <Select
                showSearch
                placeholder="Competency role"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
              >
                <Option value="">All</Option>
                {CompetencyRole?.map((value, index) => (
                  <Option key={index} value={value} label={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={4}>
            <Form.Item name="supervisor" label="Supervisor / Manager">
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
              >
                <Option value="All">All</Option>
                {supervisorList?.map((value, index) => (
                  <Select.Option
                    key={index}
                    value={value?.name}
                    label={value?.name}
                  >
                    {value?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form> */}

          {FilterJSX}

          <StyledFilterRow gutter={16}>
            <Col className="" lg={6} md={12} sm={24} xs={24}>
              <div className="people_font_style mb-2">
                Total People: {getFilterList().length}
              </div>
            </Col>
          </StyledFilterRow>

          <Row>
            {map(
              getDisplayList(),
              (
                {
                  clientid,
                  userid,
                  fullname,
                  departmentid,
                  profileurl,
                  employeeid,
                  jobtitle,
                  usertype,
                  employementtype,
                  leveldepartmentname,
                  designation,
                  grade,
                },
                index
              ) => (
                <StyledCol key={index} xs={24} sm={12} md={12} lg={8} xl={6}>
                  <Link
                    to={
                      url === "myteam"
                        ? TOPGRADESUPERVISORLIST.includes(
                            filteruser.length > 0 ? filteruser[0]?.grade : ""
                          )
                          ? `/profile-details/${userid}?type=team`
                          : "myteam"
                        : 
                        `/profile-details/${userid}?type=hr`
                    }
                  >
                    <TeamCard
                      id={userid}
                      clientid={clientid}
                      name={fullname}
                      email_id={userid}
                      employeeid={employeeid}
                      jobtitle={jobtitle}
                      usertype={usertype}
                      employementtype={employementtype}
                      hasUnreadMessage={
                        includes(unreadChatMessageList, userid) > 0
                          ? true
                          : false
                      }
                      dept_name={result(
                        find(allDepartmentList, (val) => {
                          return val.department_id === departmentid;
                        }),
                        "department_name"
                      )}
                      profile={profileurl}
                      url={url}
                      designation={designation}
                      leveldepartmentname={leveldepartmentname}
                      grade={grade}
                    />
                  </Link>
                </StyledCol>
              )
            )}
          </Row>

          {/* Pagination component */}
          {getFilterList().length > pageSize && (
            <Pagination
              current={getFilterList().length > 50 ? currentPage : 1}
              total={getFilterList().length}
              pageSize={pageSize}
              onChange={handlePageChange}
              style={{ marginTop: "20px", textAlign: "center" }}
            />
          )}
          <Modal
            centered
            visible={isBroadcastModalVisible}
            closable={false}
            footer={false}
            keyboard={false}
            title="Broadcast Message"
            onCancel={() => toggleBroadcastModalVisible(false)}
          >
            <TextArea
              rows={4}
              placeholder="Type a message."
              maxLength={500}
              value={broadcastMessage}
              onChange={(e) => setBroadcastMessage(e.target.value)}
            />
            <StyledFooterDiv>
              <Button
                type="link"
                onClick={sendMessage}
                loading={sendingMessageLoader}
              >
                Send to all
              </Button>
            </StyledFooterDiv>
          </Modal>
        </>
      )}

      {displayAddEmployeeModal && (
        <AddEmployee
          isModalVisible={displayAddEmployeeModal}
          closeModal={() => toggleAddEmployeeModal(false)}
          getEmployeeList={fetchMasterData}
          EmployeeList={userList}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
  userInfo: getUserInfo(state),
  departmentList: getDepartmentList(state),
  levelList: getlevelList(state),
  supervisorList: getSupervisorList(state),
  roleList: getRoleList(state),
  clientId: getClientid(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSuccessModal: toggleSuccessModal,
      updateUserList: setUserList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Team);
