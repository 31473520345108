import React, { useEffect, useState } from "react";
import { message, Spin } from "antd";

// Assets
import logo from '../assets/logo.png';
import microsoft from '../assets/microsoftlogo.svg';

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleProcessingModal,
} from "redux/actions";

// Theme
import styled from "@emotion/styled";
import { AUTH_URL, LOGIN_BASE_URL, NON_EMPTY_REGEX, STATUS_CODE } from "common/Constants";

// Components
import { getUserRoleId } from "redux/selectors";
import axios from "axios";

// Styled Components
const DivContainer = styled.div`
  height: 100%;
  padding-top: 15%;
  padding: 10px 10px 10px 10px;
  background-image: url('login-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-height: 625px) {
    justify-content: flex-start;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  .logo {
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
    padding: 30px 30px 30px 30px;
    border-radius: 26px;
  }

  @media (max-height: 625px) {
    position: relative;
  }
`;

const StyledContainer = styled.div`
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid black;
  box-shadow: 0px 3px 6px #00000029;
`;



const Login = ({
  setLoggedIn,
  updateProcessingModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDashboard, setIsDashboard] = useState(true);

  const onFinish = async () => {
    try {
      setIsDashboard(true);
      // updateProcessingModal(true);

      const authResponse = localStorage.getItem("authResponse");
      const parsedResponse = authResponse ? JSON.parse(authResponse) : null;

      if (!parsedResponse || !parsedResponse.idToken) {
        throw new Error("Invalid auth response");
      }

      const payload = {
        token: parsedResponse.idToken,
      };

      const response = await axios.post(`${LOGIN_BASE_URL}user/sso`, payload);
      const token = response?.data?.authorizationToken;

      if (response?.data?.status) {
        window.localStorage.setItem("token", token);
        setLoggedIn();
        updateProcessingModal(false);
      } else {
        message.error(response?.data?.message);
        updateProcessingModal(false);
        setIsDashboard(false);
        return;
      }

      setIsDashboard(false);
    } catch (error) {
      message.error("Something went wrong");
      console.error("Error fetching data:", error);
      // updateProcessingModal(false);
      setIsDashboard(false);
    }
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${AUTH_URL}/insyts/auth`, {});

      if (response && response.data) {
        window.location.href = response?.data?.response;
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
      setIsDashboard(true);
      updateProcessingModal(true);
    }
  };

  useEffect(() => {
    const initAuth = async () => {
      if (
        localStorage.getItem("authResponse") !== null &&
        JSON.parse(localStorage.getItem("authResponse")).username !== ""
      ) {
        onFinish();
      } else {
        const params = new URLSearchParams(window.location.search);
        const authCode = params.get("code");
        if (authCode) {
          const response = await axios.get(
            `${AUTH_URL}insyts/sso?code=${authCode}`
          );
          if (response) {
            localStorage.setItem(
              "authResponse",
              JSON.stringify(response.data.response)
            );
            onFinish();
          }
        } else {
          setIsDashboard(false);
        }
      }
    };

    initAuth();
  }, []);

  return (
    <>
      {isDashboard && <div />}
      {!isDashboard && (
        <DivContainer>
          <LogoContainer>
            <img className="logo" src={logo} alt="logo" />
          </LogoContainer>
          <Spin spinning={loading}>
            <StyledContainer>
              <div
                className="d-flex align-items-center gap-4"
                onClick={handleClick}
              >
                <img
                  className="logo"
                  src={microsoft}
                  alt="logo"
                  width={40}
                  height={40}
                />
                <h4 className="pt-2">Sign in with Microsoft</h4>
              </div>
            </StyledContainer>
          </Spin>

        </DivContainer>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
