import { Table } from "antd";
import React from "react";
import styled from "styled-components";

const sharedOnCell = (_, index) => {
  if (index === 3) {
    return { colSpan: 0 };
  }

  return {};
};

const timeSheetOverviewColumns = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    onCell: (_, index) => {
      return {
        colSpan: index === 3 ? 3 : 1,
        className: index === 3 ? "text-end" : "",
      };
    },
    className: "text-start",
    width: "25%",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    onCell: sharedOnCell,
    width: "25%",
  },
  {
    title: "Total Tasks",
    dataIndex: "total_tasks",
    key: "total_tasks",
    onCell: sharedOnCell,
    width: "25%",
  },
  {
    title: "Total Hours",
    dataIndex: "total_hours",
    key: "total_hours",
    colSpan: 1,
    width: "25%",
  },
];

function percentNumber(num) {
  return num % 1 === 0 ? num : num.toFixed(2);
}

const TimeSheetOverView = ({ timeSheet }) => {
  const timeSheetOverview = [
    {
      description: "Chargable",
      type: "C",
      total_tasks: getChargeableOverview(timeSheet).totalTask,
      total_hours: getChargeableOverview(timeSheet).totalHours,
    },
    {
      description: "Non-Chargable",
      type: "NC",
      total_tasks: getNonChargeableOverview(timeSheet).totalTask,
      total_hours: getNonChargeableOverview(timeSheet).totalHours,
    },
    {
      description: "Approved Absence",
      type: "AP",
      total_tasks: "0",
      total_hours: "0",
    },
    {
      description: "Total Hours",
      type: "",
      total_tasks: "",
      total_hours:
        getChargeableOverview(timeSheet).totalHours +
        getNonChargeableOverview(timeSheet).totalHours,
    },
  ];

  return (
    <TimeSheetOverViewWrap>
      <TimeSheetOverViewTable
        columns={timeSheetOverviewColumns}
        dataSource={timeSheetOverview}
        bordered
        footer={() => <TimeSheetOverViewFooter timeSheetData={timeSheet} />}
        pagination={false}
      />
    </TimeSheetOverViewWrap>
  );
};

const TimeSheetOverViewWrap = styled.div`
  .formula {
    color: #3a7ff5;
  }
  .center-bar {
    height: 48px;
    width: 1px;
    background-color: #e4e4e4;
  }
`;

export default TimeSheetOverView;

const TimeSheetOverViewFooter = ({ timeSheetData }) => {
  const chargeableOverview = getChargeableOverview(timeSheetData);
  const nonChargeableOverview = getNonChargeableOverview(timeSheetData);

  const totalChargeableHours = chargeableOverview.totalHours;
  const totalNonChargeableHours = nonChargeableOverview.totalHours;

  let effectiveTotalHours = totalChargeableHours + totalNonChargeableHours;

  const chargeablePercentage =
    effectiveTotalHours !== 0
      ? (totalChargeableHours / effectiveTotalHours) * 100
      : 0;

  const nonChargeablePercentage =
    effectiveTotalHours !== 0
      ? ((totalChargeableHours + totalNonChargeableHours) /
          effectiveTotalHours) *
        100
      : 0;

  return (
    <div className="d-flex justify-content-around align-items-center ">
      <div className="p-1">
        % Chargeability{" "}
        <span className="formula">(C / Effective Working Hours)</span> -{" "}
        {percentNumber(chargeablePercentage)}%
      </div>
      <div className="center-bar"></div>
      <div className="p-1">
        % Utilization{" "}
        <span className="formula">((C + NC) / Effective Working Hours)</span> -{" "}
        {percentNumber(nonChargeablePercentage)}%
      </div>
    </div>
  );
};

const TimeSheetOverViewTable = styled(Table)`
  th.ant-table-cell {
    font-weight: bold;
    padding: 8px 12px !important;
    text-align: center !important;
    &.text-start {
      text-align: start !important;
    }
  }
  .ant-table-cell {
    text-align: center;
    padding: 7px 11px !important;
  }
  .ant-table-cell.text-start {
    text-align: start;
  }
  .ant-table-cell.text-end {
    text-align: end;
    padding-right: 42px;
  }
  .ant-table-footer {
    padding: 0;
  }
`;

const nonChargeableClient = (client) => client === "nc";

const getChargeableOverview = (timeSheet) => {
  const totalTask = timeSheet.reduce((acc, item) => {
    if (item.task && !nonChargeableClient(item.client)) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const totalHours = timeSheet.reduce((acc, item) => {
    if (!nonChargeableClient(item.client)) {
      return (
        acc +
        (Number(item.mon) || 0) +
        (Number(item.tue) || 0) +
        (Number(item.wed) || 0) +
        (Number(item.thu) || 0) +
        (Number(item.fri) || 0) +
        (Number(item.sat) || 0) +
        (Number(item.sun) || 0)
      );
    }
    return acc;
  }, 0);

  return {
    totalTask: Number(totalTask),
    totalHours: Number(totalHours),
  };
};

const getNonChargeableOverview = (timeSheet) => {
  const totalTask = timeSheet.reduce((acc, item) => {
    if (item.task && nonChargeableClient(item.client)) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const totalHours = timeSheet.reduce((acc, item) => {
    if (nonChargeableClient(item.client)) {
      return (
        acc +
        (Number(item.mon) || 0) +
        (Number(item.tue) || 0) +
        (Number(item.wed) || 0) +
        (Number(item.thu) || 0) +
        (Number(item.fri) || 0) +
        (Number(item.sat) || 0) +
        (Number(item.sun) || 0)
      );
    }
    return acc;
  }, 0);

  return {
    totalTask: Number(totalTask),
    totalHours: Number(totalHours),
  };
};
