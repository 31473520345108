const policyTypeList = [
  "Common",
  "Security",
  "Privacy",
  "Information",
  "Other"
];

export const getAddEditPolicyJson = (data,value) => {

    const addPolicyForm = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [12, 12, 12],
          fields: [    
            [
              {
                id: 1,
                label: "Policy Title",
                type: "text",
                name: "policyTitle",
                Placeholder: "Enter",
                value: data["policyTitle"],
                maxLength: 70,
                validationRules: [
                  {
                    required: true,
                    message: `policy title is required`,
                  },
                ],
              },
            ],
            [
              {
                id: 2,
                label: "Policy Effective Date",
                type: "date",
                name: "policyEffectiveDate",
                Placeholder: "Enter",
                // value: data["date"],
                validationRules: [
                  {
                    required: true,
                    message: `Policy effective date is required`,
                  },
                ],
              },
              {
                id: 4,
                label: "Policy Type",
                type: "select",
                name: "policyType",
                Placeholder: "Enter",
                value: "",
                options: policyTypeList,
                validationRules: [
                  {
                    required: true,
                    message: `Policy type is required`,
                  },
                ],
              },
            ],
            // [
            //   {
            //     id: 5,
            //     label: "Policy Details",
            //     type: "editor",
            //     name: "policyDetails",
            //     Placeholder: "details",
            //     value: data["policyDetails"],
            //   },
            // ],
            [
              {
                id: 3,
                label: "Attach File",
                type: "dragger",
                name: "policyDocument",
                Placeholder: "attach file",
                value: "",
                validationRules: [
                  {
                    required: true,
                    message: `File is required`,
                  },
                ],
              },
            ],
            [
              // {
              //   id: 4,
              //   label: "Cancel",
              //   type: "buttongroup",
              //   position: "end",
              //   textColor: "white",
              //   event: "back",
              //   buttons: [
              //     {
              //       label: "Button 1",
              //       disabled: false,
              //       loading: false,
              //       btnStyle: { /* Button 1 style object */ },
              //       onClick: () => {
              //         // Handle Button 1 click
              //       }
              //     },
              //     {
              //       label: "Button 2",
              //       disabled: false,
              //       loading: false,
              //       btnStyle: { /* Button 2 style object */ },
              //       onClick: () => {
              //         // Handle Button 2 click
              //       }
              //     }
              //     // Add more buttons as needed
              //   ]
              // },
              {
                id: 4,
                label: value !== "" ? "Update" : "Next",
                type: "button",
                position: "end",
                textColor: "white",
                event: "submit",
              },
            ],
          ],
        },
      ],
    }
  
    return {
      addPolicyForm,
    }
  }
  