export const OptionalRCEO = [
    [{
      id: 1,
      label: "Attend all BDO Global Annual meetings",
      type: "checkbox",
      name: "attendGlobalAnnualMeetings",
    }],
    [{
      id: 2,
      label: "Managing Partner Appraisals",
      type: "checkbox",
      name: "managingPartnerAppraisals",
    }],
    [{
      id: 3,
      label: "Appraisals of regional COEs, QC, and Champions",
      type: "checkbox",
      name: "appraisalsRegionalCOEsQCChampions",
    }],
    [{
      id: 4,
      label: "Create a great place to work and create a conducive environment for collaboration, individual/collective growth, innovation, and risk management",
      type: "checkbox",
      name: "createConduciveEnvironment",
    }],
    [{
      id: 5,
      label: "Minutes of the Partners and Managers Meetings and follow-up listing and implementation review (quality focus)",
      type: "checkbox",
      name: "meetingMinutesAndFollowUp",
    }],
    [{
      id: 6,
      label: "Regional Monthly Report backs (for Region, Board, Global & others)",
      type: "checkbox",
      name: "regionalMonthlyReports",
    }],
    [{
      id: 7,
      label: "First-class offices and facilities, including cars and cell phones/laptops (project image)",
      type: "checkbox",
      name: "firstClassFacilities",
    }],
  ];

  export const OptionalMPS = [
    [{
      id: 1,
      label: "Outcomes management - Client satisfaction, Revenue Growth in every service line, Low staff attrition, Staff satisfaction, High Proposal submission and conversion, Profit and Cash balance improvement, Debtor and Creditor days reduction",
      type: "checkbox",
      name: "outcomesManagement",
    }],
    [{
      id: 2,
      label: "Attend monthly EXCO meetings and report back on activities and developments in the Cluster and Business Lines where applicable",
      type: "checkbox",
      name: "attendEXCOMeetings",
    }],
    [{
      id: 3,
      label: "Minutes of the Partners and Managers Meetings and follow-up listing and implementation review (quality focus)",
      type: "checkbox",
      name: "meetingMinutesAndFollowUp",
    }],
    [{
      id: 4,
      label: "Regulate the balance of workload amongst Partners, Directors, and staff",
      type: "checkbox",
      name: "balanceWorkload",
    }],
    [{
      id: 5,
      label: "First-class offices and facilities, including cars and cell phones/laptops (project image)",
      type: "checkbox",
      name: "firstClassFacilities",
    }],
    [{
      id: 6,
      label: "Create a great place to work and create a conducive environment for collaboration, individual/collective growth, innovation, and risk management",
      type: "checkbox",
      name: "createConduciveEnvironment",
    }],
    [{
      id: 7,
      label: "Apply for ISO certifications, awards, and recognitions, sponsor/lead Award Ceremonies, etc.",
      type: "checkbox",
      name: "applyForCertificationsAwards",
    }],
    [{
      id: 8,
      label: "Responsible for the management of staff in the office, in conjunction with HR and Heads of Business Lines",
      type: "checkbox",
      name: "staffManagement",
    }],
  ];

  export const OptionalPartner1 = [
    [{
      id: 1,
      label: "Counsel and mentor staff members within the service line/team in relation to technical and work management matters",
      type: "checkbox",
      name: "counselMentorStaff",
    }],
    [{
      id: 2,
      label: "Review and approval of client deliverables",
      type: "checkbox",
      name: "reviewApprovalClientDeliverables",
    }],
    [{
      id: 3,
      label: "Ensure administrative adherence to the laid down policies and procedures within the business line/team",
      type: "checkbox",
      name: "administrativeAdherence",
    }],
    [{
      id: 4,
      label: "Allocate work effectively and regulate the balance of workload to the team",
      type: "checkbox",
      name: "allocateWorkRegulateWorkload",
    }],
  ];

  export const OptionalPartner2 = [
    [{
      id: 1,
      label: "Reviews and performs a concurring review of financial statements (specifically those that are listed or have qualified audit opinions)",
      type: "checkbox",
      name: "counselMentorStaff",
    }],
  ]

  export const OptionalPartner3 = [
    [{
      id: 1,
      label: "Plan the Firm’s Tax business in detail and allocate staff responsibilities for the work involved in order to ensure that client deadlines are met",
      type: "checkbox",
      name: "planFirms",
    }],
    [{
      id: 2,
      label: "Act as the Firm’s Centre of Excellence in relation to Tax matters and to keep himself / herself apprised of all matters relating to taxation",
      type: "checkbox",
      name: "actFirms",
    }],
  ]

  export const OptionalManager1 = [
    [{
      id: 1,
      label: "Assist in developing budgets, resources, and timelines for upcoming assignments",
      type: "checkbox",
      name: "developBudgetsResourcesTimelines",
    }],
    [{
      id: 2,
      label: "Monitor the progress of the engagement team against the plan and alter it when needed",
      type: "checkbox",
      name: "monitorTeamProgress",
    }],
    [{
      id: 3,
      label: "Identify ways to leverage technology to ensure effective knowledge management and efficient engagement execution",
      type: "checkbox",
      name: "leverageTechnology",
    }],
    [{
      id: 4,
      label: "Assist the engagement partner with reviewing technical developments for their effect on the engagement",
      type: "checkbox",
      name: "reviewTechnicalDevelopments",
    }],
  ];
  
  export const OptionalManager2 = [
    [{
      id: 1,
      label: "Review of audit working papers, financial statements, management letters, and other relevant documents",
      type: "checkbox",
      name: "reviewAuditDocuments",
    }],
    [{
      id: 2,
      label: "Management of multiple audits concurrently and satisfactory handling of all audits from start to close",
      type: "checkbox",
      name: "manageMultipleAudits",
    }],
    [{
      id: 3,
      label: "Audit planning and execution of assigned audits for a diverse portfolio of clients within cost, quality, and time parameters, by motivating, coaching, and guiding staff, and conducting performance assessments of assigned staff",
      type: "checkbox",
      name: "auditPlanningExecution",
    }],
  ];
  

  export const OptionalManager3 = [
    [{
      id: 1,
      label: "Develop and maintain expertise in the taxation area, including but not limited to tax treaties, transfer pricing, and practices in other jurisdictions, and act as a resource for tax staff",
      type: "checkbox",
      name: "taxExpertise",
    }],
    [{
      id: 2,
      label: "Represent clients before the tax authorities",
      type: "checkbox",
      name: "representClientsTaxAuthorities",
    }],
    [{
      id: 3,
      label: "Review all tax returns and submissions to the tax department and deliverables to clients",
      type: "checkbox",
      name: "reviewTaxReturns",
    }],
    [{
      id: 4,
      label: "Prepare tax planning and estimates and become involved in planning for more complex clients",
      type: "checkbox",
      name: "prepareTaxPlanning",
    }],
    [{
      id: 5,
      label: "Direct and coordinate tax planning for less complex client situations",
      type: "checkbox",
      name: "coordinateTaxPlanning",
    }],
    [{
      id: 6,
      label: "Assist audit, accounting, and advisory staff in client meetings on tax matters",
      type: "checkbox",
      name: "assistInClientMeetings",
    }],
    [{
      id: 7,
      label: "Review tax accruals and associated working papers",
      type: "checkbox",
      name: "reviewTaxAccruals",
    }],
    [{
      id: 8,
      label: "Assume responsibility for the smooth flow of tax returns and continuous improvement efforts",
      type: "checkbox",
      name: "smoothFlowTaxReturns",
    }],
  ];

  export const OptionalManager4 = [
    [{
      id: 1,
      label: "Lead the delivery of concurrent multiple business advisory projects including Business Strategy, Business Performance Improvement, Feasibility Studies and Business Plans, Human Resources Advisory, ICT Advisory, Corporate Finance / Transaction Advisory, etc.",
      type: "checkbox",
      name: "leadBusinessAdvisoryProjects",
    }],
    [{
      id: 2,
      label: "Project management and crafting and delivering appropriate, innovative, and value-adding solutions on multiple engagements",
      type: "checkbox",
      name: "projectManagement",
    }],
    [{
      id: 3,
      label: "Ensure effective utilization of advisory methodologies, tools, and knowledge repository to meet engagement objectives and enhance service delivery",
      type: "checkbox",
      name: "utilizeAdvisoryMethodologies",
    }],
  ];

  export const OptionalManager5 = [
    [{
      id: 1,
      label: "Research and identification of new business opportunities",
      type: "checkbox",
      name: "researchNewBusinessOpportunities",
    }],
    [{
      id: 2,
      label: "Preparing and reviewing clients’ monthly/yearly management accounts",
      type: "checkbox",
      name: "prepareReviewManagementAccounts",
    }],
    [{
      id: 3,
      label: "Payroll processing and reviews",
      type: "checkbox",
      name: "payrollProcessingReviews",
    }],
    [{
      id: 4,
      label: "Filing and reviewing payroll statutory",
      type: "checkbox",
      name: "filingReviewingPayrollStatutory",
    }],
    [{
      id: 5,
      label: "Client Treasury management",
      type: "checkbox",
      name: "clientTreasuryManagement",
    }],
  ];

  export const OptionalSenior = [
    [{
      id: 1,
      label: "Plan and implement each assignment in accordance with the set standards/guidelines and in compliance with required professional standards, guidelines, and regulations, and BDO framework",
      type: "checkbox",
      name: "planImplementAssignments",
    }],
    [{
      id: 2,
      label: "Collaborate with team members to plan engagements and develop work program timelines, risk assessments, and other planning requirements",
      type: "checkbox",
      name: "collaborateTeamPlanning",
    }],
  ];
  
  export const OptionalAssociate = [
    [{
      id: 1,
      label: "Assist in planning assignments and documenting work done",
      type: "checkbox",
      name: "assistPlanningAssignments",
    }],
    [{
      id: 2,
      label: "Collaborate with team members to plan engagements and develop work program timelines, risk assessments, and other planning requirements",
      type: "checkbox",
      name: "collaborateTeamPlanning",
    }],
  ];
  
  export const optionalITQCManager = [
    [{
      id: 1,
      label: "Set up IT policies and procedures in respect of IT infrastructure, networks, business applications, printing and backups, e-mail, internet usage, security, etc.",
      type: "checkbox",
      name: "setupITPoliciesProcedures",
    }],
    [{
      id: 2,
      label: "Testing new programs before user installation and implementation",
      type: "checkbox",
      name: "testingNewPrograms",
    }],
    [{
      id: 3,
      label: "Manage user access to systems, servers, and files",
      type: "checkbox",
      name: "manageUserAccess",
    }],
    [{
      id: 4,
      label: "Administer the set-up of new profiles, create and update access and permissions, and maintain user accounts",
      type: "checkbox",
      name: "administerUserAccounts",
    }],
    [{
      id: 5,
      label: "Troubleshoot systems, offer immediate and alternative solutions to issues, and guide users systematically",
      type: "checkbox",
      name: "troubleshootSystems",
    }],
    [{
      id: 6,
      label: "Maintain all server systems both on-premise and cloud environments, ensure all services are up and backups are done as per policy",
      type: "checkbox",
      name: "maintainServerSystems",
    }],
    [{
      id: 7,
      label: "Analyze business systems design, maintenance, and manipulation",
      type: "checkbox",
      name: "analyzeBusinessSystems",
    }],
    [{
      id: 8,
      label: "Participate in the definition, designing, testing, and implementation of new systems/hardware and rolling out the same",
      type: "checkbox",
      name: "participateNewSystemsImplementation",
    }],
    [{
      id: 9,
      label: "Maintain software and hardware inventory, ensuring secure custody of equipment",
      type: "checkbox",
      name: "maintainInventory",
    }],
    [{
      id: 10,
      label: "Conduct ITGC System Audits according to schedule",
      type: "checkbox",
      name: "conductITGCAudits",
    }],
    [{
      id: 11,
      label: "Coordinate the production of technical instruction materials for technical and user support and ensure documentation is kept up to date",
      type: "checkbox",
      name: "coordinateTechInstructionMaterials",
    }],
    [{
      id: 12,
      label: "Assist in developing a plan for training users on any system developments",
      type: "checkbox",
      name: "developUserTrainingPlan",
    }],
    [{
      id: 13,
      label: "Ensure that data is fully secure and updated",
      type: "checkbox",
      name: "ensureDataSecurity",
    }],
    [{
      id: 14,
      label: "Enforce strong security, privacy, and change controls in all operational systems",
      type: "checkbox",
      name: "enforceSecurityControls",
    }],
    [{
      id: 15,
      label: "Ensure maintenance of appropriate documentation relating to support procedures for all IT resources such as hardware, software, and networks",
      type: "checkbox",
      name: "maintainSupportDocumentation",
    }],
    [{
      id: 16,
      label: "Evaluate and select appropriate and cost-effective IT solutions",
      type: "checkbox",
      name: "evaluateSelectITSolutions",
    }],
    [{
      id: 17,
      label: "Serve as a point of escalation for any IT-related issues raised by all BDO employees",
      type: "checkbox",
      name: "pointOfEscalationITIssues",
    }],
    [{
      id: 18,
      label: "Identify and implement opportunities for cost savings and process improvements",
      type: "checkbox",
      name: "identifyCostSavings",
    }],
    [{
      id: 19,
      label: "Any other tasks reasonably assigned to you by management from time to time and within your skill set",
      type: "checkbox",
      name: "otherTasks",
    }],
  ];

  export const MarketinITAssistant = [
    [{
      id: 1,
      label: "Responsible for enhancement and implementation of all business applications",
      type: "checkbox",
      name: "enhancementImplementationBusinessApplications",
    }],
    [{
      id: 2,
      label: "Handle project management for the implementation of software solutions that are specific to business requirements",
      type: "checkbox",
      name: "projectManagementSoftwareSolutions",
    }],
    [{
      id: 3,
      label: "Provide day-to-day support on the applications implemented",
      type: "checkbox",
      name: "dayToDayApplicationSupport",
    }],
    [{
      id: 4,
      label: "Maintain up-to-date records for all licenses and service packs",
      type: "checkbox",
      name: "maintainLicenseRecords",
    }],
    [{
      id: 5,
      label: "Ensure that any new software integration into company systems meets functional requirements, system compliance, and interface specifications",
      type: "checkbox",
      name: "ensureSoftwareIntegration",
    }],
    [{
      id: 6,
      label: "Collaborate with system owners in the testing of new software programs and applications",
      type: "checkbox",
      name: "collaborateSoftwareTesting",
    }],
    [{
      id: 7,
      label: "Install, configure, and deploy new applications, systems software, products, and/or enhancements to existing applications throughout the enterprise",
      type: "checkbox",
      name: "installConfigureDeployApplications",
    }],
    [{
      id: 8,
      label: "Liaise with company’s software suppliers for prompt rectification of any problems or emergencies",
      type: "checkbox",
      name: "liaiseSoftwareSuppliers",
    }],
    [{
      id: 9,
      label: "Maintain and update all IT policies and procedures",
      type: "checkbox",
      name: "maintainUpdateITPolicies",
    }],
  ]

  export const MarketinHRQCManager = [
    [{
      id: 1,
      label: "Handle all HR activities related to Recruitment & Selection; Performance Management; Training & Development; Compensation & Benefits; Employee relations; Employee Grievances",
      type: "checkbox",
      name: "handleHRActivities",
    }],
    [{
      id: 2,
      label: "Develop strategies and plans that contribute to business planning and objectives from an HR perspective",
      type: "checkbox",
      name: "developHRStrategies",
    }],
    [{
      id: 3,
      label: "Work with Top Management to research and analyze data pertaining to employee turnover, training, and employee development",
      type: "checkbox",
      name: "researchAnalyzeHRData",
    }],
    [{
      id: 4,
      label: "Assist in design and development of compensation and benefits programs, policies, and procedures",
      type: "checkbox",
      name: "assistCompensationBenefits",
    }],
    [{
      id: 5,
      label: "Advise management in personnel policy and program matters, or recommend appropriate decisions",
      type: "checkbox",
      name: "adviseManagementPersonnelPolicy",
    }],
    [{
      id: 6,
      label: "Manage relationships with line managers within respective departments to ensure timely and effective support in all areas of Human Resources and Administration",
      type: "checkbox",
      name: "manageRelationshipsLineManagers",
    }],
    [{
      id: 7,
      label: "Interact with Management and employee groups to resolve employee relations issues",
      type: "checkbox",
      name: "resolveEmployeeRelationsIssues",
    }],
    [{
      id: 8,
      label: "Handle all employee litigations",
      type: "checkbox",
      name: "handleEmployeeLitigations",
    }],
    [{
      id: 9,
      label: "Direct performance review of Management process across the company",
      type: "checkbox",
      name: "directPerformanceReview",
    }],
    [{
      id: 10,
      label: "Educate and coach Managers regarding resource policies and practices, monitor resource management practices and provide calibration across functions",
      type: "checkbox",
      name: "educateCoachManagers",
    }],
    [{
      id: 11,
      label: "Work as a partner to proactively provide the tools, knowledge, and assistance which facilitate excellent resource management within the company",
      type: "checkbox",
      name: "provideResourceManagementSupport",
    }],
    [{
      id: 12,
      label: "Maintain integrity of HRMS system (software), ensuring it remains effective and operational at all times",
      type: "checkbox",
      name: "maintainHRMSIntegrity",
    }],
    [{
      id: 13,
      label: "Be the front line interface and control point of HR to designated businesses as well as participate as a member of the senior management team, ensuring HR-related issues are considered in decision-making",
      type: "checkbox",
      name: "frontLineHRInterface",
    }],
  ]

  export const MarketinHrAssistant = [
    [{
      id: 1,
      label: "Assist HR & Administration Manager in preparing the annual recruitment plan and the departmental budget",
      type: "checkbox",
      name: "assistRecruitmentPlanBudget",
    }],
    [{
      id: 2,
      label: "Assist the HR & Administration Manager in design and development of policies and procedures related to HR and Administration",
      type: "checkbox",
      name: "assistHRPoliciesProcedures",
    }],
    [{
      id: 3,
      label: "Suggest improvements in the existing HRMS",
      type: "checkbox",
      name: "suggestHRMSImprovements",
    }],
    [{
      id: 4,
      label: "Compile and maintain records for use in employee benefits administration",
      type: "checkbox",
      name: "maintainBenefitsRecords",
    }],
    [{
      id: 5,
      label: "Coordinate employee performance evaluation process",
      type: "checkbox",
      name: "coordinatePerformanceEvaluation",
    }],
    [{
      id: 6,
      label: "Report and follow up on worker’s compensation claims, maintain records",
      type: "checkbox",
      name: "manageCompensationClaims",
    }],
    [{
      id: 7,
      label: "Prepare payroll input data and transmit to Finance for processing, and maintain all payroll records as required",
      type: "checkbox",
      name: "preparePayrollInput",
    }],
    [{
      id: 8,
      label: "Manage employee resignation, termination and full and final settlement of dues",
      type: "checkbox",
      name: "manageEmployeeExit",
    }],
    [{
      id: 9,
      label: "Manage Government insurance contributions and claims",
      type: "checkbox",
      name: "manageGovernmentInsurance",
    }],
    [{
      id: 10,
      label: "Handle medical insurance",
      type: "checkbox",
      name: "manageMedicalInsurance",
    }],
    [{
      id: 11,
      label: "Handle contract employee management",
      type: "checkbox",
      name: "manageContractEmployees",
    }],
    [{
      id: 12,
      label: "Manage leave and attendance",
      type: "checkbox",
      name: "manageLeaveAttendance",
    }],
    [{
      id: 13,
      label: "Generate reports related to HR budget, leave balance, travel expenses, visa status, insurance claims, etc.",
      type: "checkbox",
      name: "generateHRReports",
    }],
    [{
      id: 14,
      label: "Prepare and maintain documentation related to employee leave, insurance, travel, etc.",
      type: "checkbox",
      name: "maintainEmployeeDocumentation",
    }],
  ]

  export const MarketinFinanceQCManager = [
    [{
      id: 1,
      label: "Take overall responsibility for ensuring that all accounting payments, receipts, nominal ledger, payroll, indemnity, petty cash, and other source records and associated reconciliations are maintained to an acceptable standard and timetable for the financial year",
      type: "checkbox",
      name: "responsibleAccountingRecords",
    }],
    [{
      id: 2,
      label: "Ensure that Firm’s accounts are produced in an appropriate, accurate, and timely manner",
      type: "checkbox",
      name: "ensureAccurateAccounts",
    }],
    [{
      id: 3,
      label: "Ensure that appropriate financial information is produced, including external reports, costing, analysis, and responses to requests for information from budget-holders",
      type: "checkbox",
      name: "produceFinancialInformation",
    }],
    [{
      id: 4,
      label: "Manage the Finance department to ensure that efficient and consistent systems are implemented within internal clients, and that appropriate levels of financial management are achieved",
      type: "checkbox",
      name: "manageFinanceDepartment",
    }],
    [{
      id: 5,
      label: "Ensure that financial information, systems, and procedures are communicated to and understood by other staff required to use them",
      type: "checkbox",
      name: "communicateFinancialSystems",
    }],
    [{
      id: 6,
      label: "Ensure that all book-keeping and accounting tasks are completed to a timetable and a work programme established with the auditors to enable the production of unqualified final accounts",
      type: "checkbox",
      name: "completeAccountingTasks",
    }],
    [{
      id: 7,
      label: "Develop and review annual budgets in consultation with the budget holders (Business Unit / Service Line partners)",
      type: "checkbox",
      name: "developReviewAnnualBudgets",
    }],
    [{
      id: 8,
      label: "Play a full role in the annual budget-setting process in consultation with partners and others, ensuring that partners / directors understand their responsibilities for devolved budgets and the need to achieve budgeted income and maintain financial viability",
      type: "checkbox",
      name: "annualBudgetSettingProcess",
    }],
    [{
      id: 9,
      label: "Provide monthly budget reports and cash flow projections to the Managing Partner and to budget holders, working closely with them to enable them to make accurate decisions",
      type: "checkbox",
      name: "provideBudgetReports",
    }],
  ];
  
  export const MarketinFinanceAccountant = [
    [{
      id: 1,
      label: "Managing and controlling Account payables, handling payments of all types, including suppliers, staff etc.",
      type: "checkbox",
      name: "manageAccountPayables",
    }],
    [{
      id: 2,
      label: "Preparing monthly payroll and release staff salaries",
      type: "checkbox",
      name: "preparePayroll",
    }],
    [{
      id: 3,
      label: "Supervise invoicing and collections",
      type: "checkbox",
      name: "superviseInvoicingCollections",
    }],
    [{
      id: 4,
      label: "Monitor projects/jobs progress and prepare reports for budget versus actual time/effort utilization",
      type: "checkbox",
      name: "monitorProjectsProgress",
    }],
    [{
      id: 5,
      label: "Carry out bank reconciliations",
      type: "checkbox",
      name: "carryOutBankReconciliations",
    }],
    [{
      id: 6,
      label: "Liaising with Auditors during external audits",
      type: "checkbox",
      name: "liaiseWithAuditors",
    }],
    [{
      id: 7,
      label: "Handle employee salary advances",
      type: "checkbox",
      name: "handleSalaryAdvances",
    }],
    [{
      id: 8,
      label: "Release salary slips for the staff",
      type: "checkbox",
      name: "releaseSalarySlips",
    }],
    [{
      id: 9,
      label: "Coordinate with vendors on payments",
      type: "checkbox",
      name: "coordinateVendorPayments",
    }],
    [{
      id: 10,
      label: "Assist Financial Controller with day-to-day activities related to payables",
      type: "checkbox",
      name: "assistFinancialController",
    }],
    [{
      id: 11,
      label: "Handling employee queries related to all payables",
      type: "checkbox",
      name: "handleEmployeeQueries",
    }],
    [{
      id: 12,
      label: "Admin related jobs (writing letters and emails to vendors, customers, bankers, etc.)",
      type: "checkbox",
      name: "adminRelatedJobs",
    }],
    [{
      id: 13,
      label: "Handles GOSI, Medical Insurance, and other payroll-related deductions",
      type: "checkbox",
      name: "handlePayrollDeductions",
    }],
  ];

  export const MarketAdministrativeAssistant = [
    [{
      id: 1,
      label: "Cover reception desk at all times except when out of office or on break",
      type: "checkbox",
      name: "coverReceptionDesk",
    }],
    [{
      id: 2,
      label: "Answer all phone calls in a friendly and professional manner, and forwards all calls to the appropriate staff members promptly and smoothly",
      type: "checkbox",
      name: "answerPhoneCalls",
    }],
    [{
      id: 3,
      label: "Receive all company mail and forwards to appropriate staff member",
      type: "checkbox",
      name: "receiveCompanyMail",
    }],
    [{
      id: 4,
      label: "Prepare outgoing mail and correspondence on request. This may include typing of labels and envelopes, typing/word processing of letters, etc.",
      type: "checkbox",
      name: "prepareOutgoingMail",
    }],
    [{
      id: 5,
      label: "Maintain reception and conference room areas as well as other common areas to ensure a professional and welcoming appearance",
      type: "checkbox",
      name: "maintainCommonAreas",
    }],
    [{
      id: 6,
      label: "Greet all visitors promptly and in a welcoming manner, and act as hostess until appropriate staff are notified of visitor’s arrival. Notifies appropriate office staff of the arrival of any such visitors",
      type: "checkbox",
      name: "greetVisitors",
    }],
    [{
      id: 7,
      label: "Process correspondence/spreadsheets, presentations, or any other documents as requested",
      type: "checkbox",
      name: "processDocuments",
    }],
  ];

  export const MarketOfficeAssistant = [
    [{
      id: 1,
      label: "Timely paying of bills and monthly returns for clients to the revenue authority",
      type: "checkbox",
      name: "payBillsAndReturns",
    }],
    [{
      id: 2,
      label: "Deliver, post and collect mails and parcels and log them ensuring they reach their intended destination",
      type: "checkbox",
      name: "handleMailsAndParcels",
    }],
    [{
      id: 3,
      label: "Support the office and business functions by binding and filing documents",
      type: "checkbox",
      name: "bindAndFileDocuments",
    }],
    [{
      id: 4,
      label: "Perform banking operations for both clients and BDO",
      type: "checkbox",
      name: "performBankingOperations",
    }],
    [{
      id: 5,
      label: "Assist in the daily administration tasks of the office",
      type: "checkbox",
      name: "assistDailyAdminTasks",
    }],
    [{
      id: 6,
      label: "Track and keep record of verifying delivery information, for instance names, addresses, and contact numbers",
      type: "checkbox",
      name: "trackDeliveryInformation",
    }],
    [{
      id: 7,
      label: "Plan and follow the most direct routes for deliveries",
      type: "checkbox",
      name: "planDeliveryRoutes",
    }],
    [{
      id: 8,
      label: "Record package delivery information accurately",
      type: "checkbox",
      name: "recordDeliveryInformation",
    }],
    [{
      id: 9,
      label: "Communicate verbally with clients in a professional manner",
      type: "checkbox",
      name: "communicateWithClients",
    }],
    [{
      id: 10,
      label: "Receive and communicate client complaints to the right office for remedial action",
      type: "checkbox",
      name: "handleClientComplaints",
    }],
    [{
      id: 11,
      label: "Complete paperwork accurately and completely",
      type: "checkbox",
      name: "completePaperwork",
    }],
  ]

  export const MarketOfficeHousekeeping = [
    [{
      id: 1,
      label: "Prepare refreshments for staff and clients",
      type: "checkbox",
      name: "prepareRefreshments",
    }],
    [{
      id: 2,
      label: "Ensure office cleanliness at all times as per company standards",
      type: "checkbox",
      name: "ensureOfficeCleanliness",
    }],
    [{
      id: 3,
      label: "Ensuring the comfort of clients by providing refreshments, beverages, and other special requirements as instructed from time to time",
      type: "checkbox",
      name: "provideClientComfort",
    }],
    [{
      id: 4,
      label: "Assist in filing client financial reports in the correct format as guided",
      type: "checkbox",
      name: "assistFilingReports",
    }],
    [{
      id: 5,
      label: "Ensure files are well organized in the respective cabinets",
      type: "checkbox",
      name: "organizeFiles",
    }],
    [{
      id: 6,
      label: "Photocopy documents as advised and instructed from time to time",
      type: "checkbox",
      name: "photocopyDocuments",
    }],
    [{
      id: 7,
      label: "Assist during repair works in the office premises from time to time",
      type: "checkbox",
      name: "assistRepairWorks",
    }],
    [{
      id: 8,
      label: "Purchase office foodstuffs and consumables and ensure their efficient usage",
      type: "checkbox",
      name: "purchaseOfficeSupplies",
    }],
    [{
      id: 9,
      label: "Relieve the front office team during lunch breaks and other times as required",
      type: "checkbox",
      name: "relieveFrontOffice",
    }],
  ];
  
  export const MarketOfficeDriver= [
    [{
      id: 1,
      label: "Drop and pick staff within a variety of locations as assigned from time to time through different routes",
      type: "checkbox",
      name: "dropPickStaff",
    }],
    [{
      id: 2,
      label: "Ensuring vehicle cleanliness at all times",
      type: "checkbox",
      name: "vehicleCleanliness",
    }],
    [{
      id: 3,
      label: "Complete travel logs and reports as required",
      type: "checkbox",
      name: "completeTravelLogs",
    }],
  ];
  
  


  


  
  
  
  
  
  


  
  
  