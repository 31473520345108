const queryKeys = {
  addEngagement: (queryparams) => ({
    key: `add-engagement`,
    url: `/insyts/engagement?isEmail=${queryparams}`,
  }),
  postEngagementDocument: {
    key: "post-engagement-document",
    url: "/insyts/engagement/document-upload",
  },
  postEngagementDocumentList: {
    key: "post-engagement-category-doc-list",
    url: "/insyts/engagement/category-doc",
  },
  getEngagementDocument: (engagementId) => ({
    key: `get-engagement-document-${engagementId}`,
    url: `/insyts/engagement/document-upload?engagementId=${engagementId}`,
  }),
  updateEngagement: {
    key: "update-engagement",
    url: "/insyts/engagement",
  },
  updateSubcontract: {
    key: "update-engagement",
    url: "/insyts/engagement/subcontractor",
  },
  getEngagements: (queryparams) => ({
    key: "get-engagements",
    url: `/insyts/engagement?${queryparams}`,
  }),
  getClientEngagements: {
    key: `get-client-engagements-list`,
    url: `/insyts/clients/engagement`,
  },
  getClientDetailed: (clientId) => ({
    key: `get-client-detailed-${clientId}`,
    url: `/insyts/clients/engagement?clientId=${clientId}&LastEvaluatedKey=0`,
  }),
  getEngagementById: (engagementId) => ({
    key: `get-engagement-${engagementId}`,
    url: `/insyts/engagement?engagementId=${engagementId}`,
  }),
  getAllClientsList: {
    key: `get-all-clients-list`,
    url: "/insyts/clients/details",
  },
  getAllClientFiltered: {
    key: `get-all-clients-filtered`,
    url: "/insyts/engagementclientlist",
  },
  getClient: (clientId) => ({
    key: `get-client-${clientId}`,
    url: `/insyts/clients/details?clientId=${clientId}&LastEvaluatedKey=0`,
  }),
  addClientSurvey: (engagementId) => ({
    key: "add-client-survey",
    url: "/insyts/engagement/client-survey?engagementId=" + engagementId,
  }),
  addClientSurveyEmail: (engagementId) => ({
    key: "add-client-survey",
    url: `/insyts/engagement/client-survey?isEmail=true&engagementId=${engagementId}`,
  }),
  postTimesheet: {
    key: "post-timesheet",
    url: "/insyts/timesheet",
  },
  updateTimesheet: {
    key: "update-timesheet",
    url: "/insyts/timesheet",
  },
  getTimesheet: {
    key: "get-timesheet",
    url: "/insyts/timesheet", // query Param: week, employeeId, status
  },
  getAdminApprovalTimesheet: (weeks) => ({
    key: `get-admin-approval-timesheet-${weeks}`,
    url: "/insyts/timesheet/pending",
  }),
  patchTodo: {
    key: "patch-todo",
    url: "/insyts/engagement/todo-task?updateType=single",
  },
  updateTodo: {
    key: "update-todo",
    url: "/insyts/engagement/todo-task?updateType=multi",
  },
  uploadTodoImage: {
    key: `upload-todo-image`,
    url: `/insyts/engagement/todo-files`,
  },
  getTodoImages: (todoId) => ({
    key: `get-todo-images-${todoId}`,
    url: `/insyts/engagement/todo-files?todoId=${todoId}`,
  }),
  getTodoList: (engagementId) => ({
    key: `get-engagement-todo-list-${engagementId ?? "nothing"}`,
    url: "insyts/engagement/todo",
  }),
  getTodoById: (engagementId, todoId) => ({
    key: `get-todo-${todoId}`,
    url: `insyts/engagement/todo?LastEvaluatedKey=0&engagementId=${engagementId}&todoId=${todoId}`,
  }),
  deleteTodo: (todoId, engagementId) => ({
    key: `delete-todo-${todoId}`,
    url: `insyts/engagement/todo?todoId=${todoId}&engagementId=${engagementId}`,
  }),
  postFilterTodoList: (engagementId) => {
    return {
      key: `post-filter-todo-list`,
      url:
        "insyts/engagement/todo-filter?engagementId=" +
        engagementId +
        "&LastEvaluatedKey=0",
    };
  },
  addTodoObj: {
    key: "add-todo",
    url: "insyts/engagement/todo",
  },
  getActivityLog: (queryparams) => ({
    key: `get-activity-log-${queryparams}`,
    url: `activitylog${queryparams}`,
  }),
  getResourcePlanDataById: (engagementId, queryparams) => ({
    key: `post-resource-${engagementId}`,
    url: `insyts/resource-plan/employee-list${queryparams}`,
  }),
  updateResource: {
    key: "patch-resource",
    url: "insyts/resource-plan",
  },
  getAllocatedResourceList: (weekList) => ({
    key: `post-resource-list-${weekList}`,
    url: `insyts/resource-plan/employee-list`,
  }),
  getResourePlanningList: (weekList) => ({
    key: `post-resource-planning-list-${weekList}`,
    url: `insyts/resource-plan`,
  }),
  getCapacityPlanningList: (weekList) => ({
    key: `post-capasity-planning-list-${weekList}`,
    url: `insyts/resource-plan`,
  }),
  getRoleDataList: (clientId) => ({
    key: `get-role-list`,
    url: `owner-roles/${clientId}`,
  }),
  getPeopleDataList: (clientId) => ({
    key: `get-people-list`,
    url: `add-users/${clientId}`,
  }),
  getLateCheckinReport: (clientId, uniqueId) => ({
    key: `get-latecheckin-report-${uniqueId}`,
    url: `late-checkin-report/${clientId}`,
  }),
  getAbsentReport: (clientId, uniqueId) => ({
    key: `get-absent-report-${uniqueId}`,
    url: `absent-report/${clientId}`,
  }),
  getEarlyCheckoutReport: (clientId, uniqueId) => ({
    key: `get-early-checkout-report-${uniqueId}`,
    url: `earlycheckout-report/${clientId}`,
  }),
  getAllBillList: (engagementId, queryparams) => ({
    key: `get-billlist-${engagementId}`,
    url: `/insyts/billing${queryparams}`,
  }),
  getClaims: (engagementId, clientId) => ({
    key: `get-claims-${clientId}`,
    url: `/insyts/timesheet/claims?engagementId=${engagementId}&clientId=${clientId}`,
  }),

  getHolidayList: (clientId) => ({
    key: `get-holiday-list-${clientId}`,
    url: `holiday-list/${clientId}`,
  }),
  getLeaveList: (clientId,payload) => ({
    key: `get-leave-list-${payload}`,
    url: `leave-list/${clientId}`,
  }),
  getEngagementDocumentList: (engagementId, queryparams) => ({
    key: `get-engagement-doc-list-${engagementId}`,
    url: `/insyts/engagement/category-doc${queryparams}`,
  }),
  getEngagementDocumentListId: (docId, queryparams) => ({
    key: `get-engagement-doc-list-${docId}`,
    url: `/insyts/engagement/category-doc/download?${queryparams}`,
  }),
  getBillingById: (engagementId) => ({
    key: `get-billlist-${engagementId}`,
    url: `/insyts/billing?engagementid=${engagementId}`,
  }),
  getTimeSheetByengagementId: (engagementId) => ({
    key: `get-timeSheet-by-engagementid-${engagementId}`,
    url: `/insyts/timesheet?engagementId=${engagementId}`,
  }),
  postClaimsAttachment: {
    key: `post-claims-timesheet-attachment`,
    url: `/insyts/timesheet/attachment`,
  },
  getAllEnggagements: {
    key: `get-all-engagements`,
    url: `/insyts/engagement`,
  },
  getClientsList: (office) => ({
    key: `get-clients-${office}`,
    url: `/insyts/clients`,
  }),
  addEngagementAppraisal: {
    key: "add-engagement-appraisal",
    url: "/insyts/engagement/appraisal",
  },
  getBillingApprovals: (userId) => ({
    key: `get-billing-approvals-${userId}`,
    url: `insyts/engagement/billing-approval?userId=${userId}`,
  }),
  getChargeoutRates: {
    key: "Chargeout",
    url: "settings/configuration",
  },
  getHistoryChargeoutRates: (queryparams) => ({
    key: `Chargeouthistory${queryparams}`,
    url: `settings/history?id=${queryparams}`,
}),
  getPFAApproval: (queryparams) => ({
    key: `get-pfa-approval`,
    url: `/insyts/engagement/pfaapproval?${queryparams}`,
  }),
  updatePFAApproval: {
    key: `update-pfa-approval`,
    url: `/insyts/engagement/pfaapproval`,
  },
  getDeclaration: (userId) => ({
    key: `declaration/${userId}`,
    url: `declaration/${userId}`,
  }),
  getRolesList: (clientId) => ({
    key: `get-roles`,
    url: `owner-roles/${clientId}`,
  }),
  getPermissionByName: (clientId, roleId) => ({
    key: `get-roles-${clientId}-${roleId}`,
    url: `permissions/${clientId}/${roleId}`,
  }),
  getMyLeaveList: (clientId,payload) => ({
    key: `get-leave-list/${payload}`,
    url: `leave-list/${clientId}`,
  }),
  getPermissonNameList: (clientId) => ({
    key: `get-roles-${clientId}`,
    url: `permissions/nameList/${clientId}`,
  }),
  getAllDeclaration: (clientId, params) => ({
    key: `get-allDeclaration-${params}`,
    url: `/alldeclaration/${clientId}`,
  }),
  getAllTimesheetReport: (clientId, params) => ({
    key: `get-timesheet-report-${params}`,
    url: `/insytsreport/timesheet-data`,
  }),
  getOnboardingReport: (clientId, params) => ({
    key: `get-onboarding-report-${params}`,
    url: `/insytsreport/onboarding-data`,
  }),
  
};

export default queryKeys;
