import React, { useState } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { CALL_API, CALL_API_UPDATED } from 'common/API';
import { ButtonFilled } from 'reusableComponent/UIButtons';
import { STATUS_CODE } from 'common/Constants';

const FileImport = ({ getClistData }) => {
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);


  const flattenSKey = (obj) => {
    if (obj && typeof obj === 'object') {
      for (const key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
          if ('S' in obj[key]) {
            obj[key] = obj[key]['S'];
          } else if ('M' in obj[key]) {
            obj[key] = obj[key]['M'];
          } else {
            flattenSKey(obj[key]);
          }
        }
      }
    }
    return obj;
  };

  const parseNestedJSON = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        try {
          const parsed = JSON.parse(obj[key]);
          if (typeof parsed === 'object') {
            obj[key] = parseNestedJSON(parsed);
          }
        } catch (e) {
          console.log('Error parsing JSON:', e);
        }
      } else if (typeof obj[key] === 'object') {
        obj[key] = parseNestedJSON(obj[key]);
      }
    }
    return flattenSKey(obj);
  };

  const handleImport = async (data) => {
    setLoading(true);
    const filterData = data?.filter((item)=>item?.clientId !== "");
    try {
      const {code} = await CALL_API(
        `insyts/clients/insert`,
        "post",
        {
          data: filterData,
        }
      );
      if (code === STATUS_CODE.SUCCESS) {
        message.success('File imported successfully');
        getClistData();
      } else {
        message.error('File import failed');
      }
    } catch (error) {
      message.error('An error occurred during import');
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = ({ file }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = e.target.result;
      Papa.parse(csv, {
        header: true,
        complete: (result) => {
          const parsedData = result.data.map(parseNestedJSON);
          setCsvData(transformData(parsedData));
          handleImport(transformData(parsedData));
        },
        error: () => {
          message.error('Error parsing CSV file');
        },
      });
    };
    reader.readAsText(file);
  };

  const props = {
    beforeUpload: (file) => {
      handleUpload({ file });
      return false; // Prevent automatic upload
    },
  };

  const transformData = (data) => {
    return data.map(item => ({
      clientId: item["Client ID"], // Placeholder, replace with appropriate logic if needed
      clientInFormation: {
        "Relationship type": item["Relationship Type"],
        "Addresses": {
          "Address Type": item["Address Type"],
          "Registered Address": item["Registered Address"],
          "Pin No": item["Pin No"],
          "Registration No": item["Registration No"],
          "Postal Code": item["Postal Code"],
          "Use group address": item["Use group address"],
          "Country": item["Country"],
          "Address 1: City": item["Address 1: City"],
          "Registered office Postal address": item["Registered office Postal address"],
          "Operating Countries": item["Operating Countries"],
          "Primary Contact": item["Primary Contact"]
        },
        "Registered Name": item["Registered Name"],
        "Client ID": item["Client ID"],
        "Current Nature of Engagement": item["Current Nature of Engagement"],
        "Email": item["Email"],
        "Description": item["Description"],
        "Managing Office": item["Managing Office"],
        "Influential /Star Customer": item["Influential /Star Customer"],
        "Company Website": item["Company Website"],
        "Incorporation Type": item["Incorporation Type"] || '',
        "Primary Sector": item["Primary Sector"],
        "Primary Industry": item["Primary Industry"]
      },
      "clientOnBoarding": {
        "Conclusion and Acceptance Approval": "",
        "fees": {
          "Is the firm independent with respect to the client regarding the current fee arrangement.": "",
          "In case of a pro-bono engagement, please justify the rationale": "",
          "The agreed principal basis on which the fees shall be charged.": ""
        },
        "close relationships": {
            "can we confirm that no partners or staff engaged in the clients affairs have family connections": ""
        },
        "financial interest": {
            "can we confirm that any partner or closely connected person or member of staff engaged": ""
        },
        "limitation of scope": {
            "are we satisfied that there are no limitations of scope which will be imposed on our work by management": ""
        },
        "eligibility_and_independence": {
            "Ensure that the firm is qualified and is eligible to accept the appointment in terms of the Companies.": "",
            "Ensure that there is compliance with the firm's rules and procedures as described in the BDO.": ""
        },
       "other independence issues": {
            "are we satisfied that there are no other factors which could impair our independence": "",
            "have we satisfied ourselves that there were no other factors existing during the period": ""
        },
        "Relationship/Liaison Manager": item["Relationship / Liaison Manager"] || '',
        "Date": new Date().toLocaleDateString(), // Current date
        "G INVESTIGATION OF INDUSTRY BACKGROUND AND OTHER RISK FACTORS,Note below the results of investigation into the following factors in evaluating the potential business risk to the firm": {
          "is the prospective client publicly held or expecting public offering in the near future": "",
          "the apparent competence of accounting financial and data processing personnel and the adequacy": "",
          "recent continuity of management and its perceived competence": "",
          "the financial needs of the company and its ability to meet those needs in the future": "",
          "is there substantial doubt concerning the prospective clients ability to continue in existence": "",
          "managements attitude to control systems its plan to address potential problems and the viability": "",
          "has the prospective client or any of its director or shareholders been convicted of a crime": "",
          "clients type of business and brief details about the industry": "",
          "are there any negative concerns about the prospect clients marketplace or its directors": "",
          "Has the prospective client’s character, integrity and reputation been discussed with its merchant": "",
          "the rapidity of growth in recent years and the source of such growth": ""
        },
        "Risk Rating Form": {
          "Could be subject of a buy out or takeover": "",
          "Unrealistic reporting deadlines": "",
          "Listed on a stock exchange": "",
          "Weak internal controls and poor management": "",
          "Poor debt position relative to industry norms": "",
          "Domineering chief executive management": "",
          "Is evasive or abusive to BDO East Africa staff": "",
          "Continuing losses and or experiencing liquidity": "",
          "Anticipating a public offering within the next two": "",
          "Involved in high tech emerging or heavily highly": "",
          "Complex accounting principles": "",
          "Client management reputation sullied": "",
          "Engages in offshore transactions in tax havens": "",
          "Aggressive accounting policies undue emphasis": "",
          "Any other Public Interest Entity or Publicly Accountable Entity": "",
          "Has foreign subsidiaries affiliates": "",
          "Disputes unresolved issues with predecessor audit": ""
        },
        "previous_auditors": {
          "Make personal contact with the previous auditor to discuss matters relating to the prior year engage": " ",
          "The previous auditors, to determine if there is any reason why the firm should not accept engagement": "",
          "Request the prospective client to authorize the previous auditors to respond fully to our inquiry": "",
          "The prospective client, as to why (and how frequently) the company is changing auditors": "",
          "Request access to the previous auditors’ working papers as relevant": ""
        },
        "the_prospective_clients_reputation": {
          "Obtain and review the prospective client's financial statements, recent annual returns.": "",
          "Discuss the prospective client with the person who referred them, and note below their comments.": "",
          "Discuss the prospective client with partners and managers in the firm and note below their comments.": "",
          "If the above procedures are inconclusive, contact friends of the firm who may know the Client.": ""
        },
        "litigation": {
          "are we satisfied that there is no litigation taking place or likely to take place between the firm": ""
        },
        "ability_to_serve_the_client": {
          "Ensure that appropriate partners and staff are available to perform the audit, in compliance with": "",
          "Ascertain that the firm has, or can obtain, any specialist expertise required to": ""
        },
        "Signature": "SO",
        "international_referrals": {
          "Has the prospective client's reputation been discussed with the parent engagement partner?": "",
          "Has the prospective client's reputation been discussed with the International Liaison Partner.": "",
          "Have the other member firm's acceptance procedures been considered?.": ""
        },
        "conflicts of interest": {
          "are we satisfied that there are no conflicts of interest": ""
        }
      },
      "cobFormStatus": "",
      "coiCheckProcess": {
        "Has the corporate tree been requested": "",
        "Client Onboarded": "",
        "Has the global check been done": "",
        "CONCLUSION": "",
        "Signature": "",
        "Relationship / Liaison Manager": "",
        "Has the local COI been Done": "",
        "Has AML and worldCheck been done": "",
        "Date": "",
        "Client Acceptance Form Completed": ""
      },
      "createdOn": new Date().toISOString(),
      "email": "biju@acmecorp.com",
      "emsRecords": {
        "SIC Code": "",
        "Referred by Firm": "",
        "Entity Classification": "",
        "International referral": item["International referral"],
        "BDO Firm": "BDO Firm",
        "EMS Manager ID": "",
        "EMS Client ID": item["EMS Client ID"]
      },
      "files": "",
      "generalDetails": {
        "Designation Template": item["Designation Template"],
        "Secretary": item["Secretary"],
        "Size": item["Size"],
        "Inactivation Reason": item['Inactivation Reason'],
        "Business Relationship": item["Business Relationship"],
        "Regulator": item["Regulator"],
        "Type of PIE": item["Type of PIE"],
        "Marketing": {
          "Send Marketing Materials": "",
          "Last Date Included in Campaign": ""
        },
        "Principal Lawyer": item["Principle Lawyer"],
        "Reference # optional": item["Reference # optional"],
        "Principal Banker": item["Principle Banker"],
        "Contact Preferences": {
          "Email": "",
          "Mail": "",
          "Bulk Email": "",
          "Follow Email": "",
          "Phone": "",
          "Contact Method": ""
        },
        "BC Client ID": item["BC Client ID"],
        "Relationship / Liaison Manager's Role": item["Relationship / Liaison Manager's Role"],
        "Strategic Partner": item["Strategic Partner"],
        "Removal / Archive date": "",
        "Entity type": item["Entity type"],
        "Status": "",
        "Relationship / Liaison Manager - ID": item["Relationship / Liaison Manager - ID"],
        "Other Side entity name / Alias name": item["Other Side entity name  / Alias name"],
        "Removal / Archive Reason": "",
        "Country of incorporation": item["Country of incorporation"],
        "Relationship / Liaison Manager": item["Relationship / Liaison Manager"],
        "Review Cycle Period": item["Review Cycle Period"],
        "Designation Level / LOCATION_HIERARCHY": item["Designation Level / LOCATION_HIERARCHY"],
        "Relationship / Liaison Manager's Email": "",
        "Created date": item["Created date"],
        "Risk Classification": item["Risk Classification"],
        "Domicile": item["Domicile"],
        "Business Designation Type": item["Business Designation Type"],
        "Public Interest Entity": item["Public Interest Entity"],
        "BR_FLAG_VALUE": item["BR_FLAG_VALUE"]
      },
      "industry": "",
      "managingOffice": item["Managing Office"],
      "modifiedOn": "",
      "registeredName": item["Registered Name"],
      "statusReason": "Active"
    }));
  };


  return (
    <div>
      <Upload {...props} showUploadList={false} accept=".csv">
        <ButtonFilled loading={loading}>Import</ButtonFilled>
      </Upload>
      {/* <pre>{csvData ? JSON.stringify(csvData, null, 2) : 'No data'}</pre> */}
    </div>
  );
};

export default FileImport;
