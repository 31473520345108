import { useQuery } from 'react-query'

/**
 * Custom hook that returns a query state using the given key.
 *
 * @param {string} key - The key used for the query state.
 * @returns {Object} - The query state object.
 */

export const useQueryState = (key) => {
  return useQuery([key], () => {}, {
    enabled: false,
  })
}
