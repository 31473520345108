import { Button } from 'antd';
import { MANAGERKEYRES, MPS, NoDataKey, PartnerDirecter, QCAssistantAssociates, QCmanager, RCEO, Seniors } from 'common/Constants';
import { GrowthAssociate, GrowthManager, GrowthMPS, GrowthPartner, GrowthRCEO, GrowthSeniors } from 'common/KeyResopnse/KeyResGrowth';
import { MarketinFinanceAccountant, MarketinFinanceQCManager, MarketinHrAssistant, MarketinHRQCManager, MarketinITAssistant, MarketOfficeAssistant, MarketOfficeDriver, MarketOfficeHousekeeping, OptionalAssociate, optionalITQCManager, OptionalManager1, OptionalManager2, OptionalManager3, OptionalManager4, OptionalManager5, OptionalMPS, OptionalPartner1, OptionalPartner2, OptionalPartner3, OptionalRCEO, OptionalSenior } from 'common/KeyResopnse/KeyResOptional';
import { MarketFocusAssistant, MarketFocusManager, MarketFocusMPS, MarketFocusPartner, MarketFocusQCManager, MarketFocusRCEO, MarketFocusSenior, MarketingAssistant, MarketinQCManager } from 'common/KeyResopnse/KeyResponseMarketFocus';
import React, { useState } from 'react'
import styled from 'styled-components';
import GridBuilder from 'UIBuilderEngine/GridBuilder'

const StyledWrap = styled.div`
.option_div{
    box-shadow: 0px 3px 6px #00000015;
    border: 1px solid #EDEDED;
    border-radius: 5px;
}
.header_div{
    padding: 10px 20px;
    background: #F6F6F6 0% 0% no-repeat padding-box;
}
.header_div1{
    padding: 10px 20px;
}
.sub_div{
    padding: 10px 20px;
}
    .key_select_input_wrap{
       width: 50%;
    }
`;

const ManagingKeySelects = [
    'Practice / Centres of Excellence- RCEO',
    'Practice / Centres of Excellence- MPs',
    'Practice / Centres of Excellence- Partners / Directors',
    'Practice / Centres of Excellence- Managers',
    'Practice / Centres of Excellence- Seniors',
    'Practice / Centres of Excellence- Associates',
    'Marketing- QC Manager',
    'Marketing- Marketing Assistant',
    'IT- QC Manager',
    'IT- IT Assistant',
    'HR - QC Manager',
    'HR - HR Assistant',
    'Finance - QC Manager',
    'Finance - Accountant',
    'Services- Administrative Assistant & Receptionist',
    'Services- Office Assistant - Messenger',
    'Services- Office Assistant - Housekeeping',
    'Services- Driver'
]
const KeyResponsibilities = ({open, setOpen, setKeyResp, keyresp}) => {
    const [selectKeyRes, setSelectKeyRes] = useState("")
    // const handleFormChange = (e) => {
    //     setSelectKeyRes([e.target.name]: e.target.value,);
    //   };
      const handleSelectChange = (e, name) => {
        setSelectKeyRes({
          [name]: e,
        });
      };

        const totalData = {
        
                totalpages: 1,
                pages: [
                {
                    modalView: false,
                    colSpan: [24, 24, 24],
                    fields: [
                    [
                        {
                            id: 1,
                            label: "Select Job grade:",
                            type: "select",
                            name: "jobgrade",
                            Placeholder: "Enter",
                            options: ManagingKeySelects,
                        }
                    ],
                    ],
                },
                ],
            };
        const DropDownData = {
        
                totalpages: 1,
                pages: [
                {
                    modalView: false,
                    colSpan: [24, 24, 24],
                    fields: selectKeyRes.jobgrade === "Practice / Centres of Excellence- RCEO" ? RCEO
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- MPs" ? MPS
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Partners / Directors" ? PartnerDirecter
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Managers" ? MANAGERKEYRES
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Seniors" ? Seniors
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Associates" ? QCAssistantAssociates
                        : selectKeyRes.jobgrade === "Marketing- QC Manager" ? QCmanager
                        : selectKeyRes.jobgrade === "Marketing- Marketing Assistant" ? QCAssistantAssociates
                        : selectKeyRes.jobgrade === "IT- QC Manager" ? QCmanager
                        : selectKeyRes.jobgrade === "IT- IT Assistant" ? QCAssistantAssociates
                        : selectKeyRes.jobgrade === "HR - QC Manager" ? QCmanager
                        : selectKeyRes.jobgrade === "HR - HR Assistant" ? QCAssistantAssociates
                        : selectKeyRes.jobgrade === "Finance - QC Manager" ? QCmanager
                        : selectKeyRes.jobgrade === "Finance - Accountant" ? QCAssistantAssociates
                        : selectKeyRes.jobgrade === "Services- Administrative Assistant & Receptionist" ? QCAssistantAssociates
                        : selectKeyRes.jobgrade === "Services- Office Assistant - Messenger" ? QCAssistantAssociates
                        : selectKeyRes.jobgrade === "Services- Office Assistant - Housekeeping" ? QCAssistantAssociates
                        : selectKeyRes.jobgrade === "Services- Driver" ? QCAssistantAssociates
                        : [[
                            {
                                id: 1,
                                label: "No Data",
                                type: "checkbox",
                                name: "noData",
                            }
                        ]],
                    },
                ],
            };
        const DropDownData1 = {
        
            totalpages: 1,
            pages: [
            {
                modalView: false,
                colSpan: [24, 24, 24],
                fields: selectKeyRes.jobgrade === "Practice / Centres of Excellence- RCEO" ? MarketFocusRCEO
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- MPs" ? MarketFocusMPS
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Partners / Directors" ? MarketFocusPartner
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Managers" ? MarketFocusManager
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Seniors" ? MarketFocusSenior
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Associates" ? MarketFocusAssistant
                        : selectKeyRes.jobgrade === "Marketing- QC Manager" ? MarketFocusQCManager
                        : selectKeyRes.jobgrade === "Marketing- Marketing Assistant" ? MarketingAssistant
                        : selectKeyRes.jobgrade === "IT- QC Manager" ? NoDataKey
                        : selectKeyRes.jobgrade === "IT- IT Assistant" ? NoDataKey
                        : selectKeyRes.jobgrade === "HR - QC Manager" ? MarketinQCManager
                        : selectKeyRes.jobgrade === "HR - HR Assistant" ? NoDataKey
                        : selectKeyRes.jobgrade === "Finance - QC Manager" ? QCmanager
                        : selectKeyRes.jobgrade === "Finance - Accountant" ? NoDataKey
                        : selectKeyRes.jobgrade === "Services- Administrative Assistant & Receptionist" ? NoDataKey
                        : selectKeyRes.jobgrade === "Services- Office Assistant - Messenger" ? NoDataKey
                        : selectKeyRes.jobgrade === "Services- Office Assistant - Housekeeping" ? NoDataKey
                        : selectKeyRes.jobgrade === "Services- Driver" ? NoDataKey
                        : NoDataKey,
            },
            ],
        }
          const DropDownData2 = {
       
            totalpages: 1,
            pages: [
              {
                modalView: false,
                colSpan: [24, 24, 24],
                fields: selectKeyRes.jobgrade === "Practice / Centres of Excellence- RCEO" ? GrowthRCEO
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- MPs" ? GrowthMPS
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Partners / Directors" ? GrowthPartner
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Managers" ? GrowthManager
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Seniors" ? GrowthSeniors
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Associates" ? GrowthAssociate
                        : selectKeyRes.jobgrade === "Marketing- QC Manager" ? NoDataKey
                        : selectKeyRes.jobgrade === "Marketing- Marketing Assistant" ? NoDataKey
                        : selectKeyRes.jobgrade === "IT- QC Manager" ? NoDataKey
                        : selectKeyRes.jobgrade === "IT- IT Assistant" ? NoDataKey
                        : selectKeyRes.jobgrade === "HR - QC Manager" ? NoDataKey
                        : selectKeyRes.jobgrade === "HR - HR Assistant" ? NoDataKey
                        : selectKeyRes.jobgrade === "Finance - QC Manager" ? NoDataKey
                        : selectKeyRes.jobgrade === "Finance - Accountant" ? NoDataKey
                        : selectKeyRes.jobgrade === "Services- Administrative Assistant & Receptionist" ? NoDataKey
                        : selectKeyRes.jobgrade === "Services- Office Assistant - Messenger" ? NoDataKey
                        : selectKeyRes.jobgrade === "Services- Office Assistant - Housekeeping" ? NoDataKey
                        : selectKeyRes.jobgrade === "Services- Driver" ? NoDataKey
                        : NoDataKey,
              },
            ],
          };
          const DropDownData3 = {
       
            totalpages: 1,
            pages: [
              {
                modalView: false,
                colSpan: [24, 24, 24],
                fields: selectKeyRes.jobgrade === "Practice / Centres of Excellence- RCEO" ? OptionalRCEO
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- MPs" ? OptionalMPS
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Partners / Directors" ? OptionalPartner1 
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Managers" ? OptionalManager1
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Seniors" ? OptionalSenior
                        : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Associates" ? OptionalAssociate
                        : selectKeyRes.jobgrade === "Marketing- QC Manager" ? NoDataKey
                        : selectKeyRes.jobgrade === "Marketing- Marketing Assistant" ? NoDataKey
                        : selectKeyRes.jobgrade === "IT- QC Manager" ? optionalITQCManager
                        : selectKeyRes.jobgrade === "IT- IT Assistant" ? MarketinITAssistant
                        : selectKeyRes.jobgrade === "HR - QC Manager" ? MarketinHRQCManager
                        : selectKeyRes.jobgrade === "HR - HR Assistant" ? MarketinHrAssistant
                        : selectKeyRes.jobgrade === "Finance - QC Manager" ? MarketinFinanceQCManager
                        : selectKeyRes.jobgrade === "Finance - Accountant" ? MarketinFinanceAccountant
                        : selectKeyRes.jobgrade === "Services- Administrative Assistant & Receptionist" ? MarketOfficeAssistant
                        : selectKeyRes.jobgrade === "Services- Office Assistant - Messenger" ? MarketOfficeAssistant
                        : selectKeyRes.jobgrade === "Services- Office Assistant - Housekeeping" ? MarketOfficeHousekeeping
                        : selectKeyRes.jobgrade === "Services- Driver" ? MarketOfficeDriver
                        : NoDataKey,
              },
            ],
          };
          const DropDownData4 = {
       
            totalpages: 1,
            pages: [
              {
                modalView: false,
                colSpan: [24, 24, 24],
                fields: selectKeyRes.jobgrade === "Practice / Centres of Excellence- Partners / Directors" ? OptionalPartner2 
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Managers" ? OptionalManager2 : [[]], 
              },
            ],
          };
          const DropDownData5 = {
            totalpages: 1,
            pages: [
              {
                modalView: false,
                colSpan: [24, 24, 24],
                fields: selectKeyRes.jobgrade === "Practice / Centres of Excellence- Partners / Directors" ? OptionalPartner3 
                : selectKeyRes.jobgrade === "Practice / Centres of Excellence- Managers" ? OptionalManager3 : [[]],
              },
            ],
          };

          const DropDownData6 = {
            totalpages: 1,
            pages: [
              {
                modalView: false,
                colSpan: [24, 24, 24],
                fields: selectKeyRes.jobgrade === "Practice / Centres of Excellence- Managers" ? OptionalManager4 : [[]],
              },
            ],
          };
          const DropDownData7 = {
            totalpages: 1,
            pages: [
              {
                modalView: false,
                colSpan: [24, 24, 24],
                fields: selectKeyRes.jobgrade === "Practice / Centres of Excellence- Managers" ? OptionalManager5 : [[]],
              },
            ],
          };



  return (
    <StyledWrap>
        <h6>Select Key Responsibilities</h6>
        <div className='d-flex justify-content-between'>
            <div className='key_select_input_wrap'>
                <GridBuilder 
                    formStructureJson={totalData} 
                    // handleChange={handleFormChange}
                    handleSelectChange={handleSelectChange}
                    initialValues={selectKeyRes}
                />
            </div>
            <div>
                <Button onClick={() => setKeyResp(false)}>Proceed</Button>
            </div>
        </div>
        {selectKeyRes !== "" && 
            <div className='option_div'>
                <div className='header_div'>Leadership Excellence /Employee Excellence & BDO Relationships:</div>
                <div className='sub_div'>
                    <GridBuilder formStructureJson = {DropDownData}/>
                </div>
                <div className='header_div'>Market Focus/ Client Centricity:</div>
                <div className='sub_div'>
                    <GridBuilder formStructureJson = {DropDownData1}/>
                </div>
                <div className='header_div'>Growth & Profitability:</div>
                <div className='sub_div'>
                    <GridBuilder formStructureJson = {DropDownData2}/>
                </div>
                <div className='header_div'>Operational Excellence:</div>
                <div className='sub_div'>
                    <GridBuilder formStructureJson = {DropDownData3}/>
                </div>
                {selectKeyRes.jobgrade === "Practice / Centres of Excellence- Partners / Directors" && 
                    <>
                        <u><div className='header_div1'>Audit Manager</div></u>
                        <div className='sub_div'>
                            <GridBuilder formStructureJson = {DropDownData4}/>
                        </div>
                        <u><div className='header_div1'>Tax Partner / Director</div></u>
                        <div className='sub_div'>
                            <GridBuilder formStructureJson = {DropDownData5}/>
                        </div>
                    </>
                }
                {selectKeyRes.jobgrade === "Practice / Centres of Excellence- Managers" &&
                    <>
                        <u><div className='header_div1'>Audit Manager</div></u>
                        <div className='sub_div'>
                            <GridBuilder formStructureJson = {DropDownData4}/>
                        </div>

                        <u><div className='header_div1'>Tax Manager</div></u>
                        <div className='sub_div'>
                            <GridBuilder formStructureJson = {DropDownData5}/>
                        </div>

                        <u><div className='header_div1'>Advisory Manager</div></u>
                        <div className='sub_div'>
                            <GridBuilder formStructureJson = {DropDownData6}/>
                        </div>

                        <u><div className='header_div1'>BSO Manager</div></u>
                        <div className='sub_div'>
                            <GridBuilder formStructureJson = {DropDownData7}/>
                        </div>
                    </>
                }
            </div>
        }
    </StyledWrap>
  )
}

export default KeyResponsibilities