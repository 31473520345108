import { NON_EMPTY_REGEX, EMAIL_REGEX } from "common/Constants";
import emailList from "../../organisms/AddEmployee";

const validateEmail = (rule, value) => {
  if (value && emailList.includes(value.trim())) {
    return Promise.reject("This Email is already registered");
  }
  return Promise.resolve();
};

const GenderList = ["male", "female"];

export const getSelfAssessmentJson = (data) => {
  const AddSelfAssessmentForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [12, 12, 12],
        fields: [
          [
            {
              id: 1,
              label: "Name",
              type: "text",
              name: "name",
              Placeholder: "Enter the name",
              //   value: data["user_id"],
              //   validationRules: [
              //     {
              //       required: true,
              //       message: `Name is required`,
              //     },
              //   ],
            },
            {
              id: 2,
              label: "Staff Level",
              type: "text",
              name: "staffLevel",
              Placeholder: "Enter the staff level",
              //   value: data["full_name"],
              //   validationRules: [
              //     {
              //       required: true,
              //       message: `Staff level is required`,
              //     },
              //   ],
            },
          ],
          [
            {
              id: 3,
              label: "Firm",
              type: "text",
              name: "firm",
              Placeholder: "Enter the firm",
              //   value: data["password"],
              //   validationRules: [
              //     {
              //       required: true,
              //       message: `Firm is required`,
              //     },
              //   ],
            },
            {
              id: 3,
              label: "Organisational Level",
              type: "text",
              name: "organisationalLevel",
              Placeholder: "Enter the organisational level",
              value: "",
              options: GenderList,
              //   validationRules: [
              //     {
              //       required: true,
              //       message: `Organisational level is required`,
              //     },
              //   ],
            },
          ],
          [
            {
              id: 5,
              label: "Year",
              type: "text",
              name: "Year",
              Placeholder: "Select the Year",
              //   value: data["organization"],
            },
            {
              id: 6,
              label: "Competency/Quality Circle",
              type: "text",
              name: "CompetencyQualityCircle",
              Placeholder: "Enter your competency/quality circle",
              //   value: data["help"],
              //   validationRules: [
              //     {
              //       required: true,
              //       message: `Competency/Quality Circle field is required`,
              //     },
              //   ],
            },
          ],
          [
            {
              id: 7,
              label: "Period",
              type: "text",
              name: "period",
              Placeholder: "Enter your period",
            },
            {
              id: 8,
              label: "",
              type: <></>,
              name: "",
              Placeholder: "",
            },
          ],
        ],
      },
    ],
  };

  return {
    AddSelfAssessmentForm,
  };
};
