import styled from "styled-components";
import PeoplePlanning from "./PeoplePlanning";
import { getUserList } from "redux/selectors";
import { connect } from "react-redux";

const ResourcePlanning = ({ userList }) => {
  return (
    <StyleWrapper>
      <PeoplePlanning userList={userList} />
    </StyleWrapper>
  );
};

const mapStateToProps = (state) => ({
  userList: getUserList(state),
});

export default connect(mapStateToProps, null)(ResourcePlanning);

const AvailableColor = "#EDFFF7";
const BookedColor = "#FFD3D3";

const StyleWrapper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;

  .header-cell {
    background: #ededed;
  }

  .MuiTableCell-body {
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-bottom: none !important;
  }

  .data-cell {
    border-left: 1px solid rgba(224, 224, 224, 1);
  }
  .data-cell-header {
    border-left: 1px solid rgba(224, 224, 224, 1);
    background: #fff;
  }

  .MuiTableCell-head {
    padding: 10px !important;
  }

  .role {
    font-size: 10px;
    opacity: 0.4;
  }

  .profile-area {
    display: flex;
    align-items: center;
    gap: 4px;

    .profile-img img {
      border-radius: 50%;
      height: 15px;
      width: 15px;
    }

    .profile-name {
      padding-top: 4px;
    }
  }

  .percentagedata-cell {
    background-color: #e4fae7;
    padding: 10px;
  }

  .weekend-cell {
    background-color: #e6e6e6;
  }

  .pointer {
    cursor: pointer;
    border: 1px solid rgba(224, 224, 224, 1);
  }

  .table-header {
    display: flex;
    gap: 10px;
  }

  .arrow-box {
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 2px;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .pagination {
    gap: 10px;
    justify-content: end;
  }

  .table-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .null-cell {
    background-color: #ffaaaa;
  }

  #EmployeeLetter {
    width: 39px;
    height: 39px;
    border-radius: 50%;
    background: #512da8;
    font-size: 18px;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  @media screen and (max-width: 600px) {
    .table-header {
      flex-direction: column;
      align-items: stretch;
    }
  }

  .data-cell-value {
    background: #aaddff;
    padding: 5px 5px;
    border-radius: 5px;
    width: 100%;
    /* border-left: 1px solid rgba(224, 224, 224, 1); */
  }

  .add-icon {
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .addclient {
    color: #5b86e5;
    cursor: pointer;
    border: 1px solid rgba(224, 224, 224, 1);
  }
  .custom-filter-btn {
    margin-bottom: 0px !important;
  }
  .legend {
    display: flex;
    gap: 0.5rem;

    .legend-box {
      width: 20px;
      height: 20px;
      border-radius: 5px;

      &.available {
        background: ${AvailableColor};
      }
      &.booked {
        background: ${BookedColor};
      }
    }
  }
`;
