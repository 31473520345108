import { getEditResourceDateJson } from "JsonFormat/EditResourseDateJson";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import React, { useState } from "react";
import { StyledAvatar } from "./PeoplePlanning";
import { startCase } from "lodash";
import { Divider, message } from "antd";
import moment from "moment";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import { fontSize } from "@mui/system";

function AddDateModal({ goBack, Editdata, EditDate, selectedDate, getData }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const { addEndDateForm } = getEditResourceDateJson(data, EditDate);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = () => {
    goBack();
  };
  const handleSubmitForm = async (form) => {
    try {
      setLoading(true);
      const endDate = moment(selectedDate).add(form?.endDay - 1, "days");
      const paylaod = {
        userId: Editdata?.userId,
        employeeName: Editdata?.employeeName,
        employeeId: Editdata?.employeeId,
        accessRole: Editdata?.accessRole,
        projectRole: Editdata?.projectRole,
        actualBookedStartDate: moment(selectedDate).format("YYYY-MM-DD"),
        actualBookedEndDate: moment(endDate).format("YYYY-MM-DD"),
        engagementId: Editdata?.engagementId,
        engagementName: Editdata?.engagementName,
        effort: "",
        remarks: Editdata?.remarks,
        personDay: form?.endDay,
        resourceExtRate: "",
        status: "Assigned",
        engagementStartDate: Editdata?.engagementStartDate,
        engagementEndDate: Editdata?.engagementEndDate,
        weekList: [
          {
            weekId: Editdata?.weekId,
            bookedStartDate: moment(selectedDate).format("YYYY-MM-DD"),
            bookedEndDate: moment(endDate).format("YYYY-MM-DD"),
          },
        ],
      };
      const { code } = await CALL_API(`insyts/engagement/resource-plan`, "post", paylaod);
      setLoading(false);
      if(code === STATUS_CODE.SUCCESS){
        message.success("Successfully Added")
        getData()
        goBack()
      }
    } catch (error) {
      message.error("Something went wrong. Please reach out to our technical team.")
      setLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const roleStyle = { fontSize: "12px", color:"#a3a2a2"}

  return (
    <div className="px-3">
      <div className="d-flex gap-2">
        {Editdata?.profileurl ? (
          <StyledAvatar
            src={Editdata?.profileurl}
            size="default"
          ></StyledAvatar>
        ) : (
          <StyledAvatar size="default">
            {startCase(Editdata?.employeeName).charAt(0)}
          </StyledAvatar>
        )}
        <div>
          <span>{Editdata?.employeeName}</span>
          <br/>
          <span style={roleStyle}>{Editdata?.projectRole}</span>
        </div>
        
      </div>
      <Divider />
      <h6>{Editdata?.engagementName}</h6>
      <GridBuilder
        formStructureJson={addEndDateForm}
        handleChange={handleFormChange}
        formSubmit={handleSubmitForm}
        loading={loading}
        handleClick={handleClick}
      />
    </div>
  );
}

export default AddDateModal;
