import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col, Spin, message, Card, Popover, Modal } from "antd";

// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getClientid,
  getMenuList,
  getUserList,
  getUserInfo,
} from "redux/selectors";
import styled from "styled-components";
import Dropzone from "molecules/Dropzone";
import COLORS from "common/Colors";
import { get, map, includes, find } from "lodash";
import Document from "molecules/Document";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import { DEVICE } from "common/Device";
import Styled from "styled-components";
import folder from "assets/folder.svg";
import DocumentTableView from "./DocumentFolders/DocumentTableView";
import download from "downloadjs";

const StyledDocumentPageTitle = styled.h2`
  font-weight: 500;
  margin-bottom: 0px;
`;

const StyleDocumentRow = styled(Row)`
  padding-top: 20px;
`;

const StyledDocumentDiv = styled.div`
  border: solid 1px ${COLORS.GREY};
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  .nodata {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      letter-spacing: 2px;
      font-weight: bold;
      color: ${COLORS.GREY};
    }
  }
  ${({ height }) =>
    height &&
    `height: ${height}px; min-height: ${height}px; max-height: ${height}px; overflow: auto;`};
  @media ${DEVICE.tablet} {
    margin-top: 20px;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`;

const StyledDocumentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

function MyDocuments({ userRoleId, ClientId, menuList, userList, userInfo }) {
  const [documentList, setDocumentList] = useState([]);
  const [height, setHeight] = useState(200);
  const [fileName, setFileName] = useState("");
  const [isUploading, toggleUploading] = useState(false);
  const [isLoadingDocuments, toggleLoadingDocuments] = useState(false);
  const [showComponent, setShowComponent] = useState(1);
  const [selectedItem, setSelectedItem] = useState({});

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const userDetails = userList?.find(
    (item) => item?.userid === userInfo?.userid
  );

  const IdentityInfoList = [
    {
      type: "NSSF",
      details: userDetails?.nssf || "-",
      document:
        find(userDetails?.identityinformation, { type: "nssf" })
          ?.documentName || "-",
      documentid:
        find(userDetails?.identityinformation, { type: "nssf" })?.documentId ||
        "-",
    },
    {
      type: "National ID",
      details: userDetails?.national_id || "-",
      document:
        find(userDetails?.identityinformation, { type: "nationalid" })
          ?.documentName || "-",
      documentid:
        find(userDetails?.identityinformation, { type: "nationalid" })
          ?.documentId || "-",
    },
  ];

  const [previewType, setPrreviewType] = useState("");
  const [previewURL, setPreviewURL] = useState("");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const handleMenuClick = async (id, type, document) => {
    // setPrreviewType();
    const preview = document?.split(".")?.pop();
    toggleLoadingDocuments(true);
    const { code, url } = await CALL_API(`document-list/${ClientId}`, "post", {
      method: "preview-identity-document",
      documentId: id,
      identityName: type,
      userId: userDetails?.userid,
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (
        preview === "jpg" ||
        preview === "png" ||
        preview === "jpeg" ||
        preview === "svg" ||
        preview === "pdf"
      ) {
        setIsPreviewModalOpen(true);
        setPreviewURL(url);
      } else {
        download(url);
      }
    } else {
      message.error("Something went wrong!! Please try again.");
    }
    toggleLoadingDocuments(false);
  };

  const IdentityInfoColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    //     {
    //       title: "Action",
    //       dataIndex: "type",
    //       key: "type",
    //       render: (type) => {
    //         return (
    //           <Upload
    //             showUploadList={false}
    //             customRequest={({ file, onSuccess }) => {
    //               const reader = new FileReader();
    //               reader.readAsDataURL(file);
    //
    //               reader.onload = () => {
    //                 const base64Data = reader.result.split(",")[1];
    //
    //                 onSuccess();
    //                 setFileDetails({
    //                   name: file.name,
    //                   type: file.type,
    //                   content: base64Data,
    //                   identityName: type === "NSSF" ? "nssf" : "nationalid",
    //                 });
    //               };
    //
    //               reader.onerror = (error) => {
    //                 console.error("File read error:", error);
    //               };
    //             }}
    //             onChange={(info) => {
    //               if (info.file.status === "done") {
    //                 // handleFileUpload(info)
    //                 if (info.fileList.length > 0) {
    //                   const file = info.fileList[0].originFileObj;
    //
    //                   const reader = new FileReader();
    //
    //                   reader.onload = async (event) => {
    //                     const base64Data = event.target.result;
    //                     setIsDocumentupload(true);
    //                     // Log the information
    //                   };
    //
    //                   reader.readAsDataURL(file);
    //                 }
    //               } else if (info.file.status === "error") {
    //               }
    //             }}
    //           >
    //             <ButtonFilled>Attach file</ButtonFilled>
    //           </Upload>
    //         );
    //       },
    //     },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      render: (document, record) => {
        if (document !== "-") {
          return (
            <button
              className="bg-transparent text-info border-0"
              onClick={() => {
                setPrreviewType(document?.split(".")?.pop());
                handleMenuClick(
                  record?.documentid,
                  "identityinformation",
                  document
                );
              }}
            >
              {document}
            </button>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  useEffect(() => {
    getDocumentList();
  }, []);

  const getDocumentList = async () => {
    toggleLoadingDocuments(true);

    let documentList = [
      {
        label: "Employment Contract",
        value: "employmentcontract",
        type: "folder",
      },
      {
        label: "Identity Information",
        value: "identityinformation",
        type: "folder",
      },
      {
        label: "Performance Contract",
        value: "performancecontract",
        type: "folder",
      },
      {
        label: "Performance Appraisal",
        value: "performanceappraisal",
        type: "folder",
      },
    ];

    const { list } = await CALL_API(`document-list/${ClientId}`, "post", {
      method: "document-list",
    });
    console.log("list---->", list)
    documentList = [...documentList, ...list];
    setDocumentList(documentList);
    toggleLoadingDocuments(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = async function (e) {
        toggleUploading(true);
        if (
          includes(
            [
              "jpg",
              "png",
              "x-icon",
              "jpeg",
              "svg",
              "svg+xml",
              "xls",
              "xlsx",
              "pdf",
              "csv",
              "doc",
              "docx",
              "ms-doc",
              "txt",
              "ppt",
              "avi",
              "zip",
              "mov",
              "plain",
              "vnd.ms-excel",
              "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "pdf",
              "csv",
              "msword",
              "vnd.openxmlformats-officedocument.wordprocessingml.document",
              "vnd.ms-powerpoint",
              "vnd.openxmlformats-officedocument.presentationml.presentation",
            ],
            get(file, "type", "").split("/")[1]
          )
        ) {
          const { code } = await CALL_API(
            `upload-document/${ClientId}`,
            "post",
            {
              name: get(file, "name", ""),
              type: get(file, "type", ""),
              content: e.target.result,
              permissionType: "non-confidential",
            }
          );
          if (code === STATUS_CODE.SUCCESS) {
            message.success("Document uploaded");
            toggleUploading(false);
            getDocumentList();
          } else {
            message.error("You can only upload files up to 10MB in size.");
          }
        } else message.error("Invalid file format");
        toggleUploading(false);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const deleteDocument = async (id) => {
    const response = await CALL_API(
      `upload-document/${ClientId}/${id}`,
      "delete",
      {
        // documentId : id, //Commented Code so that it's used in future
        status_id: 0,
      }
    );
    message.success("Document has been Deleted");
    getDocumentList();
  };

  useEffect(() => {
    setHeight(
      window.innerHeight -
        document.querySelector(".ant-layout-header").getBoundingClientRect()
          .height -
        100
    );

    window.uploadDocumentFromApp = async (base64, name, MIMEtype) => {
      toggleUploading(true);
      const { code } = await CALL_API(`upload-document/${ClientId}`, "post", {
        name,
        type: MIMEtype,
        content: base64,
        permissionType: "non-confidential",
      });
      if (code === STATUS_CODE.SUCCESS) {
        message.success("Document uploaded");
        toggleUploading(false);
        getDocumentList();
      } else {
        message.error("Something went wrong!! Please try again.");
      }
    };
  }, []);

  const uploadDocument = () => {
    if (navigator.userAgent === "application") {
      window.Print.postMessage("upload-document");
    }
  };

  const handleFolderClick = (item) => {
    setShowComponent(2);
    setSelectedItem(item);
  };

  const goBack = () => {
    setShowComponent(1);
  };

  const Folder = useMemo(() => {
    return React.memo(({ item, description = false }) => (
      <Wrapper>
        <Card
          key={item}
          hoverable
          cover={
            <CardImage>
              <img alt="folder-img" src={folder} />
            </CardImage>
          }
          bordered={true}
          className="cards"
          onClick={() => handleFolderClick(item)}
        >
          {/* <Meta
              title={<small>{item.label}</small>}
              // description={`Created on ${moment(item?.insertedAt).format("DD MMM YYYY")}`}
            /> */}
          <span className="label">{item.label}</span>
        </Card>
      </Wrapper>
    ));
  }, []);

  if (showComponent === 2) {
    return (
      <>
        <DocumentTableView
          dataSource={IdentityInfoList}
          IdentityInfoColumns={IdentityInfoColumns}
          goBack={goBack}
          selectedItem={selectedItem}
          filteruser={userDetails}
          fetchMasterData={() => {}}
          clientId={ClientId}
          onlyView={true}
        />

        <StyledDocumentModal
          centered={true}
          visible={isPreviewModalOpen}
          footer={false}
          keyboard={false}
          width="100%"
          height="100vh"
          onOk={() => setIsPreviewModalOpen(false)}
          onCancel={() => setIsPreviewModalOpen(false)}
        >
          <Spin spinning={isLoadingDocuments}>
            {previewType === "jpg" ||
            previewType === "png" ||
            previewType === "jpeg" ||
            previewType === "svg" ? (
              <div className="img">
                <img src={previewURL} className="img_fit" alt="Preview" />
              </div>
            ) : (
              <embed
                title="PDF Viewer"
                src={previewURL}
                type="application/pdf"
                width="100%"
                height="600px"
                className="pdf-viewer-embed px-5"
              />
            )}
          </Spin>
        </StyledDocumentModal>
      </>
    );
  }

  return (
    <>
      <StyledDocumentPageTitle>My Documents</StyledDocumentPageTitle>
      <StyleDocumentRow gutter={20}>
        <Col md={12} sm={24} xs={24} onClick={uploadDocument}>
          <Spin spinning={isUploading}>
            <Dropzone
              onDrop={onDrop}
              accept={
                ("image/*,.png,.jpeg",
                ".jpg,.svg,.xls,.xlsx,.pdf,.csv,.doc,.docx,.txt,.ppt,.avi,.zip,.mov")
              }
              fileName={fileName}
              access={Menulist.find(
                (item) =>
                  item.title === "My Documents" && item.permission.create
              )}
            />
          </Spin>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Spin spinning={isLoadingDocuments}>
            <StyledDocumentDiv height={height}>
              {documentList?.length > 0 ? (
                <Row gutter={24}>
                  {map(documentList, (item, index) => (
                    <Col md={12} lg={8} sm={12} xs={24} key={index}>
                      <StyledDocumentContainer>
                        {item?.type !== "folder" ? (
                          <Document
                            id={item?.documentId}
                            name={item?.documentName}
                            staffName=""
                            uploadTime={item?.createdon}
                            deleteDocument={deleteDocument}
                          />
                        ) : (
                          <Folder item={item} />
                        )}
                      </StyledDocumentContainer>
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className="nodata">
                  <p>No Data</p>
                </div>
              )}
            </StyledDocumentDiv>
          </Spin>
        </Col>
      </StyleDocumentRow>
    </>
  );
}

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  menuList: getMenuList(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(MyDocuments);

const Wrapper = Styled.div`
    h6{
        cursor: pointer;
    }

    .label {
      font-weight: bold;
      font-size: 14px;
    }

    .ant-card-body {
      padding: 8px;
    }

    .card-container{
      padding: 10px;
      display: flex;
      gap:10px;
      flex-wrap: wrap;
      .cards{
        border: 1px solid #f1f2f4;
        border-radius: 10px;
        width: 180px;
        img{
            width: 100%;
        }
      }
   }
   .template{
    padding: 20px;
    h6{
      font-weight: bold;
      font-size: 18px;
    }
     .delete {
        cursor: pointer;
        font-weight: 500;
        margin-right: 20px;
        color: ${COLORS.PRIMARY}
     }
  
   }

`;

export const CardImage = Styled.div`
  /* background: ${(props) => props.backgroundColor || "#F0EBF8"}; */
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  small{
    font-size: 10px;
  }

  img {
    width: 100px;
    height: 100px;
  }
`;

const StyledDocumentModal = styled(Modal)`
  height: 100vh;
  max-width: 80rem;
  padding: 1%;
  width: 100%;

  .ant-modal-content {
    height: 100vh;
    width: 100%;
    box-shadow: none;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 80rem;
    transform: scale(0.85);
  }

  .iframe {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    transform: scale(0.85);
  }
  .file_fit {
    width: 100%;
    height: 100%;
  }
  .img_fit {
    max-width: 80vw;
    max-height: 100vh;
  }
`;
