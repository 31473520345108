import { Col, Form, Input, notification, Radio, Row, Table } from "antd";
import Title from "atoms/Title";
import COLORS from "common/Colors";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import React, { useEffect, useMemo, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";
import Button from "atoms/Button";
import { useSelector } from "react-redux";
import {
  getManagingOffice,
  getResourceData,
  getUserInfo,
  getUserList,
} from "redux/selectors";
import { find, isEmpty, sumBy } from "lodash";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import { useMutate } from "hooks/useMutate";
import { useScrollFetch } from "hooks/useScrollFetch";

const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* border: 2px solid #000; */
  .my-1 {
    display: flex;
    word-wrap: break-word;
  }
  .graybg {
    background-color: #f6f6f6;
  }
  .label_Color {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3019;
    color: #000;
  }
  .label_value_Color {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f243b;
  }
  .employess_detail_values {
    /* border-bottom: 1px solid #ccc; */
  }
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 10px 14px;
  }
  .action-status {
    background-color: #f7f7f8;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border: 1px solid #dbdbdb;
  }
`;
const StyledTable1 = styled(Table)`
  .ant-table-cell {
    padding: 10px 14px;
  }
  .action-status {
    background-color: #fff;
    font-weight: bold;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border: 1px solid #fff;
  }
`;

function EngagementAppraisal() {
  const { id, resourceid } = useParams();
  const [form] = Form.useForm();
  console.log("resourceid ---> ", resourceid);

  const { getEngagementById, addEngagementAppraisal, getAllClientsList } =
    queryKeys;
  const { key, url } = getEngagementById(id);

  const { data: engagementDataRes } = useFetch(key, url, {
    enabled: !!id,
  });
  const [loading, setLoading] = useState(false);

  const engagementData = engagementDataRes?.data;
  const history = useHistory();
  const cursorStyle = { cursor: "pointer" };

  const userList = useSelector((state) => getUserList(state));
  const userInfo = useSelector((state) => getUserInfo(state));
  const selectedResource = useSelector((state) => getResourceData(state));

  const { key: clientKey, url: clientURL } = getAllClientsList;

  const {
    data: clientsDataRes,
    fetchNextPage,
    // isLoading: loading,
    hasNextPage,
  } = useScrollFetch(clientKey, {
    endPoint: clientURL,
    queryParam: "LastEvaluatedKey",
  });

  const clients = useMemo(() => {
    return clientsDataRes?.pages?.reduce((acc, page) => {
      if (page?.data === undefined) return acc;
      return [...acc, ...page?.data];
    }, []);
  }, [clientsDataRes]);

  const clientOptions = clients
    ?.filter((client) => client?.bdo_id !== "NULL")
    ?.map((client) => ({
      label: client?.registeredName,
      value: client?.clientId,
    }));

  const clientName = clientOptions?.find(
    //? will give result only after submitting Engagement
    (client) => client?.value === engagementData?.clientId
  )?.label;

  const Information = [
    {
      name: "Client :",
      value: clientName,
    },
    {
      name: "Engagement Name :",
      value: engagementData?.information?.jobTitle
        ? engagementData?.information?.jobTitle
        : "-",
    },
    {
      name: "Appraiser :",
      value: find(userList, {
        userid: engagementData?.stakeHolder?.personIncharge,
      })?.fullname
        ? find(userList, {
            userid: engagementData?.stakeHolder?.personIncharge,
          })?.fullname
        : "-",
    },
    {
      name: "Appraisee :",
      value: selectedResource?.employeeName,
    },
  ];

  const financialData = [
    {
      name: (
        <div>
          <h6>Value & Efficiency:</h6>
          <p>
            Knowledgeable and well prepared to handle the allocated work. The
            methodology and software used was appropriate. Conclusions reached
            were useful, realistic and adequately supported by relevant facts
            and thorough analysis
          </p>
        </div>
      ),
    },
    {
      name: (
        <div>
          <h6>Timely Delivery:</h6>
          <p>
            Timely Delivery: The assignment / tasks / sections were completed on
            a timely basis. The timeliness of response to phone calls/e-mails
            showed care and good communication skills. No undue disruption to
            workplace during the assignment.
          </p>
        </div>
      ),
    },
    {
      name: (
        <div>
          <h6>Quality, Compliance & Risk:</h6>
          <p>
            Quality and Risks were properly managed and with compliance to
            methodology, tools and processes was as per expectation. Also,
            safeguarding of documents and work environment and staff commitments
            were respected.
          </p>
        </div>
      ),
    },
    {
      name: (
        <div>
          <h6>Client satisfaction + Survey:</h6>
          <p>
            Demonstrated professionalism and the handling of client, staff and
            supervision of the job was impeccable. Both internal and external
            stakeholders were satisfied with the delivery. 123456
          </p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      width: "70%",
      className: "action-status",
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
      width: "30%",
      render: (text, record, index) => (
        <Form.Item
          name={`value_${index}`}
          initialValue={text}
          rules={[{ required: true, message: "Please select a value!" }]} // Updated to make radio selection mandatory
        >
          <Radio.Group>
            {[...Array(6).keys()].map((value) => (
              <Radio key={value + 1} value={value + 1}>
                {value + 1}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      ),
    },
  ];

  const dataSource = financialData.map((item, index) => ({
    key: index,
    name: item.name,
    value: null, // default value, can be updated with form initial values
  }));

  const breadStyle = { color: "#636980", cursor: "pointer" };
  const breadcrumbs = [
    <label key="2" style={breadStyle} onClick={() => history.goBack()}>
      {engagementData?.information?.jobTitle}
    </label>,
    <Typography key="3" color="text.primary">
      Engagement Appraisal
    </Typography>,
  ];

  const { mutate: add, isLoading: isPosting } = useMutate(
    addEngagementAppraisal.key,
    addEngagementAppraisal.url
  );

  const handleSubmit = async (values) => {
    console.log("values--->", values);
    const payload = {
      client: clientName,
      engagementId: engagementData?.engagementId,
      engagementName: engagementData?.information?.jobTitle,
      appraiser: find(userList, {
        userid: userInfo.userid,
      })?.fullname,
      appraisee: selectedResource?.employeeName,
      valueAndEfficiency: values.value_0,
      timelyDelivery: values.value_1,
      qualityComplianceAndRisk: values.value_2,
      clientSatisfactionSurvey: values.value_3,
      status: "yes",
      updatedBy: userInfo?.userid,
      comment: values?.comments,
      resourceId: selectedResource?.resourceId,
    };

    add(payload, {
      onSuccess: (res) => {
        notification.success({
          message: "Appraisal Submitted Successfully",
        });

        history.goBack();
      },
    });
  };

  useEffect(() => {
    if (selectedResource?.engagementAppraisal)
      form.setFieldsValue({
        appraisee: selectedResource?.engagementAppraisal.employeeName,
        value_0: selectedResource?.engagementAppraisal.valueAndEfficiency,
        value_1: selectedResource?.engagementAppraisal.timelyDelivery,
        value_2: selectedResource?.engagementAppraisal.qualityComplianceAndRisk,
        value_3: selectedResource?.engagementAppraisal.clientSatisfactionSurvey,
        comments: selectedResource?.engagementAppraisal.comment,
      });
  }, [selectedResource]);

  const isDisabled = !isEmpty(selectedResource?.engagementAppraisal);

  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <div className="px-1 mt-2">
        <StyledTable1
          showHeader={false}
          columns={[
            {
              title: "Information",
              dataIndex: "name",
              key: "name",
              width: "20%",
              className: "action-status",
            },
            {
              title: "",
              dataIndex: "value",
              key: "value",
              width: "80%",
            },
          ]}
          dataSource={Information}
          pagination={false}
        />
        <br />
        <Form form={form} layout="vertical" onFinish={handleSubmit} disabled={isDisabled}>
          <StyledTable
            showHeader={false}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
          <br />
          <Form.Item
            name="comments"
            label="Additional Comments"
            rules={[
              { required: false, message: "Please input your comments!" },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item className="text-end">
            {!isDisabled && (
              <Button type="primary" htmlType="submit" loading={isPosting}>
                Submit
              </Button>
            )}
          </Form.Item>
        </Form>
        <br />
      </div>
    </div>
  );
}

export default EngagementAppraisal;
