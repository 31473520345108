import React from "react";
import styled from "styled-components";

const TimeSheetFooterHint = () => {
  return (
    <TimeSheetFooterHintWrap>
      <div className="hint">
        <p>
          <span className="hint-heading">
            Please remember the following when you fill in your timesheet:
          </span>{" "}
          <br />
          - The week-ending date is the SUNDAY of the work week that you are
          reporting on. <br />
          - Time is recorded in manhours (8 hrs = 1 manday). <br />- You may
          modify entry values as long as the individual entry is not approved.
        </p>
      </div>

      <div className="fact">
        <p>
          <span className="hint-heading">Retract Function:</span> <br />
          - You may retract the pending approve timesheet items for the
          specified weekending timesheet. <br />- Retract timesheet will
          'reopen' the pending approve timesheet items only.
        </p>
      </div>
    </TimeSheetFooterHintWrap>
  );
};

export default TimeSheetFooterHint;

const TimeSheetFooterHintWrap = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }

  .hint {
    margin-bottom: 12px;
    .hint-heading {
      /* font-weight: bold; */
      font-size: Medium;
    }
  }

  .fact {
    box-shadow: 0px 3px 6px #00000015;
    border-radius: 5px;
    padding: 0.5rem;

    .hint-heading {
      font-size: Medium;
    }
  }
`;
