import { Table } from 'antd';
import COLORS from 'common/Colors';
import React from 'react';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  /* padding-top: 20px; */
  box-shadow: 0px 3px 6px #00000015;
  margin-bottom: 0px;
  padding-bottom: 0px;
  table {
    table-layout: auto !important;
    box-shadow: 0px 3px 6px #00000015;
    border: 1px solid #EDEDED;
  }
  .ant-table-content {
    overflow-x: auto;
  }
  .ant-table-cell {
    padding: 10px;
    height: 0px !important;
    border: 1px solid #EDEDED;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: none;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function ReusableTable({data, columns}) {
  return (
    
         <StyledTable
            className="mb-2"
            dataSource={data}
            columns={columns}
            rowKey="role_id"
            pagination={false}
          />
  )
}

export default ReusableTable