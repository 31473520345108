import styled from "styled-components";

const ClientWrapStyle = styled.div`
  .title {
    display: flex;
    justify-content: space-between;

    h5 {
      font-weight: 600;
    }
  }
  .customise_table table {
    border: none;
  }
  .customise_table .ant-table {
    border-radius: 5px;
  }
  .customise_table .ant-table .ant-table-row,.customise_table  .ant-table-cell{
    cursor: pointer;
  }

  .customise_table .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    width:0;
    height:0;
  }
  .approved {
    color: #397ff4;
    text-transform: capitalize;
    cursor: pointer;
  }

  .normal-column{
    cursor: pointer;
  }

  .filter-title {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .search-box {
    max-width: 350px;
    margin-top: 12px;
  }

  .filters {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-bottom: 20px;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default ClientWrapStyle;