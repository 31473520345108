import moment from "moment";
import React, { useMemo, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useSelector } from "react-redux";
import { getUserInfo, getUserList } from "redux/selectors";
import { useScrollFetch2 } from "hooks/useScrollFetch2";
import queryKeys from "common/queryKey";
import { useQueryState } from "hooks/useQueryState";
import { useFetch } from "hooks/useFetch";
import ViewDetails from "./ViewDetails";
import { Breadcrumbs, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import { find, uniqBy } from "lodash";
import { Col, Form, Row } from "antd";

function EngagementCalendar() {
  const userList = useSelector((state) => getUserList(state));
  const userInfo = useSelector((state) => getUserInfo(state));
  const [viewDetails, setViewDetails] = useState(false);
  const [selectedEngagementData, setSelectedEngagementData] = useState({});
  const history = useHistory()

  const Statusitems = [
    {
      id: 1,
      value: "Not-Started",
    },
    {
      id: 2,
      value: "Active",
    },
    {
      id: 3,
      value: "Closed",
    },
  ];

  const filteruser = userList?.filter(
    (item) => item?.userid === userInfo.userid
  );

  const { getEngagements } = queryKeys;

  const queryParams = new URLSearchParams();
  queryParams.append(
    "country",
    filteruser[0].OfficeSupervisorId === "none"
      ? ""
      : filteruser[0]?.managingoffice
  );

  const queryString = queryParams.toString();

  const { key, url } = getEngagements(queryString);

  const { data, lastElementRef, isLoading } = useScrollFetch2(key, {
    endPoint: url,
    queryParam: "LastEvaluatedKey",
  });

  const engagements = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      if (page?.data === undefined) return acc;
      return [...acc, ...page?.data];
    }, []);
  }, [data]);

  // // Transform event data
  // const events = engagements?.map((event) => ({
  //   title: event.title + event?.clientName,
  //   start: moment(event.planStartDate, "DD-MM-YYYY").format(),
  //   end: moment(event.planEndDate, "DD-MM-YYYY").format(),
  //   status: event.status,
  //   ...event,
  // }));

  const events = useMemo(() => {
    return engagements?.map((event) => ({
      title: event.title + event?.clientName,
      start: moment(event.planStartDate, "DD-MM-YYYY").format(),
      end: moment(event.planEndDate, "DD-MM-YYYY").format(),
      status: event.status,
      ...event,
    }));
  }, [engagements]);

  const getEventColor = (status) => {
    switch (status) {
      case "Not-Started":
        return "#FBE2CD 0% 0% no-repeat padding-box";
      case "Active":
        return "#CDF2FB 0% 0% no-repeat padding-box";
      case "Closed":
        return "#E2FBCD 0% 0% no-repeat padding-box";
      default:
        return "#FBE2CD 0% 0% no-repeat padding-box";
    }
  };

  const getEventBorderColor = (status) => {
    switch (status) {
      case "Not-Started":
        return "#F18D38";
      case "Active":
        return "#38CDF1";
      case "Closed":
        return "#78F138";
      default:
        return "#F18D38";
    }
  };
  const textStyle = {
    whiteSpace: "nowrap", // Prevent wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Show ellipsis for overflow
    flexGrow: 1,
  };
  const breadStyle = { color: "#636980", cursor: "pointer" };
  const breadcrumbs = [
      <label key="2" style={breadStyle} onClick={() => history.goBack()}>
         Back
      </label>,
      <Typography key="3" color="text.primary">
         Engagement Calendar
      </Typography>,
  ]
  const [form] = Form.useForm();

  const getFilteredData = () => {
    const name = form.getFieldValue("title") || [];
    const managingoffice = form.getFieldValue("manager") || [];
    const clientname = form.getFieldValue("clientname") || [];
    const country = form.getFieldValue("country") || [];
    const status = form.getFieldValue("status") || [];

    if (
      name.length > 0 ||
      managingoffice.length > 0 ||
      clientname.length > 0 ||
      country.length > 0 ||
      status.length > 0
    ) {
      return events?.filter((item) => {
        const nameMatch =
          name && name.length > 0 ? name.some((n) => item.title === n) : true;

        const managingofficeMatch =
          managingoffice && managingoffice.length > 0
            ? managingoffice.includes(item.manager)
            : true;
        const clientNameMatch =
          clientname && clientname.length > 0
            ? clientname.includes(item?.clientname)
            : true;

        const countryMatch =
          country && country.length > 0
            ? country.includes(item?.country)
            : true;

        const statusMatch =
          status && status.length > 0 ? status.includes(item?.status) : true;

        return (
          nameMatch &&
          managingofficeMatch &&
          clientNameMatch &&
          countryMatch &&
          statusMatch
        );
      });
    } else {
      return events;
    }
  };

  return (
    <div>
          <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            <Row>
        <Col sm={24}>
          <Form
            form={form}
            layout="vertical"
            className="mt-2"
            onValuesChange={() => form.submit()}
          >
            <Row gutter={12} className="g-3 mb-2">
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="title"
                  label="Title"
                  options={uniqBy(
                    events?.map((item) => {
                      return { label: item?.title, value: item?.title };
                    }),
                    "label"
                  )}
                  form={form}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="clientname"
                  label="Client Name"
                  options={uniqBy(
                    events?.map((item) => {
                      return {
                        label: item?.clientname,
                        value: item?.clientname,
                      };
                    }),
                    "value"
                  )}
                  form={form}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="manager"
                  label="Manager"
                  options={uniqBy(
                    events?.map((item) => {
                      return {
                        label: find(userList, { userid: item?.manager })
                          ?.fullname,
                        value: item?.manager,
                      };
                    }),
                    "value"
                  )}
                  form={form}
                />
              </Col>

              {filteruser && filteruser[0]?.OfficeSupervisorId === "none" && (
                <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                  <DropdownCheckBox
                    name="country"
                    label="Country"
                    options={uniqBy(
                        events?.map((item) => {
                        return { label: item?.country, value: item?.country };
                      }),
                      "value"
                    )}
                    form={form}
                  />
                </Col>
              )}

              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="status"
                  label="Status"
                  options={uniqBy(
                    Statusitems?.map((item) => {
                      return { label: item?.value, value: item?.value };
                    }),
                    "value"
                  )}
                  form={form}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth" // Change to "timeGridWeek" or "timeGridDay" if needed
        events={getFilteredData()}
        headerToolbar={{
            left: 'title', // Show the title (month) on the left
            center: '',    // No center buttons
            right: 'prev,next' // Show prev and next buttons on the right
          }}
        editable={true}
        selectable={true}
        eventClick={(info) => {
          const engagementData = {
            id: info.event.id, // Assuming `id` is a unique identifier
            title: info.event.title,
            start: info.event.start,
            end: info.event.end,
            status: info.event.extendedProps.status,
            // Add any additional properties from `info.event` as needed
            ...info.event.extendedProps,
          };
          setSelectedEngagementData(engagementData);
          setViewDetails(true);
        }}
        height="auto" 
        contentHeight="auto" 
        dayMaxEvents={true}
        eventLimit={true}
        eventContent={(info) => {
          const bgColor = getEventColor(info.event.extendedProps.status); // Use status to get color
          const borderColor = getEventBorderColor(
            info.event.extendedProps.status
          );
          return (
            <div style={ {
                background: bgColor,
                padding: "5px",
                border: "none",
                outline: "none",
                color: "#000",
                borderLeft: `3px solid ${borderColor}`,
                borderRight: `3px solid ${borderColor}`,
                width: "100%", // Ensure full width for the container
                display: "flex", // Use flexbox for layout
                alignItems: "center", // Center items vertically
                overflowY: "scroll"
              }}>
              <span style={textStyle}>
                {"Engagement Name: "}{info.event.title} {" | Client Name: "}
                {info.event.extendedProps.clientName ?? "-"}
              </span>
            </div>
          );
        }}
      />

      <ViewDetails
        show={viewDetails}
        onClose={() => setViewDetails(false)}
        engagementData={selectedEngagementData}
      />
    </div>
  );
}

export default EngagementCalendar;
