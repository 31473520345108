import { Col, Form, Row, Table } from "antd";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import COLORS from "common/Colors";
import React from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  /* padding-top: 20px; */
  table {
    table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
  }
  .ant-table-cell {
    padding: 10px;
    height: 0px !important;
    border: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: none;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function ClientFiles({ data }) {
  const [form] = Form.useForm();
  const tempdata = [
    {
      name: "04-Reports",
      modified: "23/05/2022 12.30pm",
      sector: "",
      modifiedby: "CRMDEV",
      location: "Abyssinia Iron and Steel",
      clientname: "Abyssinia",
      industry: "",
      type: "",
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      //   width: "10%",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Modified",
      dataIndex: "modified",
      key: "modified",
      //   width: "20%",
      className: "normal-column",
      sorter: (a, b) => a.modified.localeCompare(b.modified),
    },
    {
      title: "Modified by",
      dataIndex: "modifiedby",
      key: "modifiedby",
      //   width: "20%",
      className: "normal-column",
      sorter: (a, b) => a.modifiedby.localeCompare(b.modifiedby),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      //   width: "40%",
      className: "normal-column",
      sorter: (a, b) => a.location.localeCompare(b.location),
    },
    {
      title: "Client Name",
      dataIndex: "clientname",
      key: "clientname",
      // width: "30%",
      className: "normal-column",
      sorter: (a, b) => a.clientname.localeCompare(b.clientname),
    },
    {
      title: "Industry",
      dataIndex: "location",
      key: "location",
      // width: "20%",
      className: "normal-column",
      sorter: (a, b) => a.location.localeCompare(b.location),
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
      // width: "20%",
      className: "normal-column",
      sorter: (a, b) => a.sector.localeCompare(b.sector),
    },
    {
      title: "Type of",
      dataIndex: "type",
      key: "type",
      // width: "20%",
      className: "normal-column",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      // width: "20%",
      className: "normal-column",
      sorter: (a, b) => a.source.localeCompare(b.source),
    },
  ];

  return (
    <>
      <Row>
        <Col sm={24} md={6}>
          <DropdownCheckBox
            name="name"
            label="Search Industry name"
            // options={uniqueNames}
            form={form}
          />
        </Col>
      </Row>
      <StyledTable
        className="mb-5"
        dataSource={data?.files}
        columns={columns}
        rowKey="role_id"
        pagination={false}
      />
    </>
  );
}

export default ClientFiles;
