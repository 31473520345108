import { Form, Spin, Table, Modal, Collapse } from "antd";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import { CALL_API } from "common/API";
import { CompetencyRole, Grade, PeriodOptions } from "common/Constants";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceArea,
} from "recharts";
import { getlevelList, getSupervisorList, getUserList } from "redux/selectors";
import styled from "styled-components";

const metrics = [
  {
    id: 1,
    name: "Star",
    potential: "High Competence, High Commitment",
    color: "rgba(0, 0, 255, 1)",
    x1: 4,
    x2: 6,
    y1: 4,
    y2: 6,
  },
  {
    id: 2,
    name: "High Potential",
    potential: "Moderate Competence, High Commitment",
    color: "rgba(0, 0, 255, 1)",
    x1: 4,
    x2: 6,
    y1: 2,
    y2: 4,
  },
  {
    id: 3,
    name: "Top Performer",
    potential: "High Competence, Moderate Commitment",
    color: "rgba(0, 0, 255, 1)",
    x1: 2,
    x2: 4,
    y1: 4,
    y2: 6,
  },
  {
    id: 4,
    name: "Dedicated Learner",
    potential: "Low Competence, High Commitment",
    color: "rgba(255, 192, 255, 1)",
    x1: 4,
    x2: 6,
    y1: 0,
    y2: 2,
  },
  {
    id: 5,
    name: "Reliable Performer",
    potential: "Moderate Competence, Moderate Commitment",
    color: "rgba(255, 192, 255, 1)",
    x1: 2,
    x2: 4,
    y1: 2,
    y2: 4,
  },
  {
    id: 6,
    name: "Skilled but Unmotivated",
    potential: "High Competence, Low Commitment",
    color: "rgba(255, 192, 255, 1)",
    x1: 0,
    x2: 2,
    y1: 4,
    y2: 6,
  },
  {
    id: 7,
    name: "Inconsistent Performer",
    potential: "Low Competence, Moderate Commitment",
    color: "rgba(255, 0, 0, 1)",
    x1: 2,
    x2: 4,
    y1: 0,
    y2: 2,
  },
  {
    id: 8,
    name: "Underperformer",
    potential: "Moderate Competence, Low Commitment",
    color: "rgba(255, 0, 0, 1)",
    x1: 0,
    x2: 2,
    y1: 2,
    y2: 4,
  },
  {
    id: 9,
    name: "Disengaged",
    potential: "Low Competence, Low Commitment",
    color: "rgba(255, 0, 0, 1)",
    x1: 0,
    x2: 2,
    y1: 0,
    y2: 2,
  },
];

const competencyQCColors = {
  Administration: "#118DFF",
  Advisory: "#12239E",
  Audit: "#E66C37",
  "BD & Marketing": "#D64550",
  Marketing: "#D64550",
  "Business Services & Outsourcing": "#6B007B",
  Finance: "#E044A7",
  "Human Resources": "#744EC2",
  IT: "#FFC400",
  Services: "#A3A0FB",
  Tax: "#B23CFD",
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name) => name || "-",
  },
  {
    title: "Management Office",
    dataIndex: "managementOffice",
    key: "managementOffice",
    render: (managementOffice) => managementOffice || "-",
  },
  {
    title: "Period",
    dataIndex: "period",
    key: "period",
    render: (period) => period || "-",
  },
  {
    title: "Competency/Quality Circle",
    dataIndex: "competencyQC",
    key: "competencyQC",
    render: (competencyQC) => competencyQC || "-",
  },
  {
    title: "Soft Skills",
    dataIndex: "totalSoftSkills",
    key: "totalSoftSkills",
    render: (totalSoftSkills) => totalSoftSkills || "-",
  },
  {
    title: "Technical Skills",
    dataIndex: "totalTechnical",
    key: "totalTechnical",
    render: (totalTechnical) => totalTechnical || "-",
  },
];

const EmployeePerformance = () => {
  const [form] = Form.useForm();
  const [showLoader, toggleLoader] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [competencyQCfetchData, setCompetencyQCfetchData] = useState([]);
  const [servicelinefetchData, setServicelinefetchData] = useState([]);
  const [subservicelineData, setsubservicelineData] = useState([]);
  const [DepartmentLevelData, setDepartmentLevelData] = useState([]);

  const supervisorList = useSelector(getSupervisorList);
  const userList = useSelector(getUserList);
  const levelList = useSelector(getlevelList);

  const getEmployeePerformanceData = async () => {
    toggleLoader(true);
    const response = await CALL_API(`employeeperformance`, "get");
    if (response.code === "SUCCESS") {
      toggleLoader(false);
      setPerformanceData(response.employeesPerformanceList);
    }
  };

  const getFilteredPerformanceData = () => {
    const name = form.getFieldValue("name") || [];
    const year = form.getFieldValue("year") || [];
    const period = form.getFieldValue("period") || [];
    const supervisor = form.getFieldValue("supervisor") || [];
    const managingOffice = form.getFieldValue("managingoffice") || [];
    const competencyQC = form.getFieldValue("competencyQC") || [];
    const grade = form.getFieldValue("grade") || [];
    const serviceline = form.getFieldValue("serviceline") || [];
    const team = form.getFieldValue("team") || [];
    const competencyrole = form.getFieldValue("competencyrole") || [];
    const leveldepartmentname = form.getFieldValue("leveldepartmentname") || [];

    if (
      name.length > 0 ||
      year.length > 0 ||
      period.length > 0 ||
      managingOffice.length > 0 ||
      competencyQC.length > 0 ||
      serviceline.length > 0 ||
      team.length > 0 ||
      grade.length > 0 ||
      competencyrole.length > 0 ||
      leveldepartmentname.length > 0 ||
      supervisor.length > 0
    ) {
      const allPerformanceData = performanceData.map((item) => {
        const user = userList.find((user) => user.userid === item?.userId);

        return {
          ...item,
          OfficeSupervisorId: user?.OfficeSupervisorId,
          serviceline: user?.serviceline,
          subserviceline: user?.subserviceline,
          team: user?.team,
          grade: user?.grade,
          leveldepartmentname: user?.leveldepartmentname,
          managingoffice: user?.managingoffice,
          competencyrole: user?.competencyrole,
        };
      });

      return allPerformanceData.filter((item) => {
        const nameMatch =
          name && name.length > 0 ? name.some((n) => item.name === n) : true;

        const yearMatch =
          year && year.length > 0 ? year?.includes(item.year) : true;

        const periodMatch =
          period && period.length > 0 ? period?.includes(item.period) : true;

        const managingOfficeMatch =
          managingOffice && managingOffice.length > 0
            ? managingOffice?.some((m) => item?.managingoffice?.includes(m))
            : true;

        const competencyMatch =
          competencyQC && competencyQC.length > 0
            ? competencyQC.some((c) => item.competencyQC?.includes(c))
            : true;

        const competencyroleMatch =
          competencyrole && competencyrole.length > 0
            ? competencyrole.some((c) => item.competencyrole?.includes(c))
            : true;

        const servicelineMatch =
          serviceline && serviceline.length > 0
            ? serviceline.some((s) => item.serviceline?.includes(s))
            : true;

        const teamMatch =
          team && team.length > 0
            ? team.some((t) => item.subserviceline?.includes(t))
            : true;

        const gradeMatch =
          grade && grade.length > 0
            ? grade.some((g) => item.grade === g)
            : true;

        const leveldepartmentnameMatch =
          leveldepartmentname.length > 0
            ? leveldepartmentname.some((l) =>
                item.leveldepartmentname?.includes(l)
              )
            : true;

        const supervisorMatch =
          supervisor && supervisor.length > 0
            ? supervisor.some((s) => item.OfficeSupervisorId === s)
            : true;

        return (
          nameMatch &&
          yearMatch &&
          periodMatch &&
          supervisorMatch &&
          competencyMatch &&
          servicelineMatch &&
          competencyroleMatch &&
          teamMatch &&
          gradeMatch &&
          leveldepartmentnameMatch &&
          managingOfficeMatch
        );
      });
    } else {
      return performanceData;
    }
  };

  // give an array of objects with the name of the competencyQC and the color from the getFilteredPerformanceData
  const getDataBasedOnCompetency = () => {
    const data = getFilteredPerformanceData();
    const competencyQC = data.map((item) => item.competencyQC);

    const uniqueCompetencyQC = Array.from(new Set(competencyQC.flat()));

    return uniqueCompetencyQC.map((competencyQC) => ({
      competency: competencyQC,
      color: competencyQCColors[competencyQC],
      data: data.filter((item) => item.competencyQC?.includes(competencyQC)),
      name: "Skills",
    }));
  };

  useEffect(() => {
    let level1_values = [];
    let level2_values = [];
    let level3_values = [];
    let level4_values = [];
    let level5_values = [];

    levelList.forEach((level) => {
      switch (level.levelName) {
        case "Level1":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level1_values.push(item);
            });
          });
          break;
        case "Level2":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level2_values.push(item);
            });
          });
          break;
        case "Level3":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level3_values.push(item);
            });
          });
          break;
        case "Level4":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level4_values.push(item);
            });
          });
          break;
        case "Level5":
          level.value.forEach((value) => {
            value.value.forEach((item) => {
              level5_values.push(item);
            });
          });
          break;
        default:
          break;
      }
    });

    setManagingOfficeData(Array.from(level1_values));
    setCompetencyQCfetchData(Array.from(level2_values));
    setServicelinefetchData(Array.from(level3_values));
    setsubservicelineData(Array.from(level4_values));
    setDepartmentLevelData(Array.from(level5_values));
  }, [levelList]);

  useEffect(() => {
    getEmployeePerformanceData();
  }, []);

  const yearOptions = ["2021/2022", "2022/2023", "2023/2024"];

  const uniqueNames = Array.from(
    new Set(performanceData.map((item) => item.name))
  ).map((name) => ({
    label: name,
    value: name,
  }));

  const handlePlotClick = useCallback((data) => {
    if (data && data.payload) {
      setSelectedData(data.payload);
      setIsModalVisible(true);
    }
  }, []);

  return (
    <StyledWrap>
      <h6 className="mb-4">Performance Report</h6>

      <Form
        form={form}
        layout="vertical"
        className="mt-2"
        onValuesChange={() => form.submit()}
      >
        <Row className="g-3 mb-2">
          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="name"
              label="People"
              options={uniqueNames}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="year"
              label="Year"
              options={yearOptions.map((year) => ({
                label: year,
                value: year,
              }))}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="period"
              label="Period"
              options={PeriodOptions.map((period) => ({
                label: period,
                value: period,
              }))}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="managingoffice"
              label="Country"
              options={managingOfficeData?.map((value) => ({
                label: value,
                value: value,
              }))}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="competencyQC"
              label="Competency/ QC"
              options={competencyQCfetchData?.map((value) => ({
                label: value,
                value: value,
              }))}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="serviceline"
              label="Serviceline"
              options={servicelinefetchData?.map((value) => ({
                label: value,
                value: value,
              }))}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="team"
              label="Sub Serviceline"
              options={subservicelineData?.map((value) => ({
                label: value,
                value: value,
              }))}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="leveldepartmentname"
              label="Department"
              options={[...new Set(DepartmentLevelData)]?.map((value) => ({
                label: value,
                value: value,
              }))}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="grade"
              label="Grade"
              options={Grade?.map((value) => ({
                label: value,
                value: value,
              }))}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="competencyrole"
              label="Competency Role"
              options={CompetencyRole?.map((item) => ({
                label: item,
                value: item,
              }))}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2}>
            <DropdownCheckBox
              name="supervisor"
              label="Office Supervisor"
              options={supervisorList?.map((item) => ({
                label: item?.name,
                value: item?.employeeId,
              }))}
              form={form}
            />
          </Col>
        </Row>
      </Form>

      <div className="card">
        <Spin spinning={showLoader}>
          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
              <CartesianGrid stroke="#F2F2F2" />
              <XAxis
                type="number"
                dataKey="totalSoftSkills"
                name="Soft Skills"
                unit=""
                domain={[0, 6]}
                ticks={[0, 1, 2, 3, 4, 5, 6]}
                axisLine={{ stroke: "#00000029" }}
                tick={{ fill: "#3C3C3C" }}
              >
                <Label
                  value="Soft Skills"
                  offset={-5}
                  fill="#3C3C3C"
                  position="insideBottom"
                />
              </XAxis>
              <YAxis
                type="number"
                dataKey="totalTechnical"
                name="Technical Skills"
                unit=""
                domain={[0, 6]}
                ticks={[0, 1, 2, 3, 4, 5, 6]}
                axisLine={{ stroke: "#00000029" }}
                tick={{ fill: "#3C3C3C" }}
                label={{
                  value: "Technical Skills",
                  angle: -90,
                  position: "insideLeft",
                  fill: "#3C3C3C",
                }}
              />
              {metrics.map((metric, index) => (
                <ReferenceArea
                  key={index}
                  x1={metric.x1}
                  x2={metric.x2}
                  y1={metric.y1}
                  y2={metric.y2}
                  stroke={metric.color}
                  strokeDasharray="3 3"
                  fillOpacity={0}
                  label={{
                    position: "insideTopLeft",
                    value: `${metric.name} - ${metric.id}`,
                    fill: metric.color,
                    fontSize: 12,
                  }}
                />
              ))}
              <Tooltip cursor={{ strokeDasharray: "3 3" }} />
              {/* <Scatter
                name="Skills"
                data={getFilteredPerformanceData()}
                fill="#C2FAE5"
                onClick={(e) => handlePlotClick(e)}
              /> */}

              {getDataBasedOnCompetency().map((competency) => (
                <Scatter
                  key={competency.competency}
                  name={competency.name}
                  data={competency.data}
                  fill={competency.color}
                  onClick={(e) => handlePlotClick(e)}
                />
              ))}
            </ScatterChart>
          </ResponsiveContainer>
        </Spin>

        <Collapse defaultActiveKey={1}>
          <Collapse.Panel header="Metrics" key="1">
            <div className="legends">
              {metrics.map((metric, index) => (
                <div key={index} className="legend">
                  <div
                    className="color"
                    style={{ backgroundColor: metric.color }}
                  ></div>
                  <div className="text">
                    <div>
                      {metric.name} - {metric.id}
                    </div>
                    <div>{metric.potential}</div>
                  </div>
                </div>
              ))}
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>

      <h6 className="my-3">Details:</h6>
      <Spin spinning={showLoader}>
        <div className="customise_table">
          <Table
            dataSource={getFilteredPerformanceData()}
            columns={columns}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="active-client-id"
          />
        </div>
      </Spin>

      <Modal
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={false}
        closable={false}
        width="70%"
        bodyStyle={{ padding: 0 }}
      >
        {selectedData && (
          <Table
            dataSource={[selectedData]}
            columns={columns}
            pagination={false}
            rowKey="userId"
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
          />
        )}
      </Modal>
    </StyledWrap>
  );
};

export default EmployeePerformance;

const StyledWrap = styled.div`
  .ant-table-tbody > tr > td {
    font-weight: 400 !important;
  }

  .ant-table-cell::before {
    display: none !important;
  }

  .card {
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    border: none !important;
  }

  .ant-btn {
    margin-bottom: 8px !important;
  }

  .legends {
    display: flex;
    flex-direction: column;
    padding: 0px 60px 20px 60px;

    .legend {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 20px;
      border-bottom: 1px solid #00000029;
      padding: 10px 0;
      margin-bottom: 10px;

      .color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .text {
        font-size: 14px;
      }
    }
  }
`;
