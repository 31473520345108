import TimeSheet from "molecules/TimeSheet";
import LeftIcon from "assets/icons/UI/left.svg";
import RightIcon from "assets/icons/UI/right.svg";
import React, { useState } from "react";
import { Button, DatePicker, Form, Spin, notification } from "antd";
import { ReactComponent as EditIcon } from "assets/icons/UI/edit.svg";
import queryKeys from "common/queryKey";
import { useSelector } from "react-redux";
import { getUserInfo, getUserList } from "redux/selectors";
import { useFetch } from "hooks/useFetch";
import { ClickAwayListener, IconButton } from "@mui/material";
import { useUpdate } from "hooks/useUpdate";
import useWeekData, { periodToDate } from "./useWeekData";
import { useQueryClient } from "react-query";
import { ConfigProvider } from "antd";
import en_GB from "antd/lib/locale-provider/en_GB";
import moment from "moment";
import "moment/locale/en-gb";
import TimeSheetOverView from "./TimeSheetOverView";
import TimeSheetFooterHint from "./TimeSheetFooterHint";
import { PENDING } from ".";
import useTimesheetfns from "./useTimesheetfns";

moment.locale("en-gb");

const DeclinedTimeSheet = ({ selectedPeriod }) => {
  const [editable, setEditable] = useState(false);

  const selectedPeriodToDate = periodToDate(selectedPeriod);

  const {
    date,
    week,
    currentWeek,
    edit,
    setEdit,
    currentNiceWeekRange,
    disabledDate,
    handleNextWeek,
    handlePreviousWeek,
    onChange,
    setPersonInCharge,
    personIncharge,
  } = useWeekData(selectedPeriodToDate);

  const { getTimesheet, postTimesheet } = queryKeys;

  const userInfo = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const userList = useSelector(getUserList);

  const currentUser = userList.find((user) => user.userid === userInfo.userid);

  const OfficeSuperVisorId = currentUser?.OfficeSupervisorId || null;

  const OfficeSuperVisorUserId = userList.find(
    (user) => user.employeeid === OfficeSuperVisorId
  )?.userid;

  const { data: timesheetDataRes, isLoading } = useFetch(
    `${getTimesheet.key}-week:${week}-status:declined`,
    `${getTimesheet.url}?week=${week}&employeeId=${userInfo.employer_id}&status=declined`,
    {
      enabled: !!week,
    }
  );

  const [form] = Form.useForm();

  const {
    timeSheet,
    generateTimeSheetData,
    handleAddMoreClaim,
    handleClearClaim,
    handleResetClaim,
    handleRemoveClaim,
    handlePersonInCharge,
    handleClearTimesheet,
    isChargeAbleAmtValid,
  } = useTimesheetfns({
    personIncharge,
    OfficeSuperVisorUserId,
    currentWeek,
    form,
    setPersonInCharge,
    week,
    timesheetDataRes,
  });

  const noTimesheet = !timesheetDataRes?.response?.response?.timeSheet;

  const { mutate: update, isLoading: isUpdating } = useUpdate(
    postTimesheet.key,
    postTimesheet.url
  );

  const onUpdate = (values) => {
    const timeSheet = generateTimeSheetData(values);

    if (!isChargeAbleAmtValid(timeSheet)) {
      return false;
    }

    const isValid = form.validateFields();

    if (!isValid) {
      notification.error({
        message: "Please fill all required fields",
      });
      return false;
    }

    const updatedData = {
      ...timesheetDataRes?.response?.response,
      status: PENDING,
      timeSheet: timeSheet,
    };

    update(updatedData, {
      onSuccess: (res) => {
        console.log("onSubmit  res-->", res);
        queryClient.invalidateQueries(`${getTimesheet.key}-week:${week}`);
        queryClient.invalidateQueries(
          `${getTimesheet.key}-week:${week}-status:pending`
        );
        notification.success({
          message: `Timesheet Updated Successfully`,
        });
      },
    });
  };

  return (
    <Form
      form={form}
      onFinish={onUpdate}
      initialValues={timeSheet}
      layout="vertical"
      onFinishFailed={() => {
        notification.error({
          message: "Please fill all the required fields",
        });
      }}
    >
      <div className="timesheet-header">
        <div>
          <img
            src={LeftIcon}
            alt="left"
            className="left-icon"
            onClick={handlePreviousWeek}
          />
          <img
            src={RightIcon}
            alt="right"
            className="right-icon"
            onClick={handleNextWeek}
          />
          <span className="d-inline-flex justify-content-center align-items-center gap-2">
            <span className="date">Date:</span>{" "}
            {edit ? (
              <ClickAwayListener onClickAway={() => setEdit(false)}>
                <div className="position-relative">
                  <ConfigProvider locale={en_GB}>
                    <DatePicker
                      onChange={onChange}
                      picker="week"
                      value={date}
                      open={true}
                      disabledDate={disabledDate}
                    />
                  </ConfigProvider>
                </div>
              </ClickAwayListener>
            ) : (
              <div className="current-week" onClick={() => setEdit(true)}>
                {currentNiceWeekRange}
              </div>
            )}
          </span>
        </div>

        {editable ? (
          <></>
        ) : (
          !isLoading &&
          !noTimesheet && (
            <IconButton
              onClick={() => {
                notification.info({
                  message: "now you can edit the timesheet",
                });
                setEditable(!editable);
              }}
            >
              <EditIcon />
            </IconButton>
          )
        )}
      </div>

      <Spin spinning={isLoading}>
        {!isLoading && noTimesheet ? (
          <div className="no-timesheet">
            No Declined Timesheet Found for this Week
          </div>
        ) : (
          <>
            <div className="mb-4">
              <TimeSheetOverView timeSheet={timeSheet} />
            </div>

            <TimeSheet
              editable={editable}
              data={timeSheet}
              extraActions={{
                handlePersonInCharge,
                handleClearTimesheet,
                handleAddMoreClaim,
                handleClearClaim,
                handleResetClaim,
                handleRemoveClaim,
              }}
            />
            <TimeSheetFooterHint />

            <div className="d-flex justify-content-start align-items-center gap-2 mt-4">
              {editable && !isLoading && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isUpdating}
                  className="submit-button"
                >
                  Submit
                </Button>
              )}
            </div>
          </>
        )}
      </Spin>
    </Form>
  );
};

export default DeclinedTimeSheet;
