import { Modal } from 'antd';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import COLORS from 'common/Colors';
import { IconButton } from '@mui/material';

export const displayErrorModal = (message) => {
  Modal.error({
    content: message,
    icon: '',
    footer: []
  });
}

export const displayWarningModal = (message) => {
  Modal.warning({
    content: message,
    footer: []
  });
}

export const CustomTextField = styled(TextField)({
  '& .MuiInput-root:after': {
    borderBottomColor: COLORS.PRIMARY,
  },
});

export const CustomIconButton = styled(IconButton)({
  background: COLORS.PRIMARY,
  color: COLORS.BLACK,
  '&:hover': {
    color: COLORS.BLACK
  }
});
