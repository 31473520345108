import React from "react";
import { Card, Row, Col, Badge } from "antd";

import styled from "styled-components";
import { map } from "lodash";
import { formatShortDate, getMySqlDate } from "utils/Date";

const StyledTitle = styled.div`
  font-weight: bold;
  padding: 10px 0px;
`;

const StyledHolidayCard = styled(Card)`
  box-shadow: 2px 2px 5px #dedede;
  margin-bottom: 10px;
  .ant-card-body {
    padding: 5px 20px;
  }
`;

const StyledBadge = styled(Badge)`
  padding-top: 8px;
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
`;

const StyledHolidayName = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
`;

const StyledHolidayDate = styled.div`
  font-size: smaller;
  color: #949494;
`;

function UpcomingHolidays({ holidayList }) {
  const holidayCard = ({
    holiday_id,
    holiday_name,
    start_date,
    end_date,
    color,
  }) => (
    <StyledHolidayCard key={holiday_id}>
      <Row>
        <Col span={4}>
          <StyledBadge status="success" size="small" color={color} />
        </Col>
        <Col span={20}>
          <StyledHolidayName>{holiday_name}</StyledHolidayName>
          {getMySqlDate(start_date) === getMySqlDate(end_date) && (
            <StyledHolidayDate>{formatShortDate(start_date)}</StyledHolidayDate>
          )}
          {getMySqlDate(start_date) !== getMySqlDate(end_date) && (
            <StyledHolidayDate>
              {formatShortDate(start_date)} <b>-</b> {formatShortDate(end_date)}
            </StyledHolidayDate>
          )}
        </Col>
      </Row>
    </StyledHolidayCard>
  );

  return (
    holidayList?.length > 0 && (
      <>
        <StyledTitle>Upcoming</StyledTitle>
        {map(holidayList, (holiday) => holidayCard({ ...holiday }))}
      </>
    )
  );
}

export default UpcomingHolidays;
