import { Col, Form, Row, Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Table from "atoms/Table";
import ClientWrapStyle from "./ClientWrapStyle";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import styled from "styled-components";
import moment from "moment";
import { uniqBy } from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCRMManagingOfficeList,
  getFilters,
  getStatusCodes,
} from "redux/selectors";
import { setFilter } from "redux/actions";
import ViewMore from "organisms/ViewMore";
import { CALL_FEATCH_API } from "common/API";

const StyledWrap = styled.div`
  .ant-table-tbody > tr > td {
    font-weight: 400 !important;
  }
`;

const fontStyle = { color: "#636980" };

const columns = [
  {
    title: "Registered Name",
    dataIndex: "name",
    key: "name",
    className: " approved",
    render: (name) => <ViewMore value={name} count={20} /> || "-",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Managing Office",
    dataIndex: "bdo_managingoffice@OData.Community.Display.V1.FormattedValue",
    key: "bdo_managingoffice@OData.Community.Display.V1.FormattedValue",
    className: "",
    sorter: (a, b) => {
      const valueA =
        a["bdo_managingoffice@OData.Community.Display.V1.FormattedValue"];
      const valueB =
        b["bdo_managingoffice@OData.Community.Display.V1.FormattedValue"];
      return valueA?.toString().localeCompare(valueB?.toString());
    },
  },
  {
    title: "Client ID",
    dataIndex: "bdo_id",
    key: "bdo_id",
    className: "",
    render: (bdo_id) => bdo_id || "-",
    sorter: (a, b) => a?.bdo_id.localeCompare(b?.bdo_id),
  },
  {
    title: "Owner",
    dataIndex: "_ownerid_value@OData.Community.Display.V1.FormattedValue",
    key: "_ownerid_value@OData.Community.Display.V1.FormattedValue",
    className: "",
    render: (Owner) => Owner || "-",
    sorter: (a, b) => {
      const valueA =
        a["_ownerid_value@OData.Community.Display.V1.FormattedValue"];
      const valueB =
        b["_ownerid_value@OData.Community.Display.V1.FormattedValue"];
      return valueA?.toString().localeCompare(valueB?.toString());
    },
  },
  {
    title: "Created On",
    dataIndex: "createdon",
    key: "createdon",
    className: "",
    sorter: (a, b) => moment(a.createdon).unix() - moment(b.createdon).unix(),
    render: (createdon) =>
      createdon ? moment(createdon).format("DD/MM/YYYY hh:mma") : "-",
  },
  {
    title: "Email",
    dataIndex: "emailaddress1",
    key: "emailaddress1",
    className: "approved",
    sorter: (a, b) =>
      (a?.emailaddress1 || "").localeCompare(b?.emailaddress1 || ""),
    render: (emailaddress1) => emailaddress1 || "-",
  },

  {
    title: "Status Reason",
    dataIndex: "statuscode@OData.Community.Display.V1.FormattedValue",
    key: "statuscode@OData.Community.Display.V1.FormattedValue",
    className: "",
    sorter: (a, b) => {
      const valueA = a["statuscode@OData.Community.Display.V1.FormattedValue"];
      const valueB = b["statuscode@OData.Community.Display.V1.FormattedValue"];
      return valueA?.toString().localeCompare(valueB?.toString());
    },
  },
  {
    title: "COB Form Status",
    dataIndex: "bdo_status@OData.Community.Display.V1.FormattedValue",
    key: "bdo_status@OData.Community.Display.V1.FormattedValue",
    className: "",
    sorter: (a, b) => {
      const valueA =
        a["bdo_status@OData.Community.Display.V1.FormattedValue"] || "";
      const valueB =
        b["bdo_status@OData.Community.Display.V1.FormattedValue"] || "";
      return valueA.localeCompare(valueB);
    },
  },
  {
    title: "Modified On",
    dataIndex: "modifiedon",
    key: "modifiedon",
    className: "",
    render: (modifiedon) =>
      modifiedon ? moment(modifiedon).format("DD/MM/YYYY hh:mma") : "-",
    sorter: (a, b) => a?.modifiedon.localeCompare(b?.modifiedon),
  },
];

const Active = ({
  clientList,
  showLoader,
  lastElementRef,
  loader,
  statusCodes,
  totalFilteredClientCount,
}) => {
  const [clientLoader] = useState(false);
  const { Option } = Select;
  // const dropdownStyle = {
  //   fontWeight: "bold",
  //   color: "black",
  // };

  const [form] = Form.useForm();

  const history = useHistory();
  const filters = useSelector(getFilters);
  const crmManagingOfficeList = useSelector(getCRMManagingOfficeList);
  const menuName = "client";
  const initialFilter = filters[menuName];

  const dispatch = useDispatch();

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    dispatch(setFilter(menuName, "name", values.name || []));
    dispatch(
      setFilter(menuName, "managingoffice", values.managingoffice || [])
    );
    dispatch(setFilter(menuName, "status", values.status || []));
    dispatch(setFilter(menuName, "owner", values.owner || []));
    dispatch(
      setFilter(
        menuName,
        "relationship_manager",
        values.relationship_manager || []
      )
    );
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  const uniqueStatus = uniqBy(
    statusCodes?.map((item) => {
      return { label: item?.Label, value: item?.Label };
    }),
    "value"
  );

  const uniqueName = uniqBy(
    clientList?.map((item) => {
      return {
        label: item?.name,
        value: item?.name,
      };
    }),
    "value"
  );

  const uniquemanagingOffice = uniqBy(
    crmManagingOfficeList?.map((item) => {
      return { label: item?.Label, value: item?.Value };
    }),
    "value"
  );

  const uniqueownervalue = uniqBy(
    clientList?.map((item) => {
      return {
        label:
          item?.["_ownerid_value@OData.Community.Display.V1.FormattedValue"],
        value:
          item?.["_ownerid_value@OData.Community.Display.V1.FormattedValue"],
      };
    }),
    "value"
  );

  const uniquerelationshipmanagervalue = uniqBy(
    clientList?.map((item) => {
      return {
        label:
          item?.[
            "_bdo_relationship_value@OData.Community.Display.V1.FormattedValue"
          ],
        value:
          item?.[
            "_bdo_relationship_value@OData.Community.Display.V1.FormattedValue"
          ],
      };
    }),
    "value"
  );

  const getFilteredData = () => {
    const name = form.getFieldValue("name") || [];
    const status = form.getFieldValue("status") || [];
    const owner = form.getFieldValue("owner") || [];
    const relationshipManager = form.getFieldValue("relationship_manager");
    if (
      name?.length > 0 ||
      status?.length > 0 ||
      owner?.length > 0 ||
      relationshipManager?.length > 0
    ) {
      return clientList?.filter((item) => {
        const nameMatch =
          name && name.length > 0 ? name.some((n) => item.name === n) : true;

        const statusMatch =
          status && status?.length > 0
            ? status.includes(
                item?.["statuscode@OData.Community.Display.V1.FormattedValue"]
              )
            : true;

        const ownerMatch =
          owner && owner?.length > 0
            ? owner.includes(
                item?.[
                  "_ownerid_value@OData.Community.Display.V1.FormattedValue"
                ]
              )
            : true;

        const relationshipManagerMatch =
          relationshipManager && relationshipManager?.length > 0
            ? relationshipManager.includes(
                item?.[
                  "_bdo_relationship_value@OData.Community.Display.V1.FormattedValue"
                ]
              )
            : true;

        return (
          nameMatch && statusMatch && ownerMatch && relationshipManagerMatch
        );
      });
    } else {
      return clientList;
    }
  };

  const filteredCount = totalFilteredClientCount || 0;

  return (
    <ClientWrapStyle>
      <Row>
        <Col sm={24}>
          <StyledWrap>
            <Form
              form={form}
              layout="vertical"
              className="mt-2"
              onValuesChange={handleFormChange}
              initialValues={{ ...initialFilter }}
            >
              <Row gutter={12} className="g-3 mb-2">
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <DropdownCheckBox
                    name="name"
                    label="Client Name"
                    options={uniqueName}
                    form={form}
                    handleFormChange={handleFormChange}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <DropdownCheckBox
                    name="managingoffice"
                    label="Managing Office"
                    options={uniquemanagingOffice}
                    form={form}
                    handleFormChange={handleFormChange}
                  />
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <DropdownCheckBox
                    name="status"
                    label="Status"
                    options={uniqueStatus}
                    form={form}
                    handleFormChange={handleFormChange}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <DropdownCheckBox
                    name="owner"
                    label="Owner"
                    options={uniqueownervalue}
                    form={form}
                    handleFormChange={handleFormChange}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <DropdownCheckBox
                    name="relationship_manager"
                    label="Relationship Manager"
                    options={uniquerelationshipmanagervalue}
                    form={form}
                    handleFormChange={handleFormChange}
                  />
                </Col>
              </Row>
            </Form>
          </StyledWrap>
        </Col>
      </Row>
      <div className="people_font_style">Total Clients: {filteredCount}</div>

      <Spin spinning={showLoader}>
        <div className="">
          <Table
            // dataSource={clientList}
            columns={columns}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="bdo_id"
            dataSource={getFilteredData()?.map((client) => ({
              ...client,
              "Client Name": (
                <Link
                  to={`/client-details/${client?.bdo_id}`}
                  style={fontStyle}
                >
                  {client?.bdo_id}
                </Link>
              ),
            }))}
            onRow={(record, index) => ({
              ref: index === clientList?.length - 20 ? lastElementRef : null,
              onClick: (event) => {
                event.stopPropagation();
                history.push(`/client-details/${record.bdo_id}`, {
                  extraData: clientList,
                });
              },
            })}
            pagination={false}
            footer={
              !showLoader
                ? () => (
                    <>
                      {loader && !showLoader && (
                        <div style={{ textAlign: "center" }}>
                          <Spin spinning={loader} />
                        </div>
                      )}
                    </>
                  )
                : false
            }
          />
        </div>
      </Spin>
    </ClientWrapStyle>
  );
};

const mapStateToProps = (state) => ({
  statusCodes: getStatusCodes(state),
});

export default connect(mapStateToProps, null)(Active);
