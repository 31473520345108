import React, { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import styled from "@emotion/styled";
import LeaveDonutChart from "./LeaveDonutChart";
import { getColor, LEAVE_ICON, LEAVE_INFO } from "common/Constants";
import COLORS from "common/Colors";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getLeaveCategoryList,
  getSelectedDepartmentId,
  getClientid,
} from "redux/selectors";
import DepartmentLeaveBalance from "./DepartmentLeaveBalance";
import { find, get } from "lodash";
import { dateInDetail, getKenyanDateTime } from "utils/Date";
import { CALL_API } from "common/API";
import DepartmentStaffLeaveBalance from "./DepartmentStaffLeaveBalance";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

function LeaveBifurcationReport({
  userRoleId,
  leaveCategoryList,
  selectedDepartmentId,
  clientId,
}) {
  const [displayDepartmentLeaveModal, toggleDepartmentLeaveModal] =
    useState(false);
  const [staffDepartmentLeaveModal, toggleStaffDepartmentLeaveModal] =
    useState(false);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState("");
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [displayLeaveReportLoader, toggleLeaveReportLoader] = useState(false);

  const [annualLeaveData, setAnnualLeaveData] = useState([
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: COLORS.LEAVE.ANNUAL_AVAILABLE,
    },
    {
      type: LEAVE_INFO.CONSUMED,
      value: 0,
      color: COLORS.LEAVE.ANNUAL_CONSUMED,
    },
  ]);
  const [sickLeaveData, setSickLeaveData] = useState([
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: COLORS.LEAVE.SICK_AVAILABLE,
    },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.SICK_CONSUMED },
  ]);
  const [sickLeavehalfpayData, setSickLeavehalfpayData] = useState([
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: COLORS.LEAVE.SICK_AVAILABLE,
    },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.SICK_CONSUMED },
  ]);
  const [restdadsData, setrestDaysData] = useState([
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: COLORS.LEAVE.SICK_AVAILABLE,
    },
    { type: LEAVE_INFO.CONSUMED, value: 0, color: COLORS.LEAVE.SICK_CONSUMED },
  ]);

  useEffect(() => {
    if (
      (userRoleId !== 1 && selectedDepartmentId !== 0) ||
      (userRoleId !== 6 && selectedDepartmentId !== 0) ||
      userRoleId === 1 ||
      userRoleId === 6
    ) {
      getStatistics();
    }
  }, []);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId !== 6) {
      getStatistics();
    }
  }, [selectedDepartmentId]);

  const getStatistics = async () => {
    toggleLeaveReportLoader(true);
    if (clientId) {
      const data = await CALL_API(`leave-service/${clientId}`, "post", {
        method: "overallTotalLeavesAvailableAndConsumed",
        parameters: {
          year: dateInDetail(getKenyanDateTime()).year,
          departmentId:
            userRoleId !== 1 && userRoleId !== 6 ? selectedDepartmentId : [""],
        },
      });
      // console.log("data", data)
      let annualAvailable = 0;
      let annualConsumed = 0;
      let sickAvailable = 0;
      let sickConsumed = 0;
      let sickleavehalfpayAvailable = 0;
      let sickleavehalfpayConsumed = 0;
      let restdaysAvailable = 0;
      let restdaysConsumed = 0;
      data.map((list) => {
        Object.entries(list).forEach(([key, value]) => {
          annualAvailable += value.annual.available;
          annualConsumed += value.annual.consumed;
          sickAvailable += value.sick.available;
          sickConsumed += value.sick.consumed;
          sickleavehalfpayAvailable += value.sickLeaveHalfpayHalfpay.available;
          sickleavehalfpayConsumed += value.sickLeaveHalfpayHalfpay.consumed;
          restdaysAvailable += value.restdays.available;
          restdaysConsumed += value.restdays.consumed;
        });
      });
      let dupeLeaveData = Array.from(annualLeaveData);
      dupeLeaveData[0].value = annualAvailable - annualConsumed;
      dupeLeaveData[1].value = annualConsumed;
      setAnnualLeaveData(dupeLeaveData);

      dupeLeaveData = Array.from(sickLeaveData);
      dupeLeaveData[0].value = sickAvailable - sickConsumed;
      dupeLeaveData[1].value = sickConsumed;
      setSickLeaveData(dupeLeaveData);

      dupeLeaveData = Array.from(sickLeavehalfpayData);
      dupeLeaveData[0].value =
        sickleavehalfpayAvailable - sickleavehalfpayConsumed;
      dupeLeaveData[1].value = sickleavehalfpayConsumed;
      setSickLeavehalfpayData(dupeLeaveData);

      dupeLeaveData = Array.from(restdadsData);
      dupeLeaveData[0].value = restdaysAvailable - restdaysConsumed;
      dupeLeaveData[1].value = restdaysConsumed;
      setrestDaysData(dupeLeaveData);

      toggleLeaveReportLoader(false);
    }
  };

  const openDetailWindow = (leaveTypeId) => {
    const leaveName = get(
      find(leaveCategoryList, { leave_category_id: leaveTypeId }),
      "leave_category_name",
      ""
    );
    setSelectedLeaveTypeId(leaveTypeId);
    setSelectedLeaveType(`Department's ${leaveName} Balance`);
    if (userRoleId === 1 || userRoleId === 6) {
      toggleDepartmentLeaveModal(true);
    } else {
      toggleStaffDepartmentLeaveModal(true);
    }
  };

  return (
    <>
      <StyledTitle>
        {userRoleId === 1 || userRoleId === 6
          ? "OVERALL LEAVE REPORT"
          : "DEPARTMENT LEAVES"}
      </StyledTitle>
      <Row gutter={16}>
        <Col
          md={12}
          sm={24}
          xs={24}
          xl={12}
          onClick={() => openDetailWindow(7)}
        >
          <Spin spinning={displayLeaveReportLoader}>
            <LeaveDonutChart
              iconName={LEAVE_ICON[7]}
              title="ANNUAL LEAVE"
              data={annualLeaveData}
              color={getColor(1)}
            />
          </Spin>
        </Col>
        <Col
          md={12}
          sm={24}
          xs={24}
          xl={12}
          onClick={() => openDetailWindow(1)}
        >
          <Spin spinning={displayLeaveReportLoader}>
            <LeaveDonutChart
              iconName={LEAVE_ICON[1]}
              title="SICK LEAVE"
              data={sickLeaveData}
              color={getColor(2)}
            />
          </Spin>
        </Col>
        <Col
          md={12}
          sm={24}
          xs={24}
          xl={12}
          onClick={() => openDetailWindow(2)}
        >
          <Spin spinning={displayLeaveReportLoader}>
            <LeaveDonutChart
              iconName={LEAVE_ICON[1]}
              title="SICK LEAVE HALFPAY"
              data={sickLeavehalfpayData}
              color={getColor(3)}
            />
          </Spin>
        </Col>
        <Col
          md={12}
          sm={24}
          xs={24}
          xl={12}
          onClick={() => openDetailWindow(3)}
        >
          <Spin spinning={displayLeaveReportLoader}>
            <LeaveDonutChart
              iconName={LEAVE_ICON[1]}
              title="REST DAYS"
              data={restdadsData}
              color={getColor(4)}
            />
          </Spin>
        </Col>
      </Row>
      <DepartmentLeaveBalance
        displayModal={displayDepartmentLeaveModal}
        toggleModal={() => toggleDepartmentLeaveModal(false)}
        leaveTypeId={selectedLeaveTypeId}
        title={selectedLeaveType}
      />
      <DepartmentStaffLeaveBalance
        displayModal={staffDepartmentLeaveModal}
        toggleModal={() => toggleStaffDepartmentLeaveModal(false)}
        leaveTypeId={selectedLeaveTypeId}
        selectedDepartmentId={selectedDepartmentId}
        leaveName={selectedLeaveType}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  leaveCategoryList: getLeaveCategoryList(state),
  clientId: getClientid(state),
});

export default connect(mapStateToProps, null)(LeaveBifurcationReport);
