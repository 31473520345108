import { WEB_SOCKET_CONNECTION_STATUS } from "common/Constants";
import {
  get as _get,
  find as _find,
  upperCase as _upperCase,
  filter as _filter,
  includes,
  filter,
} from "lodash";
import { dateInDetail, isDateBetweenTwoDates } from "utils/Date";

export const getLoadingDataFirstTime = (store) =>
  _get(store, `common.loadingDataFirstTime`);
export const getUserName = (store) => _get(store, `common.userInfo.name`);
export const getClientid = (store) => _get(store, `common.clientid`);
export const getTrailPeriod = (store) => _get(store, `common.trailperiod`);
export const getCurrentPlan = (store) => _get(store, `common.currentplan`);
export const getUserInfo = (store) => _get(store, `common.userInfo`);
export const getUserRoleId = (store) =>
  _get(store, `common.userInfo.roleId`, "");
export const getUserRole = (store) =>
  _get(store, `common.userInfo.userRoleType`, "");
export const getToggleDrawer = (store) =>
  _get(store, `common.toggleDrawer`, "");
export const getIsUserLoggedIn = (store) =>
  _get(store, `common.isLoggedIn`, "");
export const getSelectedDepartmentId = (store) =>
  _get(store, `common.selectedDepartmentId`, []);
export const getDepartmentList = (store) =>
  _get(store, `common.departmentList`, []);
export const getAllDepartmentList = (store) =>
  _get(store, `common.allDepartmentList`, []);
export const getSubscriptionId = (store) =>
  _get(store, `common.subscriptionId`, "");
export const getSubscriptionStatus = (store) =>
  _get(store, `common.subscriptionStatus`, "");

export const getLoadingRoute = (store) =>
  _get(store, `common.loadingRoute`);

export const getRoleList = (store) => _get(store, `common.roleList`, []);
export const getUserRoleList = (store) =>
  _get(store, `common.userRoleList`, []);
// export const getRoleName = (store) => _get(store, `common.roleName`, '');
export const getRoleName = (store) => {
  const roleid = _get(store, `common.userInfo.roleId`, "");
  return _get(
    _find(getRoleList(store), ({ roleId }) => {
      return roleid === roleId;
    }),
    "roleName",
    ""
  );
};
export const getDepartmentName = (store) => {
  // const departmentId = parseInt(_get(store, `common.selectedDepartmentId`, 0));
  const departmentId = getSelectedDepartmentId(store);
  const departmentList = getDepartmentList(store);
  const departmentNameList = [];
  departmentList.map((list) => {
    if (includes(departmentId, list.department_id)) {
      departmentNameList.push(list.department_name);
    }
  });
  return departmentNameList;
  // return _get(_find(getDepartmentList(store), ({department_id}) => {
  // return department_id === departmentId;
  // return includes(departmentId, department_id)
  // }), 'department_name', [])
};
export const getSysteDate = (store) => _get(store, `common.date`, "");

export const getTime = (store) => {
  const dateInfo = dateInDetail(getSysteDate(store));
  return _get(dateInfo, "time", "");
};

export const getTime24 = (store) => {
  const dateInfo = dateInDetail(getSysteDate(store));
  return _get(dateInfo, "time24", "");
};

export const getAMPM = (store) => {
  const dateInfo = dateInDetail(getSysteDate(store));
  return _upperCase(_get(dateInfo, "meridiem", ""));
};

export const getLongDay = (store) => {
  const dateInfo = dateInDetail(getSysteDate(store));
  return `
        ${_get(dateInfo, "day", "")},
        ${_get(dateInfo, "monthName", "")} ${_get(dateInfo, "date", "")},
        ${_get(dateInfo, "year", "")}
    `;
};

export const getLocations = (store) => _get(store, `common.locationList`, []);

export const getBreakTypeList = (store) =>
  _get(store, `common.breakTypeList`, []);

export const getCheckInDateTime = (store) =>
  _get(store, `common.checkInDateTime`, "");

export const getCheckOutDateTime = (store) =>
  _get(store, `common.checkOutDateTime`, "");

export const getIsOnBreak = (store) => _get(store, `common.isOnBreak`, false);
export const getIsCheckedIn = (store) =>
  _get(store, `common.isCheckedIn`, false);
export const getCheckInLocationId = (store) =>
  _get(store, `common.checkInLocationId`, "");
export const getCheckInDepartmentId = (store) =>
  _get(store, `common.checkInDepartmentId`, "");

export const getToggleSuccessModal = (store) =>
  _get(store, `common.displaySuccessModal`, false);

export const getSuccessModalMessage = (store) =>
  _get(store, `common.successModalMessage`, false);

export const getUserList = (store) => _get(store, `common.userList`, []);

export const getNotificationList = (store) =>
  _get(store, `common.notificationList`, []);

export const getDepartmentUserList = (store) => {
  const departmentId = getSelectedDepartmentId(store);
  // return _filter(_get(store, `common.userList`, []), { 'department_id': departmentId });
  let output = _filter(_get(store, `common.userList`, []), (v) =>
    includes(departmentId, v.departmentid)
  );
  return output;
};

export const getToggleProcessingDrawer = (store) =>
  _get(store, `common.displayProcessingModal`, "");

export const getLeaveCategoryList = (store) =>
  _get(store, `common.leaveCategoryList`, []);

export const getHolidayList = (store) => _get(store, `common.holidayList`, []);

export const getHolidayListBetweenDates = (store, startDate, endDate) => {
  const holidayList = _get(store, `common.holidayList`, []);
  const holidays = filter(
    holidayList,
    ({ date }) => isDateBetweenTwoDates(date, startDate, endDate),
    {}
  );
  return holidays;
};

export const getStatusList = (store) => _get(store, `common.statusList`, []);

export const getBreakId = (store) => _get(store, `common.breakId`, []);

export const getPunchId = (store) => _get(store, `common.punchId`, "");

export const getWebSocketStatus = (store) =>
  _get(store, `common.webSocketStatus`, []);

export const getWebSocketStatusDisplay = (store) =>
  WEB_SOCKET_CONNECTION_STATUS[getWebSocketStatus(store)];

export const getChatMessageList = (store) =>
  _get(store, `common.chatMessageList`, []);

export const getUnreadChatMessageList = (store) =>
  _get(store, `common.newMessageIndicatorList`, []);

export const getChatMessageQueue = (store) =>
  _get(store, `common.chatMessageQueue`, []);

export const getChatSelectedStaffId = (store) =>
  _get(store, `common.chatSelectedStaffId`, []);

export const getShiftTimeMaster = (store, userid) => {
  const allShifts = _get(store, `common.shiftTimeMaster`, []);
  let userShifts = filter(allShifts, { user_id: userid });
  return userShifts.length > 0 ? userShifts : filter(allShifts, { user_id: 0 });
};

export const getStatusListFromID = (store, id) => {
  return _filter(_get(store, `common.statusList`, []), ({ status_id }) =>
    includes(id, status_id)
  );
};

export const getProfilePic = (store) => _get(store, `common.profilePic`, []);
export const getMenuList = (store) => _get(store, `common.menuList`, []);
export const getActiveMenuList = (store) => _get(store, `common.activeMenuList`);
export const getBandList = (store) => _get(store, `common.bandList`, []);
export const getUserMenuList = (store) =>
  _get(store, `common.userMenuList`, []);
export const getAIChatHistory = (store) =>
  _get(store, `common.aiChatHistory`, []);
export const getCurrentAIMessageDetails = (store) =>
  _get(store, `common.currentAIMessageDetails`, {});
export const getFormName = (store) => _get(store, `common.formName`);
export const getSupervisorList = (store) =>
  _get(store, `common.supervisorList`, []);
export const getlevelList = (store) => _get(store, `common.levelList`, []);
export const getCachedTimesheets = (store) => _get(store, `common.cachedTimeSheets`);
export const getFilters = (store) => _get(store, `common.filters`);
export const getFilteredClientData = (store) => _get(store, `common.filterClientData`);
export const getTempEngagementFormData = (store) => _get(store, `common.tempengagementformdata`);
export const getEngagementSelectedTab = (store) => _get(store, `common.engagementselectedtab`);
export const getEngagementMainTab = (store) => _get(store, `common.engagementmaintab`);
export const getResourceData = (store) => _get(store, `common.resourceData`);
export const getallRoleList = (store) => _get(store, `common.allRoleList`, []);
export const getallLocationList = (store) =>
  _get(store, `common.allLocation`, []);
export const getallCompetencyList = (store) =>
  _get(store, `common.allCompetency`, []);
export const getallBreakList = (store) => _get(store, `common.allBreak`, []);
export const getallLeaveList = (store) => _get(store, `common.allLeave`, []);
export const getallShiftList = (store) => _get(store, `common.allShift`, []);
export const getStatusCodes = (store) => _get(store, `common.statusCodes`, []);
export const getallHolidayList = (store) =>
  _get(store, `common.allHoliday`, []);
export const getCRMManagingOfficeList = (store) =>
  _get(store, `common.crmManagingOfficeList`, []);

export const getManagingOffice = (store, userid) => {
  const manageoffice = _get(store, `common.levelList`, []);
  let level1_values = [];
  manageoffice.forEach((level) => {
    switch (level.levelName) {
      case "Level1":
        level.value.forEach((value) => {
          value.value.forEach((item) => {
            level1_values.push(item);
          });
        });
        break;
      default:
        break;
    }
  });
  return level1_values;
};

export const getCompetencyQc = (store, userid) => {
  const data = _get(store, `common.levelList`, []);
  let level1_values = [];
  data.forEach((level) => {
    switch (level.levelName) {
      case "Level2":
        level.value.forEach((value) => {
          value.value.forEach((item) => {
            level1_values.push(item);
          });
        });
        break;
      default:
        break;
    }
  });
  return level1_values;
};
export const getServiceLine = (store, userid) => {
  const data = _get(store, `common.levelList`, []);
  let level1_values = [];
  data.forEach((level) => {
    switch (level.levelName) {
      case "Level3":
        level.value.forEach((value) => {
          value.value.forEach((item) => {
            level1_values.push(item);
          });
        });
        break;
      default:
        break;
    }
  });
  return level1_values;
};