import { Col, Form, Row, Table } from 'antd';
import { DropdownCheckBox } from 'atoms/UIDropdown/Dropdown';
import COLORS from 'common/Colors';
import React from 'react';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  /* padding-top: 20px; */
  table {
    table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
  }
  .ant-table-cell {
    padding: 10px;
    height: 0px !important;
    border: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: none;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function Industry({data}) {
  const [form] = Form.useForm();
    const tempdata = [{
        createdon: "23/05/2022 12.30pm",
        industrytype: "Primary",
        industry: "Manufacturing and distribution",
        sector: "Iron & steel, metal based engineering"
    }]
    const columns = [
        {
          title: "Industry Type",
          dataIndex: "industrytype",
          key: "industrytype",
          width: "10%",
          sorter: (a, b) => a.industrytype.localeCompare(b.industrytype),
        },
        {
          title: "Industry",
          dataIndex: "industry",
          key: "industry",
          width: "20%",
          className: "normal-column",
          sorter: (a, b) => a.industry.localeCompare(b.industry),
        },
        {
          title: "Sector",
          dataIndex: "sector",
          key: "sector",
          width: "20%",
          className: "normal-column",
          sorter: (a, b) => a.sector.localeCompare(b.sector),
        },
        {
          title: "Created on",
          dataIndex: "createdon",
          key: "createdon",
          width: "20%",
          className: "normal-column",
          sorter: (a, b) => a.createdon.localeCompare(b.createdon),
        },
      ];
  return (
    <>
    <Row>
      <Col sm={24} md={6}>
      <DropdownCheckBox
                        name="name"
                        label="Search Industry name"
                        // options={uniqueNames}
                        form={form}
                      />
      </Col>
    </Row>
    
    <StyledTable
            className="mb-5"
            dataSource={data?.industry}
            columns={columns}
            rowKey="role_id"
            pagination={false}
          />
    </>
  )
}

export default Industry