import React, { useCallback, useEffect, useMemo } from "react";
import { DatePicker, Input, Select, Spin } from "antd";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "assets/icons/UI/search.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Table from "atoms/Table";
import queryKeys from "common/queryKey";
import moment from "moment";
import { useScrollFetchPost2 } from "hooks/useScrollFetchPost2";
import { useSelector } from "react-redux";
import { getUserInfo } from "redux/selectors";

const { RangePicker } = DatePicker;

const timeSheetColumns = [
  {
    title: "Period",
    dataIndex: "period",
    key: "period",
    width: 230,
  },
  {
    title: "Name",
    dataIndex: "employee_name",
    key: "employee_name",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Timesheet Hours",
    dataIndex: "timesheet_hours",
    key: "timesheet_hours",
    align: "center",
  },
  {
    title: "Total Claims",
    dataIndex: "total_claims",
    key: "total_claims",
    align: "center",
  },
  {
    title: "Total Tasks",
    dataIndex: "total_tasks",
    key: "total_tasks",
    align: "center",
  },
];

const generateWeeksBetweenDates = (dateRange) => {
  if (dateRange?.length === 0 || dateRange === undefined || dateRange === null)
    return [];
  let startWeek = moment(dateRange[0]).startOf("isoWeek");
  const endWeek = moment(dateRange[1]).endOf("isoWeek");
  const weeks = [];
  while (startWeek <= endWeek) {
    let endOfWeek = moment(startWeek).add(6, "days"); // create a new moment object for the end of the week
    weeks.push(
      `${startWeek.format("YYYYMMMDD").toUpperCase()}-${endOfWeek
        .format("YYYYMMMDD")
        .toUpperCase()}`
    );
    startWeek = startWeek.add(1, "week");
  }
  return weeks;
};

const TimeSheetApproval = ({ timeSheetPermission }) => {
  const history = useHistory();
  const [dateRange, setDateRange] = React.useState([]);
  const [searchName, setSearchName] = React.useState("");
  const [status, setStatus] = React.useState("pending");
  const { getAdminApprovalTimesheet } = queryKeys;
  const userInfo = useSelector(getUserInfo);

  const { key, url } = getAdminApprovalTimesheet(
    JSON.stringify(generateWeeksBetweenDates(dateRange))
  );

  const {
    data,
    isLoading: loader,
    refetch,
  } = useScrollFetchPost2(`key-${status}`, {
    endPoint: url,
    queryParam: "LastEvaluatedKey",
    extra: {
      weeks: generateWeeksBetweenDates(dateRange),
      statusArray: [status],
      employeeId: [],
      userId: userInfo?.userid,
    },
  });

  const invalidateTimeSheet = useCallback(() => {
    refetch();
  }, [refetch]);

  const timesheetsData = useMemo(() => {
    if (!data) return [];
    return data?.pages?.reduce((acc, page) => {
      if (page?.response?.response === undefined) return acc;
      return [...acc, ...page?.response?.response];
    }, []);
  }, [data]);

  const [timeSheets, setTimeSheets] = React.useState([]);

  useEffect(() => {
    if (timesheetsData) {
      setTimeSheets(
        timesheetsData
          ?.filter((timesheet) =>
            timesheet?.employeeName
              ?.toLowerCase()
              .includes(searchName.toLowerCase())
          )
          ?.map((timesheet) => {
            const workingHours = timesheet?.timeSheet?.reduce((acc, day) => {
              return (
                acc +
                (day?.mon || 0) +
                (day?.tue || 0) +
                (day?.wed || 0) +
                (day?.thu || 0) +
                (day?.fri || 0) +
                (day?.sat || 0) +
                (day?.sun || 0)
              );
            }, 0);

            const totalClaims = timesheet?.timeSheet?.reduce((acc, day) => {
              const claims = day?.claims?.reduce((acc, claim) => {
                return acc + (parseInt(claim?.amount) || 0);
              }, 0);
              return acc + claims;
            }, 0);

            const hasTask = (task) => {
              return task !== null ? 1 : 0;
            };

            const totalTasks = timesheet?.timeSheet?.reduce((acc, day) => {
              return acc + hasTask(day?.task);
            }, 0);

            const startWeek = timesheet?.week?.split("-")[0];
            const endWeek = timesheet?.week?.split("-")[1];
            const formattedPeriod = `${moment(startWeek, "YYYYMMMDD").format(
              "DD-MMM-YYYY"
            )} to ${moment(endWeek, "YYYYMMMDD").format("DD-MMM-YYYY")}`;

            const hasNc = timesheet?.timeSheet?.some(
              (sec) => sec.engagement === "nc"
            );

            const hasC = timesheet?.timeSheet?.some(
              (sec) => sec.engagement !== "nc"
            );

            const hasBoth = hasNc && hasC;

            const type = hasBoth ? "NC & C" : hasNc ? "NC" : "C";

            return {
              timesheet_id: timesheet?.timesheetId,
              week: timesheet?.week,
              type: type,
              employeeId: timesheet?.employeeId,
              employee_name: (
                <div className="employee-name">{timesheet?.employeeName}</div>
              ),
              period: <div className="period">{formattedPeriod}</div>,
              timesheet_hours: workingHours,
              total_claims: totalClaims,
              total_tasks: totalTasks,
            };
          })
      );
    }
  }, [timesheetsData, searchName, invalidateTimeSheet]);

  return (
    <TimeSheetWrap>
      <div className="filters">
        <Input
          placeholder="Search by name"
          style={{
            width: 300,
          }}
          value={searchName}
          onChange={(e) => {
            setSearchName(e.target.value);
          }}
          prefix={<SearchIcon />}
        />

        <RangePicker
          value={dateRange}
          onChange={(e) => {
            setDateRange(e);
          }}
        />

        <Select
          value={status}
          style={{ width: 120 }}
          onChange={(value) => {
            setStatus(value);
          }}
          options={[
            { label: "Pending", value: "pending" },
            { label: "Approved", value: "approved" },
            { label: "Declined", value: "declined" },
          ]}
        />
      </div>

      <div className="timesheet">
        <Spin spinning={loader}>
          <Table
            // sticky={true}
            // scroll={{ y: 400 }}
            dataSource={timeSheets || []}
            columns={timeSheetColumns}
            onRow={(record) => {
              return {
                onClick: (event) => {
                  history.push(
                    `/timesheet-detailed/${record?.week}/${record?.employeeId}`
                  );
                },
              };
            }}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="timesheet_id"
            pagination={false}
          />
        </Spin>
      </div>
    </TimeSheetWrap>
  );
};

export default TimeSheetApproval;

const TimeSheetWrap = styled.div`
  .filters {
    display: flex;
    gap: 12px;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
  }
  .timesheet {
    padding-top: 10px;
  }
  .action-buttons {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    font-weight: 600;
  }
  .period {
    font-weight: 600;
    color: #397ef3;
    cursor: pointer;
  }
  .employee-name {
    font-weight: 600;
  }
`;
