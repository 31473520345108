import { Col, Form, Row } from "antd";
import Table from "atoms/Table";
import Title from "atoms/Title";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import queryKeys from "common/queryKey";
import { useScrollFetch } from "hooks/useScrollFetch";
import { uniqBy } from "lodash";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "redux/actions";
import { getClientid, getFilters, getUserList } from "redux/selectors";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import { utils, writeFileXLSX } from "xlsx";
import BackLogRender from "./BackLogRender";
import styled from "styled-components";

function TimesheetReport() {
  const clientId = useSelector(getClientid);
  const userList = useSelector(getUserList);

  const columns = [
    {
      title: "Name",
      dataIndex: "employeeName",
      key: "employeeName",
    },
    {
      title: "Email",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      title: "Pending",
      dataIndex: "pending",
      key: "pending",
    },
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
    },
    {
      title: "Backlog",
      dataIndex: "backlog",
      key: "backlog",
      render: (_text, record) => {
        return <BackLogRender record={record} />;
      },
    },
  ];

  const [form] = Form.useForm();

  const filters = useSelector(getFilters);
  const menuName = "timesheetReport";
  const initialFilter = filters[menuName];

  const dispatch = useDispatch();

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    dispatch(setFilter(menuName, "name", values.name || []));
  };

  const { getAllTimesheetReport } = queryKeys;

  const { key, url } = getAllTimesheetReport(
    clientId,
    filters?.alldeclaration?.name ?? []
  );

  const { data, lastElementRef, isLoading } = useScrollFetch(key, {
    endPoint: url,
    queryParam: "LastEvaluatedKey",
    extra: {
      clientId: clientId,
    },
  });

  const dataList = useMemo(() => {
    if (data) {
      return data?.pages?.reduce((acc, page) => {
        if (page?.response === undefined) return acc;
        return [...acc, ...page?.response];
      }, []);
    } else {
      return [];
    }
  }, [data]);

  const allDataList = userList?.map((user) => {
    const report = dataList?.find((item) => {
      return item?.employeeId === user?.userid;
    });

    if (report) {
      return report;
    }

    return {
      employeeId: user?.userid,
      employeeName: user?.fullname,
      pending: 0,
      approved: 0,
      backlog: 0,
    };
  });

  const userOptions = allDataList?.map((user) => {
    return {
      label: user?.employeeName,
      value: user?.employeeId,
    };
  });

  const getFilteredData = () => {
    const name = form.getFieldValue("name") || [];

    const country = form.getFieldValue("managingoffice") || [];
    const status = form.getFieldValue("status") || [];

    if (name.length > 0 || status.length > 0 || country.length > 0) {
      return allDataList?.filter((item) => {
        const nameMatch =
          name && name.length > 0 ? name.includes(item.employeeId) : true;

        return nameMatch;
      });
    } else {
      return allDataList;
    }
  };

  const xport = React.useCallback(() => {
    const elt = document.getElementById("Table");
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "Timesheet Report.csv");
  }, []);

  return (
    <TimeSheetReportWrap>
      <Row>
        <Col sm={24}>
          <div className="d-flex justify-content-between">
            <Title>Timesheet Report</Title>
            <ButtonOutlined className="text-end" onClick={xport} type="link">
              Export CSV file
            </ButtonOutlined>
          </div>
        </Col>
        <Col sm={24}>
          <Form
            form={form}
            layout="vertical"
            className="mt-2"
            onValuesChange={handleFormChange}
            initialValues={{ ...initialFilter }}
          >
            <Row gutter={12} className="g-3 mb-2">
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="name"
                  label="Name"
                  options={uniqBy(userOptions, "label")}
                  form={form}
                  handleFormChange={handleFormChange}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Table
        className="pt-0"
        dataSource={getFilteredData() ?? []}
        columns={columns}
        pagination={false}
        loading={isLoading}
        onRow={(record, index) => ({
          ref: index === allDataList?.length - 20 ? lastElementRef : null,
        })}
        id="Table"
      />
    </TimeSheetReportWrap>
  );
}

export default TimesheetReport;

const TimeSheetReportWrap = styled.div`
  .backlog {
    cursor: pointer;
    color: #1890ff;
  }
`;
