import React, { useState } from "react";
import styled from "styled-components";
import ClientOnBoardJSON from "JsonFormat/ClientOnBoard";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import ClientOnBoardProspectiveJSON from "JsonFormat/ClientOnBoard/newprospective";
import { Button } from "antd";
import { CALL_API_UPDATED } from "common/API";
import { message } from "antd";
import { STATUS_CODE } from "common/Constants";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ClientOnBoardWrap = styled.div`
  .main-heading {
    color: #1b1b1b;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  .text-end {
    text-align: end;
  }
  .px_2 {
    padding: 0 10px;
  }

  .ant-btn-default {
    background-color: #c2fae5;
    border-radius: 5px;
    border: none;
    font-size: 15px;
    color: #000000;
    font-weight: 500;
  }

  .form-container {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .form-container .ant-form-item {
    margin-bottom: 3px;
  }

  .ant-btn-default {
    background-color: #c2fae5;
    border-radius: 5px;
    font-size: 15px;
    color: #000000;
    border: none;
    font-weight: 500;
  }

  .form-container .ant-form-item-label {
    padding: 0;
  }

  .form-container .ant-form-item-label > label {
    margin: 15px 0px 5px 0;
  }

  .px_3 {
    padding: 0 10px;
  }

  .ml-5 {
    margin: 0 0 0 5px;
  }

  .mx-3 {
    margin: 0 10px;
  }

  .sub-title-bg {
    background: #ededed;
    color: #1b1b1b;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    padding: 10px;
    font-weight: 500;
  }
  .ant-radio-group {
    display: flex;
    width: 100%;
  }
  .ant-radio-button-wrapper-checked {
    background: rgb(194, 250, 229);
  }
  .ant-radio-button-wrapper:first-child {
    border: none;
  }
  .ant-radio-button-wrapper:last-child {
    border: none;
  }
  .ant-radio-button-wrapper:hover {
    background: rgb(194, 250, 229);
  }
  .ant-radio-button-wrapper {
    text-align: center;
    width: 50%;
    background: #f2f2f2;
    margin: 0 3px;
    box-sizing: border-box;
    borderradius: 5px 0px 0px 0px;
    border: none;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
  }
  .upload_docs .ant-btn-default {
    background: #f1f2f2;
    border: none;
    width: 100%;
    margin: 15px 0;
    min-height: 80px;
  }
  .upload_docs .ant-upload.ant-upload-select {
    display: block;
  }
`;

const loaderStyle = { fontSize: "16px", color: "#000" };

const ClientOnBoard = () => {
  const { id } = useParams();
  const history = useHistory();
  const { ClientOnBoard } = ClientOnBoardJSON();
  const { ProspectiveClient } = ClientOnBoardProspectiveJSON();
  const [clientOnBoardData, setClientOnBoardData] = useState(ClientOnBoard);
  const [newProspectiveClient, setNewProspectiveClient] =
    useState(ProspectiveClient);
  const [showLoader, toggleLoader] = useState(false);

  const handleAddNewField = () => {
    const updatedClientOnBoardData = { ...clientOnBoardData };

    const formbuilderFieldIndex =
      updatedClientOnBoardData.pages[0].fields.findIndex((row) =>
        row.some((field) => field.type === "formbuilder")
      );
    if (formbuilderFieldIndex !== -1) {
      const formbuilderFieldData = updatedClientOnBoardData.pages[0].fields[
        formbuilderFieldIndex
      ].find((field) => field.type === "formbuilder");

      const currentLength =
        formbuilderFieldData.options[1].defaultOption.length;
      const newIdCountry = currentLength + 1;
      const newIdAffiliate = newIdCountry + 1;

      const newObj1 = {
        id: newIdCountry,
        name: "scopecountry",
        value: "",
      };
      const newObj2 = {
        id: newIdAffiliate,
        name: "scopeAffiliate",
        value: "",
      };

      formbuilderFieldData.options[1].defaultOption.push(newObj1, newObj2);

      updatedClientOnBoardData.pages[0].fields[formbuilderFieldIndex].splice(
        0,
        1,
        formbuilderFieldData
      );
      setClientOnBoardData(updatedClientOnBoardData);
    }
  };
  const handleRemoveFormBuilderOptions = (optionIdsToRemove) => {
    const updatedClientOnBoardData = { ...clientOnBoardData };

    const formbuilderFieldIndex =
      updatedClientOnBoardData.pages[0].fields.findIndex((row) =>
        row.some((field) => field.type === "formbuilder")
      );

    if (formbuilderFieldIndex !== -1) {
      const formbuilderFieldData = updatedClientOnBoardData.pages[0].fields[
        formbuilderFieldIndex
      ].find((field) => field.type === "formbuilder");

      // Filter out the options to remove
      const filteredOptions =
        formbuilderFieldData.options[1].defaultOption.filter(
          (option) => !optionIdsToRemove.includes(option.id)
        );

      // Update the default options array with filtered options
      formbuilderFieldData.options[1].defaultOption = filteredOptions;

      updatedClientOnBoardData.pages[0].fields[formbuilderFieldIndex].splice(
        0,
        1,
        formbuilderFieldData
      );

      setClientOnBoardData(updatedClientOnBoardData);
    }
  };

  const handleResetFormBuilderOptions = () => {
    const updatedClientOnBoardData = { ...clientOnBoardData };

    const formbuilderFieldIndex =
      updatedClientOnBoardData.pages[0].fields.findIndex((row) =>
        row.some((field) => field.type === "formbuilder")
      );

    if (formbuilderFieldIndex !== -1) {
      const formbuilderFieldData = updatedClientOnBoardData.pages[0].fields[
        formbuilderFieldIndex
      ].find((field) => field.type === "formbuilder");

      formbuilderFieldData.options[1].defaultOption.forEach((option) => {
        option.value = ""; // Resetting value to empty string
      });
      formbuilderFieldData.options[1].defaultOption.splice(2);

      updatedClientOnBoardData.pages[0].fields[formbuilderFieldIndex].splice(
        0,
        1,
        formbuilderFieldData
      );

      setClientOnBoardData(updatedClientOnBoardData);
    }
  };

  const [clientDetailForm, setClientDetailsForm] = useState({
    name: "",
    sector: "",
    location: "",
    nameofdirectors: "",
    clientlist: "",
    scope: "",
    socialMedia: "",
    clientCountries: [],
    parentcompany: "",
    bdoservices: "",
    serviceprovider: "",
    bankdispute: "",
    attachedFile: "",
    designation: "",
    conflictIntrest: "",
    firmEligibility: "",
    clientreputation: "",
    prevAuditors: "",
    eligibilClient: "",
    acceptableFee: "",
    internalcompetence: "",
    reviewerRisk: "",
    riskAml: "",
    comment: "",
    confirmartionCheckBox: false,
    finaldesignation: "",
  });
  const [cilentCountry, setClientCountry] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setClientDetailsForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSelectChange = (e, fileType, name) => {
    if (e) {
      handleFile(e);
      setClientDetailsForm((prevState) => ({
        ...prevState,
        attachedFile: e?.file?.name,
      }));
    }
  };

  const handleSelectSignChange = (e, fileType, name) => {
    if (e) {
      handleFile(e);
      setClientDetailsForm((prevState) => ({
        ...prevState,
        attachedSign: e?.file?.name,
      }));
    }
  };

  const handleFile = async (e) => {
    try {
      const response = await CALL_API_UPDATED(
        `/insyts/clients/attachfile`,
        "get"
      );
      await fileApi(response.url, e);
    } catch (error) {
      console.error("Error fetching file URL:", error);
    }
  };

  const fileApi = async (puturl, e) => {
    try {
      const response = await axios.put(`${puturl}`, e);
      // Handle response
      console.log("response of file upload", response.status);
      if (response.status === 200) {
        message.success("File Successfully Attached");
      } else {
        message.error(`Oops!! something went wrong.`);
      }
    } catch (error) {
      // Handle error
      console.error("Error:", error);
    }
  };

  const resetForm = () => {
    setClientCountry([]);
    handleResetFormBuilderOptions();
    setClientDetailsForm({
      name: "",
      sector: "",
      location: "",
      nameofdirectors: "",
      clientlist: "",
      scope: "",
      socialMedia: "",
      clientCountries: [
        {
          scopecountry: "",
          scopeAffiliate: "",
        },
      ],
      parentcompany: "",
      bdoservices: "",
      serviceprovider: "",
      bankdispute: "",
      attachedFile: "",
      designation: "",
      conflictIntrest: "",
      firmEligibility: "",
      clientreputation: "",
      prevAuditors: "",
      eligibilClient: "",
      acceptableFee: "",
      internalcompetence: "",
      reviewerRisk: "",
      riskAml: "",
      comment: "",
      confirmartionCheckBox: false,
      attachedSign: "",
      finaldesignation: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value, checked, id } = e.target;
console.log('e-->',e)
    if (id == "confirmartionCheckBox") {
      setClientDetailsForm((prevState) => ({
        ...prevState,
        [id]: checked,
      }));
      console.log("checked", checked);
    } else {
      setClientDetailsForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleFormBuilderInputChange = (e, rowIndex) => {
    const updatedClientOnBoardData = { ...clientOnBoardData };

    const formbuilderFieldIndex =
      updatedClientOnBoardData.pages[0].fields.findIndex((row) =>
        row.some((field) => field.type === "formbuilder")
      );
    if (formbuilderFieldIndex !== -1) {
      const formbuilderFieldData = updatedClientOnBoardData.pages[0].fields[
        formbuilderFieldIndex
      ].find((field) => field.type === "formbuilder");
      if (
        formbuilderFieldData &&
        formbuilderFieldData.options &&
        formbuilderFieldData.options[1].defaultOption
      ) {
        const options = formbuilderFieldData.options[1].defaultOption;
        const optionToUpdate = options.find((option) => option.id === rowIndex);
        if (optionToUpdate) {
          optionToUpdate.value = e.target.value;

          setClientCountry(
            updatedClientOnBoardData.pages[0].fields[7][0].options[1]
              .defaultOption
          );
          setClientOnBoardData(updatedClientOnBoardData);
        } else {
          console.error("Field with id", rowIndex, "not found.");
        }
      } else {
        console.error("Options array is not properly initialized.");
      }
    } else {
      console.error("Formbuilder field not found.");
    }
  };

  const handleApproveForm = () => {
    const requiredFields = ClientOnBoard.pages[0].fields
      .flatMap((row) =>
        row.filter(
          (field) =>
            field.validationRules &&
            field.validationRules.some((rule) => rule.required)
        )
      )
      .map((field) => field.name);

    const requiredFieldsinNew = ProspectiveClient.pages[0].fields
      .flatMap((row) =>
        row.filter(
          (field) =>
            field.validationRules &&
            field.validationRules.some((rule) => rule.required)
        )
      )
      .map((field) => field.name);

    const missingFields = requiredFields.filter(
      (field) => !clientDetailForm[field] // Add checkbox check
    );

    const allFieldsFilledIn = requiredFieldsinNew.filter(
      (field) => !clientDetailForm[field]
    );

    console.log("allFieldsFilledIn", allFieldsFilledIn);

    if (missingFields.length > 0 && allFieldsFilledIn.length > 0) {
      message.error("Please fill in all required fields");
    }

    if (
      (missingFields.length == 0 || missingFields.length == null) &&
      (allFieldsFilledIn.length == 0 || allFieldsFilledIn.length == null)
    ) {
      onFinish();
    } else {
      console.log("err");
      message.error("Please fill in all required fields");
    }
  };

  const onFinish = async () => {
    toggleLoader(true);
    const fullformData = {
      clientId: id,
      onboardingdetails: [
        {
          clientDetails: {
            "Full name of entity": clientDetailForm.name,
            "Activities/sector details": clientDetailForm.sector,
            "Location & contact details": clientDetailForm.location,
            "Name of directors & officers": clientDetailForm.nameofdirectors,
            "Client is a listed/ Regulated/ PIE company":
              clientDetailForm.clientlist,
            "Scope of service required from BDO": clientDetailForm.bdoservices,
            "Web link/ Social media presence": clientDetailForm.socialMedia,
            "Countries that the prospective clients are related group operate in":
              cilentCountry,
            "Parent company Listed/ Regulated/ PIE company":
              clientDetailForm.parentcompany,
            "Have you or any affiliate or any officer/ director ever used BDO for professional services?":
              clientDetailForm.bdoservices,
            "Who were your other professional service provider? (Previous and Current)":
              clientDetailForm.serviceprovider,
            "Please provide us information on any bank dispute, anti-money, political exposer to reputational issues faced by the entity or its directors & officers, or any other information that BDO should be aware of, if any?":
              clientDetailForm.bankdispute,
            attachedFile: clientDetailForm.attachedFile,
            Designation: clientDetailForm.designation,
          },
          "New prospective client (PC) acceptance assessment": {
            "Conflict of interest required": clientDetailForm.conflictIntrest,
            "The firm's eligibility and independence":
              clientDetailForm.firmEligibility,
            "The prospective client (PC) reputation":
              clientDetailForm.clientreputation,
            "The PC's previous auditors/advisors":
              clientDetailForm.prevAuditors,
            "The firm's eligibility to serve the client":
              clientDetailForm.eligibilClient,
            "The PC's ability to pay acceptable fees":
              clientDetailForm.acceptableFee,
            "PC organisation's internal competence":
              clientDetailForm.internalcompetence,
            "Need for concurring reviewer based on risks?":
              clientDetailForm.reviewerRisk,
            "Risks-AML check, world check and other risks":
              clientDetailForm.riskAml,
            Comment: clientDetailForm.comment,
            "I have performed the firm's required acceptance procedure, as indicated above and discussed the same with partners and managers in a meeting. Based on the information provided in the onboarding form and feedback from all partners and managers, I approve the acceptance of the engagement":
              clientDetailForm.confirmartionCheckBox,
            attachedSign: clientDetailForm.attachedSign,
            Designation: clientDetailForm.finaldesignation,
          },
        },
      ],
    };
    const response = await CALL_API_UPDATED(
      `/insyts/clients/changestatus?status=onboard`,
      "post",
      fullformData
    );
    if (response) {
      toggleLoader(false);
      resetForm();
      history.push("/client");
    } else {
      toggleLoader(false);
    }
    if (response.status === true) {
      message.success("Successfully Added");
    } else if (response.status === STATUS_CODE.INVALID_PAYLOAD) {
      message.error(`Invalid payload. Please enter correct data.`);
    } else if (response.status === STATUS_CODE.RECORD_EXIST) {
      message.error(`Leave already exists.`);
    } else if (response.status === STATUS_CODE.SOMETHING_WENT_WRONG) {
      message.error(`Oops!! something went wrong.`);
    } else {
      toggleLoader(false);
      message.error(`Oops!! something went wrong.`);
    }
  };
  return (
    <ClientOnBoardWrap>
      <div className="main-heading ">Client On Board</div>

      <div className="form-container">
        <div className="sub-title-bg">Client Details</div>
        <div className="px_3 upload_docs">
          <GridBuilder
            formStructureJson={clientOnBoardData}
            handleAddNewField={handleAddNewField}
            handleChange={handleChange}
            handleFormBuilderInputChange={handleFormBuilderInputChange}
            initialValues={clientDetailForm}
            handleRemoveFormBuilderOptions={handleRemoveFormBuilderOptions}
            handleSelectChange={handleSelectChange}
          />
        </div>
      </div>
      <div className="form-container">
        <div className="sub-title-bg">
          New prospective client (PC) acceptance assessment:
        </div>
        <div className="px_3 upload_docs">
          <GridBuilder
            formStructureJson={newProspectiveClient}
            handleChange={handleInputChange}
            initialValues={clientDetailForm}
            handleNxt={() => handleApproveForm()}
            handleApproveForm={() => handleApproveForm()}
            handleBack={() => resetForm()}
            handleSelectChange={handleSelectSignChange}
          />
        </div>
      </div>
      <div className="text-end">
        <Button className="ml-5" onClick={resetForm}>
          Cancel
        </Button>
        <Button
          className="ml-5"
          onClick={() => handleApproveForm()}
          disabled={showLoader}
        >
          {showLoader ? (
            <Spin indicator={<LoadingOutlined style={loaderStyle} spin />} />
          ) : (
            "Approve"
          )}
        </Button>
      </div>
    </ClientOnBoardWrap>
  );
};

export default ClientOnBoard;
