import React, { useEffect, useState } from "react";
import { Pie } from "@ant-design/plots";
import styled from "@emotion/styled";
import { Col, Row, Spin } from "antd";
import { chain, map } from "lodash";
// Redux
import { connect } from "react-redux";
import {
  getLocations,
  getUserRoleId,
  getSelectedDepartmentId,
  getClientid,
  getUserList,
  getUserInfo
} from "redux/selectors";

import { CALL_API } from "common/API";
import { size, includes, some } from "lodash";
import LocationBasedAttendanceList from "./LocationBasedAttendanceList";

const StyledChartContainer = styled.div`
  min-height: 504px;
  padding: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 8px !important;

  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
    font-weight: 600;
  }

  .rowstyle {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
  }

  .legends {
    padding-top: 3px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 4px;
    padding: 16px;
    div {
      display: flex;
      justify-content: start;
      align-items: center;
    }
    .legend-box {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 12px;
      font-size: 12px;
      font-weight: 500;
      @media (max-width: 426px) {
        font-size: 8px;
      }
      strong {
        margin-left: auto;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`;

const StyledColorBox = styled.span`
  ${({ color }) => color && `background: ${color};`}
  /* padding: 0px 16px; */
  height: 14px;
  width: 28px;
  border-radius: 10px;
`;

function LocationBasedPresentStaff({
  locationList,
  userRoleId,
  selectedDepartmentId,
  ClientId,
  myTeam,
  getSubordinates,
  userList,
  isHrDashboard,
  userInfo
}) {
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [reportData, setReportData] = useState([]);

  const [reportList, setReportList] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [displayAttendanceStaffModal, toggleDisplayAttendanceStaffModal] =
    useState(false);

  const getReportData = async () => {
    toggleReportLoader(true);
    if (ClientId) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(100);

      let subOrdinatesList = [""];

      if (myTeam) {
        const subOrdinates = [...new Set(getSubordinates(myTeam))];
        subOrdinatesList = subOrdinates?.length > 0 ? subOrdinates : [""];
      } else if (isHrDashboard) {
        subOrdinatesList = [""];
      }

      const { locationList } = await CALL_API(
        `location-list/${ClientId}`,
        "post",
        {
          // departmentId:
          //   userRoleId != 6 || userRoleId != 1 ? selectedDepartmentId : [""],
          officesupervisorid: subOrdinatesList,
        }
      );

      let lastColor = "#C7F9E7";

      const getRandomColor = (lastColor) => {
        let color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        if (color === lastColor || color === "#ffffff") {
          return getRandomColor(lastColor);
        }
        lastColor = color;
        return color;
      };
      
      const filteruser = userList?.filter(
        (item) => item?.userid === userInfo.userid
      );
      let locationDetails = locationList?.filter((item) => {
        const findUser = userList?.find((user) => {
          return user.userid === item?.userId;
        });
        if(filteruser[0].OfficeSupervisorId === "none"){
          const userDetails = userList?.find((user) => {
            return user.userid === item?.userId;
          });
          return userDetails;
        } else {
          const userDetails = some(findUser?.managingoffice, value => includes(filteruser[0]?.managingoffice, value));
          return userDetails;
        }
      })
      let result = chain(locationDetails)
        .groupBy((x) => x.loginLocationName)
        .map((value, key) => ({
          loginLocationName: key,
          prensentList: value,
          presentCount: size(value),
          color: getRandomColor(lastColor),
        }))
        .value();

      setReportData(result);

      toggleReportLoader(false);
    }
  };
  useEffect(() => {
    getReportData();
  }, [selectedDepartmentId]);

  const config = {
    // height:350,
    padding: "auto",
    data: reportData,
    angleField: "presentCount",
    colorField: "loginLocationName",
    radius: 0.7,
    color: map(reportData, "color", []),
    label: {
      type: "inner",
      offset: "-30%",
      autoRotate: false,
      // content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 12,
        textAlign: "center",
      },
    },
    legend: false,
    // legend: {
    //   position: "right",
    //   layout: "vertical",
    //   offsetX: -30,
    //   itemName: {
    //     // formatter: (text,item) => {
    //     //   return " hi all the user interface check nterface"
    //     // },
    //     style: {
    //       fontSize: 18,
    //     },
    //   },
    //   marker: {
    //     radius: 12,
    //     style: {
    //       r: 6,
    //     },
    //   },
    // },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  
  return (
    <>
      <Spin spinning={displayReportLoader}>
        <StyledChartContainer>
          <div className="name-title">Today's Location based Attendance</div>
          {reportData?.length > 0 ? (
            <Row className="rowstyle">
              <Col span={16}>
                <Pie
                  {...config}
                  onEvent={(plot) => {
                    plot.on("element:click", (x) => {
                      setLocationName(x.data.data.loginLocationName);
                      setReportList(x.data.data.prensentList);
                      toggleDisplayAttendanceStaffModal(true);
                    });
                  }}
                />
              </Col>
              <Col span={8} className="legends">
                {reportData.map((val) => {
                  return (
                    <div>
                      <div className="legend-box">
                        <StyledColorBox color={val.color}></StyledColorBox>
                        {val.loginLocationName}{" "}
                        {/* <strong>{val.presentCount}</strong> */}
                      </div>
                    </div>
                  );
                })}
              </Col>
            </Row>
          ) : (
            <p
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No Staff Present
            </p>
          )}
        </StyledChartContainer>
      </Spin>
      <LocationBasedAttendanceList
        displayModal={displayAttendanceStaffModal}
        toggleModal={() => toggleDisplayAttendanceStaffModal(false)}
        title={locationName}
        list={reportList}
        reportType={locationName}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  userList: getUserList(state),
  userInfo: getUserInfo(state)

});

export default connect(mapStateToProps, null)(LocationBasedPresentStaff);
