export const RadioBtnOption = [{
    label: "Yes",
    value: "Yes",
},
{
    label: "No",
    value: "No",
}];

export const RadioAcceptption = [{
    label: "Acceptable",
    value: "Acceptable"
}, {
    label: "Unacceptable",
    value: "Unacceptable"
}
]

const ClientOnBoardJSON = (data) => {
    const ProspectiveClient = {
        totalpages: 1,
        pages: [
            {
                modalView: false,
                colSpan: [12, 12, 12],
                fields: [
                    // Row 1
                    [
                        {
                            id: 1,
                            label: "Conflict of interest required:",
                            type: "radiobutton",
                            name: "conflictIntrest",
                            options: RadioBtnOption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],
                    // Row 2
                    [
                        {
                            id: 2,
                            label: "The firm's eligibility and independence:",
                            type: "radiobutton",
                            name: "firmEligibility",
                            options: RadioAcceptption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 3
                    [
                        {
                            id: 3,
                            label: "The prospective client (PC) reputation:",
                            type: "radiobutton",
                            name: "clientreputation",
                            options: RadioAcceptption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 4
                    [
                        {
                            id: 4,
                            label: "The PC's previous auditors/advisors:",
                            type: "radiobutton",
                            name: "prevAuditors",
                            options: RadioAcceptption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 5
                    [
                        {
                            id: 5,
                            label: "The firm's eligibility to serve the client:",
                            type: "radiobutton",
                            name: "eligibilClient",
                            options: RadioAcceptption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 6
                    [
                        {
                            id: 6,
                            label: "The PC's ability to pay acceptable fees:",
                            type: "radiobutton",
                            name: "acceptableFee",
                            options: RadioAcceptption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 7
                    [
                        {
                            id: 7,
                            label: "PC organisation's internal competence:",
                            type: "radiobutton",
                            name: "internalcompetence",
                            options: RadioAcceptption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 8
                    [
                        {
                            id: 8,
                            label: "Need for concurring reviewer based on risks?",
                            type: "radiobutton",
                            name: "reviewerRisk",
                            options: RadioBtnOption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 9
                    [
                        {
                            id: 9,
                            label: "Risks-AML check, world check and other risks:",
                            type: "radiobutton",
                            name: "riskAml",
                            options: RadioAcceptption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],


                    // Row 11
                    [
                        {
                            id: 11,
                            label: "Comment:",
                            type: "textarea",
                            name: "comment",
                            value: "",
                            Placeholder: "Enter",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],

                    // Row 12
                    [
                        {
                            id: 12,
                            label: "I have performed the firm's required acceptance procedure, as indicated above and discussed the same with partners and managers in a meeting. Based on the information provided in the onboarding form and feedback from all partners and managers, I approve the acceptance of the engagement.",
                            type: "checkbox",
                            name: "confirmartionCheckBox",
                            value: false,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },
                    ],
                    [
                        {
                            id: 13,
                            label: "",
                            type: "filenew",
                            name: "attachedFile",
                            options: { btnLabel: "Attach Signature" },
                            Placeholder: "Attach Signature",
                            value: "",
                            // validationRules: [
                            //     {
                            //         required: true,
                            //         message: `This field is required`,
                            //     },
                            // ]
                        },
                        {

                        }
                    ],
                    // Row 13
                    [
                        {
                            id: 13,
                            label: "Designation:",
                            type: "text",
                            name: "finaldesignation",
                            value: "",
                            Placeholder: "Enter",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ]
                        },

                    ],
                    [{
                        id: 14,

                    }]
                ],
            },
        ],
    };
    return {
        ProspectiveClient
    };
};

export default ClientOnBoardJSON;