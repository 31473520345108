import React, { useEffect, useState } from "react";
import { Bar } from "@ant-design/plots";
import { Modal, Spin, Row, Col, Table } from "antd";
import {} from "@ant-design/charts";
import styled from "@emotion/styled";

// Redux
import { connect } from "react-redux";
import { getUserRoleId, getAllDepartmentList } from "redux/selectors";
import COLORS from "common/Colors";
import {
  find,
  get,
  isNaN,
  map,
  max,
  random,
  reduce,
  differenceBy,
  concat,
  chain,
  groupBy,
  toPairs,
  zipObject,
  size,
} from "lodash";
import { CALL_API } from "common/API";
import { dateInDetail } from "utils/Date";

const StyledModal = styled(Modal)`
  width: 1000px !important;
  z-index: 10;
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
  .ant-modal-body {
    height: 500px !important;
    overflow-y: auto;
  }
`;

const SmallModal = styled(Modal)`
  width: 700px !important;
  .ant-modal-body {
    overflow-y: auto;
  }
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;
const StyledColumn = styled(Bar)`
  padding: 10px;
`;

function DepartmentAttendanceReport({
  displayModal,
  toggleModal,
  reportType,
  reportColor,
  allDepartmentList,
  list,
  name,
  lateListData,
  onTimeList,
  leaveList,
  absentList,
}) {
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [detailedData, setDetailedData] = useState([]);
  const [reportList, setReportList] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (name == "Absent") {
      setDetailedData(
        absentList == (null || undefined || "") ? [] : absentList
      );
    }
    if (name == "onTime") {
      setDetailedData(
        onTimeList == (null || undefined || "") ? [] : onTimeList
      );
      console.log("useEffect ~ onTimeList----->", onTimeList);
    }
    if (name == "Late") {
      setDetailedData(
        lateListData == (null || undefined || "") ? [] : lateListData
      );
    }
    if (name === "Leave") {
      setDetailedData(leaveList == (null || undefined || "") ? [] : leaveList);
    }
    // if (name == "Annual Leave" || name == "Other Leave" || name == "Sick Leave") {

    // }
  }, [name, detailedData, filteredData, reportType]);

  const handleResize = () => {
    setKey(Math.random());
  };

  const columns = [];
  if (reportType === "Absent") {
    columns.push({
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      align: "center",
    });
    columns.push({
      title: "Email",
      dataIndex: "userid",
      key: "userid",
      align: "center",
    });
  }
  else if (reportType === "leave") {
    columns.push({
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    });
    columns.push({
      title: "Department",
      dataIndex: "departmentName",
      key: "departmentName",
      // width: '50%',
      align: "center",
    });
    columns.push({
      title: "Leave Category",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      align: "center",
    });
    columns.push({
      title: "Start date",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      render: (start_date) => {
        if (start_date === "") return "-";
        const { date: day_th, shortMonthName, year } = dateInDetail(start_date);
        return `${day_th} ${shortMonthName} ${year}`;
      },
    });
    columns.push({
      title: "End date",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      render: (end_date) => {
        if (end_date === "") return "-";
        const { date: day_th, shortMonthName, year } = dateInDetail(end_date);
        return `${day_th} ${shortMonthName} ${year}`;
      },
    });
    columns.push({
      title: "No.of leave days",
      dataIndex: "number_of_days",
      key: "number_of_days",
      align: "center",
    });
  } 
  else if (reportType === "Present") {
    columns.push({
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      align: "center",
    });
    columns.push({
      title: "Department",
      dataIndex: "departmentName",
      key: "departmentName",
      // width: '50%',
      align: "center",
    });
    if (name === "onTime") {
      console.log("commming");
      // columns.push({
      //   title: 'Email ID',
      //   dataIndex: 'email_id',
      //   key: 'email_id',
      //   width: '50%',
      // });
      columns.push({
        title: "Station Name",
        dataIndex: "loginLocationName",
        key: "loginLocationName",
        // width: '50%',
        align: "center",
      });

      columns.push({
        title: "Check-In Time",
        dataIndex: "checkinTime",
        key: "checkinTime",
        // width: '50%',
        align: "center",
        render: (checkinTime) => {
          // const { time24 } = dateInDetail(checkintime);
          return `${checkinTime}`;
        },
      });
    } else if (name === "Late") {
      // columns.push({
      //   title: 'Email ID',
      //   dataIndex: 'email_id',
      //   key: 'email_id',
      //   width: '30%',
      // });
      columns.push({
        title: "Station Name",
        dataIndex: "loginLocationName",
        key: "loginLocationName",
        // width: '50%',
        align: "center",
      });
      columns.push({
        title: "Check-In Time",
        dataIndex: "checkinTime",
        key: "checkinTime",
        // width: '50%',
        align: "center",
        render: (checkinTime) => {
          // const { time24 } = dateInDetail(checkintime);
          return `${checkinTime}`;
        },
      });
    }
  }

  //  I want a horizontal bar chart
  const config = {
    data: reportData,
    height: 700,
    xField: "numbers",
    yField: "type",
    seriesField: "type",
    legend: false,
    color: reportColor,
    label: {
      position: "middle", // Position the label at the end of each bar
      style: {
        fill: COLORS.SECONDARY_BLACK,
      },
      formatter: ({ numbers }) => (numbers > 0 ? numbers : ""), // Display the number of employees
    },
    theme: {
      maxColumnWidth: 50,
      minColumnWidth: 50,
    },
    columnStyle: {
      // stroke: COLORS.BLACK,
      // lineWidth: 1,
      cursor: "pointer",
    },
    barSize: 50,
    xAxis: {
      label: null, // Remove the x-axis label
      line: null, // Remove the vertical line on the x-axis
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
    yAxis: {
      label: {
        style: {
          fill: COLORS.SECONDARY_BLACK,
        },
      },
    },
    tooltip: {
      showTitle: false,
      showMarkers: false,
      showContent: true,
      shared: true,
      formatter: (datum) => {
        return {
          name: datum.type,
          value: datum.numbers,
        };
      },
    },
    isStack: true, // Make it a horizontal bar chart
  };

  useEffect(() => {
    if (displayModal) {
      getReportData();
    }
  }, [displayModal]);

  const getReportData = async () => {
    toggleReportLoader(true);
    // let methodName = '';
    // let keyName = '';
    // if (reportType === 'Present') {
    //   methodName = 'todayDepartmentWisePresentCount';
    //   keyName = 'totaltimelyLogins';
    // } else if (reportType === 'Absent') {
    //   methodName = 'todayDepartmentWiseAbsentCount';
    //   keyName = 'absent';
    // } else if (reportType === 'Post cut Off') {
    //   methodName = 'todayDepartmentWisePostCutOffCount';
    //   keyName = 'totalLateLogins';
    // }
    // else if (reportType === 'leave') {
    //   methodName = 'todayDepartmentWiseLeaveCount';
    //   keyName = 'Leaves';
    // }

    // const response = await CALL_API('attendance-service', 'post', {
    //   method: methodName,
    //   parameters: {},
    // });
    const data = reduce(
      allDepartmentList,
      (result, department) => {
        result.push({
          department_id: department.department_id,
          type: department.department_name,
          numbers: 0,
        });
        return result;
      },
      []
    );
    let res = chain(list)
      .groupBy((x) => {
        if (x.departmentId) {
          return x.departmentId;
        }
        if (x.department_id) {
          return x.department_id;
        } else {
          const firstElement =
            x.userdepartmentid[Object.keys(x.userdepartmentid)[0]];
          const randomIndex = Math.floor(Math.random() * firstElement?.length);
          return firstElement[randomIndex];
        }
      })
      .map((value, key) => {
        // console.log("value--->", value, "key", key)
        const department = find(data, { department_id: key });
        return {
          department_id: key,
          type: get(department, "type", null),
          numbers: size(value),
        };
      })
      .value();
    var final_res = differenceBy(data, res, "department_id");
    setReportData(concat(final_res, res));
    toggleReportLoader(false);
    // console.log("reported Data", JSON.stringify(concat(final_res, res)));

    const result = concat(final_res, res).filter((item) => item.numbers > 0);
    // setReportData(result)
  };

  const handleReportList = (data) => {
    console.log("handleReportList ~ data----->", data);
    console.log("detailedData --->", detailedData);

    if (
      data === null ||
      data === undefined ||
      data === "" ||
      data?.data?.department_id === ("" || null || undefined)
    ) {
      console.log("Clicked null data", data);
    } else {
      setReportList(true);
      setSelectedDept(data?.data?.type);

      setFilteredData(
        detailedData?.filter((e) => {
          if (e.userdepartmentid) {
            const departmentIdKeys = Object.keys(e.userdepartmentid);
            return departmentIdKeys.some(key => e.userdepartmentid[key].includes(data.data.department_id));
          }
          if (e.departmentId) {
            return e.departmentId === data?.data?.department_id;
          }
          if (e.department_id) {
            return e.department_id === data?.data?.department_id;
          }
        })
      );
      toggleModal();
    }
  };

  const StyledTable = styled(Table)`
    .ant-table-content {
      overflow-x: auto;
      max-height: 500px;
      white-space: nowrap;
    }
    .ant-table-cell {
      padding: 10px;
    }
    .ant-table-thead .ant-table-cell {
      font-weight: bold;
      color: ${COLORS.SECONDARY_BLACK};
    }
    .non-white {
      background: #f9f9f9;
    }
  `;
  return (
    <>
      <StyledModal
        title={`Today's ${name} report`}
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
        destroyOnClose
      >
        <Row>
          <Col md={24} sm={24} xs={24} xl={24} key={key}>
            <Spin spinning={displayReportLoader}>
              <StyledColumn
                {...config}
                onReady={(plot) => {
                  plot.on("plot:click", (evt) => {
                    if (
                      evt.data !== null ||
                      evt.data !== undefined ||
                      evt.data !== ""
                    ) {
                      console.log("evt.data --->", evt.data);
                      handleReportList(evt.data);
                    }
                  });
                }}
              />
            </Spin>
          </Col>
        </Row>
      </StyledModal>
      <SmallModal
        title={`Today's ${name} Report- ${selectedDept} department`}
        open={reportList}
        footer={false}
        onCancel={() => setReportList(false)}
      >
        <StyledTable
          dataSource={filteredData}
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="user_id"
          pagination={false}
          className="name_nowrap"
        />
      </SmallModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
});

export default connect(mapStateToProps, null)(DepartmentAttendanceReport);
