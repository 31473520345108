import { Col, Input, Modal, Row, Table } from "antd";
import React from "react";
import styled from "styled-components";
import ResourcePlan from "../EngagementView/ResourcePlan";

const ViewDetails = ({
  show,
  onClose,
  engagementData,
  generateSchedule,
}) => {

  return (
    <StyledModal
      title={engagementData?.title}
      visible={show}
      onCancel={() => {
        onClose();
      }}
      width='75%'
      footer={[]}
    >
        <Row gutter={16}>
            <Col span={24}>
               <label>Client Id</label>
               <Input value={engagementData?.clientName} disabled/>
            </Col>
            <Col span={12}>
               <label>From Date</label>
               <Input value={engagementData?.planStartDate} disabled/>
            </Col>
            <Col span={12}>
               <label>To Date</label>
               <Input value={engagementData?.planEndDate} disabled/>
            </Col>
        </Row>
        <label>Team List</label>
      <ResourcePlan viewOnly={true} engagementData={engagementData} />
    </StyledModal>
  );
};

export default ViewDetails;


const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 10px 14px;
  }
  .action-status {
    background-color: #f7f7f8;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border: 1px solid #dbdbdb;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  label{
    font-weight: 600;
    margin-top: 10px;
  }
`;
