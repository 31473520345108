import { Col, Input, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Details from "./HRPolicy/Details";
import { getClientid, getUserInfo, getUserRoleId } from "redux/selectors";
import { connect } from "react-redux";
import { CALL_API } from "common/API";

const CompanyPolicy = ({ userRoleId, ClientId, userInfo }) => {
  const [showComponent, setShowComponent] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterbyrole = dataSource?.filter((item) =>
    item?.roleId?.includes(userRoleId)
  );

  const filteredDataSource = filterbyrole?.filter((item) =>
    item.policyTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const policyColumns = [
    {
      title: "Policy Name",
      dataIndex: "policyTitle",
      key: "policyName",
    },
    {
      title: "Type",
      dataIndex: "policyType",
      key: "policyType",
    },
    // {
    //   title: "Policy Effective Date",
    //   dataIndex: "policyEffectiveDate",
    //   key: "policyEffectiveDate",
    //   render: (date) => {
    //     const dateFormat = moment(date).format("DD MMM YYYY");
    //     return `${dateFormat}`;
    //   },
    // },
    // {
    //   title: "Last Updated",
    //   dataIndex: "lastUpdated",
    //   key: "lastUpdated",
    //   render: (_, record) => (
    //     <>
    //     <Icon name="edit"/>
    //     <Icon name="delete"/>
    //     </>
    //   ),
    // },
  ];

  const goBack = () => {
    setShowComponent(1);
  };

  const getPolicyData = async () => {
    setLoading(true);
    const response = await CALL_API(`hrpolicy/${ClientId}`, "get", {});
    setLoading(false);
    setDataSource(response);
  };

  useEffect(() => {
    getPolicyData();
  }, []);

  return (
    <>
      {showComponent === 1 && (
        <>
          <Row gutter={16}>
            <Col md={6} className="">
              {/* <b>Filter by</b> */}
              <Input
                className="mb-2"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                suffix={<SearchOutlined />}
              />
            </Col>
          </Row>
          <br />
          <Table
            dataSource={filteredDataSource}
            columns={policyColumns}
            pagination={false}
            loading={loading}
            // Note: Second params needed
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  setSelectedRow(record);
                  setShowComponent(2);
                },
              };
            }}
          />
        </>
      )}

      {showComponent === 2 && (
        <Details
          pagetitle="Company Policy"
          selectedRow={selectedRow}
          ClientId={ClientId}
          userInfo={userInfo}
          goBack={goBack}
          getPolicyData={getPolicyData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(CompanyPolicy);
