import React from "react";
import { Row, Col } from "antd";
import { Pie } from "@ant-design/plots";
import styled from "@emotion/styled";

const fixFloat = (value) => {
  if (!value) {
    return 0;
  }

  if (!value.toString().includes(".")) {
    return value;
  }

  return parseFloat(value).toFixed(1);
};

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b6b;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 15px;
  .styled-circle {
    svg {
    }
  }
  .report-title {
    font-weight: bold;
    font-size: 0.8rem;
    padding-top: 3px;
    padding-left: 10px;
    text-transform: uppercase;
  }
  .report-title1 {
    font-weight: bold;
    font-size: 0.7rem;
    padding-top: 3px;
  }
  .legends {
    padding-top: 2rem;

    .legend-box {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: nowrap;
      padding-bottom: 5px;
      padding-right: 12px;
      gap: 5px;

      .lable {
        font-size: 0.8rem;
      }

      .value {
        font-size: 0.8rem;
        width: 100%;
        text-align: end;
      }
    }
  }
`;

const chatStyle = {
  fontSize: 13,
};

const StyledColorBox = styled.span`
  ${({ color }) => color && `background: ${color};`}
  height: 14px !important;
  width: 14px !important;
  min-width: 14px;
  min-height: 14px;
  border-radius: 4px;
`;

function LeaveDonutChart({ iconName, title, data, color }) {
  const config = {
    width: 100,
    height: 100,
    appendPadding: 6,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.7,
    // color: [color?.actual, color?.light],
    tooltip: false,
    legend: false,
    label: false,
    statistic: {
      title: false,
      content: {
        style: chatStyle,
        // content: `${data[1]?.value}/${data[0]?.value - data[1]?.value}`,
        content: `${fixFloat(data[0]?.value - data[1]?.value)}`,
      },
    },
  };

  return (
    <StyledChartContainer>
      <Row>
        <Col span={24} className="mb-2">
          {/* <RoundIcon>
            {iconName && <Icon name={iconName} style={{ color: "#000" }} />}
          </RoundIcon> */}
          <b>{title}</b>
        </Col>
        <Col xs={24} sm={12}>
          <Pie {...config} />
        </Col>
        <Col xs={24} sm={12} className="legends">
          <div className="legend-box">
            <StyledColorBox
              className="marker"
              // color={color?.actual}
            ></StyledColorBox>
            <span className="label">Available</span>
            <span className="value">
              {fixFloat(data[0]?.value - data[1]?.value)}
            </span>
          </div>
          <div className="legend-box">
            <StyledColorBox
              className="marker"
              // color={color?.light}
            ></StyledColorBox>
            <span className="label">Consumed</span>
            <span className="value">{fixFloat(data[1]?.value)}</span>
          </div>
        </Col>
      </Row>
    </StyledChartContainer>
  );
}

export default LeaveDonutChart;
