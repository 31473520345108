import { Col, Form, Modal, Row, Spin, Table, message } from "antd";
import COLORS from "common/Colors";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { useUpdate } from "hooks/useUpdate";
import { ButtonFilled } from "reusableComponent/UIButtons";
import styled from "styled-components";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import { CALL_AI_API, CALL_API } from "common/API";
import { Grade, STATUS_CODE } from "common/Constants";
import moment from "moment";
import { EditChargeOut } from "./EditChargeOut";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";

const StyledModal = styled(Modal)`
  .ant-form-item {
    margin-bottom: "12px";
  }
`;

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function ChargeoutRates() {
  const [isEdit, setIsEdit] = useState(false);
  const [isHistory, setIsHistory] = useState(false);
  const [recordValue, setRecordValue] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRecordValue({});
  }, []);

  const { key, url } = queryKeys.getChargeoutRates;
  const { key: historyKey, url: historyUrl } =
    queryKeys.getHistoryChargeoutRates(recordValue?.id);
  const { data, isLoading, refetch } = useFetch(key, url);

  const { data: historyData, isLoading: isLoadingHistory } = useFetch(
    historyKey,
    historyUrl,
    {
      enabled: isHistory,
    }
  );

  let historyDataSource = historyData?.response?.items;

  const updateChargeoutRate = async (id, payload) => {
    try {
      setLoading(true);
      const response = await CALL_API(
        `settings/chargeout/${id}`,
        "patch",
        payload
      );

      if (response.code === STATUS_CODE.SUCCESS) {
        message.success("Successfully Updated");
        setLoading(false);
        setIsEdit(false);
        refetch();
      } else {
        message.error(
          `Update failed: ${response.message || "please try again."}`
        );
        setLoading(false);
        setIsEdit(false);
      }
    } catch (error) {
      message.error("An error occurred: " + error.message);
    } finally {
    }
  };

  const handleFormSubmit = (values) => {
    const id = recordValue.id;
    let payload = {
      // ET:values.ET,
      [recordValue.country]: values.rate,
      // RW:values.RW,
      // TZ:values.TZ,
      // UG:recordValue.UG,
      designation: recordValue.designation,
      exception: recordValue.exception,
      jobgrade: recordValue.jobgrade,
      jobgradecode: values.jobgradecode,
      revenueKPI: recordValue.revenueKPI,
      standard: values.standard,
      targetchargeability: values.targetchargeability,
      typeofwork: recordValue.typeofwork,
    };
    updateChargeoutRate(id, payload);
  };

  const historyColumns = [
    {
      title: "Job Grade Code",
      dataIndex: "jobgradecode",
      key: "jobgradecode",
      align: "center",
      render: (text) => {
        return text || "-";
      },
    },
    {
      title: "Target Chargeability",
      dataIndex: "targetchargeability",
      key: "targetchargeability",
      align: "center",
      render: (text) => (text ? `${text}%` : "-"),
    },
    // {
    //   title: "Exception",
    //   dataIndex: "Exception",
    //   key: "Exception",
    //   align: "center",
    //   render:(text)=>{
    //     return text || "-"
    //   }
    // },
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      align: "center",
      render: (text) => (text ? `$${text}` : "-"),
    },
    // {
    //   title: "DRC",
    //   dataIndex: "KE",
    //   KEy: "ke",
    //   align: "center",
    //    render: (text) =>  text ? `$${text}` : "-"
    // },
    // {
    //   title: "KE",
    //   dataIndex: "KE",
    //   KEy: "ke",
    //   align: "center",
    //    render: (text) =>  text ? `$${text}` : "-"
    // },
    // {
    //   title: "UG",
    //   dataIndex: "KE",
    //   key: "KE",
    //   align: "center",
    //    render: (text) =>  text ? `$${text}` : "-"
    // },
    // {
    //   title: "RW",
    //   dataIndex: "RW",
    //   key: "RW",
    //   align: "center",
    //    render: (text) =>  text ? `$${text}` : "-"
    // },
    // {
    //   title: "ET",
    //   dataIndex: "ET",
    //   key: "ET",
    //   align: "center",
    //    render: (text) =>  text ? `$${text}` : "-"
    // },
    // {
    //   title: "TZ",
    //   dataIndex: "TZ",
    //   key: "TZ",
    //   align: "center",
    //    render: (text) =>  text ? `$${text}` : "-"
    // },
    // {
    //   title: "Type of Work",
    //   dataIndex: "type_of_work",
    //   key: "Typeofwork",
    //   width: "20%",
    //   align: "center",
    //   render:(text)=>{
    //     return text || "-"
    //   }
    // },
    {
      title: "Updated At",
      dataIndex: "updatedat",
      key: "updatedat",
      render: (updatedat) => moment(updatedat).format("DD-MM-YYYY") || "-",
    },
  ];

  const columns = [
    {
      title: "Job Grade Code",
      dataIndex: "jobgradecode",
      key: "jobgradecode",
    },
    {
      title: "Target Chargeability",
      dataIndex: "targetchargeability",
      key: "targetchargeability",
      render: (text) => (text ? `${text}%` : "-"),
    },
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      render: (text) => (text ? `$ ${text}` : "-"),
    },
    {
      title: "Country Code",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text) => (text ? `$ ${text}` : "-"),
    },
    // {
    //   title: "Type of Work",
    //   dataIndex: "type_of_work",
    //   key: "Typeofwork",
    //   width: "20%",
    //   align: "center",
    //   render:(text)=>{
    //     return text || "-"
    //   }
    // },
    {
      title: "Action",
      dataIndex: "type_of_work",
      key: "type_of_work",
      align: "center",
      render: (_, record) => (
        <div className="d-flex">
          <ButtonFilled
            onClick={() => {
              setIsEdit(true);
              setRecordValue(record);
            }}
          >
            Edit
          </ButtonFilled>
          <ButtonFilled
            onClick={() => {
              setIsHistory(true);
              setRecordValue(record);
            }}
          >
            History
          </ButtonFilled>
        </div>
      ),
    },
  ];

  const customLocale = {
    emptyText: <div>"No history data"</div>,
  };

  const [form] = Form.useForm();

  const countries = [
    { code: "DRC", country: "DR Congo" },
    { code: "KE", country: "Kenya" },
    { code: "RW", country: "Rwanda" },
    { code: "TZ", country: "Tanzania" },
    { code: "UG", country: "Uganda" },
    { code: "ET", country: "Ethiopia" },
  ];


  const allResponseData = data?.response?.items
    .flatMap((item, index) =>
      countries.map((country) => ({
        jobgradecode: item.jobgradecode,
        targetchargeability: item.targetchargeability,
        standard: item.standard,
        country: country.code,
        countryName: country.country,
        rate: item[country.code],
        typeofwork: item.typeofwork,
        designation: item.designation,
        exception: item.exception,
        jobgrade: item.jobgrade,
        revenueKPI: item.revenueKPI,
      }))
    )
    .sort((a, b) => {
      if (a.country === "DRC" && b.country !== "DRC") return -1;
      if (a.country !== "DRC" && b.country === "DRC") return 1;
      return (
        Grade.indexOf(a.jobgradecode) -
        Grade.indexOf(b.jobgradecode)
      );
    });

  const allData = allResponseData
    ? allResponseData?.map((item, index) => ({
        ...item,
        id: index + 1,
      }))
    : [];

  const headerstyle = { fontSize: "20px", fontWeight: "bold" };

  const getFilteredData = () => {
    const values = form.getFieldsValue();
    const grade = values.grade || [];
    const country = values.country || [];

    if (grade.length === 0 && country.length === 0) return allData;

    return allData.filter(
      (item) =>
        (grade.length === 0 || grade.includes(item.jobgradecode)) &&
        (country.length === 0 || country.includes(item.country))
    );
  };

  const gradeOptions = Grade?.map((item) => ({
    label: item,
    value: item,
  }));

  const countryOptions = countries.map((item) => ({
    label: item.country,
    value: item.code,
  }));

  return (
    <>
      <Row className="page-title">
        <Col sm={24} md={12} style={headerstyle}>
          List of Chargeout Grade
        </Col>
      </Row>

      <Form
        form={form}
        layout="vertical"
        className="mt-2"
        onValuesChange={() => {}}
      >
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <DropdownCheckBox
              name="grade"
              label="Grade"
              options={gradeOptions}
              form={form}
            />
          </Col>

          <Col xs={24} sm={8}>
            <DropdownCheckBox
              name="country"
              label="Country"
              options={countryOptions}
              form={form}
            />
          </Col>
        </Row>
      </Form>

      <Spin spinning={isLoading}>
        <StyledTable
          dataSource={getFilteredData()}
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="id"
          pagination={false}
        />
      </Spin>
      {/* <StyledModal
        title="Edit Chargeout Rates"
        width={900}
        open={isEdit}
        footer={false}
        onCancel={()=>{setIsEdit(false);setRecordValue({})}}
      >
        <GridBuilder formStructureJson={ChargeoutFormJson} initialValues={recordValue} formSubmit={handleFormSubmit} loading={loading} isModalOpen={isEdit}/>
      </StyledModal> */}
      <EditChargeOut
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setRecordValue={setRecordValue}
        loading={loading}
        recordValue={recordValue}
        handleFormSubmit={handleFormSubmit}
        isLoading={isLoading}
      />
      <StyledModal
        title="Chargeout Rates History"
        width={1400}
        open={isHistory}
        footer={false}
        onCancel={() => setIsHistory(false)}
      >
        <Spin spinning={isLoadingHistory}>
          <StyledTable
            columns={historyColumns}
            dataSource={historyDataSource?.length > 0 ? historyDataSource : []}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey={(record) => record.key}
            pagination={false}
            locale={customLocale}
          />
        </Spin>
      </StyledModal>
    </>
  );
}

export default ChargeoutRates;
