import React, { useMemo, useState } from "react";
import { Row, Col, Popover, Badge, Modal, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import COLORS from "common/Colors";
import { getMySqlDate, dateInDetail } from "utils/Date";
import { getUserRoleId, getMenuList, getClientid } from "redux/selectors";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleProcessingModal, toggleSuccessModal } from "redux/actions";
import {
  StyledAmmendmentTitle,
  StyledButton,
  StyledDivider,
  StyledLeaveDetailsPopover,
  StyledLeaveDetailsPopoverFooter,
  StyledRowContainer,
} from "./StyledComponents";
import moment from "moment";
import { useScrollFetch } from "hooks/useScrollFetch";
import queryKeys from "common/queryKey";

function ResourceIndicator({
  resourceDetails,
  children,
  updateProcessingModal,
  updateSuccessModal,
  userRoleId,
  menuList,
  ClientId,
}) {
  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const [open, setOpen] = useState(false);

  const {
    date_th: start_date_th,
    shortMonthName: start_month,
    year: start_year,
  } = dateInDetail(
    moment(resourceDetails?.actualBookedStartDate, "YYYY-MM-DD")
  );

  const {
    date_th: end_date_th,
    shortMonthName: end_month,
    year: end_year,
  } = dateInDetail(moment(resourceDetails?.actualBookedEndDate, "YYYY-MM-DD"));

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const { getAllClientsList } = queryKeys;

  const { key, url } = getAllClientsList;

  const {
    data: clientsDataRes,
    fetchNextPage,
    isLoading: loading,
    hasNextPage,
  } = useScrollFetch(key, {
    endPoint: url,
    queryParam: "LastEvaluatedKey",
  });

  const clients = useMemo(() => {
    return clientsDataRes?.pages?.reduce((acc, page) => {
      if (page?.data === undefined) return acc;
      return [...acc, ...page?.data];
    }, []);
  }, [clientsDataRes]);

  const clientOptions = clients
    ?.filter((client) => client?.bdo_id !== "NULL")
    ?.map((client) => ({
      label: client?.registeredName,
      value: client?.clientId,
    }));

  const clientName = clientOptions?.find(
    //? will give result only after submitting Engagement
    (client) => client?.value === resourceDetails?.clientId
  )?.label;

  const content = (
    <StyledLeaveDetailsPopover>
      <Row>
        <Col span={20}>
          <h5>
            <Badge
              status="success"
              size="small"
              color={
                resourceDetails?.status === "Assigned"
                  ? COLORS.PENDING_LEAVE
                  : COLORS.APPROVED_LEAVE
              }
            />{" "}
            {resourceDetails.employeeName}
          </h5>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={8}>Client:</Col>
        <Col span={16}>{clientName ?? "-"}</Col>
      </Row>

      <Row gutter={20}>
        <Col span={8}>Engagment:</Col>
        <Col span={16}>{resourceDetails?.engagementName || 0}</Col>
      </Row>

      <Row gutter={20}>
        <Col span={8}>From date:</Col>
        <Col span={16}>
          {start_date_th} {start_month} {start_year}
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={8}>To date:</Col>
        <Col span={16}>
          {end_date_th} {end_month} {end_year}
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={8}>Project Role:</Col>
        <Col span={16}>{resourceDetails?.projectRole || "-"}</Col>
      </Row>

      <Row gutter={20}>
        <Col span={8}>Remark:</Col>
        <Col span={16}>{resourceDetails?.remarks || "-"}</Col>
      </Row>
    </StyledLeaveDetailsPopover>
  );

  return (
    <>
      <Popover
        className="leave-detail-popover"
        content={content}
        placement="rightTop"
        trigger={"hover"}
        open={open}
        onOpenChange={handleOpenChange}
      >
        <StyledRowContainer
          color={
            resourceDetails?.status === "Assigned"
              ? COLORS.PENDING_LEAVE
              : COLORS.APPROVED_LEAVE
          }
        >
          <Row>
            <Col span={24} className="staff-name">
              {children}
            </Col>
          </Row>
        </StyledRowContainer>
      </Popover>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  menuList: getMenuList(state),
  ClientId: getClientid(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(ResourceIndicator);
