import { countryList, ClientStatus, RiskOption, ManagingOffice, IndustryOption, IncorporationData, RelationShipOption } from "common/Constants";

const ClientBasicJSON = (data) => {
    const BasicDetail = {
        totalpages: 1,
        pages: [
            {
                modalView: false,
                colSpan: [12, 12, 12, 12, 12, 12],
                fields: [
                    // Row 1
                    [
                        {
                            id: 1,
                            label: "Client ID",
                            type: "text",
                            name: "clientId",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Client ID is required",
                                },
                            ],
                            onKeyDown: /^[a-zA-Z\s0-9-]+$/
                        },
                        {
                            id: 2,
                            label: "CRM ID:",
                            type: "text",
                            name: "crmId",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            onKeyDown: /^[a-zA-Z\s0-9-]+$/,
                        },
                    ],
                    // Row 2
                    [
                        {
                            id: 3,
                            label: "Managing Office:",
                            type: "select",
                            name: "managingOffice",
                            Placeholder: "Enter",
                            value: "",
                            options: ManagingOffice,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Managing Office is required",
                                },
                            ],
                        },
                        {
                            id: 5,
                            label: "Relationship:",
                            type: "select",
                            name: "relationship",
                            Placeholder: "Enter",
                            value: "",
                            options: RelationShipOption,
                            validationRules: [
                                {
                                    required: true,
                                    message: "relationship is required",
                                },
                            ],
                        },
                    ],
                    // Row 3
                    [
                        {
                            id: 6,
                            label: "Ultimate Parent:",
                            type: "text",
                            name: "ultimateParent",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            onKeyDown: /^[a-zA-Z ]+$/,

                        },
                        {
                            id: 7,
                            label: "Registered Name:",
                            type: "text",
                            name: "registeredName",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            onKeyDown: /^[a-zA-Z ]+$/,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Registered Name is required",
                                },
                            ],
                        },
                    ],
                    // Row 4
                    [
                        {
                            id: 8,
                            label: "Registered Address:",
                            type: "text",
                            name: "registeredAddress",
                            Placeholder: "Enter",
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: "Registered Address is required",
                                },
                            ],
                            onKeyDown: /^[a-zA-Z0-9 .]+$/
                        },
                        {
                            id: 9,
                            label: "Description :",
                            type: "text",
                            name: "description",
                            Placeholder: "Enter",
                            value: "",
                            onKeyDown: /^[a-zA-Z ]+$/,
                            maxLength: 100
                        },
                    ],
                    // Row 5
                    [
                        {
                            id: 10,
                            label: "City:",
                            type: "text",
                            name: "city",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            onKeyDown: /^[a-zA-Z ]+$/
                        },
                        {
                            id: 11,
                            label: "Country:",
                            type: "select",
                            name: "country",
                            Placeholder: "Enter",
                            value: "",
                            options: countryList,

                            validationRules: [
                                {
                                    required: true,
                                    message: `Country is required`,
                                }
                            ],
                            onKeyDown: /^[a-zA-Z ]+$/,
                        },
                    ],

                    [
                        {
                            id: 12,
                            label: "Incorporation Type:",
                            type: "select",
                            name: "incorporationType",
                            Placeholder: "Enter",
                            value: "",
                            options: IncorporationData,

                            validationRules: [
                                {
                                    required: true,
                                    message: `Incorporation Type is a required field`,
                                }
                            ],
                            onKeyDown: /^[a-zA-Z ]+$/,
                        },
                        {
                            id: 13,
                            label: "Industry:",
                            type: "select",
                            name: "industry",
                            Placeholder: "Select",
                            options: IndustryOption,
                            value: "",
                            validationRules: [
                                {
                                    required: true,
                                    message: `Industry is a required field`,
                                }
                            ],
                            onKeyDown: /^[a-zA-Z ]+$/,
                        },
                    ],
                    [
                        {
                            id: 14,
                            label: "Size:",
                            type: "select",
                            name: "size",
                            options: ["Large", "Medium", "Small"],
                            Placeholder: "Enter",
                            value: "",
                            validationRules: [{
                                required: true,
                                message: `Size is required`,
                            }],
                            onKeyDown: /^[a-zA-Z ]+$/,

                        },
                        {
                            id: 15,
                            label: "Risk Classification:",
                            type: "select",
                            name: "riskClassification",
                            Placeholder: "Enter",
                            value: "",
                            options: RiskOption,
                            validationRules: [{
                                required: true,
                                message: `Risk Classification is required`,
                            }],
                            onKeyDown: /^[a-zA-Z ]+$/,
                        },
                    ],
                    [
                        {
                            id: 16,
                            label: "Reference:",
                            type: "text",
                            name: "reference",
                            Placeholder: "Enter",
                            value: "",
                            onKeyDown: /^[a-zA-Z0-9 ]+$/,
                            maxLength: 50
                        },
                        {
                            id: 17,
                            label: "Status:",
                            type: "select",
                            name: "status",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            onKeyDown: /^[a-zA-Z ]+$/,
                            options: ClientStatus
                        },
                    ],

                    [
                        {
                            id: 18,
                            label: "Created Date:",
                            type: "date",
                            name: "createdNo",
                            Placeholder: "Enter",
                            value: "",

                        },
                        {
                            id: 19,
                            label: "Registered No.:",
                            type: "text",
                            name: "registeredNo",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 30,
                            validationRules: [
                                {
                                    pattern: /^[a-zA-Z0-9 ]+$/,
                                    message: `This field should not contain symbols`,
                                }
                            ],
                        },
                    ],
                    [
                        {
                            id: 20,
                            label: "Stock Exchange:",
                            type: "text",
                            name: "stockExchange",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 25,
                            onKeyDown: /^[a-zA-Z ]+$/
                        },
                        {
                            id: 21,
                            label: "Pin No.:",
                            type: "text",
                            name: "pinNo",
                            Placeholder: "Enter",
                            value: "",
                            maxLength: 10,
                            onKeyDown: /^[0-9]+$/
                        },
                    ],
                    [
                        {
                            id: 22,
                            label: "Next",
                            type: "buttonNxt",
                            position: "end",
                            textColor: "white",
                            event: "submit",
                        },
                    ],
                ],
            },
        ],
    };
    return {
        BasicDetail
    };
};

export default ClientBasicJSON;

