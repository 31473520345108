import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  message,
} from "antd";
import Button from "atoms/Button";
import queryKeys from "common/queryKey";
import { useUpdate } from "hooks/useUpdate";
import { find, inRange, some } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { findWeeksInRange, generateSchedule, momentDate } from "utils/Date";
import useResourceFn from "./useResourceFn";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "redux/selectors";
import { setTempEngagementFormData } from "redux/actions";

const labelStyle = {
  // width: "120px",
  display: "inline-block",
  textAlign: "start",
};

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;

const EditResourceModal = ({
  show,
  onClose,
  header,
  selectedResource,
  bookedUserList,
  refetch,
  engagementData,
  tempEngagementFormData,
}) => {
  const [dayHour, setDayHour] = useState(0);
  const filterUserBookedList = bookedUserList?.filter(
    (user) =>
      user.userId === selectedResource?.userId &&
      user?.resourceId !== selectedResource?.resourceId
  );
  const dispatch = useDispatch();

  // const actualBookedDates = filterUserBookedList?.map((item) => ({
  //   startDate: item.actualBookedStartDate,
  //   endDate: item.actualBookedEndDate,
  // }));

  //? Temporary fix for Enabling resource booking for the same date
  const actualBookedDates = [];

  const [form] = Form.useForm();

  const { updateResource } = queryKeys;

  const { mutate: updateData, isLoading: isUpdating } = useUpdate(
    updateResource.key,
    updateResource.url
  );

  const { Option } = Select;

  const justificationOptions = [
    "Availability",
    "Expense/Experience",
    "Subject Matter Expert",
    "Cost/Value",
    "Client Experience/History",
    "Technical/Education Qualification",
  ];

  const formatDate = (date) => {
    return date ? moment(date) : moment(); // Convert valid dates to moment objects, return null otherwise
  };
  const userList = useSelector((state) => getUserList(state));

  useEffect(() => {
    setPersonDay(0);
    setDayHour(0);
  }, []);

  useEffect(() => {
    const selecetedPartner =
      engagementData?.stakeHolder?.partner ?? tempEngagementFormData?.partner;
    form.setFieldsValue({
      selected_resource: selectedResource?.employeeName,
      partner: find(userList, {
        userid: selecetedPartner,
      })?.fullname,
      person_day: selectedResource?.personDay,
      booked_start_date: formatDate(selectedResource?.actualBookedStartDate),
      booked_finish_date: formatDate(selectedResource?.actualBookedEndDate),
      effort: selectedResource?.effort,
      project_role: selectedResource?.projectRole,
      status: selectedResource?.status,
      remark: selectedResource?.remarks,
      justification: selectedResource?.justification,
      resourceExtRate:
        selectedResource?.personDay * selectedResource?.resourceExtRate * 8,
    });
    calculateEffort();
    workingdays();
  }, [selectedResource]);

  const plannedStartDate = tempEngagementFormData?.plan_start_date
    ? moment(tempEngagementFormData?.plan_start_date)
    : engagementData?.stakeHolder?.planStartDate
    ? moment(engagementData?.stakeHolder?.planStartDate, "DD-MM-YYYY")
    : null;

  const plannedEndDate = tempEngagementFormData?.plan_start_date
    ? moment(tempEngagementFormData?.plan_end_date)
    : engagementData?.stakeHolder?.planEndDate
    ? moment(engagementData?.stakeHolder?.planEndDate, "DD-MM-YYYY")
    : null;

  const restrictPastDate = (current, element, ranges) => {
    let customDate = moment("2022-08-01").format("YYYY-MM-DD");

    if (plannedStartDate && plannedEndDate) {
      if (current < plannedStartDate || current > plannedEndDate) {
        return true; // Disable dates outside the planned start and end date
      }
    }

    if (ranges) {
      for (let range of ranges) {
        if (
          current >= moment(range.startDate) &&
          current <= moment(range.endDate).add(1, "day")
        ) {
          return true; // Disable current date
        }
      }
    }
    if (element === "booked_finish_date") {
      return current < moment(form.getFieldValue("booked_start_date"));
    }

    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  const restrictPastStartDate = (current, ranges) => {
    let customDate = moment("2022-08-01").format("YYYY-MM-DD");

    if (plannedStartDate && plannedEndDate) {
      if (current < plannedStartDate || current > plannedEndDate) {
        return true; // Disable dates outside the planned start and end date
      }
    }

    if (ranges) {
      for (let range of ranges) {
        if (
          current >= moment(range.startDate) &&
          current <= moment(range.endDate).add(1, "day")
        ) {
          return true; // Disable current date
        }
      }
    }

    return current && current < plannedStartDate;
  };

  const [days, setDays] = useState(0);
  const { calculateEffort, validateDateRange, workingdays } = useResourceFn(
    form,
    filterUserBookedList,
    setDays
  );

  const accessRoleOptions = [
    { value: "Is Auditor", label: "Is Auditor" },
    { value: "Member", label: "Member" },
  ];

  const statusList = [
    { value: "Assigned", label: "Assigned" },
    { value: "Un Assigned", label: "Un Assigned" },
  ];

  const onFinish = async (form) => {
    try {
      const startDate = form?.booked_start_date;
      const endDate = form?.booked_finish_date;
      const weeksInRange = findWeeksInRange(startDate, endDate);
      const prevWeekList = findWeeksInRange(
        selectedResource?.actualBookedStartDate || moment(),
        selectedResource?.actualBookedEndDate || moment()
      );

      const prevWeekListweekIds = prevWeekList?.map((item) => item.weekId);
      const payload = {
        userId: selectedResource?.userId,
        employeeName: selectedResource?.employeeName,
        employeeId: selectedResource?.employeeId,
        accessRole: form?.access_role,
        projectRole: form?.project_role,
        actualBookedStartDate: moment(form?.booked_start_date).format(
          "YYYY-MM-DD"
        ),
        actualBookedEndDate: moment(form?.booked_finish_date).format(
          "YYYY-MM-DD"
        ),
        engagementId: selectedResource?.engagementId,
        engagementName: selectedResource?.engagementName,
        clientId: engagementData?.clientId,
        effort: form?.effort,
        remarks: form?.remark,
        personDay: form?.person_day,
        resourceExtRate: selectedResource?.resourceExtRate,
        status: "Assigned",
        engagementStartDate: selectedResource?.engagementStartDate,
        engagementEndDate: selectedResource?.engagementEndDate,
        weekList: weeksInRange,
        previousWeekList: prevWeekListweekIds,
        resourceId: selectedResource?.resourceId,
        justification: form?.justification,
        managingOffice: selectedResource?.managingOffice,
      };
      if (engagementData) {
        updateData(payload, {
          onSuccess: () => {
            message.success("Successfully Updated");
            onClose();
            refetch();
          },
        });
      } else {
        // Get the existing resource list from tempEngagementFormData
        const existingResourceList = tempEngagementFormData.resourceList;

        // Function to update the resource list based on userId
        function updateResourceList(resourceList, payload) {
          const { userId, updatedData, projectRole } = payload;
          return resourceList.map((resource) => {
            // Check if the resource's userId matches the payload userId
            if (
              resource.userId === userId &&
              resource.projectRole === projectRole
            ) {
              console.log("comming");
              return { ...payload, resourceExtRate: resource?.resourceExtRate };
            }
            return resource; // Return the resource unchanged if it doesn't match
          });
        }

        // Call the function to get the updated resource list
        const updatedResourceList = updateResourceList(
          existingResourceList,
          payload
        );

        // Create the updated engagement data with the new resourceList
        const updatedEngagementData = {
          ...tempEngagementFormData,
          resourceList: updatedResourceList,
        };
        console.log("updatedEngagementData --->", updatedEngagementData);
        // Dispatch the updated engagement data
        dispatch(setTempEngagementFormData(updatedEngagementData));
        onClose();
      }
    } catch (error) {
      message.error(
        "Something went wrong. Please reach out to our technical team."
      );
      console.error("An error occurred:", error);
    }
  };

  const schedule = generateSchedule(
    form?.getFieldValue("booked_start_date"),
    form?.getFieldValue("booked_finish_date")
  );

  const isAnyStakeHolder = () => {
    return (
      selectedResource?.userId === engagementData?.stakeHolder?.partner ||
      selectedResource?.userId ===
        engagementData?.stakeHolder?.personIncharge ||
      selectedResource?.userId ===
        engagementData?.stakeHolder?.relationshipPartner ||
      selectedResource?.userId === engagementData?.stakeHolder?.isauditor ||
      selectedResource?.userId === tempEngagementFormData?.partner ||
      selectedResource?.userId === tempEngagementFormData?.isauditor ||
      selectedResource?.userId === tempEngagementFormData?.person_incharge ||
      selectedResource?.userId === tempEngagementFormData?.relationship_partner
    );
  };

  const [personDay, setPersonDay] = useState(0);

  useEffect(() => {
    let value = parseFloat(
      (
        (parseFloat(form.getFieldValue("person_day")) * 8) /
        (schedule?.length || 1)
      ).toFixed(2)
    );
    setDayHour(value);
  }, [schedule, setPersonDay, personDay]);

  return (
    <StyledModal
      title={header}
      visible={show}
      onCancel={() => {
        onClose();
      }}
      width={800}
      footer={[]}
    >
      <AddResourceModalWrap>
        <Form layout="vertical" onFinish={onFinish} colon={false} form={form}>
          <div className="resource-card">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Partner</span>}
                  name="partner"
                  rules={[
                    {
                      required: false,
                      message: "Partner is required.",
                    },
                  ]}
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Selected Resource</span>}
                  name="selected_resource"
                  rules={[
                    {
                      required: false,
                      message: "Resource is required.",
                    },
                  ]}
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              {/* <Form.Item
                  label={<span style={labelStyle}>Access Role</span>}
                  name="access_role"
                >
                  <Select
                    placeholder="Select"
                    options={accessRoleOptions.map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    fieldNames={{
                      label: "label",
                      value: "value",
                    }}
                  />
                </Form.Item> */}
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Booked Start Date</span>}
                  name="booked_start_date"
                  rules={[
                    {
                      required: true,
                      message: "Booked Start Date is required.",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select Date"
                    disabledDate={(current) =>
                      restrictPastStartDate(current, actualBookedDates)
                    }
                    onChange={() => {
                      validateDateRange();
                      workingdays();
                      calculateEffort();
                    }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Booked Finish Date</span>}
                  name="booked_finish_date"
                  dependencies={["booked_start_date"]}
                  rules={[
                    {
                      validator: (rule, value) => {
                        const bookedStartDate =
                          form.getFieldValue("booked_start_date");
                        if (bookedStartDate && value) {
//                           const isStartDateBeforePastDate = moment(
//                             bookedStartDate
//                           ).isBefore(moment(), "day");
// 
//                           if (isStartDateBeforePastDate) {
//                             return Promise.reject(
//                               "Cannot book for past dates."
//                             );
//                           }

                          // const isWithinRange = some(
                          //   generateSchedule(
                          //     form.getFieldValue("booked_start_date"),
                          //     value
                          //   ),
                          //   (scheduleEntry) => {
                          //     return some(actualBookedDates, (entry) => {
                          //       const startDate = moment(entry?.startDate);
                          //       const endDate = moment(entry?.endDate).add(
                          //         1,
                          //         "day"
                          //       );
                          //       return inRange(
                          //         scheduleEntry?.fullDate,
                          //         startDate,
                          //         endDate
                          //       );
                          //     });
                          //   }
                          // );
                          // if (isWithinRange) {
                          //   return Promise.reject("Already booked!.");
                          // }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select Date"
                    disabledDate={(current) =>
                      restrictPastDate(
                        current,
                        "booked_finish_date",
                        actualBookedDates
                      )
                    }
                    onChange={() => {
                      workingdays();
                      calculateEffort();
                    }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span
                  style={{
                    ...labelStyle,
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#5D5F68",
                    marginBottom: "20px",
                  }}
                >
                  Total Booked Mandays: {days}
                </span>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Budget Mandays</span>}
                  name="person_day"
                  rules={[
                    {
                      required: true,
                      message: "Budget Mandays is required.",
                    },
                    {
                      validator: (_, value) => {
                        // Ensure value is a number

                        const numericValue = Number(value);

                        const isFloatingNumber = numericValue % 1 !== 0;

                        const floatEnd = numericValue.toString().split(".")[1];

                        if (
                          !Number.isInteger(numericValue) &&
                          !isFloatingNumber
                        ) {
                          return Promise.reject(
                            "Budget Mandays must be a number or half number"
                          );
                        }

                        // if (isFloatingNumber && floatEnd !== "5") {
                        //   return Promise.reject(
                        //     "Budget Mandays must be half or whole number"
                        //   );
                        // }

                        if (numericValue <= 0 && value) {
                          return Promise.reject(
                            "Budget Mandays must be greater than 0."
                          );
                        }

                        if (numericValue > days) {
                          return Promise.reject(
                            `No of Days must be less than or equal to ${days}`
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter"
                    min={1}
                    max={days}
                    onChange={(e) => setPersonDay(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Role</span>}
                  name="project_role"
                  rules={[
                    {
                      required: true,
                      message: "Role is required.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select  role"
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={accessRoleOptions.map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    fieldNames={{
                      label: "label",
                      value: "value",
                    }}
                    disabled={isAnyStakeHolder()}
                  />
                </Form.Item>
              </Col>

              <Form.Item
                label={<span style={labelStyle}>%Effort</span>}
                name="effort"
                rules={[
                  {
                    required: true,
                    message: "Effort is required.",
                  },
                ]}
                style={{ display: "none" }}
              >
                <Input placeholder="Enter" disabled />
              </Form.Item>
            </Row>
            {schedule.length > 0 && (
              <StyledTable
                showHeader={true}
                columns={[
                  {
                    title: "Date",
                    dataIndex: "date",
                    key: "date",
                    width: "30%",
                    className: "action-status",
                  },
                  {
                    title: "Work hours",
                    dataIndex: "value",
                    key: "value",
                    width: "70%",
                    render: (val) => {
                      // let value = parseFloat(
                      //   (
                      //     (parseInt(form?.getFieldValue("person_day")) * 8) /
                      //     (schedule?.length || 1)
                      //   ).toFixed(2)
                      // );
                      return (
                        <span>{!isNaN(dayHour) ? dayHour : "0"} hours</span>
                      );
                    },
                  },
                ]}
                dataSource={schedule}
                pagination={false}
              />
            )}

            <Col span={24} className="mt-2">
              <Form.Item
                label="Justification"
                name="justification"
                rules={[
                  { required: true, message: "Please select a justification" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a justification"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {justificationOptions.map((justification) => (
                    <Option key={justification} value={justification}>
                      {justification}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </div>

          <div className="text-end">
            <Button type="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={isUpdating}>
              Proceed
            </Button>
          </div>
        </Form>
      </AddResourceModalWrap>
    </StyledModal>
  );
};

export default EditResourceModal;

const AddResourceModalWrap = styled.div`
  padding: 3%;
  /* .ant-form-item {
    margin-bottom: 14px;
  } */
  .ant-picker {
    width: 100%;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 5px 10px;
    font-size: 13px;
  }
  .action-status {
    background-color: #f7f7f8;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border: 1px solid #dbdbdb;
  }
`;
