import { SearchOutlined } from "@mui/icons-material";
import { Input, Select, Spin } from "antd";
import Button from "atoms/Button";
import Table from "atoms/Table";
import queryKeys from "common/queryKey";
import { useScrollFetch } from "hooks/useScrollFetch";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import AddTodoModal from "./AddTodoModal";
import { useMutate } from "hooks/useMutate";
import { getUserList } from "redux/selectors";
import { useSelector } from "react-redux";

const columns = [
  {
    title: "Task Title",
    dataIndex: "task_title",
    key: "task_title",
  },
  {
    title: "Assigned To",
    dataIndex: "assigned_to",
    key: "assigned_to",
  },
  {
    title: "Assigned By",
    dataIndex: "assigned_by",
    key: "assigned_by",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Due Date",
    dataIndex: "due_date",
    key: "due_date",
  },
];

const TodoList = ({ engagementData }) => {
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [showAddTodoModal, setShowAddTodoModal] = useState(false);
  const [priority, setPriority] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null);

  const userList = useSelector((state) => getUserList(state));

  const { getTodoList, postFilterTodoList } = queryKeys;

  const { key, url } = getTodoList(engagementData?.id);

  const {
    data,
    lastElementRef,
    isLoading: loader,
    refetch,
  } = useScrollFetch(
    key,
    {
      endPoint: url,
      queryParam: "LastEvaluatedKey",
      extra: {
        engagementId: engagementData?.id,
      },
    },
    {
      enabled: !!engagementData?.id,
    }
  );

  const todos = useMemo(() => {
    return data?.pages.reduce((acc, page) => {
      if (page?.data === undefined) return acc;
      return [...acc, ...page?.data];
    }, []);
  }, [data]);

  const [todoList, setTodoList] = useState([]);

  const { key: filterKey, url: filterUrl } = postFilterTodoList(
    engagementData?.id
  );

  const [filteredData, setFilteredData] = useState(null);

  const {
    mutate,
    data: filteredDataRes,
    isLoading: isFilterLoading,
  } = useMutate(filterKey, filterUrl);

  useEffect(() => {
    if (filteredDataRes) {
      setFilteredData(filteredDataRes?.data);
    }
  }, [filteredDataRes]);

  useEffect(() => {
    if (priority || assignedTo) {
      mutate({
        filterData: {
          ...(priority && { priority }),
          ...(assignedTo && { assignedTo }),
        },
      });
    } else {
      setFilteredData(null);
    }
  }, [priority, assignedTo, mutate]);

  useEffect(() => {
    const findUser = (id) => {
      return userList.find((user) => user.userid === id);
    };

    const todoMapper = (todo) => {
      const assignedByUser = findUser(todo?.assignedBy);
      const assignedToUser = findUser(todo?.responsible);

      return {
        task_id: todo?.todoId,
        engagement_id: todo?.engagementId,
        task_title: todo?.taskTitle,
        assigned_to:
          assignedToUser !== undefined
            ? assignedToUser?.firstname + " " + assignedToUser?.lastname
            : "-",
        assigned_by:
          assignedByUser !== undefined
            ? assignedByUser?.firstname + " " + assignedByUser?.lastname
            : "-",
        priority: (
          <div
            className={`priority ${
              todo?.priority ? todo?.priority?.toLowerCase() : ""
            }`}
          >
            {todo?.priority
              ? todo?.priority.charAt(0).toUpperCase() + todo?.priority.slice(1)
              : ""}
          </div>
        ),
        status: todo?.status,
        due_date: todo?.planEndDate,
      };
    };

    if (filteredData) {
      setTodoList(
        filteredData
          .filter((todo) => {
            if (search && todo?.taskTitle) {
              return todo.taskTitle
                .toLowerCase()
                .includes(search.toLowerCase());
            }
            return true;
          })
          .map(todoMapper)
      );
    } else if (todos) {
      setTodoList(
        todos
          .filter((todo) => {
            if (search && todo?.taskTitle) {
              return todo.taskTitle
                .toLowerCase()
                .includes(search.toLowerCase());
            }
            return true;
          })
          .map(todoMapper)
      );
    } else {
      setTodoList([]);
    }
  }, [todos, filteredData, userList, search]);

  return (
    <TodoListWrap>
      <div className="title">
        <Input
          placeholder="Search by name"
          className="search-box"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          prefix={<SearchOutlined />}
        />

        <Select
          placeholder="Priority"
          className="priority-select"
          value={priority}
          onChange={(value) => setPriority(value)}
          options={[
            { value: "low", label: "Low" },
            { value: "medium", label: "Medium" },
            { value: "high", label: "High" },
          ]}
          allowClear={true}
        />
        <Select
          placeholder="Assigned To"
          className="assigned-to-select"
          value={assignedTo}
          onChange={(value) => setAssignedTo(value)}
          allowClear={true}
        />

        <Button
          className="ms-auto"
          type="primary"
          onClick={() => {
            setShowAddTodoModal(true);
          }}
        >
          Add New
        </Button>
      </div>

      <Spin spinning={loader || isFilterLoading}>
        <Table
          onRow={(record, _rowIndex) => {
            return {
              className: "table-row",
              ref: lastElementRef,
              onClick: (event) => {
                history.push(
                  `/engagement/${engagementData.id}/todo/${record.task_id}`
                );
              },
            };
          }}
          dataSource={todoList}
          columns={columns}
          rowClassName={(_record, index) =>
            index % 2 === 0 ? "" : "non-white"
          }
          scroll={
            todoList.length > 10
              ? {
                  y: "calc(100vh - 400px)",
                }
              : null
          }
          rowKey="todo_task_id"
          pagination={false}
        />
      </Spin>

      <AddTodoModal
        open={showAddTodoModal}
        close={() => setShowAddTodoModal(false)}
        engagementData={engagementData}
        refetch={refetch}
      />
    </TodoListWrap>
  );
};

export default TodoList;

const TodoListWrap = styled.div`
  .title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .search-box {
    max-width: 250px;
    margin-bottom: 8px;
  }

  .green-dot {
    height: 15px;
    content: "";
    width: 30px;
    background-color: #4caf50;
    border-radius: 9999px;
    display: inline-block;
  }

  .table-row {
    cursor: pointer;
  }
  .ant-table-cell-scrollbar {
    display: none;
  }
  .ant-input-affix-wrapper {
    margin-bottom: 0;
  }
  .priority-select {
    width: 100%;
    min-width: 130px;
    max-width: 200px;
  }
  .assigned-to-select {
    width: 100%;
    min-width: 130px;
    max-width: 200px;
  }
  .priority {
    font-weight: 600;
    &.low {
      color: #07b715;
    }
    &.medium {
      color: #f88548;
    }
    &.high {
      color: #e00000;
    }
  }
`;
