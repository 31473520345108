import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import Button from "atoms/Button";
import Title from "atoms/Title";
import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "assets/icons/UI/search.svg";
import AddResourceModal from "../AddResourceModal";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import {
  getAllDepartmentList,
  getManagingOffice,
  getTempEngagementFormData,
  getUserInfo,
  getUserList,
} from "redux/selectors";
import { concat, every, flatMap, inRange, map, some, uniqBy } from "lodash";
import Table from "atoms/Table";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ViewMore from "organisms/ViewMore";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useFetch } from "hooks/useFetch";
import queryKeys from "common/queryKey";
import { findWeekIdsInRange } from "utils/Date";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import { currencyAmount } from "utils/functions";
import { Grade } from "common/Constants";

const { RangePicker } = DatePicker;

const resourceColumns = [
  {
    title: "Resource Name",
    dataIndex: "name",
    key: "name",
    // width: 150,
  },
  // {
  //   title: "Department",
  //   dataIndex: "department",
  //   key: "department",
  //   className: "normal-column",
  //   // width: 150,
  // },
  {
    title: "Charge Out / hr",
    dataIndex: "chargeOutRate",
    key: "chargeOutRate",
    // width: 80,
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    // width: 80,
  },
  {
    title: "Schedule",
    dataIndex: "schedule",
    key: "schedule",
    // align: "center",
  },
];

const AddResources = ({
  enagementStartDate = "2024-04-01",
  enagementEndDate = "2024-04-29",
  userList,
  allDepartmentList,
}) => {
  const [showAddResourceModal, setShowAddResourceModal] = React.useState(false);
  const [bookedUserList, setBookedUserList] = React.useState([]);
  const userInfo = useSelector((state) => getUserInfo(state));
  const manageoffice = useSelector((state) => getManagingOffice(state));
  const tempEngagementFormData = useSelector((state) =>
    getTempEngagementFormData(state)
  );
  const managinOfficeOptions = manageoffice.map((value) => {
    return {
      label: value,
      value: value,
    };
  });
  const filteruser = userList?.filter(
    (val) => val?.userid === userInfo?.userid
  );

  const { getEngagementById } = queryKeys;

  const { id } = useParams();

  const { key: engagementKey, url: engagementUrl } = getEngagementById(id);

  const { data: engagementDataRes, refetch: engagementRefetch } = useFetch(
    engagementKey,
    engagementUrl,
    {
      enabled: !!id,
    }
  );

  const engagementData = engagementDataRes?.data;

  const [selectedDateRange, setSelectedDateRange] = React.useState([
    moment(),
    moment().add(30, "days"),
  ]);

  const [allResourceList, setAllResourceList] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [availabilityFilter, setAvailabilityFilter] = React.useState("");
  const [selectedResource, setSelectedResource] = React.useState({});
  const history = useHistory();

  const handleDateChange = (dates, dateStrings) => {
    setSelectedDateRange(dates);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleAvailabilityFilter = (value) => {
    setAvailabilityFilter(value);
  };

  const filterEmployees = (employee) => {
    if (
      employee.name
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) &&
      (availabilityFilter === "" ||
        (availabilityFilter === "Completely available" &&
          employee.status === "Completely available") ||
        (availabilityFilter === "Partially available" &&
          employee.status === "Partialy available") ||
        (availabilityFilter === "Booked" && employee.status === "Booked"))
    ) {
      return true;
    }
    return false;
  };

  const filteredResource = allResourceList?.filter(filterEmployees);

  React.useEffect(() => {
    refetch();
  }, [selectedDateRange]);

  const generateSchedule = (startDate, endDate) => {
    const schedule = [];
    let currentDate = moment(startDate);
    const end = moment(endDate);

    while (currentDate.isSameOrBefore(end, "day")) {
      const dayOfWeek = currentDate.day();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        const dateString = currentDate.format("MMM DD");
        const fullDateFormatted = currentDate.format("YYYY-MM-DD");
        schedule.push({
          date: dateString,
          available: true,
          fullDate: moment(fullDateFormatted),
        });
      }
      currentDate.add(1, "day");
    }

    return schedule;
  };

  const { getAllocatedResourceList } = queryKeys;

  const weekList = findWeekIdsInRange(selectedDateRange);

  const { key, url } = getAllocatedResourceList(weekList?.join(", "));

  const {
    data: resourceAllocatedData,
    isLoading,
    refetch,
  } = useFetch(
    key,
    url,
    {
      enabled: weekList?.length > 0,
    },
    {
      engagementId: "",
      weekLists: weekList,
      startDate: new Date(selectedDateRange[0]),
      endDate: new Date(selectedDateRange[1]),
      // startDate: "",
      // endDate: "",
    }
  );

  useEffect(() => {
    getData(resourceAllocatedData?.response?.response);
  }, [resourceAllocatedData]);

  const getData = async (response) => {
    const filterUserBasedOnManagingOffice = (item1) => {
      return filteruser[0]?.managingoffice.some((val) => {
        return item1?.managingoffice.includes(val);
      });
    };

    if (response) {
      const [startDate, endDate] = selectedDateRange;
      const flattenedArray = concat(
        response?.flat(),
        tempEngagementFormData?.resourceList ?? []
      );
      setBookedUserList(flattenedArray);

      const uniqBookedList = uniqBy(flattenedArray, "userId");
      let filteredArray;

      if (filteruser[0]?.OfficeSupervisorId === "none") {
        //? if the user is the top (main guy), who reports to "none"
        filteredArray = userList?.filter(
          (item1) =>
            !flattenedArray.some((item2) => item1.userid === item2.userId) &&
            item1.statusid === "1"
        );
      } else {
        filteredArray = userList?.filter(
          (item1) =>
            !flattenedArray.some((item2) => item1.userid === item2.userId) &&
            filterUserBasedOnManagingOffice(item1) &&
            item1.statusid === "1"
        );
      }

      const transformedAvailableUserList = map(filteredArray, (userData) => {
        const schedule = generateSchedule(startDate, endDate);

        return {
          userId: userData?.userid,
          name: userData?.fullname,
          status: "Completely available",
          employeeId: userData?.employeeid,
          resourceExtRate: userData?.chargeoutrate,
          country: userData?.managingoffice,
          schedule,
        };
      }).filter((user) =>
        user?.country.includes(
          engagementData?.client?.managingOffice ??
            tempEngagementFormData?.managing_office
        )
      );
      const transformedBookedUserList = map(uniqBookedList, (userData) => {
        const filteredArray = flattenedArray?.filter(
          (user) => user.userId === userData?.userId
        );

        const chargeOutRate = userList?.find(
          (user) => user?.userid === userData?.userId
        )?.chargeoutrate;

        const filteruser = userList?.find(
          (user) => user?.userid === userData?.userId
        );
        const schedule = generateSchedule(startDate, endDate);
        const isWithinRange = every(schedule, (scheduleEntry) => {
          return some(filteredArray, (entry) => {
            const startDate = moment(entry?.actualBookedStartDate);
            const endDate = moment(entry?.actualBookedEndDate).add(1, "day");
            return inRange(scheduleEntry?.fullDate, startDate, endDate);
          });
        });

        return {
          userId: userData?.userId,
          name: userData?.employeeName,
          status: isWithinRange ? "Booked" : "Partially available",
          employeeId: userData?.employeeId,
          resourceExtRate: chargeOutRate,
          country: filteruser?.managingoffice,
          schedule,
        };
      }).filter((user) =>
        user?.country?.includes(
          engagementData?.client?.managingOffice ??
            tempEngagementFormData?.managing_office
        )
      );

      const concatenatedData = concat(
        transformedAvailableUserList,
        transformedBookedUserList
      );
      setAllResourceList(concatenatedData);
    }
  };
  
  const filterExceptStakeHolder = (item) => {
    if (engagementData?.id) {
      const partner = engagementData?.stakeHolder?.partner;
      const qaPartner = engagementData?.stakeHolder?.relationshipPartner;
      const personInCharge = engagementData?.stakeHolder?.personIncharge;
      const isAuditor = engagementData?.stakeHolder?.isauditor;

      return (
        item.userId !== partner &&
        item.userId !== qaPartner &&
        item.userId !== personInCharge &&
        item.userId !== isAuditor
      );
    } else {
      const partner = tempEngagementFormData?.partner;
      const qaPartner = tempEngagementFormData?.relationship_partner;
      const personincharge = tempEngagementFormData?.person_incharge;
      const isauditor = tempEngagementFormData?.isauditor;

      return (
        item.userId !== partner &&
        item.userId !== qaPartner &&
        item.userId !== personincharge &&
        item.userId !== isauditor
      );
    }
  };

  
  const dataSource = allResourceList
    ?.filter(filterExceptStakeHolder)
    ?.map((resource) => {
      const isUserBooked = some(bookedUserList, { userId: resource.userId });
      const filterUserBookedList = bookedUserList?.filter(
        (user) => user.userId === resource.userId
      );
      const filteredUser = userList?.filter(
        (user) => user?.userid === resource?.userId
      );

      let userDepartmentIds =
        filteredUser.length > 0 ? filteredUser[0]?.userdepartmentid : [];

      const departmentId = flatMap(userDepartmentIds);

      const department = allDepartmentList?.filter((item) =>
        departmentId.includes(item?.department_id)
      );

      const departmentNames = department?.map((val) => val?.department_name);
      const departmentName = departmentNames?.join(", ") || "-";

      const chargeOutRate = userList?.find(
        (user) => user?.userid === resource?.userId
      )?.chargeoutrate;

      const grade = filteredUser[0]?.grade;

      return {
        key: resource.userId,
        name: resource.name,
        // department: <ViewMore value={departmentName} />,
        chargeOutRate: currencyAmount("$", chargeOutRate),
        status: resource?.status,
        country: resource?.country,
        grade: grade,
        schedule: (
          <Schedule
            schedule={resource.schedule}
            bookedUserList={filterUserBookedList}
            isUserBooked={isUserBooked}
          />
        ),
        action: (
          <Button
            className="rounded-3"
            type="primary"
            onClick={() => {
              setShowAddResourceModal(true);
              setSelectedResource(resource);
            }}
          >
            Add
          </Button>
        ),
      };
    });

  const breadStyle = { color: "#636980", cursor: "pointer" };
  const breadcrumbs = [
    <label key="2" style={breadStyle} onClick={() => history.goBack()}>
      {engagementData?.information?.jobTitle ?? "back"}
    </label>,
    <Typography key="3" color="text.primary">
      Resources
    </Typography>,
  ];

  const [form] = Form.useForm();

  const gradeOptions = Grade.map((value) => {
    return {
      label: value,
      value: value,
    };
  });

  const getFilteredData = () => {
    const userid = form.getFieldValue("userid") || [];
    const available = form.getFieldValue("available") || [];
    const country = form.getFieldValue("country") || [];
    const grade = form.getFieldValue("grade") || [];
    // console.log("country -->", country);

    if (
      userid.length > 0 ||
      available.length > 0 ||
      country.length > 0 ||
      grade.length > 0
    ) {
      return dataSource?.filter((item) => {
        const userMatch =
          userid && userid.length > 0
            ? userid.some((n) => item.key === n)
            : true;

        const countryMatch =
          country && country.length > 0
            ? country.some((n) => item.country?.includes(n))
            : true;

        const gradeMatch =
          grade && grade.length > 0
            ? grade.some((n) => item.grade === n)
            : true;

        const availableMatch =
          available && available.length > 0
            ? available.includes(item.status)
            : true;

        return userMatch && availableMatch && countryMatch && gradeMatch;
      });
    } else {
      return dataSource;
    }
  };

  const resourceType = [
    {
      label: "Completely Available",
      value: "Completely available",
    },
    {
      label: "Partially Available",
      value: "Partially available",
    },
    {
      label: "Booked",
      value: "Booked",
    },
  ];

  useEffect(() => {
    refetch();
  }, [showAddResourceModal]);

  return (
    <AddResourcesWrap>
      <div className="d-flex gap-2">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <br />

      <Row>
        <Col sm={24}>
          <Form
            form={form}
            layout="vertical"
            className="mt-2"
            onValuesChange={() => form.submit()}
          >
            <Row gutter={12} className="g-3 mb-2">
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="userid"
                  label="Resource"
                  options={uniqBy(
                    dataSource?.map((item) => {
                      return { label: item?.name, value: item?.key };
                    }),
                    "value"
                  )}
                  form={form}
                />
              </Col>
              {/* {filteruser && filteruser[0]?.OfficeSupervisorId === "none" && ( */}
              {filteruser &&
                filteruser?.[0]?.managingoffice?.[0] === "Head Office" && (
                  <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                    <DropdownCheckBox
                      name="country"
                      label="Country"
                      options={managinOfficeOptions}
                      form={form}
                    />
                  </Col>
                )}
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="grade"
                  label="Grade"
                  options={gradeOptions}
                  form={form}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="available"
                  label="Availability"
                  options={resourceType}
                  form={form}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <RangePicker
                  value={selectedDateRange}
                  onChange={handleDateChange}
                  format="DD-MM-YYYY"
                  style={{
                    width: "300px",
                    borderRadius: "16px",
                  }}
                  allowClear={false}
                />
              </Col>
            </Row>
          </Form>
        </Col>
        <div className="legend">
          <Legend available />
          <Legend booked />
        </div>
      </Row>

      <Table
        columns={resourceColumns}
        dataSource={getFilteredData()}
        // pagination={false}
        rowKey="key"
        loading={isLoading}
      />

      <AddResourceModal
        show={showAddResourceModal}
        onClose={() => setShowAddResourceModal(false)}
        header="Add Resource"
        selectedResource={selectedResource}
        bookedUserList={bookedUserList}
        getData={() => {
          refetch();
          engagementRefetch();
        }}
        engagementData={engagementData}
        tempEngagementFormData={tempEngagementFormData}
      />
    </AddResourcesWrap>
  );
};

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  allDepartmentList: getAllDepartmentList(state),
});

export default connect(mapStateToProps, null)(AddResources);

const Schedule = ({ schedule, bookedUserList, isUserBooked }) => {
  const [renderIndex, setRenderIndex] = React.useState(0);
  const itemsPerPage = 7;

  const nextPage = () => {
    if (renderIndex < schedule?.length - itemsPerPage) {
      setRenderIndex(renderIndex + itemsPerPage);
    }
  };

  const prevPage = () => {
    if (renderIndex > 0) {
      setRenderIndex(renderIndex - itemsPerPage);
    }
  };

  return (
    <div className="schedule-wrap">
      <Button
        className="schedule rounded-3 back"
        type="primary"
        onClick={prevPage}
        disabled={renderIndex === 0}
      >
        <KeyboardArrowLeftIcon />
      </Button>
      {schedule.slice(renderIndex, renderIndex + itemsPerPage).map((day) => {
        const isBetween = some(bookedUserList, (entry) => {
          const startDate = moment(entry?.actualBookedStartDate);
          const endDate = moment(entry?.actualBookedEndDate).add(1, "day");
          return inRange(day?.fullDate, startDate, endDate);
        });
        return (
          <div
            key={day.date}
            className={
              isUserBooked && isBetween ? "day booked" : "day available"
            }
          >
            {day.date}
          </div>
        );
      })}
      <Button
        className="schedule rounded-3 next"
        type="primary"
        onClick={nextPage}
        disabled={renderIndex >= schedule.length - itemsPerPage}
      >
        <KeyboardArrowRightIcon />
      </Button>
    </div>
  );
};

const Legend = ({ available = false }) => {
  return (
    <div className="d-flex gap-2 align-items-center">
      <div className={available ? "legend-box available" : "legend-box booked"}>
        {null}
      </div>
      <span>{available ? "Available" : "Booked"}</span>
    </div>
  );
};

const AvailableColor = "#EDFFF7";
const BookedColor = "#FFD3D3";

const AddResourcesWrap = styled.div`
  .back {
    cursor: pointer;
    text-decoration: underline;
  }
  .legend {
    display: flex;
    gap: 0.5rem;

    .legend-box {
      width: 20px;
      height: 20px;
      border-radius: 5px;

      &.available {
        background: ${AvailableColor};
      }
      &.booked {
        background: ${BookedColor};
      }
    }
  }
  .day {
    width: 90px;
    height: 38px;
    border-radius: 5px;
    padding: 6px 12px;
    text-align: center;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.available {
      background: ${AvailableColor};
    }
    &.booked {
      background: ${BookedColor};
    }
  }
  .schedule-wrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
    gap: 2px;
  }
  .schedule {
    padding: 0 0.2rem;
    margin-left: 0;
  }
`;

// const StyleTable = styled(Table)`
//   .ant-table-thead > tr > th {
//     background-color: #f6f6f6;
//     font-weight: 700;
//   }
//   .ant-table-cell {
//     padding: 10px 14px;
//   }
// `;
