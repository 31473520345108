import { getCOICheckProcessJson } from 'JsonFormat/ClientBasicJson/COICheckProcess';
import moment from 'moment';
import React from 'react'
import GridBuilder from 'UIBuilderEngine/GridBuilder';

function COICheckProcess({data}) {
  const initialData = ({
    bdo_hasthelocalcoibeendone: data?.["bdo_hasthelocalcoibeendone@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_hastheglobalcheckbeendone: data?.["bdo_hastheglobalcheckbeendone@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_hasamlandworldcheckbeendone: data?.["bdo_hasamlandworldcheckbeendone@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_hasthecorporatetreebeenrequested: data?.["bdo_hasthecorporatetreebeenrequested@OData.Community.Display.V1.FormattedValue"] || "-",
    liaisonmanager:  data?.["_bdo_relationship_value@OData.Community.Display.V1.FormattedValue"],
    bdo_signature: data?.bdo_signature || "-",
    bdo_date: data?.bdo_date ? moment(data?.bdo_date, "DD-MM-YYYY") : null,
    bdo_acceptanceofthisclient: data?.["bdo_coicheckpassed@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_onboarded: data?.["bdo_onboarded@OData.Community.Display.V1.FormattedValue"] || "-",
  })
    const { COICheckProcessForm, COICheckProcessFormTwo, COICheckProcessFormThree } = getCOICheckProcessJson();
  return (
  <div className="scroll_height">
    <div className='box p-1 px-3 mb-3'>
        <GridBuilder
          formStructureJson={COICheckProcessForm}
          // handleChange={handleFormChange}
          initialValues={initialData}
        />
    </div>
    <div className='box p-2 px-3 mb-3'>
        <h6>CONCLUSION: I have performed the firm’s required Conflict of Interest Checks’ procedures, as indicated above and based on these procedures, I approve the onboarding of this client</h6>
        <GridBuilder
          formStructureJson={COICheckProcessFormTwo}
          // handleChange={handleFormChange}
          initialValues={initialData}
        />
    </div>
    <div className='box p-1 px-3 mb-3'>
        <GridBuilder
          formStructureJson={COICheckProcessFormThree}
          // handleChange={handleFormChange}
          initialValues={initialData}
        />
    </div>
    </div>
  )
}

export default COICheckProcess