import React from 'react';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Row } from 'antd';
import { ReactComponent as ClientIcon } from "../../assets/clientIcon.svg";
import ClientContactDetailsJSON from './ClientContactDetailsJSON';
import GridBuilder from 'UIBuilderEngine/GridBuilder';
import styled from 'styled-components';

const ClientDetaild = styled.div`
  .ant-form-item-control-input-content {
    font-size: 16px;
    font-weight: 500;
    color: #1b1b1b;
  }
  .ant-input {
    border-radius: 5px;
    border: 1px solid #dedede;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  hr{
    border: 1px solid #ccc;
    padding: 0;
    margin: 0;
  }
`;


const ClientContactDetails = () => {
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const extraData = location.state ? location.state.extraData : null;
    const { BasicDetail } = ClientContactDetailsJSON();

    const filteredData = extraData.filter((item) => item?.bdo_id == id);

  return (
    <ClientDetaild>
          <label className="bg-transparent border-0 mb-3 text-left styled-cursor" onClick={() => history.push(`/clientcontact`)} >
          Client Contact<ArrowForwardIosIcon className="px-1" /> 
          </label>
          
          <b className=" pb-0 pt-1">{filteredData[0]?.name}</b>

          <Row >
          <div className="d-flex align-items-center gap-2">
            <div className="client-icon">
              <ClientIcon />
            </div>
            <div>
              <p className="mb-0"><b>{filteredData[0]?.name}</b></p>
              <p className="mb-0">Contact</p>
            </div>
          </div>
        </Row>
        <hr/>

        <GridBuilder
            formStructureJson={BasicDetail}
            initialValues={filteredData[0]}
          />
    </ClientDetaild>
  )
}

export default ClientContactDetails