import React from 'react';
import {
    ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    ReferenceArea
  } from 'recharts';
import ReusableTable from './ReusableTable';

function FinalAppraisal() {

    const tempdata = [
        {
          category: "Technical",
          percent: "40%",
          finalscoreout: "5.9",
          weightedScore: "2.3"
        },
        {
          category: "Individual",
          percent: "60%",
          finalscoreout: "5.4",
          weightedScore: "3.2"
        },
        {
          category: "Final appraisal score",
          percent: "",
          finalscoreout: "",
          weightedScore: "5.5"
        },
      ];

      const columns = [
        {
          title: "percent",
          dataIndex: "category",
          key: "category",
        //   width: "70%",b
        },
        {
          title: "%",
          dataIndex: "percent",
          key: "percent",
        //   width: "30%",
          className: "normal-column",
        },
        {
            title: "Final score out of 6",
            dataIndex: "finalscoreout",
            key: "finalscoreout",
            // width: "30%",
            className: "normal-column",
          },
          {
            title: "Weighted Score",
            dataIndex: "weightedScore",
            key: "weightedScore",
            // width: "30%",
            className: "normal-column",
          },
      ];

    const data = [
        { x: 0, y: 0 },
      ];
      const ReferenceData =  [ 
        {x1:0, x2: 1, y1: 0, y2: 1, color: "#D536367E"},
        {x1:1, x2: 2, y1: 0, y2: 1, color: "#D536367E"},
        {x1:2, x2: 3, y1: 0, y2: 1, color: "#D536367E"},
        {x1:3, x2: 4, y1: 0, y2: 1, color: "#D536367E"},
        {x1:4, x2: 5, y1: 0, y2: 1, color: "#D57F367E"},
        {x1:5, x2: 6, y1: 0, y2: 1, color: "#D57F367E"},
        {x1:0, x2: 1, y1: 1, y2: 2, color: "#D536367E"},
        {x1:1, x2: 2, y1: 1, y2: 2, color: "#D536367E"},
        {x1:2, x2: 3, y1: 1, y2: 2, color: "#D536367E"},
        {x1:3, x2: 4, y1: 1, y2: 2, color: "#D57F367E"},
        {x1:4, x2: 5, y1: 1, y2: 2, color: "#D57F367E"},
        {x1:5, x2: 6, y1: 1, y2: 2, color: "#D57F367E"},
        {x1:0, x2: 1, y1: 2, y2: 3, color: "#D536367E"},
        {x1:1, x2: 2, y1: 2, y2: 3, color: "#D536367E"},
        {x1:2, x2: 3, y1: 2, y2: 3, color: "#D57F367E"},
        {x1:3, x2: 4, y1: 2, y2: 3, color: "#D57F367E"},
        {x1:4, x2: 5, y1: 2, y2: 3, color: "#D57F367E"},
        {x1:5, x2: 6, y1: 2, y2: 3, color: "#00A30031"},
        {x1:0, x2: 1, y1: 3, y2: 4, color: "#D536367E"},
        {x1:1, x2: 2, y1: 3, y2: 4, color: "#D57F367E"},
        {x1:2, x2: 3, y1: 3, y2: 4, color: "#D57F367E"},
        {x1:3, x2: 4, y1: 3, y2: 4, color: "#D57F367E"},
        {x1:4, x2: 5, y1: 3, y2: 4, color: "#00A30031"},
        {x1:5, x2: 6, y1: 3, y2: 4, color: "#00A30031"},
        {x1:0, x2: 1, y1: 4, y2: 5, color: "#D57F367E"},
        {x1:1, x2: 2, y1: 4, y2: 5, color: "#D57F367E"},
        {x1:2, x2: 3, y1: 4, y2: 5, color: "#D57F367E"},
        {x1:3, x2: 4, y1: 4, y2: 5, color: "#00A30031"},
        {x1:4, x2: 5, y1: 4, y2: 5, color: "#00A30031"},
        {x1:5, x2: 6, y1: 4, y2: 5, color: "#00A30031"},
        {x1:0, x2: 1, y1: 5, y2: 6, color: "#D57F367E"},
        {x1:1, x2: 2, y1: 5, y2: 6, color: "#D57F367E"},
        {x1:2, x2: 3, y1: 5, y2: 6, color: "#00A30031"},
        {x1:3, x2: 4, y1: 5, y2: 6, color: "#00A30031"},
        {x1:4, x2: 5, y1: 5, y2: 6, color: "#00A30031"},
        {x1:5, x2: 6, y1: 5, y2: 6, color: "#00A30031"},
      ]
  return (
    <div>
        <h6>Assessment Model:</h6>
        <ReusableTable data={tempdata} columns={columns} />

        <h6>Scatter graph:</h6>
        <ResponsiveContainer width="100%" height={400}>
    <ScatterChart>
      <CartesianGrid />
      <XAxis
        type="number"
        dataKey="x"
        name="X"
        domain={[1, 6]}
        interval={0}
        label={{
          value: "Soft Skills",
          offset: -5,
          fill: "#3C3C3C",
          position: "insideBottom"
        }}
      />
      <YAxis type="number" dataKey="y" name="Y" domain={[1, 6]} label={{
                  value: "Technical",
                  angle: -90,
                  position: "insideLeft",
                  fill: "#3C3C3C",
                }}/>
      <Tooltip />
      <Legend />
      {ReferenceData?.map((item)=>(
        <ReferenceArea x1={item.x1} x2={item?.x2} y1={item?.y1} y2={item?.y2} fill={item?.color} />
      ))}
      
      <Scatter data={data} fill="#fff" />
    </ScatterChart>
  </ResponsiveContainer>
    </div>
  )
}

export default FinalAppraisal