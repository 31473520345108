const ClientContactDetailsJSON = (data) => {
    const BasicDetail = {
        totalpages: 1,
        pages: [
            {
                modalView: false,
                colSpan: [12, 12, 12],
                fields: [
                    [
                        {
                            id: 0,
                            type: "label",
                            label: "Contact Information:"
                        }
                    ],
                    // Row 1
                    [
                        {
                            id: 1,
                            label: "Salutation:",
                            type: "text",
                            name: "",
                            Placeholder: "Enter",
                            maxLength: 25,
                            // value: data["clientId"],
                            viewOnly: true,
                            validationRules: [
                                {
                                    required: false,
                                },
                            ],
                        },
                        {
                            id: 2,
                            label: "Email:*",
                            type: "text",
                            name: "emailaddress1",
                            Placeholder: "Select",
                            // value: data["managingOffice"],
                            disabled: false,
                            viewOnly: true,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Email is required",
                                },
                            ],
                        },
                    ],
                    // Row 3
                    [
                        {
                            id: 3,
                            label: "First Name:",
                            type: "text",
                            name: "first name",
                            Placeholder: "Enter",
                            viewOnly: true,
                            // value: data["influentialstarcustomer"],
                            disabled: false,
                            validationRules: [
                                {
                                    required: true,
                                    message: "First Name is required",
                                },
                            ],
                        },
                        {
                            id: 4,
                            label: "Office Email:",
                            type: "text",
                            name: "officeemail",
                            Placeholder: "Enter",
                            viewOnly: true,
                            // value: data["registeredname"],
                            disabled: false,
                            validationRules: [
                                {
                                    required: false,
                                    message: "Office Email is required",
                                },
                            ],
                        },
                    ],

                    [
                        {
                            id: 5,
                            label: "Last Name:",
                            type: "text",
                            name: "lastname",
                            Placeholder: "Enter",
                            viewOnly: true,
                            disabled: false,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Last Name is required",
                                },
                            ],
                        },
                        {
                            id: 6,
                            label: "Role in Organization:",
                            type: "text",
                            name: "roleinorganization",
                            Placeholder: "Enter",
                            disabled: false,
                            viewOnly: true,
                            
                        },
                    ],
                    [
                        {
                            id: 7,
                            label: "Influential /Star Contact",
                            type: "switch",
                            name: "bdo_influentialstarcustomer",
                            Placeholder: "Enter",
                            viewOnly: true,
                            disabled: false
                        },
                        {
                            id: 8,
                            label: "Designation:",
                            type: "text",
                            name: "designation",
                            Placeholder: "Enter",
                            disabled: false,
                            viewOnly: true,
                        },
                    ],
                     [
                        {
                            id: 9,
                            label: "Role:",
                            type: "text",
                            viewOnly: true,
                            name: "role",
                            Placeholder: "Enter",
                            disabled: false,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Role is required",
                                },
                            ],
                        },
                        {
                            id: 10,
                            label: "Designation Classification:",
                            type: "text",
                            name: "designationclassification",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 11,
                            label: "Address:",
                            type: "textarea",
                            name: "bdo_registeredaddress",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false
                        },
                    ],
                    [
                        {
                            id: 13,
                            label: "Address/Contact Details:",
                            type: "label",
                        },
                    ],
                    [
                        {
                            id: 14,
                            label: "Mobile Phone:*",
                            type: "text",
                            name: "phone",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false,
                            validationRules: [
                                {
                                    required: true,
                                    message: "Mobile Phone is required",
                                },
                            ],
                        },
                        {
                            id: 15,
                            label: "Address 1: Street 1",
                            type: "text",
                            viewOnly: true,
                            name: "address1",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 16,
                            label: "Business Phone:",
                            type: "text",
                            name: "businessphone",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false,
                        },
                        {
                            id: 17,
                            label: "Address 1: Country/Region",
                            type: "text",
                            viewOnly: true,
                            name: "address1country",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 18,
                            label: "Address 1: City",
                            type: "text",
                            name: "address1city",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false,
                        },
                        {
                            id: 19,
                            label: "Address 1: ZIP/Postal Code",
                            type: "text",
                            viewOnly: true,
                            name: "address1postalcode",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 20,
                            label: "Address 1: Post Office Box",
                            type: "text",
                            name: "address1officebox",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false,
                        },
                        {
                            id: 21,
                            label: "Address 1: Street 1",
                            type: "text",
                            viewOnly: true,
                            name: "address1street",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 22,
                            label: "Preferred Method of Contact:",
                            type: "text",
                            name: "preferredmethodofcontact",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false,
                        },
                        {
                            id: 23,
                            label: "Address 1: ZIP/Postal Code",
                            type: "text",
                            viewOnly: true,
                            name: "address1postalcode",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 24,
                            label: "Preferred Method of Contact:",
                            type: "text",
                            name: "preferredmethodofcontact",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false,
                        },
                        {
                            id: 25,
                            label: "Address 1: Country/Region",
                            type: "text",
                            viewOnly: true,
                            name: "address1countryregion",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 26,
                            label: "Description:",
                            type: "textarea",
                            name: "description",
                            viewOnly: true,
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 27,
                            label: "Marketing:",
                            type: "label",
                        },
                    ],
                    [
                        {
                            id: 28,
                            label: "Originating Lead:",
                            type: "text",
                            viewOnly: true,
                            name: "_originatingleadid_value",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                        {
                            id: 29,
                            label: "Last Campaign Date:",
                            type: "text",
                            viewOnly: true,
                            name: "lastcampigndate",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 30,
                            label: "Marketing Materials:",
                            type: "text",
                            viewOnly: true,
                            name: "marketingmaterials",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                        {
                            id: 31,
                            label: "Greetings",
                            type: "text",
                            viewOnly: true,
                            name: "greetings",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 32,
                            label: "Contact Preferences:",
                            type: "label",
                        },
                    ],
                    [
                        {
                            id: 33,
                            label: "Contact Method:",
                            type: "text",
                            viewOnly: true,
                            name: "contactmethods",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                        {
                            id: 34,
                            label: "Email:",
                            type: "text",
                            viewOnly: true,
                            name: "emailaddress1",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 35,
                            label: "Follow Email:",
                            type: "text",
                            viewOnly: true,
                            name: "followemail",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                        {
                            id: 36,
                            label: "Bulk Email:",
                            type: "text",
                            viewOnly: true,
                            name: "bulkemail",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],
                    [
                        {
                            id: 37,
                            label: "Phone:",
                            type: "text",
                            viewOnly: true,
                            name: "phone",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                        {
                            id: 38,
                            label: "Mail:",
                            type: "text",
                            viewOnly: true,
                            name: "mail",
                            Placeholder: "Enter",
                            disabled: false,
                        },
                    ],

                ],
            },
        ],
    };
    return {
        BasicDetail
    };
};

export default ClientContactDetailsJSON;