import React, { useEffect, useState } from "react";
import { Form, Select, Input, DatePicker, Checkbox, Radio, Spin, Upload, Button, message, Switch } from "antd";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import EditorConvertToHTML from "atoms/EditorConvertToHTML";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import { countryList } from "common/Constants";
import COLORS from "common/Colors";
import PhoneInput from 'react-phone-number-input';
import Dragger from "antd/lib/upload/Dragger";
import Icon from "atoms/Icon";
import { height } from "@mui/system";

const { RangePicker } = DatePicker;

const textEnd = {
  textAlign: 'end',
};

function UIElement({
  label,
  type,
  options,
  name,
  position,
  background,
  textColor,
  disabled,
  row,
  Placeholder,
  maxLength,
  handleChange,
  value,
  content,
  readOnly,
  loading,
  event,
  handleClick,
  handleBack,
  validationRules,
  handleForgotPassword,
  onKeyDown,
  suffixUrl,
  domainStatus,
  urlLoader,
  link,
  form,
  handleSelectChange,
  picker,
  buttons,
  uploadFile,
  handleNxt,
  viewOnly = false,
}) {
  const labelCol = { span: 24 }; // Label takes full width
  const wrapperCol = { span: 24 }; // Input takes full width

  const countryOptions = countryList.map(country => ({ label: country, value: country }));
  const [remainingFiles, setRemainingFiles] = useState(5 - form.getFieldValue(name)?.length);


  const generateUniqueId = () => {
    // Generate a random number and convert it to base 36 (numbers + letters)
    const randomNumber = Math.random().toString(36).substr(2, 9);
  
    // Get the current timestamp
    const timestamp = Date.now();
  
    // Combine the random number with the timestamp
    const uniqueId = randomNumber + timestamp;
  
    return uniqueId;
  }


  const btnStyle = {
    background: background,
    minWidth: "100px",
    '@media only screen and (max-width: 376px)': {
      minWidth: "120px", 
    }
  };
  
  const divStyle = {
    background: background,
    color: textColor,
  };
  const pickerstyle = { width: "100%" }
  const labelsize = {fontSize : "14px"}

  const stepBtnStyle = { background: '#E3E8FC', color: `${COLORS.TEXT.PRIMARY}` }
  return (
    <div>
      {type === "label" && <h6>{label}</h6>}
      {readOnly && (
        <Form.Item label={label}>
          {value ? <strong>{value}</strong> : <Spin />}
        </Form.Item>
      )}
      {type === "select" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Select
            placeholder={Placeholder}
            options={options?.map((item) => ({
              value: item,
              label: item,
            }))}
            showSearch={true}
            value={value}
            onChange={(e) => handleSelectChange(e, name)}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          />
        </Form.Item>
      )}
      {type === "selectwithvalue" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Select
            placeholder={Placeholder}
            options={options.map((item) => ({
              value: item.value,
              label: item.label,
            }))}
            showSearch={true}
            value={value}
            onChange={(e) => handleSelectChange(e, name)}
          />
        </Form.Item>
      )}
      {(type === "text" ||
        type === "email" ||
        type === "number") &&
        !readOnly && (
          <Form.Item
            label={label}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            name={name}
            rules={validationRules}
          >
            <Input
              disabled={disabled}
              placeholder={Placeholder}
              type={type}
              name={name}
              maxLength={maxLength}
              onChange={handleChange}
              readOnly={viewOnly}
              value={value}
              onKeyDown={(e) => {
                const value = onKeyDown?.test(e.key);
                if (!value && e.key !== "Backspace" && onKeyDown) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        )}



{type === "phone"  &&
        !readOnly && (
          <Form.Item
            label={label}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            name={name}
            rules={validationRules}
          >
            <PhoneInput
              disabled={disabled}
              placeholder={Placeholder}
              type={type}
              name={name}
              maxLength={maxLength}
              onChange={(e)=>handleSelectChange(e,name)}
              value={value}
              onKeyDown={(e) => {
                const value = onKeyDown?.test(e.key);
                if (!value && e.key !== "Backspace" && onKeyDown) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        )}

      {type === "file" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Upload
            name={name}
            showUploadList={true}
            beforeUpload={(file) => {
              // Return false to prevent multiple file uploads
              return !form.getFieldValue(name);
            }}
            customRequest={({ file, onSuccess }) => {
              // Read the file and convert it to base64
              const reader = new FileReader();
              reader.readAsDataURL(file);

              reader.onload = () => {
                // Get the base64 data
                const base64Data = reader.result.split(',')[1];

                // Call onSuccess to indicate successful upload
                onSuccess();

                // Pass the base64 data to the onChange function
                handleSelectChange({ url: base64Data, fileType: file.type }, name);
              };

              reader.onerror = (error) => {
                console.error("File read error:", error);
                // Handle error if needed
              };
            }}
            onChange={(info) => {
              if (info.file.status === 'done') {
                form.setFieldsValue({ [name]: info.file });
                // Handle the file upload completion
                // message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
              }
            }}
            disabled={form.getFieldValue(name)}
          >
            <div style={{ ...divStyle, cursor: "pointer" }}>

              <ButtonOutlined >
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <PlusOutlined />
                )}
                {""}
                Upload
              </ButtonOutlined>

            </div>
          </Upload>
        </Form.Item>
      )}

{type === "dragger" && !readOnly && (
        <Form.Item
        label={label}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        name={name}
        rules={validationRules}
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Dragger
// value={value}
accept=".pdf"
customRequest={({ file, onSuccess, onError }) => {
  if (file.type !== 'application/pdf') {
    onError(new Error('Only PDF files are allowed!'));
    return;
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => {
    const base64Data = reader.result.split(",")[1];
    // Pass both the file and base64Data to onSuccess
    const uniqueId = generateUniqueId();
    onSuccess({ file, base64Data, uniqueId });
  };

 
  reader.onerror = (error) => {
    message.error("only allow pdf file")
    console.error("File read error:", error);
  };
}}
name="logo"
listType="picture"
style={{ width: "100%" }}
onChange={(value) => {
  uploadFile(value);
    handleSelectChange(value.fileList, name);
    setRemainingFiles(5-form.getFieldValue(name)?.length);
}}
maxCount= {5}
disabled={form.getFieldValue(name)?.length > 5}
>
<p className="ant-upload-drag-icon">
  <Icon name="fileupload" style={{ width: "50px", height: "50px" }} />
</p>
<p className="ant-upload-hint">Drag and drop file here</p>
<p className="ant-upload-hint">{`Upload File (Remaining: ${remainingFiles}, size >100MB)`}</p>
</Dragger>

      </Form.Item>
      )}

      {type === "textarea" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Input.TextArea
            disabled={disabled}
            placeholder={Placeholder}
            rows={row ? row : 3}
            name={name}
            onChange={handleChange}
            value={value}
            onKeyDown={(e) => {
              const value = onKeyDown?.test(e.key);
              if (!value && e.key !== "Backspace" && onKeyDown) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )}
      {type === "password" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Input.Password
            disabled={disabled}
            placeholder={Placeholder}
            name={name}
            onChange={handleChange}
            value={value}
            onKeyDown={(e) => {
              const value = onKeyDown?.test(e.key);
              if (!value && e.key !== "Backspace" && onKeyDown) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )}
      {type === "url" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
          validateStatus={domainStatus}
        >
          <Input
            placeholder={Placeholder}
            name={name}
            addonBefore={
              urlLoader ? (
                <LoadingOutlined spin />
              ) : domainStatus === "error" ? (
                <ErrorIcon />
              ) : domainStatus === "success" ? (
                <CheckCircleIcon />
              ) : (
                <ErrorOutlineIcon />
              )
            }
            addonAfter={suffixUrl}
            onChange={handleChange}
            status={domainStatus}
            onKeyDown={(e) => {
              const value = onKeyDown?.test(e.key);
              if (!value && e.key !== "Backspace" && onKeyDown) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )}
      {type === "country" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
          validateStatus={domainStatus}
        >
          <Input
            placeholder={Placeholder}
            name={name}
            addonBefore={
              <Select
                defaultValue="Australia"
                options={countryOptions}
              //note : need this 
              // onChange={handleCountryChange}
              />
            }
            addonAfter={suffixUrl}
            onChange={handleChange}
            status={domainStatus}
            onKeyDown={(e) => {
              const value = onKeyDown?.test(e.key);
              if (!value && e.key !== "Backspace" && onKeyDown) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      )}
      {type === "date" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <DatePicker
            style={pickerstyle}
            format="DD-MM-YYYY"
            disabled={disabled}
            placeholder={Placeholder}
            picker={picker}
            onChange={(date, dateString) =>
              handleChange({ target: { value: date, name: name } })
            }
            value={value ? moment(value, "YYYY-MM-DD") : null}
          />
        </Form.Item>
      )}
      {type === "daterange" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <RangePicker
            style={pickerstyle}
            disabled={disabled}
            placeholder={Placeholder}
            onChange={(dates, dateStrings) =>
              handleChange({ target: { value: dateStrings, name: name } })
            }
            value={
              value
                ? [
                  moment(value[0], "YYYY-MM-DD"),
                  moment(value[1], "YYYY-MM-DD"),
                ]
                : null
            }
            format="DD-MM-YYYY"
          />
        </Form.Item>
      )}
      {type === "checkbox" && !readOnly && (
        <Form.Item
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          valuePropName="checked"
          rules={validationRules}
        >
          <Checkbox disabled={disabled} onChange={handleChange} >
            {link ? (
              <Link to={link} target="_blank" rel="noopener noreferrer">
                {label}
              </Link>
            ) : (
              label
            )}
          </Checkbox>
        </Form.Item>
      )}
      {type === "radio" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <Radio.Group onChange={handleChange} value={value} name={name}>
            {options?.map((option, index) => (
              <Radio key={index} value={option}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      )}
       {type === "switch" && !readOnly && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
          disabled={disabled}
        >
              <Switch name={name} checked={value} >
              </Switch> <small>{value ? "Yes": "No"}</small>
        </Form.Item>
      )}

      {type === "button" && !readOnly && event === "submit" && (
        <div className={position}>
          <ButtonFilled
            htmlType="submit"
            disabled={disabled || loading || domainStatus === "error"}
            style={btnStyle}
          >
            {loading ? (
              <Spinner animation="border" size="sm" /> // Display a spinner when loading
            ) : (
              label
            )}
          </ButtonFilled>
        </div>
      )}

      {type === "button" && !readOnly && event === "click" && (
        <div className={position}>
          <ButtonOutlined
            disabled={disabled}
            onClick={() => handleBack()}
            type="secondary"
            style={btnStyle}
          >
            {label}
          </ButtonOutlined>
        </div>
      )}

{
        type === "buttonNxt" && !readOnly && event === "submit" && (
          <div className={position}>
            <ButtonFilled
              htmlType="submit"
              disabled={disabled || loading || domainStatus === "error"}
              style={btnStyle}
              onClick={handleNxt}
            >
              {loading ? (
                <Spinner animation="border" size="sm" /> // Display a spinner when loading
              ) : (
                label
              )}
            </ButtonFilled>
          </div>
        )
      }

<div style={textEnd}>
        {type === "combinebtnback" && !readOnly && event === "submit" && (
          <ButtonFilled
            htmlType="submit"
            disabled={disabled || loading || domainStatus === "error"}
            style={{ display: "inline-block" }}
            onClick={handleBack}
          >
            {loading ? (
              <Spinner animation="border" size="sm" /> // Display a spinner when loading
            ) : (
              label
            )}
          </ButtonFilled>

        )}

        {type === "combinebtnNxt" && !readOnly && event === "submit" && (

          <ButtonFilled
            htmlType="submit"
            disabled={disabled || loading || domainStatus === "error"}
            style={{ display: "inline-block" }}
            onClick={handleNxt}
          >
            {loading ? (
              <Spinner animation="border" size="sm" /> // Display a spinner when loading
            ) : (
              label
            )}
          </ButtonFilled>
        )}

      </div>

{type === "buttongroup" && !readOnly && (
  <div className={position}>
   <Form.Item>
   <Button.Group>
     {buttons.map((button, index) => (
      <>
      {button?.event === "submit" ? (
        <ButtonFilled
        key={index}
        htmlType={button?.event}
        disabled={button.disabled}
        style={button.btnStyle}
        onClick={button.onClick} // Add onClick handler if needed
      >
        {button.loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          button.label
        )}
      </ButtonFilled> ) : (
        <ButtonFilled
        key={index}
        disabled={button.disabled}
        style={button.btnStyle}
        onClick={button.onClick} // Add onClick handler if needed
      >
        {button.loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          button.label
        )}
      </ButtonFilled>
      )
      }
      </>
   
     ))}
   </Button.Group>
 </Form.Item>
 </div>
      )}

      {type === "button" && !readOnly && event === "back" && (
        <div className={position}>
          <ButtonOutlined
            disabled={disabled}
            onClick={() => handleClick()}
            type="secondary"
            style={btnStyle}
          >
            {label}
          </ButtonOutlined>
        </div>
      )}

      {type === "div" && !readOnly && (
        <div style={divStyle} className={position}>
          <a onClick={event === "click" ? () => handleForgotPassword() : ""}>
            {content}
          </a>
        </div>
      )}

      {type === "component" && !readOnly && (
        <div className={position}>
          {content}
        </div>
      )}

      {(type === "" || type === undefined) && (
        <Form.Item label={label}></Form.Item>
      )}

      {type === "editor" && (
        <Form.Item
          label={label}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          name={name}
          rules={validationRules}
        >
          <EditorConvertToHTML
            onChange={(val) => {
              form.setFieldsValue({ [name]: val });
            }}
            content={value}
          />
        </Form.Item>
      )}

      {type === 'addlist' && (
        <Form.List name={name} label={label}>
          {(fields, { add, remove }) => (
            <>
              <p style={labelsize}>{label}</p>
              {fields.map(({ key, name, ...restField }, index) => (
                <div className="d-flex gap-2 ">
                  <Form.Item
                    {...restField}
                    className="w-100"
                    name={[name, `${index}`]}
                    rules={validationRules}
                  >
                    <Input disabled={index === 0} suffix={index === 0 ? "Default" : ""} placeholder="Add Process" />
                  </Form.Item>

                  {index !== 0 && <MinusCircleOutlined className="mb-4" onClick={() => remove(name)} />}
                </div>
              ))}
              <Form.Item >
                <ButtonFilled className="w-100 mx-1" style={stepBtnStyle} onClick={() => add()} >
                  <PlusOutlined /> Add steps
                </ButtonFilled>
              </Form.Item>
              <div className="d-flex gap-2">
                <Form.Item className="w-100">
                  <Input 
                    disabled={true} 
                    defaultValue="Qualified" 
                    placeholder="Add Process" 
                    suffix="Default"
                  />
                </Form.Item>
              </div>
              <div className="d-flex gap-2">
                <Form.Item className="w-100">
                  <Input 
                    disabled={true} 
                    defaultValue="Disqualified" 
                    placeholder="Add Process" 
                    suffix="Default"
                  />
                </Form.Item>
              </div>
            </>
          )}
        </Form.List>
      )}
    </div>
  );
}

export default UIElement;
