import React, { Suspense, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useHistory, Redirect } from "react-router-dom";
import Routes from "Routes";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import { Layout, Spin, notification } from "antd";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import styled from "styled-components";
import NotificationSound from "../assets/notification-sound.mp3";
// Redux
import { connect, useSelector } from "react-redux";
import {
  getToggleDrawer,
  getLoadingDataFirstTime,
  getChatMessageQueue,
  getClientid,
  getMenuList,
  getUserRoleId,
  getUserInfo,
  getLoadingRoute,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import {
  setToggleDrawer,
  setWebSocketConnectionStatus,
  setChatMessageQueue,
  setUnreadMessageIndicator,
} from "redux/actions";
import SuccessModal from "molecules/SuccessModal";
import COLORS from "common/Colors";
import { DEVICE } from "common/Device";
import { SOCKET_URL } from "common/Constants";
import EventBus from "utils/EventBus";
import { createContext } from "react";

export const ContainerContext = createContext();

const { Content } = Layout;

const StyledContent = styled(Content)`
  background-color: #ffffff;
  padding: 24px;
  overflow-y: auto;
  ${({ isloading }) => isloading === "true" && `text-align: center;`}
  .initial-loader {
    margin-top: 100px;
    color: ${COLORS.GREY};
  }
  ${({ nopadding }) =>
    nopadding === "true" && `padding: 0px !important; border: none`}
  @media ${DEVICE.mobileS} {
    .ant-picker-calendar-full .ant-picker-content {
      width: 100%;
    }
  }
  @media ${DEVICE.laptopM} {
    .ant-table {
      width: 100%;
    }
    .ant-picker-calendar-full .ant-picker-content {
      width: 100%;
    }
  }
  @media ${DEVICE.laptopL} {
    .ant-picker-calendar-full .ant-picker-content {
      width: 100%;
    }
  }
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 24px;
`;

const StyledLoader = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const antIcon = <StyledLoadingOutlined spin />;

const Container = ({
  isLoadingDataFirstTime,
  saveWebSocketConnectionStatus,
  chatMessageQueue,
  saveChatMessageQueue,
  UserRoleId,
  menuList,
  menuLists,
  appBarWidth,
}) => {
  let history = useHistory();
  const match = useRouteMatch();
  const socketUrl = `${SOCKET_URL}`;
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
  });

  const Menulist = menuLists
    ? menuLists[UserRoleId] || Object.values(menuLists)[0] || menuLists
    : menuList[UserRoleId] || Object.values(menuList)[0] || menuList;

  const filtermenulist =
    Menulist &&
    Menulist.filter(
      (data) =>
        data.key !== "managerdashboard" &&
        data.key !== "hrdashboard" &&
        data.key !== "teamcalendar" &&
        data.key !== "allcalendar"
    );
  let defaultPath = "/";
  for (const menuItem of filtermenulist) {
    if (
      menuItem.access === true &&
      menuItem.path !== "managementview" &&
      menuItem.path !== "reportpreference"
    ) {
      if (menuItem.subMenu && menuItem.subMenu.length > 0) {
        defaultPath = menuItem.subMenu[0].path;
      } else {
        defaultPath = menuItem.path;
      }
      break;
    }
  }

  // useEffect(()=>{
  //   setPath(defaultPath);
  // },[UserRoleId, filtermenulist])

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      if (data.action === "NOTIFICATION") {
        const chatMessageQueueTmp = Array.from(chatMessageQueue);
        chatMessageQueueTmp.push({
          sender: data.senderName,
          message: data.message,
          senderId: data.senderId,
          action: data.action,
        });
        saveChatMessageQueue(chatMessageQueueTmp);
        var audio = new Audio(NotificationSound);
        audio.play();
        notification.open({
          message: data.senderName,
          description: data.message,
          onClick: () => {
            history.push(`/chat/${data.senderId}`);
            notification.destroy();
          },
        });
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    saveWebSocketConnectionStatus(readyState);
    EventBus.$dispatch("connect", readyState);
  }, [readyState]);

  useEffect(() => {
    getUnreadMessageSenders();
    EventBus.$on("sendMessage", (payload) => {
      sendMessage(
        JSON.stringify({
          action: "sendMessage",
          data: {
            ...payload,
            token: window.localStorage.getItem("token"),
          },
        })
      );
    });
    const timer = setInterval(() => getUnreadMessageSenders(), 10000);
    return () => clearInterval(timer);
  }, []);

  // Note: need this for future
  const getUnreadMessageSenders = async () => {
    // const { unread } = await CALL_API(`chat-message-list/${window.localStorage.getItem("clientId")}`, 'get', {});
    // saveUnreadMessageIndicator(map(unread, 'senderId'));
  };

  const sidebarStyle = {
    // marginRight: appBarWidth,
    // transition: "margin-right 0.3s ease-in-out",
  };

  if (window.innerWidth < 567) {
    sidebarStyle.marginRight = "0";
  }

  const layoutContentRef = React.createRef();

  return (
    <ContainerContext.Provider value={{ layoutContentRef }}>
      <StyledContent
        ref={layoutContentRef}
        isloading={isLoadingDataFirstTime.toString()}
        nopadding={`${
          window.location.pathname.indexOf("/chat/") > -1 ? "true" : "false"
        }`}
        style={sidebarStyle}
      >
        {isLoadingDataFirstTime && (
          <SyncOutlined
            style={{ fontSize: 100 }}
            spin
            className="initial-loader"
          />
        )}
        {!isLoadingDataFirstTime && (
          <Suspense fallback={<Spin indicator={antIcon} />}>
            <LoadingRoute>
              <Switch>
                {Routes.map(({ exact, path, content }, key) => (
                  <Route
                    key={key}
                    exact={exact}
                    path={`${match.path}${path}`}
                    children={content}
                  />
                ))}
                <Route path="/" render={() => <Redirect to={defaultPath} />} />
              </Switch>
            </LoadingRoute>
            <SuccessModal />
          </Suspense>
        )}
      </StyledContent>
    </ContainerContext.Provider>
  );
};

const LoadingRoute = ({ children }) => {
  const location = useLocation();
  const loadingRoute = useSelector(getLoadingRoute);
  if (
    location.pathname === "/" ||
    location.pathname === "/dashboard" ||
    location.pathname === "/authsso/dashboard"
  ) {
    return children;
  } else {
    return loadingRoute ? (
      <StyledLoader>
        {" "}
        <div>
          <Spin size="large" />
        </div>{" "}
      </StyledLoader>
    ) : (
      children
    );
  }
};

const mapStateToProps = (state) => ({
  toggleDrawer: getToggleDrawer(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
  chatMessageQueue: getChatMessageQueue(state),
  clientId: getClientid(state),
  menuList: getMenuList(state),
  UserRoleId: getUserRoleId(state),
  userInfo: getUserInfo(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeToggleDrawer: setToggleDrawer,
      saveWebSocketConnectionStatus: setWebSocketConnectionStatus,
      saveChatMessageQueue: setChatMessageQueue,
      saveUnreadMessageIndicator: setUnreadMessageIndicator,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
