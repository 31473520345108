import { sumBy } from "lodash";
import { initialClaim, InitialTimeSheetDataFn } from "./InitialTimeSheetData";
import { MAX_WORKING_HOURS, MIN_WORKING_HOURS, PENDING } from ".";
import { notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { setCacheTimesheet } from "redux/actions";
import { useDispatch } from "react-redux";

const useTimesheetfns = ({
  personIncharge,
  OfficeSuperVisorUserId,
  currentWeek,
  form,
  setPersonInCharge,
  week,
  timesheetDataRes,
}) => {
  const dispatch = useDispatch();

  const initialTimeSheet = InitialTimeSheetDataFn(currentWeek);

  const [timeSheet, setTimeSheet] = useState(initialTimeSheet);

  useEffect(() => {
    if (timesheetDataRes?.response?.response?.timeSheet) {
      setTimeSheet(timesheetDataRes?.response?.response?.timeSheet);
      form.setFieldsValue(timesheetDataRes?.response?.response?.timeSheet);
    } else {
      setTimeSheet(initialTimeSheet);
      form.setFieldsValue(initialTimeSheet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheetDataRes, form]);

  const generateTimeSheetData = (values) => {
    return Object.values(values).map((value, index) => {
      const personInCharge =
        personIncharge?.[index] || OfficeSuperVisorUserId || "";

      return {
        ...value,
        mon: parseInt(value?.mon) || 0,
        tue: parseInt(value?.tue) || 0,
        wed: parseInt(value?.wed) || 0,
        thu: parseInt(value?.thu) || 0,
        fri: parseInt(value?.fri) || 0,
        sat: parseInt(value?.sat) || 0,
        sun: parseInt(value?.sun) || 0,
        sum: parseInt(value?.sum) || 0,
        claims: value?.claims || [],
        personInCharge: personInCharge,
        currentWeek: currentWeek.format("YYYY-MM-DD"),
        ncClient: value?.ncClient || null,
        subEngagement: value?.subEngagement || null,
        remarks: value?.remarks || "",
        status: PENDING,
        approval: {},
        declined: {},
      };
    });
  };

  const isChargeAbleAmtValid = (timeSheet) => {
    const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    const dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const validateDayTotal = (total, dayName) => {
      if (total < MIN_WORKING_HOURS || total > MAX_WORKING_HOURS) {
        notification.error({
          message: `${dayName} total hours should be between 8 and 12`,
        });
        return false;
      }
      return true;
    };

    for (let i = 0; i < daysOfWeek.length; i++) {
      const dayTotal = sumBy(timeSheet, daysOfWeek[i]);
      if (daysOfWeek[i] === "sat" || daysOfWeek[i] === "sun") {
        if (dayTotal !== 0 && !validateDayTotal(dayTotal, dayNames[i])) {
          return false;
        }
      } else {
        if (!validateDayTotal(dayTotal, dayNames[i])) {
          return false;
        }
      }
    }

    return true;
  };

  const handleAddMore = () => {
    if (timeSheet.length >= 7) {
      notification.error({
        message: "You can't add more than 7 Slots",
      });
      return;
    }

    const values = form.getFieldsValue(true);
    const actualTimeSheet = generateTimeSheetData(values);
    let data = [...actualTimeSheet];
    data.push(...InitialTimeSheetDataFn(currentWeek));
    setTimeSheet(data);
    form.setFieldsValue(data);
  };

  const handleAddMoreClaim = (timeSheetIndex) => {
    const values = form.getFieldsValue(true);

    const claimsLength = values[timeSheetIndex]?.claims?.length || 0;

    if (claimsLength >= 5) {
      notification.error({
        message: "You can't add more than 5 claims",
      });
      return;
    }

    const actualTimeSheet = generateTimeSheetData(values);

    let data = [...actualTimeSheet];

    const timeSheet = data.map((value, index) => {
      if (index === timeSheetIndex) {
        value.claims.push(initialClaim);
      }
      return value;
    });

    setTimeSheet(timeSheet);
    form.setFieldsValue(timeSheet);
  };

  const handleClearClaim = (timeSheetIndex, claimIndex) => {
    const values = form.getFieldsValue(true);

    const actualTimeSheet = generateTimeSheetData(values);

    let data = [...actualTimeSheet];

    const timeSheet = data.map((value, index) => {
      if (index === timeSheetIndex) {
        return {
          ...value,
          claims: value.claims
            .map((claim, i) => {
              if (i === claimIndex) {
                return claimIndex === 0 ? initialClaim : null;
              }
              return claim;
            })
            .filter((claim) => claim !== null),
        };
      }
      return value;
    });

    setTimeSheet(timeSheet);
    form.setFieldsValue(timeSheet);
  };

  const handleResetClaim = (timeSheetIndex) => {
    const values = form.getFieldsValue(true);

    const actualTimeSheet = generateTimeSheetData(values);

    let data = [...actualTimeSheet];

    const timeSheet = data.map((value, index) => {
      if (index === timeSheetIndex) {
        return {
          ...value,
          claims: [initialClaim],
        };
      }
      return value;
    });

    setTimeSheet(timeSheet);
    form.setFieldsValue(timeSheet);
  };

  const handleRemoveClaim = (timeSheetIndex) => {
    const values = form.getFieldsValue(true);

    const actualTimeSheet = generateTimeSheetData(values);

    let data = [...actualTimeSheet];

    const timeSheet = data.map((value, index) => {
      if (index === timeSheetIndex) {
        return {
          ...value,
          claims: [],
        };
      }
      return value;
    });

    setTimeSheet(timeSheet);
    form.setFieldsValue(timeSheet);
  };

  const handlePersonInCharge = useCallback((index, value) => {
    setPersonInCharge((prev) => {
      const data = [...prev];
      data[index] = value;
      return data;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearTimesheet = (index) => {
    if (index === 0) {
      setTimeSheet(initialTimeSheet);
      form.setFieldsValue(initialTimeSheet);
      notification.info({
        message: "Timesheet Section Cleared",
      });
      return;
    }

    const values = form.getFieldsValue(true);

    const actualTimeSheet = generateTimeSheetData(values);

    let data = [...actualTimeSheet];

    if (index >= 0 && index < data.length) {
      data = data.filter((_, i) => i !== index);
      setTimeSheet(data);
      form.setFieldsValue(data);
      notification.info({
        message: "Timesheet Section Removed",
      });
    } else {
      console.error("Invalid index:", index);
    }
  };

  const handleSaveTimesheet = () => {
    const values = form.getFieldsValue(true);

    const timeSheetData = generateTimeSheetData(values);

    dispatch(setCacheTimesheet(week, timeSheetData));
    notification.success({
      message: "Timesheet Saved",
    });
  };

  return {
    timeSheet,
    setTimeSheet,
    initialTimeSheet,
    generateTimeSheetData,
    handleAddMore,
    handleAddMoreClaim,
    handleClearClaim,
    handleResetClaim,
    handleRemoveClaim,
    handlePersonInCharge,
    handleClearTimesheet,
    handleSaveTimesheet,
    isChargeAbleAmtValid,
  };
};

export default useTimesheetfns;
