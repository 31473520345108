import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Select, Button, Modal } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import {
  getLocations,
  getIsOnBreak,
  getIsCheckedIn,
  getCheckInLocationId,
  getPunchId,
  getBreakId,
  getShiftTimeMaster,
  getTime24,
  getDepartmentList,
  getAllDepartmentList,
  getUserInfo,
  getLongDay,
  getUserList,
  getClientid,
  getSelectedDepartmentId,
  getUserRoleId,
  getRoleList,
  getCheckInDepartmentId,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import {
  setIsOnBreak,
  toggleSuccessModal,
  setCheckedIn,
  setCheckInLocationId,
  toggleProcessingModal,
  setPunchId,
  setBreakId,
  setCheckInDepartmentId,
} from "redux/actions";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import CustomIcon from "atoms/Icon";
import BreakModal from "organisms/BreakModal";
import CheckInProblemModal from "organisms/CheckInProblemModal";
import { displayErrorModal } from "common/common";
import { PUNCH_ACTIONS, STATUS_CODE } from "common/Constants";
import { CALL_API } from "common/API";
import { concat, find, get, includes, size, result, orderBy } from "lodash";
import { DEVICE } from "common/Device";
import { dateInDetail, getMySqlDate, getKenyanDateTime } from "utils/Date";
import { Input } from "antd";

const StyleContainerRow = styled(Row)`
  max-height: 115px;
  min-height: 100px;
  box-shadow: 0px 4px 30px 0px #0000001f;
  border-radius: 8px;
  background: transparent
    linear-gradient(98deg, #f2f6ff -30%, ${COLORS.PRIMARY} 100%) 0% 0% no-repeat
    padding-box;
  padding: 7px 15px;
  margin-bottom: 16px;

  @media (max-width: 576px) {
    max-height: 200px;
    margin-left: 0px;
  }

  @media (max-width: 376px) {
    max-height: 220px;
  }
`;

const StyledCheckInCheckOutButton = styled(Button)`
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  width: 160px;
  :hover {
    color: #000 !important;
    border-color: #c2fae5 !important;
  }
  @media (max-width: 321px) {
    margin-bottom: 0px;
  }
  @media ${DEVICE.mobileS} {
    margin-bottom: 5px;
    margin-left: 0px;
  }
`;

const StyledStartBreak = styled(Button)`
  margin-right: 20px;
  font-size: 1rem;
  vertical-align: text-top;
  /* margin-top: 5px; */
  svg {
    margin-left: 8px;
    vertical-align: top;
  }
  @media (max-width: 376px) {
    font-size: 1rem;
    margin-left: -15px;
  }
`;
const StyledLocationSelect = styled(Select)`
  @media ${DEVICE.tablet} {
    width: initial;
  }
  @media (max-width: 575px) {
    width: 120%;
  }
  @media (max-width: 426px) {
    width: 100%;
  }
  @media (max-width: 416px) {
    width: 100%;
  }
  margin-right: 15px;
  width: 160px;
`;

function CheckInCheckout({
  locationList,
  isOnBreak,
  isCheckedIn,
  updateIsOnBreak,
  updateSuccessModal,
  updateCheckedIn,
  updateCheckInLocationId,
  checkInLocationId,
  updateProcessingModal,
  punchId,
  breakId,
  savePunchId,
  saveBreakId,
  shiftTimeMaster,
  userInfo,
  userList,
  ClientId,
  userRoleId,
  roleList,
  AllDepartmentList,
  checkInDepartmentId,
  updateCheckInDepartmentId,
}) {
  const [isBreakModalVisible, toggleDisplayBreakModal] = useState(false);
  const [isCheckInProblemModalVisible, toggleCheckInProblemModal] =
    useState(false);
  const [isCheckInReminderModalVisible, toggleCheckInReminderModal] =
    useState(false);
  const [locationId, setLocationId] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [showLateCheckInModal, setShowLateCheckInModal] = useState(false);
  const [punchInOutReason, setPunchInOutReason] = useState("");
  const [reasonSubmited, setReasonSubmited] = useState(false);
  const [reasonValidation, setReasonValidation] = useState(false);
  const containerRef = useRef(null);
  const { TextArea } = Input;
  const shiftStartTime = result(
    find(userList, { userid: userInfo?.userid }),
    "shiftstarttime"
  );
  const shiftEndTime = result(
    find(userList, { userid: userInfo?.userid }),
    "shiftendtime"
  );

  const filteruser = userList?.filter(
    (item) => item?.userid === get(userInfo, "userid", "")
  );

  const [locationError, setLocationError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);

  const filterRoleList = roleList?.filter(
    (item) => item?.roleId === userRoleId
  );

  const filterLocationId = filterRoleList[0]?.locationId;
  const filterLocation = locationList?.filter((item) =>
    filterLocationId?.includes(item?.location_id)
  );

  const filterDepartmentId = filterRoleList[0]?.department;
  const filterDepartment = AllDepartmentList?.filter((item) =>
    filterDepartmentId?.includes(item?.department_id)
  );

  useEffect(() => {
    setLocationId(checkInLocationId);
    setDepartmentId(checkInDepartmentId);
  }, [checkInLocationId, checkInDepartmentId]);

  useEffect(() => {}, [punchInOutReason, reasonSubmited]);

  const getPunchInOutReason = (action) => {
    if (punchInOutReason != "") {
      setReasonSubmited(true);
      setShowLateCheckInModal(false);
      setReasonValidation(false);
      punchInOutApiCal(action, "");
    } else {
      setReasonValidation(true);
    }
  };
  const handlePunchInOutReason = (e) => {
    setPunchInOutReason(e.target.value);
    if (e.target.value.length > 1) {
      setReasonValidation(false);
    }
  };
  const hideModal = () => {
    setShowLateCheckInModal(false);
    setPunchInOutReason("");
  };
  const startBreak = (breakTypeId) => {
    toggleDisplayBreakModal(false);
    punchInOutApiCal(PUNCH_ACTIONS.BREAK_START, breakTypeId);
  };

  const getCoordinates = async (action) => {
    return new Promise((resolve) => {
      try {
        if (navigator.userAgent === "application") {
          const latitude = window.localStorage.getItem("latitude");
          const longitude = window.localStorage.getItem("longitude");
          setTimeout(() => {
            if (latitude === null || longitude === null) {
              updateProcessingModal(false);
              displayErrorModal(
                `Please allow Geo Location to read your browser location for ${
                  action === PUNCH_ACTIONS.PUNCH_IN ? "in" : "out"
                }`
              );
              resolve({
                latitude: "",
                longitude: "",
              });
              window.Print.postMessage("access-location");
            } else if (latitude && longitude) {
              resolve({
                latitude,
                longitude,
              });
            } else {
              updateProcessingModal(false);
              displayErrorModal(
                `Please allow Geo Location to read your browser location for ${
                  action === PUNCH_ACTIONS.PUNCH_IN ? "in" : "out"
                }`
              );
              resolve({
                latitude: "",
                longitude: "",
              });
              window.Print.postMessage("access-location");
            }
          }, 1000);
        } else {
          navigator.geolocation.getCurrentPosition(
            function (location) {
              resolve({
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
              });
            },
            function (error) {
              setTimeout(() => {
                if (error.code === error.PERMISSION_DENIED) {
                  updateProcessingModal(false);
                  displayErrorModal(
                    `Please allow Geo Location to read your browser location for Check ${
                      action === PUNCH_ACTIONS.PUNCH_IN ? "in" : "out"
                    }`
                  );
                  resolve({
                    latitude: "",
                    longitude: "",
                  });
                } else {
                  updateProcessingModal(false);
                  displayErrorModal("Unable to read browser location.");
                  resolve({
                    latitude: "",
                    longitude: "",
                  });
                }
              }, 1000);
            }
          );
        }
      } catch (err) {
        setTimeout(() => {
          updateProcessingModal(false);
          displayErrorModal("Unable to read browser location.");
          resolve({
            latitude: "",
            longitude: "",
          });
        }, 1000);
      }
    });
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, department_id } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateCheckInDepartmentId(department_id?.toString());
    }
  };

  const punchInOutApiCal = async (action, breakTypeId) => {
    if (!locationId) {
      setLocationError(true);
      return;
    }
    setLocationError(false);
    // if (!departmentId) {
    //   setDepartmentError(true);
    //   return;
    // }
    // setDepartmentError(false);

    const loginFromAnywhere = get(
      find(locationList, { location_id: locationId }),
      "login_from_anywhere",
      0
    );
    const isPunchInOut = includes(
      [PUNCH_ACTIONS.PUNCH_IN, PUNCH_ACTIONS.PUNCH_OUT],
      action
    );
    if (isPunchInOut && !loginFromAnywhere) {
      updateProcessingModal(true);
      var { latitude = "", longitude = "" } = await getCoordinates(action);
    } else {
      latitude = -1.313744;
      longitude = 36.8584;
      updateProcessingModal(true);
    }

    if (
      (isPunchInOut && latitude !== "" && longitude !== "") ||
      !isPunchInOut
    ) {
      const locationName = locationList.find(
        (loc) => loc.location_id === locationId
      );
      // const deptName = result(
      //   find(AllDepartmentList, (e) => {
      //     return departmentId?.includes(e.department_id);
      //   }),
      //   "department_name"
      // );
      const {
        code,
        maxProximity = "",
        punchActionInsertedId = 0,
        breakActionInsertedId = 0,
      } = await CALL_API(`punch-in-out/${ClientId}`, "post", {
        breaktypeid: breakTypeId,
        punchinid: punchId,
        punchid: action,
        departmentname: filterDepartment.length > 0 ? filterDepartment[0]?.department_name : "",
        departmentid: filterDepartment.length > 0 ? filterDepartment[0]?.department_id : "",
        fullname: userInfo?.name,
        userid: userInfo?.userid,
        breakid: breakId,
        loginlocationid: action === 1 ? parseInt(locationId) : "",
        loginlocationname: action === 1 ? locationName?.location_name : "",
        loginlatitude: action === 1 ? latitude : "",
        loginlongitude: action === 1 ? longitude : "",
        logoutlocationid: action === 2 ? parseInt(locationId) : "",
        logoutlocationname: action === 2 ? locationName?.location_name : "",
        logoutlatitude: action === 2 ? latitude : "",
        logoutlongitude: action === 2 ? longitude : "",
        skipdistancechecking: loginFromAnywhere ? true : false,
        reason: punchInOutReason,
        shiftstarttime: shiftStartTime,
        shiftendtime: shiftEndTime,
        employeeid: filteruser[0]?.employeeid,
        officesupervisorid: filteruser[0]?.OfficeSupervisorId,
        officesupervisorname: filteruser[0]?.officesupervisor,
      });

      updateProcessingModal(false);
      if (code === STATUS_CODE.PUNCH_IN_SUCCESSFUL) {
        setPunchInOutReason("");
        updateCheckedIn(true);
        updateCheckInLocationId(locationId);
        updateSuccessModal({
          visibility: true,
          message: `You have been checked in successfully`,
        });
        updateProcessingModal(false);
        savePunchId(punchActionInsertedId);
        fetchMasterData();
      } else if (code === STATUS_CODE.PUNCH_OUT_SUCCESSFUL) {
        setPunchInOutReason("");
        updateCheckedIn(false);
        updateCheckInLocationId("");
        updateSuccessModal({
          visibility: true,
          message: `You have been checked out successfully`,
        });
        updateProcessingModal(false);
        savePunchId("");
        fetchMasterData();
      } else if (code === STATUS_CODE.BREAK_STARTED) {
        updateIsOnBreak(true);
        updateSuccessModal({
          visibility: true,
          message: "Break has been started",
        });
        updateProcessingModal(false);
        saveBreakId(breakActionInsertedId);
      } else if (code === STATUS_CODE.REASON) {
        updateProcessingModal(false);
        setReasonValidation(false);
        setShowLateCheckInModal(true);
      } else if (code === STATUS_CODE.BREAK_ENDED) {
        updateIsOnBreak(false);
        updateSuccessModal({
          visibility: true,
          message: "Your break has been stopped",
        });
        updateProcessingModal(false);
        saveBreakId("");
      } else if (code === STATUS_CODE.RECORD_EXIST) {
        if (isPunchInOut) {
          displayErrorModal(
            `You are already logged ${
              action === PUNCH_ACTIONS.PUNCH_IN ? "in" : "out"
            }.`
          );
        } else {
          displayErrorModal(`You are already on break`);
        }
      } else if (code === STATUS_CODE.OUTSIDE_ALLOWED_PROXIMITY) {
        displayErrorModal(
          `You are far from allowed proximity (${maxProximity} meters). Please try to get closer.`
        );
      } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
        toggleCheckInProblemModal(true);
      } else toggleCheckInProblemModal(true);
      updateProcessingModal(false);
    }
  };

  const stopBreak = () => {
    Modal.confirm({
      icon: "",
      content: "Are you sure you want to stop the break?",
      okText: "YES",
      cancelText: "No",
      onOk: () => {
        punchInOutApiCal(PUNCH_ACTIONS.BREAK_END, breakId);
      },
    });
  };

  if (size(shiftTimeMaster)) {
    if (
      !punchId &&
      !isCheckInReminderModalVisible &&
      window.localStorage.getItem("login_reminder") !== "true"
    ) {
      const kenyaDateTime = getKenyanDateTime();
      const dayIndex = dateInDetail(kenyaDateTime).day_index + 1;
      const { start_time, end_time } = find(shiftTimeMaster, { day: dayIndex });
      if (start_time && end_time) {
        const dayStartTime = moment(
          `${getMySqlDate(kenyaDateTime)} ${start_time}: 00`
        );
        const dayEndTime = moment(
          `${getMySqlDate(kenyaDateTime)} ${end_time}: 00`
        );
        const actualCheckInDateTime = moment(kenyaDateTime);
        if (actualCheckInDateTime < dayEndTime) {
          const diff = actualCheckInDateTime.diff(dayStartTime, "minutes");
          if (diff) {
            toggleCheckInReminderModal(true);
          }
        }
      }
    }
  }

  return (
    <>
      <StyleContainerRow>
        <Row>
          <Col xs={24} xl={12} md={12} sm={12}>
            <Col span={24}>
              <div>Location*</div>
            </Col>
            <Col md={16} sm={24} xs={24} ref={containerRef}>
              <StyledLocationSelect
                className="mt-1"
                getPopupContainer={() => containerRef.current}
                placeholder="Select Location"
                options={concat(
                  {
                    location_id: "",
                    location_name: "Select Location",
                  },
                  orderBy(filterLocation, [(loc) => loc.location_id], ["asc"])
                )}
                fieldNames={{
                  label: "location_name",
                  value: "location_id",
                }}
                value={locationId}
                onChange={(val) => setLocationId(val)}
                status={locationError ? "error" : ""}
                help={locationError ? "Location is required" : ""}
              />
            </Col>
          </Col>
          {/* <Col xs={24} xl={12} md={12} sm={12}>
            <Col span={24}>
              <div>Department*</div>
            </Col>
            <Col md={16} sm={24} xs={24} ref={containerRef}>
              <StyledLocationSelect
                className="mt-1"
                getPopupContainer={() => containerRef.current}
                placeholder="Select Department"
                options={concat(
                  {
                    department_id: "",
                    department_name: "Select Department",
                  },
                  orderBy(
                    filterDepartment,
                    [(val) => val.department_id],
                    ["asc"]
                  )
                )}
                fieldNames={{
                  label: "department_name",
                  value: "department_id",
                }}
                value={departmentId}
                onChange={(val) => setDepartmentId(val)}
                status={departmentError ? "error" : ""}
                help={departmentError ? "Department is required" : ""}
              />
            </Col>
          </Col> */}
        </Row>

        <div className="d-flex justify-content-center gap-3 align-items-center">
          {!isCheckedIn && (
            <StyledCheckInCheckOutButton
              className="mt-1"
              onClick={() => punchInOutApiCal(PUNCH_ACTIONS.PUNCH_IN, "")}
            >
              Check-In
            </StyledCheckInCheckOutButton>
          )}
          {isCheckedIn && !isOnBreak && (
            <StyledCheckInCheckOutButton
              className="mt-1"
              onClick={() => punchInOutApiCal(PUNCH_ACTIONS.PUNCH_OUT, "")}
            >
              Check-Out
            </StyledCheckInCheckOutButton>
          )}
          {isCheckedIn && (
            <StyledStartBreak
              type="text"
              onClick={() =>
                isOnBreak ? stopBreak() : toggleDisplayBreakModal(true)
              }
            >
              {isOnBreak ? "Stop Break" : "Start Break"}
              <CustomIcon name={isOnBreak ? "stop_break" : "play"} />
            </StyledStartBreak>
          )}
        </div>
        <BreakModal
          isModalVisible={isBreakModalVisible}
          closeModal={() => toggleDisplayBreakModal(!isBreakModalVisible)}
          startBreak={(breakTypeId) => {
            startBreak(breakTypeId);
          }}
        />
        <CheckInProblemModal
          isModalVisible={isCheckInProblemModalVisible}
          closeModal={() =>
            toggleCheckInProblemModal(!isCheckInProblemModalVisible)
          }
          tryAgain={() => punchInOutApiCal(PUNCH_ACTIONS.PUNCH_IN, "")}
        />

        <Modal
          title=""
          open={showLateCheckInModal}
          footer={false}
          onCancel={hideModal}
          okText="Submit"
          cancelText="Cancel"
          maskClosable={false}
        >
          <div className="mt-3 mb-1">
            Reason For {!isCheckedIn ? "Late Check-in" : "Early Check-out"}
          </div>
          <TextArea
            rows={4}
            name="punchInOutReason"
            value={punchInOutReason}
            onChange={handlePunchInOutReason}
          />
          {reasonValidation && (
            <div className="text-danger">*Reason is required</div>
          )}
          <div className="mt-3 d-block">
            <div className="d-flex justify-content-end">
              <Button onClick={hideModal}>Cancel</Button>

              <Button
                type="primary"
                onClick={() =>
                  getPunchInOutReason(
                    !isCheckedIn
                      ? PUNCH_ACTIONS.PUNCH_IN
                      : PUNCH_ACTIONS.PUNCH_OUT
                  )
                }
                className="mx-2"
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      </StyleContainerRow>
    </>
  );
}

const mapStateToProps = (state) => ({
  departmentList: getDepartmentList(state),
  locationList: getLocations(state),
  isOnBreak: getIsOnBreak(state),
  isCheckedIn: getIsCheckedIn(state),
  checkInLocationId: getCheckInLocationId(state),
  punchId: getPunchId(state),
  breakId: getBreakId(state),
  shiftTimeMaster: getShiftTimeMaster(state),
  systemTime24: getTime24(state),
  detaildate: getLongDay(state),
  userInfo: getUserInfo(state),
  userList: getUserList(state),
  ClientId: getClientid(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  AllDepartmentList: getAllDepartmentList(state),
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
  checkInDepartmentId: getCheckInDepartmentId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateIsOnBreak: setIsOnBreak,
      updateCheckedIn: setCheckedIn,
      updateSuccessModal: toggleSuccessModal,
      updateCheckInLocationId: setCheckInLocationId,
      updateProcessingModal: toggleProcessingModal,
      savePunchId: setPunchId,
      saveBreakId: setBreakId,
      updateCheckInDepartmentId: setCheckInDepartmentId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CheckInCheckout);
