

export const getEditResourceDateJson = (data, editDates) => {
  const addEndDateForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "No of Days",
              type: "text",
              name: "endDay",
              Placeholder: "Enter No of Days",
              value: data["endDay"],
              maxLength: 1,
              maxValue: editDates,
              validationRules: [
                {
                  required: true,
                  message: `No of Days is required`,
                },
                {
                  validator: (_, value) => {
                    const x = editDates; // Assuming editDates contains the maximum value
                    if (value > x) {
                      return Promise.reject(`No of Days must be less than or equal to ${x}`);
                    }
                    return Promise.resolve();
                  },
                }
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Cancel",
              type: "button",
              position: "start",
            //   textColor: "white",
              event: "back",
            },
            {
              id: 2,
              label: "Done",
              type: "button",
              position: "end",
            //   textColor: "Black",
              event: "submit",
            },
          ],
        ],
      },
    ],
  };

  return {
    addEndDateForm,
  };
};
