import { Button, Form, message, Modal, Spin, Table } from "antd";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import { CALL_API_UPDATED } from "common/API";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ButtonFilled } from "reusableComponent/UIButtons";
import styled from "styled-components";
import AddAsset from "./AddAsset";
import AssignAsset from "./AssignAsset";
import { ReactComponent as DeleteIcon } from "./../../assets/delete_Icon.svg";
import EditAsset from "./EditAsset";
import { getMenuList, getUserRoleId } from "redux/selectors";
import { useSelector } from "react-redux";

const StyledWrap = styled.div`
  .ant-table-tbody > tr > td {
    font-weight: 400 !important;
  }

  .ant-table-cell::before {
    display: none !important;
  }

  .card {
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    border: none !important;
  }

  .asset-name {
    color: #3a7ff5 !important;
  }
`;
const CenteredModal = styled(Modal)`
  .ant-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Assests = () => {
  const [showLoader, toggleLoader] = useState(false);
  const [assetsData, setAssetsData] = useState([]);
  const [isModalVisible, closeModal] = useState(false);
  const [editData, setEditData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [showRevoke, setShowRevoke] = useState(false);
  const [showmessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deletedata, setDeletedata] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const menuList = useSelector(getMenuList);
  const userRoleId = useSelector(getUserRoleId);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  useEffect(() => {
    if (!edit) {
      setEditData([]);
    }
  }, [edit]);

  const [form] = Form.useForm();

  const CategoryList = ["Laptop", "Monitor", "Hard Disk"];
  const StatusList = ["Assigned", "Available"];

  const uniqueNames = Array.from(
    new Set(assetsData.map((item) => item.assetName))
  ).map((name) => ({
    label: name,
    value: name,
  }));

  const columns = [
    {
      title: "Name",
      dataIndex: "assetName",
      key: "assetName",
      render: (assetName, record) => (
        <>
          {Menulist?.find(
            (item) =>
              item?.key === "HRResources" &&
              item?.subMenu.find(
                (subItem) =>
                  subItem?.key === "assets" && subItem?.permission.edit
              )
          ) ? (
            <Button
              type="link"
              onClick={() => handleEdit(record)}
              className="asset-name"
            >
              {assetName || "-"}
            </Button>
          ) : (
            <> {assetName || "-"}</>
          )}
        </>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => category || "-",
    },
    {
      title: "Status",
      dataIndex: "assetStatus",
      key: "assetStatus",
      render: (assetStatus) => assetStatus || "-",
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
      render: (assignedTo) => assignedTo || "-",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          {Menulist?.find(
            (item) =>
              item?.key === "HRResources" &&
              item?.subMenu.find(
                (subItem) =>
                  subItem?.key === "assets" && subItem?.permission.edit
              )
          ) && (
            <>
              {record.assetStatus === "Available" ? (
                <ButtonFilled type="link" onClick={() => handleAssign(record)}>
                  Assign
                </ButtonFilled>
              ) : (
                <ButtonFilled
                  loading={editData.assetId === record.assetId && loading}
                  type="link"
                  onClick={() => {
                    updateAssetData(record);
                    setEditData(record);
                  }}
                >
                  Revoke
                </ButtonFilled>
              )}
            </>
          )}
          {Menulist?.find(
            (item) =>
              item?.key === "HRResources" &&
              item?.subMenu.find(
                (subItem) =>
                  subItem?.key === "assets" && subItem?.permission.delete
              )
          ) && (
            <ButtonFilled
              type="link"
              danger
              onClick={() => handleDelete(record)}
            >
              <DeleteIcon />
            </ButtonFilled>
          )}
        </div>
      ),
    },
  ];

  const getAssetsData = async () => {
    toggleLoader(true);
    const response = await CALL_API_UPDATED(`assets`, "get");
    if (response.status === "SUCCESS") {
      toggleLoader(false);
      setAssetsData(response.response.assetList);
    }
  };

  useEffect(() => {
    getAssetsData();
  }, []);

  const getFilteredAssetsData = () => {
    const name = form.getFieldValue("name");
    const category = form.getFieldValue("category");
    const status = form.getFieldValue("status");

    if (!name && !category && !status) {
      return assetsData;
    } else {
      return assetsData.filter((item) => {
        const nameMatch =
          name && name.length > 0
            ? name.some((n) => item.assetName === n)
            : true;

        const categoryMatch =
          category && category.length > 0
            ? category.includes(item.category)
            : true;

        const statusMatch =
          status && status.length > 0
            ? status.includes(item.assetStatus)
            : true;

        return nameMatch && categoryMatch && statusMatch;
      });
    }
  };

  const deleteAssert = async () => {
    let name = editData.assetName;
    setDeleteLoading(true);
    const response = await CALL_API_UPDATED(
      `assets/${editData.assetId}?uniqueId=${editData.image?.uniqueId}`,
      "delete",
      {
        assetName: editData.assetName,
        category: editData.category,
        description: editData.description,
        image: editData.image,
        assetStatus: "Available",
        assignedTo: "",
        assignedDate: "",
      }
    );

    if (response.status === "SUCCESS") {
      getAssetsData();
      setDeleteLoading(false);
      message.success(`${name} has been deleted from the system!`);
      setShowMessage(false);
      setDeletedata(false);
      setEditData([]);
    } else {
      message.error("Failed to Delete Asset");
    }
  };

  const updateAssetData = async (editData) => {
    let name = editData.assetName;
    let assignTo = editData.assignedTo;
    setLoading(true);
    const response = await CALL_API_UPDATED(
      `assets/${editData?.assetId}`,
      "patch",
      {
        assetName: editData.assetName,
        category: editData.category,
        description: editData.description,
        image: editData.image,
        assetStatus: "Available",
        assignedTo: "",
        assignedDate: "",
      }
    );

    if (response.status === "SUCCESS") {
      getAssetsData();
      setLoading(false);
      message.success(
        `${name} has been revoked from employee id: ${assignTo}.`
      );
      setEditData(false);
    } else {
      message.error("Failed to Revoke Asset");
    }
  };

  const handleEdit = (data) => {
    setEditData(data);
    setEdit(true);
  };

  const handleDelete = (data) => {
    setDeletedata(true);
    setEditData(data);
    setShowMessage(true);
  };

  const handleAssign = (data) => {
    setShowRevoke(true);
    setEditData(data);
  };

  return (
    <StyledWrap>
      <h6>Assets</h6>
      <Form
        form={form}
        layout="vertical"
        className="mt-2"
        onValuesChange={() => form.submit()}
      >
        <Row className="g-3">
          <Col xs={12} sm={6} md={4} lg={3} xxl={2} className="py-2">
            <DropdownCheckBox
              name="name"
              label="Name"
              options={uniqueNames}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2} className="py-2">
            <DropdownCheckBox
              name="category"
              label="Category"
              options={CategoryList.map((year) => ({
                label: year,
                value: year,
              }))}
              form={form}
            />
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xxl={2} className="py-2">
            <DropdownCheckBox
              name="status"
              label="Status"
              options={StatusList.map((period) => ({
                label: period,
                value: period,
              }))}
              form={form}
            />
          </Col>
          {Menulist?.find(
            (item) =>
              item?.key === "HRResources" &&
              item?.subMenu.find(
                (subItem) =>
                  subItem?.key === "assets" && subItem?.permission.create
              )
          ) && (
            <Col className="d-flex justify-content-end">
              <ButtonFilled
                onClick={() => {
                  closeModal(true);
                  setEditData([]);
                  setEdit(false);
                }}
              >
                Add New
              </ButtonFilled>
            </Col>
          )}
        </Row>
      </Form>
      <Spin spinning={showLoader}>
        <div className="customise_table">
          <Table
            dataSource={getFilteredAssetsData()}
            columns={columns}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="active-client-id"
          />
        </div>
      </Spin>

      <AddAsset
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        getAssetsData={getAssetsData}
        assetsData={assetsData}
      />

      <EditAsset
        isModalVisible={edit}
        closeModal={setEdit}
        getAssetsData={getAssetsData}
        edit={edit}
        editData={editData}
      />

      <AssignAsset
        setShowRevoke={setShowRevoke}
        showRevoke={showRevoke}
        editData={editData}
        getAssetsData={getAssetsData}
      />

      <CenteredModal
        title={false}
        visible={showmessage}
        onCancel={() => {
          setShowMessage(false);
          setDeleteLoading(false);
          setSuccess(false);
          setDeletedata(false);
        }}
        width={500}
        footer={false}
        closable={false}
      >
        <div>
          <p>Are you sure you want to delete this asset?</p>
          <div className="d-flex justify-content-center">
            <ButtonFilled
              loading={deleteLoading}
              onClick={() => deleteAssert()}
            >
              Yes
            </ButtonFilled>
            <ButtonFilled
              onClick={() => {
                setShowMessage(false);
                setDeletedata(false);
                setEditData([]);
                setDeleteLoading(false);
              }}
            >
              No
            </ButtonFilled>
          </div>
        </div>
      </CenteredModal>
    </StyledWrap>
  );
};

export default Assests;
