import React, { useEffect, useRef } from "react";
import { Layout, Menu, Skeleton, Badge, Drawer } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { size, times, map, intersection, get, filter, concat } from "lodash";
import userlogo from "../assets/bdo logo.svg";
import _map from "lodash/map";
import logo from "../assets/bdo logo.svg";
import COLORS from "common/Colors";
import Icon from "../atoms/Icon";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getLoadingDataFirstTime,
  getUnreadChatMessageList,
  getDepartmentUserList,
  getUserInfo,
  getUserList,
  getClientid,
  getRoleList,
  getUserRole,
  getActiveMenuList,
} from "redux/selectors";
import { DEVICE } from "common/Device";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Item, SubMenu } = Menu;

const StyledLogo = styled.div`
  height: 70px;
  width: 80px;
  margin: 5px 15px 20px;
  text-align: center;
  background: ${COLORS.SIDEBAR} 0% 0% no-repeat padding-box;
  img {
    margin-top: 5px;
  }
  @media ${DEVICE.tablet} {
    height: 25px;
    margin-bottom: 15px;
    img {
      height: 25px !important;
    }
  }
`;

const StyledSubMenu = styled(SubMenu)`
  background: ${COLORS.SIDEBAR};
  color: ${COLORS.TEXT.SECONDARY} !important ;

  svg {
    color: ${COLORS.TEXT.SECONDARY};
    fill: ${COLORS.TEXT.SECONDARY};
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    background: ${COLORS.SIDEBAR};
    border: none;
  }
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    color: ${COLORS.TEXT.SECONDARY};
    background: ${COLORS.SIDEBAR};
  }
  .ant-drawer-close {
    color: ${COLORS.TEXT.SECONDARY};
  }
  .ant-drawer-body {
    ::-webkit-scrollbar-thumb {
      background-color: ${COLORS.PRIMARY};
      border-radius: 6px;
      font-size: 16px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  .ant-menu {
    background: ${COLORS.SIDEBAR};
  }
`;

const StyledMenuItem = styled(Item)`
  vertical-align: middle;
  border-radius: 10px;
  width: 100%;
  background: ${COLORS.SIDEBAR};
  color: ${COLORS.TEXT.SECONDARY} !important ;
  border: none !important;

  svg {
    color: ${COLORS.TEXT.SECONDARY};
    fill: ${COLORS.TEXT.SECONDARY};
  }
  path {
    color: ${COLORS.TEXT.SECONDARY};
  }
  .ant-menu {
    border: 0 !important;
  }

  .MuiSvgIcon-root {
    color: ${COLORS.TEXT.SECONDARY} !important;
  }
  &:after {
    border-right: 3px solid
      ${(props) => (props.selected ? `${COLORS.PRIMARY}` : `${COLORS.SIDEBAR}`)} !important;
  }
  &.ant-menu-item a,
  .ant-menu-item a:hover,
  .ant-menu-item a:active {
    color: #000000 !important;
  }

  &.ant-menu-item-selected a,
  .ant-menu-item-selected a:hover,
  .ant-menu-item-selected a:active {
    color: #000000 !important;
  }
  &.ant-menu-item-selected {
    color: ${(props) =>
      props.selected ? `${COLORS.TEXT.SECONDARY}` : `${COLORS.TEXT.SECONDARY}`};
    background-color: ${(props) =>
      props.selected ? `${COLORS.PRIMARY}` : "inherit"} !important;
    .anticon,
    a,
    svg {
      color: ${COLORS.TEXT.SECONDARY};
      fill: ${(props) =>
        props.selected
          ? `${COLORS.TEXT.SECONDARY}`
          : `${COLORS.TEXT.SECONDARY}`};
    }
  }
`;

const StyledMenuSkeletonContainer = styled.div`
  text-align: center;
  padding: 10px 20px;
  .ant-skeleton {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const Container = styled.div`
  color: ${COLORS.TEXT.SECONDARY};
  ::-webkit-scrollbar-thumb {
    background-color: ${COLORS.PRIMARY};
    border-radius: 6px;
    font-size: 16px;
  }
  .ant-layout-sider-children {
    background: ${COLORS.SIDEBAR} 0% 0% no-repeat padding-box;
    margin: 0;
    color: ${COLORS.TEXT.SECONDARY};
  }

  .ant-menu-submenu-title > .anticon {
    color: ${COLORS.TEXT.SECONDARY}; /* Set your desired color */
    fill: ${COLORS.TEXT.SECONDARY};
  }
  .ant-menu {
    font-size: 14px;
    background: ${COLORS.SIDEBAR} 0% 0% no-repeat padding-box;
    color: ${COLORS.TEXT.SECONDARY};
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: ${COLORS.SIDEBAR};
  }
  overflow-y: auto;
  overflow-x: hidden;
  .sidemenu {
    display: none;
  }
  .hidemenu {
    display: flex;
    width: max-content;
    height: 100%;
    padding: 10px;
    background: ${COLORS.SIDEBAR} 0% 0% no-repeat padding-box;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-weight: bold;
      padding-left: 5%;
    }
  }
  .hidemenu1 {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background: ${COLORS.SIDEBAR} 0% 0% no-repeat padding-box;
    p {
      font-weight: bold;
      padding-left: 5%;
      margin-top: 10%;
    }
  }
  @media ${DEVICE.tablet} {
    .sidemenu {
      width: 150px;
      height: 100vh;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 110px;
      transition: 350ms;
      z-index: 20;
      background: ${COLORS.SIDEBAR} 0% 0% no-repeat padding-box;
    }
    .hidemenu {
      display: none;
      padding: 10px;
    }
  }
`;

const StyledBadge = styled(Badge)`
  left: 10px;
  top: 4px;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .ant-badge-dot {
    width: 10px;
    height: 10px;
    animation: blinker 1s linear infinite;
  }
`;

const { Sider } = Layout;
const SideBar = ({
  defaultKey,
  collapsed,
  setPageTitle,
  userRoleId,
  isLoadingDataFirstTime,
  unreadChatMessageList,
  open,
  onClose,
  userInfo,
  userList,
  activeMenuList,
  clientId,
}) => {
  const activePage = useLocation().pathname.replace("/", "");

  let Menulist =
    activeMenuList?.[userRoleId] ||
    Object.values(activeMenuList)?.[0] ||
    activeMenuList;

  Menulist = Array.isArray(Menulist) ? Menulist : [];
  // console.log("Menulist--->", Menulist);

  const filtermenulist =
    (Menulist &&
      Menulist.filter(
        (data) =>
          data.key !== "managerdashboard" &&
          data.key !== "hrdashboard" &&
          data.key !== "teamcalendar" &&
          data.key !== "allcalendar" &&
          data.key !== "regionalchart" &&
          data.key !== "officechart" &&
          data.key !== "employeeperformance" &&
          data.key !== "exemployee" &&
          data.key !== "pendingapprovalstimesheet" &&
          data.key !== "declinedtimesheet" &&
          data.key !== "timesheetapprovals" &&
          data.key !== "billingapprovals" &&
          data.key !== "myengagementcalendar" &&
          data.key !== "teamengagementcalendar" &&
          data.key !== "allengagementcalendar" &&
          data.key !== "capacityresourceplanning" &&
          data.key !== "PFAapprovals"
      )) ||
    [];

  const calendarAccess =
    (Menulist &&
      Menulist.filter(
        (data) =>
          data.key === "mycalendar" ||
          data.key === "teamcalendar" ||
          data.key === "allcalendar" ||
          data.key === "myengagementcalendar" ||
          data.key === "teamengagementcalendar" ||
          data.key === "allengagementcalendar"
      )) ||
    [];

  const myTeamDashBoardAccess =
    (Menulist &&
      Menulist.filter(
        (data) => data.key === "hrdashboard" || data.key === "managerdashboard"
      )) ||
    [];

  const peopleAccess =
    (Menulist &&
      Menulist.filter(
        (data) =>
          data.key === "regionalchart" ||
          data.key === "exemployee" ||
          data.key === "officechart" ||
          data.key === "employeeperformance"
      )) ||
    [];

  const timesheetAccess = Menulist.filter(
    (data) =>
      data.key === "mytimesheet" ||
      data.key === "pendingapprovalstimesheet" ||
      data.key === "declinedtimesheet"
  );

  const approvalsAccess = Menulist.filter(
    (data) =>
      data.key === "approvals" ||
      data.key === "timesheetapprovals" ||
      data.key === "billingapprovals" ||
      data.key === "PFAapprovals"
  );

  const resourcePlanningAccess = Menulist.filter(
    (data) => data.key === "peopleresourceplanning"
    // || data.key === "capacityresourceplanning"
  );

  const canViewApprovals = approvalsAccess.some((menu) => menu.permission.view);

  const canViewResourcePalnning = resourcePlanningAccess.some(
    (menu) => menu.permission.view
  );

  const canViewTimesheet = timesheetAccess.some((menu) => menu.permission.view);

  const canViewCalendar = calendarAccess.some((menu) => menu.permission.view);

  const canViewDashboard = myTeamDashBoardAccess.some(
    (menu) => menu.permission.view
  );
  const canViewPeople = peopleAccess.some((menu) => menu.permission.view);

  const menuSkeleton = (key) => (
    <Skeleton.Input block={true} active={true} size="small" key={key} />
  );
  const location = useLocation();

  const unreadDot = () => {
    var hrUser = filter(userList, { roleid: 1 });

    if (userRoleId == 2) {
      let result = map(
        concat(
          filter(userList, {
            departmentid: get(userInfo, "staff_department_id", ""),
          }),
          hrUser
        ),
        (e) => e.userid
      );
      result = intersection(result, unreadChatMessageList);
      return size(result) ? true : false;
    }
    return size(unreadChatMessageList) ? true : false;
  };

  const logoStyle = {
    height: collapsed ? "35px" : "57px",
    width: collapsed ? "80px" : "auto",
    marginTop: collapsed ? "30px" : "0px",
    // marginLeft: collapsed ? "-30px" : "",
  };

  const containerStyle = {
    height: "100vh",
    background: `${COLORS.SIDEBAR}`,
  };

  const drawerStyle = {
    padding: "0px",
    background: `${COLORS.SIDEBAR} 0% 0% no-repeat padding-box`,
  };

  const MenuComponent = !isLoadingDataFirstTime && (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={[activePage]}
      defaultOpenKeys={[defaultKey]}
    >
      {_map(
        filtermenulist?.filter(
          (menu) =>
            (menu?.access && menu.icon !== "") ||
            (menu.key === "mycalendar" && canViewCalendar) ||
            (menu.key === "employee" && canViewPeople) ||
            (menu.key === "dashboard" && canViewDashboard) ||
            (menu.key === "mytimesheet" && canViewTimesheet) ||
            (menu.key === "approvals" && canViewApprovals) ||
            (menu.key === "peopleresourceplanning" && canViewResourcePalnning)
        ),
        (menu) => {
          const checkid = activePage.includes("client-details")
            ? "client"
            : activePage.includes("profile-details")
            ? "employee"
            : "";
          const isSelected = activePage === menu.path || checkid === menu.path;

          return menu?.subMenu && menu?.subMenu?.length > 0 ? (
            <StyledSubMenu
              key={menu?.key}
              title={menu?.title}
              icon={menu?.icon && <Icon name={menu?.icon} />}
            >
              {_map(
                menu?.subMenu.filter((submenu) => submenu?.access),
                (submenu) => {
                  return submenu?.subMenu && submenu?.subMenu?.length > 0 ? (
                    <StyledSubMenu
                      key={submenu?.key}
                      icon={submenu?.icon && <Icon name={submenu?.icon} />}
                      title={submenu?.title}
                    >
                      {_map(
                        submenu?.subMenu.filter((submenu) => submenu?.access),
                        (submenu) => {
                          return (
                            <StyledMenuItem
                              collapsed={collapsed ? "true" : "false"}
                              key={submenu?.key}
                              icon={
                                submenu?.icon && <Icon name={submenu?.icon} />
                              }
                              onClick={() => setPageTitle(submenu?.title)}
                              selected={
                                location?.pathname.substr(1) == ""
                                  ? false
                                  : true
                              }
                            >
                              <Link to={`/${submenu.path}`}>
                                {submenu?.title}
                              </Link>
                              <StyledBadge
                                dot={menu?.path === "team" && unreadDot()}
                              />
                            </StyledMenuItem>
                          );
                        }
                      )}
                    </StyledSubMenu>
                  ) : (
                    <>
                      {submenu.key !== "attendance-report-monthly" && (
                        <StyledMenuItem
                          collapsed={collapsed ? "true" : "false"}
                          key={submenu?.key}
                          icon={submenu?.icon && <Icon name={submenu?.icon} />}
                          onClick={() => setPageTitle(submenu?.title)}
                          selected={
                            location?.pathname.substr(1) == "" ? false : true
                          }
                        >
                          <Link to={`/${submenu?.path}`}>{submenu?.title}</Link>
                          <StyledBadge
                            dot={menu?.path === "team" && unreadDot()}
                          />
                        </StyledMenuItem>
                      )}
                    </>
                  );
                }
              )}
            </StyledSubMenu>
          ) : (
            <StyledMenuItem
              key={menu?.key}
              icon={menu?.icon && <Icon name={menu?.icon} />}
              onClick={() => setPageTitle(menu?.title)}
              selected={isSelected}
              className={`${isSelected ? "ant-menu-item-selected" : ""}`}
            >
              <Link to={`/${menu?.path}`}>
                {menu?.key === "mycalendar"
                  ? "Calendar"
                  : menu?.key === "dashboard"
                  ? "Dashboard"
                  : menu?.key === "employee"
                  ? "People"
                  : menu?.key === "mytimesheet"
                  ? "Timesheet"
                  : menu.key === "approvals"
                  ? "Approvals"
                  : menu.key === "peopleresourceplanning"
                  ? "Resource Planning"
                  : menu?.title}
              </Link>
              <StyledBadge dot={menu?.path === "team" && unreadDot()} />
            </StyledMenuItem>
          );
        }
      )}
    </Menu>
  );

  return (
    <Container style={containerStyle}>
      <div className="hidemenu">
        <Sider trigger={null} collapsible collapsed={collapsed}>
          {/* <Link to={userRoleId === "1" ? `ownerdashboard` : `dashboard`} onClick={() => setPageTitle("")}> */}
          <StyledLogo>
            <img
              className="mb-3 pb-2"
              style={logoStyle}
              src={userRoleId === "1" ? logo : userlogo}
              alt="Tymeplus"
            />
          </StyledLogo>
          {/* </Link> */}
          {isLoadingDataFirstTime && (
            <StyledMenuSkeletonContainer>
              {times(10, (ind) => menuSkeleton(ind))}
            </StyledMenuSkeletonContainer>
          )}

          {MenuComponent}
        </Sider>
      </div>
      <div>
        <StyledDrawer
          bodyStyle={drawerStyle}
          width={240}
          title={
            <>
              <Link to={`dashboard`}>
                <img
                  src={userRoleId == 1 ? logo : userlogo}
                  width={120}
                  alt="Tymeplus"
                />
              </Link>
            </>
          }
          placement="left"
          onClose={onClose}
          open={open}
        >
          {MenuComponent}
        </StyledDrawer>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
  departmentUserList: getDepartmentUserList(state),
  userInfo: getUserInfo(state),
  userList: getUserList(state),
  // menuList: getMenuList(state),
  activeMenuList: getActiveMenuList(state),
  clientId: getClientid(state),
  roleList: getRoleList(state),
  UserRoleId: getUserRoleId(state),
  roleId: getUserRoleId(state),
  role: getUserRole(state),
});

export default connect(mapStateToProps, null)(SideBar);
