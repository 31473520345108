export const getClientOnboardingJson = (data) => {
    const ELIGIBILITYANDINDEPENDENCE = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Ensure that the firm is qualified and is eligible to accept the appointment in terms the Companies.",
                type: "text",
                name: "bdo_thefirmisqualifiedandiseligibleinca",
                Placeholder: "",
                viewOnly: true,
              },
              {
                  id: 2,
                  label: "Ensure that there is compliance with the firm’s rules and procedures as described in the BDO.",
                  type: "text",
                  name: "bdo_firmsrulesandproceduresasdescribedintheb",
                  Placeholder: "",
                  viewOnly: true,
                }
            ],
          ],
        },
      ],
    };
  
    const PROSEPCTIVECLIENTSREPUTATION = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Discuss the prospective client with the person who referred them, and note below their comments.",
                type: "text",
                name: "bdo_theprospectiveclientwithpartnersandmanage",
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "Obtain and review the prospective client’s financial statements, recent annual returns.",
                type: "text",
                name: "reviewprospectiveclientfinancialstatements",
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 3,
                label: "Discuss the prospective client with partners and managers in the firm and note below their comments.",
                type: "text",
                name: "bdo_theprospectiveclientwithpartnersandmanage",
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 4,
                label: "If the above procedures are inconclusive, contact friends of the firm who may know the Client.",
                type: "text",
                name: "bdo_iftheaboveproceduresareinconclusivecontac",
                Placeholder: "",
                viewOnly: true,
              },
            ],    
          ],
        },
      ],
    };
  
    const IncasesofInternationalReferrals = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Has the prospective client’s reputation been discussed with the parent engagement partner?",
                type: "text",
                name: "reputationDiscussedWithParentEngagembdo_theprospectiveclientsreputationbeendiscuentPartner", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "Has the prospective client’s reputation been discussed with the International Liaison Partner.",
                type: "text",
                name: "bdo_prospectiveclientsreputation", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 3,
                label: "Have the other member firm’s acceptance procedures been considered?.",
                type: "text",
                name: "bdo_othermemberfirmsacceptanceprocedurescon", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 4,
                label: "",
                type: "div",
                name: "",
                Placeholder: "",
              },
            ],
          ],
        },
      ],
    };
    
    const PREVIOUSAUDITORS = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Request the prospective client to authorize the previous auditors to respond fully to our inquiries",
                type: "text",
                name: "bdo_theprospectiveclienttoauthorizethepreviou", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "The previous auditors, to determine if there is any reason why the firm should not accept engagement",
                type: "text",
                name: "reasonForNotAcceptingEngagement", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 3,
                label: "Make personal contact with the previous auditor to discuss matters relating to the prior year engagement",
                type: "text",
                name: "bdo_contactwithpreviousauditortodiscussmatte", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 4,
                label: "The prospective client, as to why (and how frequently) the company is changing auditors",
                type: "text",
                name: "reasonForChangingAuditors", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 5,
                label: "Request access to the previous auditors’ working papers as relevant.",
                type: "text",
                name: "bdo_thepreviousauditorsworkingpapersasrelevan", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 6,
                label: "",
                type: "div",
                name: "",
                Placeholder: "",
              },
            ],
          ],
        },
      ],
    };
    

    const ABILITYTOSERVETHECLIENT = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Ensure that appropriate partners and staff are available to perform the audit, in compliance with",
                type: "text",
                name: "bdo_appropriatepartnersandstaffareavailableto", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "Ascertain that the firm has, or can obtain, any specialist expertise required to",
                type: "text",
                name: "bdo_firmhasorcanobtainanyspecialistexpertise", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
          ],
        },
      ],
    };
    

    const Fees = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "The agreed principal basis on which the fees shall be charged.",
                type: "text",
                name: "bdo_theagreedprincipalbasisonwhichthefeesshal", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "Is the firm independent with respect to the client regarding the current fee arrangement.",
                type: "text",
                name: "bdo_firmindependentwithrespecttotheclientrega", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 3,
                label: "In case of a pro-bono engagement, please justify the rationale",
                type: "text",
                name: "bdo_incaseofaprobonoengagement", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 4,
                label: "",
                type: "div",
                name: "",
                Placeholder: "",
              },
            ],
          ],
        },
      ],
    };
    

    const INVESTIGATIONOFINDUSTRYBACKGROUNDANDOTHERRISKFACTORS = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Client’s type of business and brief details about the industry:",
                type: "text",
                name: "bdo_clientstypeofbusinessandbriefdetailsabout", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "Are there any negative concerns about the prospect client’s marketplace or its directors",
                type: "text",
                name: "bdo_arethereanynegativeconcernsabouttheprospe", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 3,
                label: "Is the prospective client publicly held, or expecting public offering in the near future?",
                type: "text",
                name: "bdo_istheprospectiveclientpubliclyheldorexpec", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 4,
                label: "Management’s attitude to control systems, its plan to address potential problems, and the viability",
                type: "text",
                name: "bdo_managementsattitudetocontrolsystemsitspla", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 5,
                label: "The apparent competence of accounting, financial and data processing personnel, and the adequacy",
                type: "text",
                name: "bdo_theapparentcompetenceofaccountingfinancia", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 6,
                label: "Has the prospective client, or any of its director or shareholders, been convicted of a crime",
                type: "text",
                name: "bdo_hastheprospectiveclientoranyofitsdirector", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 7,
                label: "Is there substantial doubt concerning the prospective client’s ability to continue in existence",
                type: "text",
                name: "bdo_istheresubstantialdoubtconcerningtheprosp", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 8,
                label: "Recent continuity of management, and its perceived competence",
                type: "text",
                name: "bdo_recentcontinuityofmanagementanditsperceiv", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 9,
                label: "The financial needs of the company, and its ability to meet those needs in the future.",
                type: "text",
                name: "bdo_thefinancialneedsofthecompanyanditsabilit", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 10,
                label: "The rapidity of growth in recent years, and the source of such growth (i.e. internal growth, merger",
                type: "text",
                name: "bdo_therapidityofgrowthinrecentyearsandtheso", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 11,
                label: "Has the prospective client’s character, integrity and reputation been discussed with its merchant",
                type: "text",
                name: "bdo_hastheprospectiveclientscharacterintegrit", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 12,
                label: "",
                type: "div",
                name: "",
                Placeholder: "",
              },
            ],
          ],
        },
      ],
    };
    

    const LITIGATION = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Are we satisfied that there is no litigation taking place, or likely to take place between the firm.",
                type: "text",
                name: "bdo_arewesatisfiedthatthereisnolitigationtaki", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "",
                type: "div",
                name: "",
                Placeholder: "",
              },
            ],
          ],
        },
      ],
    };
    
    const CLOSERELATIONSHIPS = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Can we confirm that no partners or staff engaged in the client’s affairs have family connections",
                type: "text",
                name: "bdo_canweconfirmthatnopartnersorstaffengagedi", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "",
                type: "div",
                name: "",
                Placeholder: "",
              },
            ],
          ],
        },
      ],
    };
    
    const FINANCIALINTEREST = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Can we confirm that any partner (or closely connected person) or member of staff engaged",
                type: "text",
                name: "bdo_canweconfirmthatanypartnerorcloselyconnec", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "",
                type: "div",
                name: "",
                Placeholder: "",
              },
            ],
          ],
        },
      ],
    };
    
    const CONFLICTSOFINTEREST = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Are we satisfied that there are no limitations of scope which will be imposed on our work by management",
                type: "text",
                name: "bdo_arewesatisfiedthattherearenolimitationsof", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "",
                type: "div",
                name: "",
                Placeholder: "",
              },
            ],
          ],
        },
      ],
    };
    
    const LIMITATIONOFSCOPE = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Are we satisfied that there are no limitations of scope which will be imposed on our work by management",
                type: "text",
                name: "bdo_arewesatisfiedthattherearenolimitationsof", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "",
                type: "div",
                name: "",
                Placeholder: "",
              },
            ],
          ],
        },
      ],
    };
    
    const OTHERINDEPENDENCEISSUES = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Are we satisfied that there are no other factors which could impair our independence?",
                type: "text",
                name: "bdo_arewesatisfiedthattherearenootherfactorsw", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "Have we satisfied ourselves that there were no other factors existing during the period",
                type: "text",
                name: "bdo_havewesatisfiedourselvesthattherewerenoot", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
          ],
        },
      ],
    };
    
    const RiskRatingForm = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Listed on a stock exchange",
                type: "text",
                name: "bdo_listedonastockexchange", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 2,
                label: "Anticipating a public offering within the next two",
                type: "text",
                name: "bdo_anticipatingapublicofferingwithinthenextt", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 3,
                label: "Could be subject of a buy-out or takeover",
                type: "text",
                name: "bdo_couldbesubjectofabuyoutortakeoverwithint", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 4,
                label: "Involved in high-tech, emerging or heavily/highly",
                type: "text",
                name: "bdo_involvedinhightechemergingorheavilyhighly", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 5,
                label: "Any other Public Interest Entity or Publicly Accountable Entity",
                type: "text",
                name: "bdo_publicinterestentity", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 6,
                label: "Aggressive accounting policies; undue emphasis",
                type: "text",
                name: "bdo_aggressiveaccountingpoliciesundueemphasis", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 7,
                label: "Complex accounting principles",
                type: "text",
                name: "bdo_complexaccountingprinciples", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 8,
                label: "Continuing losses and/or experiencing liquidity",
                type: "text",
                name: "bdo_continuinglossesandorexperiencingliquidit", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 9,
                label: "Unrealistic reporting deadlines",
                type: "text",
                name: "bdo_unrealisticreportingdeadlines", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 10,
                label: "Poor debt position relative to industry norms",
                type: "text",
                name: "bdo_poordebtposition", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 11,
                label: "Domineering chief executive/management",
                type: "text",
                name: "bdo_domineeringchiefexecutivemanagement", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 12,
                label: "Weak internal controls and poor management",
                type: "text",
                name: "bdo_weakinternalcontrolsandpoormanagement", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 13,
                label: "Engages in offshore transactions in tax havens",
                type: "text",
                name: "bdo_engagesinoffshoretransactionsintaxhavenso", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 14,
                label: "Has foreign subsidiaries/affiliates",
                type: "text",
                name: "bdo_hasforeignsubsidiariesaffiliates", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 15,
                label: "Client/management reputation sullied",
                type: "text",
                name: "bdo_clientmanagementreputationsullied", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 16,
                label: "Disputes, unresolved issues with predecessor audit",
                type: "text",
                name: "bdo_disputesunresolvedissueswithpredecessorau", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 17,
                label: "Is evasive or abusive to BDO East Africa staff",
                type: "text",
                name: "bdo_isevasiveorabusivetobdoeastafricastaffdur", // Set name value
                Placeholder: "",
                viewOnly: true,
              },
              {
                id: 18,
                label: "",
                type: "div",
                name: "", // Set name value if needed
                Placeholder: "",
              },
            ],
          ],
        },
      ],
    };
    
    const CONCLUSIONANDACCEPTANCEAPPROVAL = {
      totalpages: 1,
      pages: [
        {
          modalView: false,
          colSpan: [24, 24, 24],
          fields: [
            [
              {
                id: 1,
                label: "Relationship / Liaison Manager",
                type: "text",
                name: "relationship",
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 2,
                label: "Signature",
                type: "text",
                name: "bdo_signature",
                Placeholder: "",
                viewOnly: true,
              },
            ],
            [
              {
                id: 3,
                label: "Date",
                type: "text",
                name: "bdo_date",
                Placeholder: "",
                viewOnly: true,
              },
            ],
          ],
        },
      ],
    };
    
  
    return {
        ELIGIBILITYANDINDEPENDENCE,
        PROSEPCTIVECLIENTSREPUTATION,
        IncasesofInternationalReferrals,
        PREVIOUSAUDITORS,
        ABILITYTOSERVETHECLIENT,
        Fees,
        INVESTIGATIONOFINDUSTRYBACKGROUNDANDOTHERRISKFACTORS,
        LITIGATION,
        CLOSERELATIONSHIPS,
        FINANCIALINTEREST,
        CONFLICTSOFINTEREST,
        LIMITATIONOFSCOPE,
        OTHERINDEPENDENCEISSUES,
        RiskRatingForm,
        CONCLUSIONANDACCEPTANCEAPPROVAL
    };
  };
  