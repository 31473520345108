import { Link } from "@mui/material";
import { Col, Form, Row, Spin, Table } from "antd";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import queryKeys from "common/queryKey";
import { useScrollFetch } from "hooks/useScrollFetch";
import { uniqBy } from "lodash";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setFilter } from "redux/actions";
import { getFilters } from "redux/selectors";

const ClientContact = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const fontStyle = { color: "#636980" };
  const filters = useSelector(getFilters);
  const menuName= "clientcontact";
  const initialFilter = filters[menuName];

  const dispatch = useDispatch();
 

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    dispatch(setFilter(menuName, "name", values.name || []));
    dispatch(setFilter(menuName, "managingoffice", values.managingoffice || []));
    dispatch(
      setFilter(menuName, "status", values.status || [])
    );
  };

  const { getClientsList } = queryKeys;

  const { key, url } = getClientsList;

  const { data, lastElementRef, isLoading, refetch } = useScrollFetch(key, {
    endPoint: url,
    queryParam: "LastEvaluatedKey",
  });

  const dataList = useMemo(() => {
    if (data) {
      return data?.pages?.reduce((acc, page) => {
        if (page?.data === undefined) return acc;
        return [...acc, ...page?.data];
      }, []);
    } else {
      return [];
    }
  }, [data]);

  const columns = [
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      className: "normal-column approved",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "emailaddress1",
      key: "emailaddress1",
      className: "normal-column approved",
      sorter: (a, b) => a.emailaddress1.localeCompare(b.emailaddress1),
      render: (emailaddress1) => emailaddress1 || "-",
    },
    {
      title: "Client",
      dataIndex: "name",
      key: "name",
      className: "normal-column",
      render: (name) => name || "-",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Business Phone",
      dataIndex: "",
      key: "",
      className: "normal-column",
      // render: (bdo_id) => bdo_id || "-",
      // sorter: (a, b) => a.bdo_id.localeCompare(b.bdo_id),
    },
    {
      title: "Role",
      dataIndex: "",
      key: "",
      className: "normal-column",
    //   render: (role) => "kenya" || "-",
    //   sorter: (a, b) => a.role.toString().localeCompare(b.role.toString()),
    },

    {
      title: "Owner",
      dataIndex: "_ownerid_value",
      key: "_ownerid_value",
      className: "normal-column",
      render: (_ownerid_value) => _ownerid_value || "-",
      sorter: (a, b) => a._ownerid_value.toString().localeCompare(b._ownerid_value.toString()),
    },

    {
      title: "Owning business unit",
      dataIndex: "_owningbusinessunit_value",
      key: "_owningbusinessunit_value",
      className: "normal-column",
      render: (_owningbusinessunit_value) => _owningbusinessunit_value || "-",
      sorter: (a, b) =>
        a._owningbusinessunit_value
          .toString()
          .localeCompare(b._owningbusinessunit_value.toString()),
    },
  ];

  const uniqueStatus = [
    { label: "Active", value: "1" },
    { label: "Client Onboarded", value: "755390003" },
    { label: "Client Acceptance Form Completed", value: "755390002" },
  ];

  const uniqueName = uniqBy(
    dataList?.map((item) => {
      return {
        label: item?.name,
        value: item?.name,
      };
    }),
    "value"
  );

  const uniquemanagingOffice = uniqBy(
    dataList?.map((item) => {
      return { label: "Kenya", value: item?.bdo_managingoffice?.toString() };
    }),
    "value"
  );

  const getFilteredData = () => {
    const name = form.getFieldValue("name") || [];
    const managingoffice = form.getFieldValue("managingoffice") || [];
    const status = form.getFieldValue("status") || [];
    if (name.length > 0 || managingoffice.length > 0 || status.length > 0) {
      return dataList?.filter((item) => {
        const nameMatch =
          name && name.length > 0 ? name.some((n) => item.name === n) : true;

        const managingofficeMatch =
          managingoffice && managingoffice.length > 0
            ? managingoffice.includes(item.bdo_managingoffice.toString())
            : true;

        const statusMatch =
          status && status.length > 0
            ? status.includes((item?.statuscode).toString())
            : true;

        return nameMatch && managingofficeMatch && statusMatch;
      });
    } else {
      return dataList;
    }
  };

  return (
    <>
      <h6>Client contact</h6>
      <Row>
        <Col sm={24}>
          <Form
            form={form}
            layout="vertical"
            className="mt-2"
            onValuesChange={handleFormChange}
            initialValues={{ ...initialFilter }}
          >
            <Row gutter={12} className="g-3 mb-2">
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="name"
                  label="Client Name"
                  options={uniqueName}
                  form={form}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="managingoffice"
                  label="Managing Office"
                  options={uniquemanagingOffice}
                  form={form}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="status"
                  label="Status"
                  options={uniqueStatus}
                  form={form}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Spin spinning={isLoading}>
        <div className="customise_table">
          <Table
            columns={columns}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="bdo_id"
            dataSource={getFilteredData()?.map((client) => ({
              ...client,
              "Client Name": (
                <Link
                  to={`/client-details/${client?.bdo_id}`}
                  style={fontStyle}
                >
                  {client?.bdo_id}
                </Link>
              ),
            }))}
            onRow={(record, index) => ({
              ref: index === dataList.length - 5 ? lastElementRef : null,
              onClick: () => {
                history.push(`/client-contact-details/${record.bdo_id}`, {
                  extraData: dataList,
                });
              },
            })}
            pagination={false}
          />
        </div>
      </Spin>
    </>
  );
};

export default ClientContact;
