import { Modal, Table } from "antd";
import moment from "moment";
import { generateTimesheetWeek } from "pages/TimeSheet/useWeekData";
import React from "react";
import { useSelector } from "react-redux";
import { getUserList } from "redux/selectors";
import styled from "styled-components";

const BackLogRender = ({ record }) => {
  const userList = useSelector(getUserList);

  const [showModal, setShowModal] = React.useState(false);

  const handleClickBacklog = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const userId = record.employeeId;

  const user = userList.find((user) => {
    return user.userid === userId;
  });

  const hiredDate = moment(user?.hireddate, "YYYY-MM-DD");

  // from hiredDate to current date generate weeks

  let workableWeeks = [];

  let week = hiredDate.clone().startOf("isoWeek");

  while (week.isBefore(moment())) {
    workableWeeks.push(week.clone());
    week.add(1, "week");
  }

  workableWeeks = workableWeeks.map((week) => {
    const start = week.clone().startOf("isoWeek");
    const end = week.clone().endOf("isoWeek");

    return generateTimesheetWeek(start, end);
  });

  const backLogWeeks = workableWeeks.filter((week) => {
    const workedThatWeek = record?.weeks?.some((recordWeek) => {
      return recordWeek.week === week;
    });

    return !workedThatWeek;
  });

  const totalBacklog = backLogWeeks.length;

  const backLogColumn = [
    // s.no
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Week",
      dataIndex: "week",
      key: "week",
      render: (text, record) => {
        return text;
      },
    },
  ];

  return (
    <>
      <div className="backlog" onClick={handleClickBacklog}>
        {totalBacklog}
      </div>
      <StyledModal
        open={showModal}
        onCancel={handleCloseModal}
        title={"Back Log Weeks"}
        width={500}
        footer={null}
        closable={true}
        maskClosable={false}
        forceRender={true}
      >
        <ClaimsTable
          columns={backLogColumn}
          dataSource={backLogWeeks.map((week) => {
            return { week };
          })}
          bordered={true}
          pagination={
            backLogWeeks.length > 10
              ? { pageSize: 10, hideOnSinglePage: true }
              : false
          }
        />
      </StyledModal>
    </>
  );
};

export default BackLogRender;

const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    .anticon-close.ant-modal-close-icon,
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .ant-modal-header {
    border-bottom: none;
    padding-top: 10px;
  }
  .ant-modal-title {
    font-weight: 500;
    text-align: left;
    color: #1b1b1b;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
  .ant-upload-span {
    cursor: pointer;
  }
`;

const ClaimsTable = styled(Table)`
  th.ant-table-cell {
    background-color: #f5f5f5;
  }
  .ant-table-cell {
    padding: 6px 10px !important;
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    overflow-wrap: normal;
  }
  .ant-table-cell.nowrap {
    white-space: nowrap;
  }
`;
