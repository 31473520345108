import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import { accessRoleOptions, STATUS_CODE } from "common/Constants";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { concat, find, map, uniqBy } from "lodash";
import moment from "moment";
import useResourceFn from "pages/Engagement/EngagementView/useResourceFn";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserList } from "redux/selectors";
import styled from "styled-components";
import { findWeeksInRange, generateSchedule } from "utils/Date";

const labelStyle = {
  // width: "120px",
  display: "inline-block",
  textAlign: "start",
};

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;

const AddResourceFromCalender = ({
  show,
  onClose,
  header,
  bookedUserList,
  selectedDateRange,
  clickedDate,
  refetch,
}) => {
  const userList = useSelector(getUserList);

  const { getAllEnggagements } = queryKeys;

  const { data: allEngagementsDataRes } = useFetch(
    getAllEnggagements.key,
    getAllEnggagements.url
  );

  const allEngagements = allEngagementsDataRes?.data;

  const allEngagementsOptions = allEngagements?.map((option) => ({
    label: option.engagementName,
    value: option.engagementId,
  }));

  const { getEngagementById } = queryKeys;

  const [selectedEngagment, setSelectedEngagment] = React.useState(null);

  const { id } = selectedEngagment || {};

  const { key: engagementKey, url: engagementUrl } = getEngagementById(id);

  const { data: engagementDataRes, isLoading: isEngagementLoading } = useFetch(
    engagementKey,
    engagementUrl,
    {
      enabled: !!id,
    }
  );

  const engagementData = engagementDataRes?.data;

  const [selectedResource, setSelectedResource] = React.useState(null);

  const filterUserBookedList = bookedUserList?.filter(
    (user) => user.userId === selectedResource?.userId
  );

  const actualBookedDates = filterUserBookedList?.map((item) => ({
    startDate: item.actualBookedStartDate,
    endDate: item.actualBookedEndDate,
  }));

  const bookedEngagementUserList = bookedUserList?.filter(
    (user) => user.engagementId === engagementData?.engagementId
  );

  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const allResourceList = () => {
    const uniqBookedList = uniqBy(bookedUserList, "userId");

    const [startDate, endDate] = selectedDateRange;

    const availableUsers = userList?.filter(
      (item1) => !bookedUserList?.some((item2) => item1.userid === item2.userId)
    );

    const transformedAvailableUserList = map(availableUsers, (userData) => {
      const schedule = generateSchedule(startDate, endDate);

      return {
        userId: userData?.userid,
        name: userData?.fullname,
        status: "Completely available",
        employeeId: userData?.employeeid,
        resourceExtRate: userData?.chargeoutrate,
        schedule,
      };
    });

    const transformedBookedUserList = map(uniqBookedList, (userData) => {
      const filteredArray = bookedUserList?.filter(
        (user) => user.userId === userData?.userId
      );
      const schedule = generateSchedule(startDate, endDate);
      const isWithinRange = isInRange(schedule, filteredArray);

      return {
        userId: userData?.userId,
        name: userData?.employeeName,
        status: isWithinRange ? "Booked" : "Partialy available",
        employeeId: userData?.employeeId,
        resourceExtRate: userData?.resourceExtRate,
        schedule,
      };
    });

    return concat(transformedAvailableUserList, transformedBookedUserList);
  };

  const { Option } = Select;

  const justificationOptions = [
    "Availability",
    "Expense/Experience",
    "Subject Matter Expert",
    "Cost/Value",
    "Client Experience/History",
    "Technical/Education Qualification",
  ];

  useEffect(() => {
    const partner = find(userList, {
      userid: engagementData?.stakeHolder?.partner,
    })?.fullname;

    form.setFieldsValue({
      engagement: engagementData?.engagementId,
      partner: partner || "-",
    });
  }, [engagementData, form, userList]);

  const { stakeHolder, information } = engagementData || {};

  const planStartDate = moment(
    stakeHolder?.planStartDate || new Date(),
    "DD-MM-YYYY"
  ).format("DD MMM YYYY");

  const planEndDate = moment(
    stakeHolder?.planEndDate || new Date(),
    "DD-MM-YYYY"
  ).format("DD MMM YYYY");

  const restrictPastDate = (current, element, ranges) => {
    if (planStartDate && planEndDate) {
      if (current < planStartDate || current > planEndDate) {
        return true; // Disable dates outside the planned start and end date
      }
    }

    if (ranges) {
      for (let range of ranges) {
        if (
          current >= moment(range.startDate) &&
          current <= moment(range.endDate)
        ) {
          return true;
        }
      }
    }

    // Additional logic for "booked_finish_date"
    if (element === "booked_finish_date") {
      const bookedStartDate = form.getFieldValue("booked_start_date");
      if (bookedStartDate) {
        return current < moment(bookedStartDate);
      }
    }

    // Disable past dates
    return current && current < moment().startOf("day");
  };

  const restrictPastStartDate = (current, ranges) => {
    if (planStartDate && planEndDate) {
      if (current < planStartDate || current > planEndDate) {
        return true; // Disable dates outside the planned start and end date
      }
    }

    if (ranges) {
      for (let range of ranges) {
        if (
          current >= moment(range.startDate) &&
          current <= moment(range.endDate)
        ) {
          return true;
        }
      }
    }

    return current && current < moment().startOf("day");
  };

  const [days, setDays] = useState(0);
  const {
    isScheduleValid,
    isInRange,
    calculateEffort,
    validateDateRange,
    workingdays,
  } = useResourceFn(form, bookedEngagementUserList, setDays);

  useEffect(() => {
    workingdays();
    calculateEffort();
  }, [clickedDate, workingdays, calculateEffort]);

  useEffect(() => {
    form.setFieldsValue({
      booked_start_date: clickedDate,
      booked_finish_date: clickedDate,
    });
  }, [clickedDate, form]);

  const onFinish = async (form) => {
    try {
      setLoading(true);
      const startDate = form?.booked_start_date;
      const endDate = form?.booked_finish_date;
      const weeksInRange = findWeeksInRange(startDate, endDate);
      const payload = {
        userId: selectedResource?.userId,
        employeeName: selectedResource?.name,
        employeeId: selectedResource?.employeeId,
        accessRole: form?.access_role,
        projectRole: form?.project_role,
        actualBookedStartDate: moment(form?.booked_start_date).format(
          "YYYY-MM-DD"
        ),
        actualBookedEndDate: moment(form?.booked_finish_date).format(
          "YYYY-MM-DD"
        ),
        engagementId: engagementData?.engagementId,
        engagementName: information?.jobTitle,
        clientId: engagementData?.clientId,
        effort: form?.effort,
        remarks: form?.remark,
        personDay: form?.person_day,
        resourceExtRate: selectedResource?.resourceExtRate,
        status: "Assigned",
        engagementStartDate: stakeHolder?.planStartDate,
        engagementEndDate: stakeHolder?.planEndDate,
        weekList: weeksInRange,
        justification: form?.justification,
        managingOffice: engagementData?.client?.managingOffice,
      };
      const { code } = await CALL_API(
        `insyts/engagement/resource-plan`,
        "post",
        payload
      );
      setLoading(false);
      if (code === STATUS_CODE.SUCCESS) {
        message.success("Successfully Added");
        refetch();
        closeModal();
      }
    } catch (error) {
      message.error(
        "Something went wrong. Please reach out to our technical team."
      );
      setLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const closeModal = () => {
    setLoading(false);
    form.resetFields();
    setSelectedEngagment(null);
    setSelectedResource(null);
    onClose();
  };

  const schedule = generateSchedule(
    form?.getFieldValue("booked_start_date"),
    form?.getFieldValue("booked_finish_date")
  );

  return (
    <StyledModal
      title={header}
      visible={show}
      onCancel={closeModal}
      width={800}
      footer={[]}
    >
      <AddResourceModalWrap>
        <Form
          layout="vertical"
          onFinish={onFinish}
          colon={false}
          form={form}
          initialValues={{
            engagement: null,
            selected_resource: null,
            partner: `${planStartDate} - ${planEndDate}`,
            person_day: 0,
            booked_start_date: clickedDate,
            booked_finish_date: clickedDate,
            project_role: null,
            access_role: null,
            effort: 0,
            remark: "",
          }}
        >
          <div className="resource-card">
            <Row gutter={24}>
              <Col span={12}>
                <Spin spinning={isEngagementLoading}>
                  <Form.Item
                    label={<span style={labelStyle}>Engagement:</span>}
                    name="engagement"
                    rules={[
                      {
                        required: true,
                        message: "Engagement is required.",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Engagement"
                      optionFilterProp="label"
                      options={allEngagementsOptions}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        setSelectedEngagment({
                          id: value,
                        });
                      }}
                    />
                  </Form.Item>
                </Spin>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Selected Resource</span>}
                  name="selected_resource"
                  rules={[
                    {
                      required: false,
                      message: "Resource is required.",
                    },
                  ]}
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              {/* <Form.Item
                  label={<span style={labelStyle}>Access Role</span>}
                  name="access_role"
                >
                  <Select
                    placeholder="Select"
                    options={accessRoleOptions.map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    fieldNames={{
                      label: "label",
                      value: "value",
                    }}
                  />
                </Form.Item> */}
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Booked Start Date</span>}
                  name="booked_start_date"
                  rules={[
                    {
                      required: true,
                      message: "Booked Start Date is required.",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select Date"
                    disabledDate={(current) =>
                      restrictPastStartDate(current, actualBookedDates)
                    }
                    onChange={() => {
                      validateDateRange();
                      workingdays();
                      calculateEffort();
                    }}
                    format="DD-MM-YYYY"
                    disabled={selectedResource === null}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Booked Finish Date</span>}
                  name="booked_finish_date"
                  dependencies={["booked_start_date"]}
                  rules={[
                    {
                      required: true,
                      message: "Booked finish date is required.",
                    },
                    ({ getFieldValue }) => ({
                      validator: async (rule, value) => {
                        const bookedStartDate =
                          getFieldValue("booked_start_date");
                        if (bookedStartDate && value) {
                          const isStartDateBeforePastDate = moment(
                            bookedStartDate
                          ).isBefore(moment(), "day");

                          if (isStartDateBeforePastDate) {
                            return Promise.reject(
                              "Cannot book for past dates."
                            );
                          }

                          const isWithinRange = isScheduleValid(
                            generateSchedule,
                            bookedStartDate,
                            value,
                            actualBookedDates
                          );
                          if (isWithinRange) {
                            return Promise.reject("Already booked!.");
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    placeholder="Select Date"
                    disabledDate={(current) =>
                      restrictPastDate(
                        current,
                        "booked_finish_date",
                        actualBookedDates
                      )
                    }
                    onChange={() => {
                      workingdays();
                      calculateEffort();
                    }}
                    format="DD-MM-YYYY"
                    disabled={selectedResource === null}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span
                  style={{
                    ...labelStyle,
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#5D5F68",
                    marginBottom: "20px",
                  }}
                >
                  Total Booked Mandays: {days}
                </span>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Budget Mandays</span>}
                  name="person_day"
                  rules={[
                    {
                      required: true,
                      message: "Budget Mandays is required.",
                    },
                    {
                      validator: (_, value) => {
                        const numericValue = Number(value);

                        const isFloatingNumber = numericValue % 1 !== 0;

                        const floatEnd = numericValue.toString().split(".")[1];

                        if (
                          !Number.isInteger(numericValue) &&
                          !isFloatingNumber
                        ) {
                          return Promise.reject(
                            "Budget Mandays must be a number or half number"
                          );
                        }

                        if (isFloatingNumber && floatEnd !== "5") {
                          return Promise.reject(
                            "Budget Mandays must be half or whole number"
                          );
                        }

                        if (numericValue <= 0 && value) {
                          return Promise.reject(
                            "Budget Mandays must be greater than 0."
                          );
                        }

                        if (numericValue > days) {
                          return Promise.reject(
                            `No of Days must be less than or equal to ${days}`
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder="Enter" min={1} max={days} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Role</span>}
                  name="project_role"
                  rules={[
                    {
                      required: true,
                      message: "Role is required.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select  role"
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={accessRoleOptions.map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    fieldNames={{
                      label: "label",
                      value: "value",
                    }}
                  />
                </Form.Item>
              </Col>

              {/* <Form.Item
                  label={<span style={labelStyle}>%Effort</span>}
                  name="effort"
                  rules={[
                    {
                      required: true,
                      message: "Effort is required.",
                    },
                  ]}
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item> */}
            </Row>
            {schedule.length > 0 && (
              <StyledTable
                showHeader={true}
                columns={[
                  {
                    title: "Date",
                    dataIndex: "date",
                    key: "date",
                    width: "30%",
                    className: "action-status",
                  },
                  {
                    title: "Work hours",
                    dataIndex: "value",
                    key: "value",
                    width: "70%",
                    render: (val) => {
                      let value = parseFloat(
                        (
                          (parseFloat(form?.getFieldValue("person_day")) * 8) /
                          (schedule?.length || 1)
                        ).toFixed(2)
                      );
                      return <span>{!isNaN(value) ? value : "0"} hours</span>;
                    },
                  },
                ]}
                dataSource={schedule}
                pagination={false}
              />
            )}
            <Col span={24}>
              <Form.Item
                label="Justification"
                name="justification"
                rules={[
                  { required: true, message: "Please select a justification" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a justification"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {justificationOptions.map((justification) => (
                    <Option key={justification} value={justification}>
                      {justification}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </div>

          <div className="text-end">
            <Button type="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Proceed
            </Button>
          </div>
        </Form>
      </AddResourceModalWrap>
    </StyledModal>
  );
};

export default AddResourceFromCalender;

const AddResourceModalWrap = styled.div`
  padding: 3%;
  .ant-picker {
    width: 100%;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 5px 10px;
    font-size: 13px;
  }
  .action-status {
    background-color: #f7f7f8;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border: 1px solid #dbdbdb;
  }
`;
