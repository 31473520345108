import { VALIDATE_CHARACTER } from "common/Constants";

export const getEMSRecoedJson = (data) => {
  const EMSRecord = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "EMS Client ID",
              type: "text",
              name: "bdo_emsclientid",
              Placeholder: "",
              viewOnly: true,
            },
            {
                id: 2,
                label: "EMS Manager ID:",
                type: "text",
                name: "bdo_emsmanagerid",
                Placeholder: "-",
                viewOnly: true,
              }
          ],
          [
            {
              id: 3,
              label: "BDO Firm",
              type: "text",
              name: "_bdo_bdofirm_value",
              Placeholder: "-",
              viewOnly: true,
            },
            {
                id: 4,
                label: "Entity Classification",
                type: "text",
                name: "bdo_entityclassification",
                Placeholder: "-",
                viewOnly: true,
              }
          ],
          [
            {
              id: 5,
              label: "Referred by Firm",
              type: "text",
              name: "_bdo_referredbyfirm_value",
              Placeholder: "-",
              viewOnly: true,
            },
            {
                id: 6,
                label: "International referral",
                type: "text",
                name: "bdo_internationalreferral",
                Placeholder: "-",
                viewOnly: true,
              }
          ],
          [
            {
              id: 7,
              label: "",
              type: "div",
              name: "",
              Placeholder: "",
              viewOnly: true,
            },
            {
                id: 8,
                label: "SIC Code",
                type: "text",
                name: "siccode",
                Placeholder: "-",
                viewOnly: true,
              }
          ],
        ],
      },
    ],
  };


  return {
    EMSRecord,
  };
};
