import { every, filter, inRange, some } from "lodash";
import moment from "moment";
import { useCallback } from "react";
import { numberOfDaysBetweenDates } from "utils/Date";

const useResourceFn = (form, bookedEngagementUserList, setDays) => {
  function isScheduleValid(
    generateSchedule,
    bookedStartDate,
    value,
    actualBookedDates
  ) {
    return some(generateSchedule(bookedStartDate, value), (scheduleEntry) => {
      return some(actualBookedDates, (entry) => {
        const startDate = moment(entry?.startDate);
        const endDate = moment(entry?.endDate).add(1, "day");
        return inRange(scheduleEntry?.fullDate, startDate, endDate);
      });
    });
  }

  function isInRange(schedule, filteredArray) {
    return every(schedule, (scheduleEntry) => {
      return some(filteredArray, (entry) => {
        const startDate = moment(entry?.actualBookedStartDate);
        const endDate = moment(entry?.actualBookedEndDate).add(1, "day");
        return inRange(scheduleEntry?.fullDate, startDate, endDate);
      });
    });
  }

  const validateDateRange = () => {
    const endDate = moment(form.getFieldValue("booked_finish_date"));
    const startDate = moment(form.getFieldValue("booked_start_date"));
    if (endDate < startDate) {
      form.setFieldsValue({ booked_finish_date: startDate });
    }
    if (!form.getFieldValue("booked_finish_date")) {
      form.setFieldsValue({ booked_finish_date: startDate });
    }
  };

  const calculateSundays = useCallback((startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    let sundays = 0;
    let currentDay = start.clone();
    while (currentDay.isSameOrBefore(end, "day")) {
      if (currentDay.day() === 0 || currentDay.day() === 6) {
        sundays++;
      }
      currentDay.add(1, "day");
    }
    return sundays;
  }, []);

  const workingdays = useCallback(() => {
    const endDate = moment(form.getFieldValue("booked_finish_date"));
    const startDate = moment(form.getFieldValue("booked_start_date"));
    const totalDay = numberOfDaysBetweenDates(startDate, endDate) + 1;
    const sundayCount = calculateSundays(startDate, endDate);
    const personDayValue = isNaN(totalDay) ? 0 : totalDay - sundayCount;

    setDays(personDayValue)
    // form.setFieldsValue({ person_day: personDayValue });
  }, [form, calculateSundays]);

  const calculateEffort = useCallback(() => {
    const bookedStartDate = form?.getFieldValue("booked_start_date");
    const bookedEndDate = form?.getFieldValue("booked_finish_date");

    const filteredList = filter(bookedEngagementUserList, (item) => {
      const actualStart = moment(item.actualBookedStartDate)
        .startOf("day")
        .valueOf();
      const actualEnd = moment(item.actualBookedEndDate)
        .startOf("day")
        .valueOf();

      const bookedStart = moment(bookedStartDate).startOf("day").valueOf();
      const bookedEnd = moment(bookedEndDate).startOf("day").valueOf();

      console.log({
        bookedStartDate: moment(bookedStartDate).format("YYYY-MM-DD"),
        bookedEndDate: moment(bookedEndDate).format("YYYY-MM-DD"),
        actualStartDate: moment(item.actualBookedStartDate).format(
          "YYYY-MM-DD"
        ),
        actualEndDate: moment(item.actualBookedEndDate).format("YYYY-MM-DD"),
      });

      return (
        (bookedStart >= actualStart && bookedStart <= actualEnd) || // bookedStart is within actual range
        (bookedEnd >= actualStart && bookedEnd <= actualEnd) || // bookedEnd is within actual range
        (bookedStart <= actualStart && bookedEnd >= actualEnd) // booked range covers the actual range
      );
    });

    const effort =
      filteredList?.length === 0
        ? 100
        : Math.floor(100 / (filteredList?.length + 1));

    form.setFieldsValue({ effort: effort });
  }, [bookedEngagementUserList, form]);

  return {
    isScheduleValid,
    isInRange,
    validateDateRange,
    workingdays,
    calculateEffort,
  };
};

export default useResourceFn;
