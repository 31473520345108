import TabsList from "molecules/TabsList";
import React, { useEffect, useState } from "react";
import MyTimeSheet from "./MyTimeSheet";
import PendingTimeSheet from "./PendingTimeSheet";
import DeclinedTimeSheet from "./DeclinedTimeSheet";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getMenuList, getUserInfo, getUserRoleId } from "redux/selectors";
import TimeSheetHistory from "./History";
import {
  Breadcrumb,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Spin,
} from "antd";
import en_GB from "antd/lib/locale-provider/en_GB";
import "moment/locale/en-gb";
import moment from "moment";
import Button from "atoms/Button";
import { generateNiceWeekFromWeek, generateTimesheetWeek } from "./useWeekData";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";

moment.locale("en-gb");

export const ONGOING = "ongoing"; // haven't submitted even once
export const WITHDRAWN = "withdrawn"; // submitted but withdrawn
export const DECLINED = "declined"; // submitted but declined by the manager
export const APPROVED = "approved"; // submitted and approved by the manager
export const PENDING = "pending"; // submitted but not yet approved or declined
export const DRAFT = "draft"; // saved but not submitted
export const TOTAL_WORKING_HOURS = 8;
export const MAX_WORKING_HOURS = 12;
export const MIN_WORKING_HOURS = 8;

const TimeSheet = () => {
  const [tabId, setTabId] = useState("mytimesheet");
  const [showTimesheet, setShowTimesheet] = useState(false);
  const [selectedTimesheetPeriod, setSelectedTimesheetPeriod] = useState(null);
  const [showAddTimeSheet, setShowAddTimeSheet] = useState(false);
  const [duplicateTimesheet, setDuplicateTimesheet] = useState(false);

  const [selectedWeek, setSelectedWeek] = useState(null);
  const [duplicateWeek, setDuplicateWeek] = useState(null);

  const [form] = Form.useForm();

  const cancelAddTimeSheet = () => {
    setShowAddTimeSheet(false);
  };

  const handleClickTimeSheet = (period, duplicateSheet = false) => {
    setSelectedTimesheetPeriod(period);
    duplicateSheet && setDuplicateTimesheet(duplicateSheet);
    setShowTimesheet(true);
    setShowAddTimeSheet(false);
    form.resetFields();
  };

  const handleTabSelect = (id) => {
    setTabId(id);
  };

  const userRoleId = useSelector(getUserRoleId);
  const menuList = useSelector(getMenuList);

  let Menulist = menuList[userRoleId] || Object.values(menuList)[0] || menuList;

  Menulist = Array.isArray(Menulist) ? Menulist : [];

  const haveMyTimeSheetAccess = Menulist.find(
    (item) => item.key === "mytimesheet"
  )?.access;

  const havePendingApprovalAccess = Menulist.find(
    (item) => item.key === "pendingapprovalstimesheet"
  )?.access;

  const haveDeclinedAccess = Menulist.find(
    (item) => item.key === "declinedtimesheet"
  )?.access;

  useEffect(() => {
    if (haveMyTimeSheetAccess) {
      setTabId("mytimesheet");
    } else if (havePendingApprovalAccess) {
      setTabId("pendingapprovalstimesheet");
    } else if (haveDeclinedAccess) {
      setTabId("declinedtimesheet");
    }
  }, [haveMyTimeSheetAccess, havePendingApprovalAccess, haveDeclinedAccess]);

  const items = [
    haveMyTimeSheetAccess && {
      id: "mytimesheet",
      label: "My Timesheet",
      component: (
        <MyTimeSheet
          selectedPeriod={selectedTimesheetPeriod}
          duplicateTimesheet={duplicateTimesheet}
        />
      ),
    },
    havePendingApprovalAccess && {
      id: "pendingapprovalstimesheet",
      label: "Pending Approvals",
      component: <PendingTimeSheet selectedPeriod={selectedTimesheetPeriod} />,
    },
    haveDeclinedAccess && {
      id: "declinedtimesheet",
      label: "Declined",
      component: <DeclinedTimeSheet selectedPeriod={selectedTimesheetPeriod} />,
    },
  ];

  return (
    <TimeSheetWrapper>
      {showTimesheet ? (
        <div>
          <Breadcrumb separator=">">
            <Breadcrumb.Item
              onClick={() => {
                setShowTimesheet(false);
                setSelectedTimesheetPeriod(null);
              }}
            >
              Back
            </Breadcrumb.Item>
            {/* <Breadcrumb.Item>{selectedTimesheet?.period}</Breadcrumb.Item> */}
            <Breadcrumb.Item>Timesheet</Breadcrumb.Item>
          </Breadcrumb>

          <TabsList
            items={items}
            handleTabSelect={handleTabSelect}
            tabId={tabId}
          />
        </div>
      ) : (
        <div>
          <div className="heading">
            <span>Timesheet</span>
            <span>
              <Button
                onClick={() => {
                  setShowAddTimeSheet(true);
                  setSelectedWeek(null);
                  setDuplicateWeek(null);
                  form.resetFields();
                }}
                type="primary"
              >
                {" "}
                Add Timesheet{" "}
              </Button>
            </span>
          </div>

          <TimeSheetHistory handleClickTimeSheet={handleClickTimeSheet} />
        </div>
      )}

      <StyledModal
        open={showAddTimeSheet}
        onCancel={cancelAddTimeSheet}
        title={"Add Timesheet"}
        width={500}
        footer={null}
        closable={true}
        maskClosable={false}
        centered={true}
        destroyOnClose={true}
      >
        <AddTimesheet
          form={form}
          handleClickTimeSheet={handleClickTimeSheet}
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
          duplicateWeek={duplicateWeek}
          setDuplicateWeek={setDuplicateWeek}
        />
      </StyledModal>
    </TimeSheetWrapper>
  );
};

export default TimeSheet;

const AddTimesheet = ({
  form,
  handleClickTimeSheet,
  selectedWeek,
  setSelectedWeek,
  duplicateWeek,
  setDuplicateWeek,
}) => {
  const userInfo = useSelector(getUserInfo);

  const weekStart = (week) => (week ? week.clone().startOf("isoWeek") : null);

  var weekEnd = (week) => (week ? week.clone().endOf("isoWeek") : null);

  const weekPeriod = selectedWeek
    ? generateTimesheetWeek(weekStart(selectedWeek), weekEnd(selectedWeek))
    : null;

  const period = selectedWeek ? generateNiceWeekFromWeek(weekPeriod) : "-";

  const { getTimesheet } = queryKeys;

  const { data: timesheetDataRes, isLoading: selectedTimesheetLoading } =
    useFetch(
      `${getTimesheet.key}-week:${weekPeriod}`,
      `${getTimesheet.url}?week=${weekPeriod}&employeeId=${userInfo.employer_id}`,
      {
        enabled: !!weekPeriod,
      }
    );

  const duplicateWeekPeriod = duplicateWeek
    ? generateTimesheetWeek(weekStart(duplicateWeek), weekEnd(duplicateWeek))
    : false;

  const {
    data: duplicateTimesheetDataRes,
    isLoading: isDuplicateTimesheetLoading,
  } = useFetch(
    `${getTimesheet.key}-week:${duplicateWeekPeriod}`,
    `${getTimesheet.url}?week=${duplicateWeekPeriod}&employeeId=${userInfo.employer_id}`,
    {
      enabled: !!duplicateWeekPeriod,
    }
  );

  const hasTimesheet = timesheetDataRes?.response?.response?.status || false;

  useEffect(() => {
    if (hasTimesheet) {
      setSelectedWeek(null);
      form.resetFields();
      notification.error({
        message: `Timesheet for week ${period} already exists`,
      });
    }
  }, [hasTimesheet, period, form]);

  const handleSubmit = () => {
    if (selectedWeek !== null && period !== "-") {
      const duplicateTimesheet =
        duplicateTimesheetDataRes?.response?.response?.timeSheet;

      if (duplicateTimesheet) {
        handleClickTimeSheet(
          period,
          duplicateTimesheet?.map((item) => {
            item.status = "pending";
            return item;
          })
        );
      } else {
        handleClickTimeSheet(period);
      }

      setSelectedWeek(null);
      setDuplicateWeek(null);
    }
  };

  return (
    <div className="add-timesheet-box">
      <Form
        form={form}
        layout="vertical"
        className="mt-2"
        onFinish={handleSubmit}
      >
        <Spin spinning={selectedTimesheetLoading}>
          <ConfigProvider locale={en_GB}>
            <Form.Item
              label="Select Week"
              name="week"
              rules={[{ required: true, message: "Please select week" }]}
            >
              <DatePicker
                picker="week"
                className="w-100"
                value={selectedWeek}
                onChange={(date) => {
                  setSelectedWeek(date);
                }}
              />
            </Form.Item>
          </ConfigProvider>
        </Spin>

        <Form.Item label="Period">
          <Input value={period} placeholder="Select Period" disabled={true} />
        </Form.Item>

        <ConfigProvider locale={en_GB}>
          <Form.Item label="Duplicate Week" name="duplicateWeek">
            <DatePicker
              picker="week"
              className="w-100"
              value={duplicateWeek}
              onChange={(date) => {
                setDuplicateWeek(date);
              }}
            />
          </Form.Item>
        </ConfigProvider>

        <Button
          htmlType="submit"
          type="primary"
          disabled={selectedTimesheetLoading || isDuplicateTimesheetLoading}
        >
          Add
        </Button>
      </Form>
    </div>
  );
};

export const TimeSheetStatus = ({ status }) => {
  return (
    <TimeSheetStatusWrap>
      {status === "pending" && <span className="status pending">Pending</span>}
      {status === "approved" && (
        <span className="status approved">Approved</span>
      )}
      {status === "declined" && (
        <span className="status declined">Declined</span>
      )}
      {status === "withdrawn" && (
        <span className="status withdrawn">Withdrawn</span>
      )}
    </TimeSheetStatusWrap>
  );
};

const TimeSheetStatusWrap = styled.div`
  .status {
    font-size: 14px;
    font-weight: 500;
  }

  .pending {
    color: #ffa702;
  }
  .approved {
    color: #32b900;
  }
  .declined {
    color: #ff7676;
  }
  .withdrawn {
    color: #ff7676;
  }
`;

const TimeSheetWrapper = styled.div`
  .timesheet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-icon {
      width: 46px;
      height: 46px;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    .right-icon {
      margin-left: -10px;
      width: 46px;
      height: 46px;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
  .date {
    font-weight: 600;
  }
  .copy-icon {
    cursor: pointer;
    margin-left: -4px;
  }
  .submit-button,
  .history-button,
  .add-more,
  .close-button {
    border-radius: 5px;
    margin-bottom: 4px;
    margin-left: 0px;
  }
  .duplicate-week {
    font-size: 14px;
    font-weight: 500;
  }
  .no-timesheet {
    text-align: center;
    margin-top: 1rem;
    color: #bfbfbf;
    font-weight: 600;
    font-size: 1.2rem;
  }
  .current-week {
    background: #f2f6ff;
    padding: 4px 8px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: #e6f0ff;
    }
  }
  .ant-breadcrumb-link {
    cursor: pointer;
  }
  .heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button.ant-btn {
    border-radius: 5px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    .anticon-close.ant-modal-close-icon,
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .ant-modal-header {
    border-bottom: none;
    padding-top: 10px;
  }
  .ant-modal-title {
    font-weight: 500;
    text-align: left;
    color: #1b1b1b;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
  .ant-upload-span {
    cursor: pointer;
  }
  .add-timesheet-box {
    padding: 20px;
    border: 1px solid #e8e8e8;
  }
`;
