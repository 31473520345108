export const getCOICheckProcessJson = (data) => {
  const COICheckProcessForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Has the local COI been Done",
              type: "text",
              name: "bdo_hasthelocalcoibeendone",
              Placeholder: "",
              viewOnly: true,
            },
            {
                id: 2,
                label: "Has the global check been done",
                type: "text",
                name: "bdo_hastheglobalcheckbeendone",
                Placeholder: "",
                viewOnly: true,
              }
          ],
          [
            {
              id: 3,
              label: "Has AML and worldCheck been done",
              type: "text",
              name: "bdo_hasamlandworldcheckbeendone",
              Placeholder: "",
              viewOnly: true,
            },
            {
                id: 4,
                label: "Has the corporate tree been requested",
                type: "text",
                name: "bdo_hasthecorporatetreebeenrequested",
                Placeholder: "",
                viewOnly: true,
              }
          ],
        ],
      },
    ],
  };

  const COICheckProcessFormTwo = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Relationship / Liaison Manager",
              type: "text",
              name: "liaisonmanager",
              Placeholder: "",
              viewOnly: true,
            },
          ],
          [
            {
              id: 2,
              label: "Signature",
              type: "text",
              name: "bdo_signature",
              Placeholder: "",
              viewOnly: true,
            },
          ],
          [
            {
              id: 3,
              label: "Date",
              type: "text",
              name: "bdo_date",
              Placeholder: "DD-MM-YYYY",
              viewOnly: true,
            },
          ],         
        ],
      },
    ],
  };

  const COICheckProcessFormThree = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Client Acceptance Form Completed",
              type: "text",
              name: "bdo_acceptanceofthisclient",
              Placeholder: "",
              viewOnly: true,
            },
            {
                id: 2,
                label: "Client Onboarded",
                type: "text",
                name: "bdo_onboarded",
                Placeholder: "",
                viewOnly: true,
              },
          ],
        ],
      },
    ],
  };
 

  return {
    COICheckProcessForm,
    COICheckProcessFormTwo,
    COICheckProcessFormThree,
  };
};
