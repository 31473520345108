import { apiInstance } from "config/baseUrl";
import { useQueries } from "react-query";

/**
 * Custom hook for making multiple API requests using axios and react-query.
 *
 * @param {Array} queries - An array of query configurations.
 * @returns {Array} - The results of the API requests with additional states.
 */

export const useMultipleFetch = (queries) => {
  const accessToken = window.localStorage.getItem("token");

  const queryConfigs = queries?.map(
    ({ queryKey, url, options, post = false, refetch }) => ({
      queryKey: [queryKey],
      queryFn: async () => {
        return await apiInstance({
          method: post ? "POST" : "GET",
          url: url,
          headers: {
            Tymeplusjwt: accessToken,
          },
          data: post,
        })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
      },
      refetchOnWindowFocus: false,
      refetch,
      ...options,
    })
  );

  return useQueries(queryConfigs);
};

export default useMultipleFetch;
