import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Spin, Form, Select } from "antd";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import UserMonthlyReportDonut from "../organisms/UserMonthlyReportDonut";
import { USER_REPORT } from "common/Constants";
import { CALL_API } from "common/API";
import COLORS from "common/Colors";
import { get, find, includes } from "lodash";
import {
  dateInDetail,
  getMonthDateRange,
  getKenyanDateTime,
  getMySqlDate,
} from "utils/Date";
import { getClientid, getIsCheckedIn } from "redux/selectors";
import { MONTH_LIST, STATUS_CODE } from "common/Constants";
import moment from "moment";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;
const StyledRow = styled(Row)`
  padding: 15px;
  box-shadow: 0px 4px 30px 0px #0000001f;
  border-radius: 8px;
  height: 100%;
  /* margin-bottom: 15px; */
`;
const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 8px;
`;
function UserMonthlyReport({ ClientId, isCheckedIn }) {
  const [loading, setLoading] = useState(false);
  const [totalWorking, setTotalWorking] = useState("");
  const [userReport, setUserReport] = useState([
    {
      type: USER_REPORT.ONTIME,
      value: 0,
      color: COLORS.USER_REPORT_COLOR.ONTIME,
    },
    { type: USER_REPORT.LATE, value: 0, color: COLORS.USER_REPORT_COLOR.LATE },
    {
      type: USER_REPORT.LEAVE,
      value: 0,
      color: COLORS.USER_REPORT_COLOR.LEAVE,
    },
    {
      type: USER_REPORT.ABSENT,
      value: 0,
      color: COLORS.USER_REPORT_COLOR.ABSENT,
    },
    {
      type: USER_REPORT.EARLYEXIT,
      value: 0,
      color: COLORS.USER_REPORT_COLOR.EARLYEXIT,
    },
  ]);

  const [monthList, setMonthList] = useState([]);
  const [form] = Form.useForm();

  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
      // console.log(MONTH_LIST[i])
    }
    setMonthList(month_list);
  };
  const containerRef = useRef(null);

  const getCheckinData = async () => {
    const { month } = form.getFieldsValue();
    const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
      dateInDetail(getKenyanDateTime()).year,
      get(find(MONTH_LIST, { value: month }), "index", 0)
    );
    setLoading(true);
    if (ClientId) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(100);
      const { usersWiseAttendenceDetails, code } = await CALL_API(
        `attendance-report-per-user/${ClientId}`,
        "post",
        {
          startDate: getMySqlDate(startDateOfMonth),
          endDate: getMySqlDate(lastDateOfMonth),
        }
      );
      if (includes(STATUS_CODE.SUCCESS, code)) {
        let dupeLeaveData = Array.from(userReport);
        dupeLeaveData[0].value = get(
          usersWiseAttendenceDetails[0],
          "onTimeCount",
          0
        );
        dupeLeaveData[1].value = get(
          usersWiseAttendenceDetails[0],
          "lateCount",
          0
        );
        dupeLeaveData[2].value = get(
          usersWiseAttendenceDetails[0],
          "leaveCount",
          0
        );
        dupeLeaveData[3].value = get(
          usersWiseAttendenceDetails[0],
          "absentCount",
          0
        );
        dupeLeaveData[4].value = get(
          usersWiseAttendenceDetails[0],
          "earlyCheckOutCount",
          0
        );
        setTotalWorking(get(usersWiseAttendenceDetails[0], "workingDays", 0));
        setUserReport(dupeLeaveData);
      }

      setLoading(false);
    }
  };
  useEffect(() => {
    getCheckinData();
    list();
  }, [form.getFieldValue("month"), ClientId, isCheckedIn]);

  return (
    <StyledRow>
      <Col md={24} sm={24} xs={24} xl={24} ref={containerRef}>
        <StyledForm
          form={form}
          name="approval_form"
          layout="inline"
          initialValues={{
            month: dateInDetail(getKenyanDateTime()).monthName,
          }}
        >
          <StyledTitle>MONTHLY REPORT</StyledTitle>
          <Form.Item
            name="month"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select
              style={{
                width: 100,
              }}
              placeholder="Select..."
              options={monthList}
              onChange={getCheckinData}
              getPopupContainer={() => containerRef.current}
            />
          </Form.Item>
        </StyledForm>
        <Spin spinning={loading}>
          <UserMonthlyReportDonut
            title="MONTHLY REPORT"
            data={userReport}
            workingData={totalWorking}
          />
        </Spin>
      </Col>
    </StyledRow>
  );
}
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  isCheckedIn: getIsCheckedIn(state),
});

export default connect(mapStateToProps, null)(UserMonthlyReport);
