import styled from "styled-components";

const ClientDetailsWrap = styled.div`
  .box {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000b;
    border-radius: 5px;
  }
  .note {
    border-left: 4px solid #c2fae5;
  }
  .text-small {
    font: normal normal normal 12px/15px Lato;
    letter-spacing: 0px;
    color: #777777;
  }
  .main-heading {
    color: #1b1b1b;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 3px;
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .backicon {
    font-size: 12px;
  }
  .px_2 {
    padding: 0 10px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none !important;
  }
  .ant-tabs-nav {
    background-color: #f3fefa;
  }
  .ant-tabs-tab {
    font: normal normal medium 16px/19px Lato;
    letter-spacing: 0px;
    color: #000000;
    padding: 8px;
  }
  .ant-btn.selective_btn {
    margin: 2px;
    padding: 0.2em 0.4em;
    font-size: 0.9em;
    font-weight: 600;
    background: #e8e8e8;
    border: none;
    border-radius: 5px;
  }

  .ant-btn.green_clr_btn {
    margin: 2px;
    padding: 0.2em 0.4em;
    font-size: 0.9em;
    font-weight: 600;
    background: #c2fae5;
    border: none;
    border-radius: 5px;
  }
  .ant-btn.disabled_btn {
    margin: 2px;
    padding: 0.2em 0.4em;
    font-size: 0.9em;
    font-weight: 600;
    background: #d1d1d1;
    color: #938888 !important;
    border: none;
    border-radius: 5px;
  }
  .ant-btn.activeselective_btn {
    margin: 0 1px;
    padding: 0.2em 0.4em;
    font-size: 0.9em;
    font-weight: 600;
    background: #c2fae5;
    border: none;
    border-radius: 5px;
  }
  .ant-btn.selective_btn:hover {
    background: #c2fae5;
  }
  .mx_5 {
    margin: 0 5px !important;
  }

  .margin_top_15 {
    margin-top: 15px;
  }

  .scroll_height {
    max-height: calc(100vh - 330px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
  }
  .scroll_height::-webkit-scrollbar {
    width: 6px;
    margin: 0 3px;
  }

  .email_txt {
    color: #387df0;
    font-style: normal;
    font-weight: medium;
    font-size: 13px;
    line-height: 19px;
  }

  .status_txt {
    text-decoration: underline;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
  .active {
    color: #0eb200;
  }
  .deleted {
    color: #b70707;
  }
  .pending {
    color: #ed8103;
  }
  .copyicon_container {
    width: 19px;
    height: 19px;
    background-color: #c2fae5;
    outline: none;
    border: none;
    margin-left: 5px;
    color: #000;
    display: inline-flex;
    justify-content: center;
    align-item: center;
    border-radius: 2px;
  }
  .copyicon_container svg {
    font-size: 10px;
    margin-top: 3px;
  }
  .client-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .ant-collapse-borderless {
    background: transparent;
  }
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: #fff;
  }

  .ant-collapse-header-text {
    font-weight: medium;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 5px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .client-icon svg {
    width: 45px;
    height: 45px;
  }

  .collapse_form .ant-col-24.ant-form-item-label {
    padding: 0;
  }

  .collapse_form .ant-form-item {
    margin-bottom: 8px !important;
  }

  .divider_border {
    border-bottom: 2px solid #ebebeb;
    width: 100%;
    margin: 10px 0;
  }

  .deleteicon_container {
    width: 25px;
    height: 25px;
    background: #c2fae5;
    float: right;
    border-radius: 3px;
    outline: none;
    border: none;
    padding: 0;
  }

  .deleteicon_container svg {
    width: 20px;
    height: 20px;
  }

  .document_container {
    color: #397ff4;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    padding: 8px;
    background: #f2f2f2;
    margin-top: 10px;
  }
  .left_border {
    border-left: 2px solid #ebebeb;
    /* margin-right: 12px; */
  }
  .activity_box {
    box-shadow: 0px 3px 6px #0000000b;
    border-radius: 5px;
    padding: 10px 12px;
    margin-top: 10px;
    position: relative;
  }

  .activity_box h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
  }

  .text-captilize {
    text-transform: capitalize;
  }

  .activity_box p {
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0px;
  }

  .activity_box .secondary_txt {
    color: #777777;
    text-align: right;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
  }

  .activity_box .ant-btn-link {
    border: none;
    padding: 0px 5px;
  }

  .activity_box .ant-btn-link span {
    color: #397ef3;
    text-decoration: underline;
    font-size: 16px;
    line-height: 19px;
  }

  .activity_box .activesmall_indicator {
    width: 4px;
    height: 19px;
    background: #c2fae5;
    position: absolute;
    top: 10px;
    left: 0;
  }
  
`;

export default ClientDetailsWrap;
