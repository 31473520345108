import axios from "axios";

import { SERVICE_URL, STATUS_CODE, AI_BASE_URL, STAGE_URL, TEMP_URL  } from "common/Constants";

export const CALL_API = async (action, method, payload) => {
  try {
    const resp = await axios({
      method,
      url: `${SERVICE_URL}${action}`,
      data: payload,
      headers: {
        Tymeplusjwt: window.localStorage.getItem("token") || "",
      },
    });
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS || status === true) return response;
    else throw {};
  } catch (err) {
    console.log(err);
    console.log(action);
    return err;
  }
};

export const CALL_FEATCH_API = async (action, method, payload) => {
  try {
    const resp = await axios({
      method,
      url: `${SERVICE_URL}${action}`,
      data: payload,
      headers: {
        Tymeplusjwt: window.localStorage.getItem("token") || "",
      },
    });
    // const { status, response } = resp.data;
     return resp.data;
    
  } catch (err) {
    console.log(err);
    console.log(action);
    return err;
  }
};

export const CALL_API_UPDATED = async (action, method, payload) => {
  try {
    const resp = await axios({
      method,
      url: `${SERVICE_URL}${action}`,
      data: payload,
      headers: {
        Tymeplusjwt: window.localStorage.getItem("token") || "",
      },
    });
    return resp.data

  } catch (err) {
    throw err;
  }
};

export const CALL_AI_API = async (action, method, payload) => {
  try {
    const resp = await axios({
      method,
      url: `${AI_BASE_URL}${action}`,
      data: payload,
      headers: {
        Tymeplusjwt: window.localStorage.getItem("token") || "",
      },
    });
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS || status === true) return response;
    else throw {};
  } catch (err) {
    console.log(err);
    console.log(action);
    return err;
  }
};

export const CALL_API_WITH_HEADER = async (
  action,
  method,
  payload,
  additionalHeaders
) => {
  try {
    const resp = await axios({
      method,
      url: `${SERVICE_URL}${action}`,
      data: payload,
      headers: {
        Tymeplusjwt: window.localStorage.getItem("token") || "",
        ...additionalHeaders,
      },
    });
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS) return response;
    else throw {};
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const UPLOAD_DOCUMENT = async (action, file) => {
  try {
    let data = new FormData();
    data.append("file", file);

    const resp = await axios.post(`${SERVICE_URL}${action}`, data, {
      Tymeplusjwt: window.localStorage.getItem("token") || "",
      "Content-Type": "multipart/form-data",
    });
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS) return response;
    else throw {};
  } catch (err) {
    return err;
  }
};
