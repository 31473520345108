import { Form, Modal } from "antd";
import { initialValues } from "pages/Engagement/AddNewEngagement";
import JobCloserFields from "pages/Engagement/AddNewEngagement/JobCloserFields";
import ResourcePlan from "pages/Engagement/EngagementView/ResourcePlan";
import React from "react";
import styled from "styled-components";

const BillingResourceSummary = ({
  show,
  onClose,
  engagementData,
  clientName,
}) => {
  // console.log("engagementData--->", engagementData);

  const [form] = Form.useForm();

  return (
    <StyledModal
      open={show}
      onCancel={onClose}
      title={
        <TitleRender
          clientName={clientName}
          engagementName={engagementData?.information?.jobTitle || "-"}
        />
      }
      width={1200}
      footer={null}
      closable={false}
    >
      <Form layout="horizontal" form={form} colon={false} requiredMark={false}>
        <JobCloserFields update={true} />

        <div className="pt-2 fw-bold">Resource:</div>

        <ResourcePlan
          engagementData={engagementData}
          tempEngagementFormData={[]}
        />
      </Form>
    </StyledModal>
  );
};

export default BillingResourceSummary;

const TitleRender = ({ clientName, engagementName }) => {
  return (
    <div className="d-flex justify-content-between align-items-center gap-2">
      <div>
        <span className="fw-bold">Client: </span>
        <span>{clientName}</span>
      </div>
      <div>
        <span className="fw-bold">Engagement: </span>
        <span>{engagementName}</span>
      </div>
    </div>
  );
};

const StyledModal = styled(Modal)``;
