import { Col, Form, Row } from "antd";
import { CompetencyRole } from "common/Constants";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "redux/actions";
import { getFilters, getSupervisorList } from "redux/selectors";
import styled from "styled-components";
import { Grade } from "common/Constants";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";

const useEmployeeFilter = ({
  employeeFilterList,
  containerRef,
  managingOfficeData,
  competencyQCData,
  departmentLevelData,
  subservicelineData,
  servicelineData,
  STAFF_LIST,
  menuName,
  showFilters = {
    employee: true,
    managingoffice: true,
    competencyQC: true,
    serviceline: true,
    team: true,
    leveldepartmentname: true,
    competencyrole: true,
    supervisor: true,
    grade: true,
  },
}) => {
  const [form] = Form.useForm();
  const filters = useSelector(getFilters);

  const initialFilter = filters[menuName];

  const supervisorList = useSelector(getSupervisorList);
  const dispatch = useDispatch();

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    dispatch(setFilter(menuName, "employee", values.employee || []));
    dispatch(setFilter(menuName, "supervisor", values.supervisor || []));
    dispatch(
      setFilter(menuName, "competencyrole", values.competencyrole || [])
    );
    dispatch(
      setFilter(menuName, "managingoffice", values.managingoffice || [])
    );

    dispatch(
      setFilter(
        menuName,
        "leveldepartmentname",
        values.leveldepartmentname || []
      )
    );
    dispatch(setFilter(menuName, "competencyQC", values.competencyQC || []));
    dispatch(setFilter(menuName, "serviceline", values.serviceline || []));
    dispatch(setFilter(menuName, "team", values.team || []));
    dispatch(setFilter(menuName, "grade", values.grade || []));

    if (values.competencyQC?.length === 0) {
      form.setFieldsValue({ serviceline: [] });
      form.setFieldsValue({ team: [] });
      form.setFieldsValue({ leveldepartmentname: [] });
    }

    if (values.serviceline?.length === 0) {
      form.setFieldsValue({ team: [] });
      form.setFieldsValue({ leveldepartmentname: [] });
    }

    if (values.team?.length === 0) {
      form.setFieldsValue({ leveldepartmentname: [] });
    }
  };

  const getFilterList = () => {
    if (
      form.getFieldValue("employee")?.length !== 0 ||
      form.getFieldValue("supervisor")?.length !== 0 ||
      form.getFieldValue("grade")?.length !== 0 ||
      form.getFieldValue("competencyrole")?.length !== 0 ||
      form.getFieldValue("managingoffice")?.length !== 0 ||
      form.getFieldValue("leveldepartmentname")?.length !== 0 ||
      form.getFieldValue("competencyQC")?.length !== 0 ||
      form.getFieldValue("serviceline")?.length !== 0 ||
      form.getFieldValue("team")?.length !== 0
    ) {
      return employeeFilterList
        .filter((data) => {
          if (form.getFieldValue("employee")?.length !== 0) {
            return form
              .getFieldValue("employee")
              ?.some((employee) => employee === data?.userid);
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("supervisor")?.length !== 0) {
            return form
              .getFieldValue("supervisor")
              ?.some((supervisor) => supervisor === data?.OfficeSupervisorId);
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("competencyrole")?.length !== 0) {
            return form
              .getFieldValue("competencyrole")
              ?.some((role) => data?.competencyrole?.includes(role));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("managingoffice")?.length !== 0) {
            return form
              .getFieldValue("managingoffice")
              ?.some((office) => data?.managingoffice?.includes(office));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("leveldepartmentname")?.length !== 0) {
            return form
              .getFieldValue("leveldepartmentname")
              ?.some((department) =>
                data?.leveldepartmentname?.includes(department)
              );
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("competencyQC")?.length !== 0) {
            return form
              .getFieldValue("competencyQC")
              .some((qc) => data?.competencyQC?.includes(qc));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("serviceline")?.length !== 0) {
            return form
              .getFieldValue("serviceline")
              ?.some((serviceline) => data?.serviceline?.includes(serviceline));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("team")?.length !== 0) {
            return form
              .getFieldValue("team")
              ?.some((team) => data?.subserviceline?.includes(team));
          }
          return data;
        })
        .filter((data) => {
          if (form.getFieldValue("grade")?.length !== 0) {
            return form
              .getFieldValue("grade")
              ?.some((grade) => data?.grade === grade);
          }
          return data;
        });
    } else {
      return employeeFilterList;
    }
  };

  const iscompetencyQCSelcted = form.getFieldValue("competencyQC")?.length > 0;
  const isServicelineSelected = form.getFieldValue("serviceline")?.length > 0;
  const isTeamSelected = form.getFieldValue("team")?.length > 0;

  const FilterJSX = (
    <StyleWrap>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={handleFormChange}
        initialValues={{ ...initialFilter }}
      >
        <Row gutter={16}>
          {showFilters.managingoffice && (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              xxl={4}
              ref={containerRef}
            >
              <DropdownCheckBox
                name="managingoffice"
                label="Country"
                options={managingOfficeData?.map((value) => ({
                  label: value,
                  value: value,
                }))}
                form={form}
                handleFormChange={handleFormChange}
              />
            </Col>
          )}

          {showFilters.employee && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <DropdownCheckBox
                name="employee"
                label="People"
                options={getFilterList()?.map((item) => ({
                  label: item?.fullname,
                  value: item?.userid,
                }))}
                form={form}
                handleFormChange={handleFormChange}
              />
            </Col>
          )}

          {showFilters.competencyQC && (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              xxl={4}
              ref={containerRef}
            >
              <DropdownCheckBox
                name="competencyQC"
                label="Competency/ QC"
                options={competencyQCData?.map((value) => ({
                  label: value,
                  value: value,
                }))}
                form={form}
                handleFormChange={handleFormChange}
              />
            </Col>
          )}

          {showFilters.serviceline && (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              xxl={4}
              ref={containerRef}
            >
              <DropdownCheckBox
                name="serviceline"
                label="Serviceline"
                options={servicelineData?.map((value) => ({
                  label: value,
                  value: value,
                }))}
                form={form}
                handleFormChange={handleFormChange}
                disabled={!iscompetencyQCSelcted}
              />
            </Col>
          )}

          {showFilters.team && (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              xxl={4}
              ref={containerRef}
            >
              <DropdownCheckBox
                name="team"
                label="Sub Serviceline"
                options={subservicelineData?.map((value) => ({
                  label: value,
                  value: value,
                }))}
                form={form}
                handleFormChange={handleFormChange}
                disabled={!isServicelineSelected}
              />
            </Col>
          )}

          {showFilters.leveldepartmentname && (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              xxl={4}
              ref={containerRef}
            >
              <DropdownCheckBox
                name="leveldepartmentname"
                label="Department"
                options={[...new Set(departmentLevelData)]?.map((value) => ({
                  label: value,
                  value: value,
                }))}
                form={form}
                handleFormChange={handleFormChange}
                disabled={!isTeamSelected}
              />
            </Col>
          )}

          {showFilters.grade && (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              xxl={4}
              ref={containerRef}
            >
              <DropdownCheckBox
                name="grade"
                label="Grade"
                options={Grade?.map((value) => ({
                  label: value,
                  value: value,
                }))}
                form={form}
                handleFormChange={handleFormChange}
              />
            </Col>
          )}

          {showFilters.competencyrole && (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              xxl={4}
              ref={containerRef}
            >
              <DropdownCheckBox
                name="competencyrole"
                label="Competency Role"
                options={CompetencyRole?.map((item) => ({
                  label: item,
                  value: item,
                }))}
                form={form}
                handleFormChange={handleFormChange}
              />
            </Col>
          )}

          {showFilters.supervisor && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <DropdownCheckBox
                name="supervisor"
                label="Office Supervisor"
                options={supervisorList?.map((item) => ({
                  label: item?.name,
                  value: item?.employeeId,
                }))}
                form={form}
                handleFormChange={handleFormChange}
              />
            </Col>
          )}
        </Row>
      </Form>
    </StyleWrap>
  );

  return {
    FilterJSX,
    getFilterList,
  };
};

export default useEmployeeFilter;

const StyleWrap = styled.div`
  .ant-form-item {
    margin-bottom: 16px !important;
  }
`;
