import React, { useState, useEffect } from "react";
import { Form, Switch, Checkbox, Row, Col } from "antd";
import { connect } from "react-redux";
import {
  setBreakTypesList,
  setLeaveCategoryList,
  setRoleList,
} from "redux/actions";
import {
  getLocations,
  getClientid,
  getAllDepartmentList,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import { CALL_API } from "common/API";
import {
  STATUS_CODE,
  VALIDATE_CHARACTER,
  VALIDATE_SYMBOL_CHARACTER,
} from "common/Constants";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import EditSelectTable from "molecules/EditSelectTable";
import TabsList from "molecules/TabsList";
import { getEditLeaveJson } from "JsonFormat/EditLeaveJson";
import FormModal from "./FormModal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const EditLeaves = ({
  isModalVisible,
  closeModal,
  ClientId,
  getLeaveList,
  LeaveDetails,
  saveLeaveCategoryList,
  departmentList,
  locationList,
  viewOnly,
  saveRoleList,
}) => {
  const [form] = Form.useForm();

  const fetchMasterData = async (fetch_master_data) => {
    const { code, leaveCategories, roleList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      saveLeaveCategoryList(leaveCategories);
      saveRoleList(roleList);
    }
  };

  const [data, setData] = useState({});
  useEffect(() => {
    setData({
      leaveCategoryId: LeaveDetails.leaveCategoryId,
      leaveCategoryName: LeaveDetails.leaveCategoryName,
      numberOfLeaves: LeaveDetails.numberOfLeaves,
      eligible: LeaveDetails.eligible,
      holiday: LeaveDetails?.holiday,
      weekend: LeaveDetails?.weekend,
      isSaturday: LeaveDetails?.isSaturday,
    });
  }, [LeaveDetails]);

  const { EditLeaveForm } = getEditLeaveJson(data);
  const [submit, setsubmit] = useState(false);
  const [selectDepartmentId, setSelectDepartmentId] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectAllDepartmentsChecked, setSelectAllDepartmentsChecked] =
    useState(false);
  const [selectAllLocationChecked, setSelectAllLocationChecked] =
    useState(false);

  useEffect(() => {
    form.setFieldsValue({
      leave_category_name: LeaveDetails.leaveCategoryName,
      numberofLeaves: LeaveDetails.numberOfLeaves,
      description: LeaveDetails.description,
      holidays: LeaveDetails?.holiday,
      weekend: LeaveDetails?.weekend,
      isSaturday: LeaveDetails?.isSaturday,
    });
    setSelectedLocationId(LeaveDetails.locationId);
    setSelectDepartmentId(LeaveDetails.departmentId);
  }, [LeaveDetails]);

  useEffect(() => {
    setSelectAllDepartmentsChecked(
      selectDepartmentId?.length === departmentList?.length
    );
    setSelectAllLocationChecked(
      selectedLocationId?.length === locationList?.length
    );
  }, [selectDepartmentId, departmentList, selectedLocationId, locationList]);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setsubmit(true);
  };

  const departmentcolumns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id) => {
        const check = selectDepartmentId?.includes(department_id)
          ? true
          : false;
        return (
          <Switch
            disabled={viewOnly}
            checked={check}
            size="small"
            onChange={(checked) => getDepartmentId(department_id, checked)}
          />
        );
      },
    },
  ];

  const getDepartmentId = (department_id, checked) => {
    const newDepartmentId = checked
      ? [...selectDepartmentId, department_id]
      : selectDepartmentId.filter((item) => item !== department_id);
    setSelectDepartmentId(newDepartmentId);
    setsubmit(true);
  };

  const handleSelectAllDepartmentsChange = (e) => {
    const checked = e.target.checked;
    setSelectAllDepartmentsChecked(checked);
    const allDepartmentIds = departmentList.map((item) => item.department_id);
    setSelectDepartmentId(checked ? allDepartmentIds : []);
    setsubmit(true);
  };

  const locatincolumns = [
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        const check = selectedLocationId?.includes(location_id) ? true : false;
        return (
          <Switch
            disabled={viewOnly}
            size="small"
            checked={check}
            onChange={(checked) => getLocationId(location_id, checked)}
          />
        );
      },
    },
  ];

  const getLocationId = (location_id, checked) => {
    const newLocationId = checked
      ? [...selectedLocationId, location_id]
      : selectedLocationId.filter((item) => item !== location_id);
    setSelectedLocationId(newLocationId);
    setsubmit(true);
  };

  const handleSelectAllLocationChange = (e) => {
    const checked = e.target.checked;
    setSelectAllLocationChecked(checked);
    const allDepaetmentIds = locationList.map((item) => item.location_id);
    setSelectedLocationId(checked ? allDepaetmentIds : []);
    setsubmit(true);
  };

  useEffect(() => {
    if (submit) {
      onFinish();
    }
  }, [selectDepartmentId, selectedLocationId, data]);

  const onFinish = async () => {
    const {
      leaveCategoryId,
      leaveCategoryName,
      numberOfLeaves,
      eligible,
      holiday,
      weekend,
      isSaturday,
    } = data;
    const containsSpecialCharacters =
      !VALIDATE_SYMBOL_CHARACTER.test(leaveCategoryName);
    if (containsSpecialCharacters) {
      return;
    }
    const { code } = await CALL_API(
      `owner-leave/${ClientId}/${leaveCategoryId}`,
      "patch",
      {
        leavecategoryname: leaveCategoryName,
        numberofleaves: numberOfLeaves,
        department: selectDepartmentId,
        location: selectedLocationId,
        eligible: eligible,
        holiday: holiday,
        weekend: weekend,
        isSaturday: isSaturday,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      fetchMasterData();
      getLeaveList();
    }
  };

  const items = [
    {
      id: 1,
      label: "Basic",
      component: (
        <>
          {viewOnly ? (
            <>
              <div className="styled-card">
                <Row className="mb-1">
                  <Col>
                    <b>Leave Category Name</b> : {data.leaveCategoryName}
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <b>Number of Leaves</b> : {data.numberofleaves}
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <b>eligible</b> : {data.eligible}
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="styled-card">
              <GridBuilder
                formStructureJson={EditLeaveForm}
                handleChange={handleFormChange}
                initialValues={LeaveDetails}
              />
            </div>
          )}
        </>
      ),
    },
    {
      id: 2,
      label: "Department",
      component: (
        <>
          {!viewOnly && (
            <div className="mb-3 d-flex justify-content-between">
              {departmentList?.length > 0 && (
                <Checkbox
                  className=""
                  checked={selectAllDepartmentsChecked}
                  onChange={handleSelectAllDepartmentsChange}
                >
                  Select All
                </Checkbox>
              )}
            </div>
          )}
          <EditSelectTable
            columns={departmentcolumns}
            tabledata={departmentList}
            setOpenModal={() => setOpenDepartmentModal(true)}
            Name="Add Department"
          />
          <FormModal
            isModalVisible={openDepartmentModal}
            closeModal={() => setOpenDepartmentModal(false)}
            Name="Department"
          />
        </>
      ),
    },
    {
      id: 3,
      label: "Location",
      component: (
        <>
          {!viewOnly && (
            <div className="mb-3 d-flex justify-content-between">
              {locationList?.length > 0 && (
                <Checkbox
                  className=""
                  checked={selectAllLocationChecked}
                  onChange={handleSelectAllLocationChange}
                >
                  Select All
                </Checkbox>
              )}
            </div>
          )}

          <EditSelectTable
            columns={locatincolumns}
            tabledata={locationList}
            setOpenModal={() => setOpenLocationModal(true)}
            Name="Add Location"
          />
          <FormModal
            isModalVisible={openLocationModal}
            closeModal={() => setOpenLocationModal(false)}
            Name="Location"
          />
        </>
      ),
    },
  ];

  return (
    <>
      {isModalVisible && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => {
              closeModal(false);
            }}
          >
            Leave
            <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className=" pb-0 pt-1">{data.leaveCategoryName}</b>
          <TabsList items={items} form={form} />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  locationList: getLocations(state),
  departmentList: getAllDepartmentList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBreakTypes: setBreakTypesList,
      saveLeaveCategoryList: setLeaveCategoryList,
      saveRoleList: setRoleList,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps, null)(EditLeaves);
