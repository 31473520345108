import UITabs from 'atoms/UITabs'
import React, { useEffect, useState } from 'react'
import JobsList from './JobsList'
import Candidate from './Candidate'
import TalentPool from './TalentPool'
import HiringProcess from './HiringProcess'
import { CALL_API } from 'common/API'
import { STATUS_CODE } from 'common/Constants'
import AddJob from 'pages/AddJob'
import RecruitmentTab from 'pages/RecruitmentTab'
import Onboarding from 'pages/Onboarding/Onboarding'
import OnboardCandidate from './OnboardCandidate'
import DisqualifiedCandidates from './DisqualifiedCandidates'
import { getMenuList, getUserRoleId } from 'redux/selectors'
import { connect } from 'react-redux'



function Recruitment({ userRoleId, menuList }) {

    const [jobList, setJoblist] = useState([]);
    const [candidateList, setCandidateList] = useState([])
    const [processList, setProcessList] = useState([])
    const [talentList, setTalentList] = useState([])
    const [onboardList, setOnboardList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [showComponent, setShowComponent] = useState("tab")
    const [selectedItem, setSelectedItem] = useState({})
    const [activeTab, setActiveTab] = useState(1);
    const [selectedCandidate, setSelectedCandidate] = useState({})
    const [filteredCandidateList, setFilteredCandidateList] = useState([])
    const [index, setIndex] = useState(0);
    const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

    const getDataList = async () => {
        setLoading(true);

        // API for candidate List
        const { data: cadidateData, code: candidateCode } = await CALL_API(`recruitment/candidate`, "get", {});
        if (STATUS_CODE.SUCCESS === candidateCode) {
            setCandidateList(cadidateData);
        }

        // API for job List
        const { data: jobData, code: jobCode } = await CALL_API(`recruitment/jobs`, "get", {});
        if (STATUS_CODE.SUCCESS === jobCode) {
            setJoblist(jobData);
            setSelectedItem()
        }

        // API for Hiring Process List
        const { data: processData, code: processCode } = await CALL_API(`recruitment/hiringprocess`, "get", {});
        if (STATUS_CODE.SUCCESS === processCode) {
            setProcessList(processData);
        }

        // API for talent List
        const { data: talentData, code: talentCode } = await CALL_API(`recruitment/talentpool`, "get", {});
        if (STATUS_CODE.SUCCESS === talentCode) {
            setTalentList(talentData);
        }

        const response = await CALL_API(`recruitment/onboard`, "get", {});
        if (STATUS_CODE.SUCCESS === talentCode) {
            setOnboardList(response);
        }

        setLoading(false);

    }

    const getHiringProcessList = async () => {
        // API for Hiring Process List
        setLoading(true);
        const { data: processData, code: processCode } = await CALL_API(`recruitment/hiringprocess`, "get", {});
        if (STATUS_CODE.SUCCESS === processCode) {
            setProcessList(processData);
        }
        setLoading(false);
    }

    useEffect(() => {
        getDataList()
    }, [])

    useEffect(() => {
        const list = candidateList.filter(candidate => candidate.jobId === jobId);
        setFilteredCandidateList(list)
    }, [candidateList, selectedItem])

    const handleTabChange = (newValue) => {
        console.log("newValue -->", newValue)
        setActiveTab(newValue);
    };




    const items = [
        {
            id: 1,
            label: "Jobs",
            component: <JobsList
                data={jobList}
                loading={loading}
                candidateList={candidateList}
                setShowComponent={setShowComponent}
                getData={getDataList}
                setSelectedItem={setSelectedItem}
                MenuList={Menulist}
            />
        },
        {
            id: 2,
            label: "Candidate",
            component: <Candidate
                data={candidateList}
                loading={loading}
                jobList={jobList}
                processList={processList}
                setShowComponent={setShowComponent}
                getData={getDataList}
                setIndex={setIndex}
                MenuList={Menulist}
            />
        },
        {
            id: 3,
            label: "Hiring Process",
            component: <HiringProcess
                data={processList}
                tableloading={loading}
                getData={getHiringProcessList}
                MenuList={Menulist}
            />
        },
        {
            id: 4,
            label: "Talent Pool",
            component: <TalentPool
                data={talentList}
                setShowComponent={setShowComponent}
                isLoading={loading}
                jobList={jobList}
                getData={getDataList}
                setIndex={setIndex}
                MenuList={Menulist}
            />
        },
        {
            id: 5,
            label: "Onboarded Candidate",
            component: <OnboardCandidate
                data={onboardList}
                setShowComponent={setShowComponent}
                isLoading={loading}
                jobList={jobList}
                getData={getDataList} />
        },
        {
            id: 6,
            label: "Disqualified",
            component: <DisqualifiedCandidates
                data={candidateList}
                loading={loading}
                jobList={jobList}
            />
        }
    ]

    // Assuming selectedItem has a jobId property
    const jobId = selectedItem?.jobId;


    const onboardCandidate = (item) => {
        setSelectedCandidate(item)
    }

    return (
        <div>
            {showComponent === "tab" && (
                <UITabs items={items} activeTab={activeTab} onTabChange={handleTabChange} />
            )}
            {showComponent === "addJob" && (
                <AddJob
                    processList={processList}
                    setShowComponent={setShowComponent}
                    getData={getDataList}
                />
            )}
            {showComponent === "editJob" && (
                <AddJob
                    processList={processList}
                    setShowComponent={setShowComponent}
                    getData={getDataList}
                    selectedItem={selectedItem}
                />
            )}
            {showComponent === "candidateDetails" && activeTab?.toString() === "2" && (
                <>
                    <RecruitmentTab
                        candidateList={candidateList}
                        jobList={jobList}
                        processList={processList}
                        getData={getDataList}
                        goBack={() => setShowComponent("tab")}
                        activeTab={activeTab}
                        setShowComponent={setShowComponent}
                        onboardCandidate={onboardCandidate}
                        index={index}
                        Menulist={Menulist}
                    />
                </>

            )}
            {showComponent === "candidateDetails" && activeTab?.toString() === "4" && (
                <>
                    <RecruitmentTab
                        candidateList={talentList}
                        jobList={jobList}
                        processList={processList}
                        getData={getDataList}
                        goBack={() => setShowComponent("tab")}
                        activeTab={activeTab}
                        setShowComponent={setShowComponent}
                        onboardCandidate={onboardCandidate}
                        index={index}
                        Menulist={Menulist}
                    />
                </>

            )}
            {showComponent === "candidateDetails" && activeTab?.toString() === "1" && (
                <>
                    <RecruitmentTab
                        candidateList={candidateList}
                        jobList={jobList}
                        processList={processList}
                        getData={getDataList}
                        goBack={() => setShowComponent("tab")}
                        activeTab={activeTab}
                        selectedItem={selectedItem}
                        setShowComponent={setShowComponent}
                        onboardCandidate={onboardCandidate}
                        Menulist={Menulist}
                    />
                </>

            )}
            {showComponent === 'onboard' && <Onboarding selectedCandidate={selectedCandidate} goBack={() => setShowComponent("tab")} getData={getDataList} />}

        </div>
    )
}

const mapStateToProps = (state) => ({
    menuList: getMenuList(state),
    userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(Recruitment);
