export const MarketFocusRCEO = [
    [{
      id: 1,
      label: "Face of the regional firm/Cluster - Seat on the high table at every possible forum - Government, Industry, Media, Associations etc",
      type: "checkbox",
      name: "faceOfFirm",
    }],
    [{
      id: 2,
      label: "Excellent internal and external communicator of intent, strategy, value add and superb motivator of high-performance growth, discipline, learning and Development",
      type: "checkbox",
      name: "excellentCommunicator",
    }],
    [{
      id: 3,
      label: "New Business line launch (completion of service lines), Mergers and Acquisitions, geographic expansion etc (organic and inorganic growth)",
      type: "checkbox",
      name: "businessLineLaunch",
    }],
    [{
      id: 4,
      label: "Reputation & Crisis Management – PR and Press Management",
      type: "checkbox",
      name: "reputationCrisisManagement",
    }],
    [{
      id: 5,
      label: "In conjunction with Heads of Service Lines, and in line with guidance from EXCO, develop a strategic plan to develop each Center of Excellence",
      type: "checkbox",
      name: "strategicPlan",
    }],
    [{
      id: 6,
      label: "Generate new solutions or methods to solve complex business problems (size, scope, difficulty, perhaps a multidisciplinary solution) that set the standards as best practices",
      type: "checkbox",
      name: "generateSolutions",
    }],
  ];

  export const MarketFocusMPS = [
    [{
      id: 1,
      label: "Face of the local firm - Seat on the high table at every possible forum - Government, Industry, Media, Associations etc",
      type: "checkbox",
      name: "faceOfLocalFirm",
    }],
    [{
      id: 2,
      label: "New Business line launch (completion of service lines), Mergers and Acquisitions, geographic expansion etc (organic and inorganic growth)",
      type: "checkbox",
      name: "businessLineLaunch",
    }],
    [{
      id: 3,
      label: "Generate new solutions or methods to solve complex business problems (size, scope, difficulty, perhaps a multidisciplinary solution) that set the standards as best practices",
      type: "checkbox",
      name: "generateSolutions",
    }],
  ];

  export const MarketFocusPartner = [
    [{
      id: 1,
      label: "Continually search for opportunities to increase the Firm’s business; build networks and contacts, negotiate fee increases, and persuade decision makers",
      type: "checkbox",
      name: "searchOpportunities",
    }],
    [{
      id: 2,
      label: "Represent the Firm and keep Partners updated on opportunities, competition, and developments in the Audit business",
      type: "checkbox",
      name: "representFirm",
    }],
    [{
      id: 3,
      label: "Proactively prepare and present high-quality proposals to acquire new business and cross-sell services to clients",
      type: "checkbox",
      name: "prepareProposals",
    }],
    [{
      id: 4,
      label: "Prepare newsletters, write articles, and make presentations to educate and inform clients, internal staff, and external prospects",
      type: "checkbox",
      name: "prepareContent",
    }],
    [{
      id: 5,
      label: "Expand value-added services to existing clients and capitalize on opportunities within and across service lines",
      type: "checkbox",
      name: "expandServices",
    }],
    [{
      id: 6,
      label: "Establish and maintain sources of expertise outside the firm to offer a full range of services to clients",
      type: "checkbox",
      name: "maintainExpertise",
    }],
    [{
      id: 7,
      label: "Acceptance and continuance of client relationships and ensure overall responsibility for engagement resources and performance",
      type: "checkbox",
      name: "clientRelationships",
    }],
    [{
      id: 8,
      label: "Overall responsibility on Engagement resources and performance in each assignment in order to ensure that client deadlines are met",
      type: "checkbox",
      name: "clientOverAll",
    }],
    [{
      id: 9,
      label: "Ensure all assigned engagements are delivered within time, quality and cost parameters to client satisfaction, measured as part of client satisfaction survey / feedback",
      type: "checkbox",
      name: "engagementDelivery",
    }],
    [{
      id: 10,
      label: "Negotiate and resolve engagement-related problems, (e.g., deliverables, timing, and fees)",
      type: "checkbox",
      name: "Negotiate",
    }],
    [{
      id: 11,
      label: "Manage a variety of viewpoints (e.g., manage conflicts, tension, stress) effectively to create win-win solutions where possible",
      type: "checkbox",
      name: "manageViewpoints",
    }],
    [{
      id: 12,
      label: "Generate new solutions or methods to solve complex business problems (size, scope, difficulty, perhaps a multidisciplinary solution) that set the standards as best practices",
      type: "checkbox",
      name: "manageNewSolution",
    }],
    [{
      id: 13,
      label: " Proactively provides advice and recommendations to clients and management which reflect thorough understanding of industry, issues, long-term strategy, people and client culture involved and impact the client’s business across a broad range of issues",
      type: "checkbox",
      name: "manageProactively",
    }],
    [{
      id: 13,
      label: "Build reputation as trusted advisor with C-level client staff across industry verticals and market reputation of authority on subject matter issues",
      type: "checkbox",
      name: "managereputation",
    }],
    [{
      id: 14,
      label: " Identify clients that do not meet selectivity standards and take or suggest appropriate action",
      type: "checkbox",
      name: "provideAdvice",
    }],
    [{
      id: 15,
      label: "Identify ways to leverage technology to ensure effective knowledge management and efficient engagement execution",
      type: "checkbox",
      name: "leverageTechnology",
    }],
    [{
      id: 16,
      label: "Engagement responsibilities as set out in the BDO manual",
      type: "checkbox",
      name: "engagementResponsibilities",
    }],
    [{
      id: 17,
      label: "Gauge client satisfaction and define the changes in products and services needed to increase client satisfaction",
      type: "checkbox",
      name: "engagementResponsibilities",
    }],
  ];

  export const MarketFocusManager = [
    [{
      id: 1,
      label: "Prepare and present proposals for submission to acquire new business",
      type: "checkbox",
      name: "prepareProposals",
    }],
    [{
      id: 2,
      label: "Use and continue to build networks and contacts to develop business",
      type: "checkbox",
      name: "buildNetworks",
    }],
    [{
      id: 3,
      label: "Expand value-added services to existing clients by capitalising on opportunities within and across lines of service",
      type: "checkbox",
      name: "expandValueAddedServices",
    }],
    [{
      id: 4,
      label: "Actively network professionally outside of BDO (e.g., mixers, industry associations, community service)",
      type: "checkbox",
      name: "activeNetworking",
    }],
    [{
      id: 5,
      label: "Seize opportunities for new professional contacts when presented",
      type: "checkbox",
      name: "seizeOpportunities",
    }],
    [{
      id: 6,
      label: "Articulate BDO’s value proposition and understand target market/client profile",
      type: "checkbox",
      name: "articulateValueProposition",
    }],
    [{
      id: 7,
      label: "Ensure all assigned engagements are delivered within time, quality and cost parameters to client satisfaction",
      type: "checkbox",
      name: "ensureEngagements",
    }],
    [{
      id: 8,
      label: "Management of multiple engagements for a diverse portfolio of clients concurrently",
      type: "checkbox",
      name: "manageEngagements",
    }],
    [{
      id: 9,
      label: "Review working papers, reports and other relevant documents",
      type: "checkbox",
      name: "reviewDocuments",
    }],
    [{
      id: 10,
      label: "Report to the engagement partner on all major matters concerning assignments",
      type: "checkbox",
      name: "reportToPartner",
    }],
    [{
      id: 11,
      label: "Prepare and share weekly and monthly reports on assignments progress, client satisfaction and invoicing",
      type: "checkbox",
      name: "prepareReports",
    }],
    [{
      id: 12,
      label: "Develop and maintain productive working relationships with clients and assess client satisfaction",
      type: "checkbox",
      name: "maintainClientRelationships",
    }],
    [{
      id: 13,
      label: "Negotiate and resolve engagement-related problems (e.g., deliverables, timing, fees)",
      type: "checkbox",
      name: "negotiateProblems",
    }],
    [{
      id: 14,
      label: "Proactively provide advice and recommendations reflecting a thorough understanding of industry and client needs",
      type: "checkbox",
      name: "provideAdvice",
    }],
    [{
      id: 15,
      label: "Gauge client satisfaction and propose changes in services needed to increase client satisfaction",
      type: "checkbox",
      name: "gaugeClientSatisfaction",
    }],
    [{
      id: 16,
      label: "Generate new solutions or methods to solve complex business problems that set the standards as best practices",
      type: "checkbox",
      name: "generateSolutions",
    }],
    [{
      id: 17,
      label: "Manage a variety of viewpoints effectively to create win-win solutions where possible",
      type: "checkbox",
      name: "manageViewpoints",
    }],
    [{
      id: 18,
      label: "Prepare and implement sales/proposal development activities leading to incremental revenues as per set targets",
      type: "checkbox",
      name: "implementSalesActivities",
    }],
  ];

  export const MarketFocusSenior = [
    [{
      id: 1,
      label: "Explain the Firm's service offerings to a client or other contact",
      type: "checkbox",
      name: "explainServiceOfferings",
    }],
    [{
      id: 2,
      label: "Articulate BDO’s value proposition and understand the Firm's target market/client profile",
      type: "checkbox",
      name: "articulateValueProposition",
    }],
    [{
      id: 3,
      label: "Develop draft proposals clearly and concisely, incorporating proper punctuation and correct usage of grammar",
      type: "checkbox",
      name: "developDraftProposals",
    }],
    [{
      id: 4,
      label: "Responsible to the engagement manager for day-to-day client contact and conduct of audit work",
      type: "checkbox",
      name: "responsibleToEngagementManager",
    }],
    [{
      id: 5,
      label: "Develop and maintain productive working relationships with client staff and assess client satisfaction",
      type: "checkbox",
      name: "maintainClientRelationships",
    }],
    [{
      id: 6,
      label: "Deliver projects in line with scope, approach, methodologies and agreed upon timelines",
      type: "checkbox",
      name: "deliverProjects",
    }],
    [{
      id: 7,
      label: "Promptly respond to client requests",
      type: "checkbox",
      name: "respondToClientRequests",
    }],
    [{
      id: 8,
      label: "Implement client engagements from start to finish",
      type: "checkbox",
      name: "implementClientEngagements",
    }],
    [{
      id: 9,
      label: "Identify areas for improvement needed and recommend to client accordingly (value-adding services) and communicate the same to the engagement manager",
      type: "checkbox",
      name: "identifyImprovements",
    }],
    [{
      id: 10,
      label: "Develop client presentations, reports and other written deliverables clearly and concisely, incorporating proper punctuation and correct usage of grammar",
      type: "checkbox",
      name: "developClientPresentations",
    }],
    [{
      id: 11,
      label: "Undertake engagement responsibilities as set out in the BDO manual",
      type: "checkbox",
      name: "engagementResponsibilities",
    }],
  ];

  export const MarketFocusAssistant = [
    [{
      id: 1,
      label: "Explain the Firm's service offerings to a client or other contact",
      type: "checkbox",
      name: "explainServiceOfferings",
    }],
    [{
      id: 2,
      label: "Articulate BDO’s value proposition and understand the Firm's target market/client profile",
      type: "checkbox",
      name: "articulateValueProposition",
    }],
    [{
      id: 3,
      label: "Assist in developing draft proposals, clearly and concisely, incorporating proper punctuation and correct usage of grammar",
      type: "checkbox",
      name: "assistDraftProposals",
    }],
    [{
      id: 4,
      label: "Follow up on assigned clients for outstanding information as requested by the engagement manager",
      type: "checkbox",
      name: "followUpClients",
    }],
    [{
      id: 5,
      label: "Develop an understanding of processes assigned by conducting interviews and desktop research; Apply appropriate principles and standards to reach and support conclusions related to assigned areas",
      type: "checkbox",
      name: "understandProcesses",
    }],
    [{
      id: 6,
      label: "Research and communicate on current issues relating to different client sectors",
      type: "checkbox",
      name: "researchCurrentIssues",
    }],
    [{
      id: 7,
      label: "Obtain needed information from available client data and conduct appropriate client data analysis",
      type: "checkbox",
      name: "clientDataAnalysis",
    }],
    [{
      id: 8,
      label: "Review client practices within an assigned area of responsibility and refer possible problems and solutions to team leader quickly",
      type: "checkbox",
      name: "reviewClientPractices",
    }],
    [{
      id: 9,
      label: "Promptly respond to client requests",
      type: "checkbox",
      name: "respondToClientRequests",
    }],
    [{
      id: 10,
      label: "Effectively and proactively utilise basic capabilities of technology tools and other firm standard technologies to perform and document work in assigned areas",
      type: "checkbox",
      name: "utiliseTechnologyTools",
    }],
    [{
      id: 11,
      label: "Undertake engagement responsibilities as set out in the BDO manual",
      type: "checkbox",
      name: "engagementResponsibilities",
    }],
    [{
      id: 12,
      label: "Proactively suggest ways to improve own performance/approach to work and client service delivery",
      type: "checkbox",
      name: "suggestImprovements",
    }],
    [{
      id: 13,
      label: "Develop understanding of service offerings within and across lines of service to identify opportunities to meet client needs",
      type: "checkbox",
      name: "understandServiceOfferings",
    }],
    [{
      id: 14,
      label: "Assist the line manager in performing ad hoc related work as assigned",
      type: "checkbox",
      name: "assistLineManager",
    }],
    [{
      id: 15,
      label: "Assist in developing client presentations, reports and other written deliverables clearly and concisely, incorporating proper punctuation and correct usage of grammar",
      type: "checkbox",
      name: "assistClientPresentations",
    }],
  ];


  export const MarketFocusQCManager= [
    [{
      id: 1,
      label: "Apply for awards and recognitions, sponsor/lead award ceremonies, etc.",
      type: "checkbox",
      name: "applyAwards",
    }],
    [{
      id: 2,
      label: "BDO EA website: updating regularly based on customer journey and tracking usage",
      type: "checkbox",
      name: "updateBDOWebsite",
    }],
    [{
      id: 3,
      label: "Brand promotion and communication based on BDO and industry guidelines, including PowerPoint/Word/PDF templates, videos, computer-generated animation, etc.",
      type: "checkbox",
      name: "brandPromotion",
    }],
    [{
      id: 4,
      label: "Cold calling and structured sales calling + Newspaper and procurement database for RFP/RFEOI leads",
      type: "checkbox",
      name: "coldCalling",
    }],
    [{
      id: 5,
      label: "Communication strategy formulation and implementation",
      type: "checkbox",
      name: "communicationStrategy",
    }],
    [{
      id: 6,
      label: "Company brand management: appearance on external websites and social media (WhatsApp, Instagram, Twitter, Facebook, LinkedIn, Yammer groups, etc.), and mainstream media (Newspaper, TV, etc.). Create videos and photo stock for firm promotion",
      type: "checkbox",
      name: "brandManagement",
    }],
    [{
      id: 7,
      label: "Competitor analysis and tracking, and preparing market sizing reports",
      type: "checkbox",
      name: "competitorAnalysis",
    }],
    [{
      id: 8,
      label: "Contact List (attached to Company or Strategic Partner Companies) – Send greeting cards, invites for events, newsletters, thought leadership articles, etc., through email merge",
      type: "checkbox",
      name: "contactList",
    }],
    [{
      id: 9,
      label: "Corporate Social Responsibility (CSR) activities: People helping people achieve their dreams and staff involvement in the same",
      type: "checkbox",
      name: "csrActivities",
    }],
    [{
      id: 10,
      label: "Country Reports: Formatting and regularly delivering after coordinating with experts/colleagues - Tax, compliance, and market outlook, newsletters and newspaper/web articles (using BDO Global Best Practices), publishing on every available channel",
      type: "checkbox",
      name: "countryReports",
    }],
    [{
      id: 11,
      label: "Create Company List (Active, Prospective, Former) + New Audit Risk Classification (PIE, LLC, etc.) + Client industry classification",
      type: "checkbox",
      name: "companyList",
    }],
    [{
      id: 12,
      label: "Doing Business in X Country Free Reports, market outlook, newsletters and newspaper/web articles (using BDO Global Best Practices), publishing on every available channel",
      type: "checkbox",
      name: "businessReports",
    }],
    [{
      id: 13,
      label: "Engagement methodology, Upload Proposal (quality templates - Word and PPT)",
      type: "checkbox",
      name: "engagementMethodology",
    }],
    [{
      id: 14,
      label: "Graphic design: PowerPoint, websites – User Interface/User Experience (UI/UX) and other collaterals",
      type: "checkbox",
      name: "graphicDesign",
    }],
    [{
      id: 15,
      label: "Key News Dissemination from Africa – Internally to BDO Global and to clients",
      type: "checkbox",
      name: "keyNewsDissemination",
    }],
    [{
      id: 16,
      label: "Monitor client satisfaction survey & request for reference letter (post-closure of engagement)",
      type: "checkbox",
      name: "monitorClientSatisfaction",
    }],
    [{
      id: 17,
      label: "Proposal Factory - Make proposals and EOIs",
      type: "checkbox",
      name: "proposalFactory",
    }],
    [{
      id: 18,
      label: "Qualified Vendor certification no., e.g., WB, UN, DFID/FCDO, EU, USAID, etc.",
      type: "checkbox",
      name: "vendorCertification",
    }],
    [{
      id: 19,
      label: "Ready to work in management consulting jobs: Report-writing for Development Advisory, market analysis, strategy consulting",
      type: "checkbox",
      name: "managementConsulting",
    }],
    [{
      id: 20,
      label: "Respond to general mails and questions that come out of website forms",
      type: "checkbox",
      name: "respondToMails",
    }],
    [{
      id: 21,
      label: "Road shows and seminars (online and in-person)",
      type: "checkbox",
      name: "roadShows",
    }],
    [{
      id: 22,
      label: "Social media update: Brand visibility on various platforms such as Facebook, Google rating, Glassdoor, LinkedIn, Blogs/Vlogs",
      type: "checkbox",
      name: "socialMediaUpdate",
    }],
    [{
      id: 23,
      label: "Strategic Partners Company List (incl. CVs, firm profiles, and credentials)",
      type: "checkbox",
      name: "strategicPartnersList",
    }],
    [{
      id: 24,
      label: "Update the firm’s brochures, company profile, posters, banners, etc.",
      type: "checkbox",
      name: "updateBrochures",
    }],
    [{
      id: 25,
      label: "Upload Best Sample reports/deliverables",
      type: "checkbox",
      name: "uploadBestReports",
    }],
  ];

  export const MarketingAssistant = [
    [{
      id: 1,
      label: "Proposal development as guided by peers or superiors",
      type: "checkbox",
      name: "proposalDevelopment",
    }],
    [{
      id: 2,
      label: "Graphic design: PowerPoint, websites – User Interface/User Experience (UI/UX) and other collaterals",
      type: "checkbox",
      name: "graphicDesign",
    }],
    [{
      id: 3,
      label: "Competitor analysis and tracking, and preparing market sizing reports",
      type: "checkbox",
      name: "competitorAnalysis",
    }],
    [{
      id: 4,
      label: "Update the firm’s brochures, company profile, posters, banners, etc.",
      type: "checkbox",
      name: "updateBrochures",
    }],
    [{
      id: 5,
      label: "BDO EA website: Updating regularly based on customer journey and tracking usage",
      type: "checkbox",
      name: "updateBDOWebsite",
    }],
    [{
      id: 6,
      label: "Company brand management: Appearance on external websites and social media (WhatsApp, Instagram, Twitter, Facebook, LinkedIn, Yammer groups, etc.), and mainstream media (Newspaper, TV, etc.). Create videos and photo stock for firm promotion",
      type: "checkbox",
      name: "brandManagement",
    }],
    [{
      id: 7,
      label: "Brand promotion and communication based on BDO and industry guidelines, including PowerPoint/Word/PDF templates, videos, computer-generated animation, etc.",
      type: "checkbox",
      name: "brandPromotion",
    }],
    [{
      id: 8,
      label: "Social media update: Brand visibility on various platforms such as Facebook, Google rating, Glassdoor, LinkedIn, Blogs/Vlogs",
      type: "checkbox",
      name: "socialMediaUpdate",
    }],
    [{
      id: 9,
      label: "Ready to work in Management Consulting jobs: Report-writing for Development Advisory, market analysis, strategy consulting",
      type: "checkbox",
      name: "managementConsulting",
    }],
    [{
      id: 10,
      label: "Strategic Partners Company List (including CVs, firm profiles, and credentials)",
      type: "checkbox",
      name: "strategicPartnersList",
    }],
    [{
      id: 11,
      label: "Update the firm’s brochures, company profile, posters, banners, etc.",
      type: "checkbox",
      name: "updateBrochures",
    }],
    [{
      id: 12,
      label: "Upload Best Sample reports/deliverables",
      type: "checkbox",
      name: "uploadBestReports",
    }],
  ];

  export const MarketinQCManager = [
    [{
      id: 1,
      label: "Participate in top universities career fairs",
      type: "checkbox",
      name: "careerFairs",
    }],
    [{
      id: 2,
      label: "Coordinate senior team members to make high quality presentations at career fairs",
      type: "checkbox",
      name: "careerFairsPresentations",
    }],
    [{
      id: 3,
      label: "Liaise with and maintain good relations with key staff at top universities as key talent sources",
      type: "checkbox",
      name: "universityRelations",
    }],
    [{
      id: 4,
      label: "Motivate and retain key staff who deliver on projects",
      type: "checkbox",
      name: "motivateRetainStaff",
    }],
    [{
      id: 5,
      label: "Develop initiatives that promote BDO values amongst the staff",
      type: "checkbox",
      name: "promoteBDOValues",
    }],
    [{
      id: 6,
      label: "Rally all staff to participate in all firm organized CSR events and meetings",
      type: "checkbox",
      name: "CSRParticipation",
    }],
    [{
      id: 7,
      label: "Ensure that all staff participate in national events from time to time",
      type: "checkbox",
      name: "nationalEvents",
    }],
    [{
      id: 8,
      label: "Ensure that all staff abide by the policies & guidelines",
      type: "checkbox",
      name: "abidePolicies",
    }],
    [{
      id: 9,
      label: "Practice CSR values and protect the environment through self-responsibility",
      type: "checkbox",
      name: "CSRValues",
    }],
  ];

  
  
  
  
  


  
  
  
  


  