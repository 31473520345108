// Common
export const USERNAME = 'USERNAME';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const USER_ROLE = 'USER_ROLE';
export const USER_ROLE_TYPE = 'USER_ROLE_TYPE';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const SET_DEPARTMENT_ID = 'SET_DEPARTMENT_ID';
export const SET_SYSTEM_DATE = 'SET_SYSTEM_DATE';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_BREAK_TYPES = 'SET_BREAK_TYPES';
export const SET_DEPARTMENT_LIST = 'SET_DEPARTMENT_LIST';
export const SET_SUPERVISOR_LIST = 'SET_SUPERVISOR_LIST';
export const SET_CHECK_IN_DATE_TIME = 'SET_CHECK_IN_DATE_TIME';
export const SET_CHECK_OUT_DATE_TIME = 'SET_CHECK_OUT_DATE_TIME';
export const SET_IS_ON_BREAK = 'SET_IS_ON_BREAK';
export const TOGGLE_SUCCESS_MODAL = 'TOGGLE_SUCCESS_MODAL';
export const SET_USER_LIST = 'SET_USER_LIST';
export const TOGGLE_PROCESSING_MODAL = 'TOGGLE_PROCESSING_MODAL';
export const LOADING_DATE_FIRST_TIME = 'LOADING_DATE_FIRST_TIME';
export const LOADING_ROUTE = 'LOADING_ROUTE';
export const SET_LEAVE_CATEGORY_LIST = 'SET_LEAVE_CATEGORY_LIST';
export const SET_PUNCH_ACTIONS = 'SET_PUNCH_ACTIONS';
export const SET_ROLE_LIST = 'SET_ROLE_LIST';
export const SET_USER_ROLE_LIST = 'SET_USER_ROLE_LIST';
export const CLEAR_STORE = 'CLEAR_STORE';
export const SET_IS_CHECKED_IN = 'SET_IS_CHECKED_IN';
export const SET_CHECK_IN_LOCATION_ID = 'SET_CHECK_IN_LOCATION_ID';
export const SET_STATUS_LIST = 'SET_STATUS_LIST';
export const SET_ALL_DEPARTMENT_LIST = 'SET_ALL_DEPARTMENT_LIST';
export const SET_BREAK_ID = 'SET_BREAK_ID';
export const SET_PUNCH_ID = 'SET_PUNCH_ID';
export const SET_SHIFT_TIME_MASTER = 'SET_SHIFT_TIME_MASTER';
export const SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST';
export const SET_WEB_SOCKET_STATUS = 'SET_WEB_SOCKET_STATUS';
export const SET_CHAT_MESSAGE_LIST = 'SET_CHAT_MESSAGE_LIST';
export const SET_CHAT_SELECTED_STAFF_ID = 'SET_CHAT_SELECTED_STAFF_ID';
export const SET_CHAT_MESSAGE_QUEUE = 'SET_CHAT_MESSAGE_QUEUE';
export const SET_UNREAD_MESSAGE_INDICATOR = 'SET_UNREAD_MESSAGE_INDICATOR';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const USER_PROFILE = 'USER_PROFILE';
export const PROFILE_PIC = 'PROFILE_PIC';
export const MENU_LIST = 'MENU_LIST';
export const ACTIVE_MENU_LIST = 'ACTIVE_MENU_LIST';
export const BAND_LIST = 'BAND_LIST';
export const CLIENT_ID = 'CLIENT_ID';
export const CURRENT_PLAN = 'CURRENT_PLAN';
export const TRAIL_PERIOD = 'TRAIL_PERIOD';
export const SUBSCRIPTION_ID = 'SUBSCRIPTION_ID';
export const SUBSCRIPTION_STATUS = 'SUBSCRIPTION_STATUS';
export const USER_MENU_LIST = 'USER_MENU_LIST';
export const AI_CHAT_HISTORY = 'AI_CHAT_HISTORY'
export const SET_CHECK_IN_DEPARTMENT_ID = 'SET_CHECK_IN_DEPARTMENT_ID';
export const CURRENT_AI_MESSAGE_DETAILS = 'CURRENT_AI_MESSAGE_DETAILS';
export const FORM_NAME = 'FORM_NAME';
export const LEVEL_LIST = "LEVEL_LIST"
export const SET_FILTER = "SET_FILTER"
export const SET_ANALYSIS_FILTER = "SET_ANALYSIS_FILTER"
export const SET_CACHE_TIMESHEET = "SET_CACHE_TIMESHEET"
export const ALL_ROLE = "ALL_ROLE";
export const ALL_LOCATION = "ALL_LOCATION";
export const ALL_BREAK = "ALL_BREAK";
export const ALL_COMPETENCY = "ALL_COMPETENCY";
export const ALL_LEAVE = "ALL_LEAVE";
export const ALL_SHIFT = "ALL_SHIFT";
export const ALL_HOLIDAY = "ALL_HOLIDAY";
export const SET_FILTER_CLIENT_DATA = "SET_FILTER_CLIENT_DATA";
export const SET_TEMP_ENGAGEMENT_FORM_DATA = "SET_TEMP_ENGAGEMENT_FORM_DATA";
export const SET_RESOURCE_DATA = "SET_RESOURCE_DATA";
export const SET_STATUS_CODES = "SET_STATUS_CODES";
export const SET_SELECTED_ENGAGEMENT_TAB = "SET_SELECTED_ENGAGEMENT_TAB";
export const SET_MAIN_ENGAGEMENT_TAB = "SET_MAIN_ENGAGEMENT_TAB";
export const SET_CRM_MANAGINGOFFICE_LIST = "SET_CRM_MANAGINGOFFICE_LIST";
