import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  message,
  notification,
} from "antd";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import { ManagingOffice, STATUS_CODE } from "common/Constants";
import { find, inRange, isEmpty, some } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTempEngagementFormData, getUserList } from "redux/selectors";
import styled from "styled-components";
import {
  findWeeksInRange,
  generateSchedule,
  getKenyanDateTime,
  momentDate,
} from "utils/Date";
import useResourceFn from "./useResourceFn";
import { setTempEngagementFormData } from "redux/actions";
import { allowOnlyNumbers } from "utils/functions";

const labelStyle = {
  // width: "120px",
  display: "inline-block",
  textAlign: "start",
};

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;

const AddResourceModal = ({
  show,
  onClose,
  header,
  selectedResource,
  bookedUserList,
  getData,
  engagementData,
  tempEngagementFormData,
}) => {
  const filterUserBookedList = bookedUserList?.filter(
    (user) => user.userId === selectedResource?.userId
  );
  const dispatch = useDispatch();
  const { Option } = Select;
  const [dayHour, setDayHour] = React.useState(0);

  const userList = useSelector((state) => getUserList(state));

  // const actualBookedDates = filterUserBookedList?.map((item) => ({
  //   startDate: item.actualBookedStartDate,
  //   endDate: item.actualBookedEndDate,
  // }));

  //? Temporary fix for Enabling resource booking for the same date
  const actualBookedDates = [];

  const bookedEngagementUserList = bookedUserList?.filter((user) => {
    return user.engagementId === engagementData?.engagementId;
  });

  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      booked_start_date: momentDate(getKenyanDateTime()),
      booked_finish_date: momentDate(getKenyanDateTime()),
    });

    setPersonDay(0);
    setDayHour(0);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      selected_resource: selectedResource?.name,
      partner: find(userList, {
        userid:
          engagementData?.stakeHolder?.partner ??
          tempEngagementFormData?.partner,
      })?.fullname
        ? find(userList, {
            userid:
              engagementData?.stakeHolder?.partner ??
              tempEngagementFormData?.partner,
          })?.fullname
        : "-",
      person_day: null,
      booked_start_date: "",
      booked_finish_date: "",
      project_role: null,
      access_role: null,
      effort: 0,
      remark: "",
      justification: null,
    });
  }, [selectedResource]);

  const plannedStartDate = tempEngagementFormData?.plan_start_date
    ? moment(tempEngagementFormData?.plan_start_date)
    : engagementData?.stakeHolder?.planStartDate
    ? moment(engagementData?.stakeHolder?.planStartDate, "DD-MM-YYYY")
    : null;

  const plannedEndDate = tempEngagementFormData?.plan_start_date
    ? moment(tempEngagementFormData?.plan_end_date)
    : engagementData?.stakeHolder?.planEndDate
    ? moment(engagementData?.stakeHolder?.planEndDate, "DD-MM-YYYY")
    : null;

  const restrictPastDate = (current, element, ranges) => {
    let customDate = moment("2022-08-01").format("YYYY-MM-DD");
    // Check if current date is within the planned start and end date

    if (plannedStartDate && plannedEndDate) {
      if (current < plannedStartDate || current > plannedEndDate) {
        return true; // Disable dates outside the planned start and end date
      }
    }

    if (ranges) {
      for (let range of ranges) {
        if (
          current >= moment(range.startDate) &&
          current <= moment(range.endDate).add(1, "day")
        ) {
          return true; // Disable current date
        }
      }
    }
    if (element === "booked_finish_date") {
      return current < moment(form.getFieldValue("booked_start_date"));
    }

    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  const restrictPastStartDate = (current, ranges) => {
    // Check if current date is within the planned start and end date

    if (plannedStartDate && plannedEndDate) {
      if (current < plannedStartDate || current > plannedEndDate) {
        return true; // Disable dates outside the planned start and end date
      }
    }

    if (ranges) {
      for (let range of ranges) {
        if (
          current >= moment(range.startDate) &&
          current <= moment(range.endDate).add(1, "day")
        ) {
          return true; // Disable current date
        }
      }
    }

    // return current && current < moment().startOf("day");
    return current && current < plannedStartDate;
  };

  const [days, setDays] = useState(0);

  const { isScheduleValid, calculateEffort, validateDateRange, workingdays } =
    useResourceFn(form, bookedEngagementUserList, setDays);

  const schedule = generateSchedule(
    form?.getFieldValue("booked_start_date"),
    form?.getFieldValue("booked_finish_date")
  );

  const justificationOptions = [
    "Availability",
    "Expense/Experience",
    "Subject Matter Expert",
    "Cost/Value",
    "Client Experience/History",
    "Technical/Education Qualification",
  ];

  const accessRoleOptions = [
    { value: "Is Auditor", label: "Is Auditor" },
    { value: "Member", label: "Member" },
  ];
  const updateResourceList = (existingList, newResource) => {
    // Ensure existingList is initialized as an array
    if (!Array.isArray(existingList)) {
      existingList = [];
    }
    // Return a new array with the existing resources and the new resource
    return [...existingList, newResource];
  };

  const onFinish = async (form) => {
    try {
      setLoading(true);
      const startDate = form?.booked_start_date;
      const endDate = form?.booked_finish_date;
      const weeksInRange = findWeeksInRange(startDate, endDate);
      const payload = {
        userId: selectedResource?.userId,
        employeeName: selectedResource?.name,
        employeeId: selectedResource?.employeeId,
        accessRole: form?.access_role,
        projectRole: form?.project_role,
        actualBookedStartDate: moment(form?.booked_start_date).format(
          "YYYY-MM-DD"
        ),
        actualBookedEndDate: moment(form?.booked_finish_date).format(
          "YYYY-MM-DD"
        ),
        engagementId:
          engagementData?.engagementId ?? tempEngagementFormData?.engagement_id,
        engagementName:
          engagementData?.information?.jobTitle ??
          tempEngagementFormData?.job_title,
        clientId: engagementData?.clientId ?? tempEngagementFormData?.client_id,
        effort: tempEngagementFormData?.resourceList
          ? Math.round(
              (1 / (tempEngagementFormData?.resourceList.length + 1)) * 100
            )
          : form?.effort,
        remarks: form?.remark,
        personDay: form?.person_day,
        resourceExtRate: selectedResource?.resourceExtRate,
        status: "Assigned",
        engagementStartDate:
          engagementData?.stakeHolder?.planStartDate ??
          tempEngagementFormData?.plan_start_date,
        engagementEndDate:
          engagementData?.stakeHolder?.planEndDate ??
          tempEngagementFormData?.plan_end_date,
        weekList: weeksInRange,
        justification: form?.justification,
        managingOffice: selectedResource?.country[0],
      };
      if (engagementData) {
        const { code } = await CALL_API(
          `insyts/engagement/resource-plan`,
          "post",
          payload
        );
        setLoading(false);
        if (code === STATUS_CODE.SUCCESS) {
          notification.success({
            message: "Successfully Added",
          });
          getData();
          onClose();
        }
      } else {
        console.log("comming", tempEngagementFormData);
        notification.success({
          message: "Successfully Added",
        });
        const existingResourceList = tempEngagementFormData.resourceList;

        // Assuming formValues.newResources is an array of new resources to add
        const updatedResourceList = updateResourceList(
          existingResourceList,
          payload
        );

        // Update the engagement data with the new resourceList
        const updatedEngagementData = {
          ...tempEngagementFormData,
          resourceList: updatedResourceList,
        };

        // Dispatch the updated engagement data
        dispatch(setTempEngagementFormData(updatedEngagementData));
        setLoading(false);
        onClose();
      }
    } catch (error) {
      message.error(
        "Something went wrong. Please reach out to our technical team."
      );
      setLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const [personDay, setPersonDay] = useState(
    form.getFieldValue("person_day") || 0
  );

  useEffect(() => {
    let value = parseFloat(
      ((parseFloat(personDay) * 8) / (schedule?.length || 1)).toFixed(2)
    );
    setDayHour(value);
  }, [schedule, personDay]);

  return (
    <StyledModal
      title={header}
      visible={show}
      onCancel={() => {
        onClose();
      }}
      width={800}
      footer={[]}
      destroyOnClose={true}
    >
      <AddResourceModalWrap>
        <Form
          layout="vertical"
          onFinish={onFinish}
          colon={false}
          form={form}
          initialValues={{
            booked_start_date: momentDate(getKenyanDateTime()),
            booked_finish_date: momentDate(getKenyanDateTime()),
          }}
        >
          <div className="resource-card">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Partner</span>}
                  name="partner"
                  rules={[
                    {
                      required: false,
                      message: "Partner is required.",
                    },
                  ]}
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Selected Resource</span>}
                  name="selected_resource"
                  rules={[
                    {
                      required: false,
                      message: "Resource is required.",
                    },
                  ]}
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              {/* <Form.Item
                  label={<span style={labelStyle}>Access Role</span>}
                  name="access_role"
                >
                  <Select
                    placeholder="Select"
                    options={accessRoleOptions.map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    fieldNames={{
                      label: "label",
                      value: "value",
                    }}
                  />
                </Form.Item> */}
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Booked Start Date</span>}
                  name="booked_start_date"
                  rules={[
                    {
                      required: true,
                      message: "Booked Start Date is required.",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select Date"
                    disabledDate={(current) =>
                      restrictPastStartDate(current, actualBookedDates)
                    }
                    onChange={() => {
                      validateDateRange();
                      workingdays();
                      calculateEffort();
                    }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Booked Finish Date</span>}
                  name="booked_finish_date"
                  dependencies={["booked_start_date"]}
                  rules={[
                    {
                      required: true,
                      message: "Booked finish date is required.",
                    },
                    ({ getFieldValue }) => ({
                      validator: async (rule, value) => {
                        const bookedStartDate =
                          getFieldValue("booked_start_date");
                        if (bookedStartDate && value) {
                          //                           const isStartDateBeforePastDate = moment(
                          //                             bookedStartDate
                          //                           ).isBefore(moment(), "day");
                          //
                          //                           if (isStartDateBeforePastDate) {
                          //                             return Promise.reject(
                          //                               "Cannot book for past dates."
                          //                             );
                          //                           }
                          // const isWithinRange = isScheduleValid(
                          //   generateSchedule,
                          //   bookedStartDate,
                          //   value,
                          //   actualBookedDates
                          // );
                          // if (isWithinRange) {
                          //   return Promise.reject("Already booked!.");
                          // }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    placeholder="Select Date"
                    disabledDate={(current) =>
                      restrictPastDate(
                        current,
                        "booked_finish_date",
                        actualBookedDates
                      )
                    }
                    onChange={() => {
                      workingdays();
                      calculateEffort();
                    }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span
                  style={{
                    ...labelStyle,
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#5D5F68",
                    marginBottom: "20px",
                  }}
                >
                  Total Booked Mandays: {days}
                </span>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Budget Mandays</span>}
                  name="person_day"
                  rules={[
                    {
                      required: true,
                      message: "Budget Mandays is required.",
                    },
                    {
                      validator: (_, value) => {
                        // Ensure value is a number or 0.5
                        const numericValue = Number(value);

                        const isFloatingNumber = numericValue % 1 !== 0;

                        const floatEnd = numericValue.toString().split(".")[1];

                        if (
                          !Number.isInteger(numericValue) &&
                          !isFloatingNumber
                        ) {
                          return Promise.reject(
                            "Budget Mandays must be a number or half number"
                          );
                        }

                        // if (isFloatingNumber && floatEnd !== "5") {
                        //   return Promise.reject(
                        //     "Budget Mandays must be half or whole number"
                        //   );
                        // }

                        if (numericValue <= 0 && value) {
                          return Promise.reject(
                            "Budget Mandays must be greater than 0."
                          );
                        }

                        if (numericValue > days) {
                          return Promise.reject(
                            `No of Days must be less than or equal to ${days}`
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter"
                    min={0.5}
                    step={0.5}
                    value={personDay}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPersonDay(value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={labelStyle}>Role</span>}
                  name="project_role"
                  rules={[
                    {
                      required: true,
                      message: "Role is required.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select  role"
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={accessRoleOptions.map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    fieldNames={{
                      label: "label",
                      value: "value",
                    }}
                  />
                </Form.Item>
              </Col>

              <Form.Item
                label={<span style={labelStyle}>%Effort</span>}
                name="effort"
                rules={[
                  {
                    required: true,
                    message: "Effort is required.",
                  },
                ]}
                style={{ display: "none" }}
              >
                <Input placeholder="Enter" disabled />
              </Form.Item>
            </Row>
            {schedule.length > 0 && (
              <StyledTable
                showHeader={true}
                columns={[
                  {
                    title: "Date",
                    dataIndex: "date",
                    key: "date",
                    width: "30%",
                    className: "action-status",
                  },
                  {
                    title: "Work hours",
                    dataIndex: "value",
                    key: "value",
                    width: "70%",
                    render: (val) => {
                      // let value = parseFloat(
                      //   (
                      //     (parseInt(form?.getFieldValue("person_day")) * 8) /
                      //     (schedule?.length || 1)
                      //   ).toFixed(2)
                      // );
                      return (
                        <span>{!isNaN(dayHour) ? dayHour : "0"} hours</span>
                      );
                    },
                  },
                ]}
                dataSource={schedule}
                pagination={false}
              />
            )}

            <Col span={24} className="mt-2">
              <Form.Item
                label="Justification"
                name="justification"
                rules={[
                  { required: true, message: "Please select a justification" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a justification"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {justificationOptions.map((justification) => (
                    <Option key={justification} value={justification}>
                      {justification}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </div>

          <div className="text-end">
            <Button type="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Proceed
            </Button>
          </div>
        </Form>
      </AddResourceModalWrap>
    </StyledModal>
  );
};

export default AddResourceModal;

const AddResourceModalWrap = styled.div`
  padding: 3%;
  /* .ant-form-item {
    margin-bottom: 14px;
  } */
  .ant-picker {
    width: 100%;
  }
`;

function isScheduleValid(
  generateSchedule,
  bookedStartDate,
  value,
  actualBookedDates
) {
  return some(generateSchedule(bookedStartDate, value), (scheduleEntry) => {
    return some(actualBookedDates, (entry) => {
      const startDate = moment(entry?.startDate);
      const endDate = moment(entry?.endDate).add(1, "day");
      return inRange(scheduleEntry?.fullDate, startDate, endDate);
    });
  });
}

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 5px 10px;
    font-size: 13px;
  }
  .action-status {
    background-color: #f7f7f8;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border: 1px solid #dbdbdb;
  }
`;
