import { Col, Row } from "antd";
import { getGeneralInformationJson } from "JsonFormat/ClientBasicJson/GeneralInformation";
import React, { useState } from "react";
import GridBuilder from "UIBuilderEngine/GridBuilder";

function GeneralInformation({filteredData}) {


  const [initialData, setInitialData] = useState({
    relationshipliaisonmanager: filteredData?.["_bdo_relationship_value@OData.Community.Display.V1.FormattedValue"] || "-",
    relationshipliaisonmanageremail: filteredData?.["emailaddress2"] || "-",
    bdo_relationshipliaisonmanagersrole: filteredData?.["bdo_relationshipliaisonmanagersrole@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_relationshipliaisonmanagerid: filteredData?.bdo_relationshipliaisonmanagerid || "-",
    bdo_othersideentitynamealiasname: filteredData?.bdo_othersideentitynamealiasname || "-",
    bdo_entitytype: filteredData?.["bdo_entitytype@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_principlelawyer: filteredData?.bdo_principlelawyer || "-",
    bdo_secretory: filteredData?.bdo_secretory || "-",
    bdo_principlebanker: filteredData?.bdo_principlebanker|| "-",
    bdo_bcclientid: filteredData?.bdo_bcclientid || "-",
    bdo_size: filteredData?.["bdo_size@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_riskclassification: filteredData?.["bdo_riskclassification@OData.Community.Display.V1.FormattedValue"] || "-",
    _bdo_countryofincorporation_value: filteredData?.["_bdo_countryofincorporation_value@OData.Community.Display.V1.FormattedValue"] || "-",
    _bdo_domicile_value: filteredData?.["_bdo_domicile_value@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_reviewcycleperiod: filteredData?.["bdo_reviewcycleperiod@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_inactivationreason: filteredData?.bdo_inactivationreason || "-",
    bdo_publicinterestentity: filteredData?.["bdo_publicinterestentity@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_typeofpie: filteredData?.["bdo_typeofpie@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_regulator: filteredData?.["bdo_regulator@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_status: filteredData?.["statuscode@OData.Community.Display.V1.FormattedValue"] || "-",
    marketingonly: filteredData?.lastusedincampaign|| "-",
    sendmarketingmaterials:  filteredData?.["donotsendmm@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_businessrelationship: filteredData?.["bdo_businessrelationship@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_strategicpartner: filteredData?.["bdo_strategicpartner@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_br_flag_value: filteredData?.["bdo_br_flag_value@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_referenceoptional: filteredData?.bdo_referenceoptional || "-",
    bdo_businessdesignationtype: filteredData?.["bdo_businessdesignationtype@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_designationtemplate: filteredData?.["bdo_designationtemplate@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_designationlevellocation_hierarchy: filteredData?.["bdo_designationlevellocation_hierarchy@OData.Community.Display.V1.FormattedValue"]|| "-",
    bdo_createddate: filteredData?.bdo_createddate || "-",
    bdo_removalarchivedate: filteredData?.bdo_removalarchivedate || "-",
    bdo_removalarchivereason: filteredData?.bdo_removalarchivereason || "-",
    contactmethod: filteredData?.["preferredcontactmethodcode@OData.Community.Display.V1.FormattedValue"] || "-",
    email: filteredData?.emailaddress1,
    bulkemail: filteredData?.["donotbulkemail@OData.Community.Display.V1.FormattedValue"] || "-",
    followemail: filteredData?.["followemail@OData.Community.Display.V1.FormattedValue"] || "-",
    phone: filteredData?.address1_telephone2 || "-",
    mail: filteredData?.emailaddress2 || "-",
    classificationtype: filteredData?.["bdo_classificationtype@OData.Community.Display.V1.FormattedValue"] || "-",
    bdo_reviewduedate: filteredData?.bdo_reviewduedate || "-"
  });

  const {
    GeneralInformationForm,
    GeneralInformationFormTwo,
    GeneralInformationFormThree,
    GeneralInformationFormFour,
    GeneralInformationFormFive,
  } = getGeneralInformationJson(initialData);

  return (
    <div className="scroll_height" >
    <Row gutter={20}>
      <Col sm={24} md={8} lg={8} xl={8}>
      <div className="box p-1 px-3 mb-3">
        <GridBuilder
          formStructureJson={GeneralInformationForm}
          // handleChange={handleFormChange}
          initialValues={initialData}
        />
        </div>
      </Col>
      <Col sm={24} md={8} lg={8} xl={8}>
      <div className="box p-1 px-3 mb-3">
      <GridBuilder
          formStructureJson={GeneralInformationFormTwo}
          // handleChange={handleFormChange}
          initialValues={initialData}
        />
        </div>
        <div className="box p-1 px-3 mb-3">
            <h6>Marketing</h6>
        <GridBuilder
          formStructureJson={GeneralInformationFormThree}
          // handleChange={handleFormChange}
          initialValues={initialData}
        />
        </div>
      </Col>
      <Col sm={24} md={8} lg={8} xl={8}>
      <div className="box p-1 px-3 mb-3">
      <h6>Business Relationship</h6>
      <GridBuilder
          formStructureJson={GeneralInformationFormFour}
          // handleChange={handleFormChange}
          initialValues={initialData}
        />
        </div>
        <div className="box p-1 px-3 mb-3">
        <h6>Contact Preferences</h6>
        <GridBuilder
          formStructureJson={GeneralInformationFormFive}
          // handleChange={handleFormChange}
          initialValues={initialData}
        />
        </div>
      </Col>
    </Row>
    </div>
  );
}

export default GeneralInformation;
