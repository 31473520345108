import { getClientOnboardingJson } from "JsonFormat/ClientOnBoard/ClientOnboarding";
import moment from "moment";
import React from "react";
import GridBuilder from "UIBuilderEngine/GridBuilder";

function ClientOnboarding({data}) {

  const initialData = ({
    bdo_thefirmisqualifiedandiseligibleinca: data?.["bdo_thefirmisqualifiedandiseligibleinca@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_firmsrulesandproceduresasdescribedintheb: data?.["bdo_firmsrulesandproceduresasdescribedintheb@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_theprospectiveclientwithpartnersandmanage: data?.["bdo_theprospectiveclientwithpartnersandmanage@OData.Community.Display.V1.FormattedValue"] || "",
    reviewprospectiveclientfinancialstatements: data?.["bdo_theprospectiveclientsfs@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_theprospectiveclientwithpartnersandmanage: data?.["bdo_theprospectiveclientwithpartnersandmanage@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_iftheaboveproceduresareinconclusivecontac: data?.bdo_iftheaboveproceduresareinconclusivecontac || "",
    bdo_theprospectiveclientsreputationbeendiscu: data?.["bdo_theprospectiveclientsreputationbeendiscu@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_prospectiveclientsreputation: data?.["bdo_prospectiveclientsreputation@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_othermemberfirmsacceptanceprocedurescon: data?.["bdo_othermemberfirmsacceptanceprocedurescon@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_theprospectiveclienttoauthorizethepreviou: data?.["bdo_theprospectiveclienttoauthorizethepreviou@OData.Community.Display.V1.FormattedValue"] || "",
    reasonForNotAcceptingEngagement:  data?.["bdo_theprospectiveclientsfs@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_contactwithpreviousauditortodiscussmatte: data?.["bdo_contactwithpreviousauditortodiscussmatte@OData.Community.Display.V1.FormattedValue"] || "",
    reasonForChangingAuditors: data?.["bdo_heprospectiveclient@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_thepreviousauditorsworkingpapersasrelevan: data?.["bdo_thepreviousauditorsworkingpapersasrelevan@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_appropriatepartnersandstaffareavailableto: data?.["bdo_appropriatepartnersandstaffareavailableto@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_firmhasorcanobtainanyspecialistexpertise: data?.["bdo_firmhasorcanobtainanyspecialistexpertise@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_theagreedprincipalbasisonwhichthefeesshal: data?.["bdo_theagreedprincipalbasisonwhichthefeesshal@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_firmindependentwithrespecttotheclientrega: data?.["bdo_firmindependentwithrespecttotheclientrega@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_incaseofaprobonoengagement: data?.["bdo_incaseofaprobonoengagement@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_clientstypeofbusinessandbriefdetailsabout: data?.["bdo_clientstypeofbusinessandbriefdetailsabout@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_arethereanynegativeconcernsabouttheprospe: data?.["bdo_arethereanynegativeconcernsabouttheprospe@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_istheprospectiveclientpubliclyheldorexpec: data?.["bdo_istheprospectiveclientpubliclyheldorexpec@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_managementsattitudetocontrolsystemsitspla: data?.["bdo_managementsattitudetocontrolsystemsitspla@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_theapparentcompetenceofaccountingfinancia: data?.["bdo_theapparentcompetenceofaccountingfinancia@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_hastheprospectiveclientoranyofitsdirector: data?.["bdo_hastheprospectiveclientoranyofitsdirector@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_istheresubstantialdoubtconcerningtheprosp: data?.["bdo_istheresubstantialdoubtconcerningtheprosp@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_recentcontinuityofmanagementanditsperceiv: data?.["bdo_recentcontinuityofmanagementanditsperceiv@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_thefinancialneedsofthecompanyanditsabilit: data?.["bdo_thefinancialneedsofthecompanyanditsabilit@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_therapidityofgrowthinrecentyearsandtheso: data?.["bdo_therapidityofgrowthinrecentyearsandtheso@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_hastheprospectiveclientscharacterintegrit: data?.["bdo_hastheprospectiveclientscharacterintegrit@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_arewesatisfiedthatthereisnolitigationtaki: data?.["bdo_arewesatisfiedthatthereisnolitigationtaki@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_canweconfirmthatnopartnersorstaffengagedi: data?.["bdo_canweconfirmthatnopartnersorstaffengagedi@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_canweconfirmthatanypartnerorcloselyconnec: data?.["bdo_canweconfirmthatanypartnerorcloselyconnec@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_arewesatisfiedthattherearenolimitationsof: data?.["bdo_arewesatisfiedthattherearenolimitationsof@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_arewesatisfiedthattherearenolimitationsof: data?.["bdo_arewesatisfiedthattherearenolimitationsof@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_arewesatisfiedthattherearenootherfactorsw: data?.["bdo_arewesatisfiedthattherearenootherfactorsw@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_havewesatisfiedourselvesthattherewerenoot: data?.["bdo_havewesatisfiedourselvesthattherewerenoot@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_listedonastockexchange: data?.["bdo_listedonastockexchange@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_anticipatingapublicofferingwithinthenextt: data?.["bdo_anticipatingapublicofferingwithinthenextt@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_couldbesubjectofabuyoutortakeoverwithint: data?.["bdo_couldbesubjectofabuyoutortakeoverwithint@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_involvedinhightechemergingorheavilyhighly: data?.["bdo_involvedinhightechemergingorheavilyhighly@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_publicinterestentity: data?.["bdo_publicinterestentity@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_aggressiveaccountingpoliciesundueemphasis: data?.["bdo_aggressiveaccountingpoliciesundueemphasis@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_complexaccountingprinciples: data?.["bdo_complexaccountingprinciples@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_continuinglossesandorexperiencingliquidit: data?.["bdo_continuinglossesandorexperiencingliquidit@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_unrealisticreportingdeadlines: data?.["bdo_unrealisticreportingdeadlines@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_poordebtposition: data?.["bdo_poordebtposition@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_domineeringchiefexecutivemanagement: data?.["bdo_domineeringchiefexecutivemanagement@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_weakinternalcontrolsandpoormanagement: data?.["bdo_weakinternalcontrolsandpoormanagement@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_engagesinoffshoretransactionsintaxhavenso: data?.["bdo_engagesinoffshoretransactionsintaxhavenso@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_hasforeignsubsidiariesaffiliates: data?.["bdo_hasforeignsubsidiariesaffiliates@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_clientmanagementreputationsullied: data?.["bdo_clientmanagementreputationsullied@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_disputesunresolvedissueswithpredecessorau: data?.["bdo_disputesunresolvedissueswithpredecessorau@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_isevasiveorabusivetobdoeastafricastaffdur: data?.["bdo_isevasiveorabusivetobdoeastafricastaffdur@OData.Community.Display.V1.FormattedValue"] || "",
    relationship: data?.["_bdo_relationship_value@OData.Community.Display.V1.FormattedValue"] || "",
    bdo_signature: data?.bdo_signature || "",
    riskManagementPartner: data?.bdo_date || "",
  });
  
  

  const {
    ELIGIBILITYANDINDEPENDENCE,
    PROSEPCTIVECLIENTSREPUTATION,
    IncasesofInternationalReferrals,
    PREVIOUSAUDITORS,
    ABILITYTOSERVETHECLIENT,
    Fees,
    INVESTIGATIONOFINDUSTRYBACKGROUNDANDOTHERRISKFACTORS,
    LITIGATION,
    CLOSERELATIONSHIPS,
    FINANCIALINTEREST,
    CONFLICTSOFINTEREST,
    LIMITATIONOFSCOPE,
    OTHERINDEPENDENCEISSUES,
    RiskRatingForm,
    CONCLUSIONANDACCEPTANCEAPPROVAL,
  } = getClientOnboardingJson();
  return (
    <div className="mt-2 scroll_height">
      <div className="box p-2 px-3 mb-3">
        <h6>ELIGIBILITY AND INDEPENDENCE</h6>
        <GridBuilder formStructureJson={ELIGIBILITYANDINDEPENDENCE} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>THE PROSEPCTIVE CLIENT’S REPUTATION</h6>
        <GridBuilder formStructureJson={PROSEPCTIVECLIENTSREPUTATION} initialValues={initialData} />
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>In cases of International Referrals</h6>
        <GridBuilder formStructureJson={IncasesofInternationalReferrals} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>PREVIOUS AUDITORS – for new Audit and assurance clients only</h6>
        <GridBuilder formStructureJson={PREVIOUSAUDITORS} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>
          ABILITY TO SERVE THE CLIENT Is the firm able to comply with the
          following
        </h6>
        <GridBuilder formStructureJson={ABILITYTOSERVETHECLIENT} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>Fees</h6>
        <GridBuilder formStructureJson={Fees} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>
          G INVESTIGATION OF INDUSTRY BACKGROUND AND OTHER RISK FACTORS,Note
          below the results of investigation into the following factors in
          evaluating the potential business risk to the firm
        </h6>
        <GridBuilder
          formStructureJson={
            INVESTIGATIONOFINDUSTRYBACKGROUNDANDOTHERRISKFACTORS
          }
          initialValues={initialData}
        />
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>LITIGATION</h6>
        <GridBuilder formStructureJson={LITIGATION} initialValues={initialData} />
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>CLOSE RELATIONSHIPS</h6>
        <GridBuilder formStructureJson={CLOSERELATIONSHIPS} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>FINANCIAL INTEREST</h6>
        <GridBuilder formStructureJson={FINANCIALINTEREST} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>CONFLICTS OF INTEREST</h6>
        <GridBuilder formStructureJson={CONFLICTSOFINTEREST} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>LIMITATION OF SCOPE</h6>
        <GridBuilder formStructureJson={LIMITATIONOFSCOPE} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>OTHER INDEPENDENCE ISSUES</h6>
        <GridBuilder formStructureJson={OTHERINDEPENDENCEISSUES} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>Risk Rating Form</h6>
        <GridBuilder formStructureJson={RiskRatingForm} initialValues={initialData}/>
      </div>
      <div className="box p-2 px-3 mb-3">
        <h6>
          CONCLUSION AND ACCEPTANCE APPROVAL I have performed the firm’s
          required acceptance procedures, as indicated above and based on these
          procedures, I approve the acceptance/non-acceptance of this client
        </h6>
        <GridBuilder formStructureJson={CONCLUSIONANDACCEPTANCEAPPROVAL} initialValues={initialData}/>
      </div>
    </div>
  );
}

export default ClientOnboarding;
