import { VALIDATE_CHARACTER } from "common/Constants";

export const EligibleList = ["Both", "Male", "Female"];
const DoYouWant = ["Yes", "No"];

export const getAddLeaveJson = (data, alldatas) => {
  const AddLeaveForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          // Row 1
          [
            {
              id: 1,
              label: "Leave Name",
              type: "text",
              name: "leave_category_name",
              Placeholder: "Leave Name",
              value: data["leave_category_name"],
              validationRules: [
                {
                  required: true,
                  message: `Leave Name is required`,
                },
                {
                  validator: (rule, value) => {
                    const VALIDATE_CHARACTER = /^[a-zA-Z\s]*$/; // Example pattern: only letters and spaces
                    if (value && !VALIDATE_CHARACTER.test(value)) {
                      return Promise.reject("Leave Name should not contain special characters");
                    }
                    return Promise.resolve();
                  },
                },
                {
                  validator: (rule, value) => {
                    if (alldatas.some((data) => data.leave_category_name === value)) {
                      return Promise.reject("Leave Name already exists");
                    }
                    return Promise.resolve();
                  },
                },
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Number of Days",
              type: "number",
              name: "number_of_leaves",
              Placeholder: "Number of Leaves",
              value: data["number_of_leaves"],
              validationRules: [
                {
                  required: true,
                  message: `Number of Days is required`,
                },
              ],
            },
          ],
          [
            {
              id: 3,
              label: "Eligible",
              type: "radio",
              name: "eligible",
              Placeholder: "Select",
              value: "",
              options: EligibleList,
              validationRules: [
                {
                  required: true,
                  message: `Eligible is required`,
                },
              ],
            },
          ],
          [
            {
              id: 11,
              label: "Do you want to include Saturday in this leave?",
              type: "radio",
              name: "isSaturday",
              Placeholder: "Select",
              value: "",
              options: DoYouWant,
              validationRules: [
                {
                  required: true,
                  message: `This field is required`,
                },
              ],
            },
          ],
          [
            {
              id: 4,
              label: "Do you want to include Sundays in this leave?",
              type: "radio",
              name: "weekend",
              Placeholder: "Select",
              value: "",
              options: DoYouWant,
              validationRules: [
                {
                  required: true,
                  message: `This field is required`,
                },
              ],
            },
          ],
          [
            {
              id: 5,
              label: `Do you want to include the "Holidays" you provide in this leave?`,
              type: "radio",
              name: "holiday",
              Placeholder: "Select",
              value: "",
              options: DoYouWant,
              validationRules: [
                {
                  required: true,
                  message: `This field is required`,
                },
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Next",
              type: "button",
              position: "end",
              // background: "Orange",steps
              textColor: "white",
              event: "submit",
            },
          ],
        ],
      },
    ],
  };

  return {
    AddLeaveForm,
  };
};
