import React from "react";
import ReusableTable from "./ReusableTable";
import GridBuilder from "UIBuilderEngine/GridBuilder";

function SupervisorAssessment() {
  const BasicForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Name:",
              type: "text",
              name: "name",
              Placeholder: "",
              //   value: data["rolename"],
            },
            {
              id: 2,
              label: "Staff Level:",
              type: "text",
              name: "stafflevel",
              Placeholder: "",
            },
          ],
          [
            {
              id: 3,
              label: "Firm:",
              type: "text",
              name: "firm",
              Placeholder: "",
              //   value: data["rolename"],
            },
            {
              id: 4,
              label: "Organisational Level:",
              type: "text",
              name: "organisationallevel",
              Placeholder: "",
            },
          ],
          [
            {
              id: 3,
              label: "Year:",
              type: "text",
              name: "year",
              Placeholder: "",
              //   value: data["rolename"],
            },
            {
              id: 4,
              label: "Competency/Quality Circle:",
              type: "text",
              name: "competency",
              Placeholder: "",
            },
          ],
          [
            {
              id: 5,
              label: "Period:",
              type: "text",
              name: "period",
              Placeholder: "",
              //   value: data["rolename"],
            },
            {
                id: 5,
                label: "",
                type: "div",
                name: "",
                Placeholder: "",
                //   value: data["rolename"],
              },
          ],
        ],
      },
    ],
  };
  const tempdata4 = [
    {
        Pillars: "Additional Roles",
        Activity: "Assist in the investigation of significant suspected fraudulent activities and notify management of the results",
        weight: "0",
    },
    {
        Pillars: "QRC",
        Activity: "0% system deficiencies, without effective remedial action.",
        weight: "0",
    },
    {
        Pillars: "Leadership /Employee Excellence & BDO Relationships",
        Activity: ">95% Hierarchical development of staff in the service line",
        weight: "0",
    },
    {
        Pillars: "Leadership /Employee Excellence & BDO Relationships",
        Activity: ">95% Hierarchical development of staff in the service line",
        weight: "0",
    },
    {
        Pillars: "Operational Excellence",
        Activity: ">95% achievement of measurable targets on employee scorecard",
        weight: "0",
    },
    {
        Pillars: "Market Focus/ Client Centricity",
        Activity: ">90% client satisfaction survey / feedback",
        weight: "0",
    },
    {
        Pillars: "Growth & Profitability",
        Activity: "% of growth in clients / No. of new clients",
        weight: "0",
    },
    {
        Pillars: "Total",
        Activity: "",
        weight: "0",
    },
    
  ];
  const TechnicalAssessmentColumns1 = [
    {
      title: "Pillars",
      dataIndex: "Pillars",
      key: "Pillars",
      width: "30%",
    },
    {
      title: "Activity",
      dataIndex: "Activity",
      key: "Activity",
      width: "50%",
      className: "normal-column",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "20%",
      className: "normal-column",
    },
  ];

  const CommentForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Self Overall Comment:",
              type: "text",
              name: "comment",
              Placeholder: "",
              //   value: data["rolename"],
            },
          ],
          [
            {
                id: 2,
                label: "Close",
                type: "button",
                position: "start",
                textColor: "white",
                event: "submit",
              },
          ]
        ],
      },
    ],
  };

  const TechnicalAssessmentData = [
    {
      Description:
      <><h6 className="mb-0 pb-0">Value & Efficiency:</h6><p> Knowledgeable and well prepared to handle the allocated work. The methodology and software used was appropriate. Conclusions reached were useful, realistic and adequately supported by relevant facts and thorough analysis.</p></>,
      weight: "20%",
      selfscore: "5",
      supervisorscore: "6",
      weightedscore: "24%",
    },
    {
      Description:
      <><h6>Timely Delivery:</h6> <p>The assignment / tasks / sections were completed on a timely basis. The timeliness of response to phone calls/e-mails showed care and good communication skills. No undue disruption to workplace during the assignment.</p></>,
      weight: "20%",
      selfscore: "5",
      supervisorscore: "6",
      weightedscore: "24%",
    },
    {
      Description:
      <><h6>Quality, Compliance & Risk:</h6> <p>Quality and Risks were properly managed and with compliance to methodology, tools and processes was as per expectation. Also, safeguarding of documents and work environment and staff commitments were respected.</p></>,
      weight: "20%",
      selfscore: "5",
      supervisorscore: "6",
      weightedscore: "20%",
    },
    {
      Description:
      <><h6>Client satisfaction:</h6> <p>Demonstrated professionalism and the handling of client, staff and supervision of the job was impeccable. Both internal and external stakeholders were satisfied with the delivery.</p></>,
      weight: "20%",
      selfscore: "4",
      supervisorscore: "5",
      weightedscore: "20%",
    },
    {
      Description: <h6>Client Survey Score</h6>,
      weight: "20%",
      selfscore: "4",
      supervisorscore: "5",
      weightedscore: "20%",
    },
    {
      Description: <h6>TOTAL</h6>,
      weight: "100%",
      selfscore: "4.6",
      supervisorscore: "5.4",
      weightedscore: "5.9",
    },
  ];

  const TechnicalAssessmentTableData = [
    {
      Pillars: "Additional Roles",
      Activity:
        "Assist in the investigation of significant suspected fraudulent activities and notify management of the results",
      weight: "20%",
      selfscore: "5",
      supervisorscore: "6",
      weightedscore: "17.15%",
    },
    {
      Pillars: "QRC",
      Activity: "0% system deficiencies, without effective remedial action.",
      weight: "20%",
      selfscore: "6",
      supervisorscore: "6",
      weightedscore: "17.15%",
    },
    {
      Pillars: "Leadership /Employee Excellence & BDO Relationships",
      Activity: ">95% Hierarchical development of staff in the service line",
      weight: "20%",
      selfscore: "6",
      supervisorscore: "6",
      weightedscore: "17.15%",
    },
    {
      Pillars: "Leadership /Employee Excellence & BDO Relationships",
      Activity: ">95% Hierarchical development of staff in the service line",
      weight: "10%",
      selfscore: "6",
      supervisorscore: "6",
      weightedscore: "8.58%",
    },
    {
      Pillars: "Operational Excellence",
      Activity: ">95% achievement of measurable targets on employee scorecard",
      weight: "10%",
      selfscore: "4",
      supervisorscore: "6",
      weightedscore: "8.58%",
    },
    {
      Pillars: "Market Focus/ Client Centricity",
      Activity: ">90% client satisfaction survey / feedback",
      weight: "10%",
      selfscore: "6",
      supervisorscore: "6",
      weightedscore: "8.58%",
    },
    {
      Pillars: "Growth & Profitability",
      Activity: "% of growth in clients / No. of new clients",
      weight: "10%",
      selfscore: "6",
      supervisorscore: "6",
      weightedscore: "8.58%",
    },
    {
      Pillars: "Total",
      Activity: "",
      weight: "100%",
      selfscore: "5.5",
      supervisorscore: "5.8",
      weightedscore: "3.61",
    },
  ];

  const TechnicalAssessmentTableColumns = [
    {
      title: "Pillars",
      dataIndex: "Pillars",
      key: "Pillars",
      width: "90%",
    },
    {
      title: "Activity",
      dataIndex: "Activity",
      key: "Activity",
      width: "90%",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "10%",
      className: "normal-column",
    },
    {
      title: "Self score",
      dataIndex: "selfscore",
      key: "selfscore",
      width: "10%",
      className: "normal-column",
    },
    {
      title: "Supervisor score",
      dataIndex: "supervisorscore",
      key: "weisupervisorscoreght",
      width: "10%",
      className: "normal-column",
    },
    {
      title: "Weighted score",
      dataIndex: "weightedscore",
      key: "weightedscore",
      width: "10%",
      className: "normal-column",
    },
  ];

  const TechnicalAssessmentColumns = [
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      width: "90%",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "10%",
      className: "normal-column",
    },
    {
      title: "Self score",
      dataIndex: "selfscore",
      key: "selfscore",
      width: "10%",
      className: "normal-column",
    },
    {
      title: "Supervisor score",
      dataIndex: "supervisorscore",
      key: "weisupervisorscoreght",
      width: "10%",
      className: "normal-column",
    },
    {
      title: "Weighted score",
      dataIndex: "weightedscore",
      key: "weightedscore",
      width: "10%",
      className: "normal-column",
    },
  ];

  const IndividualDynamicAttributesData = [
    {
      gaf: "Integrity",
      Description:
        <>Demonstrates hight emotional quotient and leadership traits.<br></br> Demonstrates knowledge of policies and practices well.<br></br> Experiences very little difficulty in acquiring/learning knowledge/information.<br></br> Keeps abreast of work related developments.<br></br> Demonstrates ability to apply technical/professional knowledge and skills to immediate work situation.<br></br> Low level of supervision and guidance necessary.<br></br> Ensures that the limits to authority are respected at all times.<br></br> Demonstrates and embodies the BDO values and culture through their interactions, efforts and behaviour.<br></br> Should have a lot of Social Capital and must adhere to ESG and other ethical behaviours</>,
      Weight: "20%",
      selfscore: "6",
      supervisorscore: "6",
      weightedscore: "20%"
    },
    {
      gaf: "Effective communication",
      Description:
        "Expresses facts and ideas in a clear and logical manner, without excessive guidance.",
      Weight: "20%",
      selfscore: "6",
      supervisorscore: "6",
      weightedscore: "20%"
    },
    {
      gaf: "Responsibility",
      Description:
        <>Constantly accepts responsibility in a competent manner for own areas of work and those of subordinates.<br></br> Can be relied upon to accept responsibility in respect of other employees in their absence when requested to do so.<br></br> Normal level of supervision and counselling required.<br></br> Works out own programmes/approaches to overcome problems and competently performs to expectations where general principles are not adequate to determine procedure or decisions to be taken</>,
        selfscore: "6",
        supervisorscore: "6",
        weightedscore: "20%"
    },
    {
      gaf: "Reliability",
      Description:
        <>Seldom needs to enquire about progress of a task.<br></br> Shows commitment to work.<br></br> Will readily accept changes in work, work patterns or procedures, work location etc. in order to help achieve objectives.</>,
        selfscore: "6",
        supervisorscore: "6",
        weightedscore: "20%"
    },
    {
      gaf: "Teamwork",
      Description:
        <>Extremely positive and inspiring personality.<br></br> Co-operates well with supervisors, colleagues and those supervised.<br></br> Is polite and respectful of others.<br></br> Demonstrates a sound and healthy attitude when interacting with others.<br></br> Acceptable and gets on well with colleagues.<br></br> Able to influence and communicate well as part of a team.<br></br> Works to achieve team objectives and coaches/ mentors reportees.<br></br> Requires negligible level of supervision and guidance.</>,
      Weight: "20%",
      selfscore: "6",
      supervisorscore: "6",
      weightedscore: "20%"
    },
    {
        gaf: "Total",
        Description:
          "",
        Weight: "100%",
        selfscore: "6",
      supervisorscore: "6",
      weightedscore: "6"
      },
  ];

  const WowFactorData = [
    {
      wowfactor: "Key factors that contribute to adding tremendous value to both an individual and firm profile",
      Weight: "10%",
      selfscore: "5",
      supervisorscore: "5",
      weightedscore: "8.3%"
    },
    {
      wowfactor: "Total score",
      Weight: "",
      selfscore: "",
      supervisorscore: "",
      weightedscore: "4.98%"
    },
  ];

  const IndividualDynamicAttributescolumns = [
    {
      title: "GAF / BDO EA Values",
      dataIndex: "gaf",
      key: "gaf",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      width: "80%",
      className: "normal-column",
    },
    {
      title: "Weight",
      dataIndex: "Weight",
      key: "Weight",
      width: "10%",
      className: "normal-column",
    },
    {
        title: "Self score",
        dataIndex: "selfscore",
        key: "selfscore",
        width: "10%",
        className: "normal-column",
      },
      {
        title: "Supervisor score",
        dataIndex: "supervisorscore",
        key: "weisupervisorscoreght",
        width: "10%",
        className: "normal-column",
      },
      {
        title: "Weighted score",
        dataIndex: "weightedscore",
        key: "weightedscore",
        width: "10%",
        className: "normal-column",
      },
  ];

  const WowFactorColumns = [
    {
      title: "Wow factor",
      dataIndex: "wowfactor",
      key: "wowfactor",
      width: "10%",
    },
    {
      title: "Weight",
      dataIndex: "Weight",
      key: "Weight",
      width: "10%",
      className: "normal-column",
    },
    {
        title: "Self score",
        dataIndex: "selfscore",
        key: "selfscore",
        width: "10%",
        className: "normal-column",
      },
      {
        title: "Supervisor score",
        dataIndex: "supervisorscore",
        key: "weisupervisorscoreght",
        width: "10%",
        className: "normal-column",
      },
      {
        title: "Weighted score",
        dataIndex: "weightedscore",
        key: "weightedscore",
        width: "10%",
        className: "normal-column",
      },
  ];

  return (
    <div>
      <GridBuilder formStructureJson={BasicForm} />
      <h6>Technical Assessment – Chargeable Engagements Aggregate (40%)</h6>
      <ReusableTable
        data={TechnicalAssessmentData}
        columns={TechnicalAssessmentColumns}
      />
      <h6>Technical Assessment – Key Responsibilities (60%)</h6>
            <ReusableTable
        data={tempdata4}
        columns={TechnicalAssessmentColumns1}
      />
      <u><h6>Individual- Static Attributes (40%)</h6></u>
      <GridBuilder formStructureJson={BasicForm} />

      <h6>Individual- Dynamic Attributes (50%)</h6>
      <ReusableTable
        data={IndividualDynamicAttributesData}
        columns={IndividualDynamicAttributescolumns}
      />
      <h6>Individual- Wow Factor(10%):</h6>
      <ReusableTable
        data={WowFactorData}
        columns={WowFactorColumns}
      />
      <GridBuilder formStructureJson={CommentForm} />
    </div>
  );
}

export default SupervisorAssessment;
