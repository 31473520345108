import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getClientid,
  getRoleList,
  getLocations,
  getMenuList,
  getUserList,
  getallCompetencyList,
} from "redux/selectors";
import styled from "@emotion/styled";
import EditDepartment from "organisms/EditDepartment";
import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Modal,
  message,
  Switch,
  Checkbox,
  Form,
  Select,
} from "antd";
import Button from "atoms/Button";
import Table from "atoms/Table";
import { CSVLink } from "react-csv";
import {
  toggleProcessingModal,
  setAllDepartmentList,
  setAllCopetencyList,
} from "redux/actions";
import { CALL_API } from "common/API";
import { filter, includes, reduce } from "lodash";
import Confirm from "../../src/assets/Confirm.svg";
import { STATUS_CODE } from "common/Constants";
import BasicInformation from "molecules/BasicInformation";
import SelectTable from "molecules/SelectTable";
import StepsProgress from "organisms/StepsProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormModal from "organisms/FormModal";
import InReview from "molecules/InReview";
import moment from "moment";
import { ButtonOutlined } from "reusableComponent/UIButtons";
const { Option } = Select;

const StyledButton = styled(Button)`
  width: 150px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 24px 0px 30px 0px;
  }
  .modal-body {
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x {
    color: #151515;
  }
  .modal-body-content {
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;

function Department({
  ClientId,
  saveAllDepartmentList,
  roleList,
  locationList,
  menuList,
  userRoleId,
  userList,
  saveAllCompetencyList,
  allCompetency,
}) {
  const [displayAddDepartmentModal, toggleAddDepartmentModal] = useState(false);
  const [displayEditDepartmentModal, toggleEditDepartmentModal] =
    useState(false);

  const [DepartmentDetails, setDepartmentDetails] = useState({});
  const [CSVDepartmentList, setCSVDepartmentList] = useState([]);
  const [displayDepartmentLoader, setDisplayDepartmentLoader] = useState(false);
  const [deactivateDepartment, toggledeactivateDepartment] = useState(false);
  const [confirmdeactivateDepartment, toggleconfirmdeactivateDepartment] =
    useState(false);

  const [deptid, setdeptid] = useState();
  const [loading, setLoading] = useState(false);
  const [btnLoader, togglebtnLoader] = useState(false);
  const [departmentList, setdepartmentList] = useState(allCompetency);
  const csvLinkEl = useRef();

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const CSVHeaders = [
    { label: "Team Name", key: "department_name" },
    { label: "No of Location", key: "no_of_location" },
    { label: "No of Employee", key: "no_of_employee" },
    { label: "No of Role", key: "no_of_roles" },
    { label: "Created On", key: "inserted_at" },
  ];

  const columns = [
    {
      title: "Team Name",
      dataIndex: "department_name",
      key: "department_name",
      width: "15%",
    },
    {
      title: "No of Employee",
      dataIndex: "no_of_employee",
      key: "no_of_employee",
      width: "10%",
    },
    {
      title: "No of Location",
      dataIndex: "no_of_location",
      key: "no_of_location",
      width: "15%",
    },

    {
      title: "No of Role",
      dataIndex: "no_of_roles",
      key: "no_of_roles",
      width: "15%",
    },
    {
      title: "Created On",
      dataIndex: "inserted_at",
      key: "inserted_at",
      width: "15%",
      render: (_, record) => {
        return moment(record?.inserted_at).format("DD-MM-yyyy");
      },
    },
  ];

  useEffect(() => {
    getDepartmentList();
  }, [setdepartmentList]);

  useEffect(() => {
    setdepartmentList(allCompetency);
  }, [allCompetency]);

  const getDepartmentList = async () => {
    try {
      setDisplayDepartmentLoader(true);
      const { response } = await CALL_API(
        `owner-department/${ClientId}`,
        "get",
        {}
      );
      const mergedData = response?.map((item) => {
        const filteredUsers = filter(
          userList,
          (user) =>
            includes(item?.user_id, user?.userid) && user?.statusid === "1"
        );
        return {
          department_id: item?.department_id,
          clientid: item?.clientid,
          department_name: item?.department_name,
          location_id: item?.location_id,
          role_id: item?.role_id || [],
          no_of_employee: filteredUsers?.length || 0,
          no_of_location: item?.location_id.length || 0,
          no_of_roles: item?.role_id?.length || 0,
          status_id: item?.status_id || 0,
          inserted_at: item?.inserted_at || "",
        };
      });
      // setdepartmentList(mergedData);
      saveAllCompetencyList(mergedData);
      setDisplayDepartmentLoader(false);
    } catch (error) {
      console.log("[getDepartmentList], Error-->", error);
    }
  };

  const deletedDepartment = () => {
    toggledeactivateDepartment(true);
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, departmentList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveAllDepartmentList(departmentList);
      }
    }
  };

  const deleteDepartment = async (department_id) => {
    togglebtnLoader(true);
    const { code } = await CALL_API(
      `owner-department/${ClientId}/${department_id}`,
      "delete",
      {
        department_id: department_id,
        status_id: 0,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      togglebtnLoader(false);
      toggledeactivateDepartment(false);
      fetchMasterData();
      toggleconfirmdeactivateDepartment(true);
      toggleEditDepartmentModal(false);
      getDepartmentList();
    }
  };

  const ViewDepartment = (DepartmentObject, isViewOnly = false) => {
    setdeptid(DepartmentObject.department_id);
    toggleEditDepartmentModal(true);
    setDepartmentDetails({
      ...DepartmentObject,
      isViewOnly,
    });
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  // ------add department----- //
  const [step, setStep] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [create, setCreate] = useState(false);
  const [values, setValues] = useState("");
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);
  const [filteredDepartment, setFilteredDepartment] = useState([]);
  const [filters, setFilters] = useState({
    team: [],
  });

  const departmentfilter = filteredDepartment?.filter((val) => {
    if (val === "") {
      return val;
    } else if (JSON.stringify(val.status_id) === "1") {
      return val;
    }
  });

  useEffect(() => {
    const { team } = filters;

    if (team.length === 0) {
      setFilteredDepartment(departmentList);
    } else {
      setFilteredDepartment(
        departmentList.filter((item) => team.includes(item.department_name))
      );
    }
  }, [filters, departmentList]);

  const prepareCSV = async () => {
    if (departmentfilter?.length > 0) {
      setCSVDepartmentList(
        reduce(
          departmentfilter,
          function (result, list) {
            result.push({
              "Team Name": list.department_name,
              "No of Location": list.no_of_location,
              "No of Employee": list.no_of_employee,
              "No of Role": list.no_of_roles,
              "Created On": moment(list?.inserted_at).format("yyyy-MM-DD"),
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };

  useEffect(() => {
    setSelectAllRoleChecked(selectedRoleId?.length === roleList?.length);
    setSelectAllChecked(selectedLocationId?.length === locationList?.length);
  }, [selectedRoleId, selectedLocationId, roleList, locationList]);

  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Role" },
    { id: 3, title: "Country/Location" },
    { id: 4, title: "Review" },
  ];

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        return (
          <Switch
            size="small"
            checked={selectAllRoleChecked || selectedRoleId.includes(roleId)}
            onChange={(checked) => getRoleId(roleId, checked)}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
  };

  const locatincolumns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: "10%",
    },
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        return (
          <Switch
            size="small"
            checked={
              selectAllChecked || selectedLocationId.includes(location_id)
            }
            onChange={(checked) => getLocationId(location_id, checked)}
          />
        );
      },
    },
  ];

  const getLocationId = (location_id, checked) => {
    const newLocationId = checked
      ? [...selectedLocationId, location_id]
      : selectedLocationId.filter((item) => item !== location_id);
    setSelectedLocationId(newLocationId);
  };

  const handleSelectAllLocation = (e) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    const allLocationIds = locationList.map((item) => item.location_id);
    setSelectedLocationId(checked ? allLocationIds : []);
  };

  const onFinish = async () => {
    const { name } = values;
    setLoading(true);
    const { code } = await CALL_API(`owner-department/${ClientId}`, "post", {
      location_id: selectedLocationId,
      department_name: name,
      role_id: selectedRoleId,
      status_id: 1,
    });
    if (code === STATUS_CODE.SUCCESS) {
      fetchMasterData(true);
      setLoading(false);
      message.success("Successfully Added");
      setValues({});
      setSelectedLocationId([]);
      getDepartmentList();
      setCreate(false);
      setStep(0);
      toggleAddDepartmentModal(false);
    }
  };

  useEffect(() => {
    if (create) {
      onFinish();
    }
  }, [create]);

  const headerstyle = { fontSize: "20px", fontWeight: "bold" };

  const [form] = Form.useForm();

  const handleDepartmentFilter = () => {
    const values = form.getFieldsValue();
    const { team } = values;

    setFilters({ team });
  };

  return (
    <>
      {!displayAddDepartmentModal && !displayEditDepartmentModal && (
        <>
          <Row className="page-title">
            <Col sm={24} md={12} style={headerstyle}>
              List of Competencies and Servicelines
            </Col>
            <Col
              sm={24}
              md={12}
              className="d-flex justify-content-end algin-items-center"
            >
              <ButtonOutlined type="link" className="m-0" onClick={prepareCSV}>
                Export CSV file
              </ButtonOutlined>
              <CSVLink
                header={CSVHeaders}
                data={CSVDepartmentList}
                filename={"department-list.csv"}
                asyncOnClick={true}
                ref={csvLinkEl}
              />
              {Menulist?.find(
                (item) =>
                  item?.key === "manage" &&
                  item?.subMenu.find(
                    (subItem) =>
                      subItem?.key === "department" &&
                      subItem?.permission.create
                  )
              ) && (
                <StyledButton
                  type="primary"
                  htmlType="submit"
                  onClick={() => toggleAddDepartmentModal(true)}
                >
                  + Add Team
                </StyledButton>
              )}
            </Col>
          </Row>

          <Form
            form={form}
            layout="vertical"
            className="mt-2"
            onValuesChange={handleDepartmentFilter}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item label="Team" name="team">
                  <Select
                    showSearch
                    placeholder="Select"
                    optionFilterProp="value"
                    mode="multiple"
                  >
                    {departmentList?.map((item) => (
                      <Option
                        key={item.department_id}
                        value={item.department_name}
                      >
                        {item.department_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Spin
            spinning={displayDepartmentLoader && departmentList.length === 0}
          >
            <Table
              className="p-0"
              dataSource={filteredDepartment}
              columns={columns}
              rowKey="department_id"
              pagination={
                filteredDepartment?.length > 20 ? paginationOptions : false
              }
              onRow={(record) => {
                return {
                  onClick: () => {
                    ViewDepartment(record);
                  },
                };
              }}
            />
          </Spin>
        </>
      )}

      {/* ------add department------- */}
      {displayAddDepartmentModal && (
        <>
          <label
            className="bg-transparent border-0 mb-2 text-left styled-cursor"
            onClick={() => toggleAddDepartmentModal(false)}
          >
            Competency/SL <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className=" pb-0 pt-1">Add Team</b>
          <StepsProgress stepdata={stepdata} title="Add Team:" step={step} />
        </>
      )}
      {displayAddDepartmentModal && (
        <div className="styled-card">
          {displayAddDepartmentModal && step === 0 && (
            <BasicInformation
              step={step}
              setStep={setStep}
              setValues={setValues}
              values={values}
            />
          )}
          {displayAddDepartmentModal && step === 1 && (
            <>
              {roleList.length > 0 && (
                <Checkbox
                  className="mt-3"
                  checked={selectAllRoleChecked}
                  onChange={handleSelectAllRole}
                >
                  Select All
                </Checkbox>
              )}
              <SelectTable
                step={step}
                setStep={setStep}
                columns={rolecolumns}
                tabledata={roleList}
                setOpenModal={() => setOpenRoleModal(true)}
                Name="Add Role"
              />
              <FormModal
                isModalVisible={openRoleModal}
                closeModal={() => setOpenRoleModal(false)}
                Name="Role"
              />
            </>
          )}
          {step === 2 && (
            <>
              {locationList.length > 0 && (
                <Checkbox
                  className="mt-3"
                  checked={selectAllChecked}
                  onChange={handleSelectAllLocation}
                >
                  Select All
                </Checkbox>
              )}

              <SelectTable
                step={step}
                setStep={setStep}
                columns={locatincolumns}
                tabledata={locationList}
                setOpenModal={() => setOpenLocationModal(true)}
                Name="Add Location"
              />
              <FormModal
                isModalVisible={openLocationModal}
                closeModal={() => setOpenLocationModal(false)}
                Name="Location"
              />
            </>
          )}

          {step === 3 && (
            <InReview
              step={step}
              setStep={setStep}
              BasicInformation={values}
              role={selectedRoleId}
              location={selectedLocationId}
              create={onFinish}
              loading={loading}
            />
          )}
        </div>
      )}

      <EditDepartment
        isModalVisible={displayEditDepartmentModal}
        closeModal={() => toggleEditDepartmentModal(false)}
        getDepartmentList={getDepartmentList}
        DepartmentDetails={DepartmentDetails}
        deleteDepartment={deletedDepartment}
        viewOnly={
          Menulist?.find(
            (item) =>
              item?.key === "manage" &&
              item?.subMenu.find(
                (subItem) =>
                  subItem?.key === "department" && subItem?.permission.edit
              )
          )
            ? false
            : true
        }
      />

      <StyleddeactivateModal
        title="Are you sure you want to deactivate this department?"
        visible={deactivateDepartment}
        width={500}
        onCancel={() => toggledeactivateDepartment(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head">
          <Col>
            <StyledButton
              type="primary"
              htmlType="submit"
              onClick={() => toggledeactivateDepartment(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col>
            <StyledButton
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={() => deleteDepartment(deptid)}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>

      <StyledModal
        visible={confirmdeactivateDepartment}
        width={500}
        onCancel={() => toggleconfirmdeactivateDepartment(false)}
        footer={[]}
        centered
      >
        <Row className="modal-body">
          <Col>
            <img src={Confirm} alt="confirm" height={45} width={45} />
          </Col>
        </Row>
        <Row className="modal-body-content">
          <Col>
            <p>This department has been deactivated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
  locationList: getLocations(state),
  menuList: getMenuList(state),
  userList: getUserList(state),
  allCompetency: getallCompetencyList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      saveAllDepartmentList: setAllDepartmentList,
      saveAllCompetencyList: setAllCopetencyList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Department);
