import React, { useEffect, useMemo, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import {
  Avatar,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Spin,
  Upload,
  notification,
} from "antd";
import TabsList from "molecules/TabsList";
import EditableTodoBox from "pages/Engagement/EngagementView/TodoList/EditableTodoBox";
import { ReactComponent as CalendarPickerIcon } from "assets/icons/UI/calendar-picker.svg";
import { PlusOutlined } from "@ant-design/icons";
import Button from "atoms/Button";
import { useFetch } from "hooks/useFetch";
import queryKeys from "common/queryKey";
import { useSelector } from "react-redux";
import { getUserInfo, getUserList } from "redux/selectors";
import moment from "moment";
import { useUpdate } from "hooks/useUpdate";
import { useMutate } from "hooks/useMutate";
import axios from "axios";
import { useDelete } from "hooks/useDelete";
import { useQueryClient } from "react-query";

const SkeletonPlaceHolder = () => (
  <Skeleton.Button active={true} size={"small"} />
);

const TodoView = () => {
  const { id: engagementId, todoId } = useParams();
  const history = useHistory();
  const {
    getTodoById,
    updateTodo: updateTodoQuery,
    uploadTodoImage: uploadTodoImageQuery,
    getTodoImages: getTodoImagesQuery,
    deleteTodo: deleteTodoQuery,
  } = queryKeys;
  const [form] = Form.useForm();

  const userList = useSelector((state) => getUserList(state));
  const [fileList, setFileList] = useState([]);

  const findUser = (id) => {
    return userList?.find((user) => user?.userid === id);
  };

  const { key, url } = getTodoById(engagementId, todoId);

  const { data: todoDataRes, refetch, isLoading } = useFetch(key, url);

  const todoData = todoDataRes?.data;

  const assignedByUser = findUser(todoData?.assignedBy);
  const responsibleUser = findUser(todoData?.responsible);

  const assignedBy = assignedByUser
    ? assignedByUser?.firstname + " " + assignedByUser?.lastname
    : null;

  const assignedTo = responsibleUser
    ? responsibleUser?.firstname + " " + responsibleUser?.lastname
    : null;

  const editableTodoExtraData = {
    engagementId: engagementId,
    todoId: todoId,
  };

  useEffect(() => {
    if (todoData) {
      form.setFieldsValue({
        hours: todoData?.hours,
        priority: todoData?.priority,
        status: todoData?.status,
        typeOfService: todoData?.typeOfService,
        plan_start_date: moment(todoData?.planStartDate, "DD-MM-YYYY"),
        plan_end_date: moment(todoData?.planEndDate, "DD-MM-YYYY"),
        timesheet: todoData?.includedIn.timesheet,
        milestone: todoData?.includedIn.milestone,
        actual_start_date: todoData?.actualStartDate
          ? moment(todoData?.actualStartDate, "DD-MM-YYYY")
          : null,
        actual_end_date: todoData?.actualEndDate
          ? moment(todoData?.actualEndDate, "DD-MM-YYYY")
          : null,
        cpl: todoData?.cpl ?? "",
        remark: todoData?.remark ?? "",
        remark_for_cpl: todoData?.remarkForCpl ?? "",
        // attachment: todoData?.attachment ?? [], //? since we are getting the attachment from another api
        supported_by: todoData?.supportedBy ?? "",
        description: todoData?.description ?? "",
      });
    }
  }, [todoData, form]);

  const { mutate: updateTodo, isLoading: isUpdating } = useUpdate(
    updateTodoQuery.key,
    updateTodoQuery.url
  );

  const handleUpdate = () => {
    const values = form.getFieldsValue(true);
    console.log("handleUpdate  values----->", values);

    const formToAttributes = (values) => {
      return {
        hours: values.hours,
        priority: values.priority,
        status: values.status,
        typeOfService: values.typeOfService,
        planStartDate: moment(values.plan_start_date).format("DD-MM-YYYY"),
        planEndDate: moment(values.plan_end_date).format("DD-MM-YYYY"),
        includedIn: {
          timesheet: values.timesheet,
          milestone: values.milestone,
        },
        actualStartDate: values.actual_start_date
          ? moment(values.actual_start_date).format("DD-MM-YYYY")
          : null,
        actualEndDate: values.actual_end_date
          ? moment(values.actual_end_date).format("DD-MM-YYYY")
          : null,
        cpl: values.cpl,
        remark: values.remark,
        remarkForCpl: values.remark_for_cpl,
        attachment: values.attachment,
        supportedBy: values.supported_by,
        description: values.description,
      };
    };

    const data = formToAttributes(values);

    const updatedData = {
      ...todoData,
      ...data,
    };

    updateTodo(updatedData, {
      onSuccess: () => {
        notification.success({
          message: "Task Updated Successfully",
        });
        refetch();
        // history.push(`/engagement/${engagementId}`);
      },
    });
  };

  const { key: uploadTodoImageKey, url: uploadTodoImageUrl } =
    uploadTodoImageQuery;

  const { mutate: uploadImg, isLoading: isImgUploading } = useMutate(
    uploadTodoImageKey,
    uploadTodoImageUrl
  );

  const { key: getTodoImagesKey, url: getTodoImagesUrl } =
    getTodoImagesQuery(todoId);

  const { data: todoImagesRes } = useFetch(getTodoImagesKey, getTodoImagesUrl, {
    enabled: !!todoId,
  });

  const todoImages = todoImagesRes?.data;

  useEffect(() => {
    if (todoImages && todoImages.length > 0) {
      setFileList(
        todoImages.map((image) => ({
          url: image.url,
          name: image.filename
        }))
      );
    }
  }, [todoImages]);

  const uploadImageToS3 = async (base64Data, url) => {
    if (!base64Data || !url) {
      notification.error({
        message: "Error uploading image to S3",
      });
    }
    try {
      const res = await axios.put(url, base64Data);

      return res;
    } catch (error) {
      console.error("Error uploading image to S3", error);
    }
  };

  const handleUploadAttachment = async (file) => {
    const actualFile = file.file;

    const data = {
      todoId: todoId,
      fileName: actualFile.name,
    };

    uploadImg(data, {
      onSuccess: (res) => {
        setFileList((prev) => [
          ...prev,
          {
            uid: actualFile.uid,
            name: actualFile.name,
            status: "done",
            url: URL.createObjectURL(actualFile),
          },
        ]);
        uploadImageToS3(actualFile, res?.url);
      },
    });
  };

  const { key: deleteTodoKey, url: deleteTodoUrl } = deleteTodoQuery(
    todoId,
    engagementId
  );

  const { mutate: deleteTodo, isLoading: isDeleting } = useDelete(
    deleteTodoKey,
    deleteTodoUrl
  );

  const handleDeleteTodo = () => {
    deleteTodo("", {
      onSuccess: () => {
        notification.success({
          message: "Task Deleted Successfully",
        });
        history.push(`/engagement/${engagementId}`);
      },
    });
  };

  return (
    <TodoViewWrap>
      <Row gutter={12}>
        <Col lg={24} xl={17}>
          <div className="main-back">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => {
                history.push(`/engagement/${engagementId}`);
              }}
            >
              <ArrowBackIosIcon className="backicon" /> Back
            </div>
          </div>
          <Card>
            <div className="task-name">
              {todoData?.taskTitle ?? <SkeletonPlaceHolder />} (ID:{" "}
              {todoData?.todoId ?? <SkeletonPlaceHolder />})
            </div>
            <div className="created-by-who">
              Created on{" "}
              {moment(todoData?.createdDate).format("DD-MM-YYYY") ?? (
                <SkeletonPlaceHolder />
              )}{" "}
              by {assignedBy ?? <SkeletonPlaceHolder />}
            </div>
            <div className="assigned-to">
              Assigned To:{" "}
              <span className="name">
                {assignedTo ?? <SkeletonPlaceHolder />}
              </span>
            </div>

            <Form
              className="mt-2"
              colon={true}
              layout="vertical"
              form={form}
              onFinish={handleUpdate}
            >
              <Row gutter={[12, 16]}>
                <Col sm={24} lg={6}>
                  <EditableTodoBox
                    label={"Hours"}
                    name="hours"
                    value={todoData?.hours}
                    extraData={editableTodoExtraData}
                    options={[
                      { value: "1", label: "1 Hour" },
                      { value: "2", label: "2 Hours" },
                      { value: "3", label: "3 Hours" },
                      { value: "4", label: "4 Hours" },
                      { value: "5", label: "5 Hours" },
                      { value: "6", label: "6 Hours" },
                      { value: "7", label: "7 Hours" },
                      { value: "8", label: "8 Hours" },
                    ]}
                  />
                </Col>
                <Col sm={24} lg={6}>
                  <EditableTodoBox
                    label={"Priority"}
                    name="priority"
                    value={todoData?.priority}
                    extraData={editableTodoExtraData}
                    options={[
                      { value: "low", label: "Low" },
                      { value: "medium", label: "Medium" },
                      { value: "high", label: "High" },
                    ]}
                  />
                </Col>
                <Col sm={24} lg={6}>
                  <EditableTodoBox
                    label={"Status"}
                    name="status"
                    value={todoData?.status}
                    extraData={editableTodoExtraData}
                    options={[
                      { value: "new", label: "New" },
                      { value: "open", label: "Open" },
                      { value: "inprogress", label: "In Progress" },
                      { value: "completed", label: "Completed" },
                      { value: "onhold", label: "On Hold" },
                      { value: "cancelled", label: "Cancelled" },
                    ]}
                  />
                </Col>
                <Col sm={24} lg={6}>
                  <EditableTodoBox
                    label={"Types of Service"}
                    name="typeOfService"
                    value={todoData?.typeOfService}
                    extraData={editableTodoExtraData}
                    options={[
                      { value: "option1", label: "Option 1" },
                      { value: "option2", label: "Option 2" },
                      { value: "option3", label: "Option 3" },
                    ]}
                  />
                </Col>
                <Col sm={24} lg={6}>
                  <Form.Item label="Plan Start Date" name="plan_start_date">
                    <DatePicker disabled={true} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col sm={24} lg={6}>
                  <Form.Item label="Plan End Date:" name="plan_end_date">
                    <DatePicker disabled={true} placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col sm={24} lg={6}>
                  <Form.Item label="Included In:">
                    <Form.Item name="timesheet" valuePropName="checked" noStyle>
                      <Checkbox
                        value="timesheet"
                        style={{ lineHeight: "32px" }}
                      >
                        Timesheet
                      </Checkbox>
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col sm={24} lg={6}>
                  <Form.Item label="Included In:">
                    <Form.Item name="milestone" valuePropName="checked" noStyle>
                      <Checkbox
                        value="milestone"
                        style={{ lineHeight: "32px" }}
                      >
                        Milestone
                      </Checkbox>
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col sm={24} lg={6}>
                  <Form.Item
                    label="Actual Start Date:"
                    name="actual_start_date"
                  >
                    <DatePicker
                      suffixIcon={<CalendarPickerIcon />}
                      placeholder="Enter"
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} lg={6}>
                  <Form.Item label="Actual End Date:" name="actual_end_date">
                    <DatePicker
                      suffixIcon={<CalendarPickerIcon />}
                      placeholder="Enter"
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label="%CPL:" name="cpl">
                    <Input placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label="Remark:" name="remark">
                    <Input.TextArea
                      style={{
                        resize: "none",
                      }}
                      placeholder="Enter"
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label="Remark for %CPL:" name="remark_for_cpl">
                    <Input.TextArea
                      style={{
                        resize: "none",
                      }}
                      placeholder="Enter"
                    />
                  </Form.Item>
                </Col>
                <Col sm={24}>
                  <Form.Item label="Attachment:">
                    <div className="attachement-wrap">
                      <Form.Item name="attachment">
                        <Upload
                          listType="picture-card"
                          className="upload-list-inline"
                          fileList={fileList}
                          onChange={handleUploadAttachment}
                          beforeUpload={() => false}
                        >
                          <Spin spinning={isImgUploading}>
                            <button
                              style={{
                                border: 0,
                                background: "none",
                              }}
                              type="button"
                            >
                              <PlusOutlined />
                              <div
                                style={{
                                  marginTop: 8,
                                }}
                              >
                                Upload
                              </div>
                            </button>
                          </Spin>
                        </Upload>
                      </Form.Item>
                    </div>
                  </Form.Item>
                </Col>
                <Col sm={24}>
                  <Form.Item label="Supported By:" name="supported_by">
                    <Input.TextArea
                      style={{
                        resize: "none",
                      }}
                      placeholder="Enter"
                    />
                  </Form.Item>
                </Col>
                <Col sm={24}>
                  <Form.Item label="Description:" name="description">
                    <Input.TextArea
                      style={{
                        resize: "none",
                      }}
                      placeholder="Enter"
                      rows={3}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div>
                <Button
                  type="primary"
                  className="mr-2"
                  htmlType="submit"
                  loading={isUpdating}
                >
                  Save
                </Button>
                <Button
                  type="primary"
                  onClick={handleDeleteTodo}
                  loading={isDeleting}
                >
                  Delete
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
        <Col
          lg={24}
          xl={7}
          style={{
            width: "100%",
          }}
        >
          <Card
            className="comment-card"
            style={{
              position: "sticky",
              top: "20px",
              width: "100%",
            }}
          >
            <CommentHistory
              engagementId={engagementId}
              todoId={todoId}
              todoData={todoData}
              isLoading={isLoading}
            />
          </Card>
        </Col>
      </Row>
    </TodoViewWrap>
  );
};

export default TodoView;

const CommentHistory = ({ engagementId, todoId, todoData, isLoading }) => {
  const [tabId, setTabId] = useState("comments");

  const handleTabSelect = (id) => {
    setTabId(id);
  };

  const items = useMemo(
    () => [
      {
        id: "comments",
        label: "Comments",
        component: (
          <Comments
            engagementId={engagementId}
            todoId={todoId}
            todoData={todoData}
            isLoading={isLoading}
          />
        ),
      },
      {
        id: "history",
        label: "History",
        component: (
          <History
            engagementId={engagementId}
            todoId={todoId}
            todoData={todoData}
            isLoading={isLoading}
          />
        ),
      },
    ],
    [engagementId, todoId, todoData, isLoading]
  );

  return (
    <TabsList items={items} handleTabSelect={handleTabSelect} tabId={tabId} />
  );
};

const CommentCard = ({ comment }) => {
  return (
    <div className="comment-card">
      <Space>
        <Avatar style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}>
          {comment.by[0]}
        </Avatar>

        <span className="by">{comment.by}</span>
      </Space>
      <div className="message">
        <div>{comment.comment}</div>
      </div>
      <div className="time">
        <span>{comment.at}</span>
        <span className="bar"> | </span>
        <span>{comment.date}</span>
      </div>
    </div>
  );
};

const Comments = ({ engagementId, todoId, todoData, isLoading }) => {
  const userInfo = useSelector((state) => getUserInfo(state));
  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const { patchTodo, getTodoById } = queryKeys;

  const { mutate, isLoading: isUpdating } = useUpdate(
    patchTodo.key,
    patchTodo.url
  );

  const { key: getTodoByIdKey } = getTodoById(engagementId, todoId);

  const submitComment = (values) => {
    console.log("submitComment  values----->", values);
    const comment = {
      by: userInfo.employer_id,
      at: moment().format("hh:mm A"),
      date: moment().format("DD MMM YYYY"),
      comment: values.comment,
    };

    const commentData = todoData?.comments ?? [];

    const data = {
      engagementId: engagementId,
      todoId: todoId,
      name: "comments",
      value: [...commentData, comment],
    };

    mutate(data, {
      onSuccess: () => {
        form.resetFields();
        queryClient.setQueryData(getTodoByIdKey, (oldData) => {
          const oldComments = oldData.data.comments ?? [];
          return {
            ...oldData,
            data: {
              ...oldData.data,
              comments: [...oldComments, comment],
            },
          };
        });
      },
    });
  };

  const comments = todoData?.comments ?? [];

  return (
    <div className="comments-wrap">
      <div className="comments-container">
        {isLoading && (
          <div className="loader">
            <Spin spinning={isLoading} />
          </div>
        )}

        {!isLoading && comments.length === 0 && (
          <div className="text-center fw-bold">No comments yet</div>
        )}

        {comments.map((comment, index) => (
          <CommentCard key={index} comment={comment} />
        ))}
      </div>
      <Form
        form={form}
        className="comment-form"
        name="comment-form"
        onFinish={submitComment}
      >
        <Form.Item name="comment">
          <Input.TextArea
            placeholder="Add a comment"
            style={{
              resize: "none",
            }}
          />
        </Form.Item>

        <div className="text-end">
          <Button type="primary" htmlType="submit" loading={isUpdating}>
            Send
          </Button>
        </div>
      </Form>
    </div>
  );
};

const History = ({ isLoading, todoData }) => {
  const historyData = todoData?.history ?? [];

  return (
    <div className="history-wrap">
      <div className="history-container">
        {isLoading && (
          <div className="loader">
            <Spin spinning={isLoading} />
          </div>
        )}

        {!isLoading && historyData.length === 0 && (
          <div className="text-center fw-bold">No history yet</div>
        )}

        {historyData.map((his, index) => (
          <CommentCard key={index} comment={his} />
        ))}
      </div>
    </div>
  );
};

const TodoViewWrap = styled.div`
  .main-back {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-decoration: underline;
    .backicon {
      font-size: 16px;
    }
  }
  .task-name {
    font-size: 1.125rem;
    font-weight: 600;
  }
  .created-by-who {
    letter-spacing: 0.16px;
    font-size: 0.925rem;
    opacity: 0.6;
  }
  .assigned-to {
    font-size: 1rem;
    margin-top: 10px;
    font-weight: 600;
    .name {
      font-weight: 500;
      opacity: 0.6;
    }
  }

  .attachement-wrap {
    border: 1px solid #d9d9d9;
    padding: 10px;
    border-radius: 5px;
  }

  .comments-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;

    .comment-form {
      margin-top: auto;
    }

    .comments-container {
      overflow-y: auto;
      height: 90%;
    }
  }

  .history-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    .history-container {
      overflow-y: auto;
      height: 100%;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .comment-card {
    padding: 4px;
    .by {
      font-size: 12px;
    }
    .message {
      font-size: 15px;
      font-weight: 500;
      margin-left: 4px;
    }
    .time {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 4px;

      .bar {
        translate: 0.5px -1px;
      }
    }

    margin-bottom: 10px;
  }

  .ant-tabs.ant-tabs-top {
    height: 100%;
    .ant-tabs-content-holder {
      height: 100%;
      .ant-tabs-content.ant-tabs-content-top {
        height: 100%;
        .ant-tabs-tabpane {
          height: 100%;
        }
      }
    }
  }

  /* ant-design customization */
  .comment-card {
    .ant-card-body {
      padding: 4px;
      height: 80vh;
    }
  }
  .ant-card {
    border: none;
    .ant-card-body {
      box-shadow: 0px 3px 6px #00000014;
      border: none;
      border-radius: 5px;
    }
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-picker {
    width: 100%;
  }
  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
  .ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
    display: none;
  }
  /* .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  } */

  /* .upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
    float: right;
  } */

  .ant-form-item-label {
    label {
      font-weight: 600;
      color: #000;
      opacity: 1;
    }
  }
`;
