import { Checkbox, Divider, Form, Input, Radio } from "antd";
import { Button, Dropdown } from "antd";
import React, { useContext, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components";
import { ContainerContext } from "pages/Container";
import COLORS from "common/Colors";

const StyledButton = styled(Button)`
  background-color: #f0f0f0;
  color: #000;
  border: none;
  border-radius: 999px;
  padding: 0px 8px;
  font-size: 12px;
  font-weight: 500;

  &:hover,
  &:focus,
  &:active {
    background-color: #e0e0e0 !important;
  }
`;

export const DropdownCheckBox = ({
  options,
  label,
  name,
  form,
  handleFormChange = () => {},
  disabled = false,
}) => {
  const currentValue = form.getFieldValue(name);
  const [search, setSearch] = React.useState("");
  const [visible, setVisible] = React.useState(false);

  const { layoutContentRef } = useContext(ContainerContext);

  const items = [
    {
      key: "1",
      label: (
        <StyledMenuItem onClick={(e) => e.stopPropagation()}>
          <div>
            <Input
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="mb-2"
            />

            <Form.Item className="options mb-2" name={name} label="">
              <Checkbox.Group
                options={options?.filter((option) =>
                  option?.label?.toLowerCase()?.includes(search?.toLowerCase())
                )}
              />
            </Form.Item>

            <Divider className="my-2" />

            <div className="d-flex justify-content-end gap-2 mb-1">
              <StyledButton
                size="small"
                onClick={() => {
                  form.setFieldsValue({ [name]: [] });
                  handleFormChange();
                  setSearch("");
                }}
              >
                Clear
              </StyledButton>
              <StyledButton
                size="small"
                onClick={() => {
                  form?.setFieldsValue({
                    [name]: options?.map((option) => option?.value),
                  });

                  handleFormChange();
                }}
              >
                Select All
              </StyledButton>
            </div>
          </div>
        </StyledMenuItem>
      ),
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setVisible(false);
    };

    const currentLayoutContentRef = layoutContentRef.current;
    currentLayoutContentRef.addEventListener("scroll", handleScroll);

    return () => {
      currentLayoutContentRef.removeEventListener("scroll", handleScroll);
    };
  }, [layoutContentRef]);

  return (
    <StyledFilter>
      {/* <div className="filter-label">{label}</div> */}

      <Dropdown
        menu={{
          items,
          onClick: (e) => e.domEvent.stopPropagation(),
        }}
        trigger={["click"]}
        placement="bottomRight"
        // arrow={true}
        overlayStyle={{
          width: "200px",
          borderRadius: "8px",
        }}
        autoAdjustOverflow={true}
        open={visible}
        onOpenChange={(visible) => setVisible(visible)}
        disabled={disabled}
      >
        <Button
          className={`w-100 mb-3 custom-filter-btn ${
            currentValue?.length > 0 ? "filter-applied" : ""
          }`}
        >
          <div className="d-flex justify-content-start align-items-center gap-2">
            {/* <FilterOutlined /> */}
            {currentValue?.length > 0 ? (
              <>
                {label}
                <CircleNumber>{currentValue?.length}</CircleNumber>
              </>
            ) : (
              // `${currentValue?.length} Applied`
              label
            )}

            <KeyboardArrowDownIcon
              fontSize="small"
              className="ms-auto dropdown-icon"
            />
          </div>
        </Button>
      </Dropdown>
    </StyledFilter>
  );
};

export const DropdownRadio = ({
  options,
  label,
  name,
  form,
  handleFormChange = () => {},
}) => {
  const currentValue = form.getFieldValue(name);
  const [search, setSearch] = React.useState("");
  const [visible, setVisible] = React.useState(false);

  const hasValue = currentValue !== "" ? true : false;
  const { layoutContentRef } = useContext(ContainerContext);

  const currentOption = options?.find(
    (option) => option?.value === currentValue
  );

  const items = [
    {
      key: "1",
      label: (
        <StyledMenuItem onClick={(e) => e.stopPropagation()}>
          <div>
            <Input
              placeholder="Search"
              search={search}
              onChange={(e) => setSearch(e.target.value)}
              className="mb-2"
            />

            <Form.Item className="options mb-2" name={name} label="">
              <Radio.Group
                options={options?.filter((option) =>
                  option.label?.toLowerCase().includes(search?.toLowerCase())
                )}
              />
            </Form.Item>

            {hasValue && (
              <>
                <Divider className="my-2" />

                <div className="d-flex justify-content-end gap-2 mb-1">
                  <StyledButton
                    size="small"
                    onClick={() => {
                      form.setFieldsValue({ [name]: "" });
                      handleFormChange();
                      setSearch("");
                    }}
                  >
                    Clear
                  </StyledButton>
                </div>
              </>
            )}
          </div>
        </StyledMenuItem>
      ),
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setVisible(false);
    };

    const currentLayoutContentRef = layoutContentRef.current;
    currentLayoutContentRef.addEventListener("scroll", handleScroll);

    return () => {
      currentLayoutContentRef.removeEventListener("scroll", handleScroll);
    };
  }, [layoutContentRef]);

  return (
    <StyledFilter>
      {/* <div className="filter-label">{label}</div> */}

      <Dropdown
        menu={{
          items,
          onClick: (e) => e.domEvent.stopPropagation(),
        }}
        trigger={["click"]}
        placement="bottomRight"
        // arrow={true}
        overlayStyle={{
          width: "200px",
          borderRadius: "8px",
        }}
        autoAdjustOverflow={true}
        open={visible}
        onOpenChange={(visible) => setVisible(visible)}
      >
        <Button
          className={`w-100 mb-3 custom-filter-btn ${
            hasValue ? "filter-applied" : ""
          }`}
        >
          <div className="d-flex justify-content-start align-items-center gap-2">
            {hasValue ? <>{currentOption?.label}</> : label}

            <KeyboardArrowDownIcon
              fontSize="small"
              className="ms-auto dropdown-icon"
            />
          </div>
        </Button>
      </Dropdown>
    </StyledFilter>
  );
};

const StyledFilter = styled.div`
  width: 100%;
  .filter-label {
    color: #5d5f68;
    font-weight: 500;
    padding-bottom: 8px;
  }

  .custom-filter-btn {
    border-radius: 999px;

    &.filter-applied {
      background-color: ${COLORS.PRIMARY};
    }

    &:hover {
      border-color: ${COLORS.PRIMARY};
    }
  }

  .dropdown-icon {
    color: rgba(0, 0, 0, 0.25);
  }
`;

const StyledMenuItem = styled.div`
  padding: 8px 6px 0px 6px;

  .options {
    height: 100%;
    max-height: 200px;
    overflow-y: auto;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1px;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
    background-color: #00da88 !important;
    border-color: #00da88 !important;
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1px;
  }
  .ant-radio-wrapper {
    margin-left: 2px;
  }
  .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
    border-color: #00da88 !important;
  }
`;

const CircleNumber = ({ children }) => {
  return <StyledCircle>{children}</StyledCircle>;
};

const StyledCircle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
`;
