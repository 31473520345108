import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  message,
} from "antd";
import Button from "atoms/Button";
import queryKeys from "common/queryKey";
import { useMutate } from "hooks/useMutate";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { ReactComponent as CalendarPickerIcon } from "assets/icons/UI/calendar-picker.svg";
import { useSelector } from "react-redux";
import { getUserInfo, getUserList } from "redux/selectors";

const labelStyle = {
  width: "175px",
  display: "inline-block",
  textAlign: "start",
};

const AddTodoModal = ({ engagementData, open, close, refetch }) => {
  const [form] = Form.useForm();

  const { addTodoObj } = queryKeys;
  const userInfo = useSelector((state) => getUserInfo(state));
  const userList = useSelector((state) => getUserList(state));

  const { mutate: addTodo, isLoading: isAdding } = useMutate(
    addTodoObj.key,
    addTodoObj.url
  );

  const objectUserList = userList.filter(user => user.statusid === "1").map((val) => {
    return {
      label: val?.fullname,
      value: val?.userid
    }
  })


  const handleSubmit = (values) => {
    const data = {
      taskTitle: values.task_title,
      engagementId: engagementData?.id,
      responsible: values.responsible,
      planStartDate: moment(values.plan_start_date).format("DD-MM-YYYY"),
      cpl: values.cpl,
      hours: values.hours,
      priority: values.priority,
      planEndDate: moment(values.plan_end_date).format("DD-MM-YYYY"),
      remarkForCpl: values.remark_for_cpl,
      typeOfService: values.type_of_service,
      includedIn: {
        timesheet: values.included_in[0] === "timesheet" ? true : false,
        milestone: values.included_in[1] === "milestone" ? true : false,
      },
      remark: values.remark,
      supportedBy: values.supported_by,
      description: values.description,
      status: "new",
      assignedBy: userInfo?.employer_id,
    };

    addTodo(data, {
      onSuccess: (res) => {
        form.resetFields();
        message.success("New Task added successfully");
        refetch();
        close();
      },
    });
  };

  return (
    <Modal
      title="Add New Task"
      open={open}
      onCancel={close}
      width={900}
      footer={false}
    >
      <AddTodoWrap>
        <Form
          layout="horizontal"
          form={form}
          onFinish={handleSubmit}
          colon={false}
        >
          <Form.Item
            label={<span style={labelStyle}>Task Title:</span>}
            name="task_title"
            rules={[{ required: true, message: "Task Title is required" }]}
          >
            <Input placeholder="Enter" />
          </Form.Item>
          <Row gutter={24}>
            <Col sm={24} md={12} lg={12}>
              <Form.Item
                label={<span style={labelStyle}>Responsible:</span>}
                name="responsible"
                rules={[{ required: true, message: "Responsible person is required" }]}
              >
                <Select
                  placeholder="Enter"
                  options={objectUserList}
                />
              </Form.Item>
              <Form.Item
                label={<span style={labelStyle}>Plan Start Date:</span>}
                name="plan_start_date"
                rules={[{ required: true, message: "Plan Start Date is required" }]}
              >
                <DatePicker
                  suffixIcon={<CalendarPickerIcon />}
                  placeholder="Enter"
                />
              </Form.Item>
              <Form.Item
                label={<span style={labelStyle}>% CPL:</span>}
                name="cpl"
                rules={[{ required: true, message: "% CPL is required" }]}
              >
                <Input placeholder="Enter" />
              </Form.Item>
              <Form.Item
                label={<span style={labelStyle}>Hours:</span>}
                name="hours"
                rules={[{ required: true, message: "Hours are required" }]}
              >
                <Select
                  placeholder="Enter"
                  options={[
                    { value: "1", label: "1 Hour" },
                    { value: "2", label: "2 Hours" },
                    { value: "3", label: "3 Hours" },
                    { value: "4", label: "4 Hours" },
                    { value: "5", label: "5 Hours" },
                    { value: "6", label: "6 Hours" },
                    { value: "7", label: "7 Hours" },
                    { value: "8", label: "8 Hours" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={12}>
              <Form.Item
                label={<span style={labelStyle}>Priority:</span>}
                name="priority"
                rules={[{ required: true, message: "Priority is required" }]}
              >
                <Select
                  placeholder="Select"
                  options={[
                    { label: "Low", value: "low" },
                    { label: "Medium", value: "medium" },
                    { label: "High", value: "high" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={<span style={labelStyle}>Plan End Date:</span>}
                name="plan_end_date"
                rules={[{ required: true, message: "Plan End Date is required" }]}
              >
                <DatePicker
                  suffixIcon={<CalendarPickerIcon />}
                  placeholder="Enter"
                />
              </Form.Item>
              <Form.Item
                label={<span style={labelStyle}>Remark for %CPL:</span>}
                name="remark_for_cpl"
                rules={[{ required: true, message: "Remark for % CPL is required" }]}
              >
                <Input placeholder="Enter" />
              </Form.Item>
              <Form.Item
                label={<span style={labelStyle}>Type of Service:</span>}
                name="type_of_service"
                rules={[{ required: true, message: "Type of Service is required" }]}
              >
                <Select
                  placeholder="Select"
                  options={[
                    { label: "Option 1", value: "option1" },
                    { label: "Option 2", value: "option2" },
                    { label: "Option 3", value: "option3" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="included_in"
            label={<span style={labelStyle}>Included In:</span>}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: "Please select an option for Included In" }]}
          >
            <Checkbox.Group>
              <Checkbox value="timesheet" style={{ lineHeight: "32px" }}>
                Timesheet
              </Checkbox>
              <Checkbox value="milestone" style={{ lineHeight: "32px" }}>
                Milestone
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>

          <Row gutter={24}>
            <Col sm={24} md={12} lg={12}>
              <Form.Item
                label={<span style={labelStyle}>Remark:</span>}
                name="remark"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: "Remark is required" }]}
              >
                <Input.TextArea
                  placeholder="Enter"
                  style={{
                    resize: "none",
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={12}>
              <Form.Item
                label={<span style={labelStyle}>Supported By:</span>}
                name="supported_by"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: "Supported By is required" }]}
              >
                <Input.TextArea
                  placeholder="Enter"
                  style={{
                    resize: "none",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="description"
            label={<span style={labelStyle}>Description:</span>}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: "Description is required" }]}
          >
            <Input.TextArea
              placeholder="Enter"
              style={{
                resize: "none",
              }}
            />
          </Form.Item>

          <div className="text-end mt-2">
            <Button type="primary" onClick={close}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={isAdding}>
              Save
            </Button>
          </div>
        </Form>
      </AddTodoWrap>
    </Modal>
  );
};

export default AddTodoModal;

const AddTodoWrap = styled.div`
  .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-picker {
    width: 100%;
  }
  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
`;
