export const GrowthRCEO = [
    [{
      id: 1,
      label: "Approvals of all promotions and bonuses",
      type: "checkbox",
      name: "promotionsBonusesApprovals",
    }],
    [{
      id: 2,
      label: "Approvals of mergers and office expansions",
      type: "checkbox",
      name: "mergersOfficeExpansionsApprovals",
    }],
    [{
      id: 3,
      label: "Regional consolidation of outcomes management - Client satisfaction, Revenue growth in every service line, Low staff attrition, Staff satisfaction, High proposal submission and conversion, Profit and cash balance improvement, Debtor and creditor days reduction",
      type: "checkbox",
      name: "outcomesManagement",
    }],
    [{
      id: 4,
      label: "Regional job profitability - Job-wise budgeting and staff planning/resource planning and allocation",
      type: "checkbox",
      name: "jobProfitability",
    }],
    [{
      id: 5,
      label: "Ultimately responsible for the overall financial performance of BDO",
      type: "checkbox",
      name: "financialPerformanceBDO",
    }]
  ];

  export const GrowthMPS = [
    [{
      id: 1,
      label: "Job Profitability - Job wise Budgeting and Staff Planning/Resource Planning and Allocation",
      type: "checkbox",
      name: "jobProfitability",
    }],
    [{
      id: 2,
      label: "Responsible for the growth of fees from existing and new clients",
      type: "checkbox",
      name: "growthOfFees",
    }],
    [{
      id: 3,
      label: "Accountable for the financial results (monthly and YTD) of the Regional Office",
      type: "checkbox",
      name: "financialResultsRegionalOffice",
    }],
    [{
      id: 4,
      label: "Manage and assist Partners and Directors in the various Business Lines to achieve their Office and personal financial objectives set out in the business plan and budget",
      type: "checkbox",
      name: "assistPartnersDirectors",
    }],
    [{
      id: 5,
      label: "Monitor profitability, write offs, and adequacy of reserves against WIP (monthly and YTD) for the office including the review of monthly management accounts and explanation of variances",
      type: "checkbox",
      name: "monitorProfitability",
    }],
  ];
  
  export const GrowthPartner = [
    [{
      id: 1,
      label: "Responsible for the growth of fees from existing and new clients",
      type: "checkbox",
      name: "growthOfFees",
    }],
    [{
      id: 2,
      label: "Formulate client service plans to maximise net revenues through a combination of services",
      type: "checkbox",
      name: "clientServicePlans",
    }],
    [{
      id: 3,
      label: "Develops business development plans to ensure achievement of agreed set targets in business revenues per annum",
      type: "checkbox",
      name: "businessDevelopmentPlans",
    }],
    [{
      id: 4,
      label: "Manage and assist the Managing Partner to achieve the Office and personal financial objectives set out in the business plan and strategy",
      type: "checkbox",
      name: "assistManagingPartner",
    }],
    [{
      id: 5,
      label: "Management of debtors and billing in line with BDO Credit policy",
      type: "checkbox",
      name: "debtorsManagement",
    }],
    [{
      id: 6,
      label: "Commercial management of the client portfolio/assignments",
      type: "checkbox",
      name: "commercialManagement",
    }],
    [{
      id: 7,
      label: "Accountable for the financial results (monthly and YTD) of the Business Line",
      type: "checkbox",
      name: "financialResultsBusinessLine",
    }],
  ];

  export const GrowthManager = [
    [{
      id: 1,
      label: "Formulate client service plans to maximise net revenues through a combination of services",
      type: "checkbox",
      name: "clientServicePlans",
    }],
    [{
      id: 2,
      label: "Assist the engagement partner with commercial management of all assignments",
      type: "checkbox",
      name: "assistEngagementPartner",
    }],
    [{
      id: 3,
      label: "Bill completed assignments and recover outstanding fees",
      type: "checkbox",
      name: "billAssignments",
    }],
    [{
      id: 4,
      label: "Proactively monitor and address issues regarding billing and collections",
      type: "checkbox",
      name: "billingAndCollections",
    }],
  ];
  
  export const GrowthSeniors = [
    [{
      id: 1,
      label: "Billing and follow up of debtors",
      type: "checkbox",
      name: "billingFollowUpDebtors",
    }],
    [{
      id: 2,
      label: "Preparation and presentation of proposals for submission to acquire new business",
      type: "checkbox",
      name: "preparePresentProposals",
    }],
  ];

  export const GrowthAssociate = [
    [{
      id: 1,
      label: "Obtain basic understanding of how the firm generates revenue and act in consideration of how to positively impact the firm ",
      type: "checkbox",
      name: "obtainfirm",
    }],
  ];

  


  
  


  