import React from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import COLORS from "common/Colors";
const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    border-radius: 5px;
    font-weight: 500;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .ant-tabs-tab {
    font-size: 16px;
    &:hover {
      color: ${COLORS.TEXT.PRIMARY};
    }
  }
  .ant-tabs-ink-bar {
    background: ${COLORS.TAB};
    font-weight: bold;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.TAB};
    font-weight: 500;
    font-size: 16px;
  }
`;

const TabsList = ({
  items,
  handleTabSelect,
  tabId,
  tabBarExtraContent = false,
  onChange,
}) => {
  return (
    <StyledTabs
      onChange={handleTabSelect}
      tabBarExtraContent={tabBarExtraContent}
      items={items?.map((val) => {
        return {
          label: val?.label,
          key: val?.id,
          children: val?.component,
        };
      })}
      activeKey={tabId}
    />
  );
};
export default TabsList;
