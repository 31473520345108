import { Table } from "antd";
import moment from "moment";

const DoYouWant = ["Yes", "No"];
const DoYouAgree = ["Agree", "Disagree"]
const appropriate = ["there are", "there are no ethical issues that have been brought to my attention that have not been communicated to the appropriate individuals within BDO East Africa."]
const EthicalRequirements = [
    "Integrity",
    "Objectivity",
    "Professional Competence and Due Care",
    "Confidentiality",
    "Professional Behaviour",
    "Technical Standards"
]

export const getDeclarationJson = (tabledata, columns, handleSubmitForm, handlePrintWithDelay, loading, data, loader, type, setTypeBtn, filteruser) => {
    
    const DeclarationForm = {
        totalpages: 1,
        pages: [
            {
                modalView: false,
                colSpan: [24, 24, 24],
                fields: [
                    // Row 1

                    [
                        {
                            id: 1,
                            label: "1. I have reviewed the list of clients and other entities (clients’ affiliates , parents, associates, etc), to which the Independence Policies apply, as defined in the International Federation of Accountants’ Code of Ethics for Professional Accountants (the “Code”).",
                            type: "radio",
                            name: "reviewed_clients_list",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 2,
                            label: "2. I understand my obligation, and I undertake to inform the directors of BDO East Africa immediately if my personal circumstances relating to the above matters should change at any time during my employment with the firm.",
                            type: "radio",
                            name: "inform_directors_about_changes",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 3,
                            label: "3. I understand to inform the firm immediately should I be approached by a client or consider joining a client as an employee.",
                            type: "radio",
                            name: "inform_firm_about_client_employment",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 4,
                            label: "4. I understand my obligation and undertake to treat all client matters in the strictest confidence and with absolute confidentiality.",
                            type: "radio",
                            name: "treat_client_matters_confidentially",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 5,
                            label: "5. I understand my obligation and I undertake to carry out my professional duties towards the firm and its clients with complete integrity and due care.",
                            type: "radio",
                            name: "professional_duties_with_integrity",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 6,
                            label: "6. I do not have any interests, financial or otherwise, in any client of BDO East Africa or an entity that has a controlling interest in the client and the client is material to the entity.",
                            type: "radio",
                            name: "no_financial_interests_in_clients",
                            Placeholder: "Select",
                            options: DoYouAgree,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 7,
                            label: "7. I have not received a loan or guarantee from a client that is a bank or similar institution that is not made under normal lending procedures, terms and conditions, or a client that is not a bank. I have not provided a loan or guarantee to a client or have deposits or a brokerage account with a client that is a bank, broker, or similar institution, not held under normal commercial terms.",
                            type: "radio",
                            name: "no_loans_or_guarantees_from_clients",
                            Placeholder: "Select",
                            options: DoYouAgree,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 8,
                            label: "8. I do not have a commercial relationship or common financial interest with the client or its management.",
                            type: "radio",
                            name: "no_commercial_relationship_with_clients",
                            Placeholder: "Select",
                            options: DoYouAgree,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 9,
                            label: "9. I do not have any personal relationship, family, or close friends, with any client of BDO East Africa. Additionally, no member of my immediate family is a director or officer of the client, is an employee in a position to exert significant influence of the preparation of the client’s accounting records or the financial statements on which the firm will express an opinion, or was in any such position during any period covered by the engagement or the financial statements.",
                            type: "radio",
                            name: "no_personal_or_family_relationship_with_clients",
                            Placeholder: "Select",
                            options: DoYouAgree,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 10,
                            label: "10. I have not, during the period covered by the financial statements, served as a director or officer of the client or an employee in a position to exert significant influence over the preparation of the client’s accounting records or the financial statements on which the firm will express an opinion.",
                            type: "radio",
                            name: "no_director_or_officer_position_in_clients",
                            Placeholder: "Select",
                            options: DoYouAgree,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 11,
                            label: "11. I have not audited a client for more than seven (7) years and after such period not been an engagement team member or been assigned as the key audit partner for two (2) years.",
                            type: "radio",
                            name: "not_audited_client_for_more_than_7_years",
                            Placeholder: "Select",
                            options: DoYouAgree,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 12,
                            label: "12. I have not accepted gifts or hospitality from a client.",
                            type: "radio",
                            name: "not_accepted_gifts_or_hospitality_from_clients",
                            Placeholder: "Select",
                            options: DoYouAgree,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 13,
                            label: "13. As a partner, I have not charged a contingent fee directly or indirectly for audit or review engagement services or non-assurance service.",
                            type: "radio",
                            name: "no_direct_or_indirect_charging_of_contingent_fees",
                            Placeholder: "Select",
                            options: DoYouAgree,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 14,
                            label: "14. I have not pursued actual or threatened litigation against a client.",
                            type: "radio",
                            name: "no_litigation_against_clients",
                            Placeholder: "Select",
                            options: DoYouAgree,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 15,
                            type: "component",
                            content: <p>Where any of the above declarations cannot be made, full details should be provided below: I have a financial/personal relationship with the following clients:</p>
                        },
                    ],
                    [
                        {
                            id: 16,
                            type: "component",
                            content: <Table
                                dataSource={tabledata}
                                columns={columns}
                                pagination={false}
                                rowClassName={(record, index) =>
                                    index % 2 === 0 ? "" : "non-white"
                                }
                            />
                        },
                    ],
                    [
                        {
                            id: 17,
                            type: "component",
                            content: <>
                                <h6>Section B: Ethical Requirements</h6>
                                <p>I confirm that I have read and understood the International Federation of Accountants’ Code of Ethics for Professional Accountants (“the Code”). I understand my responsibilities in terms of the Code. I understand my responsibilities in terms of the Code.</p>
                                <p>I agree to carry out my work in accordance with the fundamental principles of:</p>
                                {EthicalRequirements?.map((item, index) => (
                                    <p key={index}>{`${index + 1}. ${item}`}</p>
                                ))}
                                <p>I understand the policies and procedures in place for the resolution of ethical conflicts and agree to abide by these policies and procedures.</p>
                                <h6>Select as appropriate:</h6>
                            </>
                        },
                    ],
                    [
                        {
                            id: 15,
                            label: `At the date of signing this declaration, I declare that I have been independent for the past 12 months and will be independent in the upcoming 12 months.`,
                            type: "radio",
                            name: "Select_as_appropriate",
                            Placeholder: "Select",
                            options: appropriate,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 16,
                            type: "component",
                            content: <>
                                <h6>Section C: Fit And Proper Questionnaire</h6>
                                <h6>I. Financial Integrity and Reliability</h6>
                            </>
                        },
                    ],
                    [
                        {
                            id: 1,
                            label: "1. In the last ten years has a court in any jurisdiction given any judgment against you about a debt?",
                            type: "radio",
                            name: "court_judgment_against_debt_last_10_years",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 2,
                            label: "2. In the last ten years have you made any compromise arrangement with your creditors?",
                            type: "radio",
                            name: "compromise_arrangement_with_creditors_last_10_years",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 3,
                            label: "3. Have you ever been declared bankrupt, or been the subject of a bankruptcy court order in any jurisdiction, or has a bankruptcy petition even been served on you?",
                            type: "radio",
                            name: "bankruptcy_declaration_or_order",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 4,
                            label: "4. Have you ever signed a trust deed for a creditor, made an assignment for the benefit of creditors, or made any arrangements for the payment of a composition to creditors?",
                            type: "radio",
                            name: "signed_trust_deed_or_arrangement_with_creditors",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 15,
                            type: "component",
                            content: <h6>II. Convictions or Civil Liabilities</h6>
                        },
                    ],
                    [
                        {
                            id: 5,
                            label: "5. Have you at any time pleaded guilty to or been found guilty of any offence?",
                            type: "radio",
                            name: "pleaded_or_found_guilty_of_offence",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 6,
                            label: "6. In the last five years have you, in any jurisdiction, been subject of any civil action relating to your professional or business activities which has resulted in a finding against you by a court, or a settlement being agreed?",
                            type: "radio",
                            name: "civil_action_against_professional_activities_last_5_years",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 7,
                            label: "7. Have you ever been disqualified by a court from being a director, or from acting in the management or conduct of the affairs of any company?",
                            type: "radio",
                            name: "disqualified_from_director_or_management",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 15,
                            type: "component",
                            content: <> <h6>III. Good Reputation and Character</h6>
                                <h6>8. Have you even been:</h6></>
                        },
                    ],
                    [
                        {
                            id: 8,
                            label: "- Refused the right or been restricted in the right to carry on any trade, business or profession for which a specific license, registration or other authority required?",
                            type: "radio",
                            name: "refused_or_restricted_right_to_carry_on_trade",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 9,
                            label: "- Investigated about allegations of misconduct or malpractice in connection with your professional activities which resulted in a formal complaint being proved but no disciplinary order being made?",
                            type: "radio",
                            name: "investigated_for_misconduct_resulting_in_formal_complaint",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 10,
                            label: "- The subject of disciplinary procedures by a professional body or employer resulting in a finding against you?",
                            type: "radio",
                            name: "subject_to_professional_body_disiplinary_procedures",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 11,
                            label: "- Reprimanded, excluded from membership of any profession or vocation?",
                            type: "radio",
                            name: "reprimanded_or_excluded_from_profession",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 12,
                            label: "- Dismissed from any office (other than an Auditor) or employment or requested to resign from any office, employment or partnership?",
                            type: "radio",
                            name: "dismissed_or_requested_to_resign_from_employment",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 13,
                            label: "- Reprimanded, warned about future conduct, disciplined or publicly criticized by any regulatory body, or any officially appointed enquiry concerned with the Regulation of a financial, professional or other business activity?",
                            type: "radio",
                            name: "reprimanded_or_disciplined_by_regulatory_body",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 14,
                            label: "- The subject of a court order at the instigation of any regulatory body, or an officially appointed enquiry concerned with the Regulation of a financial, professional or other business activity?",
                            type: "radio",
                            name: "subject_to_court_order_by_regulatory_body",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 15,
                            label: "9. Are you currently undergoing any investigation or disciplinary procedures as described in section 8 above?",
                            type: "radio",
                            name: "undergoing_investigation_or_disciplinary_procedures",
                            Placeholder: "Select",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 16,
                            label: "If any of the above are answered yes, please provide details below.",
                            type: "textarea",
                            name: "details",
                            Placeholder: "",
                            options: DoYouWant,
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        },
                    ],
                    type!=="Declaration" ? [
                       {
                            id: 15,
                            type: "component",
                            content: <h6>Declaration</h6>
                        },
                    ] : [],
                    type!=="Declaration" ? [
                        
                         {
                            id: 1,
                            label: "I certify that the information given above is complete and accurate and to the best of my knowledge, and that there are no other facts relevant to this declaration of which BDO East Africa should be aware.",
                            type: "checkbox",
                            name: "confirm_information",
                            Placeholder: "Select",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        } ,
                    ] : [],
                    type!=="Declaration" ?[
                         {
                            id: 2,
                            label: "I will inform BDO East Africa immediately of any changes to my status that may arise during my employment.",
                            type: "checkbox",
                            name: "employment_status",
                            Placeholder: "Select",
                            validationRules: [
                                {
                                    required: true,
                                    message: `This field is required`,
                                },
                            ],
                        } ,
                    ] : [],
                    type!=="Declaration" ? [
                        {
                            id: 15,
                            type: "component",
                            content: <div className='d-flex align-items-center gap-3'>
                                <h6 className='styled-text'>{filteruser?.fullname}</h6>
                                <h6>{moment().format('MMMM DD YYYY h:mmA')}</h6>
                            </div>
                        },
                    ] : [] ,
                    [
                        {
                            id: 3,
                            label: "Cancel",
                            type: "buttongroup",
                            position: "start",
                            textColor: "white",
                            event: "submit",
                            buttons: (type!=="Declaration" && data?.status !== "submit")?  [
                                {
                                    label: "Save as draft",
                                    disabled:  (data?.status === undefined || data?.status === "draft" || data?.status === "") ? false : true,
                                    loading: loader,
                                    event: "submit",
                                    btnStyle: {
                                         display: loading ? "none": ""
                                    },
                                    onClick: () => {
                                        setTypeBtn("draft")
                                      },
                                } ,
                                 {
                                    label: "Submit",
                                    disabled:  (data?.status === undefined || data?.status === "draft" || data?.status === "") ? false : true,
                                    loading: loader,
                                    event: "submit",
                                    btnStyle: {
                                        display: loading ? "none": ""
                                    },
                                    onClick: () => {
                                        setTypeBtn("submit")
                                      },
                                } ,
                                {
                                    label: "Print",
                                    disabled:false,
                                    loading: loading,
                                    btnStyle: {
                                         display: loading ? "none": ""
                                    },
                                    onClick: () => {
                                      handlePrintWithDelay()
                                    },
                                },
                                // Add more buttons as needed
                            ] : [
                                {
                                    label: "Print",
                                    disabled:false,
                                    loading: loading,
                                    btnStyle: {
                                         display: loading ? "none": ""
                                    },
                                    onClick: () => {
                                      // Handle Button 2 click
                                      handlePrintWithDelay()
                                    },
                                },
                            ],
                        },
                    ],
                ],
            },
        ],
    };



    return {
        DeclarationForm,
    };
};