import { Col, Form, Input, Modal, Row, Select, Upload } from 'antd';
import Button from 'atoms/Button';
import React from 'react'
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;


function AddDocument({ onClose, showAddModal, isUpdating, onFinish, setBase64, form }) {

    const categoryList = ["REP/RFI Document", "EOI/Proposal", "Award Letter", "Letter of Engagement/contract", "Deliverable/Report", "Refferral Letter", "Statement of Account", "Engagement Closure document", "Credentials", "Invoice / Credit Notes", "Other"]

    const categoryListObjects = categoryList.map(item => ({
        label: item,
        value: item
    }));


      const handleClose = () => {
        onClose();
        form.resetFields();
      };

    return (
        <div>
            <StyledModal 
                title="Add Document"
                visible={showAddModal}
                onCancel={() => {
                    onClose()
                }}
                width={800}

                footer={[]}>

                <Form
                    layout="vertical"
                    // onFinish={onFinish}
                    onFinish={onFinish}
                    colon={false}
                    form={form}

                >
                    <div className="resource-card">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="Category"
                                    name="category"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Category is required.',
                                        },]}
                                >
                                    <Select
                                        placeholder="Select"
                                        options={categoryListObjects}
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={24}>

                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Description is required.',
                                        },]}
                                >
                                    <Input.TextArea
                                        placeholder="Description"
                                        rows={2}
                                        style={{
                                            resize: "none",
                                        }}
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Attachment"
                                    name="file"
                                    valuePropName="fileList"
                                    getValueFromEvent={e => Array.isArray(e) ? e : e && e.fileList}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please upload a file.',
                                        },
                                    ]}
                                >
                                    <Upload
                                        listType="picture"
                                        maxCount={1}
                                        showUploadList={true}
                                        beforeUpload={(file) => {
                                            // Return false to prevent multiple file uploads
                                            return !form.getFieldValue(file);
                                        }}
                                        customRequest={({ file, onSuccess }) => {
                                            // Read the file and convert it to base64
                                            const reader = new FileReader();
                                            reader.readAsDataURL(file);

                                            reader.onload = () => {
                                                // Get the base64 data
                                                const base64Data = reader.result.split(',')[1];
                                                setBase64(base64Data)

                                                // Call onSuccess to indicate successful upload
                                                onSuccess();

                                                // Pass the base64 data to the onChange function

                                            };

                                            reader.onerror = (error) => {
                                                console.error("File read error:", error);
                                                // Handle error if needed
                                            };
                                        }}


                                    >
                                        <Button type="primary" icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>
                                </Form.Item>

                            </Col>
                        </Row>
                    </div>

                    <div className="text-end">
                        <Button type="primary" onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isUpdating}>
                            Save
                        </Button>
                    </div>
                </Form>

            </StyledModal>
        </div>
    )
}

export default AddDocument