import { apiInstance } from "config/baseUrl";
import { useMutation } from "react-query";

/**
 * Custom hook for performing mutation operations.
 *
 * @param {string} mutationKey - The key used for caching the mutation result.
 * @param {string} url - The URL to send the mutation request to.
 * @param {object} options - Additional options for the mutation.
 * @returns {object} - The mutation function and its associated data and status.
 */

export const useUpdate = (mutationKey, url, options) => {
  const accessToken = window.localStorage.getItem("token");

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: async (data) => {
      return await apiInstance({
        method: "PATCH",
        url: `${url}`,
        data: data,
        headers: {
          Tymeplusjwt: `${accessToken}`,
        },
      }).then((res) => {
        return res.data;
      });
    },
    ...options,
  });
};
