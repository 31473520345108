import { Button, Card, Row, Spin } from "antd";
import { getSelfAssessmentJson } from "JsonFormat/SelfAssessment";
import React, { useState } from "react";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import ReusableTable from "./ReusableTable";
import styled from "styled-components";

const SelfAssesmentStyle = styled.div`
  .individual_weight_score_btn {
    height: 35px;
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    opacity: 1;
  }
  .individual_weight_score_label {
    padding: 6px;
    margin-bottom: 0rem;
    font: normal normal normal 16px/19px;
    letter-spacing: 0px;
    color: #000000;
  }
  .selfCloseBtn {
    width: 95px;
    height: 35px;
    background: #c2fae5 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font: normal normal bold 15px/18px;
    letter-spacing: 0px;
    color: #000000;
  }
`;

const SelfAssessment = () => {
  const [loading, setLoading] = useState(false);
  const { AddSelfAssessmentForm } = getSelfAssessmentJson();

  const selfCommandForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "Self Overall Comment:",
              type: "textarea",
              name: "selfCommand",
              Placeholder: "",
            },
          ],
        ],
      },
    ],
  };

  const IndividualForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [24, 24, 24],
        fields: [
          [
            {
              id: 1,
              label: "No of years with the Firm:",
              type: "text",
              name: "noofyears",
              Placeholder: "",
              //   value: data["rolename"],
            },
            {
              id: 2,
              label: "Years of Experience:",
              type: "text",
              name: "yearsofexperience",
              Placeholder: "",
            },
          ],
          [
            {
              id: 3,
              label: "Qualifications Category:",
              type: "text",
              name: "1ualificationscategory",
              Placeholder: "",
            },
            {
              id: 2,
              label: "Age:",
              type: "select",
              name: "age",
              Placeholder: "select",
            },
          ],
        ],
      },
    ],
  };

  const tempdata = [
    {
      description: (
        <div>
          <h6>Value & Efficiency:</h6>
          Knowledgeable and well prepared to handle the allocated work. The
          methodology and software used was appropriate. Conclusions reached
          were useful, realistic, and adequately supported by relevant facts and
          thorough analysis.
        </div>
      ),
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "",
    },
    {
      description: (
        <div>
          <h6>Timely Delivery:</h6>
          The assignment / tasks / sections were completed on a timely basis.
          The timeliness of response to phone calls/e-mails showed care and good
          communication skills. No undue disruption to workplace during the
          assignment.
        </div>
      ),
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      description: (
        <div>
          <h6>Quality, Compliance & Risk:</h6>
          Quality and Risks were properly managed and with compliance to
          methodology, tools and processes was as per expectation. Also,
          safeguarding of documents and work environment and staff commitments
          were respected.
        </div>
      ),
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      description: (
        <div>
          <h6>Client satisfaction:</h6>
          Demonstrated professionalism and the handling of client, staff and
          supervision of the job was impeccable. Both internal and external
          stakeholders were satisfied with the delivery.
        </div>
      ),
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      description: "Client Survey Score",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      description: "Total",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
  ];

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "50%",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Self score",
      dataIndex: "selfScore",
      key: "selfScore",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Supervisor score",
      dataIndex: "supervisorScore",
      key: "supervisorScore",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Weighted score",
      dataIndex: "weightedScore",
      key: "weightedScore",
      width: "18%",
      className: "normal-column",
    },
  ];

  const IndividualDynamicAttributesData = [
    {
      gaf: "Integrity",
      Description:
        "Demonstrates hight emotional quotient and leadership traits. Demonstrates knowledge of policies and practices well. Experiences very little difficulty in acquiring/learning knowledge/information. Keeps abreast of work related developments. Demonstrates ability to apply technical/professional knowledge and skills to immediate work situation. Low level of supervision and guidance necessary. Ensures that the limits to authority are respected at all times. Demonstrates and embodies the BDO values and culture through their interactions, efforts and behaviour. Should have a lot of Social Capital and must adhere to ESG and other ethical behaviours",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      gaf: "Effective communication",
      Description:
        "Expresses facts and ideas in a clear and logical manner, without excessive guidance.",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      gaf: "Responsibility",
      Description:
        "Constantly accepts responsibility in a competent manner for own areas of work and those of subordinates. Can be relied upon to accept responsibility in respect of other employees in their absence when requested to do so. Normal level of supervision and counselling required. Works out own programmes/approaches to overcome problems and competently performs to expectations where general principles are not adequate to determine procedure or decisions to be taken.",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      gaf: "Reliability",
      Description:
        "Seldom needs to enquire about progress of a task. Shows commitment to work. Will readily accept changes in work, work patterns or procedures, work location etc. in order to help achieve objectives.",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      gaf: "Teamwork",
      Description:
        "Extremely positive and inspiring personality. Co-operates well with supervisors, colleagues and those supervised. Is polite and respectful of others. Demonstrates a sound and healthy attitude when interacting with others. Acceptable and gets on well with colleagues. Able to influence and communicate well as part of a team. Works to achieve team objectives and coaches/ mentors reportees. Requires negligible level of supervision and guidance.",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      gaf: "Total",
      Description:
        "",
      weight: "100%",
      selfScore: "6",
      supervisorScore: "-",
      weightedScore: "-",
    },
  ];

  const IndividualDynamicAttributescolumns = [
    {
      title: "GAF / BDO EA Values",
      dataIndex: "gaf",
      key: "gaf",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      width: "80%",
      className: "normal-column",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Self score",
      dataIndex: "selfScore",
      key: "selfScore",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Supervisor score",
      dataIndex: "supervisorScore",
      key: "supervisorScore",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Weighted score",
      dataIndex: "weightedScore",
      key: "weightedScore",
      width: "18%",
      className: "normal-column",
    },
  ];

  const Technicalcolumns = [
    {
      title: "Pillars",
      dataIndex: "pillars",
      key: "pillars",
      width: "20%",
    },
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
      width: "40%",
      className: "normal-column",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Self score",
      dataIndex: "selfScore",
      key: "selfScore",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Supervisor score",
      dataIndex: "supervisorScore",
      key: "supervisorScore",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Weighted score",
      dataIndex: "weightedScore",
      key: "weightedScore",
      width: "18%",
      className: "normal-column",
    },
  ];

  const TechnicalData = [
    {
      pillars: "Additional Roles",
      activity:
        "Assist in the investigation of significant suspected fraudulent activities and notify management of the results",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      pillars: "QRC",
      activity: "0% system deficiencies, without effective remedial action.",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      pillars: "Leadership /Employee Excellence & BDO Relationships",
      activity: ">95% Hierarchical development of staff in the service line",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      pillars: "Leadership /Employee Excellence & BDO Relationships",
      activity: ">95% Hierarchical development of staff in the service line",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      pillars: "Operational Excellence",
      activity: ">95% achievement of measurable targets on employee scorecard",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      pillars: "Market Focus/ Client Centricity",
      activity: ">90% client satisfaction survey / feedback",
      weight: "10%",
      selfScore: "4",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      pillars: "Growth & Profitability",
      activity: "% of growth in clients / No. of new clients",
      weight: "10%",
      selfScore: "4",
      supervisorScore: "-",
      weightedScore: "-",
    },
    {
      pillars: "Total",
      activity: "",
      weight: "100%",
      selfScore: "4",
      supervisorScore: "-",
      weightedScore: "-",
    },
  ];

  const IndividualWorkData = [
    {
      wowFactor:
        "Key factors that contribute to adding tremendous value to both an individual and firm profile",
      weight: "20%",
      selfScore: "5",
      supervisorScore: "-",
      weightedScore: "-",
    },
  ];

  const IndividualWorkcolumns = [
    {
      title: "Wow factor",
      dataIndex: "wowFactor",
      key: "wowFactor",
      width: "50%",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Self score",
      dataIndex: "selfScore",
      key: "selfScore",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Supervisor score",
      dataIndex: "supervisorScore",
      key: "supervisorScore",
      width: "18%",
      className: "normal-column",
    },
    {
      title: "Weighted score",
      dataIndex: "weightedScore",
      key: "weightedScore",
      width: "18%",
      className: "normal-column",
    },
  ];

  return (
    <>
      <div className="self-info-box">
        <Card>
          <GridBuilder formStructureJson={AddSelfAssessmentForm} />
        </Card>
        <br />
        <div>
          <h6>Technical Assessment – Chargeable Engagements Aggregate (40%)</h6>
          <ReusableTable data={tempdata} columns={columns} />
        </div>
        <br />
        <div>
          <h6>Technical Assessment – Key Responsibilities (60%)</h6>
          <ReusableTable data={TechnicalData} columns={Technicalcolumns} />
        </div>
        <br />
        <SelfAssesmentStyle>
          <div>
            <u>
              <h6>Individual- Static Attributes (40%)</h6>
            </u>
            <GridBuilder formStructureJson={IndividualForm} />
            <div className="individual_weight_score_btn">
              <p className="individual_weight_score_label">Weighted score</p>
            </div>
          </div>
        </SelfAssesmentStyle>
        <br />
        <div>
          <h6>Individual- Dynamic Attributes (50%)</h6>
          <ReusableTable
            data={IndividualDynamicAttributesData}
            columns={IndividualDynamicAttributescolumns}
          />
        </div>
        <br />
        <div>
          <h6>Individual- Wow Factor(10%):</h6>
          <ReusableTable
            data={IndividualWorkData}
            columns={IndividualWorkcolumns}
          />
        </div>
        <GridBuilder formStructureJson={selfCommandForm} />

        <br />
        <SelfAssesmentStyle>
          <Button className="selfCloseBtn">Close</Button>
        </SelfAssesmentStyle>
      </div>
    </>
  );
};

export default SelfAssessment;
