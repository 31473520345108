import { StyledBookedIndicator, StyledDateCell } from "./StyledComponents";
import { dateInDetail } from "utils/Date";
import moment from "moment";
import ReusableCalendar from "./ReusableCalendar";
import ResourceIndicator from "./ResourceIndicator";

const MyEngagement = ({
  myBookedDates,
  isResourceLoading,
}) => {
  const dateFullCellRender = (cellDate) => {
    const { date: day } = dateInDetail(cellDate);
    const currentDate = moment(cellDate).format("YYYY-MM-DD");

    const isBooked = myBookedDates?.some(
      (mybooked) => mybooked.date === currentDate
    );

    const myResource = myBookedDates?.find(
      (mybooked) => mybooked.date === currentDate
    );

    return (
      <StyledDateCell
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <span className={`day`}>{day}</span>
        <div className="ant-picker-calendar-date-content">
          {isBooked && (
            <ResourceIndicator resourceDetails={myResource}>
              <StyledBookedIndicator>Engaged</StyledBookedIndicator>
            </ResourceIndicator>
          )}
        </div>
      </StyledDateCell>
    );
  };

  return (
    <ReusableCalendar
      dateFullCellRender={dateFullCellRender}
      isCalendarLoading={isResourceLoading}
      calendarTitle="My Calendar"
      showHoliday={false}
    />
  );
};

export default MyEngagement;
