import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "assets/icons/UI/edit_small.svg";
import { IconButton } from "@mui/material";
import queryKeys from "common/queryKey";
import { Form, Select, Space, Spin } from "antd";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useUpdate } from "hooks/useUpdate";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQueryClient } from "react-query";

const EditableTodoBox = ({ label, name, value, extraData, options }) => {
  const { id: engagementId, todoId } = useParams();

  const form = Form.useFormInstance();
  const [isEdit, setIsEdit] = useState(false);
  const queryClient = useQueryClient();

  const { patchTodo, getTodoById } = queryKeys;

  const { mutate, isLoading: isUpdating } = useUpdate(
    patchTodo.key,
    patchTodo.url
  );

  const handleClose = () => {
    form.setFieldValue(name, value);
    setIsEdit(false);
  };

  const { key: getTodoByIdKey } = getTodoById(engagementId, todoId);

  const handleEdit = () => {
    const data = {
      ...extraData,
      name: name,
      value: form.getFieldValue(name),
    };
    mutate(data, {
      onSuccess: () => {
        setIsEdit(false);
        queryClient.setQueryData(getTodoByIdKey, (oldData) => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              [name]: form.getFieldValue(name),
            },
          };
        });
      },
    });
  };

  return (
    <EditableBoxWrap>
      <Spin spinning={isUpdating}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="label-name">{label}:</div>

          <div className="edit-icons">
            {isEdit ? (
              <Space>
                <IconButton size="small" onClick={handleEdit}>
                  <DoneIcon />
                </IconButton>
                <IconButton size="small" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Space>
            ) : (
              <IconButton
                size="small"
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
        </div>

        {isEdit ? (
          <Form.Item name={name}>
            <Select
              style={{
                width: "100%",
                maxWidth: "300px",
                marginTop: "5px",
              }}
              placeholder="Select"
              options={options}
            />
          </Form.Item>
        ) : (
          <div>
            <div className="value">{value ?? "-"}</div>
          </div>
        )}
      </Spin>
    </EditableBoxWrap>
  );
};

export default EditableTodoBox;

const EditableBoxWrap = styled.div`
  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000f;
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 4px 14px;

  .label-name {
    font-size: 1rem;
    font-weight: 700;
  }
  .edit-icons {
  }
  .value {
    font-size: 1rem;
    font-weight: 500;
  }
`;
